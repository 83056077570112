import { t } from "i18next";

import React from "react";

export const Next = (props: any): JSX.Element => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            viewBox="0 0 350 87"
        >
            <g
                id="Groupe_16"
                data-name="Groupe 16"
                transform="translate(-1842 4082)"
            >
                <g
                    id="Rectangle_12"
                    data-name="Rectangle 12"
                    transform="translate(1842 -4082)"
                    fill="#323333"
                    stroke="#000"
                    stroke-width="2"
                >
                    <rect width="350" height="87" rx="9" stroke="none" />
                    <rect
                        x="1"
                        y="1"
                        width="348"
                        height="85"
                        rx="8"
                        fill="none"
                    />
                </g>
                <text
                    id="Suivant"
                    transform="translate(2017 -4024)"
                    fill="#fff"
                    font-size="44"
                    font-family="HelveticaNeue-Medium, Helvetica Neue"
                    font-weight="500"
                >
                    <tspan x="-65.455" y="0">
                        {t("Next", {
                            lng: props.selectedLanguage,
                        })}
                    </tspan>
                </text>
            </g>
        </svg>
    );
};
