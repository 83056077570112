import React from "react";

import { t } from "i18next";

export function CancelOrderPMR(props: any): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            viewBox="0 0 275 65"
        >
            <g
                id="Groupe_11"
                data-name="Groupe 11"
                transform="translate(248 4055)"
            >
                <g
                    id="Rectangle_6"
                    data-name="Rectangle 6"
                    transform="translate(-248 -4055)"
                    fill="#fff"
                    stroke="#8b8b8b"
                    stroke-width="2"
                >
                    <rect width="275" height="65" rx="9" stroke="none" />
                    <rect
                        x="1"
                        y="1"
                        width="273"
                        height="63"
                        rx="8"
                        fill="none"
                    />
                </g>
                <text
                    id="Cancel_order"
                    transform="translate(-111 -4011)"
                    fill="#8b8b8b"
                    font-size="22"
                    font-family="HelveticaNeue-Medium, Helvetica Neue"
                    font-weight="500"
                >
                    <tspan x="-115" y="0">
                        {t("Cancel my order", {
                            lng: props.selectedLanguage,
                        })}
                    </tspan>
                </text>
            </g>
        </svg>
    );
}
