import React from "react";

export function Spinner() {
    return (
        <div
            style={{
                display: "flex",
                position: "fixed",
                right: "15px",
                top: "15px",
                zIndex: 999999,
            }}
        >
            <div
                style={{
                    animation: "400ms linear infinite spinner",
                    borderBottom: "4px solid transparent",
                    borderLeft: "2px solid #29d",
                    borderRadius: "50%",
                    borderRight: "2px solid transparent",
                    borderTop: "2px solid #29d",
                    boxSizing: "border-box",
                    height: 18,
                    width: 18,
                }}
            />
        </div>
    );
}
