import React from "react";
import classnames from "classnames";
import ReactSwitch from "react-switch";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { Tooltip } from "@mui/material";

import { store } from "@components/VerticalLayout/store";
import {
    store as settingModeOfInformation,
    setActiveModeInformation,
} from "../store";

export function CustomIconsAssociateModal({
    e,
    setDataIsUpdated,
    modifiedRows,
    setModifiedRows,
    isModeOfInformationArchived,
    setIsModeOfInformationArchived,
}: any) {
    const { t } = useTranslation();
    const { localShop, associatedModeInformation } = useSnapshot(
        settingModeOfInformation
    );
    const { userID, franchiseID, operatorID } = useSnapshot(store);

    const [modeOfInformationState, setModeOfInformationState] = React.useState<
        boolean
    >(
        localShop.length === 1 &&
            associatedModeInformation.findIndex(
                (el: any) => el.Id === e.selectedRow.Id
            ) !== -1
            ? associatedModeInformation[
                  associatedModeInformation.findIndex(
                      (el: any) => el.Id === e.selectedRow.Id
                  )
              ].state
            : true
    );
    const uuidUser = localStorage.getItem("uuidUser");

    async function handleStateChange() {
        let table = [...modifiedRows];
        if (e.selectedRow.Id !== undefined) {
            let obj = [
                {
                    userId: userID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    shopId: [e.selectedRow.shopId],
                    franchiseId: franchiseID,

                    data: {
                        [e.selectedRow.Id]: {
                            designation:
                                e.selectedRow[t("Mode of information")],
                            idMI: e.selectedRow.idMI,
                            supportOfSale: e.selectedRow[t("Sales support")],
                            salesSupportData: [
                                e.selectedRow[t("Sales support")],
                            ],
                            displayName: e.selectedRow[t("Display name")],
                            modeOfSale: e.selectedRow[t("Sales method")],
                            tag: e.selectedRow.tag,
                            isArchived: false,
                            state: e.selectedRow.state,
                            shopId: e.selectedRow.shopId,
                            advancedDisplayName:
                                e.selectedRow.advancedDisplayName,
                        },
                    },
                },
            ];

            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`;
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(obj),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.error) {
                            throw Error(result.message);
                        }

                        table.push(e.selectedRow);
                        setModifiedRows(table);
                    })
                    .catch((error: any) => {
                        toast.error(`${t("There's an error")!}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    })
            );
        }
    }

    React.useEffect(() => {
        setActiveModeInformation({
            Id: e.selectedRow.Id,
            state: modeOfInformationState,
        });

        // eslint-disable-next-line
    }, [modeOfInformationState]);

    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    <Tooltip
                        title={
                            isModeOfInformationArchived
                                ? ""
                                : modeOfInformationState === false
                                ? t("Activate")
                                : t("Unactivate")
                        }
                    >
                        <div>
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={modeOfInformationState}
                                onChange={async () => {
                                    setModeOfInformationState(
                                        !modeOfInformationState
                                    );
                                    handleStateChange();
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={50}
                                height={20}
                                className={`${classnames(
                                    "pointer__clz ml-3 mdi_icn_AFenableDisable",
                                    {
                                        "not-allowed-icon__clz": isModeOfInformationArchived,
                                    }
                                )}`}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </React.Fragment>
    );
}
