import React from "react";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import {
    Dropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
} from "reactstrap";
import {
    VerticalDotsIcon,
    SettingsIcon,
    DuplicateIcon,
    TrashIcon,
    EyeIcon,
    ShieldExclamationIcon,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { store as userStore } from "../../../../components/VerticalLayout/store";
import store, {
    setIsModalAssociateStore,
    setIsModalDissociateOpened,
    setTemplate,
    setIsActionsClicked,
    setActions,
    setIsEdited,
} from "./store";
import ErrorToast from "@components/Common/ErrorTost";

type DropDownWrapperType = {
    element: any;
    setSelectedRows: Function;
    isProjectArchived: boolean;
    setIsConfirmationModalArchived: Function;
    setIsConfirmationModalActive: Function;
    setIsConfirmationModalCopied: Function;
    setIsConfigurationModalOpened: Function;
    setIdStore: Function;
    dataFranchise: any;
    setIsModalPublishOpened: Function;
};

export function DropDownWrapper({
    element,
    setSelectedRows,
    isProjectArchived,
    setIsConfirmationModalArchived,
    setIsConfirmationModalActive,
    setIsConfirmationModalCopied,
    setIsConfigurationModalOpened,
    setIdStore,
    dataFranchise,
    setIsModalPublishOpened,
}: DropDownWrapperType): JSX.Element {
    const { t } = useTranslation();

    const { oneShop } = useSnapshot(userStore);
    const { isModalAssociateStore, isActionsClicked, isEdited } = useSnapshot(
        store
    );

    const [isDropdownOpened, setIsDropdownOpened] = React.useState(false);

    function toggleDropdown() {
        setIsDropdownOpened((prevState) => !prevState);
    }
    let selectedAccount = localStorage.getItem("selectedAccount");
    const isAssociateShops = React.useCallback(() => {
        let isAssociate: boolean = false;
        dataFranchise?.dataProjectFranchise?.data.forEach((el: any) => {
            if (el.projectId === element.selectedRow.original.projectId) {
                if (Object.keys(el.associates).length > 1) {
                    isAssociate = true;
                }
            }
        });

        return isAssociate;
    }, [element.selectedRow.original.projectId, dataFranchise]);

    let isAssociate = isAssociateShops();

    return (
        <React.Fragment>
            {element.selectedRow.original.key_project ? (
                <Dropdown isOpen={isDropdownOpened} toggle={toggleDropdown}>
                    <DropdownToggle
                        id="page-header-user-dropdown"
                        tag="button"
                        className={`${classnames(
                            "pointer__clz btn btn header-item waves-effect"
                        )}`}
                    >
                        <VerticalDotsIcon
                            height={25}
                            width={25}
                            className={classnames("cmn_drp_menuProject", {
                                kds_drp_menuProject: !isProjectArchived,
                                cmn_drp_menuProject: isProjectArchived,
                            })}
                        />
                    </DropdownToggle>
                    <DropdownMenu>
                        {element.selectedRow.original[t("Status")] ===
                        "Publié" ? (
                            <DropdownItem
                                style={{ cursor: "pointer" }}
                                className="cgt_icn_consult"
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(() => {
                                            setIsEdited(false);
                                            setSelectedRows(
                                                element.selectedRow.original
                                            );
                                            setIsConfigurationModalOpened(true);
                                            toast.info(
                                                `${t(
                                                    "Attention you can not modify a published project"
                                                )!}`,
                                                {
                                                    position:
                                                        toast.POSITION
                                                            .TOP_CENTER,
                                                    autoClose: 2000,
                                                    theme: "colored",
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                }
                                            );
                                            if (
                                                oneShop &&
                                                selectedAccount === "boutique1"
                                            ) {
                                                setIdStore(2);
                                            } else if (
                                                oneShop &&
                                                selectedAccount === "boutique2"
                                            ) {
                                                setIdStore(3);
                                            } else {
                                                setIdStore(0);
                                            }
                                        });
                                    } else {
                                        setSelectedRows(
                                            element.selectedRow.original
                                        );
                                        setIsConfigurationModalOpened(true);
                                        toast.info(
                                            `${t(
                                                "Attention you can not modify a published project"
                                            )!}`,
                                            {
                                                position:
                                                    toast.POSITION.TOP_CENTER,
                                                autoClose: 2000,
                                                theme: "colored",
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                            }
                                        );
                                        if (
                                            oneShop &&
                                            selectedAccount === "boutique1"
                                        ) {
                                            setIdStore(2);
                                        } else if (
                                            oneShop &&
                                            selectedAccount === "boutique2"
                                        ) {
                                            setIdStore(3);
                                        } else {
                                            setIdStore(0);
                                        }
                                    }
                                }}
                            >
                                <SettingsIcon height={15} width={15} />
                                <span>{t("To consult")}</span>
                            </DropdownItem>
                        ) : (
                            <DropdownItem
                                className={classnames("cmn_icn_configuration", {
                                    "not-allowed-icon__clz": isProjectArchived,
                                })}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(() => {
                                            setIsEdited(false);
                                            setSelectedRows(
                                                element.selectedRow.original
                                            );
                                            setTemplate(
                                                element.selectedRow.original
                                                    .template
                                            );

                                            if (
                                                oneShop &&
                                                selectedAccount === "boutique1"
                                            ) {
                                                setIdStore(2);
                                            } else if (
                                                oneShop &&
                                                selectedAccount === "boutique2"
                                            ) {
                                                setIdStore(3);
                                            } else {
                                                setIdStore(0);
                                            }

                                            setIsConfigurationModalOpened(true);
                                        });
                                    } else {
                                        setSelectedRows(
                                            element.selectedRow.original
                                        );
                                        setTemplate(
                                            element.selectedRow.original
                                                .template
                                        );

                                        if (
                                            oneShop &&
                                            selectedAccount === "boutique1"
                                        ) {
                                            setIdStore(2);
                                        } else if (
                                            oneShop &&
                                            selectedAccount === "boutique2"
                                        ) {
                                            setIdStore(3);
                                        } else {
                                            setIdStore(0);
                                        }

                                        setIsConfigurationModalOpened(true);
                                    }
                                }}
                            >
                                <SettingsIcon height={15} width={15} />
                                <span>{t("Configure")}</span>
                            </DropdownItem>
                        )}

                        <DropdownItem
                            style={{ cursor: "pointer" }}
                            className={classnames("cmn_icn_publish", {
                                "not-allowed-icon__clz":
                                    element.selectedRow.original[
                                        t("Status")
                                    ] === "Publié" ||
                                    isProjectArchived ||
                                    (isAssociate && oneShop),
                            })}
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);
                                        if (
                                            Object.keys(
                                                element.selectedRow.original
                                                    .associates
                                            ).length === 0 &&
                                            !oneShop
                                        ) {
                                            toast.error(
                                                `${t(
                                                    "Unable to publish a template not associated with one or more store(s)"
                                                )!}`,
                                                {
                                                    position:
                                                        toast.POSITION
                                                            .TOP_CENTER,
                                                    autoClose: 2000,
                                                    theme: "colored",
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    icon: (
                                                        <ShieldExclamationIcon
                                                            width={25}
                                                            height={25}
                                                            fill="white"
                                                        />
                                                    ),
                                                }
                                            );
                                        } else if (
                                            Object.keys(
                                                element.selectedRow.original
                                                    .template
                                            ).length === 0
                                        ) {
                                            toast.error(
                                                `${t(
                                                    "Unable to Publish an Unconfigured Project"
                                                )!}`,
                                                {
                                                    position:
                                                        toast.POSITION
                                                            .TOP_CENTER,
                                                    autoClose: 2000,
                                                    theme: "colored",
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    icon: (
                                                        <ShieldExclamationIcon
                                                            width={25}
                                                            height={25}
                                                            fill="white"
                                                        />
                                                    ),
                                                }
                                            );
                                        } else {
                                            setIsModalPublishOpened(true);
                                        }
                                        setSelectedRows(
                                            element.selectedRow.original
                                        );
                                    });
                                } else {
                                    if (
                                        Object.keys(
                                            element.selectedRow.original
                                                .associates
                                        ).length === 0 &&
                                        !oneShop
                                    ) {
                                        toast.error(
                                            `${t(
                                                "Unable to publish a template not associated with one or more store(s)"
                                            )!}`,
                                            {
                                                position:
                                                    toast.POSITION.TOP_CENTER,
                                                autoClose: 2000,
                                                theme: "colored",
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                icon: (
                                                    <ShieldExclamationIcon
                                                        width={25}
                                                        height={25}
                                                        fill="white"
                                                    />
                                                ),
                                            }
                                        );
                                    } else if (
                                        Object.keys(
                                            element.selectedRow.original
                                                .template
                                        ).length === 0
                                    ) {
                                        toast.error(
                                            `${t(
                                                "Unable to Publish an Unconfigured Project"
                                            )!}`,
                                            {
                                                position:
                                                    toast.POSITION.TOP_CENTER,
                                                autoClose: 2000,
                                                theme: "colored",
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                icon: (
                                                    <ShieldExclamationIcon
                                                        width={25}
                                                        height={25}
                                                        fill="white"
                                                    />
                                                ),
                                            }
                                        );
                                    } else {
                                        setIsModalPublishOpened(true);
                                    }
                                    setSelectedRows(
                                        element.selectedRow.original
                                    );
                                }
                            }}
                        >
                            <DuplicateIcon height={15} width={15} />
                            <span>{t("Publish")}</span>
                        </DropdownItem>
                        <DropdownItem
                            style={{ cursor: "pointer" }}
                            className={classnames("cmn_icn_duplicate", {
                                "not-allowed-icon__clz":
                                    isProjectArchived ||
                                    (isAssociate && oneShop),
                            })}
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);
                                        setSelectedRows([
                                            element.selectedRow.original,
                                        ]);
                                        setIsConfirmationModalCopied(true);
                                    });
                                } else {
                                    setSelectedRows([
                                        element.selectedRow.original,
                                    ]);
                                    setIsConfirmationModalCopied(true);
                                }
                            }}
                        >
                            <DuplicateIcon height={15} width={15} />
                            <span>{t("Duplicate")}</span>
                        </DropdownItem>
                        {!oneShop ? (
                            <DropdownItem
                                style={{ cursor: "pointer" }}
                                className={classnames(
                                    "cmn_icn_associateStore",
                                    {
                                        "not-allowed-icon__clz":
                                            isProjectArchived ||
                                            oneShop ||
                                            element.selectedRow.original[
                                                t("Status")
                                            ] === "Publié",
                                    }
                                )}
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(() => {
                                            setIsEdited(false);
                                            setSelectedRows(
                                                element.selectedRow.original
                                            );
                                            setIsModalAssociateStore(
                                                !isModalAssociateStore
                                            );
                                        });
                                    } else {
                                        setSelectedRows(
                                            element.selectedRow.original
                                        );
                                        setIsModalAssociateStore(
                                            !isModalAssociateStore
                                        );
                                    }
                                }}
                            >
                                <DuplicateIcon height={15} width={15} />
                                <span>{t("Associate to store(s)")}</span>
                            </DropdownItem>
                        ) : null}
                        <DropdownItem
                            className={classnames(
                                "",
                                {
                                    "not-allowed-icon__clz":
                                        element.selectedRow.original[
                                            t("Status")
                                        ] === "Publié",
                                },
                                isProjectArchived
                                    ? "cmn_icn_FRunarchive"
                                    : "cmn_btn_FRarchive"
                            )}
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);
                                        setSelectedRows([
                                            element.selectedRow.original,
                                        ]);
                                        if (oneShop) {
                                            setIsModalDissociateOpened(true);
                                        } else if (!oneShop) {
                                            if (!isProjectArchived) {
                                                if (
                                                    element.selectedRow.original
                                                        .subRows.length > 0
                                                ) {
                                                    return ErrorToast(
                                                        `${t(
                                                            "Please note that you cannot archive a project associated with the store(s)."
                                                        )!}`
                                                    );
                                                } else {
                                                    setIsConfirmationModalArchived(
                                                        true
                                                    );
                                                }
                                            } else {
                                                setIsConfirmationModalActive(
                                                    true
                                                );
                                            }
                                        }
                                    });
                                } else {
                                    setSelectedRows([
                                        element.selectedRow.original,
                                    ]);
                                    if (oneShop) {
                                        setIsModalDissociateOpened(true);
                                    } else if (!oneShop) {
                                        if (!isProjectArchived) {
                                            if (
                                                element.selectedRow.original
                                                    .subRows.length > 0
                                            ) {
                                                return ErrorToast(
                                                    `${t(
                                                        "Please note that you cannot archive a project associated with the store(s)."
                                                    )!}`
                                                );
                                            } else {
                                                setIsConfirmationModalArchived(
                                                    true
                                                );
                                            }
                                        } else {
                                            setIsConfirmationModalActive(true);
                                        }
                                    }
                                }
                            }}
                        >
                            {!isProjectArchived ? (
                                <TrashIcon height={15} width={15} fill="red" />
                            ) : (
                                <EyeIcon height={15} width={15} />
                            )}

                            <span
                                className={classnames("", {
                                    "not-allowed-icon__clz":
                                        element.selectedRow.original[
                                            t("Status")
                                        ] === "Publié",
                                })}
                            >
                                {!isProjectArchived
                                    ? oneShop
                                        ? `${t("Delete")}`
                                        : `${t("Archive")}`
                                    : `${t("Enable")}`}
                            </span>
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            ) : null}
        </React.Fragment>
    );
}
