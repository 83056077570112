import React from "react";
import ReactSwitch from "react-switch";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import {
    designStore,
    generalConfigStore,
    setInformationPrinterOptionsWithOutEmailItems,
    setIsHeaderActive,
} from "@store";

import { truncateString } from "@helpers/general";

import { Translation } from "./Translation";

export function MainContentWithoutSendingByEmail(): JSX.Element {
    const { t } = useTranslation();

    const { project } = useSnapshot(generalConfigStore);
    const { isHeaderActive, selectedLanguage } = useSnapshot(designStore);

    const informationPrinterOptionsWithOutEmailItems = (project.template.pages
        .ways["printerOptions"] as PrinterOptionsPageType).header
        .informationMessage.withoutemail;

    function handleInformationMessageSwitchOnClickEvent() {
        if (isHeaderActive === false) {
            setIsHeaderActive(true);
        }

        setInformationPrinterOptionsWithOutEmailItems({
            ...informationPrinterOptionsWithOutEmailItems,
            active: !informationPrinterOptionsWithOutEmailItems.active,
        });
    }

    return (
        <div
            style={{
                top: "363px",
                left: "555px",
                width: "800px",
                height: "500px",
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                border: "1px solid #CECECE",
                borderRadius: "9px",
                opacity: 1,
            }}
        >
            <div>
                <div
                    className="text-left px-4 d-flex align-items-center"
                    style={{
                        backgroundColor: "#EDEDED",
                        borderTopLeftRadius: "9px",
                        borderTopRightRadius: "9px",
                        zIndex: 1,
                        height: "40px",
                        fontSize: "20px",
                    }}
                >
                    {t("Header")}
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "30px",
                    }}
                >
                    <div className="p-4  d-flex justify-content-between align-items-center">
                        <div>
                            <div>{t("Information message")} </div>
                            <div style={{ color: "#B7B7B7", fontSize: "13px" }}>
                                {informationPrinterOptionsWithOutEmailItems
                                    .languages[selectedLanguage]?.content !==
                                undefined
                                    ? truncateString(
                                          informationPrinterOptionsWithOutEmailItems
                                              .languages[selectedLanguage]
                                              .content,
                                          80
                                      )
                                    : ""}
                            </div>
                        </div>
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={
                                informationPrinterOptionsWithOutEmailItems.active
                            }
                            onChange={() =>
                                handleInformationMessageSwitchOnClickEvent()
                            }
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                </div>
            </div>
            {informationPrinterOptionsWithOutEmailItems.active === true ? (
                <Translation />
            ) : null}
        </div>
    );
}
