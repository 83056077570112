import React from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";
import SimpleBar from "simplebar-react";
import {
    designStore,
    generalConfigStore,
    setActiveOrderTakingConfigOptions,
    setIsConfirmationPopupForDeletingAnArticleActive,
    setIsOrderTakingConfigOptionsActive,
    setIsShopCartDetailsActive,
    setIsShownProductCompositionDescription,
    setOrderTakingOptionsItems,
    setPageOrderTaking,
} from "@store";

export function MainContent(): JSX.Element {
    const { t } = useTranslation();
    const {
        project: { template },
        isPRMActive,
        isHelpActive,
        isShopCartDetailsActive,
    } = useSnapshot(generalConfigStore);

    const {
        isConfirmationPopupForDeletingAnArticleActive,
        isShownProductCompositionDescription,
    } = useSnapshot(designStore);

    const [options, setOptions] = React.useState(
        (template.pages.ways[
            "orderTaking"
        ] as OrderTakingPageType).options.items.filter(
            (option) => option.title !== "Usual"
        )
    );
    function handleCheckIconOnClickEvent(index: number) {
        setOptions((prevState: OptionsItemType[]): OptionsItemType[] => {
            let newState = [...prevState];

            newState = newState.map(
                (item: OptionsItemType, itemIndex: number) => {
                    if (itemIndex === index) {
                        return {
                            ...item,
                            active: !item.active,
                        };
                    }

                    return { ...item };
                }
            );
            setOrderTakingOptionsItems(newState);

            setActiveOrderTakingConfigOptions(newState);

            if (newState.filter((item) => item.active === true).length === 0) {
                setIsOrderTakingConfigOptionsActive(false);
            }

            return newState;
        });
    }

    React.useEffect(() => {
        if (isPRMActive === false) {
            setOptions((prevState: OptionsItemType[]): OptionsItemType[] => {
                let newState = [...prevState];

                newState = newState.map((item: OptionsItemType) => {
                    if (item.title === "PRM/Child") {
                        return {
                            ...item,
                            active: false,
                        };
                    }

                    return { ...item };
                });
                setOrderTakingOptionsItems(newState);

                return newState;
            });
        }
        if (isHelpActive === false) {
            setOptions((prevState: OptionsItemType[]): OptionsItemType[] => {
                let newState = [...prevState];

                newState = newState.map((item: OptionsItemType) => {
                    if (item.title === "Ask for help") {
                        return {
                            ...item,
                            active: false,
                        };
                    }

                    return { ...item };
                });
                setOrderTakingOptionsItems(newState);

                return newState;
            });
        }
    }, [isHelpActive, isPRMActive]);

    return (
        <div className=" d-flex flex-column justify-content-between mx-2 m-2 rounded border">
            <div
                className="text-left px-4 d-flex align-items-center cursor__clz"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                {t("Basket and options")}
            </div>
            <SimpleBar
                autoHide={true}
                style={{ maxHeight: 250, borderColor: "#CECECE" }}
            >
                <div className="p-4">
                    {options
                        .filter(
                            (item) =>
                                item.title !== "Loyalty account" &&
                                item.title !== "Customer account" &&
                                item.title !== "Allergen"
                        )
                        .map((option, index) => (
                            <div
                                className="py-2 d-flex flex-row justify-content-between align-items-center"
                                key={index}
                            >
                                <div>{t(option.title)}</div>

                                {option.title === "PRM/Child" ? (
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={option.active}
                                        onChange={() =>
                                            handleCheckIconOnClickEvent(index)
                                        }
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                        disabled={!isPRMActive}
                                    />
                                ) : option.title === "Ask for help" ? (
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={option.active}
                                        onChange={() =>
                                            handleCheckIconOnClickEvent(index)
                                        }
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                        disabled={!isHelpActive}
                                    />
                                ) : null}
                            </div>
                        ))}

                    <div className="py-2 d-flex flex-row justify-content-between align-items-center">
                        {t("Detailed basket")}

                        <ReactSwitch
                            className="ksk_chk_expertTakeOrderFeaturesDetailsBasket"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isShopCartDetailsActive}
                            onChange={() => {
                                setIsShopCartDetailsActive(
                                    !isShopCartDetailsActive
                                );
                                setPageOrderTaking({
                                    ...(template.pages.ways[
                                        "orderTaking"
                                    ] as OrderTakingPageType)
                                        .orderTakingProduct,
                                    isShopCartDetailsActive: !isShopCartDetailsActive,
                                });
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>

                    <div className="py-2 d-flex flex-row justify-content-between align-items-center">
                        {t(
                            "Show item details as text in reduced shopping cart"
                        )}

                        <ReactSwitch
                            className="ksk_chk_expertTakeOrderFeaturesDetailsBasket"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isShownProductCompositionDescription}
                            onChange={() => {
                                setIsShownProductCompositionDescription(
                                    !isShownProductCompositionDescription
                                );
                                setPageOrderTaking({
                                    ...(template.pages.ways[
                                        "orderTaking"
                                    ] as OrderTakingPageType)
                                        .orderTakingProduct,
                                    isShownProductCompositionDescription: !isShownProductCompositionDescription,
                                });
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                    <div className="py-2 d-flex flex-row justify-content-between align-items-center">
                        {t("Enable confirmation popup for deleting an article")}

                        <ReactSwitch
                            className="ksk_chk_expertTakeOrderFeaturesDetailsBasket"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={
                                isConfirmationPopupForDeletingAnArticleActive
                            }
                            onChange={() => {
                                setIsConfirmationPopupForDeletingAnArticleActive(
                                    !isConfirmationPopupForDeletingAnArticleActive
                                );
                                setPageOrderTaking({
                                    ...(template.pages.ways[
                                        "orderTaking"
                                    ] as OrderTakingPageType)
                                        .orderTakingProduct,
                                    isConfirmationPopupForDeletingAnArticleActive: !isConfirmationPopupForDeletingAnArticleActive,
                                });
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>

                    {/* <div className="py-2 d-flex flex-row justify-content-between align-items-center">
                    <div>{t("Change of sales method")}</div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isSalesMethodChangeActive}
                        onChange={() => {
                            setIsSalesMethodChangeActive(
                                !isSalesMethodChangeActive
                            );
                            setPageOrderTaking({
                                ...(template.pages.ways[
                                    "orderTaking"
                                ] as OrderTakingPageType).orderTakingProduct,
                                salesMethodsChangeActive: !isSalesMethodChangeActive,
                            });
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div> */}
                </div>
            </SimpleBar>
        </div>
    );
}
