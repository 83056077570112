import { proxy } from "valtio";

type StoreType = {
    activationDeactivationMessageModalOpened: boolean;
    message: string;
    YesFunction: Function;
    NoFunction: Function;
};

export const store = proxy<StoreType>({
    activationDeactivationMessageModalOpened: false,
    message: "",
    YesFunction: () => {},
    NoFunction: () => {},
});

export function setActivationDeactivationMessageModalOpened(
    value: boolean
): void {
    store.activationDeactivationMessageModalOpened = value;
}
export function setMessage(value: string): void {
    store.message = value;
}
export function setYesFunction(value: Function): void {
    store.YesFunction = value;
}
export function setNoFunction(value: Function): void {
    store.NoFunction = value;
}

export default store;
