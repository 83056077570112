import React from "react";
import classnames from "classnames";
import { Collapse } from "reactstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next/";
import { useSnapshot } from "valtio";

import { store as colorStore } from "@store";

import { StyledSidebarMenuPropsType, MenuItemsType, isVisibleElement } from ".";

import { ArrowDownIcon } from "../../Common/SvgIcons/ArrowDownIcon";
import { ArrowUpIcon } from "../../Common/SvgIcons/ArrowUpIcon";
import { SubMenu } from "./SubMenu";

type WrapperPropsType = Pick<
    StyledSidebarMenuPropsType,
    "backgroundColor" | "collapsed"
>;

type MenuItemType = {
    setIsMenuClicked: Function;
    index: number;

    subMenuCollapsed: any;
    setSubMenuCollapsed: any;
    setMenuCollapsed: Function;
    menuCollapsed: any;
} & WrapperPropsType &
    MenuItemsType;

const Wrapper = styled.div<WrapperPropsType>`
    .collapsed__clz {
        display: none;
        z-index: 1;
        position: fixed;

        margin-left: 220px !important;
        min-width: 220px;
        max-width: 270px;
        margin-top: -43px !important;
        ${({ backgroundColor }: WrapperPropsType) => {
            return `background-color: ${backgroundColor}`;
        }};
    }
    &:hover .collapsed__clz {
        display: block;
    }
`;

export function Menu({
    link,
    menuItem,
    subMenu,
    icon,
    index,
    collapsed,
    backgroundColor,
    setMenuCollapsed,
    menuCollapsed,
    customClassName,
    setIsMenuClicked,
    subMenuCollapsed,
    setSubMenuCollapsed,
    id,
}: MenuItemType) {
    const { t } = useTranslation();
    const { mainColor } = useSnapshot(colorStore);

    return isVisibleElement(id) ? (
        <li className={customClassName}>
            <Link
                onClick={() => {
                    setIsMenuClicked(true);
                    setSubMenuCollapsed(-1);
                    if (menuCollapsed === index) {
                        setMenuCollapsed(-1);
                        // setIsSub({ menuCollapsed: -1, click: true });
                    } else {
                        // setIsSub({ menuCollapsed: index, click: true });
                        setMenuCollapsed(index);
                    }
                }}
                to={
                    subMenu !== undefined
                        ? menuItem === t("Applications")
                            ? "applications"
                            : "#"
                        : link
                }
                className={classnames(
                    "d-flex align-items-center justify-content-between menu-item__clz pb-2",
                    {
                        "has-arrow": subMenu !== undefined,
                    },
                    (navData: any) => {
                        return navData.isActive ? "active " : "";
                    }
                )}
            >
                <div className="d-flex align-items-center justify-content-between menu-item__clz">
                    <div
                        style={{
                            marginRight: "7px",
                            fill: "white",
                        }}
                    >
                        {icon}
                    </div>

                    <span
                        className={classnames({
                            "d-none": collapsed === true,
                        })}
                    >
                        {menuItem}
                    </span>
                </div>

                {collapsed === true ||
                subMenu === undefined ? null : menuCollapsed === index ? (
                    <ArrowUpIcon />
                ) : (
                    <ArrowDownIcon />
                )}
            </Link>
            {collapsed === true ? (
                isVisibleElement(id) ? (
                    <ul
                        className="collapsed-submenu__clz"
                        style={{ backgroundColor: mainColor }}
                    >
                        {subMenu !== undefined ? (
                            <li
                                key={index}
                                className="menuitem-collapsed-header__clz"
                            >
                                <span>{menuItem}</span>
                            </li>
                        ) : (
                            <li
                                key={index}
                                style={{ padding: "0px" }}
                                className="menuitem-collapsed-header__clz"
                            >
                                <Link to={link}>{menuItem}</Link>
                            </li>
                        )}

                        {subMenu !== undefined
                            ? subMenu?.map(
                                  (
                                      {
                                          link,
                                          menuItem,
                                          subMenu,
                                          id,
                                      }: MenuItemsType,
                                      index: number
                                  ) => {
                                      return (
                                          <Wrapper
                                              key={index}
                                              backgroundColor={mainColor}
                                          >
                                              {isVisibleElement(id) ? (
                                                  <li className="collapsed-sub-submenu__clz">
                                                      <Link to={link}>
                                                          {menuItem}
                                                      </Link>
                                                  </li>
                                              ) : null}
                                              {subMenu !== undefined ? (
                                                  <ul className="collapsed__clz">
                                                      <li
                                                          key={index}
                                                          className="menuitem-collapsed-header__clz"
                                                      >
                                                          <span>
                                                              {menuItem}
                                                          </span>
                                                      </li>
                                                      {subMenu?.map(
                                                          (
                                                              {
                                                                  link,
                                                                  menuItem,
                                                                  id,
                                                              }: MenuItemsType,
                                                              index: number
                                                          ) => {
                                                              return isVisibleElement(
                                                                  id
                                                              ) ? (
                                                                  <li
                                                                      key={
                                                                          index
                                                                      }
                                                                  >
                                                                      <Link
                                                                          to={
                                                                              link
                                                                          }
                                                                      >
                                                                          {
                                                                              menuItem
                                                                          }
                                                                      </Link>
                                                                  </li>
                                                              ) : null;
                                                          }
                                                      )}
                                                  </ul>
                                              ) : null}
                                          </Wrapper>
                                      );
                                  }
                              )
                            : null}
                    </ul>
                ) : null
            ) : null}
            {subMenu !== undefined && collapsed !== true ? (
                <Collapse isOpen={menuCollapsed === index}>
                    <ul>
                        {subMenu?.map(
                            (
                                {
                                    link,
                                    menuItem,
                                    icon,
                                    subMenu,
                                    customClassName,
                                    id,
                                }: MenuItemsType,
                                index: number
                            ) => {
                                return isVisibleElement(id) ? (
                                    <li key={index} className={customClassName}>
                                        {isVisibleElement(id) ? (
                                            <SubMenu
                                                id={id}
                                                setIsMenuClicked={
                                                    setIsMenuClicked
                                                }
                                                index={index}
                                                Icon={icon}
                                                link={link}
                                                menuItem={menuItem}
                                                subMenu={subMenu}
                                                menuCollapsed={menuCollapsed}
                                                setSubMenuCollapsed={
                                                    setSubMenuCollapsed
                                                }
                                                subMenuCollapsed={
                                                    subMenuCollapsed
                                                }
                                                customClassName={
                                                    customClassName
                                                }
                                            />
                                        ) : null}
                                    </li>
                                ) : null;
                            }
                        )}
                    </ul>
                </Collapse>
            ) : null}
        </li>
    ) : null;
}
