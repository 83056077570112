import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { CheckboxIcon, StyledModal } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import ErrorToast from "@components/Common/ErrorTost";

import { store } from "@components/VerticalLayout/store";
import {
    store as generalSettingStore,
    setIsAssociateModalOpened,
    setIsAssociateModalClosed,
    setNumberActiveMultiShop,
    setIsAssociatedPosEditor,
    setSelectedRow,
    setIds,
    setMessage,
    setPosEditor,
    setGlobalDataIsUpdated,
} from "../store";

import UniqueRowSelection from "./UniqueRowSelection";
import { ConfirmationMessage } from "./ConfirmationMessage";
import { ConfirmationUpdate } from "./ConfirmationUpdate";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

export function PosEditorAssociateModal({
    setData,
    selectedShopsRows,
    setLimit,
}: any) {
    const { t } = useTranslation();
    let {
        isAssociateModalOpened,
        localShop,
        isAssociateModalClosed,
        numberActiveMultiShop,
        idsLocalShop,
        rowsShop,
        selectedRowsPosEditor,
        activePosEditor,
        isAssociatedPosEditor,
        posEditor,
    } = useSnapshot(generalSettingStore);
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [error, setError] = React.useState<boolean>(false);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [isValidateClickedModal, setIsValidateClickedModal] = React.useState<
        boolean | number
    >(false);
    const [isStateChanged, setIsStateChanged] = React.useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [selectedPosEditor, setSelectedPosEditor] = React.useState<any[]>([]);
    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);
    const shopId = 0;
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    const url =
        localShop.length === 1
            ? `${process.env.REACT_APP_API_V2_URL}/settings/general/list/posEditor?userId=${userID}&shopId=${localShop[0]}&franchiseId=${franchiseID}`
            : `${process.env.REACT_APP_API_V2_URL}/settings/general/posEditor?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=activated`;

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 0,
            columnName: " ",
            customJsx: (e: any) => (
                <UniqueRowSelection
                    e={e}
                    isOneShopClicked={
                        selectedShopsRows.filter(
                            (el: any) => el.subRows !== undefined
                        ).length < 2
                    }
                    selectedPosEditor={selectedPosEditor}
                />
            ),
        },

        // {
        //     indexOFColumn: 6,
        //     columnName: t("Action"),
        //     customJsx: (e: any) => (
        //         <CustomIconsAssociateModal
        //             element={e}
        //             isOneShopClicked={
        //                 selectedShopsRows.filter(
        //                     (el: any) => el.subRows !== undefined
        //                 ).length < 2
        //             }
        //             selectedPosEditor={selectedPosEditor}
        //         />
        //     ),
        // },
    ];

    function checkIfPosEditorAssociated(shopId: string) {
        let associated = rowsShop.map((elt: any) => {
            if (elt.shopId === shopId) {
                return elt.subRows;
            } else {
                return null;
            }
        });
        return associated.filter((el: any) => el)[0];
    }
    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };
    async function RedisPublishPeripheral() {
        let redisApiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/peripheral/redis`;
        for (let element of localShop) {
            try {
                mutate(
                    redisApiUrl,
                    await fetch(redisApiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify({
                            shopId: element,
                        }),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            }
                        })
                );
            } catch (e: any) {
                console.log(`${t("There's an error")}!`);
            }
        }
    }
    async function AssociatePosEditor() {
        toast.dismiss();
        let isError: boolean = false;
        let isData: boolean = false;
        let localData: any = [];
        if (isValidateButtonDisabled === false) {
            setIsValidateButtonDisabled(true);
            setLimit(1);

            for (let element of localShop) {
                let associated = checkIfPosEditorAssociated(element);
                let objectData: any = {};

                if (posEditor !== "") {
                    setPosEditor(activePosEditor);

                    let isActive = activePosEditor.find(
                        (element: any) =>
                            element.Id === selectedRowsPosEditor.Id
                    ).state;

                    if (
                        associated.findIndex(
                            (elt: any) => elt.Id === selectedRowsPosEditor.Id
                        ) === -1 &&
                        associated.length !== 0
                    ) {
                        isError = true;
                    } else if (
                        associated.findIndex(
                            (elt: any) =>
                                elt.Id === selectedRowsPosEditor.Id &&
                                elt.isActive !== isActive
                        ) !== -1 ||
                        associated.length === 0 ||
                        associated.findIndex(
                            (elt: any) =>
                                elt.Id === selectedRowsPosEditor.Id &&
                                elt.isActive === isActive
                        ) === -1
                    ) {
                        Object.assign(objectData, {
                            [selectedRowsPosEditor.Id]: {
                                posEditor:
                                    selectedRowsPosEditor[t("Pos Editor")],
                                codeComment:
                                    selectedRowsPosEditor["codeComment"],
                                note: selectedRowsPosEditor[t("Remark")],
                                isActive: activePosEditor.find(
                                    (element: any) =>
                                        element.Id === selectedRowsPosEditor.Id
                                ).state,
                                isArchived: selectedRowsPosEditor.isArchived,
                                shopId: element,
                                user: selectedRowsPosEditor.user,
                                versionServer:
                                    selectedRowsPosEditor.versionServer,
                                keyAuthentication:
                                    selectedRowsPosEditor.keyAuthentication,
                                apiEmail: selectedRowsPosEditor.apiEmail,
                                port: selectedRowsPosEditor.port,
                                server: selectedRowsPosEditor.server,
                                apiServer: selectedRowsPosEditor.apiServer,
                                setupId: selectedRowsPosEditor.setupId,
                                apiPassword: selectedRowsPosEditor.apiPassword,
                                modeOfSaleEmpId:
                                    selectedRowsPosEditor.modeOfSaleEmpId,
                                modeOfSaleLivId:
                                    selectedRowsPosEditor.modeOfSaleLivId,
                                modeOfSaleSPId:
                                    selectedRowsPosEditor.modeOfSaleSPId,
                                divisionPriceItems:
                                    selectedRowsPosEditor.divisionPriceItems,
                                divisionPriceOptions:
                                    selectedRowsPosEditor.divisionPriceOptions,
                                divisionTax: selectedRowsPosEditor.divisionTax,
                                layoutLabel: selectedRowsPosEditor.layoutLabel,
                                apiWebhook: selectedRowsPosEditor.apiWebhook,
                                happyHour: selectedRowsPosEditor?.happyHour,
                                happyHourState:
                                    selectedRowsPosEditor?.happyHourState,
                                tcposStoreId:
                                    selectedRowsPosEditor?.tcposStoreId,
                                tcposWebhookPort:
                                    selectedRowsPosEditor?.tcposWebhookPort,
                                tcposWebhookIPAddess:
                                    selectedRowsPosEditor?.tcposWebhookIPAddess,
                                tcposFranchiseSchema:
                                    selectedRowsPosEditor?.tcposFranchiseSchema,
                                tcposPaymentCash:
                                    selectedRowsPosEditor?.tcposPaymentCash,
                                tcposPaymentCreditCard:
                                    selectedRowsPosEditor?.tcposPaymentCreditCard,
                                tcposSetupDisk:
                                    selectedRowsPosEditor?.tcposSetupDisk,
                                tcposCodeCountry:
                                    selectedRowsPosEditor?.tcposCodeCountry,
                                tcposLangue: selectedRowsPosEditor?.tcposLangue,
                                tcposApiPort:
                                    selectedRowsPosEditor?.tcposApiPort,
                                tcposPartnerApiUrl:
                                    selectedRowsPosEditor?.tcposPartnerApiUrl,
                                tcposIp: selectedRowsPosEditor?.tcposIp,
                                tcposConnectionCheckTime:
                                    selectedRowsPosEditor?.tcposConnectionCheckTime,
                                tcposDeletingDataSate:
                                    selectedRowsPosEditor?.tcposDeletingDataSate,
                                codeRetryTable:
                                    selectedRowsPosEditor?.codeRetryTable,
                                maxRetry: selectedRowsPosEditor?.maxRetry,
                                retryDelai: selectedRowsPosEditor?.retryDelai,
                                checkStock: selectedRowsPosEditor?.checkStock,
                                orderValidationPreCheck:
                                    selectedRowsPosEditor?.orderValidationPreCheck,
                                timeoutAPI: selectedRowsPosEditor?.timeoutAPI,
                                automaticProductionLevel:
                                    selectedRowsPosEditor?.automaticProductionLevel,
                                tcposCorrTakeawayDisplayName:
                                    selectedRowsPosEditor?.tcposCorrTakeawayDisplayName,
                                tcposCorrTakeawayID:
                                    selectedRowsPosEditor?.tcposCorrTakeawayID,
                                tcposCorrOnSiteDisplayName:
                                    selectedRowsPosEditor?.tcposCorrOnSiteDisplayName,
                                tcposCorrOnSiteID:
                                    selectedRowsPosEditor?.tcposCorrOnSiteID,
                            },
                        });
                    }
                    let dataPosEditor = {
                        userId: userID,
                        shopId: [element],
                        franchiseId: franchiseID,
                        operatorId: operatorID,
                        uuidUser: uuidUser,
                        data: objectData,
                        affectation: true,
                    };

                    if (Object.keys(dataPosEditor.data).length !== 0) {
                        isData = true;
                    }
                    localData.push(
                        fetch(
                            `${process.env.REACT_APP_API_V2_URL}/settings/general/posEditor`,
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify(dataPosEditor),
                            }
                        ).then((response) => response.json())
                    );
                }
                try {
                    mutate(
                        `${process.env.REACT_APP_API_V2_URL}/settings/general/posEditor`,
                        await Promise.all(localData).then((result) => {
                            result.forEach((element: any) => {
                                if (element.error === true) setError(true);
                            });
                        })
                    );
                } catch (e) {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
            if (error === false) {
                if (isError && !isData) {
                    ErrorToast(
                        t(
                            "Please note that one or more Pos Editor could not be assigned. A store cannot have more than one Pos Editor setting."
                        )
                    );
                } else if (isData && isError) {
                    toast.success(
                        `${t(
                            "Pos Editor assignment was successfully assigned"
                        )!}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            theme: "colored",
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    ErrorToast(
                        t(
                            "Please note that one or more Pos Editor could not be assigned. A store cannot have more than one Pos Editor setting."
                        )
                    );
                    RedisPublishPeripheral();
                    setIsValidateButtonDisabled(false);
                    setLimit(2);
                } else {
                    toast.success(
                        `${t(
                            "Pos Editor assignment was successfully assigned"
                        )!}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            theme: "colored",
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                }
                RedisPublishPeripheral();
                setNumberActiveMultiShop(
                    numberActiveMultiShop +
                        selectedRows.length * localShop.length
                );

                setIsAssociateModalOpened(!isAssociateModalOpened);
                setIsAssociateModalClosed(!isAssociateModalClosed);
                setData(true);
                setGlobalDataIsUpdated(true);
                setIsValidateButtonDisabled(false);
            }
            setData(true);
        }
    }

    function CountIds() {
        let number = 0;
        idsLocalShop.forEach((el: any) => {
            selectedRows.forEach((v: any) => {
                if (v.Id === el) {
                    number = number + 1;
                }
            });
        });
        setIds(number);
    }
    function checkIfFiscalYearExistAndArchived() {
        let archived: any[] = [];
        if (rowsShop.length > 0) {
            rowsShop.forEach((element: any) => {
                if (element.archivedPosEditor !== undefined) {
                    element.archivedPosEditor.forEach((el: any) => {
                        archived.push(el.Id);
                    });
                }
            });
        }
        return archived;
    }
    React.useEffect(() => {
        let local: any = [];
        selectedShopsRows.forEach((el: any) => {
            if (el?.subRows !== undefined)
                el?.subRows.forEach((element: any) => {
                    local.push(element);
                });
        });
        setSelectedPosEditor(local);
        let notChanged: any = [];
        local.forEach((el: any) => {
            if (selectedRowsPosEditor.Id === el.Id) {
                let test: any = activePosEditor.find(
                    (element: any) => element.Id === el.Id
                );
                notChanged.push(test.state === el.isActive);
            }
        });
        setIsStateChanged(notChanged.includes(false));
    }, [selectedShopsRows, selectedRowsPosEditor, activePosEditor]);
    React.useEffect(() => {
        if (rowsShop[0] !== undefined) {
            if (rowsShop[0]?.subRows !== undefined) {
                localShop.length > 1
                    ? setPosEditor("")
                    : setPosEditor(
                          rowsShop[0]?.subRows[0] !== undefined
                              ? rowsShop[0]?.subRows[0]?.Id
                              : rowsShop[0].Id
                      );
            } else if (rowsShop[1]?.subRows !== undefined) {
                localShop.length > 1
                    ? setPosEditor("")
                    : setPosEditor(
                          rowsShop[1]?.subRows[1] !== undefined
                              ? rowsShop[1]?.subRows[1]?.Id
                              : rowsShop[0].Id
                      );
            }
        }
    }, [rowsShop, localShop]);
    React.useEffect(() => {
        if (isValidateClickedModal === true) {
            AssociatePosEditor();
        }
        // eslint-disable-next-line
    }, [isValidateClickedModal]);
    return (
        <StyledModal
            toggle={true}
            isOpen={isAssociateModalOpened!}
            centered
            className="modal-wrapper__clz"
            backdrop="static"
            fade={false}
            scrollable
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsAssociateModalOpened(!isAssociateModalOpened);
                    setIsAssociateModalClosed(!isAssociateModalClosed);
                    setData(true);
                }}
            >
                <span
                    className="text-uppercase fed_txt_AFloyaltyList"
                    style={{
                        fontFamily: "Segoe UI Semibold",
                        color: "#2b2828",
                        fontSize: "20px",
                    }}
                >
                    {t("List of Pos Editor")}
                </span>
            </ModalHeader>
            <ModalBody>
                <DynamicTable
                    url={url}
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    customSelect
                    setSelectedRows={setSelectedRows}
                    canResize
                    canMovedCheckboxLeftOnExpand
                    actionColumn={ActionColumn}
                    showGlobalFilter
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    elevationTable={1}
                    minHeight="500px"
                    maxHeight="500px"
                    name="associatePosEditor"
                />
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    className="fed_btn_AFcancel"
                    onClick={() => {
                        setIsAssociateModalOpened(!isAssociateModalOpened);
                        setIsAssociateModalClosed(!isAssociateModalClosed);
                        setData(true);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    variant="primary"
                    className="fed_btn_AFvalidate"
                    onClick={() => {
                        let archived = checkIfFiscalYearExistAndArchived();
                        CountIds();
                        if (posEditor === "" || posEditor === undefined) {
                            ErrorToast(
                                t(
                                    "Attention ! You must select least one Pos Editor"
                                )
                            );
                        } else if (isStateChanged) {
                            setIsModalOpened(!isModalOpened);
                        } else {
                            try {
                                setSelectedRow(selectedRows);

                                if (
                                    archived.includes(selectedRowsPosEditor.Id)
                                ) {
                                    setIsAssociatedPosEditor(true);
                                    setMessage(
                                        t(
                                            "Attention! One or more selected Pos Editor are already assigned and archived at the store level.\nWould you unarchive them?"
                                        )
                                    );
                                    // eslint-disable-next-line
                                    throw "Break";
                                }

                                AssociatePosEditor();
                            } catch (e) {
                                if (e !== "Break") throw e;
                            }
                        }
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
            {isModalOpened ? (
                <ConfirmationMessage
                    msg={t(
                        "Warning! This change will affect specific settings in the store(s).\n\nDo you want to confirm the modification?"
                    )}
                    setIsModalOpened={setIsModalOpened}
                    isModalOpened={isModalOpened}
                    setIsValidateClickedModal={setIsValidateClickedModal}
                />
            ) : null}
            {isAssociatedPosEditor ? (
                <ConfirmationUpdate
                    isModalOpened={isAssociatedPosEditor}
                    setIsModalOpened={setIsAssociatedPosEditor}
                    setIsValidateClickedModal={setIsValidateClickedModal}
                />
            ) : null}
        </StyledModal>
    );
}
