import React from "react";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next/";
import { motion } from "framer-motion/dist/framer-motion";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import useResource from "@hooks/useResource";

import { store as userStore } from "@components/VerticalLayout/store";
import PageTitle from "@components/Common/PageTitle";
import { getPathAuthorization } from "@components/VerticalLayout";

import CardMenu from "./CardMenu";
import { CARD_MENU_LIST } from "./cardMenuList";

import "./Cartestyle.css";

export default function ApplicationHome() {
    const { t } = useTranslation();
    const { oneShop, userID, franchiseID, shopID } = useSnapshot(userStore);

    const url = oneShop
        ? `${process.env.REACT_APP_API_V2_URL}/settings/general/application/counter?userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}`
        : `${process.env.REACT_APP_API_V2_URL}/settings/general/application/counter?userId=${userID}&franchiseId=${franchiseID}`;
    const { resourceData } = useResource(url);

    const numberKiosk = resourceData.kioskNumber.count;
    const numberOrb = resourceData.orbNumber.count;
    const numberSco = resourceData.scoNumber.count;
    const numberKds = resourceData.kdsNumber.count;

    return (
        <React.Fragment>
            <PageTitle title={"Applications"} />
            <div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 1 }}
                    className="page-content__clz  home-page-content__clz"
                >
                    <StyledBreadcrumb
                        items={[
                            {
                                item: "Settings".toUpperCase(),
                                link: `${process.env.REACT_APP_PORTAIL_URL}`,
                            },
                            {
                                item:
                                    localStorage
                                        .getItem("selectedAccount")
                                        ?.toUpperCase() || "",
                                link: oneShop
                                    ? "/setting/customer-account"
                                    : "/compte",
                            },
                            {
                                item: t("Application").toUpperCase(),
                                link: "#",
                            },
                        ]}
                    />
                    <div className="row p-5">
                        {CARD_MENU_LIST.map((cardMenu: any, index: any) => {
                            if (
                                getPathAuthorization(cardMenu.path).Visible ===
                                true
                            ) {
                                return (
                                    <CardMenu
                                        key={index}
                                        title={t(cardMenu.title).toUpperCase()}
                                        Icon={cardMenu.Icon}
                                        description={t(cardMenu.description)}
                                        path={cardMenu.path}
                                        numberApplication={
                                            cardMenu.title === "KIOSK"
                                                ? numberKiosk
                                                : cardMenu.title === "KDS"
                                                ? numberKds
                                                : cardMenu.title === "SCO"
                                                ? numberSco
                                                : cardMenu.title === "ORB"
                                                ? numberOrb
                                                : 0
                                        }
                                    />
                                );
                            } else {
                                return null;
                            }
                        })}
                    </div>
                </motion.div>
            </div>
        </React.Fragment>
    );
}
