import React from "react";
import { useSnapshot } from "valtio";
import { CheckIcon } from "@aureskonnect/react-ui";
import classNames from "classnames";

import {
    generalConfigStore,
    designStore,
    setGeneralTransition,
    setIsAnimationIn,
    computedStore,
    setItems,
    setIsSpecificTransitionChosen,
    setChosenTransition,
} from "@store";
import { t } from "i18next";

type PropsType = {
    transition: string;
};

export function TransitionCardWrapper({ transition }: PropsType): JSX.Element {
    const { activePageName } = useSnapshot(designStore);
    const { items } = useSnapshot(computedStore);
    const {
        transitions,
        generalTransition,
        project: {
            template: {
                pages: { ways },
            },
        },
    } = useSnapshot(generalConfigStore);

    function handleTransitionCardOnClickEvent(transition: string) {
        setIsAnimationIn(false);
        setIsSpecificTransitionChosen(true);
        setGeneralTransition({ prettyName: "", timeout: 0, className: "" });
        setChosenTransition(transitions[transition]);
        setItems({
            ...items,
            ways: {
                ...ways,
                [activePageName]: {
                    ...ways[activePageName],
                    animation: transition,
                },
            },
        });

        setTimeout(() => {
            setIsAnimationIn(true);
        }, 1000);
    }

    const chosenTransitionCondition =
        generalTransition.prettyName !== ""
            ? generalTransition.prettyName ===
              transitions[transition].prettyName
            : transition ===
              (ways[activePageName] as
                  | PageType
                  | PaymentPageType
                  | ConsommationPageType).animation;

    return (
        <div
            style={{
                height: "230px",
                width: "190px",
            }}
            className="d-grid__clz justify-content-center"
        >
            <div
                className="d-flex justify-content-center"
                onClick={() => handleTransitionCardOnClickEvent(transition)}
            >
                <div
                    className={classNames("d-grid__clz", {
                        "align-items-center": !chosenTransitionCondition,
                    })}
                    style={{
                        height: "160px",
                        width: "160px",
                        border: "1px solid #CBCBCB",
                        borderRadius: "17px",
                        borderBottom:
                            chosenTransitionCondition === true
                                ? "10px solid blue"
                                : "1px solid #BCBCBC",

                        gridTemplateColumns:
                            chosenTransitionCondition === true
                                ? "0.1fr auto"
                                : "",
                    }}
                >
                    {chosenTransitionCondition === true ? (
                        <CheckIcon
                            className="mt-2 ml-2"
                            height={30}
                            width={30}
                            style={{
                                fill: "blue",
                                cursor: "pointer",
                            }}
                        />
                    ) : null}
                    <div
                        className={classNames("d-flex align-items-center", {
                            "justify-content-center ": !chosenTransitionCondition,
                        })}
                    >
                        <img
                            alt=""
                            src="./images/transition.png"
                            style={{
                                height: "90px",
                                width: "90px",
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
                <span
                    className="text-center cursor__clz"
                    style={{ fontSize: "14px" }}
                >
                    {t(transitions[transition].prettyName)}
                </span>
            </div>
        </div>
    );
}
