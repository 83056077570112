import React from "react";
import { useTranslation } from "react-i18next";
import { CheckIcon } from "@aureskonnect/react-ui";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";
import classnames from "classnames";

import { store } from "../../../../components/VerticalLayout/store";
import {
    store as saleStore,
    setLocalShop,
    setIsAssociateModalOpened,
    setNumberActiveMultiShop,
    setNumberArchiveMultiShop,
    setIdsLocalShop,
    setShopsFidelityData,
    setRowsShop,
    setAssociatedFidelity,
    setGlobalDataIsUpdated,
    setSelectedFidelity,
} from "../store";

import { ActionColumn } from "./ActionColumn";
import { ConfirmationActiveFidelity } from "../ConfirmationActiveFidelity";
import { FidelityAssociateModal } from "./FidelityAssociateModal";
import { CustomIcons } from "./CustomIcons";
import HistoryModal from "../HistoryModal";
import InformationFidelity from "../InformationFidelity";
import { removeArrayOfObjectsDuplicates } from "@helpers/general";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { SupportCodeModal } from "@pages/Applications/DeviceAssociateModal/MoneticsApplicationForm/SupportCodeModal";

import "./index.css";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import FidelityForm from "../FidelityFranchise/FidelityForm";
import ErrorToast from "@components/Common/ErrorTost";

export default function ShopsFidelity({
    isFidelityArchived,
    setIsFidelityArchived,
    setLimit,
}: any) {
    const { oneShop, userID, franchiseID } = useSnapshot(store);
    const {
        isAssociateModalOpened,
        globalDataIsUpdated,
        isConsultModalOpened,
        modify,
    } = useSnapshot(saleStore);

    const { t } = useTranslation();
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [isShopSelected, setIsShopSelected] = React.useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isConsult, setIsConsult] = React.useState<boolean>(false);

    const [isHistoryModalOpened, setIsHistoryModalOpened] = React.useState<
        boolean
    >(false);

    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [isModalOpenedSupport, setIsModalOpenedSupport] = React.useState<
        boolean
    >(false);
    const [codeSupport, setCodeSupport] = React.useState<string>("");
    const [isValidCode, setIsValidCode] = React.useState(false);
    const [dataTable, setDataTable] = React.useState<any>({});
    const [isAddFidelityClicked, setIsAddFidelityClicked] = React.useState<
        boolean
    >(false);
    const [isAddNewFidelityButtonClicked] = React.useState(false);
    const [fidelityChoiceIndex, setFidelityChoiceIndex] = React.useState<
        number
    >(0);
    const [fidelity, setFidelity] = React.useState<any>([]);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    const urlArchive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity?userId=${userID}&franchiseId=${franchiseID}&uuidUser=${uuidUser}`;

    const urlActive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity?userId=${userID}&franchiseId=${franchiseID}&type=activated&uuidUser=${uuidUser}`;

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 99,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setDataIsUpdated={setGlobalDataIsUpdated}
                    setIsHistoryModalOpened={setIsHistoryModalOpened}
                    isHistoryModalOpened={isHistoryModalOpened}
                    setSelectedRows={setSelectedRows}
                    isFidelityArchived={isFidelityArchived}
                    selectedRows={selectedRows}
                    setIsModalOpenedSupport={setIsModalOpenedSupport}
                    setIsConsult={setIsConsult}
                    setIsAddFidelityClicked={setIsAddFidelityClicked}
                    setFidelity={setFidelity}
                />
            ),
            disableFilter: false,
        },

        {
            indexOFColumn: 5,
            columnName: t("Centralized loyalty"),
            customJsx: (e: any) => (
                <span>
                    {" "}
                    {e.selectedRow[t("State")] === true && (
                        <CheckIcon
                            className="mt-1 ml-1 cmn_chk_selectButton"
                            height={30}
                            width={30}
                        />
                    )}
                </span>
            ),
            filterName: t("Centralized loyalty"),
            canFilter: true,
        },
    ];

    React.useEffect(() => {
        let localShop: string[] = [];
        let idsLocalShop: string[] = [];
        let associatedFidelity: any[] = [];

        Object.values(selectedRows).forEach((el: any) => {
            localShop.push(el.shopId);
            if (el[t("Shop")] !== "" && el[t("Shop")] !== undefined) {
                for (let i in el.subRows) {
                    idsLocalShop.push(el.subRows[i].Id);
                    associatedFidelity.push(el.subRows[i]);
                }
            }
        });

        if (associatedFidelity.length > 0) {
            setIsShopSelected(false);
            localShop = [];
            ErrorToast(
                t(
                    "Attention,a shop cannot have more than one fidelity settings"
                )
            );
        } else {
        }
        setAssociatedFidelity(associatedFidelity);
        setRowsShop(selectedRows);
        setIdsLocalShop(idsLocalShop);
        setLocalShop(localShop);
        setIsShopSelected(localShop.length !== 0);
        setShopsFidelityData(dataTable);
    }, [selectedRows, dataTable, t]);

    React.useEffect(() => {
        let localArchived: number = 0;
        let localActive: number = 0;

        if (selectedRows.length > 0) {
            selectedRows.forEach((element: any) => {
                if (element?.Boutique !== undefined) {
                    localActive += element?.FidelityNumber.activated;

                    localArchived += element?.FidelityNumber.archived;
                }
            });
            if (selectedRows.filter((el: any) => el.Boutique).length > 0) {
                setNumberActiveMultiShop(localActive);

                setNumberArchiveMultiShop(localArchived);
            } else {
                setNumberActiveMultiShop(
                    dataTable.othersData !== undefined
                        ? dataTable.othersData.activated
                        : 0
                );

                setNumberArchiveMultiShop(
                    dataTable.othersData !== undefined
                        ? dataTable.othersData.archived
                        : 0
                );
            }
        } else {
            setNumberActiveMultiShop(
                dataTable.othersData !== undefined
                    ? dataTable.othersData.activated
                    : 0
            );

            setNumberArchiveMultiShop(
                dataTable.othersData !== undefined
                    ? dataTable.othersData.archived
                    : 0
            );
        }
    }, [dataTable.othersData, selectedRows, dataTable]);

    React.useEffect(() => {
        let array: any = [];
        selectedRows.forEach((element: any) => {
            if (element[t("Shop")] !== undefined) {
                element.subRows.forEach((el: any) => {
                    array.push({ [el.shopId]: el.Id });
                });
            } else {
                array.push({ [element.shopId]: element.Id });
            }
        });

        setSelectedFidelity(removeArrayOfObjectsDuplicates(array));
    }, [selectedRows, t]);

    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: oneShop ? "650px" : "600px",
                    gridTemplateColumns: isAddFidelityClicked
                        ? "2fr 1fr "
                        : "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    url={isFidelityArchived ? urlArchive : urlActive}
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    setData={setDataTable}
                    canExpand
                    canSelect
                    customSelect
                    setSelectedRows={setSelectedRows}
                    customJsxSideFilterButton={
                        <React.Fragment>
                            {modify === true ? (
                                <>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <CustomMainColorButton
                                            className={`${classnames(
                                                "w-100 ml-3 fed_btn_BTassignLoyalty",
                                                {
                                                    "not-allowed-icon__clz": isFidelityArchived,
                                                }
                                            )}`}
                                            rounded
                                            variant="primary"
                                            disabled={
                                                isShopSelected ? false : true
                                            }
                                            onClick={() => {
                                                setIsAssociateModalOpened(true);
                                                setLocalFilterActive(false);
                                            }}
                                        >
                                            {t("Affect fidelity")}
                                        </CustomMainColorButton>
                                        <div></div>
                                    </div>
                                </>
                            ) : null}
                            <ActionColumn
                                setIsHistoryModalOpened={
                                    setIsHistoryModalOpened
                                }
                                isHistoryModalOpened={isHistoryModalOpened}
                                selectedRows={selectedRows}
                                setDataIsUpdated={setGlobalDataIsUpdated}
                                isFidelityArchived={isFidelityArchived}
                                setIsFidelityArchived={setIsFidelityArchived}
                                setLimit={setLimit}
                            />
                        </React.Fragment>
                    }
                    canResize
                    showGlobalFilter
                    showFilter
                    canMovedCheckboxLeftOnExpand
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    actionColumn={() => <></>}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={
                        setGlobalDataIsUpdated as
                            | React.Dispatch<
                                  React.SetStateAction<number | boolean>
                              >
                            | undefined
                    }
                    dataIsUpdated={globalDataIsUpdated}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="Fidelity"
                />
                {isAddFidelityClicked ? (
                    <FidelityForm
                        isAddFidelityClicked={isAddFidelityClicked}
                        setIsAddFidelityClicked={setIsAddFidelityClicked!}
                        setDataIsUpdated={setGlobalDataIsUpdated}
                        dataFidelity={fidelity}
                        isAddNewFidelityButtonClicked={
                            isAddNewFidelityButtonClicked
                        }
                        setDataFidelity={setFidelity}
                        setLocalFilterActive={setLocalFilterActive}
                        fidelityChoiceIndex={fidelityChoiceIndex}
                        setFidelityChoiceIndex={setFidelityChoiceIndex}
                        setIsConsult={setIsConsult}
                        isConsult={isConsult}
                    />
                ) : null}
            </div>

            <ConfirmationActiveFidelity
                setIsModalOpened={setIsModalOpened}
                isModalOpened={isModalOpened}
            />
            {isHistoryModalOpened ? (
                <HistoryModal
                    setIsModalOpened={setIsHistoryModalOpened}
                    isModalOpened={isHistoryModalOpened}
                    setDataUpdated={setGlobalDataIsUpdated}
                />
            ) : null}
            {isAssociateModalOpened ? (
                <FidelityAssociateModal
                    setData={setGlobalDataIsUpdated}
                    selectedShopsRows={selectedRows}
                    setLimit={setLimit}
                />
            ) : null}

            {isConsultModalOpened ? (
                <InformationFidelity
                    selectedShopsRows={selectedRows}
                    setGlobalDataIsUpdated={setGlobalDataIsUpdated}
                />
            ) : null}

            {isModalOpenedSupport ? (
                <SupportCodeModal
                    isModalOpened={isModalOpenedSupport}
                    setIsModalOpened={setIsModalOpenedSupport}
                    codeSupport={codeSupport}
                    setCodeSupport={setCodeSupport}
                    isValidCode={isValidCode}
                    setIsValidCode={setIsValidCode}
                />
            ) : null}
        </React.Fragment>
    );
}
