import React from "react";
import { useTranslation } from "react-i18next";

type DimensionsType = {
    e: any;
};
export default function Dimensions({ e }: DimensionsType) {
       const { t } = useTranslation();
 const [width, setWidth] = React.useState<number>(0);
 const [height, setHeight] = React.useState<number>(0);
    
let imageDimensionsWidth:number=0
let imageDimensionsHeight:number=0

    let imageUrl = e.selectedRow[t("Image")];
    
 

    let img = document.createElement("img");
    img.src = imageUrl;
    img.onload = function () {
        imageDimensionsWidth = img.width;
        imageDimensionsHeight = img.height;
        setWidth(imageDimensionsWidth);
        setHeight(imageDimensionsHeight);

    };

    return (
        <React.Fragment>
             {e.selectedRow?.subRows === undefined ? (
                 <div>
            {width}*{height}
            </div>
             ):null}
        </React.Fragment>
    );
}
