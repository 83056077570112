import React from "react";
import { useSnapshot } from "valtio";

import { designStore, generalConfigStore } from "@store";

// import { truncateString } from "@helpers/general";

import { ImageContent } from "@components/Common/Design/Preview/ImageContent";
import { UsualEmailKeyboard } from "@components/Common/UsualEmailKeyboard";
import {
    EMAIL_CONFIRMATION_PRINTER_OPTIONS_TAB,
    WITH_EMAIL_PRINTER_OPTIONS_TAB,
} from "@constants/index";

export function MainContentPrinterOptionsPreview(): JSX.Element {
    const { project } = useSnapshot(generalConfigStore);
    const {
        activeTabPrinterOptions,
        activeTabPrinterOptionsSetupOptions,
    } = useSnapshot(designStore);

    const itemsPrinterOptions = (project.template.pages.ways[
        "printerOptions"
    ] as PrinterOptionsPageType).items;
    return (
        <React.Fragment>
            {activeTabPrinterOptionsSetupOptions !==
            EMAIL_CONFIRMATION_PRINTER_OPTIONS_TAB ? (
                <div
                    className="d-flex flex-column align-items-center"
                    style={{
                        gap: "20px",
                    }}
                >
                    <div
                        className="d-flex justify-content-center align-items-center p-2"
                        style={{
                            gap: "20px",
                            width: "450px",
                        }}
                    >
                        {Object.values(itemsPrinterOptions)
                            .filter(
                                (x: any) =>
                                    x.withmail !==
                                    (activeTabPrinterOptions ===
                                    WITH_EMAIL_PRINTER_OPTIONS_TAB
                                        ? false
                                        : true)
                            )
                            .map((value: any, index: number) => {
                                return (
                                    <>
                                        <ImageContent
                                            item={value}
                                            key={index}
                                            activeMainContentItemsLength={
                                                Object.values(
                                                    itemsPrinterOptions
                                                ).length
                                            }
                                        />
                                    </>
                                );
                            })}
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        width: "100%",
                        height: "267px",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                    }}
                >
                    <UsualEmailKeyboard />
                </div>
            )}
        </React.Fragment>
    );
}
