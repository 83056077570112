import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    CrossIcon,
    StyledLabel,
    StyledTextInput,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import dayjs from "dayjs";
import { mutate } from "swr";

import { TimePicker, ConfigProvider } from "antd";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import classnames from "classnames";
import { useSnapshot } from "valtio";
import { v4 as uuid } from "uuid";
import locale from "antd/es/locale/fr_FR";
import { Moment } from "moment";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { resetAllPeripherals } from "@pages/Applications/DeviceAssociateModal/store";
import { store } from "@components/VerticalLayout/store";
import {
    store as service,
    setNumberActive,
    setDayOfTheWeek,
    setIsConfirmationModal,
    setIsValidateClicked,
    setIsActionsClicked,
    setActions,
    setIsEdited,
    setIsPlusIconClicked,
} from "../store";

import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import MultiCustomCreatableSelection from "@components/Common/MultiCustomCreatableSelection";
import {
    CustomCardBody,
    CustomCardFooter,
    CustomCardHeader,
} from "@components/Common/CustomCardStyled";
import ServiceDate from "./ServiceDate";
import { ConfirmationModificationModal } from "./ConfirmationModificationModal";
import { ConfirmationAssociationBoutiqueModal } from "./ConfirmationAssociationBoutiqueModal";
import { OverLappedMessage } from "./OverLappedMessage";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "./index.css";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";

type ModeOfSaleFormType = {
    setIsAddServiceClicked: Function;
    isAddServiceClicked: boolean;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<boolean | number>>;
    rowData: React.SetStateAction<any>;
    setDataService: Function;
    setLocalFilterActive: Function;
    allData: any;
    isAddNewLogoButtonClicked: boolean;
    isConsult: boolean;
    setIsConsult: Function;
};

export default function ServiceForm({
    setIsAddServiceClicked,
    isAddServiceClicked,
    setDataIsUpdated,
    rowData,
    setDataService,
    setLocalFilterActive,
    allData,
    isAddNewLogoButtonClicked,
    setIsConsult,
    isConsult,
}: ModeOfSaleFormType) {
    const { t } = useTranslation();
    const { userID, franchiseID, shopID, oneShop, operatorID } = useSnapshot(
        store
    );
    const {
        numberActive,
        shopsServiceData,
        dayOfTheWeek,
        dataDesignationService,
        dataFranchiseService,
        listFranchiseArchived,
        servicesData,
        isActionsClicked,
        isEdited,
        isPlusIconClicked,
    } = useSnapshot(service);

    const [associated, setAssociated] = React.useState<any[]>([]);
    const [designation, setDesignation] = React.useState<string>("");
    const [tag, setTag] = React.useState<string>("");
    const [title, setTitle] = React.useState<string>("");

    const [inputDesignation, setInputDesignation] = React.useState<boolean>(
        false
    );
    dayjs.extend(customParseFormat);
    const [isModifiedServiceModal, setIsModifiedServiceModal] = React.useState<
        boolean
    >(false);
    const [isModalOverLapped, setIsModalOverLapped] = React.useState<boolean>(
        false
    );
    const [isSendingTag, setIsSendingTag] = React.useState<boolean>(false);
    const [dataEdited, setDataEdited] = React.useState<any>();
    const [activeDays, setActiveDays] = useState<any[]>([]);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [inputDate, setInputDate] = React.useState<boolean>(false);

    const [startTime, setStartTime] = useState<string | null>("");
    const [inputStartTime, setInputStartTime] = React.useState<boolean>(false);
    const [endTime, setEndTime] = useState<string | null>("");

    const [inputEndTime, setInputEndTime] = React.useState<boolean>(false);
    const shopId = oneShop ? shopID : "0";
    const [tagsData, setTagsData] = React.useState<any>([]);
    const [tags, setTags] = React.useState<any[]>([]);
    const [isNameExist, setIsNameExist] = React.useState<boolean>(false);
    const [
        isModifiedAssociateModal,
        setIsModifiedAssociateModal,
    ] = React.useState<boolean>(false);
    const [isChevauchement, setIsChevauchement] = React.useState<boolean>(
        false
    );
    const [
        isClickedBouttonValider,
        setIsClickedBouttonValider,
    ] = React.useState<boolean>(true);

    let localNames: string[] = [];
    let LocalNames = fetchDataName();
    let designationFranchise: string[] = [];
    let listServiceData: any[] = [];

    function listData() {
        let listService: any[] = [];
        servicesData.forEach((element: any) => {
            if (rowData.Id !== element.Id) {
                listService.push(element);
            }
        });
        return [...listService];
    }
    const format = "HH:mm";

    const AssociateService = React.useCallback(() => {
        let idsAssociated: string[] = [];
        shopsServiceData.forEach((element: any) => {
            element.subRows.forEach((el: any) => {
                if (el.Id === rowData.Id) idsAssociated.push(element.shopId);
            });
        });
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }, [rowData.Id, shopsServiceData]);

    function stateService() {
        let state: boolean = false;

        shopsServiceData.forEach((element: any) => {
            element.subRows.forEach((el: any) => {
                if (el.Id === rowData.Id) {
                    state = el.isActive;
                }
            });
        });
        return state;
    }
    const onChange = (timeString: string) => {
        setStartTime(timeString);
    };
    const onChangeTimeEnd = (timeString: string) => {
        setEndTime(timeString);
    };
    function fetchDataName() {
        if (dataDesignationService !== undefined) {
            dataDesignationService.forEach((el: any) => {
                if (rowData.length !== 0) {
                    if (rowData[t("Designation")] !== el) {
                        localNames.push(el);
                    }
                } else {
                    localNames.push(el);
                }
            });
        }

        return localNames;
    }
    designationFranchise = fetchDataDesignationService();
    let designationFranchiseArchivage: string[] = fetchDataNameFranchiseArchivage();
    function fetchDataDesignationService() {
        if (dataFranchiseService !== undefined) {
            Object.values(dataFranchiseService).forEach((element: any) => {
                designationFranchise.push(element[t("Designation")]);
            });
        }

        return designationFranchise;
    }
    const fetchDataIsExisteService = () => {
        let isExiste: boolean = false;
        if (allData.data !== undefined) {
            isExiste = allData.data.find(
                (el: any) =>
                    el[t("Designation")] === designation &&
                    el[t("Start hour")] === startTime &&
                    el[t("End hour")] === endTime &&
                    el[t("Day of the week")] === dayOfTheWeek
            );
        }

        return isExiste;
    };

    function fetchDataNameFranchiseArchivage() {
        let localNamesFranchise: string[] = [];
        if (listFranchiseArchived !== undefined) {
            listFranchiseArchived.forEach((el: any) => {
                localNamesFranchise.push(el);
            });
        }
        return localNamesFranchise;
    }

    async function addService() {
        let isServiceExist: boolean = fetchDataIsExisteService();
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/services`;
        if (isChevauchement && oneShop) {
            setIsModalOverLapped(true);
        } else {
            let data = {
                userId: userID,
                shopId: oneShop ? [shopId, "0"] : [shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                adding: true,
                data: {
                    [uuid()]: {
                        designation: designation,
                        tag: tagsData.join(","),
                        isArchived: false,
                        heure_début: startTime,
                        heure_fin: endTime,
                        shopId: shopId,
                        jour_de_la_semaine: dayOfTheWeek,
                        activeDays: activeDays,
                    },
                },
            };
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
            };
            if (isServiceExist && oneShop) {
                setIsModifiedAssociateModal(true);
            } else if (
                designationFranchiseArchivage.includes(designation) &&
                oneShop
            ) {
                setIsConfirmationModal(true);
            } else {
                try {
                    mutate(
                        apiUrlAdd,
                        await fetch(apiUrlAdd, requestOptions)
                            .then((response) => response.json())
                            .then((data) => {
                                if (data.error) {
                                    throw Error("error");
                                }
                                toast.success(
                                    `${t("Service is successfully added")!}`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        theme: "colored",
                                        icon: (
                                            <CheckboxIcon
                                                height={25}
                                                width={25}
                                                fill="white"
                                            />
                                        ),
                                    }
                                );
                                setDesignation("");
                                setIsSendingTag(true);
                                setTagsData([]);
                                setTag("");
                                setDataIsUpdated!(true);
                                setIsEdited(false);
                                resetAllPeripherals();
                                setLocalFilterActive(false);
                                setNumberActive(numberActive + 1);
                                setActiveDays([]);
                                setDataService({});
                                setStartTime("");
                                setEndTime("");
                                setDayOfTheWeek("");
                                setIsChevauchement(false);
                                clearData();
                                clearMultiCustomSelectionData();
                                setDataService({});
                                handleGetServicesTagsButtonOnClickEvent();
                            })
                    );
                } catch (e) {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        }
    }

    async function editService() {
        setIsValidateClicked(false);
        let dataAssociate: any[] = [];
        listServiceData = listData();
        const apiUrlupdate = `${process.env.REACT_APP_API_V2_URL}/settings/general/services`;
        if (isChevauchement && oneShop) {
            setIsModalOverLapped(true);
        } else {
            let dataConfig = {
                userId: userID,
                shopId: [shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,

                data: {
                    [rowData.Id]: {
                        designation: designation,
                        tag: tagsData.join(","),
                        isArchived: rowData.isArchived,
                        shopId: shopId,
                        heure_début: startTime,
                        heure_fin: endTime,
                        isActive: stateService(),
                        jour_de_la_semaine: dayOfTheWeek,
                        activeDays: activeDays,
                    },
                },
            };
            setDataEdited(dataConfig.data);

            if (isChevauchement && oneShop) {
                setIsModalOverLapped(true);
            } else {
                try {
                    fetch(apiUrlupdate, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify(dataConfig),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            if (!oneShop) {
                                dataAssociate = AssociateService();
                            }
                            toast.success(
                                !(dataAssociate.length > 0)
                                    ? `${t(
                                          "The service is changed successfully"
                                      )!}`
                                    : `${t(
                                          "The service of the franchise is changed successfully"
                                      )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );

                            if (dataAssociate.length > 0) {
                                setIsModifiedServiceModal(true);
                            } else {
                                setDataIsUpdated!(true);
                                setIsSendingTag(true);
                                setIsAddServiceClicked(!isAddServiceClicked);
                                setIsEdited(false);
                                setTagsData([]);
                                setStartTime("");
                                setEndTime("");
                                setActiveDays([]);
                                setIsChevauchement(false);
                                clearData();
                                clearMultiCustomSelectionData();
                                setDataService({});
                            }
                            setIsValidateClicked(true);
                            setDataIsUpdated!(true);
                        });
                } catch (e) {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        }
    }
    async function handleGetServicesTagsButtonOnClickEvent() {
        const newList: any[] = [];
        try {
            await Promise.all([
                fetch(
                    `${
                        process.env.REACT_APP_API_V2_URL
                    }/settings/general/tag?userId=${userID}&shopId=${"0"}&franchiseId=${franchiseID}`
                ).then((value) => value.json()),
            ]).then((result) => {
                for (let element of result[0].data) {
                    newList.push(element.Designation);
                }

                setTags(newList);
            });
        } catch (error) {
            console.log(error);
        }
    }
    const formatCreateLabel = (inputValue: any) => (
        <span>
            {inputValue} ({t("New tag")})
        </span>
    );
    useEffect(() => {
        handleGetServicesTagsButtonOnClickEvent();
        // eslint-disable-next-line
    }, [tag]);

    useEffect(() => {
        // eslint-disable-next-line
        listServiceData = listData();
        listServiceData.forEach((element: any) => {
            activeDays.forEach((day: number) => {
                if (element.activeDays.includes(day)) {
                    if (
                        startTime !== "" &&
                        endTime !== "" &&
                        startTime !== null &&
                        endTime !== null
                    ) {
                        if (
                            (startTime > element[t("Start hour")] &&
                                startTime < element[t("End hour")]) ||
                            (endTime > element[t("Start hour")] &&
                                endTime < element[t("End hour")]) ||
                            startTime === endTime ||
                            (startTime === element[t("Start hour")] &&
                                endTime === element[t("End hour")]) ||
                            startTime === element[t("Start hour")]
                        ) {
                            setIsChevauchement(true);
                        }
                    }
                }
            });
        });
    }, [startTime, endTime, activeDays, listData()]);

    useEffect(() => {
        if (rowData.Id) {
            setTitle(t(isConsult ? "View a service" : "Modify a service"));
            setDesignation(rowData[t("Designation")]);
            setTag(rowData?.tag);
            setTagsData(rowData.tag.split(","));
            setStartTime(rowData[t("Start hour")]);
            setEndTime(rowData[t("End hour")]);
            setActiveDays(rowData?.activeDays);
            setDayOfTheWeek(rowData[t("Day of the week")]);
        } else {
            setDesignation("");
            setTitle(t("Add a service"));
            setIsEdited(false);
            setTag("");
            setStartTime("");
            setEndTime("");
            setActiveDays([]);
            setTagsData([]);
            setDayOfTheWeek("");
            setIsClickedBouttonValider(true);
        }

        setAssociated(AssociateService);
        // eslint-disable-next-line
    }, [rowData.Id, t, isAddNewLogoButtonClicked]);

    useEffect(() => {
        if (Object.values(rowData).length !== 0) {
            if (rowData[t("Designation")] !== "") {
                setInputDesignation(false);
                setIsNameExist(false);
            }
            if (rowData[t("Start hour")] !== "") {
                setInputStartTime(false);
            }
            if (rowData[t("End hour")] !== "") {
                setInputEndTime(false);
            }
            if (rowData.activeDays.length !== 0) {
                setInputDate(false);
            }
        }
    }, [rowData, t]);

    useEffect(() => {
        clearData();
        clearMultiCustomSelectionData();
    }, [isAddServiceClicked, rowData.Id]);
    React.useEffect(() => {
        if (isPlusIconClicked) {
            setIsPlusIconClicked(false);
            setInputDesignation(false);
            setIsNameExist(false);
            setInputStartTime(false);
            setInputEndTime(false);
            setInputDate(false);
        }
    }, [isPlusIconClicked]);
    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader
                    className="d-flex align-items-center justify-content-between"
                    style={{ maxHeight: "76px" }}
                >
                    <h5 className="pt-3 srv_txt_FRtitleFormService">{title}</h5>
                    <CrossIcon
                        className="icon-close-notification__clz srv_icn_FRcloseForm"
                        height={13}
                        width={13}
                        onClick={() => {
                            setIsConsult(false);
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsEdited(false);
                                    setDataService({});
                                    setIsChevauchement(false);
                                    setIsAddServiceClicked!(
                                        !isAddServiceClicked
                                    );
                                    setIsEdited(false);
                                    clearData();
                                    clearMultiCustomSelectionData();
                                    setDataIsUpdated!(true);
                                });
                            } else {
                                setDataService({});
                                setIsChevauchement(false);
                                setIsAddServiceClicked!(!isAddServiceClicked);
                                setIsEdited(false);
                                clearData();
                                clearMultiCustomSelectionData();
                                setDataIsUpdated!(true);
                            }
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className={`pl-3 pr-5 ${isConsult ? "pe-none__clz" : ""}`}
                    style={{
                        maxHeight: "470px",
                        overflowY: " auto",
                    }}
                >
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Designation")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("srv_inp_FRdesignation", {
                                input__clz: inputDesignation,
                                "not-allowed-input__clz":
                                    oneShop && rowData.Id !== undefined,
                            })}
                            autocomplete="off"
                            name="designation"
                            onChange={(e: any) => {
                                if (
                                    LocalNames.includes(e.target.value) &&
                                    oneShop
                                ) {
                                    setIsNameExist(true);
                                } else {
                                    setIsNameExist(false);
                                }

                                setDesignation(e.target.value.trim());

                                setIsEdited(true);
                                setInputDesignation(false);
                                setIsClickedBouttonValider(true);
                            }}
                            value={designation}
                            placeholder={t("Write")}
                            type="text"
                            autoFocus={!oneShop}
                        />
                        {inputDesignation ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a designation")}
                            </div>
                        ) : null}
                        {isNameExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("The name is already exists")!}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <span className="d-flex align-item-between p-2">
                            <div className="mr-2">
                                <StyledLabel
                                    htmlFor="example-input"
                                    className="required__clz mt-3"
                                >
                                    {t("Start time")}
                                </StyledLabel>
                                <ConfigProvider locale={locale}>
                                    <TimePicker
                                        onChange={(
                                            time: Moment | null,
                                            e: string
                                        ) => {
                                            onChange(e);
                                            setIsClickedBouttonValider(true);
                                            setIsEdited(true);
                                            setInputStartTime(false);
                                        }}
                                        format="HH:mm"
                                        style={{ width: 193 }}
                                        className={classnames(
                                            "srv_drp_FRstartHour",
                                            {
                                                input__clz: inputStartTime,
                                                "not-allowed-icon__clz":
                                                    oneShop &&
                                                    rowData.Id !== undefined,
                                            }
                                        )}
                                        value={
                                            startTime !== ""
                                                ? (dayjs(startTime, format) as
                                                      | Moment
                                                      | null
                                                      | undefined)
                                                : null
                                        }
                                    />
                                </ConfigProvider>

                                {inputStartTime ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t("Please choose a time")}
                                    </div>
                                ) : null}
                            </div>
                            <div className="ml-5">
                                <StyledLabel
                                    htmlFor="example-input"
                                    className="required__clz mt-3"
                                >
                                    {t("End time")}
                                </StyledLabel>
                                <ConfigProvider locale={locale}>
                                    <TimePicker
                                        onChange={(
                                            time: Moment | null,
                                            e: string
                                        ) => {
                                            onChangeTimeEnd(e);
                                            setIsClickedBouttonValider(true);
                                            setIsEdited(true);
                                            setInputEndTime(false);
                                        }}
                                        format="HH:mm"
                                        style={{ width: 193 }}
                                        className={classnames(
                                            "srv_drp_FRendTime",
                                            {
                                                input__clz: inputEndTime,
                                                "not-allowed-icon__clz":
                                                    oneShop &&
                                                    rowData.Id !== undefined,
                                            }
                                        )}
                                        value={
                                            endTime !== ""
                                                ? (dayjs(endTime, format) as
                                                      | Moment
                                                      | null
                                                      | undefined)
                                                : null
                                        }
                                    />
                                </ConfigProvider>
                                {inputEndTime ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t("Please choose a time")}
                                    </div>
                                ) : null}
                            </div>
                        </span>
                    </AvGroup>

                    <StyledLabel className="mt-3 required__clz">
                        {t("Day of the week")}
                    </StyledLabel>
                    <ServiceDate
                        activeDays={activeDays}
                        setActiveDays={setActiveDays}
                        setInputDate={setInputDate}
                        setIsEdited={setIsEdited}
                        setIsChevauchement={setIsChevauchement}
                        setIsClickedBouttonValider={setIsClickedBouttonValider}
                        inputDate={inputDate}
                        rowData={rowData}
                    />
                    {inputDate ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("Please choose a day")}
                        </div>
                    ) : null}
                    <AvGroup>
                        <StyledLabel htmlFor="text" className="mt-3">
                            {t("Tag")}
                        </StyledLabel>

                        <MultiCustomCreatableSelection
                            onDelete={() => {
                                setIsEdited(true);
                            }}
                            isSendingTag={isSendingTag}
                            setResult={setTagsData}
                            result={tagsData}
                            data={tags}
                            onChange={(e: any) => {
                                setIsEdited(true);
                                setIsClickedBouttonValider(true);
                            }}
                            placeholder={`${t("Select")}…`}
                            value={t("Choose")}
                            name="tag"
                            className="srv_inp_FRtags"
                            noOptionsMessage={() => t("No options")}
                            onCreateOption={(e: any) => {
                                setIsEdited(true);
                            }}
                            formatCreateLabel={formatCreateLabel}
                        />
                        <div className="d-flex flex-wrap"></div>
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2 srv_btn_FRcancel cmn_btn_cancel"
                        variant="light"
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsAddServiceClicked(
                                        !isAddServiceClicked
                                    );
                                    setDataIsUpdated!(true);
                                });
                            } else {
                                setIsAddServiceClicked(!isAddServiceClicked);
                                setDataIsUpdated!(true);
                            }
                            setIsConsult(false);
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        disabled={!isClickedBouttonValider}
                        variant="primary"
                        className={`srv_btn_FRvalidate cmn_btn_validate ${classnames(
                            { "not-allowed-input-vr__clz": isConsult }
                        )}`}
                        onClick={() => {
                            if (designation === "") setInputDesignation(true);
                            if (startTime === "") setInputStartTime(true);
                            if (endTime === "") setInputEndTime(true);
                            if (activeDays.length === 0) setInputDate(true);
                            else if (
                                (isEdited &&
                                    rowData[t("Designation")] === designation &&
                                    rowData[t("Start time")] === startTime &&
                                    rowData[t("End time")] === endTime &&
                                    rowData[t("Jour de la semaine")] ===
                                        dayOfTheWeek &&
                                    rowData.tag === tagsData.join(",")) ||
                                !isEdited
                            ) {
                                setIsModalOpened(true);
                            } else if (
                                designation !== "" &&
                                startTime !== "" &&
                                endTime !== "" &&
                                activeDays.length !== 0 &&
                                !isNameExist &&
                                rowData.Id !== undefined
                            ) {
                                editService();
                                setIsClickedBouttonValider(false);
                            } else if (
                                designation !== "" &&
                                startTime !== "" &&
                                endTime !== "" &&
                                activeDays.length !== 0 &&
                                !isNameExist
                            ) {
                                addService();
                                setIsClickedBouttonValider(false);
                            }
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            {isModifiedServiceModal ? (
                <ConfirmationModificationModal
                    isModalOpened={isModifiedServiceModal}
                    setIsModalOpened={setIsModifiedServiceModal}
                    setIsAddServiceClicked={setIsAddServiceClicked}
                    dataServices={dataEdited}
                    isAddServiceClicked={isAddServiceClicked}
                    shopId={associated}
                    setDataIsUpdated={setDataIsUpdated}
                    setIsClickedBouttonValider={setIsClickedBouttonValider}
                />
            ) : null}
            <ConfirmationEditMessage
                setDataIsUpdated={setDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddServiceClicked}
                setIsAddClicked={setIsAddServiceClicked}
                setEditedData={setDataService}
            />
            {isModifiedAssociateModal && (
                <ConfirmationAssociationBoutiqueModal
                    isModalOpened={isModifiedAssociateModal}
                    setIsModalOpened={setIsModifiedAssociateModal}
                    setDataIsUpdated={setDataIsUpdated}
                    designation={designation}
                    startTime={startTime}
                    dayOfTheWeek={dayOfTheWeek}
                    endTime={endTime}
                    setDesignation={setDesignation}
                    setTag={setTag}
                    setStartTime={setStartTime}
                    setEndTime={setEndTime}
                    setActiveDays={setActiveDays}
                    setTagsData={setTagsData}
                    setDayOfTheWeek={setDayOfTheWeek}
                    setLocalFilterActive={setLocalFilterActive}
                    setIsClickedBouttonValider={setIsClickedBouttonValider}
                    allData={allData}
                    tagsData={tagsData}
                />
            )}
            <OverLappedMessage
                isModalOpened={isModalOverLapped}
                setIsModalOpened={setIsModalOverLapped}
                setIsChevauchement={setIsChevauchement}
                setIsClickedBouttonValider={setIsClickedBouttonValider}
                setDataIsUpdated={setDataIsUpdated}
            />
        </React.Fragment>
    );
}
