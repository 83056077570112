import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import { diff } from "deep-diff";
import { mutate } from "swr";
import { toast } from "react-toastify";

import {
    store,
    setIsModalOpenedSimpleProject,
    setIsModalConfirmationConfigurationKiosk,
    setKioskProject,
} from "../../../store/project";
import {
    computedStore,
    generalConfigStore,
    setChosenTemplate,
    setInitItemsFromGeneralSetting,
    setIsInformationModesEmpty,
    setIsModalOpened,
    setIsProjectModesModalOpened,
    setProject,
} from "../../../store";
import { setKey } from "@pages/Project/store";

import { store as userStore } from "../../../components/VerticalLayout/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import "./index.css";
import { moveArrayElementToTheBegin } from "../../../helpers/general";

type PropsType = {
    children: React.ReactNode;
    title: string;
    rowData: any;
    setIsDataUpdated: Function;
    isDataUpdated: boolean | number;
    setIsCreatedProject: Function;
};

export function ModalWrapperSimple({
    children,
    title = "",
    rowData,
    setIsDataUpdated,
    isDataUpdated,
    setIsCreatedProject,
}: PropsType): JSX.Element {
    const { t } = useTranslation();

    const { isModalOpenedSimpleProject } = useSnapshot(store);

    const navigate = useNavigate();

    const toggle = () => {
        setIsModalOpenedSimpleProject(!isModalOpenedSimpleProject);
        setIsCreatedProject(false);
    };
    const {
        userID,
        franchiseID,
        projectId,
        shopID,
        oneShop,
        operatorID,
    } = useSnapshot(userStore);

    const [isDataChanged, setIsDataChanged] = React.useState<boolean>(false);
    const { items } = useSnapshot(computedStore);
    const {
        isWaysTabActive,
        project: { template, files },
        project,
    } = useSnapshot(generalConfigStore);
    React.useEffect(() => {
        if (isDataChanged === true) {
            if (
                (oneShop &&
                    (rowData === undefined ||
                        Object.keys(rowData).length === 0)) ||
                (oneShop &&
                    rowData !== undefined &&
                    rowData?.template?.id === "")
            ) {
                handleUpdateProjectShopsButtonOnClickEvent();
                handleSaveTemplateFromShopsToFranchiseOnClickEvent();
            } else if (
                oneShop &&
                rowData !== undefined &&
                rowData?.template.id !== ""
            ) {
                handleUpdateProjectShopsButtonOnClickEvent();
            } else {
                handleUpdateProjectButtonOnClickEvent(template, files);
            }
            setIsProjectModesModalOpened(false);
            setIsCreatedProject(false);
            setIsModalOpened(false);
        }

        // eslint-disable-next-line
    }, [isDataChanged, template, files]);

    async function handleUpdateProjectButtonOnClickEvent(
        template: any,
        files: any
    ) {
        let apiUrl: any;
        apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/template`;
        mutate(
            apiUrl,
            await fetch(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userId: userID,
                    franchiseId: franchiseID,
                    project_id: "PROJECT",
                    projectId: projectId,
                    shopId: "0",
                    operatorId: operatorID,
                    data: {
                        template: template,
                        files: files,
                    },
                }),

                method: "POST",
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                    setIsModalOpenedSimpleProject(!isModalOpenedSimpleProject);
                    if (!oneShop) {
                        toast.success(`${t("Template saved successfully")}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        });

                        let willUpdate = true;
                        if (
                            rowData !== undefined &&
                            Object.keys(rowData).length !== 0 &&
                            rowData?.subRows.length > 0 &&
                            (diff(template, project.template) !== undefined ||
                                diff(rowData.files, project.files) !==
                                    undefined)
                        ) {
                            rowData?.subRows.forEach((elt: any) => {
                                if (
                                    elt.template !== "" ||
                                    (elt.subRows &&
                                        elt.subRows.some(
                                            (el: any) => el.template !== ""
                                        ))
                                ) {
                                    willUpdate = false;
                                    setIsModalConfirmationConfigurationKiosk(
                                        true
                                    );
                                }
                            });
                        }
                        if (willUpdate === true)
                            setKey(Math.floor(Math.random() * 10000));
                    } else {
                        setKey(Math.floor(Math.random() * 10000));
                    }
                })

                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }
    async function handleUpdateProjectShopsButtonOnClickEvent() {
        let apiUrl: any;
        const kioskTemplate = JSON.parse(localStorage.getItem("project")!);
        setKioskProject(kioskTemplate);
        apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/template`;
        mutate(
            apiUrl,
            await fetch(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                },

                body: JSON.stringify({
                    userId: userID,
                    franchiseId: franchiseID,
                    project_id: "PROJECT",
                    projectId: projectId,
                    shopId: shopID,
                    operatorId: operatorID,
                    data: {
                        template: project.template,
                        files: project.files,
                    },
                }),

                method: "POST",
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                    toast.success(`${t("Template saved successfully")}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    });

                    if (
                        rowData !== undefined &&
                        Object.keys(rowData).length > 0 &&
                        rowData?.template.id !== "" &&
                        (diff(kioskTemplate, project.template) !== undefined ||
                            diff(rowData.files, project.files) !== undefined)
                    ) {
                        rowData?.subRows.forEach((elt: any) => {
                            if (elt.template !== "") {
                                setIsModalConfirmationConfigurationKiosk(true);
                            }
                        });
                    } else {
                        setKey(Math.floor(Math.random() * 10000));
                    }
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }

    const handleSaveTemplateFromShopsToFranchiseOnClickEvent = async () => {
        let shopId = "0";
        let template: any;
        const getLanguagesUrl: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/langue?franchiseId=${franchiseID}&shopId=${shopId}`
        );
        const languages = await getLanguagesUrl.json();

        const getSalesMethodUrl: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/sale?franchiseId=${franchiseID}&shopId=${shopId}`
        );
        const saleMethods = await getSalesMethodUrl.json();
        if (
            saleMethods.filter(
                (item: any) =>
                    item.active === true &&
                    item.id !== "f25e7c96-b5d3-4f2e-acd0-1500258283c2"
            ).length === 0 ||
            languages.filter((item: any) => item.active === true).length === 0
        ) {
            toast.warning(
                `${t(
                    `Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings.`
                )!}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
            return;
        } else {
            const getReglementUrl: Response = await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/consomation/reglement?franchiseId=${franchiseID}&shopId=${shopId}`
            );
            const reglements = await getReglementUrl.json();

            const getInformationMethodUrl: Response = await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/consomation/information?franchiseId=${franchiseID}&shopId=${shopId}`
            );

            const getLogoUrl: Response = await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/consomation/logo?franchiseId=${franchiseID}&shopId=${shopId}`
            );

            const informationModes = await getInformationMethodUrl.json();

            const logo = await getLogoUrl.json();

            if (
                informationModes.length === 0 ||
                informationModes.filter((item: ProjectMainContentItemType) => {
                    return saleMethods.filter((saleMethod: any) => {
                        return (
                            Object.keys(item.active).includes(
                                saleMethod.name
                            ) && saleMethod.active === true
                        );
                    });
                }).length === 0
            ) {
                setIsInformationModesEmpty(true);
            } else {
                setIsInformationModesEmpty(false);
            }
            template = {
                ...project.template,
                content: {
                    ...project.template.content,
                    languages: {
                        ...project.template.content.languages,
                        items:
                            languages.filter(
                                (language: LanguagesItemType) =>
                                    language.active === true &&
                                    language.isDefault === false
                            ).length > 1
                                ? moveArrayElementToTheBegin(
                                      languages.find(
                                          (language: LanguagesItemType) =>
                                              language.isDefault === true
                                      ),
                                      moveArrayElementToTheBegin(
                                          languages.find(
                                              // eslint-disable-next-line no-loop-func
                                              (language: LanguagesItemType) =>
                                                  language.active === true &&
                                                  language.isDefault === false
                                          ),
                                          languages
                                      )
                                  )
                                : languages,
                    },
                    salesMethods: {
                        items: saleMethods,
                    },
                    meansOfPayment: {
                        items: reglements,
                    },
                    informationModes: {
                        items: informationModes,
                    },
                    generalDesign: {
                        ...project.template.content.generalDesign,
                        logo,
                    },
                },
            };

            let files = project.files;
            setProject({
                ...project,
                template,
                files,
            });
            setInitItemsFromGeneralSetting({
                informationModes,
                saleMethods,
                reglements,
                languages,
                logo,
            });

            setChosenTemplate(template, files);
            let project1 = {
                ...project,
                template,
            };

            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/template`;
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        userId: userID,
                        franchiseId: franchiseID,
                        projectId: projectId,
                        project_id: "PROJECT",
                        shopId: "0",
                        operatorId: operatorID,
                        data: {
                            template: project1.template,
                            files: project1.files,
                        },
                    }),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.error) {
                            throw Error(result.message);
                        }

                        setKey(Math.floor(Math.random() * 10000));
                    })

                    .catch((error) => {
                        toast.error(`${t("There's an error")!}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    })
            );
        }
    };

    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                toggle={toggle}
                isOpen={isModalOpenedSimpleProject}
                className="modal-wrapper__clz"
            >
                <ModalHeader toggle={toggle} className="text-uppercase">
                    {title}
                </ModalHeader>
                <ModalBody className="p-0">{children}</ModalBody>
                <ModalFooter>
                    {isWaysTabActive ? (
                        <CustomMainColorButton
                            outline={true}
                            rounded={true}
                            className="m-2"
                            variant="primary"
                            disabled={Object.values(items.ways).length === 0}
                            onClick={() => navigate("/fullscreen-preview")}
                        >
                            {t("See")}
                        </CustomMainColorButton>
                    ) : null}
                    <CustomMainColorButton
                        disabled={project.template.id === "" ? true : false}
                        className="w-20"
                        rounded
                        variant="primary"
                        onClick={() => {
                            setIsDataChanged(true);
                        }}
                    >
                        {t("Save")}
                    </CustomMainColorButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
