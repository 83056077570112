import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledLabel,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";

import { capitalize } from "@helpers/general";
import { store } from "@components/VerticalLayout/store";
import { mutate } from "swr";
import {  setShopDataIsUpdated } from "../store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationModificationMessageType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    idsAssociated: any;
    data: any;
    userData: any;
    setIsAddSaleSupportClicked: Function;
    setIsEdited: any;
    isAddSaleSupportClicked: boolean;
    setSaleSupportData: Function;
    resetData: Function;
};

export function ConfirmationModificationModal({
    isModalOpened,
    setIsModalOpened,
    idsAssociated,
    data,
    userData,
    setIsAddSaleSupportClicked,
    setIsEdited,
    isAddSaleSupportClicked,
    setSaleSupportData,
    resetData,
}: ConfirmationModificationMessageType) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const uuidUser = localStorage.getItem("uuidUser");
    async function editSaleSupport() {
        toast.dismiss();
        let dataSaleSupport = [
            {
                userId: userID,
                shopId: [...idsAssociated],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                data: data,
            },
        ];

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataSaleSupport),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "The sale support of shop  was successfully changed"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );

                        setIsAddSaleSupportClicked(false);
                        setIsEdited(false);
                        setIsModalOpened(!isModalOpened);
                        setSaleSupportData([]);
                        resetData();
                      
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }
    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => setIsModalOpened(!isModalOpened)}
            >
                <StyledH2>{capitalize(t("Alert"))}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        `Do you want to apply these changes for all stores? \n Attention ! If you click on "Validate", the specific settings of the shops will be modified.`
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                        setIsAddSaleSupportClicked(false);
                        setIsEdited(false);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        editSaleSupport();
                        setIsModalOpened(!isModalOpened);
                        setShopDataIsUpdated(true);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
