import React from "react";
import { useSnapshot } from "valtio";

import { generalConfigStore } from "@store";

import { TransitionCardWrapper } from "./TransitionCardWrapper";

export function Transitions(): JSX.Element {

    const { transitions } = useSnapshot(generalConfigStore);
    return (
        <div
            className="d-grid__clz px-5 py-3"
            style={{
                gridTemplateRows: "0.1fr auto",
                gap: "20px",
            }}
        >
            <div className="d-flex flex-wrap" style={{ gap: "20px" }}>
                {Object.keys(transitions).map(
                    (transition: string, index: number) => {
                        return (
                            <TransitionCardWrapper
                                key={index}
                                transition={transition}
                            />
                        );
                    }
                )}
            </div>
        </div>
    );
}
