import React from "react";
import {
    DownloadIcon,
    StyledH2,
    StyledIconButton,
    StyledLabel,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";

import { SelectPrinterSvgIcon } from "@components/Common/SvgIcons";
import AlertLog from "./AlertLog";

import "./index.css";

type LogPathsModalType = {
    setIsDeviceAssociateModalOpened: Function;
    isDeviceAssociateModalOpened: boolean;
    rowData: any;
};

export default function Modalresult({
    setIsDeviceAssociateModalOpened,
    isDeviceAssociateModalOpened,
    rowData,
}: LogPathsModalType) {
    const { t } = useTranslation();
    const [isAlertLogModalOpened, setIsAlertLogModalOpened] = React.useState<
        boolean
    >(false);

    return (
        <React.Fragment>
            <Modal
                size="xl"
                isOpen={isDeviceAssociateModalOpened}
                style={{
                    marginTop: "12%",
                    marginLeft: "30%",
                    width: "942px",
                    height: "517px",
                }}
            >
                <ModalHeader
                    toggle={() =>
                        setIsDeviceAssociateModalOpened!(
                            !isDeviceAssociateModalOpened
                        )
                    }
                >
                    <StyledH2>
                        {t("Change machine log")}
                        {rowData.Application === ""
                            ? `${rowData.Périphérique} - ${rowData.appId}`
                            : `${
                                  rowData.peripheral === undefined
                                      ? ""
                                      : rowData.peripheral
                              } / ${rowData.Application}`}
                    </StyledH2>
                </ModalHeader>

                <ModalBody style={{ height: "500px" }}>
                    <AvForm>
                        <div className="d-flex text-center">
                            <StyledH2
                                style={{
                                    marginTop: "26px",
                                    marginLeft: "50px",
                                    fontSize: "21px",
                                }}
                            >
                                {t(
                                    "Please enter your support code and choose your download method"
                                )}
                            </StyledH2>
                        </div>
                        <div
                            style={{
                                marginTop: "37px",
                                marginLeft: "136px",
                            }}
                        >
                            <div className="ml-5">
                                <div>
                                    <StyledLabel
                                        htmlFor="path"
                                        className="mt-3 ml-4"
                                        style={{ fontSize: "17px" }}
                                    >
                                        {t("Carrier code")}
                                    </StyledLabel>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <StyledTextInput
                                        id="path"
                                        name="path"
                                        placeholder={t("Write")}
                                        type="text"
                                        style={{
                                            width: "460px",
                                            marginLeft: "20px",
                                        }}
                                    />
                                    <StyledIconButton
                                        icon="LinkIcon"
                                        onClick={() =>
                                            setIsAlertLogModalOpened(
                                                !isAlertLogModalOpened
                                            )
                                        }
                                        style={{
                                            right: "20%",
                                            position: "absolute",

                                            opacity: "0.6",
                                        }}
                                    >
                                        <SelectPrinterSvgIcon
                                            height={20}
                                            width={20}
                                        />
                                    </StyledIconButton>
                                </div>
                            </div>
                            <div className="ml-5">
                                <label htmlFor="file1">
                                    <DownloadIcon
                                        height={25}
                                        width={25}
                                        style={{
                                            right: "70%",
                                            position: "absolute",
                                            zIndex: 1,
                                            color: "white",
                                            marginTop: "20px",
                                            opacity: "0.6",
                                        }}
                                    />
                                </label>
                                <input
                                    type="file"
                                    id="file1"
                                    placeholder="file1"
                                />
                                <StyledTextInput
                                    id="file1"
                                    name="path"
                                    placeholder={t("Download")}
                                    type="text"
                                    className="placeholder__clz"
                                    style={{
                                        width: "465px ",
                                        marginLeft: "20px",
                                        textAlign: "center",
                                    }}
                                />
                            </div>
                            <div className="ml-5">
                                <label htmlFor="file2">
                                    <DownloadIcon
                                        height={25}
                                        width={25}
                                        style={{
                                            right: "70%",
                                            position: "absolute",
                                            zIndex: 1,
                                            color: "white",
                                            marginTop: "20px",
                                            opacity: "0.6",
                                        }}
                                    />
                                </label>
                                <input
                                    type="file"
                                    id="file2"
                                    placeholder="file2"
                                />
                                <StyledTextInput
                                    id="file2"
                                    name="path"
                                    placeholder={t(
                                        "Download and send to server"
                                    )}
                                    className="placeholder__clz"
                                    textAlign="center"
                                    type="text"
                                    style={{
                                        width: "465px ",
                                        marginLeft: "20px",
                                        textAlign: "center",
                                    }}
                                />
                            </div>
                        </div>
                    </AvForm>
                </ModalBody>
            </Modal>
            {isAlertLogModalOpened && (
                <AlertLog
                    rowData={rowData}
                    isAlertLogModalOpened={isAlertLogModalOpened}
                    setIsAlertLogModalOpened={setIsAlertLogModalOpened}
                />
            )}
        </React.Fragment>
    );
}
