import React from "react";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";

import {
    computedStore,
    designStore,
    setIsPageActionsButtonsActive,
    setPageActionsButtonsItems,
    setActionsButtonsItems,
    setIsActionButtonsActive,
} from "@store";

type ActionsButtonsPropsType = {
    localActionsButtonsItems: ActionsButtonsDesignItemType[];
    setLocalActionsButtonsItems: React.Dispatch<
        React.SetStateAction<ActionsButtonsDesignItemType[]>
    >;
};

export function ActionsButtonsWrapper({
    localActionsButtonsItems,
    setLocalActionsButtonsItems,
}: ActionsButtonsPropsType): JSX.Element {
    const { t } = useTranslation();

    const { isActionButtonsActive, activeSlideIndex } = useSnapshot(
        designStore
    );
    const { elements } = useSnapshot(computedStore);

    const FinalMessageIndex = elements.find(
        (element) => element.name === "finalMessage"
    )?.index;

    const isDisabled =
        FinalMessageIndex !== undefined
            ? elements[activeSlideIndex].name === "printerOptions" &&
              elements[activeSlideIndex].index === FinalMessageIndex - 1 
            : false;

    function handleActivateSwitchOnClickEvent(name: string) {
        const copOfData = [...localActionsButtonsItems];
        if (
            copOfData.filter((actionButtonItem) => {
                return actionButtonItem.active === true;
            }).length === 0 &&
            elements[activeSlideIndex].name !== "printerOptions" &&
            isActionButtonsActive === false
        ) {
            setIsActionButtonsActive(true);
            setIsPageActionsButtonsActive(
                true,
                elements[activeSlideIndex].name
            );
        }

        const findIndex = copOfData.find((element) => element.name === name);

        if (findIndex) {
            const actionButtonItem = copOfData.indexOf(findIndex);

            copOfData[actionButtonItem] = {
                ...findIndex,
                active: !copOfData[actionButtonItem].active,
            };
            setLocalActionsButtonsItems(copOfData);
            setActionsButtonsItems(copOfData);
            setPageActionsButtonsItems(
                copOfData,
                elements[activeSlideIndex].name
            );
        }

        if (
            copOfData.filter((actionButtonItem) => {
                return actionButtonItem.active === true;
            }).length === 0 &&
            elements[activeSlideIndex].name !== "printerOptions"
        ) {
            setIsActionButtonsActive(false);
            setIsPageActionsButtonsActive(
                false,
                elements[activeSlideIndex].name
            );
        }
    }

    return (
        <div>
            <div
                className="mt-4 rounded border"
                style={{
                    width: "93%",
                    height: "96%",
                    borderColor: "#CECECE",
                }}
            >
                <div
                    className="text-left px-4 d-flex align-items-center cursor__clz"
                    style={{
                        backgroundColor: "#EDEDED",
                        height: "40px",
                        fontSize: "20px",
                    }}
                >
                    {t("Actions Buttons")}
                </div>
                <div className="p-4" style={{ maxWidth: "400px" }}>
                    {localActionsButtonsItems.map(
                        (item: ActionsButtonsDesignItemType, index: number) => {
                            return (
                                <div
                                    className="py-2 switch-items__clz cursor__clz"
                                    key={index}
                                >
                                    <span>{t(item?.name)}</span>
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        onChange={() => {
                                            handleActivateSwitchOnClickEvent(
                                                localActionsButtonsItems[index]
                                                    .name
                                            );
                                        }}
                                        checked={
                                            localActionsButtonsItems[index]
                                                .active
                                        }
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                        disabled={isDisabled}
                                    />
                                </div>
                            );
                        }
                    )}
                </div>
            </div>
        </div>
    );
}
