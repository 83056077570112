import React from "react";
import { Card } from "reactstrap";
import { useSnapshot } from "valtio";

import { computedStore, designStore, generalConfigStore } from "@store";
import { truncateString } from "@helpers/general";

export function LoyaltyConsommationCard(): JSX.Element {
    const { selectedLanguage, activeSlideIndex } = useSnapshot(designStore);
    const {
        project: { template },
    } = useSnapshot(generalConfigStore);
    const { elements } = useSnapshot(computedStore);

    const activeMainContentItems = ((template.content as ProjectContentType)[
        elements[activeSlideIndex].name
    ] as ProjectContentItemType)?.items.filter(
        (item): boolean => item.active === true
    );

    return (
        <Card
            style={{
                width: "350px",
                height: "122px",
                borderRadius: "20px",
                borderBottom: `6px solid ${
                    (template.content.generalDesign as GeneralDesignType)
                        .colors[0].content
                }`,
                boxShadow: "0px 3px 6px #00000029",
                font: "Segoe UI",
            }}
        >
            <div
                style={{
                    display: "grid",
                    gridTemplateRows: "1fr 0.1fr 0.8fr 0.8fr",
                    height: "100%",
                }}
            >
                <div
                    className="d-flex justify-content-between align-items-center mx-5 cursor__clz"
                    style={{
                        gap: "20px",
                        fontSize: "normal normal normal 20px/51px Open Sans",
                        color: "#323333",
                    }}
                >
                    {truncateString(
                        activeMainContentItems[0].languages[selectedLanguage]
                            .name,
                        14
                    )}

                    <div className="font-weight-bold cursor__clz">100.00 €</div>
                </div>
                <hr
                    className="d-flex justify-content-between align-items-center"
                    style={{
                        height: "2px",
                        width: "80%",
                        backgroundColor: "#AFAFAF",
                        border: "0px",
                        margin: "8px !important",
                    }}
                />

                <div
                    className="d-flex justify-content-between align-items-center mx-1 px-2 font-weight-bold cursor__clz"
                    style={{
                        fontSize: "10px Segoe UI;",
                    }}
                >
                    {truncateString(
                        activeMainContentItems[1].languages[selectedLanguage]
                            .name,
                        30
                    )}
                    <div className="cursor__clz">5.00 €</div>
                </div>
                <div
                    className="d-flex justify-content-between align-items-center mx-1 px-2 cursor__clz"
                    style={{
                        fontSize: "10px",
                        color: "#7C7C7C",
                    }}
                >
                    {truncateString(
                        activeMainContentItems[2].languages[selectedLanguage]
                            .name,
                        30
                    )}
                    <div>20.00 €</div>
                </div>
                <div
                    className="d-flex justify-content-between align-items-center mx-1 px-2 cursor__clz"
                    style={{
                        fontSize: "10px",
                        color: "#7C7C7C",
                    }}
                >
                    {truncateString(
                        activeMainContentItems[3].languages[selectedLanguage]
                            .name,
                        45
                    )}
                </div>
            </div>
        </Card>
    );
}
