import React from "react";
import classnames from "classnames";
import { toast } from "react-toastify";
import ReactSwitch from "react-switch";

import {
    CheckboxIcon,
    EyeIcon,
    PencilIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { Tooltip } from "@mui/material";
import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import ErrorToast from "@components/Common/ErrorTost";
import { store as aggregator } from "../../../../components/VerticalLayout/store";
import store, {
    setNumberActive,
    setNumberArchived,
    setSelectedAggregator,
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setKey,
} from "../store";
import { ConfirmationArchive } from "../ConfirmationArchive";
import ConsultSvgIcon from "@components/Common/SvgIcons/ConsultSvgIcon";
import {
    setActivationDeactivationMessageModalOpened,
    setMessage,
    setYesFunction,
} from "@components/Common/ConfirmationActivationDeactivationMessageModal/store";
import { uuid } from "uuidv4";

export function CustomIcons({
    e,
    setShopId,
    setAggregator,
    setIsModalOpened,
    isModalOpened,
    setDataIsUpdated,
    setIsHistoryModalOpened,
    setSelectedRows,
    isAggregatorArchived,
    setIsAddAggregatorClicked,
    isAddAggregatorClicked,
    selectedRows,
    setLocalFilterActive,
    type,
    setIsConsult,
}: any) {
    const { t } = useTranslation();
    const {
        numberActive,
        numberArchived,
        shopsAggregatorData,
        archivedAggregatorFranchise,
        isActionsClicked,
        isEdited,
        modify,
    } = useSnapshot(store);
    const { franchiseID, oneShop, userID, operatorID } = useSnapshot(
        aggregator
    );
    const [loading, setLoading] = React.useState<boolean>(false);
    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);
    const [aggregatorState, setAggregatorState] = React.useState<boolean>(
        e.selectedRow.state
    );
    const uuidUser = localStorage.getItem("uuidUser");

    let dataAssociate: any[] = [];
    let dataArchive: any[] = [];

    if (!oneShop) {
        dataAssociate = AssociateAggregator();
        dataArchive = ArchiveAggregator();
    } else {
        dataArchive = ArchiveAggregatorFranchise();
    }

    function AssociateAggregator() {
        let idsAssociated: any = [];
        if (shopsAggregatorData.data !== undefined) {
            shopsAggregatorData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((elt: any) => {
                        idsAssociated.push(elt.Id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    async function handleStateChange() {
        let id = e.selectedRow.Id;
        setLoading(true);
        if (id !== undefined && e.selectedRow[t("Aggregator")] !== undefined) {
            let dataConfig = [
                {
                    userId: userID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    shopId: [e.selectedRow.shopId],
                    franchiseId: franchiseID,
                    isShop: false,
                    data: {
                        [id]: {
                            aggregator: e.selectedRow[t("Aggregator")],
                            webhook: e.selectedRow[t("Webhook")],
                            note: e.selectedRow[t("Remark")],
                            isActive: !aggregatorState,
                            state: !aggregatorState,
                            stateShop: !aggregatorState,
                            isArchived: false,
                            shopId: e.selectedRow.shopId,
                        },
                    },
                },
            ];

            const isStateChange = true;
            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator`;
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ dataConfig, isStateChange }),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.error) {
                            setLoading(false);
                            throw Error(result.message);
                        }

                        toast.success(
                            aggregatorState === true
                                ? `${t(
                                      "The Aggregator has been successfully deactivated"
                                  )}`
                                : `${t(
                                      "The Aggregator has been successfully activated"
                                  )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated!(true);
                        setLoading(false);
                        setKey(uuid());
                    })
                    .catch((error: any) => {
                        setLoading(false);
                        toast.error(`${t("There's an error")!}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    })
            );
        }
    }
    function ArchiveAggregator() {
        let idsAssociated: any = [];
        if (shopsAggregatorData.data !== undefined) {
            shopsAggregatorData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((el: any) => {
                        idsAssociated.push(el.Id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    function ArchiveAggregatorFranchise() {
        let idsAssociated: any = [];
        if (
            archivedAggregatorFranchise.othersData.archivedFranchise.data !==
            undefined
        ) {
            archivedAggregatorFranchise.othersData.archivedFranchise.data.forEach(
                (element: any) => {
                    idsAssociated.push(element.Id);
                }
            );
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    async function activeAggregator() {
        toast.dismiss();
        let dataAggregator = {
            userId: userID,
            shopId: [e.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            isShop: false,
            data: {
                [e.selectedRow.Id]: {
                    aggregator: e.selectedRow[t("Aggregator")],
                    webhook: e.selectedRow[t("Webhook")],
                    note: e.selectedRow[t("Remark")],
                    isActive: e.selectedRow.isActive,
                    isArchived: false,
                    shopId: e.selectedRow.shopId,
                    state: e.selectedRow.state,
                    stateShop: e.selectedRow.stateShop,
                },
            },
        };

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataAggregator),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "The Aggregator has been successfully unarchive"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated!(true);
                        setKey(uuid());
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    {modify === false || isAggregatorArchived ? (
                        <Tooltip title={t("To consult")}>
                            <div>
                                {" "}
                                <ConsultSvgIcon
                                    className="pointer__clz afa_icn_toConsult"
                                    height={35}
                                    width={35}
                                    style={{
                                        cursor: "pointer",
                                        position: "relative",
                                        bottom: "5px",
                                    }}
                                    fill="black"
                                    onClick={() => {
                                        setIsAddAggregatorClicked(true);
                                        if (!isAddAggregatorClicked === false) {
                                            setIsAddAggregatorClicked(true);
                                        }

                                        setShopId(e.selectedRow.shopId);
                                        setAggregator(e.selectedRow);
                                        setLocalFilterActive(false);

                                        setIsConsult(true);
                                    }}
                                />
                            </div>
                        </Tooltip>
                    ) : null}
                    {modify === true ? (
                        <>
                            <Tooltip
                                title={isAggregatorArchived ? "" : t("Edit")}
                            >
                                <div>
                                    <PencilIcon
                                        onClick={() => {
                                            if (isEdited === true) {
                                                setIsActionsClicked(
                                                    !isActionsClicked
                                                );
                                                setActions(() => {
                                                    setIsEdited(false);

                                                    setIsAddAggregatorClicked(
                                                        true
                                                    );

                                                    setShopId(
                                                        e.selectedRow.shopId
                                                    );
                                                    setAggregator(
                                                        e.selectedRow
                                                    );
                                                    setLocalFilterActive(false);
                                                });
                                            } else {
                                                setIsAddAggregatorClicked(true);

                                                setShopId(e.selectedRow.shopId);
                                                setAggregator(e.selectedRow);
                                                setLocalFilterActive(false);
                                            }
                                        }}
                                        height={25}
                                        width={25}
                                        style={{ cursor: "pointer" }}
                                        className={`${classnames(
                                            "pointer__clz cmn_icn_FRedit",
                                            {
                                                "not-allowed-icon__clz d-none": isAggregatorArchived,
                                            },
                                            oneShop ? "mr-2" : "ml-3"
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                            {oneShop && (
                                <Tooltip
                                    title={
                                        isAggregatorArchived
                                            ? ""
                                            : aggregatorState === false
                                            ? t("Activate")
                                            : t("Unactivate")
                                    }
                                >
                                    <div>
                                        <ReactSwitch
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            handleDiameter={26}
                                            offColor="#f7b4b8"
                                            offHandleColor="#E30613"
                                            checked={aggregatorState}
                                            onChange={async () => {
                                                if (isEdited === true) {
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(() => {
                                                        setIsEdited(false);
                                                        setIsAddAggregatorClicked(
                                                            false
                                                        );
                                                        setMessage(
                                                            aggregatorState
                                                                ? t(
                                                                      "Caution, the Aggregator may be used by other modules.\n Would you like to confirm the deactivation of the selected Aggregator?"
                                                                  )
                                                                : t(
                                                                      "Would you like to confirm the activation of the selected Aggregator?"
                                                                  )
                                                        );
                                                        setYesFunction(() => {
                                                            setAggregatorState(
                                                                !aggregatorState
                                                            );
                                                            handleStateChange();

                                                            setActivationDeactivationMessageModalOpened(
                                                                true
                                                            );
                                                        });
                                                    });
                                                } else {
                                                    setMessage(
                                                        aggregatorState
                                                            ? t(
                                                                  "Caution, the aggregator may be used by other modules.\n Would you like to confirm the deactivation of the selected aggregator?"
                                                              )
                                                            : t(
                                                                  "Would you like to confirm the activation of the selected aggregator?"
                                                              )
                                                    );
                                                    setYesFunction(() => {
                                                        setAggregatorState(
                                                            !aggregatorState
                                                        );
                                                        handleStateChange();
                                                    });

                                                    setActivationDeactivationMessageModalOpened(
                                                        true
                                                    );
                                                }
                                            }}
                                            onColor="#c2eddd"
                                            onHandleColor="#34C38F"
                                            width={50}
                                            height={20}
                                            className={`${classnames(
                                                " cmn_icn_BTenableDisable pointer__clz",
                                                {
                                                    "not-allowed-icon__clz":
                                                        isAggregatorArchived ||
                                                        e.selectedRow[
                                                            t("Aggregator")
                                                        ] === undefined,
                                                },
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                            {!isAggregatorArchived ? (
                                <Tooltip
                                    title={
                                        e.selectedRow[t("State")] === true &&
                                        oneShop
                                            ? ""
                                            : t("Archive")
                                    }
                                >
                                    <div>
                                        <TrashIcon
                                            onClick={() => {
                                                if (isEdited === true) {
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(() => {
                                                        setIsEdited(false);
                                                        setIsAddAggregatorClicked(
                                                            false
                                                        );
                                                        if (
                                                            dataAssociate.includes(
                                                                e.selectedRow.Id
                                                            )
                                                        ) {
                                                            ErrorToast(
                                                                `${t(
                                                                    "Please note that you cannot archive an item assigned to stores."
                                                                )!}`
                                                            );
                                                        } else {
                                                            setIsArchiveModalOpened(
                                                                true
                                                            );
                                                        }
                                                    });
                                                } else {
                                                    setIsAddAggregatorClicked(
                                                        false
                                                    );
                                                    if (
                                                        dataAssociate.includes(
                                                            e.selectedRow.Id
                                                        )
                                                    ) {
                                                        ErrorToast(
                                                            `${t(
                                                                "Please note that you cannot archive an item assigned to stores."
                                                            )!}`
                                                        );
                                                    } else {
                                                        setIsArchiveModalOpened(
                                                            true
                                                        );
                                                    }
                                                }
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{ cursor: "pointer" }}
                                            className={`  ${classnames(
                                                "pointer__clz cmn_icn_FRarchived",
                                                oneShop ? "mr-2" : "ml-3",
                                                {
                                                    "not-allowed-icon__clz":
                                                        e.selectedRow[
                                                            t("State")
                                                        ] === true && oneShop,
                                                }
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={
                                        dataArchive.includes(
                                            e.selectedRow.Id
                                        ) && oneShop
                                            ? ""
                                            : t("Unarchive")
                                    }
                                >
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                if (
                                                    dataArchive.includes(
                                                        e.selectedRow.Id
                                                    ) &&
                                                    !oneShop
                                                ) {
                                                    activeAggregator();
                                                    setIsModalOpened(
                                                        !isModalOpened
                                                    );
                                                } else {
                                                    activeAggregator();
                                                    setNumberActive(
                                                        numberActive + 1
                                                    );
                                                    setNumberArchived(
                                                        numberArchived - 1
                                                    );
                                                }

                                                setAggregator(e.selectedRow);
                                                setSelectedRows([
                                                    e.selectedRow,
                                                ]);
                                            }}
                                            className={`${classnames(
                                                "pointer__clz  cmn_icn_FRunarchive",
                                                {
                                                    "not-allowed-icon__clz":
                                                        dataArchive.includes(
                                                            e.selectedRow.Id
                                                        ) && oneShop,
                                                },
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}

                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(() => {
                                            setIsEdited(false);
                                            setIsAddAggregatorClicked(false);
                                            setIsHistoryModalOpened(true);
                                            setSelectedAggregator([
                                                {
                                                    [e.selectedRow.shopId]:
                                                        e.selectedRow.Id,
                                                },
                                            ]);

                                            setSelectedRows([e.selectedRow]);
                                        });
                                    } else {
                                        setIsAddAggregatorClicked(false);
                                        setIsHistoryModalOpened(true);
                                        setSelectedAggregator([
                                            {
                                                [e.selectedRow.shopId]:
                                                    e.selectedRow.Id,
                                            },
                                        ]);

                                        setSelectedRows([e.selectedRow]);
                                    }
                                }}
                                className={`${classnames(
                                    "pointer__clz  cmn_icn_FRhistory",
                                    oneShop ? "mr-2" : "ml-3",
                                    loading ? "not-allowed-icon__clz" : ""
                                )}`}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
            {isArchiveModalOpened ? (
                <ConfirmationArchive
                    isModalOpened={isArchiveModalOpened}
                    setIsModalOpened={setIsArchiveModalOpened}
                    e={e}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                />
            ) : null}
        </React.Fragment>
    );
}
