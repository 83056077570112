import { t } from "i18next";

import React from "react";

export function NoTHANKS(props: any): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            viewBox="0 0 384 439"
        >
            <defs>
                <filter
                    id="Rectangle_14"
                    x="0"
                    y="0"
                    width="384"
                    height="439"
                    filterUnits="userSpaceOnUse"
                >
                    {/* <feOffset dy="3" input="SourceAlpha" /> */}
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g
                id="Groupe_20"
                data-name="Groupe 20"
                transform="translate(-3156 4091)"
            >
                <g
                    transform="matrix(1, 0, 0, 1, 3156, -4091)"
                    filter="url(#Rectangle_14)"
                >
                    <g
                        id="Rectangle_14-2"
                        data-name="Rectangle 14"
                        transform="translate(9 6)"
                        fill="#fff"
                        stroke="#cbcbcb"
                        stroke-width="1"
                    >
                        <rect width="366" height="421" rx="13" stroke="none" />
                        <rect
                            x="0.5"
                            y="0.5"
                            width="365"
                            height="420"
                            rx="12.5"
                            fill="none"
                        />
                    </g>
                </g>
                <text
                    id="NO_THANKS"
                    data-name="NO THANKS"
                    transform="translate(3348 -3868)"
                    font-size="38"
                    font-family="HelveticaNeue-Medium, Helvetica Neue"
                    font-weight="500"
                >
                    <tspan x="-96.425" y="0">
                        {t("NO THANKS", {
                            lng: props.selectedLanguage,
                        })}
                    </tspan>
                </text>
            </g>
        </svg>
    );
}
