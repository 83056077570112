import React from "react";
import { StyledLabel, StyledSelectInput } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import classnames from "classnames";
import { useSnapshot } from "valtio";

import { setMoneticData, store } from "./store";

import "react-toastify/dist/ReactToastify.css";

type ValinaSwissConfigTypes = {
    setIsEdited: Function;
};

export function ValinaSwissConfig({ setIsEdited }: ValinaSwissConfigTypes) {
    const { t } = useTranslation();
    const {
        isConsult,
        inputIdIntegrator,
        idIntegrator,
        autoValidate,
        inputCurrency,
        currency,
        inputTerminalType,
        typeTerminal,
    } = useSnapshot(store);

    const [currencies, setCurrencies] = React.useState<any>([]);
    async function getCurrencies() {
        await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/currencies/symbol`
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    setCurrencies(
                        result.data.map((el: any) => {
                            return { label: el.key, value: el.Symbole };
                        })
                    );
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    React.useEffect(() => {
        getCurrencies();
    }, []);

    return (
        <React.Fragment>
            <AvForm>
                <AvGroup>
                    <StyledLabel
                        htmlFor="example-input"
                        className="required__clz mt-3"
                    >
                        {t("Terminal type")}
                    </StyledLabel>
                    <AvField
                        className={classnames({
                            input__clz: inputTerminalType,
                            readOnly__clz: isConsult,
                        })}
                        autocomplete="off"
                        id="terminalType"
                        name="terminalType"
                        placeholder={t("Write")}
                        type="text"
                        onChange={(e: any) => {
                            setMoneticData(e.target.value, "typeTerminal");
                            setMoneticData(false, "inputTerminalType");
                            setIsEdited(true);
                        }}
                        value={typeTerminal}
                    />
                    {inputTerminalType && (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("Please enter an integrator id")}
                        </div>
                    )}
                </AvGroup>
                <AvGroup>
                    <StyledLabel
                        htmlFor="example-input"
                        className="mt-3 required__clz"
                    >
                        {t("ID of integrator")}
                    </StyledLabel>
                    <AvField
                        className={classnames({
                            input__clz: inputIdIntegrator,
                            readOnly__clz: isConsult,
                        })}
                        autocomplete="off"
                        id="idIntegrator"
                        name="idIntegrator"
                        placeholder={t("Write")}
                        type="text"
                        onChange={(e: any) => {
                            setMoneticData(e.target.value, "idIntegrator");
                            setMoneticData(false, "inputIdIntegrator");
                            setIsEdited(true);
                        }}
                        value={idIntegrator}
                    />
                    {inputIdIntegrator && (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("Please enter an integrator id")}
                        </div>
                    )}
                </AvGroup>
                <AvGroup>
                    <StyledLabel
                        htmlFor="example-input"
                        className="required__clz mt-3"
                    >
                        {t("Automatic validation")}(auto-commit)
                    </StyledLabel>
                    <StyledSelectInput
                        className={classnames({
                            readOnly__clz: isConsult,
                        })}
                        autocomplete="off"
                        id="AutoValidate"
                        name="AutoValidate"
                        type="text"
                        onChange={(e: any) => {
                            setMoneticData(e, "autoValidate");
                            setMoneticData(false, "inputAutoValidate");
                            setIsEdited(true);
                        }}
                        options={[
                            {
                                label: t("True"),
                                value: t("True"),
                            },
                            {
                                label: t("False"),
                                value: t("False"),
                            },
                        ]}
                        value={autoValidate}
                        placeholder={`${t("Select")}…`}
                    />
                    {/* <AvField
                        className={classnames({
                            input__clz: inputAutoValidate,
                            readOnly__clz: isConsult,
                        })}
                        autocomplete="off"
                        id="AutoValidate"
                        name="AutoValidate"
                        placeholder={t("Write")}
                        type="text"
                        onChange={(e: any) => {
                            setMoneticData(e.target.value, "autoValidate");
                            setMoneticData(false, "inputAutoValidate");
                            setIsEdited(true);
                        }}
                        value={autoValidate}
                    />
                    {inputAutoValidate && (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("Please enter an auto validation")}
                        </div>
                    )} */}
                </AvGroup>
                <AvGroup>
                    <StyledLabel
                        htmlFor="example-input"
                        className="required__clz mt-3"
                    >
                        {t("Currency")}
                    </StyledLabel>
                    <StyledSelectInput
                        isClearable
                        className={classnames({
                            invalid__clz: inputCurrency,
                        })}
                        noOptionsMessage={() => t("No options")}
                        options={currencies}
                        autocomplete="off"
                        name="change"
                        onChange={(e: any) => {
                            if (e !== null) {
                                setMoneticData(e.label, "currency");
                            } else {
                                setMoneticData("", "currency");
                            }
                            setMoneticData(false, "inputCurrency");
                            setIsEdited(true);
                        }}
                        value={
                            currency === ""
                                ? null
                                : {
                                      label: currency,
                                      value: currency,
                                  }
                        }
                        placeholder={`${t("Select")}…`}
                        type="text"
                    />
                    {inputCurrency ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("Please select a change")}
                        </div>
                    ) : null}
                </AvGroup>
            </AvForm>
        </React.Fragment>
    );
}
