import { proxy } from "valtio";
import { devtools } from "valtio/utils";

type StoreDiversType = {
    centralizedPrintingPrintOtherPeople: boolean;
    standAlonePrintingStationPrints: boolean;
    printingOptionsA4Format: boolean;
    printingOptionsPrintingProcess: boolean;
    printingOptionsSpecialCharacters: boolean;
    printingOptionsInstalledPrinter: boolean;
    printingOptionsForcePaper: boolean;
    printingOptionsDeportedServer: boolean;
    codeBarcodeTicket: string;
    codeQrCode: string;
    codeCheckedBarcodeTicket: boolean;
    codeCheckedQrCode: boolean;
    paperlessTicket: boolean;
};

export const storeDivers = proxy<StoreDiversType>({
    centralizedPrintingPrintOtherPeople: false,
    standAlonePrintingStationPrints: false,
    printingOptionsA4Format: false,
    printingOptionsPrintingProcess: false,
    printingOptionsSpecialCharacters: false,
    printingOptionsInstalledPrinter: false,
    printingOptionsForcePaper: false,
    printingOptionsDeportedServer: false,
    codeBarcodeTicket: "",
    codeQrCode: "",
    codeCheckedBarcodeTicket: false,
    codeCheckedQrCode: false,
    paperlessTicket: true,
});

devtools(storeDivers);
export function setCentralizedPrintingPrintOtherPeople(
    centralizedPrintingPrintOtherPeople: boolean
): void {
    storeDivers.centralizedPrintingPrintOtherPeople = centralizedPrintingPrintOtherPeople;
}
export function setStandAlonePrintingStationPrints(
    standAlonePrintingStationPrints: boolean
): void {
    storeDivers.standAlonePrintingStationPrints = standAlonePrintingStationPrints;
}
export function setPaperlessTicket(paperlessTicket: boolean): void {
    storeDivers.paperlessTicket = paperlessTicket;
}
export function setPrintingOptionsA4Format(
    printingOptionsA4Format: boolean
): void {
    storeDivers.printingOptionsA4Format = printingOptionsA4Format;
}
export function setPrintingOptionsPrintingProcess(
    printingOptionsPrintingProcess: boolean
): void {
    storeDivers.printingOptionsPrintingProcess = printingOptionsPrintingProcess;
}
export function setPrintingOptionsSpecialCharacters(
    printingOptionsSpecialCharacters: boolean
): void {
    storeDivers.printingOptionsSpecialCharacters = printingOptionsSpecialCharacters;
}
export function setPrintingOptionsInstalledPrinter(
    printingOptionsInstalledPrinter: boolean
): void {
    storeDivers.printingOptionsInstalledPrinter = printingOptionsInstalledPrinter;
}
export function setPrintingOptionsForcePaper(
    printingOptionsForcePaper: boolean
): void {
    storeDivers.printingOptionsForcePaper = printingOptionsForcePaper;
}
export function setPrintingOptionsDeportedServer(
    printingOptionsDeportedServer: boolean
): void {
    storeDivers.printingOptionsDeportedServer = printingOptionsDeportedServer;
}
export function setCodeBarcodeTicket(codeBarcodeTicket: string): void {
    storeDivers.codeBarcodeTicket = codeBarcodeTicket;
}
export function setCodeQrCode(codeQrCode: string): void {
    storeDivers.codeQrCode = codeQrCode;
}
export function setCodeCheckedBarcodeTicket(
    codeCheckedBarcodeTicket: boolean
): void {
    storeDivers.codeCheckedBarcodeTicket = codeCheckedBarcodeTicket;
}
export function setCodeCheckedQrCode(codeCheckedQrCode: boolean): void {
    storeDivers.codeCheckedQrCode = codeCheckedQrCode;
}

export function resetStore() {
    storeDivers.centralizedPrintingPrintOtherPeople = false;
    storeDivers.standAlonePrintingStationPrints = false;
    storeDivers.printingOptionsPrintingProcess = false;
    storeDivers.printingOptionsSpecialCharacters = false;
    storeDivers.printingOptionsInstalledPrinter = false;
    storeDivers.printingOptionsForcePaper = false;
    storeDivers.printingOptionsDeportedServer = false;
    storeDivers.codeBarcodeTicket = "";
    storeDivers.codeQrCode = "";
    storeDivers.codeCheckedBarcodeTicket = false;
    storeDivers.printingOptionsA4Format = false;
    storeDivers.codeCheckedQrCode = false;
}

export default storeDivers;
