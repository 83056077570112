import { t } from "i18next";

import React from "react";

export function GiveUp(props: any): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            viewBox="0 0 350 87"
        >
            <g
                id="Groupe_14"
                data-name="Groupe 14"
                transform="translate(-1122 4082)"
            >
                <g
                    id="Rectangle_10"
                    data-name="Rectangle 10"
                    transform="translate(1122 -4082)"
                    fill="#fff"
                    stroke="#8b8b8b"
                    stroke-width="2"
                >
                    <rect width="350" height="87" rx="9" stroke="none" />
                    <rect
                        x="1"
                        y="1"
                        width="348"
                        height="85"
                        rx="8"
                        fill="none"
                    />
                </g>
                <text
                    id="Give up"
                    transform="translate(1297 -4024)"
                    fill="#8b8b8b"
                    font-size="38"
                    font-family="HelveticaNeue-Medium, Helvetica Neue"
                    font-weight="500"
                >
                    <tspan x="-108.395" y="0">
                        {t("Give up", { lng: props.selectedLanguage })}
                    </tspan>
                </text>
            </g>
        </svg>
    );
}
