import React from "react";
import classnames from "classnames";
import {
    CheckboxIcon,
    PlusIcon,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { uuid } from "uuidv4";

import {
    setIsHistoryModalOpened,
    setIsInlineErrorCleared,
    store as choiceStore,
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setIsLoading,
    setKey,
} from "../store";
import { store } from "@components/VerticalLayout/store";

import ErrorToast from "@components/Common/ErrorTost";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";
import { ArchivedValidationModal } from "@components/Common/ArchivedValidationModal";

import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";

type CustomSideFilterType = {
    selectedRows: any;
    setIsInInitializationModalOpened: any;
    setDataIsUpdated: any;
    choice: any;
    setLocalFilterActive: any;
    setIsAddChoiceClicked: Function;
    isAddChoiceClicked: boolean;
    setIsModalConfirmationOpened: Function;
    isModalConfirmationOpened: boolean;
    isArchivedPaymentMethodsClicked: boolean;
    setIsArchivedPaymentMethodsClicked: Function;
};

export function CustomSideFilter({
    selectedRows,
    setIsInInitializationModalOpened,
    setDataIsUpdated,
    choice,
    setLocalFilterActive,
    setIsAddChoiceClicked,
    isAddChoiceClicked,
    setIsModalConfirmationOpened,
    isModalConfirmationOpened,
    isArchivedPaymentMethodsClicked,
    setIsArchivedPaymentMethodsClicked,
}: CustomSideFilterType) {
    const { t } = useTranslation();
    const {
        numberActive,
        numberArchived,
        isHistoryModalOpened,
        shopsPaymentMethodsData,
        isInlineErrorCleared,
        listFranchiseArchived,
        isActionsClicked,
        isEdited,
        modify,
        isLoading,
    } = useSnapshot(choiceStore);
    const { userID, franchiseID, oneShop, operatorID, shopID } = useSnapshot(
        store
    );
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    const shopId = oneShop ? shopID : 0;
    let dataArchive: any[] = [];
    if (!oneShop) {
        dataArchive = ArchivedPaymentMethod();
    } else {
        dataArchive = ArchivedPaymentMethodFranchise();
    }

    function ArchivedPaymentMethod() {
        let idsAssociated: any[] = [];
        if (shopsPaymentMethodsData !== undefined) {
            shopsPaymentMethodsData.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    idsAssociated.push(el.Id);
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function ArchivedPaymentMethodFranchise() {
        let idsAssociated: any[] = [];
        if (listFranchiseArchived !== undefined) {
            listFranchiseArchived.forEach((element: any) => {
                idsAssociated.push(element.id);
            });
        }

        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    const [
        isArchivedValidationModalOpened,
        setIsArchivedValidationModalOpened,
    ] = React.useState<boolean>(false);
    const [isValidateClicked, setIsValidateClicked] = React.useState<boolean>(
        false
    );
    const [isActive, setIsActive] = React.useState<boolean>(false);

    function paymentMethodExistInShops() {
        let isIdExist;
        let idsAssociated: any = [];
        shopsPaymentMethodsData.forEach((el: any) => {
            el.subRows.forEach((element: any) => {
                idsAssociated.push(element.id);
            });
        });

        selectedRows.forEach((el: any) => {
            if (idsAssociated.includes(el.id)) {
                isIdExist = true;
            }
        });
        if (isIdExist === true && !oneShop) {
            ErrorToast(
                `${t(
                    "Please note that you cannot archive an item assigned to stores."
                )!}`
            );
            setIsLoading(false);
        } else {
            if (!isArchivedPaymentMethodsClicked) {
                setIsArchivedValidationModalOpened(true);
                setIsLoading(false);
            } else {
                handleArchivePaymentMethod(true);
            }
        }
    }

    function PaymentMethodExistInShopsActivated() {
        let isIdExist;
        let idsAssociated: any = [];
        shopsPaymentMethodsData.forEach((el: any) => {
            el.subRows.forEach((element: any) => {
                idsAssociated.push(element.id);
            });
        });

        selectedRows.forEach((el: any) => {
            if (idsAssociated.includes(el.id)) {
                isIdExist = true;
            }
        });
        if (isIdExist === true) {
            setIsModalConfirmationOpened(!isModalConfirmationOpened);
            setIsLoading(false);
        } else {
            !isArchivedPaymentMethodsClicked
                ? setIsArchivedValidationModalOpened(true)
                : handleArchivePaymentMethod(false);
        }
    }

    async function handleArchivePaymentMethod(value: boolean) {
        setIsLoading(true);
        toast.dismiss();
        let localData: any = [];
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod`;
        if (selectedRows.length !== 0) {
            selectedRows.forEach(async (el: any) => {
                let objectData: any = {
                    userId: userID,
                    shopId: [selectedRows[0].idShop],
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    data: {
                        [el.id]: {
                            designation: el[t("Type of payment method")],
                            currency: el.Devise,
                            display: el[t("Display name")],
                            shopId: el.idShop,
                            saleSupport: el[t("Support de vente")],
                            country: el.Pays,
                            tag: el.Tag,
                            isActive: false,
                            isArchived: value,
                            advancedDisplayName: el.advancedDisplayName,
                        },
                    },
                };
                localData.push(
                    fetch(apiUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(objectData),
                    }).then((response) => response.json())
                );
            });

            try {
                mutate(
                    apiUrl,
                    await Promise.all(localData).then((result: any) => {
                        let errors: boolean[] = result.map(
                            (el: any) => el.error
                        );
                        if (!errors.includes(true)) {
                            toast.success(
                                !value
                                    ? `${t(
                                          "The Payment method is successfully unarchived"
                                      )!}`
                                    : `${t(
                                          "The Payment method is successfully archived"
                                      )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setIsValidateClicked(false);
                            setIsArchivedValidationModalOpened(false);
                            setKey(uuid());
                            setIsLoading(false);
                        } else {
                            throw Error(result.message);
                        }
                    })
                );
            } catch (e) {
                setIsLoading(false);
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    async function isSaleSupportArchived() {
        toast.dismiss();
        let isSaleSupportArchived = false;
        const url = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=archived`;
        mutate(
            url,
            await fetch(url, {
                method: "GET",
                headers: { "Accept-Language": i18nextLng },
            })
                .then((response) => response.json())
                .then((data) => {
                    data.data.forEach((elt: any) => {
                        selectedRows.forEach((element: any) => {
                            element[t("Sales support")]
                                .split(",")
                                .forEach((sale: any) => {
                                    if (sale === elt[t("Sale support")]) {
                                        isSaleSupportArchived = true;
                                    }
                                });
                        });
                    });
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
        return isSaleSupportArchived;
    }
    React.useEffect(() => {
        if (isValidateClicked) {
            handleArchivePaymentMethod(isActive);
        }
        // eslint-disable-next-line
    }, [isValidateClicked, isActive]);

    return (
        <div className="d-flex justify-content-start align-items-center ml-2">
            {modify === true ? (
                <>
                    {isAddChoiceClicked !== true ? (
                        <span
                            onClick={() => {
                                setIsAddChoiceClicked(!isAddChoiceClicked);
                                setLocalFilterActive(false);
                            }}
                            className={`${classnames(
                                "w-100 ml-2 cmn_btn_openForm",
                                {
                                    "not-allowed-icon__clz": isArchivedPaymentMethodsClicked,
                                }
                            )}`}
                        >
                            <CustomMainColorButtonWithIcon
                                icon="PlusIcon"
                                iconPosition="left"
                                rounded
                                onClick={() => {
                                    setIsInlineErrorCleared(
                                        !isInlineErrorCleared
                                    );
                                }}
                            >
                                {t("Add a payment method")}
                            </CustomMainColorButtonWithIcon>
                        </span>
                    ) : (
                        <div className="d-flex justify-content-start">
                            <CustomMainColorIconButton
                                icon="PlusIcon"
                                className="mdr_btn_FRreset"
                                rounded
                                disabled={choice.length === 0 ? false : true}
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsInInitializationModalOpened(true);
                                    } else {
                                        setIsInlineErrorCleared(true);
                                    }
                                }}
                            >
                                <PlusIcon height={20} width={20} fill="white" />
                            </CustomMainColorIconButton>
                        </div>
                    )}
                </>
            ) : null}

            <VerticalDotsIcon
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
                className="cmn_drp_FRmenu"
            />
            <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton mdr_drp_FRbuttonMenu"
            >
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item pl-1 cmn_btn_FRlistActivated"
                    )}
                    onClick={() => {
                        if (isEdited === true) {
                            setIsActionsClicked(!isActionsClicked);
                            setActions(() => {
                                setIsEdited(false);
                                clearData();
                                clearMultiCustomSelectionData();
                                setIsAddChoiceClicked(false);
                                setIsArchivedPaymentMethodsClicked(false);
                            });
                        } else {
                            setIsAddChoiceClicked(false);
                            setIsArchivedPaymentMethodsClicked(false);
                        }
                    }}
                >
                    {t("Enabled payment methods")}({numberActive})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item pl-1 cmn_btn_FRlistArchived"
                    )}
                    onClick={() => {
                        if (isEdited === true) {
                            setIsActionsClicked(!isActionsClicked);
                            setActions(() => {
                                setIsAddChoiceClicked(false);
                                setIsEdited(false);
                                clearData();
                                clearMultiCustomSelectionData();
                                setIsArchivedPaymentMethodsClicked(true);
                            });
                        } else {
                            setIsAddChoiceClicked(false);
                            setIsArchivedPaymentMethodsClicked(true);
                        }
                    }}
                >
                    {t("Archived payment methods")}({numberArchived})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={`${classnames(
                        "dropdown-item pl-1 cmn_btn_FRhistory",
                        {
                            "not-allowed-icon__clz":
                                selectedRows.length === 0 || isLoading,
                        }
                    )}`}
                    onClick={() => {
                        if (isEdited === true) {
                            setIsActionsClicked(!isActionsClicked);
                            setActions(() => {
                                setIsEdited(false);
                                clearData();
                                clearMultiCustomSelectionData();
                                setIsAddChoiceClicked(false);
                                if (selectedRows.length === 1) {
                                    ErrorToast(
                                        `${t(
                                            "Attention, it is a multiple action. Please select at least two items"
                                        )!}`
                                    );
                                } else {
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                                }
                            });
                        } else {
                            setIsAddChoiceClicked(false);
                            if (selectedRows.length === 1) {
                                ErrorToast(
                                    `${t(
                                        "Attention, it is a multiple action. Please select at least two items"
                                    )!}`
                                );
                            } else {
                                setIsHistoryModalOpened(!isHistoryModalOpened);
                            }
                        }
                    }}
                >
                    {t("Historical")}
                </div>
                {modify === true ? (
                    <>
                        <div
                            style={{ cursor: "pointer" }}
                            className={`${classnames("dropdown-item pl-1", {
                                "not-allowed-icon__clz":
                                    selectedRows.length === 0 ||
                                    (isArchivedPaymentMethodsClicked &&
                                        oneShop &&
                                        selectedRows.some((element: any) =>
                                            dataArchive.includes(element.id)
                                        )),
                            })}`}
                            onClick={async () => {
                                setIsLoading(true);
                                let isArchived = await isSaleSupportArchived();

                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsLoading(false);
                                        setIsAddChoiceClicked(false);
                                        setIsEdited(false);
                                        clearData();
                                        clearMultiCustomSelectionData();
                                        if (selectedRows.length === 1) {
                                            ErrorToast(
                                                `${t(
                                                    "Attention, it is a multiple action. Please select at least two items"
                                                )!}`
                                            );
                                        } else if (
                                            isArchived === true &&
                                            isArchivedPaymentMethodsClicked
                                        ) {
                                            ErrorToast(
                                                `${t(
                                                    "Please note that  the unarchiving process could not be successfully completed. Please check the settings of the store(s) data."
                                                )!}`
                                            );
                                        } else if (
                                            !isArchivedPaymentMethodsClicked
                                        ) {
                                            paymentMethodExistInShops();
                                            setIsActive(true);
                                        } else {
                                            PaymentMethodExistInShopsActivated();

                                            setIsActive(false);
                                        }
                                    });
                                } else {
                                    setIsAddChoiceClicked(false);

                                    if (selectedRows.length === 1) {
                                        ErrorToast(
                                            `${t(
                                                "Attention, it is a multiple action. Please select at least two items"
                                            )!}`
                                        );
                                        setIsLoading(false);
                                    } else if (
                                        isArchived === true &&
                                        isArchivedPaymentMethodsClicked
                                    ) {
                                        ErrorToast(
                                            `${t(
                                                "Please note that  the unarchiving process could not be successfully completed. Please check the settings of the store(s) data."
                                            )!}`
                                        );
                                        setIsLoading(false);
                                    } else if (
                                        !isArchivedPaymentMethodsClicked
                                    ) {
                                        paymentMethodExistInShops();
                                        setIsActive(true);
                                    } else {
                                        PaymentMethodExistInShopsActivated();
                                        setIsActive(false);
                                    }
                                }
                            }}
                        >
                            <span
                                className={classnames(
                                    "cmn_btn_FRarchived cmn_btn_FRunarchive",
                                    {
                                        "not-allowed-icon__clz": isLoading,
                                    }
                                )}
                            >
                                {isArchivedPaymentMethodsClicked
                                    ? t("Unarchive")
                                    : t("Archive")}
                            </span>
                        </div>
                    </>
                ) : null}
            </div>
            {isArchivedValidationModalOpened ? (
                <ArchivedValidationModal
                    text={t(
                        "Attention, this (these) payment method can be used by other modules.\n\nDo you want to confirm the archiving of the selected of payment method(s)"
                    )}
                    isModalOpened={isArchivedValidationModalOpened}
                    setIsModalOpened={setIsArchivedValidationModalOpened}
                    setIsValidateClicked={setIsValidateClicked}
                />
            ) : null}
        </div>
    );
}
