import {
    ConfigurationTemplateIcon,
    DeviceSettingsIcon,
} from "@components/Common/SvgIcons";

export type CardMenuType = {
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    description?: string;
    title: string;
    number: number;
    path: string;
};

export const CARD_MENU_LIST: CardMenuType[] = [
    {
        title: "Project setup",
        number: 0,
        Icon: ConfigurationTemplateIcon,
        description:
            "In this section, you have the ability to configure the ORB (Order Routing Boards), allowing you to customize them according to the specific needs of your store.",
        path: "/orb-project-setup",
    },
    {
        title: "ORB Setup",
        number: 0,
        Icon: DeviceSettingsIcon,
        description:
            "In this section, you can perform the declaration and configuration of the ORB (Order Routing Boards)  of the store.",
        path: "/orb-setup",
    },
];
