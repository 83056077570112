import React from "react";
import { t } from "i18next";
import { EyeIcon, PencilIcon, TrashIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { Tooltip } from "@mui/material";

import store, {
    setSelectedMonnyeur,
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setIsLoading,
} from "./store";
import { store as globalStore } from "../../../components/VerticalLayout/store";
import { store as userStore } from "@components/VerticalLayout/store";

import { verifyIfPeripheralAssociate } from "../../../helpers/peripheralsHelpers";
import ErrorToast from "@components/Common/ErrorTost";
import ConsultSvgIcon from "@components/Common/SvgIcons/ConsultSvgIcon";
import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";

export function CustomIcons({
    e,
    setShopId,
    setEditedData,
    setIsModalOpened,
    isModalOpened,
    setCashManagementId,
    setObjectApiUrlDissociate,
    setInputIp,
    setLocalShop,
    setIsConfirmationEditRunning,
    typeDisplay,
    setIsConsult,
    setSelectedRows,
    setIsHistoryModalOpened,
    setIsAddCashManagementClicked,
    isAddCashManagementClicked,
}: any) {
    const { shopID, oneShop } = useSnapshot(globalStore);
    const {
        isActionsClicked,
        isEdited,
        isUpdateOperation,
        modify,
        isLoading,
    } = useSnapshot(store);
    const { userID } = useSnapshot(userStore);
    const { franchiseID } = useSnapshot(userStore);
    const appId = "KIOSK";
    let ifPeripheralAssociateObject: {
        isPeripheralAssociate: boolean;
        appId_children: any[];
    };
    return (
        <React.Fragment>
            {e.selectedRow.subRows === undefined ? (
                <div className="d-flex justify-content-around ml-3">
                    {/* <div className="col-2"> */}
                    {(modify === false || typeDisplay === "archived") && (
                        <Tooltip title={t("To consult").toString()}>
                            <div>
                                <ConsultSvgIcon
                                    className="cmn_icn_toConsult"
                                    height={35}
                                    width={35}
                                    onClick={() => {
                                        setIsConsult(true);
                                        setShopId(
                                            oneShop
                                                ? shopID
                                                : e.selectedRow.shopId
                                        );
                                        setLocalShop([e.selectedRow.shopId]);
                                        setEditedData(e.selectedRow);
                                        setInputIp(false);

                                        setIsAddCashManagementClicked(true);
                                    }}
                                    style={{
                                        cursor: "pointer",
                                        position: "relative",
                                        bottom: "5px",
                                    }}
                                />
                            </div>
                        </Tooltip>
                    )}
                    {modify === true ? (
                        <>
                            {typeDisplay === "notArchived" ? (
                                <Tooltip title={t("Edit").toString()}>
                                    <div>
                                        <PencilIcon
                                            height={25}
                                            width={25}
                                            className={` ${classnames(
                                                "cmn_icn_FRedit",
                                                {
                                                    "not-allowed-icon__clz":
                                                        typeDisplay ===
                                                        "archived",
                                                }
                                            )}`}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                if (isEdited === true) {
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(() => {
                                                        setIsEdited(false);
                                                        setShopId(
                                                            oneShop
                                                                ? shopID
                                                                : e.selectedRow
                                                                      .shopId
                                                        );
                                                        setEditedData(
                                                            e.selectedRow
                                                        );
                                                        setInputIp(false);
                                                        setLocalShop([
                                                            e.selectedRow
                                                                .shopId,
                                                        ]);

                                                        setIsAddCashManagementClicked(
                                                            true
                                                        );
                                                    });
                                                } else {
                                                    setShopId(
                                                        oneShop
                                                            ? shopID
                                                            : e.selectedRow
                                                                  .shopId
                                                    );
                                                    setLocalShop([
                                                        e.selectedRow.shopId,
                                                    ]);
                                                    setEditedData(
                                                        e.selectedRow
                                                    );
                                                    setInputIp(false);

                                                    setIsAddCashManagementClicked(
                                                        true
                                                    );
                                                }
                                            }}
                                        />
                                    </div>
                                </Tooltip>
                            ) : null}
                            {/* </div> */}

                            {/* <div className="col-2"> */}
                            {typeDisplay === "notArchived" ? (
                                <Tooltip title={t("Archive").toString()}>
                                    <div>
                                        <TrashIcon
                                            className={`${classnames(
                                                "cmn_icn_FRarchived ml-2",
                                                {
                                                    "not-allowed-icon__clz": isLoading,
                                                }
                                            )}`}
                                            onClick={async () => {
                                                setIsLoading(true);
                                                if (isEdited === true) {
                                                    setIsLoading(false);
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(async () => {
                                                        setIsEdited(false);
                                                        setIsAddCashManagementClicked(
                                                            false
                                                        );
                                                        if (
                                                            isUpdateOperation ===
                                                            true
                                                        ) {
                                                            setIsConfirmationEditRunning(
                                                                true
                                                            );
                                                        } else {
                                                            setShopId(
                                                                oneShop
                                                                    ? shopID
                                                                    : e
                                                                          .selectedRow
                                                                          .shopId
                                                            );
                                                            ifPeripheralAssociateObject = await verifyIfPeripheralAssociate(
                                                                e.selectedRow
                                                                    .Id,
                                                                e.selectedRow
                                                                    .shopId,
                                                                franchiseID,
                                                                userID,
                                                                appId
                                                            );
                                                            setObjectApiUrlDissociate(
                                                                {
                                                                    userId: userID,
                                                                    shopId:
                                                                        e
                                                                            .selectedRow
                                                                            .shopId,
                                                                    franchiseId: franchiseID,
                                                                    appId: appId,
                                                                    appId_children:
                                                                        ifPeripheralAssociateObject.appId_children,
                                                                    peripheralId:
                                                                        e
                                                                            .selectedRow
                                                                            .Id,
                                                                }
                                                            );

                                                            if (
                                                                ifPeripheralAssociateObject.isPeripheralAssociate
                                                            ) {
                                                                // setIsModalDeleteAssociatePeripheralOpened(
                                                                //     !isModalDeleteAssociatePeripheralOpened
                                                                // );
                                                                ErrorToast(
                                                                    t(
                                                                        "Please note that you cannot archive a device associated with an application."
                                                                    )
                                                                );
                                                            } else {
                                                                setIsModalOpened(
                                                                    !isModalOpened
                                                                );
                                                            }
                                                            setCashManagementId(
                                                                e.selectedRow.Id
                                                            );
                                                        }
                                                    });
                                                } else {
                                                    if (
                                                        isUpdateOperation ===
                                                        true
                                                    ) {
                                                        setIsConfirmationEditRunning(
                                                            true
                                                        );
                                                        setIsLoading(false);
                                                    } else {
                                                        setShopId(
                                                            oneShop
                                                                ? shopID
                                                                : e.selectedRow
                                                                      .shopId
                                                        );
                                                        ifPeripheralAssociateObject = await verifyIfPeripheralAssociate(
                                                            e.selectedRow.Id,
                                                            e.selectedRow
                                                                .shopId,
                                                            franchiseID,
                                                            userID,
                                                            appId
                                                        );
                                                        setObjectApiUrlDissociate(
                                                            {
                                                                userId: userID,
                                                                shopId:
                                                                    e
                                                                        .selectedRow
                                                                        .shopId,
                                                                franchiseId: franchiseID,
                                                                appId: appId,
                                                                appId_children:
                                                                    ifPeripheralAssociateObject.appId_children,
                                                                peripheralId:
                                                                    e
                                                                        .selectedRow
                                                                        .Id,
                                                            }
                                                        );

                                                        if (
                                                            ifPeripheralAssociateObject.isPeripheralAssociate
                                                        ) {
                                                            // setIsModalDeleteAssociatePeripheralOpened(
                                                            //     !isModalDeleteAssociatePeripheralOpened
                                                            // );
                                                            ErrorToast(
                                                                t(
                                                                    "Please note that you cannot archive a device associated with an application."
                                                                )
                                                            );
                                                            setIsLoading(false);
                                                        } else {
                                                            setIsModalOpened(
                                                                !isModalOpened
                                                            );
                                                            setIsLoading(false);
                                                        }
                                                        setCashManagementId(
                                                            e.selectedRow.Id
                                                        );
                                                    }
                                                }
                                            }}
                                            style={{ cursor: "pointer" }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip title={t("Unarchive").toString()}>
                                    <div>
                                        <EyeIcon
                                            className={`${classnames(
                                                "cmn_icn_unarchive",
                                                {
                                                    "not-allowed-icon__clz": isLoading,
                                                }
                                            )}`}
                                            onClick={async () => {
                                                setIsLoading(true);
                                                if (
                                                    isUpdateOperation === true
                                                ) {
                                                    setIsConfirmationEditRunning(
                                                        true
                                                    );
                                                    setIsLoading(false);
                                                } else {
                                                    setShopId(
                                                        oneShop
                                                            ? shopID
                                                            : e.selectedRow
                                                                  .shopId
                                                    );
                                                    ifPeripheralAssociateObject = await verifyIfPeripheralAssociate(
                                                        e.selectedRow.Id,
                                                        e.selectedRow.shopId,
                                                        franchiseID,
                                                        userID,
                                                        appId
                                                    );
                                                    setObjectApiUrlDissociate({
                                                        userId: userID,
                                                        shopId:
                                                            e.selectedRow
                                                                .shopId,
                                                        franchiseId: franchiseID,
                                                        appId: appId,
                                                        appId_children:
                                                            ifPeripheralAssociateObject.appId_children,
                                                        peripheralId:
                                                            e.selectedRow.Id,
                                                    });

                                                    if (
                                                        ifPeripheralAssociateObject.isPeripheralAssociate
                                                    ) {
                                                        // setIsModalDeleteAssociatePeripheralOpened(
                                                        //     !isModalDeleteAssociatePeripheralOpened
                                                        // );
                                                        ErrorToast(
                                                            t(
                                                                "Please note that you cannot archive a device associated with an application."
                                                            )
                                                        );
                                                        setIsLoading(false);
                                                    } else {
                                                        setIsModalOpened(
                                                            !isModalOpened
                                                        );
                                                        setIsLoading(false);
                                                    }
                                                    setCashManagementId(
                                                        e.selectedRow.Id
                                                    );
                                                }
                                            }}
                                            style={{ cursor: "pointer" }}
                                            height={25}
                                            width={25}
                                            fill="black"
                                        />
                                    </div>
                                </Tooltip>
                            )}
                            <Tooltip title={t("Consult history").toString()}>
                                <div>
                                    <TimePastSvgIcon
                                        onClick={() => {
                                            if (isEdited === true) {
                                                setIsActionsClicked(
                                                    !isActionsClicked
                                                );
                                                setActions(() => {
                                                    setIsEdited(false);
                                                    setIsHistoryModalOpened(
                                                        true
                                                    );
                                                    setSelectedMonnyeur([
                                                        {
                                                            [e.selectedRow
                                                                .shopId]:
                                                                e.selectedRow
                                                                    .Id,
                                                        },
                                                    ]);
                                                    setSelectedRows([
                                                        e.selectedRow,
                                                    ]);
                                                });
                                            } else {
                                                setIsEdited(false);
                                                setIsHistoryModalOpened(true);
                                                setSelectedMonnyeur([
                                                    {
                                                        [e.selectedRow.shopId]:
                                                            e.selectedRow.Id,
                                                    },
                                                ]);
                                                setSelectedRows([
                                                    e.selectedRow,
                                                ]);
                                            }
                                        }}
                                        className="ml-2 cmn_icn_history"
                                        style={{ cursor: "pointer" }}
                                    />
                                </div>
                            </Tooltip>
                        </>
                    ) : (
                        <>
                            <Tooltip title={t("Consult history").toString()}>
                                <div>
                                    <TimePastSvgIcon
                                        onClick={() => {
                                            if (isEdited === true) {
                                                setIsActionsClicked(
                                                    !isActionsClicked
                                                );
                                                setActions(() => {
                                                    setIsEdited(false);
                                                    setIsHistoryModalOpened(
                                                        true
                                                    );
                                                    setSelectedMonnyeur([
                                                        {
                                                            [e.selectedRow
                                                                .shopId]:
                                                                e.selectedRow
                                                                    .Id,
                                                        },
                                                    ]);
                                                    setSelectedRows([
                                                        e.selectedRow,
                                                    ]);
                                                });
                                            } else {
                                                setIsEdited(false);
                                                setIsHistoryModalOpened(true);
                                                setSelectedMonnyeur([
                                                    {
                                                        [e.selectedRow.shopId]:
                                                            e.selectedRow.Id,
                                                    },
                                                ]);
                                                setSelectedRows([
                                                    e.selectedRow,
                                                ]);
                                            }
                                        }}
                                        className="ml-2 cmn_icn_history"
                                        style={{ cursor: "pointer" }}
                                    />
                                </div>
                            </Tooltip>
                        </>
                    )}
                </div>
            ) : // </div>
            null}
        </React.Fragment>
    );
}
