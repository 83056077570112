import React from "react";
import { useSnapshot } from "valtio";
import classNames from "classnames";

import { designStore } from "@store";

import { MainContentWrapper } from "./MainContentWrapper";
import { ContainerWrapper } from "./ContainerWrapper";
import { LanguagesWrapper } from "./LanguagesWrapper";
import { ActionsButtonsWrapper } from "./ActionsButtonsWrapper";
import { ConfigOptionsWrapper } from "./ConfigOptionsWrapper";
import { TopBannerWrapper } from "./TopBannerWrapper";
import { HeaderWrapper } from "./HeaderWrapper";
import { SubStepWrapper } from "./SubStepWrapper";
import { PreviewBackgroundWrapper } from "./PreviewBackgroundWrapper";
import { AccountFidelityWrapper } from "./AccountFidelityWrapper";

export function Preview(): JSX.Element {
    const {
        isTopBannerActive,
        isHeaderActive,
        isLanguagesActive,
        isConfigOptionsActive,
        isLogoActive,
        isActionButtonsActive,
        activeTabIndex,
        isSubStepActivated,
        activePageName,
    } = useSnapshot(designStore);

    return (
        <React.Fragment>
            {isSubStepActivated === true ? (
                activePageName === "connection" ? (
                    <AccountFidelityWrapper />
                ) : (
                    <SubStepWrapper />
                )
            ) : (
                <PreviewBackgroundWrapper>
                    <ContainerWrapper
                        isTopBannerActive={isTopBannerActive}
                        isHeaderActive={isHeaderActive}
                        isLanguagesActive={isLanguagesActive}
                        isConfigOptionsActive={isConfigOptionsActive}
                        isLogoActive={isLogoActive}
                        isActionButtonsActive={isActionButtonsActive}
                    >
                        {isTopBannerActive ? <TopBannerWrapper /> : null}

                        {isHeaderActive ? <HeaderWrapper /> : null}

                        <div
                            className={classNames(
                                "d-flex justify-content-center align-items-center",
                                {
                                    "border-wrapper__clz": activeTabIndex === 2,
                                }
                            )}
                            style={{
                                width: "429px",
                            }}
                        >
                            <MainContentWrapper />
                        </div>
                        {isActionButtonsActive === true ? (
                            <div
                                className={classNames({
                                    "border-wrapper__clz": activeTabIndex === 3,
                                })}
                            >
                                <ActionsButtonsWrapper />
                            </div>
                        ) : null}

                        <LanguagesWrapper />

                        {isConfigOptionsActive ? (
                            <ConfigOptionsWrapper />
                        ) : null}
                    </ContainerWrapper>
                </PreviewBackgroundWrapper>
            )}
        </React.Fragment>
    );
}
