import React from "react";
import classnames from "classnames";
import ReactSwitch from "react-switch";
import { toast } from "react-toastify";
import { uuid } from "uuidv4";

import { CheckboxIcon, EyeIcon, TrashIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { Tooltip } from "@mui/material";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";

import { store as ModeOfInformation } from "../../../../components/VerticalLayout/store";
import store, {
    setFnDelete,
    setIsDeleteCheckClick,
    setNumberActiveMultiShop,
    setNumberArchiveMultiShop,
    setIsLoading,
    setKey,
} from "../store";
import ErrorToast from "@components/Common/ErrorTost";
import { generalConfigStore, setItemsDisable } from "@store";

export function CustomIcons({
    e,
    setRowData,
    setDataIsUpdated,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    setSelectedRows,
    isModeOfInformationArchived,
    setIsModeOfInformationArchived,
}: any) {
    const { t } = useTranslation();
    const {
        numberActiveMultiShop,
        numberArchiveMultiShop,
        modeInformationData,
        modify,
        isLoading,
    } = useSnapshot(store);
    const { franchiseID, userID, oneShop, operatorID } = useSnapshot(
        ModeOfInformation
    );
    const { itemsDisable } = useSnapshot(generalConfigStore);
    const [modeOfInformationState, setModeOfInformationState] = React.useState<
        boolean
    >(e.selectedRow.state);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    let dataArchive: any[] = [];

    if (!oneShop) {
        dataArchive = ArchiveModeOfInformation();
    }
    function ArchiveModeOfInformation() {
        let idsAssociated: any = [];
        if (modeInformationData.data !== undefined) {
            modeInformationData.data.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    async function handleStateChange() {
        let id = e.selectedRow.Id;

        if (id !== undefined) {
            let dataConfig = [
                {
                    userId: userID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    shopId: [e.selectedRow.shopId],
                    franchiseId: franchiseID,
                    isShop: false,
                    data: {
                        [id]: {
                            designation:
                                e.selectedRow[t("Mode of information")],
                            idMI: e.selectedRow.idMI,
                            supportOfSale: e.selectedRow[t("Sales support")],
                            salesSupportData: [
                                e.selectedRow[t("Sales support")],
                            ],
                            displayName: e.selectedRow[t("Display name")],
                            modeOfSale: e.selectedRow[t("Sales method")],
                            tag: e.selectedRow.tag,
                            isArchived: false,
                            state: !modeOfInformationState,
                            shopId: e.selectedRow.shopId,
                            stateShop: !modeOfInformationState,
                            advancedDisplayName:
                                e.selectedRow.advancedDisplayName,
                        },
                    },
                },
            ];
            const isStateChange = true;
            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`;
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ dataConfig, isStateChange }),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.error) {
                            throw Error(result.message);
                        }

                        toast.success(
                            modeOfInformationState === true
                                ? `${t(
                                      "The Mode of information has been successfully deactivated"
                                  )}`
                                : `${t(
                                      "The Mode of information has been successfully activated"
                                  )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated!(true);
                    })
                    .catch((error: any) => {
                        toast.error(`${t("There's an error")!}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    })
            );
        }
    }

    async function activeModeOfInformation() {
        toast.dismiss();
        if (e.selectedRow.Id !== undefined) {
            let dataModeOfInformation = [
                {
                    userId: userID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    shopId: [e.selectedRow.shopId],
                    franchiseId: franchiseID,
                    isShop: false,
                    data: {
                        [e.selectedRow.Id]: {
                            designation:
                                e.selectedRow[t("Mode of information")],
                            idMI: e.selectedRow.idMI,
                            supportOfSale: e.selectedRow[t("Sales support")],
                            salesSupportData: [
                                e.selectedRow[t("Sales support")],
                            ],
                            displayName: e.selectedRow[t("Display name")],
                            modeOfSale: e.selectedRow[t("Sales method")],
                            tag: e.selectedRow.tag,
                            isArchived: false,
                            state: e.selectedRow.state,
                            shopId: e.selectedRow.shopId,
                            stateShop: e.selectedRow.state,
                            advancedDisplayName:
                                e.selectedRow.advancedDisplayName,
                        },
                    },
                },
            ];

            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`;
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(dataModeOfInformation),
            };
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    "Information mode has been successfully unarchived"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setKey(uuid());
                            setIsLoading(false);

                            if (
                                e.selectedRow.state === false &&
                                itemsDisable.find(
                                    (item: any) =>
                                        item.id === e.selectedRow.Id &&
                                        item.shopId === e.selectedRow.shopId
                                ) === undefined
                            ) {
                                const item = [
                                    ...JSON.parse(JSON.stringify(itemsDisable)),
                                    {
                                        id: e.selectedRow.Id,
                                        state: false,
                                        shopId: e.selectedRow.shopId,
                                    },
                                ];
                                setItemsDisable(item);
                                localStorage.setItem(
                                    "itemsDisable",
                                    JSON.stringify(item)
                                );
                            }
                        })
                );
            } catch (e: any) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsLoading(false);
            }
        }
    }
    async function archiveModeOfInformation() {
        setIsLoading(true);
        toast.dismiss();
        if (e.selectedRow.Id !== undefined) {
            let dataModeOfInformation = [
                {
                    userId: userID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    shopId: [e.selectedRow.shopId],
                    franchiseId: franchiseID,
                    isShop: false,
                    data: {
                        [e.selectedRow.Id]: {
                            designation:
                                e.selectedRow[t("Mode of information")],
                            supportOfSale: e.selectedRow[t("Sales support")],
                            idMI: e.selectedRow.idMI,
                            salesSupportData: [
                                e.selectedRow[t("Sales support")],
                            ],
                            displayName: e.selectedRow[t("Display name")],
                            modeOfSale: e.selectedRow[t("Sales method")],
                            tag: e.selectedRow.tag,
                            isArchived: true,
                            state: false,
                            shopId: e.selectedRow.shopId,
                            stateShop: e.selectedRow.state,
                            advancedDisplayName:
                                e.selectedRow.advancedDisplayName,
                        },
                    },
                },
            ];

            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`;
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(dataModeOfInformation),
            };
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    "The Information mode has been successfully archived"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setKey(uuid());
                            setIsLoading(false);

                            const item = itemsDisable.find(
                                (item: any) =>
                                    item.id === e.selectedRow.Id &&
                                    item.shopId === e.selectedRow.shopId
                            );
                            if (item !== undefined) {
                                const item = itemsDisable.filter(
                                    (item: any) =>
                                        !(
                                            item.id === e.selectedRow.Id &&
                                            item.shopId === e.selectedRow.shopId
                                        )
                                );
                                setItemsDisable(item);
                                localStorage.setItem(
                                    "itemsDisable",
                                    JSON.stringify(item)
                                );
                            }
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsLoading(false);
            }
        }
    }

    async function isSaleSupportArchived() {
        toast.dismiss();
        let isSaleSupportArchived = false;
        const url = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale_archived?userId=${userID}&franchiseId=${franchiseID}&uuidUser=${uuidUser}`;
        mutate(
            url,
            await fetch(url, {
                method: "GET",
                headers: { "Accept-Language": i18nextLng },
            })
                .then((response) => response.json())
                .then((data) => {
                    data.data.forEach((elt: any) => {
                        e.selectedRow[t("Sales method")].forEach(
                            (sale: any) => {
                                if (
                                    elt.subRows.some(
                                        (saleMethod: any) =>
                                            `${saleMethod["Mode of sale"]} ${saleMethod.shopId}` ===
                                            `${sale.label} ${e.selectedRow.shopId}`
                                    )
                                ) {
                                    isSaleSupportArchived = true;
                                }
                            }
                        );
                    });
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );

        return isSaleSupportArchived;
    }

    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around ">
                    {modify === true ? (
                        <>
                            <Tooltip
                                title={
                                    isModeOfInformationArchived
                                        ? ""
                                        : modeOfInformationState === false
                                        ? t("Activate")
                                        : t("Unactivate")
                                }
                            >
                                <div>
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={modeOfInformationState}
                                        onChange={async () => {
                                            setModeOfInformationState(
                                                !modeOfInformationState
                                            );
                                            handleStateChange();

                                            if (
                                                !modeOfInformationState ===
                                                false
                                            ) {
                                                const item = [
                                                    ...JSON.parse(
                                                        JSON.stringify(
                                                            itemsDisable
                                                        )
                                                    ),
                                                    {
                                                        id: e.selectedRow.idMI,
                                                        state: !modeOfInformationState,
                                                        shopId:
                                                            e.selectedRow
                                                                .shopId,
                                                    },
                                                ];
                                                setItemsDisable(item);

                                                localStorage.setItem(
                                                    "itemsDisable",
                                                    JSON.stringify(item)
                                                );
                                            } else if (
                                                !modeOfInformationState === true
                                            ) {
                                                const item = itemsDisable.filter(
                                                    (item: any) =>
                                                        !(
                                                            item.id ===
                                                                e.selectedRow
                                                                    .idMI &&
                                                            item.shopId ===
                                                                e.selectedRow
                                                                    .shopId
                                                        )
                                                );
                                                setItemsDisable(item);

                                                localStorage.setItem(
                                                    "itemsDisable",
                                                    JSON.stringify(item)
                                                );
                                            }
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                        className={`${classnames(
                                            "pointer__clz ml-1 cmn_icn_BTenableDisable",
                                            {
                                                "not-allowed-icon__clz": isModeOfInformationArchived,
                                            }
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                            {!isModeOfInformationArchived ? (
                                <Tooltip title={t("Archive")}>
                                    <div>
                                        <TrashIcon
                                            onClick={() => {
                                                setIsDeleteCheckClick(true);
                                                setFnDelete(async () => {
                                                    archiveModeOfInformation();
                                                    setNumberActiveMultiShop(
                                                        numberActiveMultiShop -
                                                            1
                                                    );
                                                    setNumberArchiveMultiShop(
                                                        numberArchiveMultiShop +
                                                            1
                                                    );
                                                });
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{ cursor: "pointer" }}
                                            className={`${classnames(
                                                "ml-3 cmn_icn_BTarchived",
                                                {
                                                    "not-allowed-icon__clz": isLoading,
                                                }
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={
                                        dataArchive.includes(e.selectedRow.Id)
                                            ? ""
                                            : t("Unarchive")
                                    }
                                >
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            style={{ cursor: "pointer" }}
                                            onClick={async () => {
                                                let isArchived = await isSaleSupportArchived();
                                                setIsLoading(true);
                                                if (isArchived === true) {
                                                    ErrorToast(
                                                        `${t(
                                                            "Please note that  the unarchiving process could not be successfully completed. Please check the settings of the store(s) data."
                                                        )!}`
                                                    );
                                                    setIsLoading(false);
                                                } else {
                                                    activeModeOfInformation();
                                                    setNumberActiveMultiShop(
                                                        numberActiveMultiShop +
                                                            1
                                                    );
                                                    setNumberArchiveMultiShop(
                                                        numberArchiveMultiShop -
                                                            1
                                                    );
                                                }
                                            }}
                                            className={`${classnames(
                                                "pointer__clz ml-2 cmn_icn_BTunarchive",
                                                {
                                                    "not-allowed-icon__clz":
                                                        isLoading ||
                                                        dataArchive.includes(
                                                            e.selectedRow.Id
                                                        ),
                                                }
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}
                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                className="pointer__clz ml-2 cmn_icn_BThistory"
                                onClick={() => {
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                                    setRowData(e.selectedRow);
                                    setSelectedRows([e.selectedRow]);
                                }}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </React.Fragment>
    );
}
