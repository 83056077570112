import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledLabel,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";

import {
    store as generalSettingStore,
    setIsModified,
    setGlobalDataIsUpdated,
} from "../store";
import { store } from "@components/VerticalLayout/store";

import { capitalize } from "@helpers/general";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { languages } from "@constants/index";
import { generalConfigStore, setItemsDisable } from "@store";

type ConfirmationModifiedMessageType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    e: any;
    languageState: any;
    setDataIsUpdated: Function;
    dataIsUpdated: boolean | number;
};

export function ConfirmationAssociateModifiedMessage({
    isModalOpened,
    setIsModalOpened,
    e,
    languageState,
    setDataIsUpdated,
    dataIsUpdated,
}: ConfirmationModifiedMessageType) {
    const { t } = useTranslation();
    const { franchiseID, userID, operatorID } = useSnapshot(store);
    const {
        isModified,
        isActivation,
        globalDataIsUpdated,
        shopsLanguagesData,
    } = useSnapshot(generalSettingStore);
    const { itemsDisable } = useSnapshot(generalConfigStore);

    const [error, setError] = React.useState<boolean>(false);
    const uuidUser = localStorage.getItem("uuidUser");
    async function enableAllLanguage() {
        toast.dismiss();
        const id = e.selectedRow.Id;
        let dataLanguage = {
            userId: userID,
            shopId: e.selectedRow.shopId,
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            isShop: true,
            data: {
                [id]: {
                    id: id,
                    language: languages.find(
                        (lg: any) => lg.value === e.selectedRow.abbreviation
                    )?.label,
                    display_name: e.selectedRow.display_name,
                    country: e.selectedRow.country,
                    urlImage: e.selectedRow.url_image,
                    isArchived: e.selectedRow.isArchived,
                    isFavorite: e.selectedRow.isFavorite,
                    isFavoriteShop: e.selectedRow.isFavorite,
                    isActive: !languageState,
                    shopId: e.selectedRow.shopId,
                    isActiveShop: !languageState,
                    abbreviation: e.selectedRow.abbreviation,
                },
            },
        };
        const data = new FormData();
        data.append("dataLanguage", JSON.stringify(dataLanguage));
        data.append("image", "");
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/language`;
        const requestOptions = {
            method: "POST",
            body: data,
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function enableLocalLanguage() {
        toast.dismiss();
        const id = e.selectedRow.Id;
        let dataLanguage = {
            userId: userID,
            shopId: e.selectedRow.shopId,
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            isShop: true,

            data: {
                [id]: {
                    id: id,
                    language: languages.find(
                        (lg: any) => lg.value === e.selectedRow.abbreviation
                    )?.label,
                    display_name: e.selectedRow.display_name,
                    country: e.selectedRow.country,
                    urlImage: e.selectedRow.url_image,
                    isArchived: e.selectedRow.isArchived,
                    isFavorite: e.selectedRow.isFavorite,
                    isFavoriteShop: e.selectedRow.isFavorite,
                    isActive: !languageState,
                    shopId: e.selectedRow.shopId,
                    isActiveShop: !languageState,
                    abbreviation: e.selectedRow.abbreviation,
                },
            },
        };
        const data = new FormData();
        data.append("dataLanguage", JSON.stringify(dataLanguage));
        data.append("image", "");
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/language`;
        const requestOptions = {
            method: "POST",
            body: data,
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function favoriteShopLanguage(isFavorite: boolean) {
        toast.dismiss();
        let localData: any = [];
        const updateFavorite = true;
        shopsLanguagesData.forEach((shop: any) => {
            shop.subRows.forEach((language: any) => {
                if (e.selectedRow.Id === language.Id) {
                    let objectData = {
                        [language.Id]: {
                            id: language.Id,
                            language: languages.find(
                                (lg: any) => lg.value === language.abbreviation
                            )?.label,
                            display_name: language.display_name,
                            country: language.country,
                            urlImage: language.url_image,
                            isArchived: language.isArchived,
                            isFavorite: isFavorite,
                            isActive: true,
                            shopId: language.shopId,
                            abbreviation: language.abbreviation,
                            advancedDisplayName: language.advancedDisplayName,
                        },
                    };
                    if (language.isActive === false) {
                        const item = itemsDisable.filter(
                            (item: any) =>
                                !(
                                    item.id === language.Id &&
                                    item.shopId === language.shopId
                                )
                        );
                        setItemsDisable(item);
                        localStorage.setItem(
                            "itemsDisable",
                            JSON.stringify(item)
                        );
                    }
                    let dataLanguage = {
                        userId: userID,
                        shopId: [shop.shopId],
                        franchiseId: franchiseID,
                        operatorId: operatorID,
                        uuidUser: uuidUser,
                        isShop: false,
                        data: objectData,
                    };
                    const data = new FormData();
                    data.append(
                        "dataLanguage",
                        JSON.stringify({ dataLanguage, updateFavorite })
                    );
                    data.append("image", "");
                    localData.push(
                        fetch(
                            `${process.env.REACT_APP_API_V2_URL}/settings/general/language`,
                            {
                                method: "POST",
                                body: data,
                            }
                        ).then((response) => response.json())
                    );
                } else if (
                    shop.subRows.findIndex(
                        (elt: any) => elt.Id === e.selectedRow.Id
                    ) !== -1
                ) {
                    let objectData = {
                        [language.Id]: {
                            id: language.Id,
                            language: language[t("Language")],
                            display_name: language.display_name,
                            country: language.country,
                            urlImage: language.url_image,
                            isArchived: language.isArchived,
                            isFavorite: false,
                            isActive: language.isActive,
                            shopId: language.shopId,
                            abbreviation: language.abbreviation,
                            advancedDisplayName: language.advancedDisplayName,
                        },
                    };
                    let dataLanguage = {
                        userId: userID,
                        shopId: [shop.shopId],
                        franchiseId: franchiseID,
                        operatorId: operatorID,
                        uuidUser: uuidUser,
                        isShop: true,
                        data: objectData,
                    };

                    const data = new FormData();
                    data.append(
                        "dataLanguage",
                        JSON.stringify({ dataLanguage, updateFavorite })
                    );
                    data.append("image", "");
                    localData.push(
                        fetch(
                            `${process.env.REACT_APP_API_V2_URL}/settings/general/language`,
                            {
                                method: "POST",
                                body: data,
                            }
                        ).then((response) => response.json())
                    );
                }
            });
        });
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/language`,
                await Promise.all(localData).then((result) => {
                    result.forEach((element: any) => {
                        if (element.error === true) setError(true);
                    });
                })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setError(true);
        }
        if (error === false) {
            toast.success(
                `${
                    isFavorite
                        ? `${t(
                              "Shop favorite state has been successfully activated"
                          )}`
                        : `${t(
                              "Shop favorite state has been successfully deactivated"
                          )}`
                }`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
        }
        setDataIsUpdated(!dataIsUpdated);
        setGlobalDataIsUpdated(!globalDataIsUpdated);
    }

    React.useEffect(() => {
        setDataIsUpdated(!dataIsUpdated);
        // eslint-disable-next-line
    }, [isModalOpened]);

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => setIsModalOpened(!isModalOpened)}
            >
                <StyledH2>{capitalize(t("Alert"))}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        'Do you want to apply these changes for all stores? \n Attention ! If you click on "Validate", the specific settings of the shops will be modified.'
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                        isActivation && enableLocalLanguage();
                        setIsModified(!isModified);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        isActivation
                            ? enableAllLanguage()
                            : favoriteShopLanguage(!e.selectedRow.isFavorite);
                        setIsModalOpened(!isModalOpened);
                        setIsModified(!isModified);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
