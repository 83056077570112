import classNames from "classnames";
import React from "react";
import { useSnapshot } from "valtio";

import { designStore, generalConfigStore } from "@store";

import { PreviewBackgroundWrapper } from "../PreviewBackgroundWrapper";
import { ActionsButtonsWrapper } from "./ActionsButtonsWrapper";
import { BasketWrapper } from "./BasketWrapper";
import { ContainerWrapper } from "./ContainerWrapper";
import { HeaderWrapper } from "./HeaderWrapper";
import { MainContentWrapper } from "./MainContentWrapper";
import { TopBannerWrapper } from "./TopBannerWrapper";

import "./ConfigOptionsWrapper.css";

export function GenericInterfacePreview(): JSX.Element {
    const { activeTabIndex, isHeaderOrderTakingActive } = useSnapshot(
        designStore
    );
    const {
        isOrderTakingTopBannerActive,
        isShopCartDetailsActive,
    } = useSnapshot(generalConfigStore);

    return (
        <PreviewBackgroundWrapper>
            <ContainerWrapper
                isTopBannerActive={isOrderTakingTopBannerActive}
                isHeaderActive={isHeaderOrderTakingActive}
                isShopCartDetailsActive={isShopCartDetailsActive}
            >
                {isOrderTakingTopBannerActive === true ? (
                    <div
                        className={classNames(
                            "d-flex justify-content-center align-items-center",
                            {
                                "border-wrapper__clz": activeTabIndex === 0,
                            }
                        )}
                    >
                        <TopBannerWrapper />
                    </div>
                ) : null}
                {isHeaderOrderTakingActive === true ? (
                    <div>
                        <div
                            className={classNames({
                                "border-wrapper__clz": activeTabIndex === 1,
                                "d-contents":
                                    isOrderTakingTopBannerActive === true,
                                "mt-3": isOrderTakingTopBannerActive === false,
                            })}
                            style={{
                                width: "429px",
                                height: "36px",
                            }}
                        >
                            <HeaderWrapper />
                        </div>

                        <hr
                            style={{
                                height: "1px",
                                width: "96%",
                                backgroundColor: "#cccccc",
                                margin: "unset 2px unset 2px",
                                marginTop: "unset",
                                marginBottom: "unset",
                                border: " 0px none",
                            }}
                        />
                    </div>
                ) : null}

                <MainContentWrapper />

                <div
                    className={classNames({
                        "border-wrapper__clz": activeTabIndex === 4,
                    })}
                    style={{
                        width: "429px",
                        background: "#ffffff",
                    }}
                >
                    <BasketWrapper />
                </div>
                <div
                    className={classNames(
                        " d-flex justify-content-center align-items-center",
                        {
                            "border-wrapper__clz": activeTabIndex === 5,
                        }
                    )}
                    style={{
                        width: "429px",
                    }}
                >
                    <ActionsButtonsWrapper />
                </div>
            </ContainerWrapper>
        </PreviewBackgroundWrapper>
    );
}
