import { t } from "i18next";

import React from "react";

export const PayerPMR = (props: any): JSX.Element => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            viewBox="0 0 453 65"
        >
            <g
                id="Groupe_23"
                data-name="Groupe 23"
                transform="translate(-4863 4106)"
            >
                <g
                    id="Rectangle_16"
                    data-name="Rectangle 16"
                    transform="translate(4863 -4106)"
                    fill="#323333"
                    stroke="#666"
                    stroke-width="1"
                >
                    <rect
                        width={props.width}
                        height={props.height}
                        rx="7"
                        stroke="none"
                    />
                    <rect
                        x="0.5"
                        y="0.5"
                        width={props.width}
                        height={props.height}
                        rx="6.5"
                        fill="none"
                    />
                </g>
                <text
                    id="Pay"
                    transform="translate(4950 -4059)"
                    fill="#fff"
                    font-size="38"
                    font-family="HelveticaNeue-Medium, Helvetica Neue"
                    font-weight="500"
                >
                    <tspan x="-50.35" y="0">
                        {t("Pay", { lng: props.selectedLanguage })}
                    </tspan>
                </text>
            </g>
        </svg>
    );
};
