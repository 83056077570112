import React from "react";

type ModelNumberType = {
    text: string;
    colorPrimary: any;
    colorSecondary: any;
    width?: string;
    height?: string;
    marginTop?: string
};

export default function ModelNumber({
    text,
    colorPrimary,
    colorSecondary,
    width,
    height,
    marginTop,
}: ModelNumberType) {
    return (
        <div
            className="d-flex align-items-center justify-content-center"
            style={{
                width: width !== undefined ? width : "105px",
                height: height !== undefined ? height : "35px",
                backgroundColor: colorSecondary,
                color: colorPrimary,
                fontSize: "175%",
                marginTop: marginTop !== undefined ? marginTop : ""
            }}
        >
            {text}
        </div>
    );
}
