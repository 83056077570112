import React from "react";
import classNames from "classnames";
import { useSnapshot } from "valtio";

import { designStore, generalConfigStore } from "@store";

import { getImageContentById, getTopBannerImagePath } from "@helpers/general";

import { PreviewTopBannerBackgroundWrapper } from "./PreviewTopBannerBackgroundWrapper";

export function TopBannerWrapper(): JSX.Element {
    const { isLogoActive, activeTabIndex, activePageName } = useSnapshot(
        designStore
    );
    const {
        project: { template },
        files,
    } = useSnapshot(generalConfigStore);

    let pageId: string;

    pageId = (template.pages.ways[activePageName] as PageType)!.topBanner
        .id as string;

    const logoImage = getImageContentById(
        (template.content.generalDesign as GeneralDesignType).logo.id as string,
        files
    );

    const logoImageSrc =
        logoImage?.includes("http") || logoImage?.includes("https")
            ? logoImage
            : `./images/${logoImage}`;

    const topBannerBackgroundImageSrc = getTopBannerImagePath(pageId, files);

    return (
        <div
            className={classNames({
                "border-wrapper-banner__clz": activeTabIndex === 0,
            })}
        >
            <PreviewTopBannerBackgroundWrapper>
                {isLogoActive ? (
                    <img
                        src={logoImageSrc}
                        style={{
                            height: "85px",
                            width: "85px",
                            position:
                                topBannerBackgroundImageSrc !== undefined &&
                                topBannerBackgroundImageSrc.type !==
                                    undefined &&
                                topBannerBackgroundImageSrc.type === "video"
                                    ? "fixed"
                                    : "unset",
                            zIndex: 1,
                        }}
                        alt="Logo"
                    />
                ) : null}
            </PreviewTopBannerBackgroundWrapper>
        </div>
    );
}
