import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledLabel,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { capitalize } from "@helpers/general";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";
import { uuid } from "uuidv4";

import { store,  setGlobalDataIsUpdated, setKey } from "./store";
import { store as dataStore } from "@components/VerticalLayout/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { getUpdatedSaleSupport } from "./PaymentMethods/PaymentMethodForm";


type ConfirmationModificationMessageType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    idsAssociated: any;
    data: any;
    userData: any;
    setIsAddChoiceClicked: Function;
    setIsEdited: Function;
    isAddChoiceClicked: boolean;
    setDataChoice: Function;
    resetData: Function;
    shopsPaymentMethodsData: any;
    salesSupport: any;
    setToastLimit: Function;
    setIsSendingTag: Function;
    paymentMethod: any;
};

export function ConfirmationModificationMessage({
    isModalOpened,
    setIsModalOpened,
    isAddChoiceClicked,
    data,
    userData,
    setIsAddChoiceClicked,
    setIsEdited,
    idsAssociated,
    setDataChoice,
    resetData,
    shopsPaymentMethodsData,
    salesSupport,
    setToastLimit,
    setIsSendingTag,
    paymentMethod,
}: ConfirmationModificationMessageType) {
    const { salesSupports } = useSnapshot(store);
    const { operatorID } = useSnapshot(dataStore);
    const { t } = useTranslation();

    const [isSalesSupportEmpty, setIsSalesSupportEmpty] = useState<boolean[]>(
        []
    );
    const [isDataChanged, setIsDataChanged] = useState<boolean>(false);
    const [canValidate, setCanValidate] = useState<boolean>(false);
    const uuidUser = localStorage.getItem("uuidUser");

    async function editPaymentMethod(shopsId: string[]) {
        setCanValidate(true);
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod`;
        let dataConfig = {
            userId: userData.userId,
            shopId: shopsId,
            franchiseId: userData.franchiseId,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data: data,
        };
        let updatedData: any = {};

        let oldData = {
            designation: paymentMethod["Type of payment method"],
            display: paymentMethod["Display name"],
            shopId: userData.shopId,
            country: paymentMethod.Country,
            saleSupport: paymentMethod["Sales support"],
            languages: paymentMethod.languages,
            tag: paymentMethod.Tag,
            isActive: paymentMethod.isActive,
            isArchived: paymentMethod.isArchived,
            advancedDisplayName: paymentMethod.advancedDisplayName,
        };
        if (
            oldData.saleSupport.split(",").includes("KIOSK") === true ||
            (Object.values(data)[0] as any).saleSupport
                .split(",")
                .includes("KIOSK") === true
        ) {
            updatedData = getUpdatedSaleSupport(
                oldData,
                Object.values(dataConfig.data)[0]
            );
        }
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ dataConfig, updatedData }),
        };
        toast.dismiss();
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        if (!isSalesSupportEmpty.includes(false)) {
                            toast.success(
                                `${t(
                                    "Shop payment method has been changed successfully"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                        } else if (
                            (isSalesSupportEmpty.includes(true) &&
                                isSalesSupportEmpty.includes(false)) ||
                            (!isSalesSupportEmpty.includes(true) &&
                                isDataChanged)
                        ) {
                            setToastLimit(2);
                            toast.warn(
                                `${t(
                                    "Please note that the modification could not be made at one or more stores.Please check the data settings of the store(s)"
                                )}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            toast.success(
                                `${t(
                                    "Shop payment method has been changed successfully"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                        } else if (
                            !isSalesSupportEmpty.includes(true) &&
                            !isDataChanged
                        ) {
                            toast.warn(
                                `${t(
                                    "Please note that the modification could not be made at one or more stores.Please check the data settings of the store(s)"
                                )}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                        }
                        setIsSendingTag(true);
                        setGlobalDataIsUpdated(true);
                        setIsAddChoiceClicked(!isAddChoiceClicked);
                        setIsEdited(false);
                        setIsModalOpened(!isModalOpened);
                        setDataChoice([]);
                        resetData();
                        setCanValidate(false);
                        setKey(uuid())
                    })
            );
        } catch (e) {
            setCanValidate(false);
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    React.useEffect(() => {
        let allPaymentShop: any = [];
        let localSalesSupport: boolean[] = [];
        if (shopsPaymentMethodsData !== undefined) {
            shopsPaymentMethodsData.forEach((el: any) => {
                if (el.subRows !== undefined)
                    el.subRows.forEach((element: any) => {
                        allPaymentShop.push(element.idShop);
                    });
            });
        }

        salesSupports.forEach((el: any) => {
            if (allPaymentShop.includes(el.id)) {
                let filteredSalesSupport: any = el.salesSupport.filter(
                    (value: any) => salesSupport.split(",").includes(value)
                );
                localSalesSupport.push(
                    filteredSalesSupport.length > 0 &&
                        salesSupport.split(",").length ===
                            filteredSalesSupport.length
                );
            }
        });
        setIsSalesSupportEmpty(localSalesSupport);
        let localData: any = Object.values(data)[0];
        setIsDataChanged(
            paymentMethod.Pays !== localData.country ||
                paymentMethod[t("Display name")] !== localData.display ||
                paymentMethod.Tag !== localData.tag
        );
    }, [
        shopsPaymentMethodsData,
        salesSupports,
        salesSupport,
        data,
        isDataChanged,
        paymentMethod,
        t,
    ]);

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => setIsModalOpened(!isModalOpened)}
            >
                <StyledH2>{capitalize(t("Alert"))}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        'Do you want to apply these changes for all stores? \n Attention ! If you click on "Validate", the specific settings of the shops will be modified.'
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsAddChoiceClicked(!isAddChoiceClicked);
                        resetData();
                        setIsEdited(false);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    disabled={canValidate}
                    className="mr-2"
                    variant="primary"
                    onClick={() => editPaymentMethod(idsAssociated)}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
