import { proxy } from "valtio";

type StoreType = {
    numberActive: number;
    numberArchived: number;
    isEdited: boolean;
    isPlusIconClicked: boolean;
    selectedApplicationSco: any[];
    isAddPeripheralClicked: boolean;
    allPrinters: any[];
    printerSelected: any[];
    selectedPeripherals: any[];
    isPrinterModified: boolean;
    checkModificationEffect: boolean;
    printers: any[];
    isConfigurationPrinterClicked: boolean;
    monetics: any[];
    cashManagements: any[];
    allMonetics: any[];
    allCashManagements: any[];
    selectedRowData: any;
    allMoneticsData: any[];
    isConfigurationCashManagementClicked: boolean;
    isConfigurationMoneticClicked: boolean;
    modify: boolean;
    isActionsClicked: boolean;
    actions: Function;
    validationAction: any;
};

export const store = proxy<StoreType>({
    numberActive: 0,
    numberArchived: 0,
    isEdited: false,
    isPlusIconClicked: false,
    selectedApplicationSco: [],
    isAddPeripheralClicked: false,
    allPrinters: [],
    printerSelected: [],
    selectedPeripherals: [],
    isPrinterModified: false,
    checkModificationEffect: false,
    printers: [],
    isConfigurationPrinterClicked: false,
    monetics: [],
    cashManagements: [],
    allMonetics: [],
    allCashManagements: [],
    selectedRowData: {},
    allMoneticsData: [],
    isConfigurationCashManagementClicked: false,
    isConfigurationMoneticClicked: false,
    isActionsClicked: false,
    actions: () => { },
    validationAction: {},
    modify: false,
});

export function setModify(modify: boolean): void {
    store.modify = modify;
}
export function resetAllPeripherals() {
    store.allPrinters = [];
    store.allMonetics = [];
}
export function resetStore() {
    store.printers = [];
    store.monetics = [];
    store.cashManagements = [];
    store.printerSelected = [];
}
export function setNumberActive(numberActive: number): void {
    store.numberActive = numberActive;
}
export function setNumberArchived(numberArchived: number): void {
    store.numberArchived = numberArchived;
}
export function setIsEdited(isEdited: boolean): void {
    store.isEdited = isEdited;
}
export function setIsPlusIconClicked(isPlusIconClicked: boolean): void {
    store.isPlusIconClicked = isPlusIconClicked;
}
export function setSelectedApplicationSco(selectedApplicationSco: any[]): any {
    store.selectedApplicationSco = selectedApplicationSco;
}
export function setIsAddPeripheralClicked(isAddPeripheralClicked: boolean) {
    store.isAddPeripheralClicked = isAddPeripheralClicked;
}
export function setAllPrinters(printer: any) {
    store.allPrinters.push(printer);
}
export function setInAllPrinters(printer: any) {
    if (
        store.printerSelected.filter((selectedPrinter: any) => {
            return selectedPrinter.Id === printer.Id;
        }).length === 0
    ) {
        store.printerSelected.push(printer);
    } else {
        store.printerSelected = store.printerSelected.filter(
            (selectedPrinter: any) => {
                return selectedPrinter.Id !== printer.Id;
            }
        );
    }
}

export function setIsPrinterModified(isPrinterModified: boolean) {
    store.isPrinterModified = isPrinterModified;
}
export function setCheckModificationEffect(x: boolean) {
    store.checkModificationEffect = x;
}

export function setIsConfigurationPrinterClicked(
    isConfigurationPrinterClicked: boolean
) {
    store.isConfigurationPrinterClicked = isConfigurationPrinterClicked;
}
export function setSelectedPeripherals(selectedPeripheral: any) {
    var duplicatedData: any = store.selectedPeripherals.filter(
        (el: any) => el.Id === selectedPeripheral.Id
    );

    if (duplicatedData.length !== 0) {
        store.selectedPeripherals = store.selectedPeripherals.filter(
            (el: any) => el.Id !== selectedPeripheral.Id
        );
    } else store.selectedPeripherals.push(selectedPeripheral);
}
export function setMonetics(monetic: any) {
    var duplicatedData: any = store.monetics.filter(
        (el: any) => el.Id === monetic.Id
    );

    if (duplicatedData.length !== 0) {
        store.monetics = store.monetics.filter(
            (el: any) => el.Id !== monetic.Id
        );
    } else store.monetics.push(monetic);
}
export function setSelectedRowData(cashManagementRowData: any) {
    store.selectedRowData = cashManagementRowData;
}
export function setAllMoneticsData(monetics: any) {
    store.allMoneticsData.push(monetics);
}
export function setCashManagements(cashManagement: any) {
    var duplicatedData: any = store.cashManagements.filter(
        (el: any) => el.Id === cashManagement.Id
    );

    if (duplicatedData.length !== 0) {
        store.cashManagements = store.cashManagements.filter(
            (el: any) => el.Id !== cashManagement.Id
        );
    } else store.cashManagements.push(cashManagement);
}
export function setIsConfigurationCashManagementClicked(
    isConfigurationCashManagementClicked: boolean
) {
    store.isConfigurationCashManagementClicked = isConfigurationCashManagementClicked;
}
export function setIsConfigurationMoneticClicked(
    isConfigurationMoneticClicked: boolean
) {
    store.isConfigurationMoneticClicked = isConfigurationMoneticClicked;
}

export function setIsActionsClicked(isActionsClicked: boolean): any {
    store.isActionsClicked = isActionsClicked;
}

export function setValidationAction(validationAction: any): any {
    store.validationAction = validationAction;
}

export function setActions(actions: any): any {
    store.actions = actions;
}
export default store;
