import React, { HTMLProps } from "react";
import { Table } from "reactstrap";
import {
    CellContext,
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    HeaderContext,
    Row,
    useReactTable,
} from "@tanstack/react-table";
import { t } from "i18next";
import { SearchIcon } from "@aureskonnect/react-ui";

import "./index.css";
import classnames from "classnames";
type CustomReactTablePropsType<Type> = {
    tableColumns: ColumnDef<Type>[];
    data: Type[];
    isRowSelectionEnabled?: boolean;
    globalFilter?: string;
    customRowSelection?: Record<string, boolean>;
    setGlobalFilter?: React.Dispatch<React.SetStateAction<string>>;
    setSelectedRows?: React.Dispatch<React.SetStateAction<Row<Type>[]>>;
    setRowSelection?: React.Dispatch<
        React.SetStateAction<Record<string, boolean>>
    >;
    isAdvancedDisplayName?: boolean;
    isConsult?: boolean;
};
export default function CustomReactTable<Type extends any>({
    tableColumns,
    data,
    globalFilter = "",
    isRowSelectionEnabled = false,
    customRowSelection = {},
    setGlobalFilter = () => {},
    setSelectedRows = () => {},
    setRowSelection = () => {},
    isAdvancedDisplayName,
    isConsult,
}: CustomReactTablePropsType<Type>) {
    const rowSelectionColumn = {
        id: "select",
        header: SelectAllRowsCheckBox,
        cell: RowSelectionCheckBox,
    };
    const columns: ColumnDef<Type>[] = [...tableColumns];
    if (isRowSelectionEnabled) {
        columns.unshift(rowSelectionColumn);
    }
    const table = useReactTable({
        data,
        columns,
        state: { globalFilter, rowSelection: customRowSelection },
        globalFilterFn: "includesString",
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),
        getCoreRowModel: getCoreRowModel(),
    });
    const selectedRows = table.getSelectedRowModel().flatRows;
    React.useEffect(() => {
        if (isRowSelectionEnabled) {
            setSelectedRows(selectedRows);
        }
    }, [isRowSelectionEnabled, selectedRows, setSelectedRows]);

    return (
        <Table responsive className="border table__orb__clz">
            <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr
                        key={headerGroup.id}
                        className={classnames("", {
                            "d-flex": !isAdvancedDisplayName,
                        })}
                    >
                        {headerGroup.headers.map((header) => (
                            <th
                                key={header.id}
                                className={classnames("", {
                                    "d-flex  align-items-center justify-content-between": !isAdvancedDisplayName,
                                })}
                            >
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                          header.column.columnDef.header,
                                          header.getContext()
                                      )}
                                {header.column.getCanFilter() ? (
                                    <div>
                                        <Filter
                                            column={header.column}
                                            table={table}
                                        />
                                    </div>
                                ) : null}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody>
                <div
                    style={{
                        overflowY: !isAdvancedDisplayName ? "scroll" : "hidden",
                        height: !isAdvancedDisplayName ? "551px" : "",
                        borderTop: !isAdvancedDisplayName
                            ? "1px solid #dee2e6"
                            : "",
                    }}
                >
                    {table.getRowModel().rows.map((row) => (
                        <tr
                            key={row.id}
                            style={{
                                borderBottom: !isAdvancedDisplayName
                                    ? " 1px solid #dee2e6"
                                    : "",
                            }}
                        >
                            {row.getVisibleCells().map((cell) => (
                                <td
                                    key={cell.id}
                                    style={{
                                        pointerEvents: isConsult
                                            ? "none"
                                            : "all",
                                    }}
                                >
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </div>
            </tbody>
        </Table>
    );
}
function IndeterminateCheckbox({
    indeterminate,
    className = "",
    ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
    const ref = React.useRef<HTMLInputElement>(null!);

    React.useEffect(() => {
        if (typeof indeterminate === "boolean") {
            ref.current.indeterminate = !rest.checked && indeterminate;
        }
    }, [ref, indeterminate, rest.checked]);
    return (
        <input type="checkbox" ref={ref} {...rest} className="checkbox__clz" />
    );
}
function SelectAllRowsCheckBox<Type extends any>({
    table,
}: HeaderContext<Type, unknown>) {
    return (
        <IndeterminateCheckbox
            {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
            }}
        />
    );
}
function RowSelectionCheckBox<Type extends any>({
    row,
}: CellContext<Type, unknown>) {
    return (
        <IndeterminateCheckbox
            {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
            }}
        />
    );
}

function Filter({ column, table }: { column: any; table: any }) {
    const firstValue = table
        .getPreFilteredRowModel()
        .flatRows[0]?.getValue(column.id);

    return typeof firstValue === "number" ? (
        <div>
            <div
                style={{ position: "relative" }}
                className="d-flex align-items-center justify-content-center"
            >
                <SearchIcon
                    height={15}
                    width={15}
                    style={{ position: "absolute", zIndex: 10, left: "10px" }}
                />
                <input
                    type="number"
                    value={
                        ((column.getFilterValue() as any)?.[0] ?? "") as string
                    }
                    onChange={(e) =>
                        column.setFilterValue((old: any) => [
                            e.target.value,
                            old?.[1],
                        ])
                    }
                    placeholder={`Min`}
                    style={{
                        border: "none",
                        paddingLeft: "40px",
                        paddingRight: "20px",
                        backgroundColor: "#f3f3f9",
                        borderRadius: "10px",
                    }}
                />
            </div>
            <div
                style={{ position: "relative" }}
                className="d-flex align-items-center justify-content-center"
            >
                <SearchIcon
                    height={15}
                    width={15}
                    style={{ position: "absolute", zIndex: 10, left: "10px" }}
                />
                <input
                    type="number"
                    value={
                        ((column.getFilterValue() as any)?.[1] ?? "") as string
                    }
                    onChange={(e) =>
                        column.setFilterValue((old: any) => [
                            old?.[0],
                            e.target.value,
                        ])
                    }
                    placeholder={`Max`}
                    style={{
                        border: "none",
                        paddingLeft: "40px",
                        paddingRight: "20px",
                        backgroundColor: "#f3f3f9",
                        borderRadius: "10px",
                    }}
                />
            </div>
        </div>
    ) : (
        <div
            style={{ position: "relative" }}
            className="d-flex align-items-center justify-content-center"
        >
            <SearchIcon
                height={15}
                width={15}
                style={{ position: "absolute", zIndex: 10, left: "10px" }}
            />
            <input
                type="text"
                value={(column.getFilterValue() ?? "") as string}
                onChange={(e) => column.setFilterValue(e.target.value)}
                placeholder={t("Search")}
                style={{
                    border: "none",
                    paddingLeft: "40px",
                    paddingRight: "20px",
                    backgroundColor: "#f3f3f9",
                    borderRadius: "10px",
                }}
            />
        </div>
    );
}
