import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { capitalize } from "@helpers/general";
import { useSnapshot } from "valtio";

import { store as posEditor } from "../store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationUpdateType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setIsValidateClickedModal: Function;
};

export function ConfirmationUpdate({
    isModalOpened,
    setIsModalOpened,
    setIsValidateClickedModal,
}: ConfirmationUpdateType) {
    const { t } = useTranslation();
    const { message } = useSnapshot(posEditor);

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => setIsModalOpened(!isModalOpened)}
            >
                <span
                    style={{
                        fontFamily: "Segoe UI Semibold",
                        color: "#2b2828",
                        fontSize: "20px",
                    }}
                >
                    {capitalize(t("Alert"))}
                </span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {message}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        setIsValidateClickedModal(true);
                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
