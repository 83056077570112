import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";

import { setTicketKitchenModeSale, storeKitchenTicketType } from "./Hook/store";
import { store } from "../store";

import "../index.css";

function PrintKitchenTicket({ rowData }: any) {
    const { t } = useTranslation();
    const { ticketKitchenModeSale } = useSnapshot(storeKitchenTicketType);
    const { salesModes } = useSnapshot(store);

    useEffect(() => {
        setTicketKitchenModeSale(salesModes.kitchenTicket);
    }, [salesModes.kitchenTicket]);
    const handleStateSortedVisibilityChange = (item: any, key: number) => {
        let items = { ...item };
        items.state = !items.state;
        let newVisibility = [...ticketKitchenModeSale] as any;
        newVisibility = JSON.parse(JSON.stringify(newVisibility));
        newVisibility[key].state = items.state;
        setTicketKitchenModeSale(newVisibility);
    };

    return (
        <div>
            <div className="d-flex p-2">
                <div>
                    <b>
                        {t(
                            "Print the kitchen ticket according to the mode of sale"
                        )}
                    </b>
                </div>
            </div>
            <div className="d-flex  align-items-center d-flex flex-wrap">
                {ticketKitchenModeSale
                    ? ticketKitchenModeSale.map(
                          (modeOfSale: any, key: any): JSX.Element => (
                              <div className="text-danger row b-2 align-items-center">
                                  <div
                                      className="d-flex col-4"
                                      style={{ color: "#212529 " }}
                                  >
                                      <div className="px-1">
                                          <h6 style={{ width: "87px" }}>
                                              {t(modeOfSale.designation)}
                                          </h6>
                                      </div>
                                      <div className="mr-2">
                                          <ReactSwitch
                                              uncheckedIcon={false}
                                              checkedIcon={false}
                                              handleDiameter={26}
                                              offColor="#f7b4b8"
                                              offHandleColor="#E30613"
                                              checked={modeOfSale.state}
                                              onChange={() => {
                                                  handleStateSortedVisibilityChange(
                                                      modeOfSale,
                                                      key
                                                  );
                                              }}
                                              onColor="#c2eddd"
                                              onHandleColor="#34C38F"
                                              width={55}
                                              height={20}
                                              className="afa_icn_printKitchenTicketModeOfSales"
                                          />
                                      </div>
                                  </div>
                              </div>
                          )
                      )
                    : null}
            </div>
        </div>
    );
}

export default PrintKitchenTicket;
