import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { capitalize } from "@helpers/general";
import { generalConfigStore } from "@store";
import { useSnapshot } from "valtio";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type PropsType = {
    setIsModalOpened: Function;
    isModalOpened: boolean;
};

export function ConfirmationTicket({
    setIsModalOpened,
    isModalOpened,
}: PropsType) {
    const { t } = useTranslation();
    const { functionIsPaperlessChecked } = useSnapshot(generalConfigStore);
    function handleValidateOnclickEvent() {
        functionIsPaperlessChecked();
        setIsModalOpened(!isModalOpened);
    }
    return (
        <React.Fragment>
            <StyledModal
                toggle={setIsModalOpened}
                isOpen={isModalOpened}
                centered
                backdrop="static"
            >
                <ModalHeader
                    className="text-uppercase"
                    toggle={() => {
                        setIsModalOpened(!isModalOpened);
                    }}
                    tag="div"
                >
                    <StyledH2>{capitalize("Alert")}</StyledH2>
                </ModalHeader>
                <ModalBody>
                    <StyledLabel className="pl-3 m-0">
                        {t(
                            "Do you want to deactivate the Dematerialized Ticket module?"
                        )}
                    </StyledLabel>
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        variant="light"
                        className="Cgt_btn_modeExpert"
                        onClick={() => {
                            setIsModalOpened(!isModalOpened);
                        }}
                    >
                        {t("No")}
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        className="mr-2 Cgt_btn_modeSimple"
                        variant="primary"
                        onClick={() => {
                            handleValidateOnclickEvent();
                        }}
                    >
                        {t("Yes")}
                    </CustomMainColorButton>
                </ModalFooter>
            </StyledModal>
        </React.Fragment>
    );
}
