import React from "react";
import { useSnapshot } from "valtio";
import { CheckIcon } from "@aureskonnect/react-ui";
import classNames from "classnames";

import {
    generalConfigStore,
    setProductAnimation,
    setGeneralTransition,
    setIsAnimationIn,
    setChosenTransition,
    setPageOrderTakingCompositeProduct,
} from "@store";
import { t } from "i18next";

type PropsType = {
    transition: string;
};

export function TransitionCardWrapper({ transition }: PropsType): JSX.Element {
    const {
        transitions,
        project: {
            template: {
                pages: { ways },
            },
        },
    } = useSnapshot(generalConfigStore);

    function handleTransitionCardOnClickEvent(transition: string) {
        setProductAnimation(transition);
        setPageOrderTakingCompositeProduct({
            ...(ways["orderTaking"] as OrderTakingPageType).compositeProduct,
            productAnimation: transition,
        });
        setIsAnimationIn(false);
        setGeneralTransition({ prettyName: "", timeout: 0, className: "" });
        setChosenTransition(transitions[transition]);
        setTimeout(() => {
            setIsAnimationIn(true);
        }, 1000);
    }

    const chosenTransitionCondition =
        transition ===
        (ways["orderTaking"] as OrderTakingPageType).compositeProduct
            .productAnimation;
    return (
        <div
            style={{
                height: "180px",
                width: "160px",
            }}
            className="d-grid__clz justify-content-center"
        >
            <div
                className="d-flex justify-content-center"
                onClick={() => handleTransitionCardOnClickEvent(transition)}
            >
                <div
                    className={classNames("d-grid__clz", {
                        "align-items-center": !chosenTransitionCondition,
                    })}
                    style={{
                        height: "160px",
                        width: "160px",
                        border:"1px solid #6f6d6d", 
                        borderRadius: "17px",
                        borderBottom:
                            chosenTransitionCondition === true
                                ? "10px solid blue"
                                : "1px solid #6f6d6d", 

                        gridTemplateColumns:
                            chosenTransitionCondition === true
                                ? "0.1fr auto"
                                : "",
                    }}
                >
                    {chosenTransitionCondition === true ? (
                        <CheckIcon
                            className="mt-2 ml-2"
                            height={30}
                            width={30}
                            style={{
                                fill: "blue",
                                cursor: "pointer",
                            }}
                        />
                    ) : null}
                    <div
                        className={classNames("d-flex align-items-center", {
                            "justify-content-center ": !chosenTransitionCondition,
                        })}
                    >
                        <img
                            alt=""
                            src="./images/transition.png"
                            style={{
                                height: "90px",
                                width: "90px",
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
                <span
                    className="text-center cursor__clz"
                    style={{ fontSize: "14px" }}
                >
                    {t(transitions[transition].prettyName)}
                </span>
            </div>
        </div>
    );
}
