import React from "react";

import { useSnapshot } from "valtio";
import classnames from "classnames";
import { useTranslation } from "react-i18next/";
import { mutate } from "swr";
import { CheckboxIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";
import { uuid } from "uuidv4";

import { store as ModeOfSale } from "../../../../components/VerticalLayout/store";
import { store, setIsLoading, setKey } from "../store";

import ErrorToast from "@components/Common/ErrorTost";
import { ConfirmationArchive } from "../ConfirmationArchive";

export function ActionColumnModeSale({
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    selectedRows,
    setDataIsUpdated,
    setIsModeOfSaleArchived,
    isModeOfSaleArchived,
    setLimit,
}: any) {
    const { t } = useTranslation();
    const {
        numberActiveMultiShop,
        numberArchiveMultiShop,
        archivedModeOfSaleFranchise,
        modify,
        isLoading,
    } = useSnapshot(store);

    const [error, setError] = React.useState(false);
    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);
    const [listSaleMode, setListSaleMode] = React.useState<any>([]);
    const { franchiseID, userID, operatorID } = useSnapshot(ModeOfSale);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    function archiveModeOfSaleFranchise() {
        let idsAssociated: any = [];
        if (
            archivedModeOfSaleFranchise.othersData
                .archivedModeOfSaleFranchise !== undefined
        ) {
            archivedModeOfSaleFranchise.othersData.archivedModeOfSaleFranchise.forEach(
                (element: any) => {
                    idsAssociated.push(element.Id);
                }
            );
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    const getArrayOfRows = () => {
        let arrayOfRows: any[] = [];
        let ids: any[] = [];

        selectedRows.forEach(async (el: any) => {
            if (
                el[t("Shop")] === undefined &&
                ids.indexOf(`${el.shopId}${el.Id}`) === -1
            ) {
                arrayOfRows.push(el);
                ids.push(`${el.shopId}${el.Id}`);
            }
        });

        arrayOfRows = [...new Set(arrayOfRows)];

        return arrayOfRows;
    };

    let arrayOfRows = getArrayOfRows();

    async function activeModeOfSale() {
        setIsLoading(true);
        toast.dismiss();
        let localData: any = [];
        let arrayOfRows = getArrayOfRows();
        arrayOfRows.forEach(async (el: any) => {
            let dataModeOfSale = {
                userId: userID,
                shopId: [el.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                isShop: false,
                data: {
                    [el.Id]: {
                        designation: el["Mode of sale"],
                        tag: el.tag,
                        display_name: el.display_name,
                        support_vente: el.support_vente,
                        isArchived: false,
                        isFavorite: el.isFavorite,
                        state: el.state,
                        shopId: el.shopId,
                        advancedDisplayName: el.advancedDisplayName,
                    },
                },
            };

            localData.push(dataModeOfSale);
        });
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`,
                await fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(localData),
                    }
                ).then((result) => console.log(result))
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setError(true);
            setIsLoading(false);
        }

        if (error === false) {
            toast.success(
                `${t("Sales method has been successfully activated")!}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
            setDataIsUpdated!(true);
            setIsLoading(false);
            setKey(uuid());
        }
    }

    async function isSaleSupportArchived() {
        toast.dismiss();
        let isSaleSupportArchived = false;
        const url = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&franchiseId=${franchiseID}&type=archived`;
        mutate(
            url,
            await fetch(url, {
                method: "GET",
                headers: { "Accept-Language": i18nextLng },
            })
                .then((response) => response.json())
                .then((data) => {
                    data.data.forEach((elt: any) => {
                        selectedRows.forEach((element: any) => {
                            element.support_vente.forEach((support: any) => {
                                if (
                                    elt.subRows.some(
                                        (sale: any) =>
                                            `${sale[t("Sale support")]} ${
                                                sale.shopId
                                            }` ===
                                            `${support} ${element.shopId}`
                                    )
                                ) {
                                    isSaleSupportArchived = true;
                                }
                            });
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
        );
        return isSaleSupportArchived;
    }
    const dataFetch = React.useCallback(async () => {
        const data = await (
            await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information?userId=${userID}&franchiseId=${franchiseID}`
            )
        ).json();
        let listSaleMode: any[] = [];
        if (data.data !== undefined) {
            data.data.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    el["Mode de vente"].forEach((elt: any) => {
                        listSaleMode.push(`${elt.value} ${el.shopId}`);
                    });
                });
            });
        }
        setListSaleMode(listSaleMode);
    }, [franchiseID, userID]);

    React.useEffect(() => {
        dataFetch();
    }, [dataFetch]);
    return (
        <React.Fragment>
            <div>
                <div className="dropdown">
                    <VerticalDotsIcon
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        style={{ cursor: "pointer" }}
                        height={25}
                        width={25}
                        className="cmn_drp_BTmenu"
                        fill="black"
                    />
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <div
                            className="dropdown-item pl-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setIsModeOfSaleArchived(false);
                            }}
                        >
                            <span className="ml-2 cmn_btn_BTlistActivated">
                                {t("Sales method activated(s)")}(
                                {numberActiveMultiShop})
                            </span>
                        </div>
                        <div
                            className="dropdown-item pl-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setIsModeOfSaleArchived(true);
                            }}
                        >
                            <span className="ml-2 cmn_btn_BTlistArchived">
                                {t("Sales method archived(s)")}(
                                {numberArchiveMultiShop})
                            </span>
                        </div>
                        <div
                            style={{ cursor: "pointer" }}
                            className={`${classnames(
                                " dropdown-item pl-1 pointer__clz",
                                {
                                    "not-allowed-icon__clz":
                                        selectedRows.length === 0 ||
                                        arrayOfRows.length === 0,
                                }
                            )}`}
                            onClick={() => {
                                if (
                                    selectedRows.length === 1 &&
                                    (selectedRows[0].subRows === undefined ||
                                        selectedRows[0].subRows.length < 2)
                                ) {
                                    ErrorToast(
                                        `${t(
                                            "Attention, it is a multiple action. Please select at least two items"
                                        )!}`
                                    );
                                } else {
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                                }
                            }}
                        >
                            <span className="ml-2 cmn_btn_BThistory">
                                {t("Historical")}
                            </span>
                        </div>
                        {modify === true ? (
                            <>
                                <div
                                    style={{ cursor: "pointer" }}
                                    className={`${classnames(
                                        " dropdown-item pl-1 pointer__clz",
                                        {
                                            "not-allowed-icon__clz":
                                                isLoading ||
                                                selectedRows.length === 0 ||
                                                (isModeOfSaleArchived &&
                                                    getArrayOfRows().some(
                                                        (element: any) =>
                                                            archiveModeOfSaleFranchise().includes(
                                                                element.Id
                                                            )
                                                    )) ||
                                                arrayOfRows.length === 0,
                                        }
                                    )}`}
                                    onClick={async () => {
                                        setIsLoading(true);
                                        let isArchived = await isSaleSupportArchived();

                                        if (arrayOfRows.length < 2) {
                                            ErrorToast(
                                                `${t(
                                                    "Attention, it is a multiple action. Please select at least two items"
                                                )!}`
                                            );
                                            setIsLoading(false);
                                        } else if (
                                            isArchived === true &&
                                            isModeOfSaleArchived
                                        ) {
                                            ErrorToast(
                                                `${t(
                                                    "Please note that  the unarchiving process could not be successfully completed. Please check the settings of the store(s) data."
                                                )!}`
                                            );
                                            setIsLoading(false);
                                        } else {
                                            if (isModeOfSaleArchived) {
                                                activeModeOfSale();
                                            } else {
                                                setIsArchiveModalOpened(true);
                                                setIsLoading(false);
                                            }
                                        }
                                    }}
                                >
                                    <span className="ml-2 cmn_btn_BTarchived cmn_btn_BTunarchive">
                                        {isModeOfSaleArchived
                                            ? t("unarchive")
                                            : t("archive")}
                                    </span>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
            {isArchiveModalOpened ? (
                <ConfirmationArchive
                    isModalOpened={isArchiveModalOpened}
                    setIsModalOpened={setIsArchiveModalOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                    setLimit={setLimit}
                    listSaleMode={listSaleMode}
                />
            ) : null}
        </React.Fragment>
    );
}
