import React from "react";
import { StyledLabel, StyledModal } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { t } from "i18next";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

export function DeleteModal({
    isDeleteStateScreenItemModalOpened,
    setIsDeleteStateScreenItemModalOpened,
    setActiveStateScreenItems,
    activeStateScreenItems,
    deletedActiveStateScreenItemId,
}: any): JSX.Element {
    function handleDeleteItemOnClickEvent() {
        const localData = [...activeStateScreenItems];
        const newData = localData.filter(
            (item) => item.id !== deletedActiveStateScreenItemId
        );

        setActiveStateScreenItems(newData);
        setIsDeleteStateScreenItemModalOpened(
            !isDeleteStateScreenItemModalOpened
        );
    }

    return (
        <StyledModal
            toggle={setIsDeleteStateScreenItemModalOpened}
            isOpen={isDeleteStateScreenItemModalOpened}
            centered
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsDeleteStateScreenItemModalOpened(
                        !isDeleteStateScreenItemModalOpened
                    );
                }}
            >
                <span
                    className="text-uppercase"
                    style={{
                        fontFamily: "Segoe UI Semibold",
                        color: "#2b2828",
                        fontSize: "20px",
                    }}
                >
                    {t("Delete an item")}
                </span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="d-flex justify-content-center pl-3 m-0">
                    {t("Do you want to confirm the delete of this element")}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomMainColorButton
                    rounded={true}
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        setIsDeleteStateScreenItemModalOpened(
                            !isDeleteStateScreenItemModalOpened
                        );
                    }}
                >
                    {t("Cancel")}
                </CustomMainColorButton>
                <CustomMainColorButton
                    variant="primary"
                    onClick={handleDeleteItemOnClickEvent}
                    rounded={true}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
