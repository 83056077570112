import React from "react";
import { useTranslation } from "react-i18next";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";

import { store as userStore } from "@components/VerticalLayout/store";

import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

type CardFooterListShopType = {
    dataListShops: any;
    rowData: any;
    setIsModalAssociateStore: Function;
    isModalAssociateStore: boolean;
    setIsDataUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
};

export default function CardFooterListShop({
    dataListShops,
    rowData,
    setIsModalAssociateStore,
    isModalAssociateStore,
    setIsDataUpdated,
}: CardFooterListShopType) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID } = useSnapshot(userStore);

    async function handleValidateButtonClickEvent() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/projects/sco/associate/shops`;
        let dataShop: any = {};
        for (let el of dataListShops) {
            dataShop[el.id_boutique] = {
                designation: el[t("Shop")],
                id_boutique: el.id_boutique,
            };
        }

        let savedData = {
            userId: userID,
            franchiseId: franchiseID,
            project_id: "PROJECT",
            projectId: rowData.projectId,
            operatorId: operatorID,
            data: dataShop,
        };

        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedData),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then(async (data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }

                        toast.success(
                            `${t("Store assignment was successful")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        await handleProjectValidateButtonClickEvent();
                        setIsModalAssociateStore(!isModalAssociateStore);
                        setIsDataUpdated(true);
                    })
            );
        } catch (e: any) {
            toast.error(`${t("Mistake ! Please try again")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }
    async function handleProjectValidateButtonClickEvent() {
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/application/sco/project`;
        let listShops: any[] = [];
        for (let el of dataListShops) {
            listShops.push(el.id_boutique);
        }

        let id = rowData.projectId;
        const idProject: string = "PROJECT";
        let savedData = {
            userId: userID,
            shopId: listShops,
            franchiseId: franchiseID,
            type: "Add",
            operatorId: operatorID,
            data: {
                [idProject]: {
                    id_project: idProject,
                    data_project: {
                        [id]: {
                            projectId: id,
                            template: {},
                            designation: rowData[t("Designation")],
                            shopId: listShops,
                            note: rowData[t("Note")],
                            status: rowData[t("Status")],
                            isArchived: false,
                        },
                    },
                },
            },
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedData),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then(async (data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                    })
            );
        } catch (e: any) {
            toast.error(`${t("Mistake ! Please try again")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <div>
            <div className="justify-content-between d-flex align-items-right  float-right p-3 mt-3">
                <CustomSecondaryColorButton
                    outline
                    rounded
                    className="mr-2 cmn_btn_cancelAlert"
                    variant="light"
                    onClick={() => {
                        setIsModalAssociateStore(!isModalAssociateStore);
                    }}
                >
                    <span> {t("Cancel")}</span>
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    variant="primary"
                    className="cmn_btn_validateAlert"
                    onClick={handleValidateButtonClickEvent}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </div>
        </div>
    );
}
