import React from "react";

type ContainerPropsType = {
    isFinished: boolean;
    animationDuration: number;
} & ChildrenPropsType;

export function Container({
    children,
    isFinished,
    animationDuration,
}: ContainerPropsType) {
    return (
        <div
            style={{
                opacity: isFinished ? 0 : 1,
                pointerEvents: "none",
                transition: `opacity ${animationDuration}ms linear`,
            }}
        >
            {children}
        </div>
    );
}
