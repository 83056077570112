import React, { useEffect } from "react";
import ReactSwitch from "react-switch";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import {
    setCustomerInformationModeSaleCode,
    storeKitchenTicketType,
    setCustomerInformationModeSaleName,
    setCustomerInformationModeSaleNumTel,
    setCustomerInformationModeSaleAdress,
    setCustomerInformationModeSaleCity,
    setCustomerInformationModeSaleCompany,
} from "./Hook/store";
import { store } from "../store";

import "../index.css";

export default function PrintCustomerInformation({ rowData }: any) {
    const { t } = useTranslation();
    const {
        customerInformationModeSaleName,
        customerInformationModeSaleNumTel,
        customerInformationModeSaleAdress,
        customerInformationModeSaleCity,
        customerInformationModeSaleCode,
        customerInformationModeSaleCompany,
    } = useSnapshot(storeKitchenTicketType);

    const { salesModes } = useSnapshot(store);

    useEffect(() => {
        setCustomerInformationModeSaleAdress(
            salesModes.customerInformationAdress
        );
        setCustomerInformationModeSaleCompany(
            salesModes.customerInformationCompany
        );
        setCustomerInformationModeSaleCode(salesModes.customerInformationCode);
        setCustomerInformationModeSaleCity(salesModes.customerInformationCity);
        setCustomerInformationModeSaleNumTel(
            salesModes.customerInformationNumTel
        );
        setCustomerInformationModeSaleName(salesModes.customerInformationName);
    }, [
        salesModes.customerInformationAdress,
        salesModes.customerInformationCompany,
        salesModes.customerInformationNumTel,
        salesModes.customerInformationCity,
        salesModes.customerInformationCode,
        salesModes.customerInformationName,
    ]);
    const handleStateSortedVisibilityChange = (item: any, key: number) => {
        let items = { ...item };
        items.state = !items.state;
        let newVisibility = [...customerInformationModeSaleName] as any;
        newVisibility = JSON.parse(JSON.stringify(newVisibility));
        newVisibility[key].state = items.state;
        setCustomerInformationModeSaleName(newVisibility);
    };
    const handleStateSortedVisibilityChangeNumTel = (
        item: any,
        key: number
    ) => {
        let items = { ...item };
        items.state = !items.state;
        let newVisibility = [...customerInformationModeSaleNumTel] as any;
        newVisibility = JSON.parse(JSON.stringify(newVisibility));
        newVisibility[key].state = items.state;
        setCustomerInformationModeSaleNumTel(newVisibility);
    };
    const handleStateSortedVisibilityChangeCity = (item: any, key: number) => {
        let items = { ...item };
        items.state = !items.state;
        let newVisibility = [...customerInformationModeSaleCity] as any;
        newVisibility = JSON.parse(JSON.stringify(newVisibility));
        newVisibility[key].state = items.state;
        setCustomerInformationModeSaleCity(newVisibility);
    };
    const handleStateSortedVisibilityChangeAdress = (
        item: any,
        key: number
    ) => {
        let items = { ...item };
        items.state = !items.state;
        let newVisibility = [...customerInformationModeSaleAdress] as any;
        newVisibility = JSON.parse(JSON.stringify(newVisibility));
        newVisibility[key].state = items.state;
        setCustomerInformationModeSaleAdress(newVisibility);
    };
    const handleStateSortedVisibilityChangeCompany = (
        item: any,
        key: number
    ) => {
        let items = { ...item };
        items.state = !items.state;
        let newVisibility = [...customerInformationModeSaleCompany] as any;
        newVisibility = JSON.parse(JSON.stringify(newVisibility));
        newVisibility[key].state = items.state;
        setCustomerInformationModeSaleCompany(newVisibility);
    };
    const handleStateSortedVisibilityChangeCode = (item: any, key: number) => {
        let items = { ...item };
        items.state = !items.state;
        let newVisibility = [...customerInformationModeSaleCode] as any;
        newVisibility = JSON.parse(JSON.stringify(newVisibility));
        newVisibility[key].state = items.state;
        setCustomerInformationModeSaleCode(newVisibility);
    };

    return (
        <div>
            <div className="d-flex  align-items-center d-flex flex-wrap p-2">
                <div>
                    <b>{t("Print customer information by mode of sale")}</b>
                </div>
            </div>
            <div className="d-flex  align-items-center flex-wrap ">
                <label className="p-2" style={{ width: "15%" }}>
                    {t("Name")}
                </label>
                {customerInformationModeSaleName !== undefined
                    ? customerInformationModeSaleName.map(
                          (modeOfSale: any, key: any): JSX.Element => (
                              <div className="text-danger row b-2 align-items-center">
                                  <div
                                      className="d-flex col-4"
                                      style={{ color: "#212529 " }}
                                  >
                                      <div className="px-1">
                                          <h6 style={{ width: "87px" }}>
                                              {t(modeOfSale.designation)}
                                          </h6>
                                      </div>
                                      <div className="mr-2">
                                          <ReactSwitch
                                              uncheckedIcon={false}
                                              checkedIcon={false}
                                              handleDiameter={26}
                                              offColor="#f7b4b8"
                                              offHandleColor="#E30613"
                                              checked={modeOfSale.state}
                                              onChange={() => {
                                                  handleStateSortedVisibilityChange(
                                                      modeOfSale,
                                                      key
                                                  );
                                              }}
                                              onColor="#c2eddd"
                                              onHandleColor="#34C38F"
                                              width={55}
                                              height={20}
                                              className="afa_icn_printNameInOnSiteSalesMode"
                                          />
                                      </div>
                                  </div>
                              </div>
                          )
                      )
                    : null}
            </div>

            <div className="d-flex  align-items-center flex-wrap ">
                <label className="p-2" style={{ width: "15%" }}>
                    {t("Phone number")}
                </label>
                {customerInformationModeSaleNumTel !== undefined
                    ? customerInformationModeSaleNumTel.map(
                          (modeOfSale: any, key: any): JSX.Element => (
                              <div className="text-danger row b-2 align-items-center">
                                  <div
                                      className="d-flex col-4"
                                      style={{ color: "#212529 " }}
                                  >
                                      <div className="px-1">
                                          <h6 style={{ width: "87px" }}>
                                              {t(modeOfSale.designation)}
                                          </h6>
                                      </div>
                                      <div className="mr-2">
                                          <ReactSwitch
                                              uncheckedIcon={false}
                                              checkedIcon={false}
                                              handleDiameter={26}
                                              offColor="#f7b4b8"
                                              offHandleColor="#E30613"
                                              checked={modeOfSale.state}
                                              onChange={() => {
                                                  handleStateSortedVisibilityChangeNumTel(
                                                      modeOfSale,
                                                      key
                                                  );
                                              }}
                                              onColor="#c2eddd"
                                              onHandleColor="#34C38F"
                                              width={55}
                                              height={20}
                                              className="afa_icn_printPhoneInOnSiteSalesMode"
                                          />
                                      </div>
                                  </div>
                              </div>
                          )
                      )
                    : null}
            </div>
            <div className="d-flex  align-items-center flex-wrap ">
                <label className="p-2" style={{ width: "15%" }}>
                    {t("Company")}
                </label>
                {customerInformationModeSaleCity !== undefined
                    ? customerInformationModeSaleCity.map(
                          (modeOfSale: any, key: any): JSX.Element => (
                              <div className="text-danger row b-2 align-items-center">
                                  <div
                                      className="d-flex col-4"
                                      style={{ color: "#212529 " }}
                                  >
                                      <div className="px-1">
                                          <h6 style={{ width: "87px" }}>
                                              {t(modeOfSale.designation)}
                                          </h6>
                                      </div>
                                      <div className="mr-2">
                                          <ReactSwitch
                                              uncheckedIcon={false}
                                              checkedIcon={false}
                                              handleDiameter={26}
                                              offColor="#f7b4b8"
                                              offHandleColor="#E30613"
                                              checked={modeOfSale.state}
                                              onChange={() => {
                                                  handleStateSortedVisibilityChangeCity(
                                                      modeOfSale,
                                                      key
                                                  );
                                              }}
                                              onColor="#c2eddd"
                                              onHandleColor="#34C38F"
                                              width={55}
                                              height={20}
                                              className="afa_icn_printCompanyInOnSiteSalesMode"
                                          />
                                      </div>
                                  </div>
                              </div>
                          )
                      )
                    : null}
            </div>
            <div className="d-flex  align-items-center flex-wrap ">
                <label className="p-2" style={{ width: "15%" }}>
                    {t("Address")}
                </label>
                {customerInformationModeSaleAdress !== undefined
                    ? customerInformationModeSaleAdress.map(
                          (modeOfSale: any, key: any): JSX.Element => (
                              <div className="text-danger row b-2 align-items-center">
                                  <div
                                      className="d-flex col-4"
                                      style={{ color: "#212529 " }}
                                  >
                                      <div className="px-1">
                                          <h6 style={{ width: "87px" }}>
                                              {t(modeOfSale.designation)}
                                          </h6>
                                      </div>
                                      <div className="mr-2">
                                          <ReactSwitch
                                              uncheckedIcon={false}
                                              checkedIcon={false}
                                              handleDiameter={26}
                                              offColor="#f7b4b8"
                                              offHandleColor="#E30613"
                                              checked={modeOfSale.state}
                                              onChange={() => {
                                                  handleStateSortedVisibilityChangeAdress(
                                                      modeOfSale,
                                                      key
                                                  );
                                              }}
                                              onColor="#c2eddd"
                                              onHandleColor="#34C38F"
                                              width={55}
                                              height={20}
                                              className="afa_icn_printAdressInOnSiteSalesMode"
                                          />
                                      </div>
                                  </div>
                              </div>
                          )
                      )
                    : null}
            </div>
            <div className="d-flex  align-items-center flex-wrap ">
                <label className="p-2" style={{ width: "15%" }}>
                    {t("Postal code")}
                </label>
                {customerInformationModeSaleCode !== undefined
                    ? customerInformationModeSaleCode.map(
                          (modeOfSale: any, key: any): JSX.Element => (
                              <div className="text-danger row b-2 align-items-center">
                                  <div
                                      className="d-flex col-4"
                                      style={{ color: "#212529 " }}
                                  >
                                      <div className="px-1">
                                          <h6 style={{ width: "87px" }}>
                                              {t(modeOfSale.designation)}
                                          </h6>
                                      </div>
                                      <div className="mr-2">
                                          <ReactSwitch
                                              uncheckedIcon={false}
                                              checkedIcon={false}
                                              handleDiameter={26}
                                              offColor="#f7b4b8"
                                              offHandleColor="#E30613"
                                              checked={modeOfSale.state}
                                              onChange={() => {
                                                  handleStateSortedVisibilityChangeCode(
                                                      modeOfSale,
                                                      key
                                                  );
                                              }}
                                              onColor="#c2eddd"
                                              onHandleColor="#34C38F"
                                              width={55}
                                              height={20}
                                              className="afa_icn_printCodePostalInOnSiteSalesMode"
                                          />
                                      </div>
                                  </div>
                              </div>
                          )
                      )
                    : null}
            </div>
            <div className="d-flex  align-items-center flex-wrap ">
                <label className="p-2" style={{ width: "15%" }}>
                    {t("City")}
                </label>
                {customerInformationModeSaleCompany !== undefined
                    ? customerInformationModeSaleCompany.map(
                          (modeOfSale: any, key: any): JSX.Element => (
                              <div className="text-danger row b-2 align-items-center">
                                  <div
                                      className="d-flex col-4"
                                      style={{ color: "#212529 " }}
                                  >
                                      <div className="px-1">
                                          <h6 style={{ width: "87px" }}>
                                              {t(modeOfSale.designation)}
                                          </h6>
                                      </div>
                                      <div className="mr-2">
                                          <ReactSwitch
                                              uncheckedIcon={false}
                                              checkedIcon={false}
                                              handleDiameter={26}
                                              offColor="#f7b4b8"
                                              offHandleColor="#E30613"
                                              checked={modeOfSale.state}
                                              onChange={() => {
                                                  handleStateSortedVisibilityChangeCompany(
                                                      modeOfSale,
                                                      key
                                                  );
                                              }}
                                              onColor="#c2eddd"
                                              onHandleColor="#34C38F"
                                              width={55}
                                              height={20}
                                              className="afa_icn_printCityInOnSiteSalesMode"
                                          />
                                      </div>
                                  </div>
                              </div>
                          )
                      )
                    : null}
            </div>
        </div>
    );
}
