import { proxy } from "valtio";

type StoreType = {
    isSaleSupport: boolean;
    numberArchived: number;
    numberActive: number;
    isAssociateSaleSupportOpened: boolean;
    localShop: any;
    isAssociateSaleSupportClosed: boolean;
    numberActiveMultiShop: number;
    numberArchiveMultiShop: number;
    isSaleSupportAssociatedModifiedClosed: boolean;
    idsLocalShop: string[];
    isAssociatedSaleSupport: boolean;
    selectedRows: any[];
    isAssociatedModalClosed: boolean;
    ids: number;
    isSaleSupportAssociatedArchivedClosed: boolean;
    isModified: boolean;
    isActivation: boolean;
    isAssociatedClosed: boolean;
    shopsSaleSupportData: any[];
    saleSupportData: any;
    activeSaleSupports: any[];
    selectedSaleSupports: any[];
    isHistoryShopsModalOpened: boolean;
    isHistoryModalOpened: boolean;
    shopsSelectedRow: any;
    isArchiveSVModalOpened: boolean;
    archivedData: any[];
    DesignationSaleSupportFranchise: any;
    dataFranchiseSaleSupport: any;
    isArchivedSaleSupportClicked: any;
    shopDataIsUpdated: boolean | number;
    associatedSaleSupport: any[];
    franchiseDataIsUpdated: boolean;
    listFranchiseArchived: any[];
    isInputUpdated: boolean;
    selectedHistorySaleSupports: any;
    isInlineErrorCleared: boolean;
    salesSupportFavored: any;
    saleSupportToBeArchived: any;
    isActionsClicked: boolean;
    isEdited: boolean;
    actions: Function;
    validationAction: any;
    modify: boolean;
    key: any;
    isLoading: boolean;
};

export const store = proxy<StoreType>({
    isSaleSupport: false,
    numberArchived: 0,
    numberActive: 0,
    isAssociateSaleSupportOpened: false,
    localShop: [],
    isAssociateSaleSupportClosed: false,
    numberActiveMultiShop: 0,
    numberArchiveMultiShop: 0,
    isSaleSupportAssociatedModifiedClosed: false,
    idsLocalShop: [],
    isAssociatedSaleSupport: false,
    selectedRows: [],
    isAssociatedModalClosed: false,
    ids: 0,
    isSaleSupportAssociatedArchivedClosed: false,
    isModified: false,
    isActivation: false,
    isAssociatedClosed: false,
    shopsSaleSupportData: [],
    saleSupportData: {},
    activeSaleSupports: [],
    selectedSaleSupports: [],
    isHistoryShopsModalOpened: false,
    isHistoryModalOpened: false,
    shopsSelectedRow: [],
    isArchiveSVModalOpened: false,
    archivedData: [],
    DesignationSaleSupportFranchise: [],
    dataFranchiseSaleSupport: [],
    isArchivedSaleSupportClicked: false,
    shopDataIsUpdated: false,
    franchiseDataIsUpdated: false,
    associatedSaleSupport: [],
    listFranchiseArchived: [],
    isInputUpdated: false,
    selectedHistorySaleSupports: {},
    isInlineErrorCleared: false,
    salesSupportFavored: [],
    saleSupportToBeArchived: {},
    isActionsClicked: false,
    isEdited: false,
    actions: () => {},
    validationAction: {},
    modify: false,
    key: 0,
    isLoading: false,
});

export function setIsLoading(isLoading: boolean): void {
    store.isLoading = isLoading;
}

export function setModify(value: boolean): void {
    store.modify = value;
}
export function setIsHistoryModalOpened(isHistoryModalOpened: boolean): any {
    store.isHistoryModalOpened = isHistoryModalOpened;
}

export function setSalesSupportFavored(salesSupportFavored: any): any {
    let localIndex: number = store.salesSupportFavored.findIndex((e: any) => {
        return e.id === salesSupportFavored.id;
    });
    if (localIndex === -1 || localIndex === undefined) {
        store.salesSupportFavored.push(salesSupportFavored);
    } else {
        store.salesSupportFavored[localIndex] = salesSupportFavored;
    }

    if (salesSupportFavored.status === true) {
        store.salesSupportFavored = store.salesSupportFavored.map((el: any) =>
            el.id !== salesSupportFavored.id ? { id: el.id, status: false } : el
        );
    }
}

export function setIsHistoryShopsModalOpened(
    isHistoryShopsModalOpened: boolean
): void {
    store.isHistoryShopsModalOpened = isHistoryShopsModalOpened;
}

export function setIsArchivedSaleSupportClicked(
    isArchivedSaleSupportClicked: boolean
): void {
    store.isArchivedSaleSupportClicked = isArchivedSaleSupportClicked;
}
export function setShopsSelectedRow(shopSelectedRow: any): any {
    store.shopsSelectedRow = shopSelectedRow;
}

export function setIsSaleSupport(isSaleSupport: boolean): void {
    store.isSaleSupport = isSaleSupport;
}
export function setShopsSaleSupportData(shopsSaleSupportData: any[]): void {
    store.shopsSaleSupportData = shopsSaleSupportData;
}

export function setActiveSaleSupports(activeSaleSupports: any): any {
    let localIndex: number = store.activeSaleSupports.findIndex((e: any) => {
        return e.Id === activeSaleSupports.Id;
    });
    if (localIndex === -1 || localIndex === undefined) {
        store.activeSaleSupports.push(activeSaleSupports);
    } else {
        store.activeSaleSupports[localIndex] = activeSaleSupports;
    }
}

export function setNumberArchived(number: number): void {
    store.numberArchived = number;
}

export function setNumberActive(number: number): void {
    store.numberActive = number;
}

export function setIsAssociateSaleSupportOpened(
    isAssociateSaleSupportOpened: boolean
): void {
    store.isAssociateSaleSupportOpened = isAssociateSaleSupportOpened;
}
export function setLocalShop(localShop: any[]): void {
    store.localShop = localShop;
}
export function setIsAssociateModalClosed(
    isAssociatedModalClosed: boolean
): void {
    store.isAssociatedModalClosed = isAssociatedModalClosed;
}

export function setNumberActiveMultiShop(number: number): void {
    store.numberActiveMultiShop = number;
}
export function setNumberArchiveMultiShop(number: number): void {
    store.numberArchiveMultiShop = number;
}

export function setIsSaleSupportAssociatedModifiedClosed(
    isSaleSupportAssociatedModifiedClosed: boolean
): void {
    store.isSaleSupportAssociatedModifiedClosed = isSaleSupportAssociatedModifiedClosed;
}
export function setIdsLocalShop(idsLocalShop: string[]): void {
    store.idsLocalShop = idsLocalShop;
}

export function setIsAssociatedSaleSupport(
    isAssociatedSaleSupport: boolean
): void {
    store.isAssociatedSaleSupport = isAssociatedSaleSupport;
}
export function setSelectedRow(selectedRows: any[]): void {
    store.selectedRows = selectedRows;
}

export function setIsAssociatedClosed(isAssociatedModalClosed: boolean): void {
    store.isAssociatedModalClosed = isAssociatedModalClosed;
}

export function setIds(id: number): void {
    store.ids = id;
}

export function setIsSaleSupportAssociatedArchivedClosed(
    isSaleSupportAssociatedArchivedClosed: boolean
): void {
    store.isSaleSupportAssociatedArchivedClosed = isSaleSupportAssociatedArchivedClosed;
}
export function setIsModified(isModified: boolean): void {
    store.isModified = isModified;
}
export function setIsActivation(isActivation: boolean): void {
    store.isActivation = isActivation;
}

export function setSaleSupportData(saleSupportData: any): void {
    store.saleSupportData = saleSupportData;
}
export function setSelectedSaleSupports(selectedSaleSupports: any[]): any {
    store.selectedSaleSupports = selectedSaleSupports;
}

export function setArchivedData(archivedData: any[]): any {
    store.archivedData = archivedData;
}
export function setIsArchiveSVModalOpened(
    isArchiveSVModalOpened: boolean
): any {
    store.isArchiveSVModalOpened = isArchiveSVModalOpened;
}

export function setDataDesignationSaleSupport(
    DesignationSaleSupportFranchise: any[]
): void {
    store.DesignationSaleSupportFranchise = DesignationSaleSupportFranchise;
}
export function setDataFranchiseSaleSupport(
    dataFranchiseSaleSupport: any[]
): void {
    store.dataFranchiseSaleSupport = dataFranchiseSaleSupport;
}
export function setShopDataIsUpdated(shopDataIsUpdated: boolean): void {
    store.shopDataIsUpdated = shopDataIsUpdated;
}
export function setFranchiseDataIsUpdated(
    franchiseDataIsUpdated: boolean
): void {
    store.franchiseDataIsUpdated = franchiseDataIsUpdated;
}
export function setAssociatedSaleSupport(associatedSaleSupport: any): void {
    store.associatedSaleSupport = associatedSaleSupport;
}
export function setListFranchiseArchived(listFranchiseArchived: any[]): void {
    store.listFranchiseArchived = listFranchiseArchived;
}
export function setIsInputUpdated(isInputUpdated: boolean): any {
    store.isInputUpdated = isInputUpdated;
}
export function setSelectedHistorySaleSupports(
    selectedHistorySaleSupports: any[]
): any {
    store.selectedHistorySaleSupports = selectedHistorySaleSupports;
}
export function setIsInlineErrorCleared(isInlineErrorCleared: any): any {
    store.isInlineErrorCleared = isInlineErrorCleared;
}
export function setSaleSupportToBeArchived(saleSupportToBeArchived: any): any {
    store.saleSupportToBeArchived = saleSupportToBeArchived;
}

export function setIsEdited(isEdited: boolean): any {
    store.isEdited = isEdited;
}

export function setIsActionsClicked(isActionsClicked: boolean): any {
    store.isActionsClicked = isActionsClicked;
}

export function setValidationAction(validationAction: any): any {
    store.validationAction = validationAction;
}

export function setActions(actions: any): any {
    store.actions = actions;
}

export function setKey(key: any): void {
    store.key = key;
}

export default store;
