import React from "react";
import { useTranslation } from "react-i18next";

type BeforeModificationType = {
    e: any;
};

export function BeforeModification({ e }: BeforeModificationType) {
    const { t } = useTranslation();

    let indexOfImage = e.selectedRow.Actions.split(",").findIndex((el: any) =>
        el.includes("image")
    );
    let ArrayDataBeforeImage = e.selectedRow[t("Before modification")]
        .split(",")
        .splice(0, indexOfImage);
    let ArrayDataAfterImage = e.selectedRow[t("Before modification")]
        .split(",")
        .splice(indexOfImage + 1);

    return (
        <div>
            {ArrayDataBeforeImage.join()}
            {indexOfImage !== -1 && indexOfImage !== 0 && ","}
            {indexOfImage !== -1 && (
                <img
                    alt="no data"
                    src={
                        e.selectedRow[t("Before modification")].split(",")[
                            e.selectedRow.Actions.split(
                                ","
                            ).findIndex((el: any) => el.includes("image"))
                        ]
                    }
                    height="25px"
                    width="35px"
                />
            )}
            {ArrayDataAfterImage.length !== 0 &&
                !ArrayDataAfterImage.every((el: any) => el === "") &&
                indexOfImage !== -1 &&
                ","}
            {ArrayDataAfterImage.join()}
        </div>
    );
}
