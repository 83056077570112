import React from "react";
import { StyledSelectInput } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import {
    designStore,
    generalConfigStore,
    setSelectedActionsButtonsLanguage,
} from "@store";
import { moveArrayElementToTheBegin } from "@helpers/general";

export function LanguagesSelectWrapper(): JSX.Element {
    const { t } = useTranslation();

    const { defaultLanguage } = useSnapshot(generalConfigStore);
    const {  activeLanguages } = useSnapshot(designStore);

      const orderLanguage = moveArrayElementToTheBegin(
          activeLanguages.filter(
                (element: LanguagesItemType): boolean =>
                    element.isDefault === true
            )[0],
            activeLanguages
        );
        
    const [languagesOptions, setLanguagesOptions] = React.useState<
        LocalOptionsType[]
    >(
        JSON.parse(JSON.stringify(orderLanguage)).map((option: any) => ({
            label: option.title,
            value: option.name,
        }))
    );

    const [selectedLanguageOption, setSelectedLanguageOption] = React.useState<
        LocalOptionsType
    >({
        label: defaultLanguage.title,
        value: defaultLanguage.name,
    });

    function handleSelectOnChangeEvent(selectedValue: LocalOptionsType) {
        setSelectedActionsButtonsLanguage(selectedValue.value as string);
        setSelectedLanguageOption(selectedValue);
    }

    React.useEffect(() => {
        setLanguagesOptions(
            JSON.parse(JSON.stringify(moveArrayElementToTheBegin(
          activeLanguages.filter(
                (element: LanguagesItemType): boolean =>
                    element.isDefault === true
            )[0],
            activeLanguages
        ))).map((option: any) => ({
                label: option.title,
                value: option.name,
            }))
        );
        setSelectedActionsButtonsLanguage(defaultLanguage.name);
        setSelectedLanguageOption({
            label: defaultLanguage.title,
            value: defaultLanguage.name,
        });
    }, [activeLanguages, defaultLanguage]);
    return (
        <StyledSelectInput
            id="languages-select"
            name="languages-select"
            options={languagesOptions}
            value={
                selectedLanguageOption !== undefined
                    ? selectedLanguageOption
                    : languagesOptions[0]
            }
            onChange={handleSelectOnChangeEvent}
            noOptionsMessage={() => t("There's no more choice")}
            className="w-25"
        />
    );
}
