import React from "react";
import {
    Validate,
    Back,
    OtherPaymentMethods,
    Ignore,
    GiveUp,
    Allergen,
    CustomerAccount,
    Next,
    NoTHANKS,
    OutOfStock,
    Payer,
    PayerPMR,
    CancelOrder,
    CancelOrderPMR,
    ValidateStep,
    NextStep,
    PreviousStep,
    AbandonArticle,
    ContinueOrder,
    NoThank,
    Yes,
    No,
    AddToOrder,
    Previous,
    NextPDC,
    SuspendedProduct,
    Without,
} from "./SvgIcons";

export function DynamicSvgComponent({
    nameSvg,
    width,
    height,
    selectedLanguage,
    isDisplayPrice,
}: any) {
    let Component = null;
    switch (nameSvg) {
        case "validate":
            Component = (
                <Validate
                    width={width}
                    height={height}
                    selectedLanguage={selectedLanguage}
                />
            );
            break;
        case "back":
            Component = (
                <Back
                    width={width}
                    height={height}
                    selectedLanguage={selectedLanguage}
                />
            );
            break;
        case "otherPaymentMethods":
            Component = (
                <OtherPaymentMethods
                    width={width}
                    height={height}
                    selectedLanguage={selectedLanguage}
                />
            );
            break;
        case "ignore":
            Component = (
                <Ignore
                    width={width}
                    height={height}
                    selectedLanguage={selectedLanguage}
                />
            );
            break;
        case "giveUp":
            Component = (
                <GiveUp
                    width={width}
                    height={height}
                    selectedLanguage={selectedLanguage}
                />
            );
            break;
        case "allergen":
            Component = (
                <Allergen
                    width={width}
                    height={height}
                    selectedLanguage={selectedLanguage}
                />
            );
            break;
        case "customerAccount":
            Component = (
                <CustomerAccount
                    width={width}
                    height={height}
                    selectedLanguage={selectedLanguage}
                />
            );
            break;
        case "next":
            Component = (
                <Next
                    width={width}
                    height={height}
                    selectedLanguage={selectedLanguage}
                />
            );
            break;
        case "noThanks1":
            Component = (
                <NoTHANKS
                    width={width}
                    height={height}
                    selectedLanguage={selectedLanguage}
                />
            );
            break;
        case "outOfStock":
            Component = (
                <OutOfStock
                    width={width}
                    height={height}
                    selectedLanguage={selectedLanguage}
                />
            );
            break;
        case "payer":
            Component = (
                <Payer
                    width={width}
                    height={height}
                    selectedLanguage={selectedLanguage}
                    isDisplayPrice={isDisplayPrice}
                />
            );
            break;
        case "payerPMR":
            Component = (
                <PayerPMR
                    width={width}
                    height={"90px"}
                    selectedLanguage={selectedLanguage}
                />
            );
            break;
        case "cancelOrder":
            Component = (
                <CancelOrder
                    width={width}
                    height={height}
                    selectedLanguage={selectedLanguage}
                />
            );
            break;
        case "cancelOrderPMR":
            Component = (
                <CancelOrderPMR
                    width={width}
                    height={height}
                    selectedLanguage={selectedLanguage}
                />
            );
            break;
        case "validStep":
            Component = (
                <ValidateStep
                    width={width}
                    height={"70px"}
                    selectedLanguage={selectedLanguage}
                />
            );
            break;
        case "nextStep":
            Component = (
                <NextStep
                    width={width}
                    height={height}
                    selectedLanguage={selectedLanguage}
                />
            );
            break;
        case "previousStep":
            Component = (
                <PreviousStep
                    width={width}
                    height={height}
                    selectedLanguage={selectedLanguage}
                />
            );
            break;
        case "abandonArticle":
            Component = (
                <AbandonArticle
                    width={width}
                    height={height}
                    selectedLanguage={selectedLanguage}
                />
            );
            break;
        case "continueOrder":
            Component = (
                <ContinueOrder
                    width={width}
                    height={height}
                    selectedLanguage={selectedLanguage}
                />
            );
            break;
        case "noThanks":
            Component = (
                <NoThank
                    width={width}
                    height={height}
                    selectedLanguage={selectedLanguage}
                />
            );
            break;
        case "oui":
            Component = (
                <Yes
                    width={width}
                    height={height}
                    selectedLanguage={selectedLanguage}
                />
            );
            break;
        case "non":
            Component = (
                <No
                    width={width}
                    height={height}
                    selectedLanguage={selectedLanguage}
                />
            );
            break;
        case "addToOrder":
            Component = (
                <AddToOrder
                    width={width}
                    height={height}
                    selectedLanguage={selectedLanguage}
                />
            );
            break;
        case "previous":
            Component = (
                <Previous
                    width={width}
                    height={height}
                    selectedLanguage={selectedLanguage}
                />
            );
            break;
        case "nextPDC":
            Component = (
                <NextPDC
                    width={width}
                    height={height}
                    selectedLanguage={selectedLanguage}
                />
            );
            break;
        case "suspendedProduct":
            Component = (
                <SuspendedProduct
                    width={width}
                    height={height}
                    selectedLanguage={selectedLanguage}
                />
            );
            break;
        case "without":
            Component = (
                <Without
                    width={width}
                    height={height}
                    selectedLanguage={selectedLanguage}
                />
            );
            break;

        default:
            Component = <div></div>;
            break;
    }
    return Component;
}
