import React from "react";
import { useSnapshot } from "valtio";

import { designStore, generalConfigStore } from "@store";

import { getTopBannerImagePath } from "@helpers/general";

type PropsType = {
    children: React.ReactNode;
    selectedSubStep?: ProjectMainContentItemType;
    subStep?: any;
};

export function PreviewTopBannerBackgroundWrapper({
    children,
    selectedSubStep,
    subStep,
}: PropsType): JSX.Element {
    const {
        project: { template },
        files,
    } = useSnapshot(generalConfigStore);
    const { activePageName } = useSnapshot(designStore);

    let pageId: string;
    if (selectedSubStep !== undefined) {
        pageId = (template.pages.subSteps[
            selectedSubStep.shortName as string
        ] as PageType)!.topBanner.id as string;
    } else if (
        selectedSubStep === undefined &&
        subStep !== undefined &&
        subStep === 2
    ) {
        pageId = (template.pages.subSteps[
            "printerOptionsConfirmationEmail"
        ] as PageType)!.topBanner.id as string;
    } else {
        pageId = (template.pages.ways[activePageName] as PageType)!.topBanner
            .id as string;
    }
    const [
        topBannerBackgroundImageSrc,
        setTopBannerBackgroundImageSrc,
    ] = React.useState(
        getTopBannerImagePath(pageId, files) as {
            url: string;
            type: string;
        }
    );

    React.useEffect(() => {
        setTopBannerBackgroundImageSrc(
            getTopBannerImagePath(pageId, files) as {
                url: string;
                type: string;
            }
        );
    }, [files, pageId]);

    return (
        <React.Fragment>
            {topBannerBackgroundImageSrc.type !== undefined &&
            topBannerBackgroundImageSrc.type === "video" ? (
                <React.Fragment>
                    <video
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        style={{
                            height: "110px",
                            width: "430px",
                            objectFit: "cover",
                            borderRadius: "2px",
                            position: "fixed",
                            zIndex: 0,
                        }}
                        src={topBannerBackgroundImageSrc.url}
                    />
                    {children}
                </React.Fragment>
            ) : (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                        backgroundImage: `url('${topBannerBackgroundImageSrc.url}')`,
                        width: "430px",
                        backgroundSize: "cover",
                        height: "110px",
                        borderRadius: "2px",
                    }}
                >
                    {children}
                </div>
            )}
        </React.Fragment>
    );
}
