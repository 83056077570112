import React from "react";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import { getPathAuthorization } from "@components/VerticalLayout";
import { useLocation } from "react-router-dom";

import { store } from "@components/VerticalLayout/store";
import { setModify } from "./store";

import { MainColorTabsWrapper } from "@components/Common/MainColorTabsWrapper";
import PageTitle from "@components/Common/PageTitle";
import MessageInfo from "@components/Common/MessageInfo";
import ServicesFranchise from "./ServiceFranchise";
import ServicesShops from "./ServicesShops";
import { StyledTabs } from "./StyledTabs";

import "./index.css";

export function Services() {
    const location = useLocation();
    setModify(getPathAuthorization(location.pathname).Modifie);
    const { t } = useTranslation();
    const [isServicesArchived, setIsServicesArchived] = React.useState<boolean>(
        false
    );
    const { oneShop } = useSnapshot(store);

    const tabs1 = (
        <ServicesFranchise
            isServicesArchived={isServicesArchived}
            setIsServicesArchived={setIsServicesArchived}
        />
    );

    const tabs2 = (
        <StyledTabs
            tabs={[
                {
                    content: (
                        <ServicesFranchise
                            isServicesArchived={isServicesArchived}
                            setIsServicesArchived={setIsServicesArchived}
                        />
                    ),
                    disabled: false,
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark cmn_btn_franchise"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Service")}
                        </div>
                    ),
                },
                {
                    content: (
                        <ServicesShops
                            isServicesArchived={isServicesArchived}
                            setIsServicesArchived={setIsServicesArchived}
                        />
                    ),
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark border-0 cmn_btn_boutique"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Store service")}
                        </div>
                    ),
                },
            ]}
        />
    );
    return (
        <React.Fragment>
            <ToastContainer limit={1} />
            <PageTitle title={t("General settings")} />
            <div className="page-content__clz">
                <div className="srv_txt_service">
                    <StyledBreadcrumb
                        items={[
                            {
                                item: "Settings".toUpperCase(),
                                link: `${process.env.REACT_APP_PORTAIL_URL}`,
                            },
                            {
                                item:
                                    localStorage
                                        .getItem("selectedAccount")
                                        ?.toUpperCase() || "",
                                link: oneShop
                                    ? "/setting/customer-account"
                                    : "/compte",
                            },
                            {
                                item: t("General settings").toUpperCase(),
                                link: "#",
                            },
                            {
                                item: !isServicesArchived
                                    ? t("Service").toUpperCase()
                                    : t("Archived Service").toUpperCase(),
                                link: "#",
                            },
                        ]}
                    />
                </div>

                <MessageInfo
                    message={t(
                        "The services such as the morning service and the lunch service of the store are configured in this section. During the configuration, it is important to check for any overlapping between the days and schedules of the different services."
                    )}
                />
                <MainColorTabsWrapper>
                    {oneShop ? tabs1 : tabs2}
                </MainColorTabsWrapper>
            </div>
        </React.Fragment>
    );
}
