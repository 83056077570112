import React from "react";
import classNames from "classnames";
import { useSnapshot } from "valtio";

import { designStore, generalConfigStore } from "@store";

import { getImageContentById } from "@helpers/general";

export function TopBannerWrapper(): JSX.Element {
    const { isLogoActive, activeTabIndex, activePageName } = useSnapshot(
        designStore
    );
    const {
        project: { template, files: images },
    } = useSnapshot(generalConfigStore);

    const topBannerBackgroundImageId = images.find((image) => {
        return (
            image.key === "topBannerBackground" && image.name === activePageName
        );
    })?.id;

    const topBannerBackgroundImageContent = getImageContentById(
        topBannerBackgroundImageId as string,
        images
    );

    const topBannerBackgroundImageSrc =
        topBannerBackgroundImageContent?.includes("http") ||
        topBannerBackgroundImageContent?.includes("https")
            ? topBannerBackgroundImageContent
            : `./images/${topBannerBackgroundImageContent}`;

    const logoImage = getImageContentById(
        (template.content.generalDesign as GeneralDesignType).logo.id as string,
        images
    );

    const logoImageSrc =
        logoImage?.includes("https") || logoImage?.includes("http")
            ? logoImage
            : `./images/${logoImage}`;

    return (
        <div
            className={classNames("p-2 d-flex justify-content-center", {
                "border-wrapper-banner__clz": activeTabIndex === 0,
            })}
            style={{
                backgroundImage: `url(${topBannerBackgroundImageSrc})`,
                width: "430px",
                backgroundSize: "cover",
            }}
        >
            {isLogoActive ? (
                <img
                    alt="img"
                    src={logoImageSrc}
                    style={{ height: "85px", width: "85px" }}
                />
            ) : null}
        </div>
    );
}
