import React from "react";
import classnames from "classnames";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next/";
import { StyledButton } from "@aureskonnect/react-ui";

import {
    store,
    setMonetics,
    setSelectedPeripherals,
    setCheckModificationEffect,
} from "./store";
import { store as useStore } from "../PeripheralConfigure/MoneticPeripheralConfigure/store";
import { getAuthorizationStates } from "@components/VerticalLayout/StyledSidebarMenu";
import { store as globalStore } from "@components/VerticalLayout/store";

import "./index.css";

export default function MoneticsActionColumn({
    rowData,
    element,
    selectedRows,
}: any) {
    const { t } = useTranslation();
    const { monetics } = useSnapshot(store);
    const { dataTable } = useSnapshot(useStore);
    const { oneShop } = useSnapshot(globalStore);

    const modify = getAuthorizationStates(
        "5590fe0f-69fc-9ff9-699d-d9a9defd12ac"
    ).Modifie;
    const [isSelected, setIsSelected] = React.useState<boolean>(false);

    function isSelectedMonetics() {
        let isSelectedmonetics: any[] = [];
        dataTable.forEach((element: any) => {
            if (
                selectedRows.length !== 0 &&
                selectedRows.filter((elt: any) => elt[t("Application")] !== "")
                    .length === 1
            ) {
                if (
                    selectedRows.filter(
                        (elt: any) => elt[t("Application")] !== ""
                    )[0].shopId === element.shopId
                ) {
                    for (let el of element.subRows) {
                        if (oneShop && el.peripheralType === "monetic") {
                            if (
                                monetics
                                    .map((item) => item.Id)
                                    .indexOf(el.id_monetic) === -1 &&
                                monetics.length === 0
                            ) {
                                isSelectedmonetics.push(el);
                            }
                        } else if (!oneShop && el?.subRows !== undefined) {
                            for (let row of el.subRows) {
                                if (row.peripheralType === "monetic") {
                                    if (
                                        monetics
                                            .map((item) => item.Id)
                                            .indexOf(row.id_monetic) === -1 &&
                                        monetics.length === 0
                                    ) {
                                        isSelectedmonetics.push(row);
                                    }
                                }
                            }
                        }
                    }
                }
            } else if (rowData.shopId === element.shopId) {
                for (let el of element.subRows) {
                    if (oneShop && el.peripheralType === "monetic") {
                        if (
                            monetics
                                .map((item) => item.Id)
                                .indexOf(el.id_monetic) === -1 &&
                            monetics.length === 0
                        ) {
                            isSelectedmonetics.push(el);
                        }
                    } else if (!oneShop && el?.subRows !== undefined) {
                        for (let row of el.subRows) {
                            if (row.peripheralType === "monetic") {
                                if (
                                    monetics
                                        .map((item) => item.Id)
                                        .indexOf(row.id_monetic) === -1 &&
                                    monetics.length === 0
                                ) {
                                    isSelectedmonetics.push(row);
                                }
                            }
                        }
                    }
                }
            }
        });
        return isSelectedmonetics;
    }
    let moneticsList: any[] = JSON.parse(JSON.stringify(isSelectedMonetics()));

    return (
        <StyledButton
            rounded
            outline
            className={`${classnames(
                "button-style-select___clz afa_btn_selectPeripheral",
                {
                    "not-allowed-icon__clz":
                        (((monetics
                            .map((item) => item.Id)
                            .indexOf(element.Id) === -1 &&
                            monetics.length === 1) ||
                            moneticsList
                                .map((item) => item.id_monetic)
                                .indexOf(element.Id) !== -1) &&
                            (((selectedRows.length !== 0
                                ? selectedRows
                                      .filter(
                                          (elt: any) =>
                                              elt[t("Application")] !== ""
                                      )[0]
                                      .subRows.find(
                                          (el: any) =>
                                              el.peripheralType === "monetic"
                                      )?.id_monetic !== element.Id
                                : rowData.subRows.find(
                                      (el: any) =>
                                          el.peripheralType === "monetic"
                                  )?.id_monetic !== element.Id) &&
                                monetics.length === 0) ||
                                monetics.length !== 0)) ||
                        modify === false ||
                        selectedRows.filter(
                            (elt: any) => elt[t("Application")] !== ""
                        ).length > 1,
                }
            )}`}
            variant={
                monetics.map((item) => item.Id).indexOf(element.Id) !== -1
                    ? "danger"
                    : "light"
            }
            onClick={() => {
                setMonetics(element);
                setIsSelected(!isSelected);
                setSelectedPeripherals(element);
                setCheckModificationEffect(true);
            }}
        >
            <span>
                {monetics.map((item) => item.Id).indexOf(element.Id) !== -1
                    ? t("Deselect")
                    : t("Select")}
            </span>
        </StyledButton>
    );
}
