import React from "react";
import ReactSwitch from "react-switch";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import {
    designStore,
    setIsFooterTicketNumberMessageActive,
    setIsTicketNumberActive,
    setIsFooterFinalMessageActive,
    setIsFooterTicketMessageActive,
    computedStore,
    setIsDesignFooterFinalMessageActive,
    setIsFooterTicketNumberActive,
} from "@store";

export function MainContent(): JSX.Element {
    const { t } = useTranslation();

    const {
        isFooterTicketNumberMessageActive,
        isTicketNumberActive,
        selectedLanguage,
        footerTicketNumberMessage,
        footerFinalMessage,
        isFooterFinalMessageActive,
        activeSlideIndex,
    } = useSnapshot(designStore);

    const { elements } = useSnapshot(computedStore);

    function handleTicketNumberMessageSwitchOnClickEvent() {
        setIsFooterTicketNumberMessageActive(
            !isFooterTicketNumberMessageActive
        );
        setIsFooterTicketMessageActive(
            !isFooterTicketNumberMessageActive,
            elements[activeSlideIndex].name
        );
    }

    function handleFooterFinalMessageSwitchOnClickEvent() {
        setIsFooterFinalMessageActive(!isFooterFinalMessageActive);
        setIsDesignFooterFinalMessageActive(
            !isFooterFinalMessageActive,
            elements[activeSlideIndex].name
        );
    }

    return (
        <div
            className="mx-2 mt-4 rounded border"
            style={{ borderColor: "#CECECE" }}
        >
            <div
                className="text-left px-4 d-flex align-items-center cursor__clz"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                {t("Footer")}
            </div>
            <div className="p-4" style={{ maxWidth: "400px" }}>
                <div className="py-2 switch-items__clz cursor__clz">
                    <div> {t("Show order number")} </div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isTicketNumberActive}
                        onChange={() => {
                            setIsTicketNumberActive(!isTicketNumberActive);
                            setIsFooterTicketNumberActive(
                                !isTicketNumberActive
                            );
                            handleTicketNumberMessageSwitchOnClickEvent();
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
                <div className="py-2 switch-items__clz cursor__clz">
                    <div>
                        <div> {t("Message de numéro de ticket")} </div>
                        <div style={{ color: "#B7B7B7", fontSize: "13px" }}>
                            {footerTicketNumberMessage[selectedLanguage]
                                ?.defaultContent !== undefined
                                ? t(
                                      footerTicketNumberMessage[
                                          selectedLanguage
                                      ].defaultContent
                                  )
                                : null}
                        </div>
                    </div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isFooterTicketNumberMessageActive}
                        onChange={handleTicketNumberMessageSwitchOnClickEvent}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                        disabled={isTicketNumberActive === false ? true : false}
                    />
                </div>

                <div className="py-2 switch-items__clz cursor__clz">
                    <div>
                        <div>{t("Final message")} </div>
                        <div style={{ color: "#B7B7B7", fontSize: "13px" }}>
                            {footerFinalMessage[selectedLanguage]
                                ?.defaultContent !== undefined
                                ? t(
                                      footerFinalMessage[selectedLanguage]
                                          .defaultContent
                                  )
                                : null}
                        </div>
                    </div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isFooterFinalMessageActive}
                        onChange={handleFooterFinalMessageSwitchOnClickEvent}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
            </div>
        </div>
    );
}
