import React from "react";
import { useTranslation } from "react-i18next";

export function DisplayName({ e }: any) {
        const { t } = useTranslation();
    const url = e.selectedRow[t("Image")];

    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <div>
                    <img alt="no data" src={url} height="25px" width="35px" />
                </div>
            ) : null}
        </React.Fragment>
    );
}
