import { t } from "i18next";

import React from "react";

export const AddToOrder = (props: any): JSX.Element => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            viewBox="0 0 461 87"
        >
            <g
                id="Groupe_9"
                data-name="Groupe 9"
                transform="translate(1767 4015)"
            >
                <rect
                    id="Rectangle_3"
                    data-name="Rectangle 3"
                    width="461"
                    height="87"
                    rx="9"
                    transform="translate(-1767 -4015)"
                    fill="#323333"
                />
                <text
                    id="Ajouter_à_la_commande"
                    data-name="Ajouter à la commande"
                    transform="translate(-1739 -3959)"
                    fill="#fff"
                    font-size="38"
                    font-family="HelveticaNeue-Medium, Helvetica Neue"
                    font-weight="500"
                >
                    <tspan x="0" y="0">
                        Ajouter à la commande
                    </tspan>
                </text>
                <rect
                    id="Rectangle_8"
                    data-name="Rectangle 8"
                    width="461"
                    height="87"
                    rx="9"
                    transform="translate(-1767 -4015)"
                    fill="#323333"
                />
                <text
                    id="addToOrder"
                    data-name="addToOrder"
                    transform="translate(-1536 -3959)"
                    fill="#fff"
                    font-size="38"
                    font-family="HelveticaNeue-Medium, Helvetica Neue"
                    font-weight="500"
                >
                    <tspan x="-203.091" y="0">
                        {t("Add to order", { lng: props.selectedLanguage })}
                    </tspan>
                </text>
            </g>
        </svg>
    );
};
