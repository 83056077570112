import React from "react";
import { useSnapshot } from "valtio";

import { designStore, generalConfigStore } from "@store";

import { getBackgroundPathById } from "@helpers/general";

type PropsType = {
    children: React.ReactNode;
};

export function PreviewBackgroundWrapper({ children }: PropsType): JSX.Element {
    const {
        imageKey,
        files,
        project: { template },
    } = useSnapshot(generalConfigStore);
    const { activePageName } = useSnapshot(designStore);

    const BackgroundPge: BackgroundType = (template.pages.ways[
        activePageName
    ] as
        | PageType
        | PaymentPageType
        | OrderTakingPageType
        | PrinterOptionsPageType).background;

    const [
        isBackgroundPreviewActive,
        setIsBackgroundPreviewActive,
    ] = React.useState(BackgroundPge.active);

    const [backgroundSrc, setBackgroundSrc] = React.useState(
        getBackgroundPathById(BackgroundPge.id as string, files) as {
            url: string;
            type: string;
        }
    );

    React.useEffect(() => {
        setBackgroundSrc(
            getBackgroundPathById(BackgroundPge.id as string, files) as {
                url: string;
                type: string;
            }
        );
        setIsBackgroundPreviewActive(BackgroundPge.active);
    }, [BackgroundPge.active, BackgroundPge.id, files]);

    return (
        <React.Fragment>
            {isBackgroundPreviewActive === true ? (
                backgroundSrc.type === "video" ? (
                    <React.Fragment>
                        <video
                            key={imageKey}
                            autoPlay={true}
                            muted={true}
                            loop={true}
                            style={{
                                height: "720px",
                                width: "430px",
                                objectFit: "cover",
                                position: "fixed",
                            }}
                            src={backgroundSrc.url}
                        />
                        {children}
                    </React.Fragment>
                ) : (
                    <div
                        key={imageKey}
                        style={{
                            backgroundImage: `url(${backgroundSrc.url})`,
                            height: "723px",
                            backgroundSize: "cover",
                        }}
                    >
                        {children}
                    </div>
                )
            ) : (
                <div
                    style={{
                        height: "725px",
                    }}
                >
                    {children}
                </div>
            )}
        </React.Fragment>
    );
}
