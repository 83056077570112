import React from "react";
import classnames from "classnames";
import {
    DisassociateIcon,
    PencilIcon,
    SettingsIcon,
    ShieldExclamationIcon,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next/";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";

import store, {
    setIsModalDissociateOpened,
    setSelectedApplicationOrb,
    setTemplate,
    setIsActionsClicked,
    setActions,
    setIsEdited,
} from "./store";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";

export function CustomIcons({
    e,
    setSelectedRows,
    isAddProject,
    setIsAddProject,
    isProjectArchived,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    setIsConfigurationModalOpened,
    setIdStore,
}: any) {
    const { t } = useTranslation();
    const {
        isModalDissociateOpened,
        modify,
        isEdited,
        isActionsClicked,
    } = useSnapshot(store);

    return (
        <React.Fragment>
            {e.selectedRow?.subRows !== undefined &&
            e.selectedRow.key_project !== undefined ? (
                <div className="d-flex justify-content-between mt-2">
                    <div>
                        {modify === true && (
                            <PencilIcon
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(() => {
                                            setIsEdited(false);
                                            setSelectedRows(e.selectedRow);
                                            if (!isAddProject) {
                                                setIsAddProject(!isAddProject);
                                            }
                                        });
                                    } else {
                                        setSelectedRows(e.selectedRow);
                                        if (!isAddProject) {
                                            setIsAddProject(!isAddProject);
                                        }
                                    }
                                }}
                                height={25}
                                width={25}
                                style={{ cursor: "pointer" }}
                                className={`${classnames(
                                    "pointer__clz cmn_icn_FRedit",
                                    {
                                        "not-allowed-icon__clz": isProjectArchived,
                                    }
                                )}`}
                            />
                        )}
                    </div>
                    <div>
                        <TimePastSvgIcon
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setSelectedApplicationOrb([
                                            {
                                                [e.selectedRow.shopId]:
                                                    e.selectedRow.ProjectId,
                                            },
                                        ]);
                                        setIsHistoryModalOpened(
                                            !isHistoryModalOpened
                                        );

                                        setSelectedRows([e.selectedRow]);

                                        setIsEdited(false);
                                    });
                                } else {
                                    setSelectedApplicationOrb([
                                        {
                                            [e.selectedRow.shopId]:
                                                e.selectedRow.ProjectId,
                                        },
                                    ]);
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );

                                    setSelectedRows([e.selectedRow]);
                                }
                            }}
                            className="pointer__clz ml-2 cmn_icn_FRhistory"
                        />
                    </div>
                </div>
            ) : e.selectedRow[t("Designation")] !== "" &&
              e.selectedRow.id_boutique !== undefined ? (
                <div>
                    {modify === true && (
                        <div>
                            <SettingsIcon
                                className="pointer__clz ml-4"
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(() => {
                                            setIsConfigurationModalOpened(true);
                                            setSelectedRows(e.selectedRow);
                                            setTemplate(e.selectedRow.template);
                                            if (
                                                e.selectedRow[t("Designation")]
                                                    .replace(/\s/g, "")
                                                    .toLowerCase() ===
                                                "boutique1"
                                            ) {
                                                setIdStore(2);
                                            } else if (
                                                e.selectedRow[t("Designation")]
                                                    .replace(/\s/g, "")
                                                    .toLowerCase() ===
                                                "boutique2"
                                            ) {
                                                setIdStore(3);
                                            }

                                            setIsEdited(false);
                                        });
                                    } else {
                                        setIsConfigurationModalOpened(true);
                                        setSelectedRows(e.selectedRow);
                                        setTemplate(e.selectedRow.template);
                                        if (
                                            e.selectedRow[t("Designation")]
                                                .replace(/\s/g, "")
                                                .toLowerCase() === "boutique1"
                                        ) {
                                            setIdStore(2);
                                        } else if (
                                            e.selectedRow[t("Designation")]
                                                .replace(/\s/g, "")
                                                .toLowerCase() === "boutique2"
                                        ) {
                                            setIdStore(3);
                                        }
                                    }
                                }}
                                height={25}
                                width={25}
                            />

                            <DisassociateIcon
                                height={40}
                                width={35}
                                fill="white"
                                style={{ position: "relative", bottom: "13px" }}
                                className={`${classnames(
                                    "pointer__clz ml-2 mt-3",
                                    {
                                        "not-allowed-icon__clz": isProjectArchived,
                                    }
                                )}`}
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(() => {
                                            setSelectedRows([e.selectedRow]);
                                            if (
                                                e.selectedRow.Status ===
                                                t("Published")
                                            ) {
                                                toast.error(
                                                    `${t(
                                                        "Unable to unlink a store from a published project."
                                                    )!}`,
                                                    {
                                                        position:
                                                            toast.POSITION
                                                                .TOP_CENTER,
                                                        autoClose: 2000,
                                                        theme: "colored",
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        icon: (
                                                            <ShieldExclamationIcon
                                                                width={25}
                                                                height={25}
                                                                fill="white"
                                                            />
                                                        ),
                                                    }
                                                );
                                            } else {
                                                setIsModalDissociateOpened(
                                                    !isModalDissociateOpened
                                                );
                                            }

                                            setIsEdited(false);
                                        });
                                    } else {
                                        setSelectedRows([e.selectedRow]);
                                        if (
                                            e.selectedRow.Status ===
                                            t("Published")
                                        ) {
                                            toast.error(
                                                `${t(
                                                    "Unable to unlink a store from a published project."
                                                )!}`,
                                                {
                                                    position:
                                                        toast.POSITION
                                                            .TOP_CENTER,
                                                    autoClose: 2000,
                                                    theme: "colored",
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    icon: (
                                                        <ShieldExclamationIcon
                                                            width={25}
                                                            height={25}
                                                            fill="white"
                                                        />
                                                    ),
                                                }
                                            );
                                        } else {
                                            setIsModalDissociateOpened(
                                                !isModalDissociateOpened
                                            );
                                        }
                                    }
                                }}
                            />
                        </div>
                    )}
                </div>
            ) : (
                modify === true && (
                    <SettingsIcon
                        className="pointer__clz ml-4"
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsConfigurationModalOpened(true);
                                    setSelectedRows(e.selectedRow);
                                    setTemplate(e.selectedRow.template);
                                    if (
                                        e.selectedRow[t("Designation")]
                                            .replace(/\s/g, "")
                                            .toLowerCase() === "boutique1"
                                    ) {
                                        setIdStore(2);
                                    } else if (
                                        e.selectedRow[t("Designation")]
                                            .replace(/\s/g, "")
                                            .toLowerCase() === "boutique2"
                                    ) {
                                        setIdStore(3);
                                    }

                                    setIsEdited(false);
                                });
                            } else {
                                setIsConfigurationModalOpened(true);
                                setSelectedRows(e.selectedRow);
                                setTemplate(e.selectedRow.template);
                                if (
                                    e.selectedRow[t("Designation")]
                                        .replace(/\s/g, "")
                                        .toLowerCase() === "boutique1"
                                ) {
                                    setIdStore(2);
                                } else if (
                                    e.selectedRow[t("Designation")]
                                        .replace(/\s/g, "")
                                        .toLowerCase() === "boutique2"
                                ) {
                                    setIdStore(3);
                                }
                            }
                        }}
                        height={22}
                        width={22}
                    />
                )
            )}
        </React.Fragment>
    );
}
