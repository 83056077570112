import { Card, Box } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { CSSTransition } from "react-transition-group";
import { designStore, generalConfigStore } from "@store";

import { data } from "@pages/GeneralConfig/Design/Content/OrderTaking/fastFoodCard";

import { AddIcon } from "../Icons/AddIcon";
import { AllergenIcon } from "../Icons/AllergenIcon";
import { FireIcon } from "../Icons/FireIcon";
import { InfoIcon } from "../Icons/InfoIcon";
import "./index.css";

export function MainContentWrapper(): JSX.Element {
    const { t } = useTranslation();
    const workflow = data.workFlow;

    const {
        project: {
            template: {
                content: { generalDesign },
                pages,
            },
        },
        secondColor,
        isAllergenOrderTakingActive,
        isOrderTakingCaloryActive,
        chosenTransition,
        isAnimationIn,
    } = useSnapshot(generalConfigStore);
    const { informationMessageCompositeProduct, breadcrumbTrail } = useSnapshot(
        designStore
    );
    return (
        <div
            className={classNames({
                "d-flex flex-column": breadcrumbTrail === true,
                "p-2": breadcrumbTrail === false,
            })}
        >
            <div className="mb-2">
                {informationMessageCompositeProduct === true ? (
                    <div className=" d-flex justify-content-center">
                        {t("Veuillez choisir votre plat")}
                    </div>
                ) : null}

                {(pages.ways["orderTaking"] as OrderTakingPageType)
                    .compositeProduct.isMinMaxShown ? (
                    <div className="d-flex justify-content-center">
                        ({t("Min 1 : Max 5")})
                    </div>
                ) : null}
            </div>

            <CSSTransition
                in={isAnimationIn}
                appear={true}
                timeout={chosenTransition.timeout}
                classNames={chosenTransition.className}
            >
                <div
                    className="d-flex flex-column justify-content-center"
                    style={{
                        gap: "30px",
                        marginTop:
                            informationMessageCompositeProduct === true
                                ? "40px"
                                : "90px",
                    }}
                >
                    <div
                        className="d-flex flex-row justify-content-center"
                        style={{ gap: "20px" }}
                    >
                        {workflow.product.slice(0, 2).map((product, index) => {
                            return (
                                <div
                                    key={index}
                                    className=" d-flex justify-content-center align-items-center"
                                    style={{ zIndex: 1 }}
                                >
                                    <Box>
                                        <Card
                                            style={{
                                                height: "100%",
                                                width: "133px",
                                                display: "grid",
                                                gridTemplateRows:
                                                    "70px 10px 20px 20px 20px",
                                            }}
                                        >
                                            <div
                                                className="d-flex flex-row justify-content-around align-items-center"
                                                style={{ gap: "10px" }}
                                            >
                                                <img
                                                    alt={product.name.content}
                                                    src={product.image}
                                                    style={{
                                                        height: "60px",
                                                    }}
                                                />
                                                <div
                                                    className="mt-4 d-flex flex-column justify-content-end align-items-center"
                                                    style={{
                                                        gap: "2px",
                                                    }}
                                                >
                                                    <InfoIcon width={15} />
                                                    {isAllergenOrderTakingActive ===
                                                    true ? (
                                                        <AllergenIcon
                                                            globalColor={
                                                                (generalDesign as GeneralDesignType)
                                                                    .colors[0]
                                                                    .content
                                                            }
                                                            width={15}
                                                        />
                                                    ) : null}
                                                    <AddIcon
                                                        secondColor={
                                                            secondColor
                                                        }
                                                        globalColor={
                                                            (generalDesign as GeneralDesignType)
                                                                .colors[0]
                                                                .content
                                                        }
                                                        width={30}
                                                    />
                                                </div>
                                            </div>

                                            {isOrderTakingCaloryActive ===
                                            true ? (
                                                <div
                                                    className="d-flex justify-content-center align-items-center"
                                                    style={{
                                                        gap: "2px",
                                                    }}
                                                >
                                                    <FireIcon />

                                                    <span
                                                        style={{
                                                            fontWeight:
                                                                "normal",
                                                            fontSize: "10px",
                                                            fontFamily:
                                                                "Segoe UI",
                                                            textAlign: "center",
                                                            marginTop: "5px",
                                                        }}
                                                    >
                                                        {
                                                            product.calorie
                                                                .content
                                                        }{" "}
                                                        {"cal"}
                                                    </span>
                                                </div>
                                            ) : null}

                                            <span
                                                className="d-flex  justify-content-center align-items-center"
                                                style={{
                                                    font:
                                                        "normal normal bold 9px/5px Segoe UI",
                                                    textAlign: "center",
                                                    width: "100%",
                                                    height: "100%",
                                                    color: "#343434",
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                {product.name.content}
                                            </span>

                                            <span
                                                className="d-flex justify-content-center align-items-center"
                                                style={{
                                                    textAlign: "center",
                                                    width: "100%",
                                                    height: "100%",
                                                    color: "#343434",
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                {product.price.content} {"€"}
                                            </span>

                                            <span
                                                style={{
                                                    textAlign: "center",
                                                    color: "#000000",
                                                    fontWeight: "normal",
                                                    fontSize: "6px",
                                                    fontFamily: "Segoe UI",
                                                }}
                                            >
                                                {product.description.content}
                                            </span>
                                        </Card>
                                    </Box>
                                </div>
                            );
                        })}
                    </div>
                    <div
                        className="d-flex flex-row justify-content-center"
                        style={{ gap: "20px" }}
                    >
                        {workflow.product.slice(2).map((product, index) => {
                            return (
                                <div
                                    key={index}
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ zIndex: 1 }}
                                >
                                    <Box>
                                        <Card
                                            style={{
                                                height: "100%",
                                                width: "133px",
                                                display: "grid",
                                                gridTemplateRows:
                                                    "70px 10px 20px 20px 20px",
                                            }}
                                        >
                                            <div
                                                className="d-flex flex-row justify-content-around align-items-center"
                                                style={{ gap: "10px" }}
                                            >
                                                <img
                                                    alt={product.name.content}
                                                    src={product.image}
                                                    style={{
                                                        height: "60px",
                                                    }}
                                                />
                                                <div
                                                    className="mt-4 d-flex flex-column justify-content-end align-items-center"
                                                    style={{
                                                        gap: "2px",
                                                    }}
                                                >
                                                    <InfoIcon width={15} />
                                                    {isAllergenOrderTakingActive ===
                                                    true ? (
                                                        <AllergenIcon
                                                            globalColor={
                                                                (generalDesign as GeneralDesignType)
                                                                    .colors[0]
                                                                    .content
                                                            }
                                                            width={15}
                                                        />
                                                    ) : null}
                                                    <AddIcon
                                                        secondColor={
                                                            secondColor
                                                        }
                                                        globalColor={
                                                            (generalDesign as GeneralDesignType)
                                                                .colors[0]
                                                                .content
                                                        }
                                                        width={30}
                                                    />
                                                </div>
                                            </div>
                                            {isOrderTakingCaloryActive ===
                                            true ? (
                                                <div
                                                    className="d-flex justify-content-center align-items-center"
                                                    style={{
                                                        gap: "2px",
                                                    }}
                                                >
                                                    <FireIcon />

                                                    <span
                                                        style={{
                                                            fontWeight:
                                                                "normal",
                                                            fontSize: "10px",
                                                            fontFamily:
                                                                "Segoe UI",
                                                            textAlign: "center",
                                                            marginTop: "5px",
                                                        }}
                                                    >
                                                        {
                                                            product.calorie
                                                                .content
                                                        }{" "}
                                                        {"cal"}
                                                    </span>
                                                </div>
                                            ) : null}

                                            <span
                                                className="d-flex justify-content-center align-items-center"
                                                style={{
                                                    font:
                                                        "normal normal bold 9px/5px Segoe UI",
                                                    textAlign: "center",
                                                    width: "100%",
                                                    height: "100%",
                                                    color: "#343434",
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                {product.name.content}
                                            </span>

                                            <span
                                                className="d-flex justify-content-center align-items-center"
                                                style={{
                                                    textAlign: "center",
                                                    width: "100%",
                                                    height: "100%",
                                                    color: "#343434",
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                {product.price.content} {"€"}
                                            </span>

                                            <span
                                                className="d-flex justify-content-center align-items-center"
                                                style={{
                                                    textAlign: "center",
                                                    color: "#000000",
                                                    fontWeight: "normal",
                                                    fontSize: "6px",
                                                    fontFamily: "Segoe UI",
                                                }}
                                            >
                                                {product.description.content}
                                            </span>
                                        </Card>
                                    </Box>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
}
