import React from "react";
import { useTranslation } from "react-i18next";
import { AvForm } from "availity-reactstrap-validation";
import { Table } from "reactstrap";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";

import {
    generalConfigStore,
    designStore,
    setInformationPrinterOptionsEntryEmailItems,
} from "@store";

import { SwiperLanguagesWrapper } from "@components/Common/Design/SwiperLanguagesWrapper";
import { CustomInput as Input } from "@components/Common/CustomInput";

export function MainContentTranslationEntryEmail(): JSX.Element {
    const { t } = useTranslation();
    const { project } = useSnapshot(generalConfigStore);
    const { selectedLanguage } = useSnapshot(designStore);

    const informationPrinterOptionsEntryEmailItems = ((project.template.pages
        .subSteps[
        "printerOptionsConfirmationEmail"
    ] as unknown) as PrinterOptionsConfirmationEmailSubStepType).header
        .informationMessage.entryemail;

    function handleResetTranslationOnClickEvent() {
        const localInformationMessageItems: PrinterOptionsInformationMessageContentType = {
            ...informationPrinterOptionsEntryEmailItems,
            languages: {
                ...informationPrinterOptionsEntryEmailItems.languages,
                [selectedLanguage]: {
                    defaultContent:
                        informationPrinterOptionsEntryEmailItems.languages[
                            selectedLanguage
                        ].defaultContent,
                    content:
                        informationPrinterOptionsEntryEmailItems.languages[
                            selectedLanguage
                        ].defaultContent,
                },
            },
        };

        setInformationPrinterOptionsEntryEmailItems(
            localInformationMessageItems
        );
    }

    function handleInformationMessageItemInputOnChangeEvent(content: string) {
        const localItems: PrinterOptionsInformationMessageContentType = {
            ...informationPrinterOptionsEntryEmailItems,
            languages: {
                ...informationPrinterOptionsEntryEmailItems.languages,
                [selectedLanguage]: {
                    defaultContent:
                        informationPrinterOptionsEntryEmailItems.languages[
                            selectedLanguage
                        ].defaultContent,
                    content: content,
                },
            },
        };

        setInformationPrinterOptionsEntryEmailItems(localItems);
    }

    return (
        <div style={{ width: "48rem" }}>
            <SwiperLanguagesWrapper />
            <div className=" mx-4">
                <AvForm>
                    <Table bordered className="languages-table__clz">
                        <thead>
                            <tr>
                                <th
                                    className="p-0"
                                    style={{
                                        width: "30%",
                                        verticalAlign: "middle",
                                    }}
                                >
                                    <div className="d-flex align-items-center justify-content-center">
                                        {t("Default text")}
                                    </div>
                                </th>
                                <th className="p-0">
                                    <div
                                        className="d-grid__clz"
                                        style={{
                                            gridTemplateColumns: "1fr 0.1fr",
                                        }}
                                    >
                                        <div className="d-flex align-items-center justify-content-center">
                                            {t("Custom")}
                                        </div>

                                        <StyledIconButton
                                            title={t("Reset")}
                                            className="m-0"
                                            icon="RefreshIcon"
                                            onClick={() => {
                                                handleResetTranslationOnClickEvent();
                                            }}
                                        >
                                            <RefreshIcon
                                                height={15}
                                                width={15}
                                            />
                                        </StyledIconButton>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {informationPrinterOptionsEntryEmailItems.active ? (
                                <tr>
                                    <td style={{ width: "30%" }}>
                                        {informationPrinterOptionsEntryEmailItems
                                            .languages[selectedLanguage]
                                            .defaultContent !== undefined
                                            ? informationPrinterOptionsEntryEmailItems
                                                  .languages[selectedLanguage]
                                                  .defaultContent
                                            : null}
                                    </td>
                                    <td>
                                        <Input
                                            value={
                                                informationPrinterOptionsEntryEmailItems
                                                    .languages[selectedLanguage]
                                                    .content !== undefined
                                                    ? informationPrinterOptionsEntryEmailItems
                                                          .languages[
                                                          selectedLanguage
                                                      ].content
                                                    : undefined
                                            }
                                            onChange={(
                                                event: React.ChangeEvent<
                                                    HTMLInputElement
                                                >
                                            ) => {
                                                return handleInformationMessageItemInputOnChangeEvent(
                                                    event.target.value
                                                );
                                            }}
                                        />
                                    </td>
                                </tr>
                            ) : null}
                        </tbody>
                    </Table>
                </AvForm>
            </div>
        </div>
    );
}
