import React from "react";
import { StyledTextInput } from "@aureskonnect/react-ui";
import { AvForm } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import "./index.css";

export default function DisplayNameInputCell({ row, column }: any) {
    const { t } = useTranslation();
        const updateDisplayName = (e: any) => {
        row.original.setUpdatedAdvancedDisplayName(
            (advancedDisplayName: any) => {
                return {
                    ...advancedDisplayName,
                    languages: {
                        ...advancedDisplayName.languages,
                        [column.id]: e.target.value,
                    },
                };
            }
        );
        row.original.setIsEdited(true);
    };

    return (
        <AvForm>
            <StyledTextInput
                id="test"
                name="test"
                style={{
                    border: "0px",
                    borderColor: "inherit",
                    webkitBoxShadow: "none",
                    boxShadow: "none",
                    textAlign: "center",
                }}
                placeholder={t("Write")}
                type="text"
                value={
                    row.original.updatedAdvancedDisplayName.languages[
                        column.id
                    ] ?? ""
                }
                onChange={(e: any) => {
                    updateDisplayName(e);
                }}
                className={`${classnames(
                    "centered-input",
                    column.id === "fr"
                        ? "cmn_inp_frenshDisplayName"
                        : column.id === "en"
                        ? "cmn_inp_englishDisplayName"
                        : ""
                )}`}
            />
        </AvForm>
    );
}
