import React from "react";
import classnames from "classnames";
import {
    CheckboxIcon,
    PlusIcon,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { uuid } from "uuidv4";

import { store as useStore } from "../../../../components/VerticalLayout/store";
import {
    setFranchiseDataIsUpdated,
    setIsHistoryModalOpened,
    setIsInlineErrorCleared,
    setShopDataIsUpdated,
    store,
    setActions,
    setIsActionsClicked,
    setIsEdited,
    setIsLoading,
    setKey,
} from "../store";

import ErrorToast from "../../../../components/Common/ErrorTost";
import { ConfirmationArchive } from "../ConfirmationArchive";

import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";

export function CustomSideFilter({
    selectedRows,
    saleSupport,
    setIsInInitializationModalOpened,
    setDataIsUpdated,
    setLocalFilterActive,
    setIsAddSaleSupportClicked,
    isAddSaleSupportClicked,
    setIsModalConfirmationOpened,
    isModalConfirmationOpened,
    isArchivedSaleSupportClicked,
    setIsArchivedSaleSupportClicked,
    setLimit,
}: any) {
    const { t } = useTranslation();
    const { oneShop, userID, franchiseID, shopID, operatorID } = useSnapshot(
        useStore
    );
    const {
        numberArchived,
        numberActive,
        isHistoryModalOpened,
        shopsSaleSupportData,
        isInlineErrorCleared,
        listFranchiseArchived,
        isEdited,
        isActionsClicked,
        modify,
        isLoading,
    } = useSnapshot(store);
    const uuidUser = localStorage.getItem("uuidUser");
    let dataArchive: any[] = [];
    if (!oneShop) {
        dataArchive = ArchivedSaleSupport();
    } else {
        dataArchive = ArchivedSaleSupportFranchise();
    }

    const [isActive, setIsActive] = React.useState<boolean>(false);
    const [
        isArchivedValidationModalOpened,
        setIsArchivedValidationModalOpened,
    ] = React.useState<boolean>(false);
    const [isValidateClicked, setIsValidateClicked] = React.useState<boolean>(
        false
    );
    function ArchivedSaleSupport() {
        let idsAssociated: any[] = [];
        if (shopsSaleSupportData !== undefined) {
            shopsSaleSupportData.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    idsAssociated.push(el.Id);
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function ArchivedSaleSupportFranchise() {
        let idsAssociated: any[] = [];
        if (listFranchiseArchived !== undefined) {
            listFranchiseArchived.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    function saleSupportExistInShops() {
        let isIdExist;
        let idsAssociated: any = [];

        shopsSaleSupportData.forEach((el: any) => {
            el.subRows.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        });
        selectedRows.forEach((el: any) => {
            if (idsAssociated.includes(el.Id)) {
                isIdExist = true;
            }
        });

        if (isIdExist === true && !oneShop) {
            ErrorToast(
                `${t(
                    "Please note that you cannot archive an item assigned to stores."
                )!}`
            );
        } else {
            setIsArchivedValidationModalOpened(true);
        }
    }

    async function handleArchiveSaleSupport(value: boolean) {
        setIsLoading(true);
        toast.dismiss();
        setLimit(1);
        let localData: any = [];
        let saleSupportUsed: any = [];
        let isLimitToastChang: boolean = false;
        let listOfSaleSupport = selectedRows.map((el: any) => el.Id).join(",");

        listOfSaleSupport = encodeURIComponent(listOfSaleSupport);
        if (!isArchivedSaleSupportClicked) {
            let SaleSupportURLUsed = `${process.env.REACT_APP_API_V2_URL}/settings/general/isSaleSupportUsed?userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}&saleSupport=${listOfSaleSupport}`;
            mutate(
                SaleSupportURLUsed,
                await fetch(SaleSupportURLUsed, {
                    method: "GET",
                    headers: { "Content-Type": "application/json" },
                })
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.saleSupportUsed.length > 0) {
                            saleSupportUsed = result.saleSupportUsed;
                            isLimitToastChang = true;
                            toast.error(
                                `${t(
                                    "Attention, you cannot archive a used element."
                                )}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                        }
                    })
                    .catch((error) => {
                        toast.error(`${t("There's an error")!}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    })
            );
        }

        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport`;
        if (
            (isValidateClicked && selectedRows.length !== 0) ||
            (isArchivedSaleSupportClicked && selectedRows.length !== 0)
        ) {
            selectedRows.forEach(async (el: any) => {
                if (saleSupportUsed.includes(el.Id)) {
                } else {
                    let objectData: any = {
                        userId: userID,
                        shopId: [selectedRows[0].shopId],
                        franchiseId: franchiseID,
                        operatorId: operatorID,
                        uuidUser: uuidUser,
                        data: {
                            [el.Id]: {
                                id: el.Id,
                                displayName: el[t("Display name")],
                                support_de_vente: el[t("Sale support")],
                                shopId: el.shopId,
                                tag: el.Tag,
                                isActive: false,
                                isArchived: value,
                                isFavorite: false,
                                advancedDisplayName: el.advancedDisplayName,
                            },
                        },
                    };
                    localData.push(objectData);
                }
            });

            try {
                if (localData.length > 0) {
                    await fetch(apiUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(localData),
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            setIsLoading(false);
                            setKey(uuid());
                            if (isLimitToastChang === true) setLimit(2);
                            toast.success(
                                !value
                                    ? `${t(
                                          "Sale support is successfully unarchived"
                                      )!}`
                                    : `${t(
                                          "Sale Support is successfully archived"
                                      )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                        });
                }
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setShopDataIsUpdated(true);
                setFranchiseDataIsUpdated(true);
                setIsLoading(false);
            }
        }

        setIsValidateClicked(!isValidateClicked);
    }

    function saleSupportExistInShopsActivated() {
        let isIdExist;
        let idsAssociated: any = [];
        shopsSaleSupportData.forEach((el: any) => {
            el.subRows.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        });

        selectedRows.forEach((el: any) => {
            if (idsAssociated.includes(el.Id)) {
                isIdExist = true;
            }
        });
        if (isIdExist === true) {
            setIsModalConfirmationOpened(!isModalConfirmationOpened);
        } else {
            !isArchivedSaleSupportClicked
                ? setIsArchivedValidationModalOpened(true)
                : handleArchiveSaleSupport(false);
        }
    }

    React.useEffect(() => {
        if (isValidateClicked) {
            handleArchiveSaleSupport(isActive);
        }
        // eslint-disable-next-line
    }, [isValidateClicked, isActive]);

    return (
        <div className="d-flex justify-content-start align-items-center ml-2">
            {modify === true ? (
                <>
                    {isAddSaleSupportClicked !== true ? (
                        <span
                            onClick={() => {
                                setIsAddSaleSupportClicked(
                                    !isAddSaleSupportClicked
                                );
                                setLocalFilterActive(false);
                            }}
                            className={`${classnames(
                                "w-100 ml-2 cmn_btn_openForm",
                                {
                                    "not-allowed-icon__clz": isArchivedSaleSupportClicked,
                                }
                            )}`}
                        >
                            <CustomMainColorButtonWithIcon
                                icon="PlusIcon"
                                iconPosition="left"
                                rounded
                                variant="primary"
                                onClick={() => {
                                    setIsInlineErrorCleared(
                                        !isInlineErrorCleared
                                    );
                                }}
                            >
                                {t("Add a sale support")}
                            </CustomMainColorButtonWithIcon>
                        </span>
                    ) : (
                        <CustomMainColorIconButton
                            icon="PlusIcon"
                            className="sdv_btn_FRreset "
                            rounded
                            disabled={
                                saleSupport.Id !== undefined ? true : false
                            }
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsInInitializationModalOpened(true);
                                } else {
                                    setIsInlineErrorCleared(true);
                                }
                            }}
                        >
                            <PlusIcon height={20} width={20} fill="white" />
                        </CustomMainColorIconButton>
                    )}
                </>
            ) : null}

            <VerticalDotsIcon
                className="cmn_drp_FRmenu"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item pl-1 cmn_btn_FRlistActivated"
                    )}
                    onClick={() => {
                        setIsArchivedSaleSupportClicked(false);
                    }}
                >
                    {t("Enabled sales support")} ({numberActive})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item pl-1 cmn_btn_FRlistArchived "
                    )}
                    onClick={() => {
                        if (isEdited === true) {
                            setIsActionsClicked(!isActionsClicked);
                            setActions(() => {
                                setIsEdited(false);
                                setIsArchivedSaleSupportClicked(true);
                                setIsAddSaleSupportClicked(false);
                            });
                        } else {
                            setIsArchivedSaleSupportClicked(true);
                            setIsAddSaleSupportClicked(false);
                        }
                    }}
                >
                    {t("Archived sales Support")} ({numberArchived})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={`${classnames(
                        "dropdown-item pl-1  cmn_btn_FRhistory",
                        {
                            "not-allowed-icon__clz": selectedRows.length === 0,
                        }
                    )}`}
                    onClick={() => {
                        if (isEdited === true) {
                            setIsActionsClicked(!isActionsClicked);
                            setActions(() => {
                                setIsEdited(false);
                                setIsAddSaleSupportClicked(false);
                                if (selectedRows.length === 1) {
                                    ErrorToast(
                                        `${t(
                                            "Attention, it is a multiple action. Please select at least two items"
                                        )!}`
                                    );
                                } else
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                            });
                        } else {
                            if (selectedRows.length === 1) {
                                ErrorToast(
                                    `${t(
                                        "Attention, it is a multiple action. Please select at least two items"
                                    )!}`
                                );
                            } else
                                setIsHistoryModalOpened(!isHistoryModalOpened);
                        }
                    }}
                >
                    {t("Historical")}
                </div>
                {modify === true ? (
                    <>
                        <div
                            style={{ cursor: "pointer" }}
                            className={`${classnames(
                                "dropdown-item pl-1 cmn_btn_FRarchived cmn_btn_FRunarchive",
                                {
                                    "not-allowed-icon__clz":
                                        isLoading ||
                                        selectedRows.length === 0 ||
                                        (isArchivedSaleSupportClicked &&
                                            oneShop &&
                                            selectedRows.some((element: any) =>
                                                dataArchive.includes(element.Id)
                                            )),
                                }
                            )}`}
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsLoading(false);
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);
                                        setIsAddSaleSupportClicked(false);
                                        if (selectedRows.length === 1) {
                                            ErrorToast(
                                                `${t(
                                                    "Attention, it is a multiple action. Please select at least two items"
                                                )!}`
                                            );
                                        } else if (
                                            !isArchivedSaleSupportClicked
                                        ) {
                                            saleSupportExistInShops();
                                            setIsActive(true);
                                        } else {
                                            saleSupportExistInShopsActivated();
                                            setIsActive(false);
                                        }
                                    });
                                } else {
                                    if (selectedRows.length === 1) {
                                        ErrorToast(
                                            `${t(
                                                "Attention, it is a multiple action. Please select at least two items"
                                            )!}`
                                        );
                                    } else if (!isArchivedSaleSupportClicked) {
                                        saleSupportExistInShops();
                                        setIsActive(true);
                                    } else {
                                        saleSupportExistInShopsActivated();
                                        setIsActive(false);
                                    }
                                }
                            }}
                        >
                            {isArchivedSaleSupportClicked
                                ? t("Unarchive")
                                : t("Archive")}
                        </div>
                    </>
                ) : null}
            </div>
            {isArchivedValidationModalOpened ? (
                <ConfirmationArchive
                    isArchiveSVModalOpened={isArchivedValidationModalOpened}
                    setIsArchiveSVModalOpened={
                        setIsArchivedValidationModalOpened
                    }
                    setIsValidateClicked={setIsValidateClicked}
                />
            ) : null}
        </div>
    );
}
