import React from "react";
import { useSnapshot } from "valtio";

import { designStore } from "@store";

import { ActionsButtonsImageContent } from "./ActionsButtonsImageContent";
import {
    EMAIL_CONFIRMATION_PRINTER_OPTIONS_TAB,
    WITH_EMAIL_PRINTER_OPTIONS_TAB,
} from "@constants/index";
import { Button } from "reactstrap";
export function ActionsButtonsWrapper(): JSX.Element {
    const {
        actionsButtonsItems,
        activeTabPrinterOptionsSetupOptions,
        activeTabPrinterOptions,
    } = useSnapshot(designStore);

    const activeActionsButtons = actionsButtonsItems.filter((item) => {
        return item.active === true;
    });

    const mainActiveActionsButtons = activeActionsButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });
    return (
        <React.Fragment>
            <div
                className="d-flex justify-content-center py-2"
                style={{ gap: "5px" }}
            >
                {mainActiveActionsButtons.map((item, index) => {
                    return (
                        <ActionsButtonsImageContent item={item} key={index} />
                    );
                })}
                {activeTabPrinterOptionsSetupOptions !==
                    EMAIL_CONFIRMATION_PRINTER_OPTIONS_TAB &&
                activeTabPrinterOptions === WITH_EMAIL_PRINTER_OPTIONS_TAB ? (
                    <Button
                        style={{
                            height: "32px",
                            width: "150px",
                            background: "#0b0b0b",
                            fontSize: "13px",
                        }}
                    >
                        Imprimer ticket
                    </Button>
                ) : null}
            </div>
        </React.Fragment>
    );
}
