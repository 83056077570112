import React from "react";
import { useTranslation } from "react-i18next";
import { StyledLabel, StyledTextInput, InfoIcon } from "@aureskonnect/react-ui";
import { AvGroup } from "availity-reactstrap-validation";
import classnames from "classnames";
import { useSnapshot } from "valtio";
import { Tooltip } from "@mui/material";
import { Card, CardBody } from "reactstrap";
import ReactSwitch from "react-switch";
import { numberMatchWord } from "@constants/index";
import { store } from "@components/VerticalLayout/store";
import { store as FidelityStore } from "../store";

import "./index.css";

type FidelityAuresKonnectType = {
    numberFidelityPoints: string;
    setNumberFidelityPoints: Function;
    moneyCorrespondence: string;
    setMoneyCorrespondence: Function;
    sortedVisibility: any;
    setSortedVisibility: Function;
    setIsEdited: Function;
    dataFidelity?: any;
    setAuresKonnectGroup: Function;
    auresKonnectGroup: string;
    inputGroup: boolean;
    setInputGroup: Function;
    setIsCentralizationActive: Function;
    isCentralizationActive: boolean;
    isVisibiliteActive: boolean;
    setIsVisibiliteActive: Function;
    authenticationMode: any;
    setAuthenticationMode: Function;
    authenticationModeData: any;
    inputAuthenticationMode: boolean;
    setInputAuthenticationMode: Function;
    setDesignation: Function;
    designation: string;
    setChoiceFidelity: Function;
    choiceFidelity: string;
    isExist?: boolean;
    isFidelityNumberInValid: boolean;
    inputNumberFidelityPoints: boolean;
    setInputNumberFidelityPoints: Function;
    isTest: boolean;
    isConsult?: boolean;
};
export default function FidelityAuresKonnect({
    numberFidelityPoints,
    setNumberFidelityPoints,
    moneyCorrespondence,
    setMoneyCorrespondence,
    sortedVisibility,
    setSortedVisibility,
    setIsEdited,
    dataFidelity,
    setAuresKonnectGroup,
    auresKonnectGroup,
    inputGroup,
    setInputGroup,
    setIsCentralizationActive,
    isCentralizationActive,
    isVisibiliteActive,
    setIsVisibiliteActive,
    authenticationMode,
    setAuthenticationMode,
    authenticationModeData,
    setInputAuthenticationMode,
    inputAuthenticationMode,
    setDesignation,
    designation,
    setChoiceFidelity,
    choiceFidelity,
    isExist,
    isFidelityNumberInValid,
    inputNumberFidelityPoints,
    setInputNumberFidelityPoints,
    isTest,
    isConsult,
}: FidelityAuresKonnectType) {
    const { t } = useTranslation();
    const { oneShop } = useSnapshot(store);
    const { isConsultModalOpened } = useSnapshot(FidelityStore);
    const handleStateSortedVisibilityChange = (
        item: any,
        e: any,
        key: number
    ) => {
        item.state = !item.state;
        let newVisibility = [...sortedVisibility] as any;
        newVisibility[key].state = item.state;

        if (item.designation === "Emporter") {
            const driveIndex = newVisibility.findIndex(
                (el: any) => el.designation === "Drive"
            );
            if (driveIndex !== -1) {
                newVisibility[driveIndex].state = item.state;
            }
        }
        if (item.designation === "Drive") {
            const driveIndex = newVisibility.findIndex(
                (el: any) => el.designation === "Emporter"
            );
            if (driveIndex !== -1) {
                newVisibility[driveIndex].state = item.state;
            }
        }
        if (item.designation[0] === "Emporter") {
            const driveIndex = newVisibility.findIndex(
                (el: any) => el.designation[0] === "Drive"
            );
            if (driveIndex !== -1) {
                newVisibility[driveIndex].state = item.state;
            }
        }
        if (item.designation[0] === "Drive") {
            const driveIndex = newVisibility.findIndex(
                (el: any) => el.designation[0] === "Emporter"
            );
            if (driveIndex !== -1) {
                newVisibility[driveIndex].state = item.state;
            }
        }




        if (item.designation === "Takeaway") {
            const driveIndex = newVisibility.findIndex(
                (el: any) => el.designation === "Drive-through"
            );
            if (driveIndex !== -1) {
                newVisibility[driveIndex].state = item.state;
            }
        }
        if (item.designation === "Drive-through") {
            const driveIndex = newVisibility.findIndex(
                (el: any) => el.designation === "Takeaway"
            );
            if (driveIndex !== -1) {
                newVisibility[driveIndex].state = item.state;
            }
        }
        if (item.designation[0] === "Takeaway") {
            const driveIndex = newVisibility.findIndex(
                (el: any) => el.designation[0] === "Drive-through"
            );
            if (driveIndex !== -1) {
                newVisibility[driveIndex].state = item.state;
            }
        }
        if (item.designation[0] === "Drive-through") {
            const driveIndex = newVisibility.findIndex(
                (el: any) => el.designation[0] === "Takeaway"
            );
            if (driveIndex !== -1) {
                newVisibility[driveIndex].state = item.state;
            }
        }




        setSortedVisibility(newVisibility);
    };

    const handleOnCLickEvent = () => {
        if (isCentralizationActive === false) {
            setChoiceFidelity(
                choiceFidelity.includes(`(${t("Centralized")})`)
                    ? choiceFidelity
                    : `${choiceFidelity} (${t("Centralized")})`
            );
            setDesignation(
                designation.includes(`(${t("Centralized")})`)
                    ? designation
                    : `${designation} (${t("Centralized")})`
            );
        } else if (isCentralizationActive === true) {
            setChoiceFidelity(
                choiceFidelity.includes(`(${t("Centralized")})`)
                    ? choiceFidelity.replace(`(${t("Centralized")})`, "").trim()
                    : choiceFidelity
            );

            setDesignation(
                designation.includes(`(${t("Centralized")})`)
                    ? designation.replace(`(${t("Centralized")})`, "").trim()
                    : designation
            );
        }

        setIsCentralizationActive(!isCentralizationActive);
        setIsEdited(true);
    };

    const handleOnCLickEvent2 = () => {
        setIsVisibiliteActive(!isVisibiliteActive);
        setIsEdited(true);
    };

    return (
        <React.Fragment>
            <AvGroup className={` ${isConsult ? "pe-none__clz" : ""}`}>
                <StyledLabel className="required__clz mt-3" htmlFor="name">
                    {t("Group")}
                </StyledLabel>
                <StyledTextInput
                    autocomplete="off"
                    id="Group"
                    name="Group"
                    placeholder={t("Write")}
                    type="text"
                    onChange={(e: any) => {
                        setAuresKonnectGroup(e.target.value);
                        setInputGroup(false);
                        setIsEdited(true);
                    }}
                    className={classnames({
                        input__clz: inputGroup || isExist,
                        "not-allowed-icon__clz":
                            (oneShop && dataFidelity.Id !== undefined) ||
                            isTest ||
                            dataFidelity.Id !== undefined ||
                            (isCentralizationActive && isConsultModalOpened),
                    })}
                    value={auresKonnectGroup}
                />
                {inputGroup ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please enter a group")}
                    </div>
                ) : null}
                {isExist ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {choiceFidelity.startsWith(t("Fidelity Aures Konnect"))
                            ? t(
                                  "Display name, loyalty type and group combination already exists"
                              )!
                            : t(
                                  "The display name, fidelity type combination already exists"
                              )!}
                    </div>
                ) : null}
            </AvGroup>
            <AvGroup className={` ${isConsult ? "pe-none__clz" : ""}`}>
                <div className="d-flex justify-content-between">
                    {" "}
                    <StyledLabel className="mt-4" htmlFor="name">
                        {t("Enable Loyalty Centralization")}
                    </StyledLabel>
                    <Tooltip
                        title={
                            isCentralizationActive && isConsultModalOpened
                                ? ""
                                : t(
                                      "The centralisation of loyalty makes it possible to group customer data, thus offering them the possibility to use their balance of loyalty points in all franchise stores"
                                  )
                        }
                    >
                        <div>
                            <InfoIcon
                                style={{ cursor: "pointer", marginTop: "24px" }}
                                className="pointer__clz hex_icn_FRretail ml-5"
                                height={25}
                                width={25}
                                fill="black"
                            />
                        </div>
                    </Tooltip>
                    <div style={{ marginTop: "24px" }}>
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isCentralizationActive}
                            onChange={() => {
                                handleOnCLickEvent();
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={52}
                            height={20}
                            className={classnames(
                                "fed_icn_loyaltyCentralization",
                                {
                                    "not-allowed-icon__clz":
                                        (oneShop &&
                                            dataFidelity.Id !== undefined) ||
                                        isTest ||
                                        isConsultModalOpened,
                                }
                            )}
                        />
                    </div>
                </div>
            </AvGroup>
            <AvGroup className={` ${isConsult ? "pe-none__clz" : ""}`}>
                <StyledLabel className="required__clz mt-3" htmlFor="name">
                    {t("Number of fidelity points")}
                </StyledLabel>
                <StyledTextInput
                    autocomplete="off"
                    id="Number of fidelity points"
                    name="Number of fidelity points"
                    placeholder={t("Write")}
                    type="text"
                    className={classnames("fed_inp_FRnumberOfLoyaltyPoints", {
                        input__clz:
                            isFidelityNumberInValid ||
                            inputNumberFidelityPoints,
                        "not-allowed-icon__clz":
                            (oneShop && dataFidelity.Id !== undefined) ||
                            isTest ||
                            (isCentralizationActive && isConsultModalOpened),
                    })}
                    onChange={(e: any) => {
                        setNumberFidelityPoints(e.target.value);
                        setIsEdited(true);
                        setInputNumberFidelityPoints(false);
                    }}
                    value={numberFidelityPoints}
                    validate={{
                        pattern: {
                            value: numberMatchWord,

                            errorMessage: t(
                                "The number of fidelity points is invalid"
                            ),
                        },
                    }}
                />
                {isFidelityNumberInValid ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("The number of fidelity points is invalid")!}
                    </div>
                ) : null}
                {inputNumberFidelityPoints ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please enter a number of fidelity points")}
                    </div>
                ) : null}
            </AvGroup>
            <AvGroup className={` ${isConsult ? "pe-none__clz" : ""}`}>
                <StyledLabel className="required__clz mt-3" htmlFor="name">
                    {t("Money correspondence")}
                </StyledLabel>
                <StyledTextInput
                    autocomplete="off"
                    id="Money correspondence"
                    name="Money correspondence"
                    placeholder={t("Write")}
                    type="text"
                    onChange={(e: any) => {
                        setIsEdited(true);
                        setMoneyCorrespondence(1);
                    }}
                    className="fed_inp_FRmoneyCorrespondence not-allowed-icon__clz"
                    value={moneyCorrespondence}
                />
            </AvGroup>
            {/* <AvGroup className={` ${isConsult ? "pe-none__clz" : ""}`}>
                <StyledLabel htmlFor="text" className="required__clz mt-3">
                    {t("Authentication mode")}
                </StyledLabel>
                <MultiCustomSelect
                    onDelete={() => {}}
                    result={authenticationMode}
                    setResult={setAuthenticationMode}
                    data={authenticationModeData}
                    onChange={(e: any) => {
                        setIsEdited(true);
                        setInputAuthenticationMode(false);
                    }}
                    className={classnames("fed_inp_FRauthenticationMode", {
                        invalid__clz: inputAuthenticationMode,
                        "not-allowed-icon__clz":
                            (oneShop && dataFidelity.Id !== undefined) ||
                            (isCentralizationActive && isConsultModalOpened),
                    })}
                    blocked={false}
                    placeholder={`${t("Select")}`}
                    value={t("Choose")}
                    name="Authentication mode"
                />
                {inputAuthenticationMode ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please select an authentication mode")}
                    </div>
                ) : null}
            </AvGroup> */}

            <AvGroup className={` ${isConsult ? "pe-none__clz" : ""}`}>
                <div className="d-flex justify-content-between">
                    {" "}
                    <StyledLabel className="mt-4" htmlFor="name">
                        {t("Visibility")}
                    </StyledLabel>
                    <div style={{ marginTop: "24px" }}>
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isVisibiliteActive}
                            onChange={() => {
                                handleOnCLickEvent2();
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={52}
                            height={20}
                            className={classnames(
                                "fed_icn_loyaltyCentralization",
                                {
                                    "not-allowed-icon__clz":
                                        (oneShop &&
                                            dataFidelity.Id !== undefined) ||
                                        isTest ||
                                        isConsultModalOpened,
                                }
                            )}
                        />
                    </div>
                </div>
            </AvGroup>

            {/* <div className="mt-3">
                <StyledLabel> {t("Visibility")}</StyledLabel>
            </div> */}
            <Card className={`mt-1 ${isConsult ? "pe-none__clz" : ""}`}>
                <CardBody>
                    {sortedVisibility.map(
                        (modeOfSale: any, key: any): JSX.Element => {
                            if (
                                modeOfSale.designation !== "Retrait C&C" &&
                                modeOfSale.designation !== "Click and Collect"
                            ) {
                                return (
                                    <div className="text-danger row align-items-center">
                                        <div
                                            className="d-flex col-4"
                                            style={{ color: "#212529 " }}
                                        >
                                            <div>
                                                <h6 style={{ width: "85px" }}>
                                                    {modeOfSale.designation}
                                                </h6>
                                            </div>
                                            <div>
                                                <ReactSwitch
                                                    uncheckedIcon={false}
                                                    checkedIcon={false}
                                                    handleDiameter={26}
                                                    offColor="#f7b4b8"
                                                    offHandleColor="#E30613"
                                                    checked={modeOfSale.state}
                                                    onChange={(e: any) => {
                                                        setIsEdited(true);
                                                        handleStateSortedVisibilityChange(
                                                            modeOfSale,
                                                            e,
                                                            key
                                                        );
                                                    }}
                                                    onColor="#c2eddd"
                                                    onHandleColor="#34C38F"
                                                    width={55}
                                                    height={20}
                                                    disabled={
                                                        isCentralizationActive &&
                                                        isConsultModalOpened
                                                    }
                                                    className={`${classnames(
                                                        "fed_icn_FRmodeOfSales",

                                                        {
                                                            "not-allowed-icon__clz":
                                                                (oneShop &&
                                                                    dataFidelity.Id !==
                                                                        undefined) ||
                                                                isTest ||
                                                                isConsultModalOpened,
                                                        },
                                                        modeOfSale.designation ===
                                                            "Restaurant"
                                                            ? "fed_icn_restaurant"
                                                            : modeOfSale.designation ===
                                                              "En livraison"
                                                            ? "fed_icn_delivery"
                                                            : modeOfSale.designation ===
                                                              "Sur place"
                                                            ? "fed_icn_eatIn"
                                                            : modeOfSale.designation ===
                                                              "Emporter"
                                                            ? "fed_icn_takeOut"
                                                            : modeOfSale.designation ===
                                                              "Drive"
                                                            ? "fed_icn_Drive"
                                                            : modeOfSale.designation ===
                                                              "Retrait C&C"
                                                            ? "fed_icn_WithdrawalC&C"
                                                            : ""
                                                    )}`}
                                                />
                                            </div>
                                        </div>
                                        {/* <div
            className="col-7"
            style={{
                background:
                    "rgb(255, 255, 255) none no-repeat scroll 0% 0% padding-box",
                border: "1px solid rgb(230, 230, 230)",
                margin: "1.25em",
                color: "#212529 ",
            }}
        >
            <div className="row d-flex justify-content-between">
                {modeOfSale.saleSupports.map(
                    (
                        saleSupport: any,
                        key1: any
                    ): JSX.Element => (
                        <div
                            className="d-flex justify-content-between bd-highlight"
                            key={saleSupport}
                        >
                            <div
                                className="p-2 bd-highlight"
                                style={{
                                    width: "60px",
                                }}
                            >
                                {
                                    saleSupport.designation
                                }
                            </div>
                            <div className="mt-2 flex-shrink-1 bd-highlight ml-1">
                                <ReactSwitch
                                    uncheckedIcon={
                                        false
                                    }
                                    checkedIcon={false}
                                    handleDiameter={26}
                                    offColor="#f7b4b8"
                                    offHandleColor="#E30613"
                                    checked={
                                        saleSupport.state
                                    }
                                    onChange={(
                                        e: any
                                    ) => {
                                        handleStateSellingSupportChange(
                                            saleSupport,
                                            e,
                                            key,
                                            key1
                                        );

                                        setIsEdited(
                                            true
                                        );
                                    }}
                                    onColor="#c2eddd"
                                    onHandleColor="#34C38F"
                                    width={52}
                                    height={20}
                                    disabled={
                                        !modeOfSale.state ||
                                        (isCentralizationActive &&
                                            isConsultModalOpened)
                                    }
                                    className={`${classnames(
                                        "fed_icn_FRenableDisable",

                                        saleSupport.designation ===
                                            "KIOSK"
                                            ? modeOfSale.designation ===
                                              "Restaurant"
                                                ? "fed_icn_kioskRestaurant"
                                                : modeOfSale.designation ===
                                                  "En livraison"
                                                ? "fed_icn__kioskDelivery"
                                                : modeOfSale.designation ===
                                                  "Sur place"
                                                ? "fed_icn__kioskEatIn"
                                                : modeOfSale.designation ===
                                                  "Emporter"
                                                ? "fed_icn__kioskTakeOut"
                                                : modeOfSale.designation ===
                                                  "Drive"
                                                ? "fed_icn_KioskDrive"
                                                : modeOfSale.designation ===
                                                  "Retrait C&C"
                                                ? "fed_icn_KioskRetraitC&C"
                                                : ""
                                            : ""
                                    )}`}
                                />
                            </div>
                        </div>
                    )
                )}
            </div>
        </div> */}
                                    </div>
                                );
                            } else {
                                return <div></div>;
                            }
                        }
                    )}
                </CardBody>
            </Card>
        </React.Fragment>
    );
}
