import React from "react";
import { StyledSelectInput } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import { setSelectedSaleModeValue, generalConfigStore } from "@store";

export function SalesMethodsSelectWrapper(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: {
            template: {
                content: { salesMethods },
            },
        },
        selectedSaleModeValue,
     
    } = useSnapshot(generalConfigStore);

    const [salesMethodsOptions, setSalesMethodsOptions] = React.useState<
        LocalOptionsType[]
    >([]);

    const [
        selectedSalesMethodsOption,
        setSelectedSalesMethodsOption,
    ] = React.useState<LocalOptionsType>(salesMethodsOptions[0]);

    function handleSelectOnChangeEvent(selectedValue: LocalOptionsType) {
        setSelectedSaleModeValue(selectedValue.value as string);
        setSelectedSalesMethodsOption(selectedValue);
    }

    React.useEffect(() => {
   
        const options = (salesMethods as ProjectContentItemType).items
            .filter((saleMethod) => {
                return (
                    saleMethod.name !== "Retrait C&C" &&
                    (saleMethod.informationModes as InformationModesContentType)
                        .items.length !== 0 &&
                    saleMethod.active === true
                );
            })
            .map((option) => ({
                label: t(option.name) as string,
                value: option.name,
            }));

        setSalesMethodsOptions(options);
          
    }, [salesMethods, t]);

    React.useEffect(() => {
       
        const initSaleMethodOption = salesMethodsOptions.find((option) => {
            return option.value === selectedSaleModeValue;
        });
if(initSaleMethodOption !== undefined){
  
 setSelectedSalesMethodsOption(initSaleMethodOption);
    
}
       
    }, [salesMethodsOptions, selectedSaleModeValue]);
    return (
        <StyledSelectInput
            id="sales-methods-select"
            name="sales-methods-select"
            options={salesMethodsOptions}
            value={
                selectedSalesMethodsOption !== undefined
                    ? selectedSalesMethodsOption
                    : salesMethodsOptions[0]
            }
            onChange={handleSelectOnChangeEvent}
            noOptionsMessage={() => t("There's no more choice")}
            className="w-25 mdi_drp_saleModeList"
        />
    );
}
