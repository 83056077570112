import React from "react";
import { useSnapshot } from "valtio";

import { designStore, generalConfigStore } from "@store";

import { getImageContentById } from "@helpers/general";
import { DynamicSvgComponent } from "@components/Common/DynamicSvgComponent";

export function ActionsButtonsWrapper(): JSX.Element {
    const {
        project: { template, files: images },
    } = useSnapshot(generalConfigStore);
    const { selectedLanguage } = useSnapshot(designStore);

    const actionsButtonsOrderTakingItems = (template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).actionsButtons.items.filter(
        (actionButton) =>
            actionButton.name === "Pay" || actionButton.name === "Cancel order"
    );
    const activeActionsButtons = actionsButtonsOrderTakingItems.filter(
        (item) => {
            return item.active === true;
        }
    );

    const mainActiveActionsButtons = activeActionsButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });
    let imageContentElement0 = getImageContentById(
        mainActiveActionsButtons[0].id,
        images,
        selectedLanguage
    );

    const imageScrElement0 =
        imageContentElement0?.includes("http") ||
        imageContentElement0?.includes("https")
            ? imageContentElement0
            : `./images/${imageContentElement0}`;
    return (
        <React.Fragment>
            {mainActiveActionsButtons.length > 1 ? (
                mainActiveActionsButtons.map((item, index) => {
                    const imageContent = getImageContentById(
                        item.id,
                        images,
                        selectedLanguage
                    );

                    const imageSrc =
                        imageContent?.includes("http") ||
                        imageContent?.includes("https")
                            ? imageContent
                            : `./images/${imageContent}`;

                    return (
                        <div key={index}>
                            {imageSrc.includes("png") ||
                            imageSrc.includes("webp") ||
                            imageSrc.includes("jpg") ? (
                                <>
                                    <img
                                        alt={item.name}
                                        src={imageSrc}
                                        style={{
                                            width: "200px",
                                            height: "25px",
                                            position: "relative",
                                            zIndex: 1,
                                        }}
                                    />
                                    {index === 1 ? (
                                        <div
                                            style={{
                                                zIndex: 100,
                                                position: "absolute",
                                                color: "white",
                                                fontSize: "13px",
                                                fontWeight: "bold",
                                                padding: "10px",
                                                left: "357px",
                                            }}
                                        >
                                            125.00 €
                                        </div>
                                    ) : null}
                                </>
                            ) : (
                                <div
                                    style={{ position: "relative", zIndex: 1 }}
                                >
                                    <DynamicSvgComponent
                                        nameSvg={imageContent as string}
                                        width={"210px"}
                                        height={"30px"}
                                        selectedLanguage={selectedLanguage}
                                        isDisplayPrice={"125.00 €"}
                                    />
                                </div>
                            )}
                        </div>
                    );
                })
            ) : (
                <div>
                    {imageScrElement0.includes("png") ||
                    imageScrElement0.includes("webp") ||
                    imageScrElement0.includes("jpg") ? (
                        <>
                            <img
                                alt={mainActiveActionsButtons[0].name}
                                src={imageScrElement0}
                                style={{
                                    width: "210px",
                                    height: "25px",
                                    position: "relative",
                                    zIndex: 1,
                                }}
                            />
                            <div
                                style={{
                                    zIndex: 100,
                                    position: "absolute",
                                    color: "white",
                                    fontSize: "13px",
                                    fontWeight: "bold",
                                    padding: "10px",
                                    left: "254px",
                                }}
                            >
                                125.00 €
                            </div>
                        </>
                    ) : (
                        <DynamicSvgComponent
                            nameSvg={imageContentElement0}
                            width={"250px"}
                            height={"25px"}
                            selectedLanguage={selectedLanguage}
                            isDisplayPrice={"125.00 €"}
                        />
                    )}
                </div>
            )}
        </React.Fragment>
    );
}
