import React from "react";
import {
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { useSnapshot } from "valtio";
import { CrossIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { t } from "i18next";
import { toast } from "react-toastify";

import {
    generalConfigStore,
    setConfigOptions,
    setIsConfirmationEmailActive,
    setIsCustomerAccountActive,
    setIsEmailActive,
    setIsPaperlessTicketActive,
    setIsUnauthenticatedEmailActive,
    setItems,
} from "@store";

import { DragItem } from "./DragItem";
import { addItemInList, removeItemFromList } from ".";

import "../index.css";

type ListItemPropsType = {
    index: number;
    item:
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
        | OrderTakingPageType
        | PrinterOptionsPageType;
};

export default function ListItem({
    item,
    index,
}: ListItemPropsType): JSX.Element {
    const { project } = useSnapshot(generalConfigStore);

    function handleRemoveWayElementButtonOnClickEvent(
        item:
            | PageType
            | ConsommationPageType
            | StandbyScreenPageType
            | PaymentPageType
            | OrderTakingPageType
            | PrinterOptionsPageType
    ) {
        const originalElements: (
            | PageType
            | ConsommationPageType
            | StandbyScreenPageType
            | PaymentPageType
            | OrderTakingPageType
            | PrinterOptionsPageType
        )[] = Object.values(project.template.pages.elements);

        const newWays = Object.values(project.template.pages.ways).filter(
            (way) => way.name !== item.name
        );
        originalElements.push(item);

        let newItems = {
            ...project.template.pages,
            elements: Object.assign(
                {},
                ...originalElements.map(
                    (
                        element:
                            | PageType
                            | ConsommationPageType
                            | StandbyScreenPageType
                            | PaymentPageType
                            | OrderTakingPageType
                            | PrinterOptionsPageType
                    ) => ({
                        [element.name]: { ...element, prefix: "elements" },
                    })
                )
            ),
            ways: Object.assign(
                {},
                ...newWays.map(
                    (
                        way:
                            | PageType
                            | ConsommationPageType
                            | StandbyScreenPageType
                            | PaymentPageType
                            | OrderTakingPageType
                            | PrinterOptionsPageType
                    ) => ({
                        [way.name]: { ...way, prefix: "ways" },
                    })
                )
            ),
        };
        const selectedSaleMethodDeliveryExist = (project.template.content
            .salesMethods as ProjectContentItemType).items.filter(
            (sale: any) => sale.name === "Delivery"
        )[0];

        if (item.name === "informationModes") {
            const localPagesItems: ItemsPagesType = { ...newItems };
            const informationModesConsommationIndex = Object.keys(
                localPagesItems.ways
            ).includes("informationModesConsommation")
                ? Object.keys(localPagesItems.ways).indexOf(
                      "informationModesConsommation"
                  )
                : -1;
            if (informationModesConsommationIndex === -1) {
                return setItems(newItems);
            } else {
                const [removedElement, newSourceList] = removeItemFromList(
                    Object.values(localPagesItems["ways"]),
                    informationModesConsommationIndex
                );

                const removedItem = { ...removedElement };

                removedItem.prefix = "elements";

                localPagesItems["ways"] = Object.assign(
                    {},
                    ...newSourceList.map((element) => ({
                        [element.name]: { ...element },
                    }))
                );

                const destinationList = Object.values(
                    localPagesItems["elements"]
                );

                let newDestinationList = addItemInList(
                    destinationList,
                    informationModesConsommationIndex,
                    removedItem
                ) as PageType[];

                localPagesItems["elements"] = Object.assign(
                    {},
                    ...newDestinationList.map((element, index: number) => ({
                        [element.name]: {
                            ...element,
                            index: index,
                        },
                    }))
                );

                return setItems(localPagesItems);
            }
        }

        if (item.name === "authenticationModes") {
            if (
                selectedSaleMethodDeliveryExist !== undefined &&
                selectedSaleMethodDeliveryExist.active === true
            ) {
                toast.warning(
                    t("You cannot remove authentication modes step"),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            } else {
                const initOptions = (project.template.content
                    .options as OptionsType).items;
                let newState = [...initOptions];
                newState[0] = {
                    ...newState[0],
                    active: false,
                };
                setConfigOptions(newState);
                setIsCustomerAccountActive(false);
                const localPagesItems: ItemsPagesType = { ...newItems };
                const connectionIndex = Object.keys(
                    localPagesItems.ways
                ).includes("connection")
                    ? Object.keys(localPagesItems.ways).indexOf("connection")
                    : -1;

                const loyaltyConsommationIndex = Object.keys(
                    localPagesItems.ways
                ).includes("loyaltyConsommation")
                    ? Object.keys(localPagesItems.ways).indexOf(
                          "loyaltyConsommation"
                      )
                    : -1;
                if (connectionIndex === -1 && loyaltyConsommationIndex === -1) {
                    return setItems(newItems);
                } else if (
                    connectionIndex === -1 &&
                    loyaltyConsommationIndex !== -1
                ) {
                    const [removedElement, newSourceList] = removeItemFromList(
                        Object.values(localPagesItems["ways"]),
                        loyaltyConsommationIndex
                    );

                    const removedItem = { ...removedElement };

                    removedItem.prefix = "elements";

                    localPagesItems["ways"] = Object.assign(
                        {},
                        ...newSourceList.map((element) => ({
                            [element.name]: { ...element },
                        }))
                    );

                    const destinationList = Object.values(
                        localPagesItems["elements"]
                    );

                    let newDestinationList = addItemInList(
                        destinationList,
                        loyaltyConsommationIndex,
                        removedItem
                    ) as PageType[];

                    localPagesItems["elements"] = Object.assign(
                        {},
                        ...newDestinationList.map((element, index: number) => ({
                            [element.name]: {
                                ...element,
                                index: index,
                            },
                        }))
                    );

                    return setItems(localPagesItems);
                } else {
                    const [removedElement, newSourceList] = removeItemFromList(
                        Object.values(localPagesItems["ways"]),
                        connectionIndex
                    );

                    const removedItem = { ...removedElement };

                    removedItem.prefix = "elements";

                    localPagesItems["ways"] = Object.assign(
                        {},
                        ...newSourceList.map((element) => ({
                            [element.name]: { ...element },
                        }))
                    );

                    const destinationList = Object.values(
                        localPagesItems["elements"]
                    );

                    let newDestinationList = addItemInList(
                        destinationList,
                        connectionIndex,
                        removedItem
                    ) as PageType[];

                    localPagesItems["elements"] = Object.assign(
                        {},
                        ...newDestinationList.map((element, index: number) => ({
                            [element.name]: {
                                ...element,
                                index: index,
                            },
                        }))
                    );
                    const newLocalPagesItems: ItemsPagesType = {
                        ...localPagesItems,
                    };
                    const loyaltyConsommationIndex = Object.keys(
                        newLocalPagesItems.ways
                    ).indexOf("loyaltyConsommation");

                    const [
                        removedElement1,
                        newSourceList1,
                    ] = removeItemFromList(
                        Object.values(newLocalPagesItems["ways"]),
                        loyaltyConsommationIndex
                    );

                    const removedItem1 = { ...removedElement1 };

                    removedItem1.prefix = "elements";

                    newLocalPagesItems["ways"] = Object.assign(
                        {},
                        ...newSourceList1.map((element) => ({
                            [element.name]: { ...element },
                        }))
                    );

                    const destinationList1 = Object.values(
                        newLocalPagesItems["elements"]
                    );

                    let newDestinationList1 = addItemInList(
                        destinationList1,
                        loyaltyConsommationIndex,
                        removedItem1
                    ) as PageType[];

                    newLocalPagesItems["elements"] = Object.assign(
                        {},
                        ...newDestinationList1.map(
                            (element, index: number) => ({
                                [element.name]: {
                                    ...element,
                                    index: index,
                                },
                            })
                        )
                    );
                    return setItems(newLocalPagesItems);
                }
            }
        }
        if (
            item.name === "informationModesConsommation" &&
            Object.keys(newItems.ways).indexOf(" informationModes") !==
                undefined
        ) {
            toast.warning(
                t(
                    "Attention, it is not recommended to remove 'information modes consommation' step before 'information modes' step"
                ),
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                }
            );

            return;
        }
        if (
            item.name === "loyaltyConsommation" &&
            Object.keys(newItems.ways).indexOf("authenticationModes") !==
                undefined
        ) {
            toast.warning(
                t(
                    "Attention, it is not recommended to remove 'loyalty consommation' step before 'authentication modes' step"
                ),
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                }
            );

            return;
        }
        if (
            item.name === "printerOptions" &&
            Object.keys(newItems.ways).indexOf("printerOptions") !== undefined
        ) {
            setIsEmailActive(false);
            setIsConfirmationEmailActive(false);
            setIsUnauthenticatedEmailActive(false);
            setIsPaperlessTicketActive(false);

            return setItems({
                ...newItems,
                elements: {
                    ...newItems.elements,
                    printerOptions: {
                        ...newItems.elements.printerOptions,
                        isPaperlessTicketActive: false,
                        printerOptionsConfig: {
                            ...(newItems.elements.printerOptions
                                .printerOptionsConfig as PrinterOptionsConfigType),
                            isEmailActive: false,
                            isConfirmationEmailActive: false,
                            isUnauthenticatedEmailActive: false,
                        },
                    },
                },
            });
        }

        return setItems(newItems);
    }

    return (
        <React.Fragment>
            <Draggable draggableId={String(item.id)} index={index}>
                {(
                    provided: DraggableProvided,
                    snapshot: DraggableStateSnapshot
                ): JSX.Element => {
                    return (
                        <DragItem
                            ref={provided.innerRef}
                            item={item}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            isDragging={snapshot.isDragging}
                        >
                            {item.prefix === "ways" ? (
                                <React.Fragment>
                                    <div className="ways-item__clz">
                                        {item.content !== undefined ? (
                                            <div
                                                style={{ position: "relative" }}
                                            >
                                                <img
                                                    src={`./images/${item.content}`}
                                                    alt={item.title}
                                                    style={{
                                                        height: "100%",
                                                        width: "100%",
                                                        objectFit: "fill",
                                                        position: "absolute",
                                                        borderTopRightRadius:
                                                            "1rem",
                                                        borderTopLeftRadius:
                                                            "1rem",
                                                    }}
                                                />
                                                {![
                                                    "orderTaking",
                                                    "finalMessage",
                                                    "salesMethods",
                                                    "standbyScreen",
                                                    "payment",
                                                ].includes(item.name) ? (
                                                    <div className="d-flex justify-content-end">
                                                        <StyledIconButton
                                                            icon="CrossIcon"
                                                            className="close-button__clz m-0"
                                                            onClick={() => {
                                                                handleRemoveWayElementButtonOnClickEvent(
                                                                    item
                                                                );
                                                            }}
                                                        >
                                                            <CrossIcon
                                                                height={15}
                                                                width={15}
                                                                fill="white"
                                                            />
                                                        </StyledIconButton>
                                                    </div>
                                                ) : null}
                                            </div>
                                        ) : null}

                                        <div className="ways-item-index__clz">
                                            <div
                                                style={{
                                                    borderBottomLeftRadius:
                                                        "1rem",
                                                }}
                                                className="bg-dark text-white p-2 d-flex justify-content-center align-items-center"
                                            >
                                                {index + 1}
                                            </div>

                                            <div
                                                style={{
                                                    background: "#E8E8E8",
                                                    borderBottomRightRadius:
                                                        "1rem",
                                                }}
                                                className="p-2 d-flex justify-content-center align-items-center"
                                            >
                                                {t(item.title)}
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <div
                                    className="elements-item__clz"
                                    style={{ height: "180px" }}
                                >
                                    {item.content !== undefined ? (
                                        <div
                                            className="d-flex justify-content-center"
                                            style={{
                                                position: "relative",
                                            }}
                                        >
                                            <img
                                                src={`./images/${item.content}`}
                                                alt={item.title}
                                                style={{
                                                    height: "100%",
                                                    width: "90px",
                                                    objectFit: "fill",
                                                    position: "absolute",
                                                    border: "1px solid",
                                                    borderRadius: "6px",
                                                }}
                                            />
                                        </div>
                                    ) : null}
                                    <div
                                        className="text-center p-2 d-flex justify-content-center align-items-center"
                                        style={{ fontSize: "14px" }}
                                    >
                                        {t(item.title)}
                                    </div>
                                </div>
                            )}
                        </DragItem>
                    );
                }}
            </Draggable>
        </React.Fragment>
    );
}
