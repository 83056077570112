import { CheckboxIcon } from "@aureskonnect/react-ui";
import React from "react";
import { ModalFooter } from "reactstrap";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import moment from "moment";
import { toast } from "react-toastify";

import { store as userStore } from "@components/VerticalLayout/store";
import {
    setIsModesModalPublishOpened,
    setIsLoading,
    store,
} from "../../../store/project";
import ModalAssociateStore from "./ModalAssociateStore";
import { setKey } from "../store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";

type CardFooterModalPublishType = {
    rowData: any;
};

export default function CardFooterModalPublish({
    rowData,
}: CardFooterModalPublishType) {
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isValidatedClicked, setIsValidatedClicked] = React.useState<boolean>(
        false
    );

    const { isModesModalPublishOpened } = useSnapshot(store);
    const {
        userID,
        franchiseID,
        oneShop,
        shopID,
        shopName,
        operatorID,
    } = useSnapshot(userStore);
    const uuidUser = localStorage.getItem("uuidUser");

    const { t } = useTranslation();
    const { projectData } = useSnapshot(store);
    const [isDisabled, setIsDisabled] = React.useState(false);
    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);

    function AssociateProject() {
        let idShops: any = [];
        if (rowData !== undefined) {
            Object.values(rowData.shopKey) !== undefined &&
                Object.values(rowData.shopKey).forEach((el: any) => {
                    idShops.push(el);
                });
        }
        idShops = [...new Set(idShops), "0"];
        return idShops;
    }
    function isAssociate() {
        let projectId: any = [];
        let associateskeys: any = [];
        let isAssociateShops: boolean = false;
        if (projectData !== undefined) {
            projectData.forEach((el: any) => {
                Object.values(el?.subRows).forEach((elment: any) => {
                    if (rowData?.subRows !== undefined) {
                        Object.values(rowData?.subRows).forEach((item: any) => {
                            if (
                                elment?.id_boutique === item?.id_boutique &&
                                el?.Status === "Publié"
                            ) {
                                associateskeys.push(el?.id_boutique);
                                projectId.push(el?.id_project);
                            }
                        });
                    }
                });
            });
        }
        if (Object.keys(associateskeys).length > 0) {
            isAssociateShops = true;
        }
        return isAssociateShops;
    }

    let isAssociateShops: boolean = isAssociate();

    async function handleSaveButtonOnClickEvent() {
        let localData: any = [];

        let data: any = {};
        data[shopID] = {
            designation: shopName,
            id_boutique: shopID,
            hoursOfUse:
                rowData.hoursOfUse !== undefined ? rowData.hoursOfUse : "",
        };
        let dataAssociate: any[] = AssociateProject();
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/status`;
        let savedData = {
            userId: userID,
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            shopId: oneShop ? [shopID, "0"] : dataAssociate,
            projectId: rowData.projectId,
            status: "Publié",
            modified_at: `${moment().format("DD/MM/YY [at] HH:mm a")}`,
        };
        localData.push(
            fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(savedData),
            }).then((response) => response.json())
        );

        try {
            mutate(
                apiUrl,
                await Promise.all(localData).then((result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                        toast.success(
                            `${t("The project is published successfully")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsLoading(false);
                        setKey(Math.floor(Math.random() * 10000));
                        setIsModesModalPublishOpened(
                            !isModesModalPublishOpened
                        );
                        setTimeout(RedisPublish, 3000);
                    }
                })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
    }
    async function handleSaveButtonOnClickEventDissociationShops() {
        if (isValidateButtonDisabled === false) {
            setIsValidateButtonDisabled(true);
            let localData: any = [];

            let dataAssociate: any[] = AssociateProject();
            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/status`;
            let savedData = {
                userId: userID,
                franchiseId: franchiseID,
                shopId: dataAssociate,
                projectId: rowData.projectId,
                operatorId: operatorID,
                uuidUser: uuidUser,
                status: "Publié",
                modified_at: `${moment().format("DD/MM/YY [at] HH:mm a")}`,
            };

            localData.push(
                fetch(apiUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(savedData),
                }).then((response) => response.json())
            );

            try {
                mutate(
                    apiUrl,
                    await Promise.all(localData).then(async (result: any) => {
                        let errors: boolean[] = result.map((el: any) => el.error);
                        if (!errors.includes(true)) {
                            toast.success(
                                `${t("The project is published successfully")!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            await dissociationShops();
                            setIsModalOpened(false);
                        } else {
                            throw Error(result.message);
                        }
                    })
                );
            } catch (e: any) {
                console.log(`${t("There's an error")}!`);
            }
            setIsLoading(false);

            setKey(Math.floor(Math.random() * 10000));
            setIsValidateButtonDisabled(false);
        }
    }
    function handleOnClickEvent() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/projects/delete`;
        projectData.forEach((el: any) => {
            let associateskeys: any = [];
            el?.subRows.forEach((elment: any) => {
                rowData.subRows.forEach((item: any) => {
                    if (
                        elment?.id_boutique === item?.id_boutique &&
                        el?.Status === "Publié"
                    ) {
                        associateskeys.push(item?.id_boutique);
                    }
                });
            });
            let savedData = {
                userId: userID,
                franchiseId: franchiseID,
                shopId: associateskeys.join(","),
                projectId: "PROJECT",
                operatorId: operatorID,
                uuidUser: uuidUser,
                project_id: el?.projectId,
            };

            if (associateskeys.length !== 0) {
                try {
                    mutate(
                        apiUrl,
                        fetch(apiUrl, {
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bareer ${localStorage.getItem(
                                    "jwt"
                                )}`,
                            },
                            body: JSON.stringify(savedData),
                            method: "delete",
                        })
                            .then((response) => response.json())
                            .then(async (data) => {
                                if (data.error) {
                                    throw Error(
                                        "Error while saving selection!"
                                    );
                                } else {
                                    setKey(Math.floor(Math.random() * 10000));
                                    setIsLoading(false);
                                }
                            })
                    );
                } catch (e: any) {
                    console.log(`${t("There's an error")}!`);
                }
            }
        });
    }
    const getProjectData = async (element: any) => {
        let dataProject = {};
        let apiUrl = `${
            process.env.REACT_APP_API_V2_URL
        }/settings/projectData?userId=106&shopId=${
            oneShop ? shopID : element?.shopId
        }&franchiseId=${franchiseID}&projectId=${
            element?.id_project ?? element?.projectId
        }`;
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        dataProject = data[0];
                    })
            );
            return dataProject;
        } catch (e: any) {}
    };
    async function dissociationShops() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/projects/dissociation/shops`;
        let apiProject = `${process.env.REACT_APP_API_V2_URL}/settings/project`;
        let keys: any = [];
        let projectIdProject: any = [];

        for (let el of projectData) {
            let associateskeys: any = [];
            let associateShops: any = [];
            for (let elment of el.subRows) {
                for (let item of rowData.subRows) {
                    if (
                        elment?.id_boutique === item?.id_boutique &&
                        el?.Status === "Publié"
                    ) {
                        associateskeys.push(item?.id_boutique);
                        keys.push(item?.id_boutique);
                        associateShops.push(...el.shopKey);
                    }
                }
                associateskeys = [...new Set(associateskeys)];
                associateShops = [...new Set(associateShops)];
                if (el.subRows.length === associateskeys.length)
                    projectIdProject.push(el);
            }
            let savedData = {
                userId: userID,
                franchiseId: franchiseID,
                project_id: "PROJECT",
                projectId: el?.projectId,
                operatorId: operatorID,
                uuidUser: uuidUser,
                shopId: "0",
                shopsKey: associateskeys.join(","),
            };
            if (associateskeys.length !== 0) {
                try {
                    mutate(
                        apiUrl,
                        fetch(apiUrl, {
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bareer ${localStorage.getItem(
                                    "jwt"
                                )}`,
                            },
                            body: JSON.stringify(savedData),
                            method: "POST",
                        })
                            .then((response) => response.json())
                            .then(async (data) => {
                                if (data.error) {
                                    throw Error(
                                        "Error while saving selection!"
                                    );
                                } else {
                                    setKey(Math.floor(Math.random() * 10000));
                                }
                            })
                    );
                    setIsLoading(false);
                } catch (e: any) {
                    console.log(`${t("There's an error")}!`);
                }
            }
        }
        for (let element of projectIdProject) {
            let dataProject: any = await getProjectData(element);
            let template = dataProject.template;
            let files = dataProject.files;

            let savedDataProject = {
                userId: userID,
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                shopId: "0",
                data: {
                    PROJECT: {
                        id_project: "PROJECT",
                        data_project: {
                            [element.projectId]: {
                                projectId: element.projectId,
                                designation: element[t("Designation")],
                                associates: {},
                                isArchived: true,
                                template: template,
                                files: files,
                                note: element[t("Remark")],
                                nombre: element.Nombre,
                                status: "en attente",
                                modified_at: `${moment().format(
                                    "MM/DD/YY [at] HH:mm a"
                                )}`,
                                hour: `${moment().format(
                                    "DD/MM/YY - HH:mm a"
                                )}`,
                            },
                        },
                    },
                },
            };

            try {
                mutate(
                    apiProject,
                    fetch(apiProject, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify(savedDataProject),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            } else {
                                setIsLoading(false);
                                setKey(Math.floor(Math.random() * 10000));
                            }
                        })
                );
            } catch (e: any) {
                console.log(`${t("There's an error")}!`);
            }
        }

        keys = [...new Set(keys)];
        setIsLoading(true);
        if (Object.keys(keys).length !== 0) {
            await handleOnClickEvent();
        }
        setTimeout(RedisPublish, 3000);
    }

    async function RedisPublish() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/overwite/redis`;
        let rows = !oneShop ? [...rowData.subRows] : [rowData];
        rows.forEach(async (shop: any) => {
            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify({
                            shopId: shop.shopId,
                        }),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            }
                        })
                );
            } catch (e: any) {
                console.log(`${t("There's an error")}!`);
            }
        });
    }
    React.useEffect(() => {
        if (isValidatedClicked) {
            handleSaveButtonOnClickEventDissociationShops();
        }
        // eslint-disable-next-line
    }, [isValidatedClicked]);

    return (
        <React.Fragment>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="primary"
                    onClick={() => {
                        setIsModesModalPublishOpened!(
                            !isModesModalPublishOpened
                        );
                        setIsLoading(false);

                        setKey(Math.floor(Math.random() * 10000));
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    disabled={isDisabled}
                    onClick={() => {
                        if (!isAssociateShops || oneShop) {
                            setIsDisabled(true);
                            handleSaveButtonOnClickEvent();
                        } else {
                            setIsModalOpened(true);
                        }
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
            {isModalOpened ? (
                <ModalAssociateStore
                    setIsModalOpened={setIsModalOpened}
                    isModalOpened={isModalOpened}
                    setIsValidatedClicked={setIsValidatedClicked}
                    rowData={rowData}
                />
            ) : null}
        </React.Fragment>
    );
}
