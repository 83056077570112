import React from "react";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { StyledBreadcrumb, Tabs } from "@aureskonnect/react-ui";
import { getPathAuthorization } from "@components/VerticalLayout";
import { useLocation } from "react-router-dom";

import { store } from "../../../components/VerticalLayout/store";
import { setModify } from "./store";

import PageTitle from "../../../components/Common/PageTitle";
import MessageInfo from "../../../components/Common/MessageInfo";

import HoursFranchise from "./openingHours/HoursFranchise";
import HoursShop from "./openingHours/HoursShop";
import { ExceptionalHours } from "./ExceptionalHours/FranchiseExceptionalHours";
import { ShopExceptionalHours } from "./ExceptionalHours/ShopExceptionalHours";

import { MainColorTabsWrapper } from "@components/Common/MainColorTabsWrapper";

import "./index.css";

export function LocalHours() {
    const location = useLocation();
    setModify(getPathAuthorization(location.pathname).Modifie);
    const { t } = useTranslation();
    const { oneShop } = useSnapshot(store);
    const [
        isArchivedExceptionalHoursClicked,
        setIsArchivedExceptionalHoursClicked,
    ] = React.useState(false);
    const tabs1 = (
        <Tabs
            tabs={[
                {
                    content: <HoursFranchise />,
                    disabled: false,
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark hov_btn_openingTime  "
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Opening time")}
                        </div>
                    ),
                },
                {
                    content: (
                        <ExceptionalHours
                            isArchivedExceptionalHoursClicked={
                                isArchivedExceptionalHoursClicked
                            }
                            setIsArchivedExceptionalHoursClicked={
                                setIsArchivedExceptionalHoursClicked
                            }
                        />
                    ),
                    disabled: false,
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark   hov_btn_exceptionalSchedule"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Exceptional schedule")}
                        </div>
                    ),
                },
            ]}
        />
    );
    const tabs2 = (
        <Tabs
            tabs={[
                {
                    content: <HoursFranchise />,
                    disabled: false,
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark  hov_btn_openingTime "
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Opening time")}
                        </div>
                    ),
                },
                {
                    content: <HoursShop />,
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark border-0  hov_btn_shopOpeningHours "
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Opening time shops")}
                        </div>
                    ),
                },
                {
                    content: (
                        <ExceptionalHours
                            isArchivedExceptionalHoursClicked={
                                isArchivedExceptionalHoursClicked
                            }
                            setIsArchivedExceptionalHoursClicked={
                                setIsArchivedExceptionalHoursClicked
                            }
                        />
                    ),
                    disabled: false,
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark hov_btn_exceptionalSchedule  "
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Exceptional schedule")}
                        </div>
                    ),
                },
                {
                    content: (
                        <ShopExceptionalHours
                            isArchivedExceptionalHoursClicked={
                                isArchivedExceptionalHoursClicked
                            }
                            setIsArchivedExceptionalHoursClicked={
                                setIsArchivedExceptionalHoursClicked
                            }
                        />
                    ),
                    disabled: false,
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark 	hov_btn_shopExceptionalHours  "
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Shop exceptional schedule")}
                        </div>
                    ),
                },
            ]}
        />
    );
    return (
        <React.Fragment>
            <ToastContainer limit={2} />
            <PageTitle title={t("General settings")} />
            <div className="page-content__clz  hov_txt_shopHours">
                <StyledBreadcrumb
                    items={[
                        {
                            item: "Settings".toUpperCase(),
                            link: `${process.env.REACT_APP_PORTAIL_URL}`,
                        },
                        {
                            item:
                                localStorage
                                    .getItem("selectedAccount")
                                    ?.toUpperCase() || "",
                            link: oneShop
                                ? "/setting/customer-account"
                                : "/compte",
                        },
                        {
                            item: t("General settings").toUpperCase(),
                            link: "#",
                        },
                        {
                            item: !isArchivedExceptionalHoursClicked
                                ? t("Hours shop").toUpperCase()
                                : t("Archived hours shop").toUpperCase(),
                            link: "#",
                        },
                    ]}
                />
                <MessageInfo
                    message={t(
                        "The opening and closing hours of the store are configured in a section. It is also possible to declare exceptional working hours such as annual vacation periods."
                    )}
                />
                <MainColorTabsWrapper>
                    {oneShop ? tabs1 : tabs2}
                </MainColorTabsWrapper>
            </div>
        </React.Fragment>
    );
}
export const HoursShops = React.memo(LocalHours);
