import React from "react";
import { PlusIcon } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next/";
import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";
import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { setIsPlusIconClicked } from "./store";


export default function CustomSideFilter({
    isAddCity,
    setIsAddCity,
    setLocalFilterActive,
    setSelectedRows,
    setIsInInitializationModalOpened,
    isEdited,
    selectedRows
}: any) {
    const { t } = useTranslation();

    return (
        <div className="d-flex justify-content-start align-items-center ml-2">
            {isAddCity !== true ? (
                <span
                    onClick={() => {
                        setIsAddCity(!isAddCity);
                        setSelectedRows([]);
                        setLocalFilterActive(false);
                    }}
                    className="w-100 ml-2"
                >
                    <CustomMainColorButtonWithIcon
                        icon="PlusIcon"
                        iconPosition="left"
                        rounded
                        variant="primary"
                    >
                        {t("Add a city")}
                    </CustomMainColorButtonWithIcon>
                </span>
            ) : (
                <CustomMainColorIconButton
                icon="PlusIcon"
                rounded
                disabled={selectedRows.length === 0 ? false : true}
                onClick={() => {
                    if (isEdited === true){
                        setIsInInitializationModalOpened(true);
                       }
                    else {
                        
                        setIsPlusIconClicked(true);
                     
                    }
                }}
            >
                <PlusIcon height={20} width={20} fill="white" />
            </CustomMainColorIconButton>
                    
            )}
        </div>
    );
}
