import React from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";

import {
    designStore,
    generalConfigStore,
    setIsSummaryOrderTakingConfigOptionsActive,
    setOrderTakingSummaryOptionsItems,
    setPageOrderTaking,
    setPageOrderTakingSummary,
} from "@store";

export function Summary(): JSX.Element {
    const { t } = useTranslation();
    const {
        project: { template },
        isHelpActive,
        isPRMActive,
    } = useSnapshot(generalConfigStore);
    // const { isSalesMethodChangeInSummaryActive } = useSnapshot(designStore);
    const [options, setOptions] = React.useState(
        (template.pages.ways[
            "orderTaking"
        ] as OrderTakingPageType).summary.options.items.filter(
            (option) => option.title !== "Usual"
        )
    );

    function handleCheckIconOnClickEvent(index: number) {
        setOptions((prevState: OptionsItemType[]): OptionsItemType[] => {
            let newState = [...prevState];

            newState = newState.map(
                (item: OptionsItemType, itemIndex: number) => {
                    if (itemIndex === index) {
                        return {
                            ...item,
                            active: !item.active,
                        };
                    }

                    return { ...item };
                }
            );
            setPageOrderTakingSummary({
                ...(template.pages.ways["orderTaking"] as OrderTakingPageType)
                    .summary,
                options: {
                    ...(template.pages.ways[
                        "orderTaking"
                    ] as OrderTakingPageType).summary.options,
                    items: newState,
                },
            });

            if (newState.filter((item) => item.active === true).length === 0) {
                setIsSummaryOrderTakingConfigOptionsActive(false);
                setPageOrderTakingSummary({
                    ...(template.pages.ways[
                        "orderTaking"
                    ] as OrderTakingPageType).summary,
                    options: {
                        items: newState,
                        active: false,
                    },
                });
            }

            return newState;
        });
    }

    // eslint-disable-next-line
    const OptionReactSwitch = (option: any, index: number) => {
        let Component = null;
        switch (option.title) {
            case "PRM/Child":
                Component = (
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={option.active}
                        onChange={() => handleCheckIconOnClickEvent(index)}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                        disabled={!isPRMActive}
                    />
                );
                break;

            case "Ask for help":
                Component = (
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={option.active}
                        onChange={() => handleCheckIconOnClickEvent(index)}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                        disabled={!isHelpActive}
                    />
                );
                break;

            default:
                Component = null;
                break;
        }
        return Component;
    };

    React.useEffect(() => {
        if (isPRMActive === false) {
            setOptions((prevState: OptionsItemType[]): OptionsItemType[] => {
                let newState = [...prevState];

                newState = newState.map((item: OptionsItemType) => {
                    if (item.title === "PRM/Child") {
                        return {
                            ...item,
                            active: false,
                        };
                    }

                    return { ...item };
                });
                setOrderTakingSummaryOptionsItems(newState);

                return newState;
            });
        }
        if (isHelpActive === false) {
            setOptions((prevState: OptionsItemType[]): OptionsItemType[] => {
                let newState = [...prevState];

                newState = newState.map((item: OptionsItemType) => {
                    if (item.title === "Ask for help") {
                        return {
                            ...item,
                            active: false,
                        };
                    }

                    return { ...item };
                });
                setOrderTakingSummaryOptionsItems(newState);

                return newState;
            });
        }
    }, [isHelpActive, isPRMActive]);

    return (
        <div
            className="mx-2 m-2 rounded border"
            style={{ borderColor: "#CECECE" }}
        >
            <div
                className="text-left px-4 d-flex align-items-center cursor__clz"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                {t("Summary")}
            </div>

            <div className="p-4" style={{ height: "491px" }}>
                <div className="py-2 d-flex flex-row justify-content-between align-items-center">
                    {t("Show the edit article button")}

                    <ReactSwitch
                        className="ksk_chk_expertTakeOrderFeaturesDetailsBasket"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={
                            (template.pages.ways[
                                "orderTaking"
                            ] as OrderTakingPageType).orderTakingProduct
                                .isProductModificationActive
                        }
                        onChange={() => {
                            setPageOrderTaking({
                                ...(template.pages.ways[
                                    "orderTaking"
                                ] as OrderTakingPageType).orderTakingProduct,
                                isProductModificationActive: !(template.pages
                                    .ways["orderTaking"] as OrderTakingPageType)
                                    .orderTakingProduct
                                    .isProductModificationActive,
                            });
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>

                <div className="py-2 d-flex flex-row justify-content-between align-items-center">
                    {t("Show item details as text")}

                    <ReactSwitch
                        className="ksk_chk_expertTakeOrderFeaturesDetailsBasket"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={
                            (template.pages.ways[
                                "orderTaking"
                            ] as OrderTakingPageType).orderTakingProduct
                                .isArticleDetailsAsTextActive
                        }
                        onChange={() => {
                            setPageOrderTaking({
                                ...(template.pages.ways[
                                    "orderTaking"
                                ] as OrderTakingPageType).orderTakingProduct,
                                isArticleDetailsAsTextActive: !(template.pages
                                    .ways["orderTaking"] as OrderTakingPageType)
                                    .orderTakingProduct
                                    .isArticleDetailsAsTextActive,
                            });
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>

                {options
                    .filter(
                        (item) =>
                            item.title !== "Customer account" &&
                            item.title !== "Allergen"
                    )
                    .map((option, index) => (
                        <div
                            className="py-2 d-flex flex-row justify-content-between align-items-center"
                            key={index}
                        >
                            <div>{t(option.title)}</div>
                            {OptionReactSwitch(option, index)}
                        </div>
                    ))}
            </div>
        </div>
    );
}
