import React from "react";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { useTranslation } from "react-i18next/";
import { mutate } from "swr";
import { CheckboxIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";
import { uuid } from "uuidv4";

import { store, setIsLoading, setKey } from "../store";
import { store as ModeOfSale } from "../../../../components/VerticalLayout/store";

import ErrorToast from "@components/Common/ErrorTost";
import { ConfirmationArchive } from "../ConfirmationArchive";

export function ActionColumnDevise({
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    selectedRows,
    setDataIsUpdated,
    isDeviseArchived,
    setIsDeviseArchived,
    setLimit,
}: any) {
    const { t } = useTranslation();
    const {
        numberActiveMultiShop,
        numberArchiveMultiShop,
        deviceData,
        dataShops,
        modify,
        isLoading,
    } = useSnapshot(store);
    const [error, setError] = React.useState(false);
    const { franchiseID, userID, oneShop, operatorID } = useSnapshot(
        ModeOfSale
    );
    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);
    const uuidUser = localStorage.getItem("uuidUser");

    const getArrayOfRows = () => {
        let arrayOfRows: any[] = [];
        selectedRows.forEach(async (el: any) => {
            if (el[t("Shop")] === undefined) {
                arrayOfRows.push(el);
            }
        });
        return arrayOfRows;
    };

    let arrayOfRows = getArrayOfRows();
    let dataArchive: any[] = [];
    const getIsArrayOfRowsShop = () => {
        let isArrayShop: boolean = false;
        selectedRows.forEach(async (el: any) => {
            if (Object.values(el).includes(el.id)) {
                isArrayShop = true;
            }
        });
        return isArrayShop;
    };
    let isArrayShop = getIsArrayOfRowsShop();

    if (!oneShop) {
        dataArchive = archiveDevise();
    }
    function archiveDevise() {
        let idsAssociated: any = [];
        if (deviceData !== undefined) {
            deviceData.forEach((element: any) => {
                idsAssociated.push(element.id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function shopsIdArchiveDevise(e: any) {
        let idsAssociated: any = [];
        let shopSelectedRows: any[] = [];
        selectedRows.forEach((el: any) => {
            if (el.idShop === e.idShop) {
                shopSelectedRows.push(el.idShop);
            }
        });

        if (dataShops !== undefined) {
            dataShops.forEach((element: any) => {
                if (
                    element.subRows !== undefined &&
                    element.subRows.length === 0 &&
                    ((element.archivedDevices !== undefined &&
                        element.archivedDevices.length === 1) ||
                        (selectedRows.subRows === undefined &&
                            shopSelectedRows.length === 1))
                ) {
                    element.archivedDevices.forEach((el: any) => {
                        if (el.id === e.id && el.idShop === e.idShop)
                            idsAssociated.push(el.idShop);
                    });
                }
            });
        }

        idsAssociated = [...new Set(idsAssociated)];

        return idsAssociated;
    }
    async function activeDevise() {
        let isNotEmpty: boolean = false;
        let isEmpty: boolean = false;
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/devise`;
        toast.dismiss();
        let localData: any = [];
        selectedRows.forEach(async (el: any) => {
            let shopsIdArchived = shopsIdArchiveDevise(el);
            if (el.Boutique === undefined) {
                let data: any;

                data = {
                    [el.id]: {
                        Nom_d_Affichage: el[t("Display name")],
                        tag: el[t("Tag")],
                        symbole: el[t("Symbol")],
                        Nbr_décimale: el[t("Nbr decimal")],
                        designation_décimale: el[t("Decimal designation")],
                        devise: el[t("Currency")],
                        pays: el[t("Country")],
                        isActive: false,
                        isArchived: false,
                    },
                };
                let dataDevise = {
                    userId: userID,
                    shopId: shopsIdArchived,
                    franchiseId: franchiseID,
                    data: data,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                };
                if (dataDevise.shopId.length !== 0) {
                    isNotEmpty = true;
                } else {
                    isEmpty = true;
                }
                localData.push(
                    fetch(apiUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(dataDevise),
                    }).then((response) => response.json())
                );
            }
        });

        try {
            mutate(apiUrl, await Promise.all(localData).then((result) => {}));
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setError(true);
            setIsLoading(false);
        }

        if (error === false && isNotEmpty) {
            toast.success(`${t("The Currency was successfully unarchived")!}`, {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
                icon: <CheckboxIcon height={25} width={25} fill="white" />,
            });
            setIsLoading(false);
            setKey(uuid());
        }
        if (isEmpty) {
            ErrorToast(
                t("Attention,a shop cannot have more than one currency")
            );
            setIsLoading(false);
            setDataIsUpdated!(true);
        }
        setLimit(2);
    }

    return (
        <React.Fragment>
            <div>
                <div className="dropdown">
                    <VerticalDotsIcon
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        style={{ cursor: "pointer" }}
                        height={25}
                        width={25}
                        fill="black"
                        className="cmn_drp_BTmenu"
                    />
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <div
                            className="dropdown-item pl-1 cmn_btn_BTlistActivated"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setIsDeviseArchived(false);
                                setDataIsUpdated(true);
                            }}
                        >
                            {t("currency activated")} ({numberActiveMultiShop})
                        </div>
                        <div
                            className="dropdown-item pl-1 cmn_btn_BTlistArchived"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setIsDeviseArchived(true);
                                setDataIsUpdated(true);
                            }}
                        >
                            {t("currency Archived")}({numberArchiveMultiShop})
                        </div>
                        <div
                            style={{ cursor: "pointer" }}
                            className={`${classnames(
                                " dropdown-item pl-1 pointer__clz cmn_btn_BThistory",
                                {
                                    "not-allowed-icon__clz":
                                        selectedRows.length === 0 ||
                                        arrayOfRows.length === 0 ||
                                        !isArrayShop,
                                }
                            )}`}
                            onClick={() => {
                                if (arrayOfRows.length < 2) {
                                    ErrorToast(
                                        `${t(
                                            "Attention, it is a multiple action. Please select at least two items"
                                        )!}`
                                    );
                                } else {
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                                }
                            }}
                        >
                            {t("Historical")}
                        </div>
                        {modify === true ? (
                            <>
                                <div
                                    style={{ cursor: "pointer" }}
                                    className={`${classnames(
                                        " dropdown-item pl-1 pointer__clz cmn_btn_BTunarchive cmn_btn_BTarchived",
                                        {
                                            "not-allowed-icon__clz":
                                                isLoading ||
                                                selectedRows.length === 0 ||
                                                arrayOfRows.length === 0 ||
                                                (arrayOfRows.some(
                                                    (element: any) =>
                                                        dataArchive.includes(
                                                            element.id
                                                        )
                                                ) &&
                                                    isDeviseArchived) ||
                                                !isArrayShop ||
                                                arrayOfRows.length === 1,
                                        }
                                    )}`}
                                    onClick={() => {
                                        setIsLoading(true);
                                        if (arrayOfRows.length < 2) {
                                            ErrorToast(
                                                `${t(
                                                    "Attention, it is a multiple action. Please select at least two items"
                                                )!}`
                                            );
                                            setIsLoading(false);
                                        } else if (isDeviseArchived) {
                                            activeDevise();
                                        } else {
                                            setIsLoading(false);
                                            setIsArchiveModalOpened(
                                                !isArchiveModalOpened
                                            );
                                        }
                                    }}
                                >
                                    {isDeviseArchived
                                        ? `${t("Dearchive")}`
                                        : `${t("Archive")}`}
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
            {isArchiveModalOpened ? (
                <ConfirmationArchive
                    isModalOpened={isArchiveModalOpened}
                    setIsModalOpened={setIsArchiveModalOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                />
            ) : null}
        </React.Fragment>
    );
}
