import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";
import translationES from "./locales/es/translation.json";
import translationIT from "./locales/it/translation.json";
import translationNL from "./locales/nl/translation.json";
import translationPT from "./locales/pt/translation.json";

// the translations
const resources = {
    en: {
        translation: translationEN,
    },
    fr: {
        translation: translationFR,
    },
    es: {
        translation: translationES,
    },
    it: {
        translation: translationIT
    },
    nl: {
        translation: translationNL
    },
    pt: {
        translation: translationPT
    }
};
const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
i18n.use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: i18nextLng,
        fallbackLng: "en", // use en if detected lng is not available

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
