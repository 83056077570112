import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledLabel,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";

import { store } from "@components/VerticalLayout/store";

type ConfirmationInitializationMessageType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    numberClick: number;
    setNumberClick: Function;
    isLoading: boolean;
    setIsLoading: Function;
};

export function ModalDefaultSetting({
    isModalOpened,
    setIsModalOpened,
    numberClick,
    setNumberClick,
    isLoading,
    setIsLoading,
}: ConfirmationInitializationMessageType) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID, isShopUser } = useSnapshot(store);
    // eslint-disable-next-line
    const [shopsList, setShopsList] = React.useState<any>([]);
    // eslint-disable-next-line
    const [countryList, setCountryList] = React.useState<any>([]);
    // eslint-disable-next-line
    const [shopCountryList, setShopCountryList] = React.useState<any>([]);

    const uuidUser = localStorage.getItem("uuidUser");

    const dataFetch = async () => {
        let list: any[] = [];
        let listPays: any[] = [];
        let listOfCountry: any = [];
        const data = await (
            await fetch(
                ` ${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod?userId=${userID}&franchiseId=${franchiseID}&type=activated`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                        "Content-Language": "en-US",
                    },

                    method: "GET",
                }
            )
        ).json();

        for (let el of data.data) {
            list.push(el.idShop);
        }

        for (let el of data.othersData.countryShop) {
            listPays.push(el.pays);
        }

        if (isShopUser) {
            for (let el of data.othersData.shopList) {
                list.push(el.uid);
            }

            list = [...new Set(list)];
        } else {
            list = [...new Set(list)];
        }
        listPays = [...new Set(listPays)];
        let listOfId: any[] = [];
        setShopsList(list);
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/pays`;
        let idsShopsCountry: any = [];
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },

                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error!");
                        }

                        idsShopsCountry = data?.data
                            ?.filter((x: any) => {
                                return (
                                    listPays.indexOf(x["frenchDesignation"]) !==
                                    -1
                                );
                            })
                            ?.map((x: any) => x.Id);

                        data.data.forEach((element: any) => {
                            listOfId.push({
                                label: element["Designation"],
                                value: element.Id,
                            });
                        });
                        listPays.forEach((element: any) => {
                            for (let elt of listOfId) {
                                if (element === elt.label) {
                                    listOfCountry.push(elt.value);
                                }
                            }
                        });
                    })
            );
        } catch (e: any) {}

        listOfCountry = [...new Set(listOfCountry)];
        setCountryList(listOfCountry);

        setShopCountryList(idsShopsCountry);
    };

    React.useEffect(() => {
        dataFetch();
        //eslint-disable-next-line
    }, []);

    async function handleValidateButtonOnClickEvent() {
        setIsModalOpened(!isModalOpened);
        setIsLoading(true);

        let dataSaleSupport;
        dataSaleSupport = {
            userId: userID,
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
        };
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataSaleSupport),
        };
        let apiUrlAdd = ` ${process.env.REACT_APP_API_V2_URL}/settings/default`;

        try {
            mutate(
                apiUrlAdd,
                fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        setIsLoading(false);

                        toast.success(
                            `${t(
                                "The default settings have been applied successfully."
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <StyledModal
            toggle={true}
            isOpen={numberClick === 0 && isModalOpened}
            centered
            size="l"
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                    setNumberClick(1);
                }}
            >
                <StyledH2 className="text-uppercase">
                    {t("Default setting")}
                </StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {t("Do you want to apply the default setting?")}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                        setNumberClick(1);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={async () => {
                        handleValidateButtonOnClickEvent();
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
