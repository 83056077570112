import React from "react";

export default function RefreshPosIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={48}
            height={48}
            viewBox="0 0 48 48"
            {...props}
        >
            <g id="Groupe_18388" data-name="Groupe 18388">
                <path
                    id="Tracé_17789"
                    data-name="Tracé 17789"
                    d="M5.154,122.99h18.69a5.15,5.15,0,0,0,3.627-1.487,5.03,5.03,0,0,0,0-7.219,5.156,5.156,0,0,0-2.778-1.418q0-.034,0-.069a6.8,6.8,0,0,0-6.8-6.8,6.728,6.728,0,0,0-3.862,1.21,6.807,6.807,0,0,0-1.959,2.1,5.1,5.1,0,0,0-7.711,3.548,5.154,5.154,0,0,0-2.839,1.428,5.03,5.03,0,0,0,0,7.219A5.15,5.15,0,0,0,5.154,122.99Zm18.69-8.5a3.4,3.4,0,1,1,0,6.8H5.154a3.4,3.4,0,1,1,0-6.8H6v-.85a3.395,3.395,0,0,1,5.777-2.422l.914.9.471-1.192a5.09,5.09,0,0,1,9.829,1.867,4.6,4.6,0,0,1-.065.644l-.152,1.055Z"
                    transform="translate(0 -106)"
                    fill="#2b2828"
                />
            </g>
            <path
                id="Tracé_20344"
                data-name="Tracé 20344"
                d="M157.906,205.806l.72.72H151v1.441h11.1l-3.18-3.18Z"
                transform="translate(-141.545 -198.211)"
                fill="#2b2828"
            />
            <path
                id="Tracé_20345"
                data-name="Tracé 20345"
                d="M132.967,304.18l1.019-1.019-.72-.72h7.626V301h-11.1Z"
                transform="translate(-121.35 -289.801)"
                fill="#2b2828"
            />
        </svg>
    );
}
