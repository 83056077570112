import React from "react";
import classNames from "classnames";
import { useSnapshot } from "valtio";

import { designStore, generalConfigStore } from "@store";

import { getImageContentById, getTopBannerImagePath } from "@helpers/general";
import { PreviewTopBannerBackgroundWrapper } from "@components/Common/Design/Preview/PreviewTopBannerBackgroundWrapper";

type TopBannerWrapperType = {
    isLogoActive: boolean;
};

export function TopBannerWrapper({
    isLogoActive,
}: TopBannerWrapperType): JSX.Element {
    const {
        activeTabIndex,
        activePageName,
        activeTabPrinterOptionsSetupOptions,
    } = useSnapshot(designStore);
    const {
        project: { template },
        files,
    } = useSnapshot(generalConfigStore);

    let pageId: string;
    if (activeTabPrinterOptionsSetupOptions !== 2) {
        pageId = (template.pages.ways[activePageName] as PageType)!.topBanner
            .id as string;
    } else {
        pageId = (template.pages.subSteps[
            "printerOptionsConfirmationEmail"
        ] as PageType)!.topBanner.id as string;
    }

    const logoImage = getImageContentById(
        (template.content.generalDesign as GeneralDesignType).logo.id as string,
        files
    );

    const logoImageSrc =
        logoImage?.includes("https") || logoImage?.includes("http")
            ? logoImage
            : `./images/${logoImage}`;

    const topBannerBackgroundImageSrc = getTopBannerImagePath(pageId, files);

    return (
        <div
            className={classNames("pt-2 d-flex justify-content-center", {
                "border-wrapper-banner__clz": activeTabIndex === 0,
            })}
        >
            <PreviewTopBannerBackgroundWrapper
                subStep={
                    activeTabPrinterOptionsSetupOptions === 2 ? 2 : undefined
                }
            >
                {isLogoActive ? (
                    <img
                        src={logoImageSrc}
                        style={{
                            height: "85px",
                            width: "85px",
                            position:
                                topBannerBackgroundImageSrc !== undefined &&
                                topBannerBackgroundImageSrc.type !==
                                    undefined &&
                                topBannerBackgroundImageSrc.type === "video"
                                    ? "fixed"
                                    : "unset",
                            zIndex: 1,
                        }}
                        alt="Logo"
                    />
                ) : null}
            </PreviewTopBannerBackgroundWrapper>
        </div>
    );
}
