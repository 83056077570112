import React from "react";
import { useSnapshot } from "valtio";
import classNames from "classnames";

import { designStore, generalConfigStore } from "@store";

import { truncateString } from "@helpers/general";
import {
    EMAIL_CONFIRMATION_PRINTER_OPTIONS_TAB,
    VERIFICATION_EMAIL_PRINTER_OPTIONS_TAB,
    WITH_EMAIL_PRINTER_OPTIONS_TAB,
} from "@constants/index";

export function HeaderWrapper(): JSX.Element {
    const {
        selectedLanguage,
        activeTabPrinterOptions,
        activeTabPrinterOptionsSetupOptions,
        activeTabPrinterOptionsConfirmationEmail,
    } = useSnapshot(designStore);

    const { project } = useSnapshot(generalConfigStore);

    return (
        <div
            className={classNames(
                "d-flex flex-column justify-content-center align-items-center"
            )}
            style={{ width: "450px" }}
        >
            {activeTabPrinterOptionsSetupOptions !==
            EMAIL_CONFIRMATION_PRINTER_OPTIONS_TAB ? (
                activeTabPrinterOptions === WITH_EMAIL_PRINTER_OPTIONS_TAB ? (
                    (project.template.pages.ways[
                        "printerOptions"
                    ] as PrinterOptionsPageType).header.informationMessage
                        .withemail.active ? (
                        <div
                            style={{
                                fontSize: "18px",
                                width: "252px",
                                textAlign: "center",
                                font: "normal normal 600 22px/28px Segoe UI",
                                color: "#343434",
                            }}
                        >
                            {truncateString(
                                (project.template.pages.ways[
                                    "printerOptions"
                                ] as PrinterOptionsPageType).header
                                    .informationMessage.withemail.languages[
                                    selectedLanguage
                                ].content,
                                80
                            )}
                        </div>
                    ) : null
                ) : (project.template.pages.ways[
                      "printerOptions"
                  ] as PrinterOptionsPageType).header.informationMessage
                      .withoutemail.active ? (
                    <div
                        style={{
                            fontSize: "18px",
                            width: "252px",
                            textAlign: "center",
                            font: "normal normal 600 22px/28px Segoe UI",
                            color: "#343434",
                        }}
                    >
                        {truncateString(
                            (project.template.pages.ways[
                                "printerOptions"
                            ] as PrinterOptionsPageType).header
                                .informationMessage.withoutemail.languages[
                                selectedLanguage
                            ].content,
                            80
                        )}
                    </div>
                ) : null
            ) : activeTabPrinterOptionsConfirmationEmail ===
              VERIFICATION_EMAIL_PRINTER_OPTIONS_TAB ? (
                ((project.template.pages.subSteps[
                    "printerOptionsConfirmationEmail"
                ] as unknown) as PrinterOptionsConfirmationEmailSubStepType)
                    .header.informationMessage.confirmationemail.active ? (
                    <div
                        style={{
                            fontSize: "18px",
                            width: "252px",
                            textAlign: "center",
                            font: "normal normal 600 22px/28px Segoe UI",
                            color: "#343434",
                        }}
                    >
                        {truncateString(
                            ((project.template.pages.subSteps[
                                "printerOptionsConfirmationEmail"
                            ] as unknown) as PrinterOptionsConfirmationEmailSubStepType)
                                .header.informationMessage.confirmationemail
                                .languages[selectedLanguage].content,
                            80
                        )}
                    </div>
                ) : null
            ) : ((project.template.pages.subSteps[
                  "printerOptionsConfirmationEmail"
              ] as unknown) as PrinterOptionsConfirmationEmailSubStepType)
                  .header.informationMessage.entryemail.active ? (
                <div
                    style={{
                        fontSize: "18px",
                        width: "252px",
                        textAlign: "center",
                        font: "normal normal 600 22px/28px Segoe UI",
                        color: "#343434",
                    }}
                >
                    {truncateString(
                        ((project.template.pages.subSteps[
                            "printerOptionsConfirmationEmail"
                        ] as unknown) as PrinterOptionsConfirmationEmailSubStepType)
                            .header.informationMessage.entryemail.languages[
                            selectedLanguage
                        ].content,
                        80
                    )}
                </div>
            ) : null}
        </div>
    );
}
