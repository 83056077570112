import React from "react";
import { CrossIcon } from "@aureskonnect/react-ui";
import classNames from "classnames";
import { useSnapshot } from "valtio";

import { store } from "@store";

import { MessageSvgIcon } from "@components/SvgIcons";

import "./index.css";

type MessageInfoType = {
    message: string;
};

export default function MessageInfo({ message }: MessageInfoType) {
    const [isMessageClosed, setIsMessageClosed] = React.useState<boolean>(
        false
    );
    const { mainColor } = useSnapshot(store);
    return (
        <div
            className={classNames(
                "align-items-center border bg-white m-2 rounded",
                {
                    "d-none": isMessageClosed,
                    "d-flex": !isMessageClosed,
                }
            )}
        >
            <div className="flex-grow-1">
                <div className="d-flex align-items-center">
                    <div
                        className="p-2 col-icon-notification__clz"
                        style={{ backgroundColor: mainColor }}
                    >
                        <MessageSvgIcon height={50} width={50} fill="white" />
                    </div>
                    <div className="notification-text__clz pl-4 cmn_txt_notification">
                        {message}
                    </div>
                </div>
            </div>
            <div
                className="d-flex align-items-center justify-content-center mr-3"
                style={{ cursor: "pointer" }}
            >
                <CrossIcon
                    className="icon-close-notification__clz cmn_icn_closeNotification"
                    height={13}
                    width={13}
                    onClick={() => setIsMessageClosed(!isMessageClosed)}
                />
            </div>
        </div>
    );
}
