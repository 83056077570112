import React from "react";
import { useTranslation } from "react-i18next";
import { CheckboxIcon, StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useSnapshot } from "valtio";
import { mutate } from "swr";
import { toast } from "react-toastify";

import {
    store,
    setIsModalConfirmationConfigurationKiosk,
    setIsLoading,
} from "../../store/project/index";
import {
    generalConfigStore,
    setChosenTemplate,
    setInitItemsFromGeneralSetting,
    setProject,
} from "@store";
import { store as userStore } from "@components/VerticalLayout/store";
import kioskSettingStore from "@pages/GeneralConfig/store";
import { setKey } from "./store";

import { capitalize, moveArrayElementToTheBegin } from "../../helpers/general";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

type PropsType = {
    setIsDataUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    isDataUpdated: boolean | number;
};

export default function ModalConfirmationConfigurationKiosk({
    setIsDataUpdated,
    isDataUpdated,
}: PropsType) {
    const { t } = useTranslation();
    const { isModalConfirmationConfigurationKiosk, kioskProject } = useSnapshot(
        store
    );

    const { project, itemsDisable } = useSnapshot(generalConfigStore);
    const {
        userID,
        projectId,
        franchiseID,
        oneShop,
        operatorID,
        shopID,
    } = useSnapshot(userStore);
    const { projectRowData } = useSnapshot(kioskSettingStore);
    const uuidUser = localStorage.getItem("uuidUser");

    const getProjectData = async (shopId: string) => {
        let dataProject = {};
        let apiUrl = `${
            process.env.REACT_APP_API_V2_URL
        }/settings/projectData?userId=106&shopId=${shopId}&franchiseId=${franchiseID}&projectId=${JSON.parse(
            JSON.stringify(project.projectId)
        )}`;
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        dataProject = data[0];
                    })
            );
            return dataProject;
        } catch (e: any) {}
    };

    async function handleUpdateProjectShopsButtonOnClickEvent(
        element: any,
        isError?: boolean
    ) {
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/template`;

        let { shopProject, warn } = await getDataAfterModificationInStatusPlus(
            element
        );

        let template = {
            ...project.template,
            content: {
                ...project.template.content,
                languages: {
                    ...JSON.parse(
                        JSON.stringify(project.template.content.languages)
                    ),
                    // eslint-disable-next-line no-loop-func
                    items: (shopProject.template.content
                        .languages as ProjectContentItemType).items,
                },
                salesMethods: {
                    // eslint-disable-next-line no-loop-func
                    items: (shopProject.template.content
                        .salesMethods as ProjectContentItemType).items,
                },
                meansOfPayment: {
                    // eslint-disable-next-line no-loop-func
                    items: (shopProject.template.content
                        .meansOfPayment as ProjectContentItemType).items,
                },
                informationModes: {
                    // eslint-disable-next-line no-loop-func
                    items: (shopProject.template.content
                        .informationModes as ProjectContentItemType).items,
                },
                generalDesign: {
                    ...kioskProject.template.content.generalDesign,
                    logo: (shopProject.template.content
                        .generalDesign as GeneralDesignType).logo,
                },
            },
        };

        let dataShop = {
            userId: userID,
            franchiseId: franchiseID,
            project_id: "PROJECT",
            projectId: projectId,
            shopId: element,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data: {
                template: template,
                files: project.files,
            },
        };

        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(dataShop),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                    })
            );
            return warn;
        } catch (e: any) {
            if (isError !== undefined) isError = true;
            toast.error(`${t("There's an error")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function OverwriteTemplateOneShop() {
        let apiUrlOverwiteTemplate = `${process.env.REACT_APP_API_V2_URL}/settings/application/overwite/template`;
        if (projectRowData.subRows.length !== 0) {
            let localData: any = [];
            projectRowData.subRows.forEach(async (element: any) => {
                let savedData = {
                    userId: userID,
                    franchiseId: franchiseID,
                    shopId: projectRowData.idShop,
                    appId: "KIOSK",
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    appId_children: element.appId,
                    templateKey: projectRowData.projectId,
                };
                localData.push(
                    fetch(apiUrlOverwiteTemplate, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify(savedData),
                    }).then((response) => response.json())
                );
            });
            try {
                mutate(
                    apiUrlOverwiteTemplate,
                    await Promise.all(localData).then(async (result: any) => {
                        let errors: boolean[] = result.map(
                            (el: any) => el.error
                        );
                        if (!errors.includes(true)) {
                            toast.success(
                                `${t("Template saved successfully")}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            if (projectRowData.Status === "Publié")
                                setTimeout(() => {
                                    RedisPublish();
                                }, 3000);
                        }
                    })
                );
                setIsModalConfirmationConfigurationKiosk(false);
                setIsLoading(false);
                setTimeout(function () {
                    setKey(Math.floor(Math.random() * 10000));
                }, 2000);
            } catch (e: any) {
                console.log(`${t("There's an error")}!`);
            }
        }
    }
    async function RedisPublish() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/overwite/redis`;

        if (projectRowData.subRows.length !== 0) {
            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify({
                            shopId: JSON.parse(JSON.stringify(projectRowData))
                                .shopId,
                        }),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            }
                        })
                );
            } catch (e: any) {
                console.log(`${t("There's an error")}!`);
            }
        }
    }
    async function OverwriteTemplate() {
        setIsLoading(true);
        setIsModalConfirmationConfigurationKiosk(
            !isModalConfirmationConfigurationKiosk
        );

        await updateTemplate();
    }
    async function updateTemplate() {
        let warning: any = false;
        let isError = false;
        let apiUrlOverwiteTemplate = `${process.env.REACT_APP_API_V2_URL}/settings/application/overwite/template`;
        let localData: any = [];

        if (projectRowData.shopId !== 0 && projectRowData.shopId !== "0") {
            for (let element of projectRowData.subRows) {
                let template: any = "";
                let apiUrl = `${
                    process.env.REACT_APP_API_V2_URL
                }/settings/applicationTemplate?userId=106&shopId=${
                    oneShop ? shopID : element.shopId
                }&franchiseId=${franchiseID}&projectId=${
                    element.id_project
                }&applicationId=${element.appId}`;
                try {
                    mutate(
                        apiUrl,
                        await fetch(apiUrl, {
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bareer ${localStorage.getItem(
                                    "jwt"
                                )}`,
                            },
                            method: "GET",
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                template = data[0].template;
                            })
                    );
                } catch (e: any) {
                    isError = true;
                }
                if (template !== "") {
                    let savedData = {
                        userId: userID,
                        operatorId: operatorID,
                        uuidUser: uuidUser,
                        franchiseId: franchiseID,
                        shopId: projectRowData.id_boutique,
                        appId: "KIOSK",
                        appId_children: element.appId,
                        templateKey: projectRowData.id_project,
                    };
                    localData.push(
                        fetch(apiUrlOverwiteTemplate, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bareer ${localStorage.getItem(
                                    "jwt"
                                )}`,
                            },
                            body: JSON.stringify(savedData),
                        }).then((response) => response.json())
                    );
                }
            }
            try {
                mutate(
                    apiUrlOverwiteTemplate,
                    await Promise.all(localData).then(async (result: any) => {
                        let errors: boolean[] = result.map(
                            (el: any) => el.error
                        );
                        if (errors.includes(true)) {
                            console.log("there is an error");
                        }
                    })
                );
            } catch (e: any) {
                isError = true;
                console.log(`${t("There's an error")}!`);
            }
        } else {
            for (let element of JSON.parse(JSON.stringify(projectRowData))
                ?.subRows) {
                if (element.subRows.length !== 0) {
                    for (let el of element?.subRows) {
                        let savedData = {
                            userId: userID,
                            franchiseId: franchiseID,
                            shopId: element.id_boutique,
                            operatorId: operatorID,
                            uuidUser: uuidUser,
                            appId: "KIOSK",
                            appId_children: el.appId,
                            templateKey: el.id_project,
                        };
                        localData.push(
                            fetch(apiUrlOverwiteTemplate, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    authorization: `Bareer ${localStorage.getItem(
                                        "jwt"
                                    )}`,
                                },
                                body: JSON.stringify(savedData),
                            }).then((response) => response.json())
                        );
                    }
                    try {
                        mutate(
                            apiUrlOverwiteTemplate,
                            await Promise.all(localData).then(
                                // eslint-disable-next-line no-loop-func
                                async (result: any) => {
                                    let errors: boolean[] = result.map(
                                        (el: any) => el.error
                                    );
                                    if (!errors.includes(true)) {
                                        warning = await handleUpdateProjectShopsButtonOnClickEvent(
                                            element.id_boutique
                                        );
                                    }
                                }
                            )
                        );
                    } catch (e: any) {
                        isError = true;
                        console.log(`${t("There's an error")}!`);
                    }
                } else {
                    warning = await handleUpdateProjectShopsButtonOnClickEvent(
                        element.id_boutique,
                        isError
                    );
                }
            }
        }

        if (warning === true) {
            toast.warning(
                `${t(
                    "Please note, the modification could not be carried out successfully for one or more stores. Please check the Language, Sales method, and/or payment method configuration."
                )}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
        }

        toast.success(`${t("Template saved successfully")}`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            theme: "colored",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            icon: <CheckboxIcon height={25} width={25} fill="white" />,
        });
        setIsLoading(false);
        setKey(Math.floor(Math.random() * 10000));
        if (projectRowData.Status === "Publié")
            setTimeout(() => {
                RedisPublish();
            }, 3000);
    }

    async function getDataAfterModificationInStatusPlus(shopId: string) {
        let template: any;
        const shopData: any = await getProjectData(shopId);
        let infoSaleMethod = false;
        let infoLanguage = false;
        let infoReglement = false;
        if (
            JSON.parse(
                JSON.stringify(
                    (kioskProject.template.content
                        .salesMethods as ProjectContentItemType).items.filter(
                        (a) =>
                            (shopData.template.content.salesMethods
                                .items as ProjectMainContentItemType[]).some(
                                (b) =>
                                    a.id === b.id &&
                                    itemsDisable.find(
                                        (item: any) => item.id === b.id
                                    ) === undefined
                            )
                    )
                )
            ).filter((el: any) => el.active === true).length > 0
        ) {
            JSON.parse(
                JSON.stringify(kioskProject.template.content.salesMethods)
            ).items.forEach((element: any) => {
                shopData.template.content.salesMethods.items.forEach(
                    (el: any) => {
                        if (el.id === element.id) {
                            if (
                                itemsDisable.find(
                                    (item: any) => item.id === el.id
                                ) === undefined &&
                                el.active !== element.active
                            ) {
                                el.active = element.active;
                                el.languages = element.languages;
                            } else {
                                el.languages = element.languages;
                                infoSaleMethod = true;
                            }
                        }
                    }
                );
            });
        }

        if (
            JSON.parse(
                JSON.stringify(
                    kioskProject.template.content.languages.items.filter(
                        (a: any) =>
                            shopData.template.content.languages.items.some(
                                (b: any) =>
                                    a.id === b.id &&
                                    itemsDisable.find(
                                        (item: any) => item.id === b.id
                                    ) === undefined
                            )
                    )
                )
            ).filter((el: any) => el.active === true).length > 0
        ) {
            JSON.parse(
                JSON.stringify(kioskProject.template.content.languages)
            ).items.forEach((element: any) => {
                shopData.template.content.languages.items.forEach((el: any) => {
                    if (el.id === element.id) {
                        if (
                            itemsDisable.find(
                                (item: any) => item.id === el.id
                            ) === undefined &&
                            el.active !== element.active
                        ) {
                            el.active = element.active;
                        } else {
                            infoLanguage = true;
                        }
                    }
                });
            });
        }

        JSON.parse(
            JSON.stringify(kioskProject.template.content.informationModes)
        ).items.forEach((element: any) => {
            shopData.template.content.informationModes.items.forEach(
                (el: any) => {
                    if (el.id === element.id) {
                        if (
                            itemsDisable.find(
                                (item: any) => item.id === el.id
                            ) === undefined &&
                            el.active !== element.active
                        ) {
                            el.active = element.active;
                            el.languages = element.languages;
                        } else {
                            el.languages = element.languages;
                        }
                    }
                }
            );
        });

        if (
            JSON.parse(
                JSON.stringify(
                    (kioskProject.template.content
                        .meansOfPayment as ProjectContentItemType).items.filter(
                        (a) =>
                            (shopData.template.content
                                .meansOfPayment as ProjectContentItemType).items.some(
                                (b) =>
                                    a.id === b.id &&
                                    itemsDisable.find(
                                        (item: any) => item.id === b.id
                                    ) === undefined
                            )
                    )
                )
            ).filter((el: any) => el.active === true).length > 0
        ) {
            JSON.parse(
                JSON.stringify(kioskProject.template.content.meansOfPayment)
            ).items.forEach((element: any) => {
                (shopData.template.content
                    .meansOfPayment as ProjectContentItemType).items.forEach(
                    (el: any) => {
                        if (el.id === element.id) {
                            if (
                                itemsDisable.find(
                                    (item: any) => item.id === el.id
                                ) === undefined &&
                                el.active !== element.active
                            ) {
                                el.active = element.active;
                                el.languages = element.languages;
                            } else {
                                el.languages = element.languages;
                                infoReglement = true;
                            }
                        }
                    }
                );
            });
        }

        template = {
            ...kioskProject.template,
            content: {
                ...kioskProject.template.content,
                languages: {
                    ...kioskProject.template.content.languages,
                    items:
                        shopData.template.content.languages.items.filter(
                            (language: LanguagesItemType) =>
                                language.active === true &&
                                language.isDefault === false
                        ).length > 1
                            ? moveArrayElementToTheBegin(
                                  shopData.template.content.languages.items.find(
                                      (language: LanguagesItemType) =>
                                          language.isDefault === true
                                  ),
                                  moveArrayElementToTheBegin(
                                      shopData.template.content.languages.items.find(
                                          (language: LanguagesItemType) =>
                                              language.active === true &&
                                              language.isDefault === false
                                      ),
                                      shopData.template.content.languages.items
                                  )
                              )
                            : shopData.template.content.languages.items,
                },
                salesMethods: {
                    items: shopData.template.content.salesMethods.items,
                },
                meansOfPayment: {
                    items: shopData.template.content.meansOfPayment.items,
                },
                informationModes: {
                    items: shopData.template.content.informationModes.items,
                },
            },
        };

        let files = kioskProject.files;
        setProject({
            ...kioskProject,
            template,
            files,
        });
        setInitItemsFromGeneralSetting({
            informationModes: shopData.template.content.informationModes.items,
            saleMethods: shopData.template.content.salesMethods.items,
            reglements: shopData.template.content.meansOfPayment.items,
            languages: shopData.template.content.languages.items,
            logo: shopData.template.content.generalDesign.logo,
        });

        setChosenTemplate(template, files);
        let shopProject = {
            ...kioskProject,
            template,
        };

        let warn = false;
        if (!infoReglement || !infoLanguage || !infoSaleMethod) {
            warn = true;
        }
        return { shopProject, warn };
    }
    return (
        <React.Fragment>
            <Modal
                isOpen={isModalConfirmationConfigurationKiosk}
                style={{
                    maxWidth: "650px",
                    maxHeight: "500px",
                    marginTop: "300px",
                }}
            >
                <ModalHeader
                    toggle={() => {
                        setIsModalConfirmationConfigurationKiosk(
                            !isModalConfirmationConfigurationKiosk
                        );
                        setKey(Math.floor(Math.random() * 10000));
                    }}
                >
                    <StyledH2>{capitalize(t("Alert"))}</StyledH2>
                </ModalHeader>
                <ModalBody>
                    <StyledLabel
                        className="pl-3 m-0"
                        style={{
                            font: "normal normal normal 20px/27px Segoe UI",
                            whiteSpace: "pre-line",
                            opacity: "1",
                            textAlign: "left",
                            color: "#000000",
                        }}
                    >
                        {(projectRowData.shopId === 0 ||
                            projectRowData.shopId === "0") &&
                        !oneShop
                            ? t(
                                  "Attention, if you click on validate, the specific configuration of the shops will be overwritten."
                              )
                            : t(
                                  "Be careful, if you click on validate, the specific configuration of the applications will be overwritten."
                              )}
                        !
                    </StyledLabel>
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <CustomMainColorButton
                        outline
                        variant="primary"
                        onClick={() => {
                            setIsModalConfirmationConfigurationKiosk(
                                !isModalConfirmationConfigurationKiosk
                            );
                            if (projectRowData.Status === "Publié")
                                setTimeout(() => {
                                    RedisPublish();
                                }, 3000);
                            setKey(Math.floor(Math.random() * 10000));
                        }}
                        rounded
                    >
                        {t("Cancel")}
                    </CustomMainColorButton>
                    <CustomMainColorButton
                        rounded
                        className="mr-2"
                        variant="primary"
                        onClick={() => {
                            if (oneShop) {
                                OverwriteTemplateOneShop();
                            } else {
                                OverwriteTemplate();
                            }
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
