import React, { useEffect } from "react";
import { StyledH2, CheckboxIcon } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import ReactSwitch from "react-switch";

import { capitalize } from "@helpers/general";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "react-toastify/dist/ReactToastify.css";

type PeripheralsModalConfigurationsType = {
    setIsMoneticsPathsModalOpened: Function;
    isMoneticsPathsModalOpened: boolean;
    data: any;
    infoData: any;
    setDataIsUpdated: Function;
    displayMode: any;
};

export function MoneticValinaSetting({
    setIsMoneticsPathsModalOpened,
    isMoneticsPathsModalOpened,
    data,
    infoData,
    setDataIsUpdated,
    displayMode,
}: PeripheralsModalConfigurationsType) {
    const { t } = useTranslation();
    const [additionalStep, setAdditionalStep] = React.useState<boolean>(
        data.general?.Choice_of_payment?.additional_step
            ? data.general?.Choice_of_payment?.additional_step
            : false
    );
    const [printThePaymentTicket, setPrintThePaymentTicket] = React.useState<
        boolean
    >(
        data.general?.credit_card_ticket?.print_payment_ticket
            ? data.general?.credit_card_ticket?.print_payment_ticket
            : false
    );

    function saveSettingEventhandle() {
        let dataConfig = {
            userId: infoData.userId,
            shopId: infoData.shopId,
            franchiseId: infoData.franchiseId,
            appId_children:
                infoData.appId_children !== ""
                    ? infoData.appId_children
                    : data.Application,
            appId: infoData.appId,
            moneticId:
                data.id_monetic !== "" ? data.id_monetic : data.peripheralId,
            data: {
                Choice_of_payment: { additional_step: additionalStep },
                credit_card_ticket: {
                    print_payment_ticket: printThePaymentTicket,
                },
            },
        };

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };
        fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/application/monetic/general`,
            requestOptions
        )
            .then(async () => {
                toast.success(t("The device is configured successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                });
                setIsMoneticsPathsModalOpened(false);
                setDataIsUpdated!(true);
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    }

    useEffect(() => {
        if (Object.keys(data).length !== 0) {
            setAdditionalStep(
                data.general?.Choice_of_payment?.additional_step
                    ? data.general?.Choice_of_payment?.additional_step
                    : false
            );
            setPrintThePaymentTicket(
                data.general?.credit_card_ticket?.print_payment_ticket
                    ? data.general?.credit_card_ticket?.print_payment_ticket
                    : false
            );
        }
    }, [data]);

    return (
        <React.Fragment>
            <AvForm>
                <Modal size="xl" centered isOpen={isMoneticsPathsModalOpened}>
                    <ModalHeader
                        toggle={() => {
                            setIsMoneticsPathsModalOpened!(
                                !isMoneticsPathsModalOpened
                            );
                            setDataIsUpdated!(true);
                        }}
                    >
                        {displayMode === t("View by device") ? (
                            <StyledH2>
                                {capitalize(t("Settings"))} {data.peripheral} -{" "}
                                {data.appName}
                            </StyledH2>
                        ) : (
                            <StyledH2>
                                {capitalize(t("Settings"))} {data.appName} -{" "}
                                {data.Périphérique}
                            </StyledH2>
                        )}
                    </ModalHeader>
                    <ModalBody style={{ minHeight: "500px" }}>
                        <div className="d-flex  align-items-center justify-content-between">
                            <div className="mb-4">
                                <b className="text-uppercase ">
                                    <StyledH2
                                        style={{
                                            fontSize: "19px",
                                        }}
                                        className="text-uppercase"
                                    >
                                        {t("Valina setting")}
                                    </StyledH2>
                                </b>
                            </div>
                        </div>

                        <div className="d-flex  align-items-center justify-content-between">
                            <div style={{ fontSize: "16px" }}>
                                <b style={{ fontSize: "15px !important" }}>
                                    {t(
                                        "Choice of payment with code or contactless"
                                    )}
                                    <b style={{ color: "red" }}>*</b>
                                </b>
                                <br />
                                <br />
                                {t(
                                    "Add an additional step to choose between payment with code or contactless"
                                )}
                            </div>
                            <div
                                className="p-2"
                                style={{ position: "relative", top: "29px" }}
                            >
                                <ReactSwitch
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    handleDiameter={26}
                                    offColor="#f7b4b8"
                                    offHandleColor="#E30613"
                                    checked={additionalStep}
                                    onChange={(e: any) => {
                                        setAdditionalStep(!additionalStep);
                                    }}
                                    onColor="#c2eddd"
                                    onHandleColor="#34C38F"
                                    width={55}
                                    height={20}
                                    className="afa_icn_paymentChoice"
                                />
                            </div>
                        </div>
                        <div
                            className="d-flex mt-4 mb-4"
                            style={{
                                border: "0.1px solid rgb(128 128 128 / 5%)",
                            }}
                        ></div>
                        <div
                            className="d-flex  align-items-center justify-content-between"
                            style={{ fontSize: "16px" }}
                        >
                            <div>
                                <b style={{ fontSize: "15px !important" }}>
                                    {t("Credit card ticket")}
                                    <b style={{ color: "red" }}>*</b>
                                </b>
                                <br />
                                <br />

                                {t("Print the payment ticket")}
                            </div>
                            <div
                                style={{
                                    position: "relative",
                                    top: "15px",
                                }}
                            >
                                <CustomMainColorButton
                                    rounded
                                    variant="primary"
                                    style={{
                                        height: "20px",
                                        fontSize: "14px !important",
                                    }}
                                    className="afa_btn_testPrinter"
                                >
                                    {t("Test the printer")}
                                </CustomMainColorButton>
                            </div>
                            <div></div>
                            <div></div>
                            <div className="p-2">
                                <div
                                    className="p-2"
                                    style={{
                                        position: "relative",
                                        top: "29px",
                                        left: "9px",
                                    }}
                                >
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={printThePaymentTicket}
                                        onChange={(e: any) => {
                                            setPrintThePaymentTicket(
                                                !printThePaymentTicket
                                            );
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={55}
                                        height={20}
                                        className="afa_icn_bankCardTicket"
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className="border-footer-Valina__clz">
                        <div>
                            <div className="d-flex  align-items-right justify-content-between float-right p-3">
                                <div className="justify-content-between d-flex align-items-right  float-right">
                                    <CustomSecondaryColorButton
                                        outline
                                        rounded
                                        className="mr-2 afa_btn_cancelPopup"
                                        variant="light"
                                        onClick={() => {
                                            setIsMoneticsPathsModalOpened(
                                                false
                                            );
                                        }}
                                    >
                                        <span> {t("Cancel")}</span>
                                    </CustomSecondaryColorButton>
                                    <CustomMainColorButton
                                        rounded
                                        variant="primary"
                                        className="afa_btn_validatePopup"
                                        onClick={() => {
                                            saveSettingEventhandle();
                                        }}
                                    >
                                        {t("Validate")}
                                    </CustomMainColorButton>
                                </div>
                            </div>
                        </div>
                    </ModalFooter>
                </Modal>
            </AvForm>
        </React.Fragment>
    );
}
