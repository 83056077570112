import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AvForm } from "availity-reactstrap-validation";
import { StyledLabel } from "@aureskonnect/react-ui";

import { ConfigProvider, TimePicker } from "antd";
import locale from "antd/es/locale/fr_FR";
import moment, * as Moment from "moment";
import classnames from "classnames";
import { useSnapshot } from "valtio";

import { OverLappedMessage } from "./CardWrapperSpecific/OverLappedMessage";

import { setHourOfUse, setIsErreur, setOverLaps, store } from "../store";

import "antd/dist/antd.css";

export default function Schedule({ selectedRows }: any) {
    const { t } = useTranslation();
    const [overLappedItem] = React.useState({
        keyDay: -1,
        keyHour: -1,
        changedItem: "",
    });

    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);

    let days = [
        {
            0: {
                Icon: t("M"),
                title: t("Monday"),
                visible: false,
            },
            1: {
                Icon: t("Tu"),
                title: t("Tuesday"),
                visible: false,
            },
            2: {
                id: 2,
                Icon: t("W"),
                title: t("Wednesday"),
                visible: false,
            },

            3: {
                id: 3,
                Icon: t("Th"),
                title: t("Thursday"),
                visible: false,
            },
            4: {
                id: 4,
                Icon: t("F"),
                title: t("Friday"),
                visible: false,
            },
            5: {
                id: 5,
                Icon: t("S"),
                title: t("Saturday"),
                visible: false,
            },
            6: {
                id: 6,
                Icon: t("Su"),
                title: t("Sunday"),
                visible: false,
            },
        },
    ];
    const [overLap] = React.useState<any[]>([
        {
            HeureDebut_Matin: false,
            HeureFin_Matin: false,
            HeureDebut_soir: false,
            HeureFin_soir: false,
            inputDebut_Matin: false,
            inputFin_Matin: false,
            inputDebut_Soir: false,
            inputFin_Soir: false,
        },
        {
            HeureDebut_Matin: false,
            HeureFin_Matin: false,
            HeureDebut_soir: false,
            HeureFin_soir: false,
            inputDebut_Matin: false,
            inputFin_Matin: false,
            inputDebut_Soir: false,
            inputFin_Soir: false,
        },
        {
            HeureDebut_Matin: false,
            HeureFin_Matin: false,
            HeureDebut_soir: false,
            HeureFin_soir: false,
            inputDebut_Matin: false,
            inputFin_Matin: false,
            inputDebut_Soir: false,
            inputFin_Soir: false,
        },

        {
            HeureDebut_Matin: false,
            HeureFin_Matin: false,
            HeureDebut_soir: false,
            HeureFin_soir: false,
            inputDebut_Matin: false,
            inputFin_Matin: false,
            inputDebut_Soir: false,
            inputFin_Soir: false,
        },
        {
            HeureDebut_Matin: false,
            HeureFin_Matin: false,
            HeureDebut_soir: false,
            HeureFin_soir: false,
            inputDebut_Matin: false,
            inputFin_Matin: false,
            inputDebut_Soir: false,
            inputFin_Soir: false,
        },
        {
            HeureDebut_Matin: false,
            HeureFin_Matin: false,
            HeureDebut_soir: false,
            HeureFin_soir: false,
            inputDebut_Matin: false,
            inputFin_Matin: false,
            inputDebut_Soir: false,
            inputFin_Soir: false,
        },
        {
            HeureDebut_Matin: false,
            HeureFin_Matin: false,
            HeureDebut_soir: false,
            HeureFin_soir: false,
            inputDebut_Matin: false,
            inputFin_Matin: false,
            inputDebut_Soir: false,
            inputFin_Soir: false,
        },
    ]);
    const [week, setWeek] = React.useState<any[]>([
        {
            day: t("Monday"),
            Jour_actif: false,
            HeureDebut_Matin: "",
            HeureFin_Matin: "",
            HeureDebut_soir: "",
            HeureFin_soir: "",
        },
        {
            day: t("Tuesday"),
            Jour_actif: false,
            HeureDebut_Matin: "",
            HeureFin_Matin: "",
            HeureDebut_soir: "",
            HeureFin_soir: "",
        },
        {
            day: t("Wednesday"),
            Jour_actif: false,
            HeureDebut_Matin: "",
            HeureFin_Matin: "",
            HeureDebut_soir: "",
            HeureFin_soir: "",
        },
        {
            day: t("Thursday"),
            Jour_actif: false,
            HeureDebut_Matin: "",
            HeureFin_Matin: "",
            HeureDebut_soir: "",
            HeureFin_soir: "",
        },
        {
            day: t("Friday"),
            Jour_actif: false,
            HeureDebut_Matin: "",
            HeureFin_Matin: "",
            HeureDebut_soir: "",
            HeureFin_soir: "",
        },
        {
            day: t("Saturday"),
            Jour_actif: false,
            HeureDebut_Matin: "",
            HeureFin_Matin: "",
            HeureDebut_soir: "",
            HeureFin_soir: "",
        },
        {
            day: t("Sunday"),
            Jour_actif: false,
            HeureDebut_Matin: "",
            HeureFin_Matin: "",
            HeureDebut_soir: "",
            HeureFin_soir: "",
        },
    ]);
    let { hourOfUse, overLaps } = useSnapshot(store);

    useEffect(() => {
        if (
            selectedRows[0]["Horaire"] !== null &&
            selectedRows[0]["Horaire"] !== undefined &&
            selectedRows[0]["Horaire"] !== ""
        ) {
            setHourOfUse(JSON.parse(selectedRows[0]["Horaire"]));
        } else {
            setHourOfUse(JSON.parse(JSON.stringify(week)));
        }
        setOverLaps(overLap);
    }, [selectedRows, week, overLap]);

    const handleWeekChange = (
        changedItem: string,
        item: any,
        keyDay: number
    ) => {
        let newWeek = JSON.parse(JSON.stringify(hourOfUse));

        newWeek[keyDay][changedItem] = item.toString().replace(":", "");

        newWeek[keyDay].Jour_actif = true;

        setHourOfUse(newWeek);
    };

    const checkIfHoursOverLapped = (
        key: number,
        changedItem: string,
        item: any
    ) => {
        let newWeek = JSON.parse(JSON.stringify(hourOfUse));
        let newOverLapp = JSON.parse(JSON.stringify(overLaps));

        if (
            changedItem.search("Matin") === -1 &&
            ((Number(newWeek[key].HeureDebut_Matin) <
                Number(item.toString().replace(":", "")) &&
                Number(newWeek[key].HeureFin_Matin) >
                    Number(item.toString().replace(":", ""))) ||
                Number(
                    newWeek[key].HeureDebut_Matin >
                        Number(item.toString().replace(":", "")) &&
                        Number(newWeek[key].HeureFin_Matin) <
                            Number(item.toString().replace(":", ""))
                ))
        ) {
            newOverLapp[key][changedItem] = true;
            setIsErreur(true);
        } else if (
            (Number(newWeek[key].HeureDebut_soir) <
                Number(item.toString().replace(":", "")) &&
                Number(newWeek[key].HeureFin_soir) >
                    Number(item.toString().replace(":", ""))) ||
            (Number(newWeek[key].HeureDebut_soir) >
                Number(item.toString().replace(":", "")) &&
                Number(newWeek[key].HeureFin_soir) <
                    Number(item.toString().replace(":", "")))
        ) {
            newOverLapp[key][changedItem] = true;
            setIsErreur(true);
        } else {
            newOverLapp[key][changedItem] = false;
            setIsErreur(false);
        }

        setOverLaps(newOverLapp);
    };

    return (
        <React.Fragment>
            <StyledLabel
                className="text-uppercase ml-2 mb-3"
                style={{ font: "normal normal 600 35px/47px Segoe UI" }}
            >
                {t("Hours of use")}:
            </StyledLabel>
            <AvForm>
                <fieldset
                    className="border"
                    style={{
                        height: "773px",
                        overflowY: "scroll",
                        maxHeight: "800px",
                    }}
                >
                    <legend className="w-auto">
                        <StyledLabel htmlFor="text">
                            {t("Choose the time")}
                        </StyledLabel>
                    </legend>

                    {hourOfUse.map((element: any, key: number) => (
                        <fieldset
                            key={key}
                            className="border px-2"
                            style={{
                                width: "1460px",
                                marginTop: "1px",
                                height: "119px",
                            }}
                        >
                            <div className="d-flex justify-content-between pt-2">
                                {days.map((day: any) => {
                                    return (
                                        <div className="d-flex justify-content-between">
                                            <div
                                                className={
                                                    element.Jour_actif
                                                        ? "cercleactive__clz mr-2"
                                                        : "cercle__clz mr-2 "
                                                }
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <div className="d-flex justify-content-center align-items-center mt-1">
                                                    <h5
                                                        style={{
                                                            color: element.Jour_actif
                                                                ? "white"
                                                                : "black",
                                                        }}
                                                    >
                                                        {day[key].Icon}
                                                    </h5>
                                                </div>
                                            </div>

                                            <StyledLabel
                                                style={{
                                                    marginTop:
                                                        key !== 0
                                                            ? "5px"
                                                            : "0px",
                                                    marginLeft: "10px",
                                                }}
                                            >
                                                {day[key].title}
                                            </StyledLabel>
                                        </div>
                                    );
                                })}
                                <div
                                    style={{
                                        position: "relative",
                                        left: "-200px",
                                    }}
                                >
                                    <div className="d-flex flex-wrap mb-1">
                                        <StyledLabel>
                                            {t("Start time")}
                                        </StyledLabel>
                                        <div className="d-flex flex-column ">
                                            <ConfigProvider locale={locale}>
                                                <TimePicker
                                                    value={
                                                        element.HeureDebut_Matin !==
                                                        ""
                                                            ? (moment(
                                                                  element.HeureDebut_Matin,
                                                                  "HH:mm"
                                                              ) as
                                                                  | Moment.Moment
                                                                  | null
                                                                  | undefined)
                                                            : null
                                                    }
                                                    format="HH:mm"
                                                    style={{
                                                        width: "100px",
                                                        height: "30px",
                                                        marginLeft: "10px",
                                                        marginRight: "10px",
                                                    }}
                                                    className={classnames({
                                                        input__clz:
                                                            overLaps[key]
                                                                .HeureDebut_Matin ||
                                                            overLaps[key]
                                                                .inputDebut_Matin,
                                                    })}
                                                    onChange={(
                                                        time: Moment.Moment | null,
                                                        e: string
                                                    ) => {
                                                        handleWeekChange(
                                                            "HeureDebut_Matin",
                                                            e,
                                                            key
                                                        );
                                                        checkIfHoursOverLapped(
                                                            key,
                                                            "HeureDebut_Matin",
                                                            e
                                                        );
                                                    }}
                                                />
                                            </ConfigProvider>
                                        </div>
                                        {overLaps[key].inputDebut_Matin ? (
                                            <div
                                                style={{
                                                    width: "35%",
                                                    marginTop: "1.85rem",
                                                    fontSize: "80%",
                                                    color: "#f46a6a",
                                                    position: "absolute",
                                                    left: "100px",
                                                }}
                                            >
                                                {t("Please choose a time")}
                                            </div>
                                        ) : null}
                                        {overLaps[key].HeureDebut_Matin ? (
                                            <div
                                                style={{
                                                    width: "35%",
                                                    marginTop: "1.85rem",
                                                    fontSize: "80%",
                                                    color: "#f46a6a",
                                                    position: "absolute",
                                                    left: "100px",
                                                }}
                                            >
                                                {t("Overlap detected")}
                                            </div>
                                        ) : null}
                                        <StyledLabel
                                            style={{
                                                position: "relative",
                                                left: "100px",
                                            }}
                                        >
                                            {t("End time")}
                                        </StyledLabel>
                                        <div className="d-flex flex-column">
                                            <ConfigProvider locale={locale}>
                                                <TimePicker
                                                    value={
                                                        element.HeureFin_Matin !==
                                                        ""
                                                            ? (moment(
                                                                  element.HeureFin_Matin,
                                                                  "HH:mm"
                                                              ) as
                                                                  | Moment.Moment
                                                                  | null
                                                                  | undefined)
                                                            : null
                                                    }
                                                    format="HH:mm"
                                                    style={{
                                                        width: "100px",
                                                        height: "30px",
                                                        marginLeft: "120px",
                                                    }}
                                                    className={classnames({
                                                        input__clz:
                                                            overLaps[key]
                                                                .HeureFin_Matin ||
                                                            overLaps[key]
                                                                .inputFin_Matin,
                                                    })}
                                                    onChange={(
                                                        time: Moment.Moment | null,
                                                        e: string
                                                    ) => {
                                                        handleWeekChange(
                                                            "HeureFin_Matin",
                                                            e,
                                                            key
                                                        );
                                                        checkIfHoursOverLapped(
                                                            key,
                                                            "HeureFin_Matin",
                                                            e
                                                        );
                                                    }}
                                                />
                                            </ConfigProvider>
                                        </div>
                                        {overLaps[key].inputFin_Matin ? (
                                            <div
                                                style={{
                                                    width: "35%",
                                                    marginTop: "1.85rem",
                                                    fontSize: "80%",
                                                    color: "#f46a6a",
                                                    position: "absolute",
                                                    left: "392px",
                                                }}
                                            >
                                                {t("Please choose a time")}
                                            </div>
                                        ) : null}
                                        {overLaps[key].HeureFin_Matin ? (
                                            <div
                                                style={{
                                                    width: "35%",
                                                    marginTop: "1.85rem",
                                                    fontSize: "80%",
                                                    color: "#f46a6a",
                                                    position: "absolute",
                                                    left: "392px",
                                                }}
                                            >
                                                {t("Overlap detected")}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="d-flex flex-wrap">
                                        <StyledLabel className="mt-3">
                                            {t("Start time")}
                                        </StyledLabel>
                                        <div className="d-flex flex-column ">
                                            <ConfigProvider locale={locale}>
                                                <TimePicker
                                                    value={
                                                        element.HeureDebut_soir !==
                                                        ""
                                                            ? (moment(
                                                                  element.HeureDebut_soir,
                                                                  "HH:mm"
                                                              ) as
                                                                  | Moment.Moment
                                                                  | null
                                                                  | undefined)
                                                            : null
                                                    }
                                                    format="HH:mm"
                                                    style={{
                                                        width: "100px",
                                                        height: "30px",
                                                        marginLeft: "10px",
                                                        marginRight: "10px",
                                                        top: "16px",
                                                    }}
                                                    className={classnames({
                                                        input__clz:
                                                            overLaps[key]
                                                                .HeureDebut_soir ||
                                                            overLaps[key]
                                                                .inputDebut_Soir,
                                                    })}
                                                    onChange={(
                                                        time: Moment.Moment | null,
                                                        e: string
                                                    ) => {
                                                        handleWeekChange(
                                                            "HeureDebut_soir",
                                                            e,
                                                            key
                                                        );
                                                        checkIfHoursOverLapped(
                                                            key,
                                                            "HeureDebut_soir",
                                                            e
                                                        );
                                                    }}
                                                />
                                            </ConfigProvider>
                                            {overLaps[key].inputDebut_Soir ? (
                                                <div
                                                    style={{
                                                        width: "35%",
                                                        marginTop: "3rem",
                                                        fontSize: "80%",
                                                        color: "#f46a6a",
                                                        position: "absolute",
                                                        left: "100px",
                                                    }}
                                                >
                                                    {t("Please choose a time")}
                                                </div>
                                            ) : null}
                                            {overLaps[key].HeureDebut_soir ? (
                                                <div
                                                    style={{
                                                        width: "35%",
                                                        marginTop: "3rem",
                                                        fontSize: "80%",
                                                        color: "#f46a6a",
                                                        position: "absolute",
                                                        left: "100px",
                                                    }}
                                                >
                                                    {t("Overlap detected")}
                                                </div>
                                            ) : null}
                                        </div>

                                        <StyledLabel
                                            className="mt-3"
                                            style={{
                                                position: "relative",
                                                left: "100px",
                                            }}
                                        >
                                            {t("End time")}
                                        </StyledLabel>
                                        <div className="d-flex flex-column">
                                            <ConfigProvider locale={locale}>
                                                <TimePicker
                                                    value={
                                                        element.HeureFin_soir !==
                                                        ""
                                                            ? (moment(
                                                                  element.HeureFin_soir,
                                                                  "HH:mm"
                                                              ) as
                                                                  | Moment.Moment
                                                                  | null
                                                                  | undefined)
                                                            : null
                                                    }
                                                    format="HH:mm"
                                                    style={{
                                                        width: "100px",
                                                        height: "30px",
                                                        marginLeft: "120px",
                                                        top: "16px",
                                                    }}
                                                    className={classnames({
                                                        input__clz:
                                                            overLaps[key]
                                                                .HeureFin_soir ||
                                                            overLaps[key]
                                                                .inputDebut_Soir,
                                                    })}
                                                    onChange={(
                                                        time: Moment.Moment | null,
                                                        e: string
                                                    ) => {
                                                        handleWeekChange(
                                                            "HeureFin_soir",
                                                            e,
                                                            key
                                                        );
                                                        checkIfHoursOverLapped(
                                                            key,
                                                            "HeureFin_soir",
                                                            e
                                                        );
                                                    }}
                                                />
                                                {overLaps[key].inputFin_Soir ? (
                                                    <div
                                                        style={{
                                                            width: "35%",
                                                            marginTop: "3rem",
                                                            fontSize: "80%",
                                                            color: "#f46a6a",
                                                            position:
                                                                "absolute",
                                                            left: "392px",
                                                        }}
                                                    >
                                                        {t(
                                                            "Please choose a time"
                                                        )}
                                                    </div>
                                                ) : null}
                                                {overLaps[key].HeureFin_soir ? (
                                                    <div
                                                        style={{
                                                            width: "35%",
                                                            marginTop: "3rem",
                                                            fontSize: "80%",
                                                            color: "#f46a6a",
                                                            position:
                                                                "absolute",
                                                            left: "392px",
                                                        }}
                                                    >
                                                        {t("Overlap detected")}
                                                    </div>
                                                ) : null}
                                            </ConfigProvider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    ))}
                </fieldset>
            </AvForm>
            <OverLappedMessage
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                overLappedItem={overLappedItem}
                week={week}
                setWeek={setWeek}
            />
        </React.Fragment>
    );
}
