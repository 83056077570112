import React from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import { store } from "@store";
import { store as userStore } from "@components/VerticalLayout/store";
import { setSalesModes } from "@pages/ApplicationSetting/store";
import GeneralPanelSetting from "@pages/ApplicationSetting/General/index";
import ClientPanelSetting from "@pages/ApplicationSetting/CustomerTicket/index";
import KitchenPanelSetting from "@pages/ApplicationSetting/KitchenTicket/index";
import ModeleTicket from "../../ApplicationSetting/ModeleTicket";
import Divers from "@pages/ApplicationSetting/Divers";

import "./index.css";

type MenuWrapperType = {
    rowData: any;
    oneShop: boolean;
};

type TabsMenuType = {
    title: string;
    content: React.ReactNode;
    customClassName: string;
    subMenu?: {
        title: string;
        content: React.ReactNode;
    }[];
};

export function MenuWrapper({ rowData, oneShop }: MenuWrapperType) {
    const { t } = useTranslation();
    const { mainColor } = useSnapshot(store);

    const [activeTab, setActiveTab] = React.useState<number>(0);
    const [activeSubTab, setActiveSubTab] = React.useState<number>(0);
    const [isSubMenuCollapsed, setIsSubMenuCollapsed] = React.useState<boolean>(
        false
    );
    const { userID, franchiseID, shopID } = useSnapshot(userStore);

    const shopId = oneShop ? shopID : rowData.shopId;

    const fetchModeOfSale = React.useCallback(() => {
        const languageHeader = "en-US";

        const headers = new Headers({
            "Accept-Language": languageHeader,
        });

        const requestOptions = {
            method: "GET",
            headers: headers,
        };

        fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`,
            requestOptions
        )
            .then((res) => res.json())
            .then((response) => {
                const saleModes: any[] = response.data.map((saleMode: any) => {
                    return {
                        // eslint-disable-next-line array-callback-return
                        saleSupports: saleMode.support_vente.map((el: any) => {
                            if (el === "KIOSK")
                                return {
                                    designation: saleMode["Mode of sale"],
                                    state: false,
                                };
                        }),
                    };
                });

                let salesKitchen_ticket: any[] = [];
                let rowDataSalesKitchen_ticket: any[] = [];

                let salesModes: any[] = [];

                let rowDataSalesCustomer_ticket: any[] = [];
                let salesCustomer_ticket: any[] = [];

                let rowDataSalesCustomerAddressModeSale: any[] = [];
                let salesSalesCustomerAddressModeSale: any[] = [];
                let salesSupportMode: any[] = [];

                if (
                    rowData["customer_ticket"]?.dateTimeDeliveryModeSale !==
                    undefined
                ) {
                    for (let element of rowData["customer_ticket"]
                        ?.dateTimeDeliveryModeSale) {
                        rowDataSalesCustomer_ticket.push(element);
                    }
                }
                if (
                    rowData["Kitchen_ticket"]?.ticketKitchenModeSale !==
                    undefined
                ) {
                    for (let element of rowData["Kitchen_ticket"]
                        ?.ticketKitchenModeSale) {
                        rowDataSalesKitchen_ticket.push(element);
                    }
                }

                if (
                    rowData["Kitchen_ticket"]?.customerAddressModeSale !==
                    undefined
                ) {
                    for (let element of rowData["customer_ticket"]
                        ?.dateTimeDeliveryModeSale) {
                        rowDataSalesCustomerAddressModeSale.push(element);
                    }
                }

                saleModes !== undefined &&
                    saleModes.forEach((el: any) => {
                        el.saleSupports.forEach((elt: any) => {
                            if (elt !== undefined) {
                                salesSupportMode.push(elt.designation);
                                rowDataSalesKitchen_ticket = rowDataSalesKitchen_ticket.filter(
                                    (o) => o.designation !== elt.designation
                                );
                                rowDataSalesCustomer_ticket = rowDataSalesCustomer_ticket.filter(
                                    (o) => o.designation !== elt.designation
                                );
                                rowDataSalesCustomerAddressModeSale = rowDataSalesCustomerAddressModeSale.filter(
                                    (o) => o.designation !== elt.designation
                                );
                            }
                        });
                    });

                saleModes !== undefined &&
                    saleModes.forEach((el: any) => {
                        if (
                            rowData["Kitchen_ticket"]?.ticketKitchenModeSale !==
                            undefined
                        ) {
                            for (let element of rowData["Kitchen_ticket"]
                                ?.ticketKitchenModeSale) {
                                salesModes.push(element.designation);
                                salesKitchen_ticket.push(element);
                            }
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    elt !== undefined &&
                                    !salesModes.includes(elt.designation)
                                ) {
                                    salesKitchen_ticket.push(elt);
                                }
                            });
                        } else {
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    rowData["Kitchen_ticket"]
                                        ?.ticketKitchenModeSale === undefined ||
                                    rowData["Kitchen_ticket"]
                                        ?.ticketKitchenModeSale.length === 0
                                ) {
                                    elt !== undefined &&
                                        salesKitchen_ticket.push(elt);
                                }
                            });
                        }

                        if (
                            rowData["customer_ticket"]
                                ?.dateTimeDeliveryModeSale !== undefined
                        ) {
                            for (let element of rowData["customer_ticket"]
                                ?.dateTimeDeliveryModeSale) {
                                salesModes.push(element.designation);
                                salesCustomer_ticket.push(element);
                            }
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    elt !== undefined &&
                                    !salesModes.includes(elt.designation)
                                ) {
                                    salesCustomer_ticket.push(elt);
                                }
                            });
                        } else {
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    rowData["customer_ticket"]
                                        ?.dateTimeDeliveryModeSale ===
                                        undefined ||
                                    rowData["customer_ticket"]
                                        ?.dateTimeDeliveryModeSale.length === 0
                                ) {
                                    elt !== undefined &&
                                        salesCustomer_ticket.push(elt);
                                }
                            });
                        }

                        if (
                            rowData["Kitchen_ticket"]
                                ?.customerAddressModeSale !== undefined
                        ) {
                            for (let element of rowData["Kitchen_ticket"]
                                ?.customerAddressModeSale) {
                                salesModes.push(element.designation);
                                salesSalesCustomerAddressModeSale.push(element);
                            }
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    elt !== undefined &&
                                    !salesModes.includes(elt.designation)
                                ) {
                                    salesSalesCustomerAddressModeSale.push(elt);
                                }
                            });
                        } else {
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    rowData["Kitchen_ticket"]
                                        ?.customerAddressModeSale ===
                                        undefined ||
                                    rowData["Kitchen_ticket"]
                                        ?.customerAddressModeSale.length === 0
                                ) {
                                    elt !== undefined &&
                                        salesSalesCustomerAddressModeSale.push(
                                            elt
                                        );
                                }
                            });
                        }
                    });
                salesKitchen_ticket = [...new Set(salesKitchen_ticket)];
                for (let sale of rowDataSalesKitchen_ticket) {
                    salesKitchen_ticket = salesKitchen_ticket.filter(
                        (o) => o.designation !== sale.designation
                    );
                }

                salesCustomer_ticket = [...new Set(salesCustomer_ticket)];
                for (let sale of rowDataSalesCustomer_ticket) {
                    salesCustomer_ticket = salesCustomer_ticket.filter(
                        (o) => o.designation !== sale.designation
                    );
                }

                salesSalesCustomerAddressModeSale = [
                    ...new Set(salesSalesCustomerAddressModeSale),
                ];
                for (let sale of rowDataSalesCustomerAddressModeSale) {
                    salesSalesCustomerAddressModeSale = salesSalesCustomerAddressModeSale.filter(
                        (o) => o.designation !== sale.designation
                    );
                }

                const saleModesNames: any[] = response.data.map(
                    (saleMode: any) => {
                        return {
                            saleSupports: saleMode.support_vente.map(
                                // eslint-disable-next-line array-callback-return
                                (el: any) => {
                                    if (el === "KIOSK")
                                        return {
                                            designation:
                                                saleMode["Mode of sale"],
                                            state: false,
                                        };
                                }
                            ),
                        };
                    }
                );

                let salesNames: any[] = [];

                let rowDataSales: any[] = [];

                if (
                    rowData["Kitchen_ticket"]?.customer_information_mode_sale
                        ?.customerInformationModeSaleName !== undefined
                ) {
                    for (let element of rowData["Kitchen_ticket"]
                        ?.customer_information_mode_sale
                        ?.customerInformationModeSaleName) {
                        rowDataSales.push(element);
                    }
                }
                saleModesNames !== undefined &&
                    saleModesNames.forEach((el: any) => {
                        el.saleSupports.forEach((elt: any) => {
                            if (elt !== undefined) {
                                salesSupportMode.push(elt.designation);
                                rowDataSales = rowDataSales.filter(
                                    (o) => o.designation !== elt.designation
                                );
                            }
                        });
                    });
                saleModesNames !== undefined &&
                    saleModesNames.forEach((el: any) => {
                        if (
                            rowData["Kitchen_ticket"]
                                ?.customer_information_mode_sale
                                ?.customerInformationModeSaleName !== undefined
                        ) {
                            for (let element of rowData["Kitchen_ticket"]
                                ?.customer_information_mode_sale
                                ?.customerInformationModeSaleName) {
                                salesModes.push(element.designation);
                                salesNames.push(element);
                            }
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    elt !== undefined &&
                                    !salesModes.includes(elt.designation)
                                ) {
                                    salesNames.push(elt);
                                }
                            });
                        } else {
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    rowData["Kitchen_ticket"]
                                        ?.customer_information_mode_sale
                                        ?.customerInformationModeSaleName ===
                                        undefined ||
                                    rowData["Kitchen_ticket"]
                                        ?.customer_information_mode_sale
                                        ?.customerInformationModeSaleName === 0
                                ) {
                                    elt !== undefined && salesNames.push(elt);
                                }
                            });
                        }
                    });
                salesNames = [...new Set(salesNames)];
                for (let sale of rowDataSales) {
                    salesNames = salesNames.filter(
                        (o) => o.designation !== sale.designation
                    );
                }

                const saleModesNumTel: any[] = response.data.map(
                    (saleMode: any) => {
                        return {
                            saleSupports: saleMode.support_vente.map(
                                // eslint-disable-next-line array-callback-return
                                (el: any) => {
                                    if (el === "KIOSK")
                                        return {
                                            designation:
                                                saleMode["Mode of sale"],
                                            state: false,
                                        };
                                }
                            ),
                        };
                    }
                );
                let salesNamesNumTel: any[] = [];
                let salesNumTel: any[] = [];
                let rowDataSalesNumTel: any[] = [];

                let salesSupportModeNumTel: any[] = [];
                if (
                    rowData["Kitchen_ticket"]?.customer_information_mode_sale
                        ?.customerInformationModeSaleNumTel !== undefined
                ) {
                    for (let element of rowData["Kitchen_ticket"]
                        ?.customer_information_mode_sale
                        ?.customerInformationModeSaleNumTel) {
                        rowDataSalesNumTel.push(element);
                    }
                }
                saleModesNumTel !== undefined &&
                    saleModesNumTel.forEach((el: any) => {
                        el.saleSupports.forEach((elt: any) => {
                            if (elt !== undefined) {
                                salesSupportModeNumTel.push(elt.designation);
                                rowDataSalesNumTel = rowDataSalesNumTel.filter(
                                    (o) => o.designation !== elt.designation
                                );
                            }
                        });
                    });
                saleModesNumTel !== undefined &&
                    saleModesNumTel.forEach((el: any) => {
                        if (
                            rowData["Kitchen_ticket"]
                                ?.customer_information_mode_sale
                                ?.customerInformationModeSaleNumTel !==
                            undefined
                        ) {
                            for (let element of rowData["Kitchen_ticket"]
                                ?.customer_information_mode_sale
                                ?.customerInformationModeSaleNumTel) {
                                salesNamesNumTel.push(element.designation);
                                salesNumTel.push(element);
                            }
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    elt !== undefined &&
                                    !salesNamesNumTel.includes(elt.designation)
                                ) {
                                    salesNumTel.push(elt);
                                }
                            });
                        } else {
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    rowData["Kitchen_ticket"]
                                        ?.customer_information_mode_sale
                                        ?.customerInformationModeSaleNumTel ===
                                        undefined ||
                                    rowData["Kitchen_ticket"]
                                        ?.customer_information_mode_sale
                                        ?.customerInformationModeSaleNumTel ===
                                        0
                                ) {
                                    elt !== undefined && salesNumTel.push(elt);
                                }
                            });
                        }
                    });
                salesNumTel = [...new Set(salesNumTel)];

                for (let sale of rowDataSalesNumTel) {
                    salesNumTel = salesNumTel.filter(
                        (o) => o.designation !== sale.designation
                    );
                }

                const saleModesAdress: any[] = response.data.map(
                    (saleMode: any) => {
                        return {
                            saleSupports: saleMode.support_vente.map(
                                // eslint-disable-next-line array-callback-return
                                (el: any) => {
                                    if (el === "KIOSK")
                                        return {
                                            designation:
                                                saleMode["Mode of sale"],
                                            state: false,
                                        };
                                }
                            ),
                        };
                    }
                );
                let salesNamesAdress: any[] = [];
                let salesAdress: any[] = [];
                let rowDataSalesAdress: any[] = [];

                let salesSupportModeAdress: any[] = [];
                if (
                    rowData["Kitchen_ticket"]?.customer_information_mode_sale
                        ?.customerInformationModeSaleAdress !== undefined
                ) {
                    for (let element of rowData["Kitchen_ticket"]
                        ?.customer_information_mode_sale
                        ?.customerInformationModeSaleAdress) {
                        rowDataSalesAdress.push(element);
                    }
                }
                saleModesAdress !== undefined &&
                    saleModesAdress.forEach((el: any) => {
                        el.saleSupports.forEach((elt: any) => {
                            if (elt !== undefined) {
                                salesSupportModeAdress.push(elt.designation);
                                rowDataSalesAdress = rowDataSalesAdress.filter(
                                    (o) => o.designation !== elt.designation
                                );
                            }
                        });
                    });
                saleModesAdress !== undefined &&
                    saleModesAdress.forEach((el: any) => {
                        if (
                            rowData["Kitchen_ticket"]
                                ?.customer_information_mode_sale
                                ?.customerInformationModeSaleAdress !==
                            undefined
                        ) {
                            for (let element of rowData["Kitchen_ticket"]
                                ?.customer_information_mode_sale
                                ?.customerInformationModeSaleAdress) {
                                salesNamesAdress.push(element.designation);
                                salesAdress.push(element);
                            }
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    elt !== undefined &&
                                    !salesNamesAdress.includes(elt.designation)
                                ) {
                                    salesAdress.push(elt);
                                }
                            });
                        } else {
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    rowData["Kitchen_ticket"]
                                        ?.customer_information_mode_sale
                                        ?.customerInformationModeSaleAdress ===
                                        undefined ||
                                    rowData["Kitchen_ticket"]
                                        ?.customer_information_mode_sale
                                        ?.customerInformationModeSaleAdress ===
                                        0
                                ) {
                                    elt !== undefined && salesAdress.push(elt);
                                }
                            });
                        }
                    });
                salesAdress = [...new Set(salesAdress)];
                for (let sale of rowDataSalesAdress) {
                    salesAdress = salesAdress.filter(
                        (o) => o.designation !== sale.designation
                    );
                }

                const saleModesCity: any[] = response.data.map(
                    (saleMode: any) => {
                        return {
                            saleSupports: saleMode.support_vente.map(
                                // eslint-disable-next-line array-callback-return
                                (el: any) => {
                                    if (el === "KIOSK")
                                        return {
                                            designation:
                                                saleMode["Mode of sale"],
                                            state: false,
                                        };
                                }
                            ),
                        };
                    }
                );
                let salesNamesCity: any[] = [];
                let salesCity: any[] = [];
                let rowDataSalesCity: any[] = [];

                let salesSupportModeCity: any[] = [];
                if (
                    rowData["Kitchen_ticket"]?.customer_information_mode_sale
                        ?.customerInformationModeSaleCity !== undefined
                ) {
                    for (let element of rowData["Kitchen_ticket"]
                        ?.customer_information_mode_sale
                        ?.customerInformationModeSaleCity) {
                        rowDataSalesCity.push(element);
                    }
                }
                saleModesCity !== undefined &&
                    saleModesCity.forEach((el: any) => {
                        el.saleSupports.forEach((elt: any) => {
                            if (elt !== undefined) {
                                salesSupportModeCity.push(elt.designation);
                                rowDataSalesCity = rowDataSalesCity.filter(
                                    (o) => o.designation !== elt.designation
                                );
                            }
                        });
                    });
                saleModesCity !== undefined &&
                    saleModesCity.forEach((el: any) => {
                        if (
                            rowData["Kitchen_ticket"]
                                ?.customer_information_mode_sale
                                ?.customerInformationModeSaleCity !== undefined
                        ) {
                            for (let element of rowData["Kitchen_ticket"]
                                ?.customer_information_mode_sale
                                ?.customerInformationModeSaleCity) {
                                salesNamesCity.push(element.designation);
                                salesCity.push(element);
                            }
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    elt !== undefined &&
                                    !salesNamesCity.includes(elt.designation)
                                ) {
                                    salesCity.push(elt);
                                }
                            });
                        } else {
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    rowData["Kitchen_ticket"]
                                        ?.customer_information_mode_sale
                                        ?.customerInformationModeSaleCity ===
                                        undefined ||
                                    rowData["Kitchen_ticket"]
                                        ?.customer_information_mode_sale
                                        ?.customerInformationModeSaleCity === 0
                                ) {
                                    elt !== undefined && salesCity.push(elt);
                                }
                            });
                        }
                    });
                salesCity = [...new Set(salesCity)];
                for (let sale of rowDataSalesCity) {
                    salesCity = salesCity.filter(
                        (o) => o.designation !== sale.designation
                    );
                }

                const saleModesCompany: any[] = response.data.map(
                    (saleMode: any) => {
                        return {
                            saleSupports: saleMode.support_vente.map(
                                // eslint-disable-next-line array-callback-return
                                (el: any) => {
                                    if (el === "KIOSK")
                                        return {
                                            designation:
                                                saleMode["Mode of sale"],
                                            state: false,
                                        };
                                }
                            ),
                        };
                    }
                );
                let salesNamesCompany: any[] = [];
                let salesCompany: any[] = [];
                let rowDataSalesCompany: any[] = [];

                let salesSupportModeCompany: any[] = [];
                if (
                    rowData["Kitchen_ticket"]?.customer_information_mode_sale
                        ?.customerInformationModeSaleCompany !== undefined
                ) {
                    for (let element of rowData["Kitchen_ticket"]
                        ?.customer_information_mode_sale
                        ?.customerInformationModeSaleCompany) {
                        rowDataSalesCompany.push(element);
                    }
                }
                saleModesCompany !== undefined &&
                    saleModesCompany.forEach((el: any) => {
                        el.saleSupports.forEach((elt: any) => {
                            if (elt !== undefined) {
                                salesSupportModeCompany.push(elt.designation);
                                rowDataSalesCompany = rowDataSalesCompany.filter(
                                    (o) => o.designation !== elt.designation
                                );
                            }
                        });
                    });
                saleModesCompany !== undefined &&
                    saleModesCompany.forEach((el: any) => {
                        if (
                            rowData["Kitchen_ticket"]
                                ?.customer_information_mode_sale
                                ?.customerInformationModeSaleCompany !==
                            undefined
                        ) {
                            for (let element of rowData["Kitchen_ticket"]
                                ?.customer_information_mode_sale
                                ?.customerInformationModeSaleCompany) {
                                salesNamesCompany.push(element.designation);
                                salesCompany.push(element);
                            }
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    elt !== undefined &&
                                    !salesNamesCompany.includes(elt.designation)
                                ) {
                                    salesCompany.push(elt);
                                }
                            });
                        } else {
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    rowData["Kitchen_ticket"]
                                        ?.customer_information_mode_sale
                                        ?.customerInformationModeSaleCompany ===
                                        undefined ||
                                    rowData["Kitchen_ticket"]
                                        ?.customer_information_mode_sale
                                        ?.customerInformationModeSaleCompany ===
                                        0
                                ) {
                                    elt !== undefined && salesCompany.push(elt);
                                }
                            });
                        }
                    });
                salesCompany = [...new Set(salesCompany)];
                for (let sale of rowDataSalesCompany) {
                    salesCompany = salesCompany.filter(
                        (o) => o.designation !== sale.designation
                    );
                }

                const saleModesCode: any[] = response.data.map(
                    (saleMode: any) => {
                        return {
                            saleSupports: saleMode.support_vente.map(
                                // eslint-disable-next-line array-callback-return
                                (el: any) => {
                                    if (el === "KIOSK")
                                        return {
                                            designation:
                                                saleMode["Mode of sale"],
                                            state: false,
                                        };
                                }
                            ),
                        };
                    }
                );
                let salesNamesCode: any[] = [];
                let salesCode: any[] = [];
                let rowDataSalesCode: any[] = [];

                let salesSupportModeCode: any[] = [];
                if (
                    rowData["Kitchen_ticket"]?.customer_information_mode_sale
                        ?.customerInformationModeSaleCode !== undefined
                ) {
                    for (let element of rowData["Kitchen_ticket"]
                        ?.customer_information_mode_sale
                        ?.customerInformationModeSaleCode) {
                        rowDataSalesCode.push(element);
                    }
                }
                saleModesCompany !== undefined &&
                    saleModesCompany.forEach((el: any) => {
                        el.saleSupports.forEach((elt: any) => {
                            if (elt !== undefined) {
                                salesSupportModeCode.push(elt.designation);
                                rowDataSalesCode = rowDataSalesCode.filter(
                                    (o) => o.designation !== elt.designation
                                );
                            }
                        });
                    });
                saleModesCode !== undefined &&
                    saleModesCode.forEach((el: any) => {
                        if (
                            rowData["Kitchen_ticket"]
                                ?.customer_information_mode_sale
                                ?.customerInformationModeSaleCode !== undefined
                        ) {
                            for (let element of rowData["Kitchen_ticket"]
                                ?.customer_information_mode_sale
                                ?.customerInformationModeSaleCode) {
                                salesNamesCode.push(element.designation);
                                salesCode.push(element);
                            }
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    elt !== undefined &&
                                    !salesNamesCode.includes(elt.designation)
                                ) {
                                    salesCode.push(elt);
                                }
                            });
                        } else {
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    rowData["Kitchen_ticket"]
                                        ?.customer_information_mode_sale
                                        ?.customerInformationModeSaleCode ===
                                        undefined ||
                                    rowData["Kitchen_ticket"]
                                        ?.customer_information_mode_sale
                                        ?.customerInformationModeSaleCode === 0
                                ) {
                                    elt !== undefined && salesCode.push(elt);
                                }
                            });
                        }
                    });
                salesCode = [...new Set(salesCode)];
                for (let sale of rowDataSalesCode) {
                    salesCode = salesCode.filter(
                        (o) => o.designation !== sale.designation
                    );
                }

                const saleModesTickets: any[] = response.data.map(
                    (saleMode: any) => {
                        return {
                            saleSupports: saleMode.support_vente.map(
                                // eslint-disable-next-line array-callback-return
                                (el: any) => {
                                    if (el === "KIOSK")
                                        return {
                                            designation:
                                                saleMode["Mode of sale"],
                                            state: false,
                                        };
                                }
                            ),
                        };
                    }
                );
                let rowDataSalesModesTickets: any[] = [];

                if (
                    rowData["customer_ticket"]?.customerTicketSalesModes !==
                    undefined
                ) {
                    for (let element of rowData["customer_ticket"]
                        ?.customerTicketSalesModes) {
                        rowDataSalesModesTickets.push(element);
                    }
                }
                saleModesTickets !== undefined &&
                    saleModesTickets.forEach((el: any) => {
                        el.saleSupports.forEach((elt: any) => {
                            if (elt !== undefined) {
                                rowDataSalesModesTickets = rowDataSalesModesTickets.filter(
                                    (o) => o.designation !== elt.designation
                                );
                            }
                        });
                    });

                let salesModesTicketsData: any[] = [];
                let salesModesTickets: any[] = [];
                saleModesTickets !== undefined &&
                    saleModesTickets.forEach((el: any) => {
                        if (
                            rowData["customer_ticket"]
                                ?.customerTicketSalesModes === undefined ||
                            rowData["customer_ticket"]?.customerTicketSalesModes
                                .length === 0
                        ) {
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    rowData["customer_ticket"]
                                        ?.customerTicketSalesModes ===
                                        undefined ||
                                    rowData["customer_ticket"]
                                        ?.customerTicketSalesModes.length === 0
                                ) {
                                    elt !== undefined &&
                                        salesModesTicketsData.push(elt);
                                }
                            });
                        } else {
                            for (let element of rowData["customer_ticket"]
                                ?.customerTicketSalesModes) {
                                salesModesTickets.push(element.designation);
                                salesModesTicketsData.push(element);
                            }
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    elt !== undefined &&
                                    !salesModesTickets.includes(elt.designation)
                                ) {
                                    salesModesTicketsData.push(elt);
                                }
                            });
                        }
                    });
                salesModesTicketsData = [...new Set(salesModesTicketsData)];
                for (let sale of rowDataSalesModesTickets) {
                    salesModesTicketsData = salesModesTicketsData.filter(
                        (o) => o.designation !== sale.designation
                    );
                }

                setSalesModes({
                    salesSalesCustomerTicketSalesModes: salesModesTicketsData,
                    customerInformationCompany: salesCompany,
                    customerInformationCity: salesCity,
                    customerInformationName: salesNames,
                    customerInformationNumTel: salesNumTel,
                    customerInformationAdress: salesAdress,
                    customerInformationCode: salesCode,
                    salesCustomerAddressModeSale: salesSalesCustomerAddressModeSale,
                    customerTicket: salesCustomer_ticket,
                    kitchenTicket: salesKitchen_ticket,
                });
            });
    }, [franchiseID, shopId, userID, rowData]);

    React.useEffect(() => {
        fetchModeOfSale();
    }, [fetchModeOfSale]);

    const tabs: TabsMenuType[] = [
        {
            title: t("General"),
            customClassName: "afa_sid_generalPopup",
            content: (
                <GeneralPanelSetting rowData={rowData} oneShop={oneShop} />
            ),
        },
        {
            title: t("Customer ticket"),
            customClassName: "afa_sid_ticketClientPopup",
            content: <ClientPanelSetting rowData={rowData} oneShop={oneShop} />,
        },
        {
            title: t("Kitchen ticket"),
            customClassName: "afa_sid_kitchenTicketPopup",
            content: (
                <KitchenPanelSetting rowData={rowData} oneShop={oneShop} />
            ),
        },
        {
            title: t("Models tickets"),
            customClassName: "afa_sid_ticketTemplatePopup",
            content: <ModeleTicket rowData={rowData} oneShop={oneShop} />,
        },
        {
            title: t("Various"),
            customClassName: "afa_sid_variousPopup",
            content: <Divers rowData={rowData} oneShop={oneShop} />,
        },
    ];

    function toggleNav(tab: number) {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
        if (isSubMenuCollapsed === true) {
            setIsSubMenuCollapsed(false);
        }
    }

    function toggleSubNav(tab: number) {
        if (activeSubTab !== tab) {
            setActiveSubTab(tab);
        }
    }

    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "220px 1fr",
                fontSize: "15px",
            }}
        >
            <Nav
                tabs
                className="d-flex flex-column"
                style={{ backgroundColor: mainColor, height: "90.2%" }}
            >
                {tabs.map((element, index) => {
                    return (
                        <NavItem
                            key={index}
                            className={classNames(
                                `pb-1 menu-wrapper-nav-link__clz ${element.customClassName}`,
                                {
                                    "active menu-wrapper-items-nav-link-active__clz":
                                        activeTab === index,
                                    "not-allowed-icon__clz":
                                        Object.keys(rowData)[0] !== undefined
                                            ? (rowData.tickets.value === "02" &&
                                                  element.title ===
                                                      t("Customer ticket")) ||
                                              (rowData.tickets.value === "03" &&
                                                  element.title ===
                                                      t("Customer ticket"))
                                            : false,
                                    "not-allowed-customer-icon__clz":
                                        Object.keys(rowData)[0] !== undefined
                                            ? (rowData.tickets.value === "01" &&
                                                  element.title ===
                                                      t("Kitchen ticket")) ||
                                              (rowData.tickets.value === "01" &&
                                                  element.title ===
                                                      t("Models tickets"))
                                            : false,
                                }
                            )}
                        >
                            <NavLink
                                className={classNames(
                                    "border-0 menu-wrapper-nav-link__clz",
                                    {
                                        "active menu-wrapper-items-nav-link-active__clz":
                                            activeTab === index,
                                    }
                                )}
                                onClick={() => {
                                    toggleNav(index);

                                    if (element.title === t("Configuration")) {
                                        setIsSubMenuCollapsed(
                                            !isSubMenuCollapsed
                                        );
                                    }
                                }}
                            >
                                <span className="px-2">{element.title}</span>
                            </NavLink>
                            {element.title === t("Configuration") &&
                            isSubMenuCollapsed
                                ? element.subMenu?.map(
                                      (
                                          item: {
                                              title: string;
                                              content: React.ReactNode;
                                          },
                                          subMenuIndex: number
                                      ): JSX.Element => (
                                          <NavLink
                                              disabled={
                                                  item.title ===
                                                  t("Customer account")
                                              }
                                              key={subMenuIndex}
                                              className={classNames(
                                                  "border-0 text-nowrap pl-5 menu-wrapper-nav-link__clz",
                                                  {
                                                      "active options-nav-link-active__clz":
                                                          activeSubTab ===
                                                          subMenuIndex,
                                                  }
                                              )}
                                              onClick={() => {
                                                  toggleSubNav(subMenuIndex);
                                              }}
                                          >
                                              <span className="px-2">
                                                  {item.title}
                                              </span>
                                          </NavLink>
                                      )
                                  )
                                : null}
                        </NavItem>
                    );
                })}
            </Nav>
            <TabContent activeTab={activeTab}>
                {tabs.map((element, index) => {
                    return element.subMenu !== undefined &&
                        isSubMenuCollapsed ? (
                        element.subMenu?.map(({ content }, subMenuIndex) => {
                            return (
                                <TabPane
                                    tabId={index}
                                    className={classNames({
                                        "d-none": subMenuIndex !== activeSubTab,
                                    })}
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                        overflow: "hidden",
                                    }}
                                    key={subMenuIndex}
                                >
                                    {content}
                                </TabPane>
                            );
                        })
                    ) : (
                        <TabPane
                            tabId={index}
                            style={{
                                height: "100%",
                                width: "100%",
                                overflow: "hidden",
                            }}
                            key={index}
                        >
                            {element.content}
                        </TabPane>
                    );
                })}
            </TabContent>
        </div>
    );
}
