import KioskSvgIcon from "@components/Common/SvgIcons/KioskSvgIcon";

export type CardMenuType = {
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    description?: string;
    title: string;
    number?: string;
    path: string;
};

export const CARD_MENU_LIST: CardMenuType[] = [
    {
        title: "KIOSK",
        number: "0",
        Icon: KioskSvgIcon,
        description:
            "The configuration of a kiosk involves customizing the user interface, setting up options such as payment methods, ordering modes, and configuring associated devices.",
        path: "/kiosk",
    },
    // {
    //     title: "C&C",
    //     number: "0",
    //     Icon: CcSvgIcon,
    //     description:
    //         "This module allows configuration of the C&C application and website. This includes setting up different options such as payment methods, sales methods and order taking options.",
    //     path: "/c&c",
    // },
    // {
    //     title: "KDS",
    //     number: "0",
    //     Icon: KdsSvgIcon,
    //     description:
    //         "The KDS (Kitchen Display System) configuration module allows restaurateurs to customize the kitchen display options, including order routing, display settings, and notifications. This customization caters to their specific needs in kitchen operations and workflow, enhancing overall efficiency.",
    //     path: "/kds",
    // },
    // {
    //     title: "SCO",
    //     number: "0",
    //     Icon: ScoSvgIcon,
    //     description:
    //         "The SCO configuration module enables the personalized customization of self-checkout system options, such as accepted payment methods and security settings, providing merchants with full flexibility to tailor the self-service experience to their specific needs.",
    //     path: "/sco",
    // },
    // {
    //     title: "ORB",
    //     number: "0",
    //     Icon: OrbSvgIcon,
    //     description:
    //         "The ORB client call display configuration module allows for customization of display and notification options, providing users with the flexibility to configure the device according to their specific needs and enhance the restaurant service experience.",
    //     path: "/orb",
    // },
];
