import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    CheckboxIcon,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";
import { uuid } from "uuidv4";

import { store } from "../../../../components/VerticalLayout/store";
import {
    store as logoStore,
    setNumberActive,
    setNumberArchived,
    setIsValidateClicked,
    setIsLoading,
    setKey,
} from "../store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationDeleteMessageType = {
    setIsModalConfirmationOpened: Function;
    isModalConfirmationOpened: boolean;
    setDataIsUpdated: Function;
    selectedRows: any;
};

export function ConfirmationActiveLogo({
    setIsModalConfirmationOpened,
    isModalConfirmationOpened,
    setDataIsUpdated,
    selectedRows,
}: ConfirmationDeleteMessageType) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const { numberActive, numberArchived, shopsLogoData } = useSnapshot(
        logoStore
    );
    const uuidUser = localStorage.getItem("uuidUser");

    function ArchivedLogo(e: any) {
        let idsAssociated = ["0"];
        if (shopsLogoData !== undefined) {
            shopsLogoData.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    if (el.Id === e.Id) idsAssociated.push(el.shopId);
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];

        return idsAssociated;
    }

    async function activeLogo(isValidation: boolean) {
        setIsLoading(true);
        setIsValidateClicked(false);
        toast.dismiss();
        let localData: any[] = [];
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/logo`;

        Object.values(selectedRows).forEach(async (element: any) => {
            const id = element.Id;
            let shopsArchived = isValidation ? ArchivedLogo(element) : ["0"];
            shopsArchived.forEach((el: any) => {
                let dataLogo = {
                    userId: userID,
                    shopId: [el],
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,

                    data: {
                        [id]: {
                            designation: element[t("Designation")],
                            support_de_vente: element[t("Sales support")],
                            urlImage: element[t("Image")],
                            shopId: el,
                            tag: element.tag,
                            img: element.img,
                            isActive: el === "0" ? true : false,
                            isArchived: false,
                        },
                    },
                };
                const data = new FormData();
                data.append("dataLogo", JSON.stringify(dataLogo));
                data.append("image", "");
                const requestOptions = {
                    method: "POST",

                    body: data,
                };

                localData.push(
                    fetch(apiUrlAdd, requestOptions).then((response) =>
                        response.json()
                    )
                );

                // let dataLogo = {
                //     userId: userID,
                //     shopId: isValidation ? shopsArchived : ["0"],
                //     franchiseId: franchiseID,
                //     operatorId: operatorID,
                //     uuidUser: uuidUser,

                //     data: {
                //         [id]: {
                //             designation: element[t("Designation")],
                //             support_de_vente: element[t("Sales support")],
                //             urlImage: element[t("Image")],
                //             shopId: element.shopId,
                //             tag: element.tag,
                //             img: element.img,
                //             isActive: element.isActive,
                //             isArchived: false,
                //         },
                //     },
                // };
                // const data = new FormData();
                // data.append("dataLogo", JSON.stringify(dataLogo));
                // data.append("image", "");
                // const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/logo`;
                // const requestOptions = {
                //     method: "POST",

                //     body: data,
                // };

                // localData.push(
                //     fetch(apiUrlAdd, requestOptions).then((response) =>
                //         response.json()
                //     )
                // );
            });
            try {
                mutate(
                    apiUrlAdd,
                    await Promise.all(localData).then((result: any) => {
                        let errors: boolean[] = result.map(
                            (el: any) => el.error
                        );
                        if (!errors.includes(true)) {
                            toast.success(
                                `${t("logo has been successfully activated")!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setIsLoading(false);
                            setKey(uuid());
                            setDataIsUpdated!(true);
                            setIsValidateClicked(true);
                        } else {
                            throw Error(result.message);
                        }
                    })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsLoading(false);
            }
        });
    }

    return (
        <StyledModal toggle={true} isOpen={isModalConfirmationOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() =>
                    setIsModalConfirmationOpened(!isModalConfirmationOpened)
                }
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {t(
                        "Attention! the logo is archived at store level. Do you want to activate it in shops too?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        activeLogo(false);
                        setIsModalConfirmationOpened(
                            !isModalConfirmationOpened
                        );
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        activeLogo(true);
                        setIsModalConfirmationOpened(
                            !isModalConfirmationOpened
                        );
                        setNumberActive(
                            selectedRows.length !== 0
                                ? numberActive + selectedRows.length
                                : numberActive + 1
                        );
                        setNumberArchived(
                            selectedRows.length !== 0
                                ? numberArchived - selectedRows.length
                                : numberArchived - 1
                        );
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
