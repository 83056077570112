import React from "react";
import ReactSwitch from "react-switch";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import {
    setprintdatetimedeliverymodesaleItemsLines,
    setprintdatetimedeliverymodesaleMethodPayment,
    setprintdatetimedeliverymodesaleMethodPaymentTva,
    setprintdatetimedeliverymodesaleProviderName,
    setprintdatetimedeliverymodesalePrintNotes,
    setprintdatetimedeliverymodesaleWithoutPrice,
    storeCustomerTicket,
} from "./Hook/store";
import "../index.css";
import classnames from "classnames";

export default function PrintProvider() {
    const { t } = useTranslation();
    const { printDateTimeDeliveryModesaleItemsLines } = useSnapshot(
        storeCustomerTicket
    );
    const { printDateTimeDeliveryModesaleMethodPayment } = useSnapshot(
        storeCustomerTicket
    );
    const { printDateTimeDeliveryModesaleMethodPaymentTva } = useSnapshot(
        storeCustomerTicket
    );
    const { printDateTimeDeliveryModesaleProviderName } = useSnapshot(
        storeCustomerTicket
    );
    const { printDateTimeDeliveryModesalePrintNotes } = useSnapshot(
        storeCustomerTicket
    );
    const { printDateTimeDeliveryModesaleWithoutPrice } = useSnapshot(
        storeCustomerTicket
    );
    return (
        <div>
            <div className="d-flex  align-items-center justify-content-between">
                <div>{t("Print provider name")}</div>
                <div className="p-1 pr-4">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={printDateTimeDeliveryModesaleProviderName}
                        onChange={(e: any) => {
                            setprintdatetimedeliverymodesaleProviderName(
                                !printDateTimeDeliveryModesaleProviderName
                            );
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                        className="afa_icn_printTheProviderName"
                    />
                </div>
            </div>
            <div className="d-flex  align-items-center justify-content-between">
                <div>{t("Print components without prices")}</div>
                <div className="p-1 pr-4">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={printDateTimeDeliveryModesaleWithoutPrice}
                        onChange={(e: any) => {
                            setprintdatetimedeliverymodesaleWithoutPrice(
                                !printDateTimeDeliveryModesaleWithoutPrice
                            );
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                        className="afa_icn_printComponentsWithoutPrice"
                    />
                </div>
            </div>
            <div className="d-flex  align-items-center justify-content-between">
                <div>{t("Print Notes")}</div>
                <div className="p-1 pr-4">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={printDateTimeDeliveryModesalePrintNotes}
                        onChange={(e: any) => {
                            setprintdatetimedeliverymodesalePrintNotes(
                                !printDateTimeDeliveryModesalePrintNotes
                            );
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                        className="afa_icn_printNotes"
                    />
                </div>
            </div>
            <div className="d-flex  align-items-center justify-content-between">
                <div>{t("Print items on two lines")}</div>
                <div className="p-1 pr-4">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={printDateTimeDeliveryModesaleItemsLines}
                        onChange={(e: any) => {
                            setprintdatetimedeliverymodesaleItemsLines(
                                !printDateTimeDeliveryModesaleItemsLines
                            );
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                        className="afa_icn_printItemsOnTwoLines"
                    />
                </div>
            </div>
            <div className="d-flex  align-items-center justify-content-between">
                <div>
                    {t(
                        "Print the Payment method below the total amount of the order"
                    )}
                </div>

                <div className="p-1 pr-4">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        className={`${classnames(
                            "afa_icn_printTheMethodPaymentBelowTotalAmountOrder",
                            {
                                "not-allowed-icon__clz": printDateTimeDeliveryModesaleMethodPaymentTva,
                            }
                        )}`}
                        checked={printDateTimeDeliveryModesaleMethodPayment}
                        onChange={(e: any) => {
                            setprintdatetimedeliverymodesaleMethodPayment(
                                !printDateTimeDeliveryModesaleMethodPayment
                            );
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                    />
                </div>
            </div>
            <div className="d-flex  align-items-center justify-content-between">
                <div>
                    {t("Print the Payment method below the VAT of the order")}
                </div>
                <div className="p-1 pr-4">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        className={`${classnames(
                            "afa_icn_printTheModePaymentBelowTheVATOrder",
                            {
                                "not-allowed-icon__clz": printDateTimeDeliveryModesaleMethodPayment,
                            }
                        )}`}
                        checked={printDateTimeDeliveryModesaleMethodPaymentTva}
                        onChange={(e: any) => {
                            setprintdatetimedeliverymodesaleMethodPaymentTva(
                                !printDateTimeDeliveryModesaleMethodPaymentTva
                            );
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                    />
                </div>
            </div>
        </div>
    );
}


