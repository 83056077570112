import styled from "styled-components";

type ContainerWrapperPropsType = {
    isTopBannerActive: boolean;
    isHeaderActive: boolean;
    isActionButtonsActive: boolean;
};

export const ContainerWrapper = styled.div<ContainerWrapperPropsType>`
    display: grid;
    background-color: transparent;
    position: relative;
    grid-template-rows: ${() =>
        "100px min-content 1fr min-content min-content min-content"};

    height: 100%;
`;
