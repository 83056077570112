import { proxy } from "valtio";

type StoreType = {
    numberArchived: number;
    numberActive: number;
    isAssociateModalOpened: boolean;
    localShop: any[];
    isAssociateModalClosed: boolean;
    numberActiveMultiShop: number;
    numberArchiveMultiShop: number;
    idsLocalShop: string[];
    isAssociatedPosEditor: boolean;
    selectedRows: any[];
    ids: number;
    shopsPosEditorData: any;
    posEditorData: any;
    rowsShop: any[];
    archivedPosEditorFranchise: any;
    associatedPosEditor: any[];
    posEditorFranchise: any;
    activePosEditor: any[];
    globalDataIsUpdated: boolean | number;
    dataArchive: any;
    dataFranchiseArchive: any;
    isArchived: boolean;
    message: string;
    posEditor: string;
    selectedRowsPosEditor: any;
    isAssignment: boolean;
    selectedPosEditor: any[];
    isConsultModalOpened: boolean;
    isActionsClicked: boolean;
    isEdited: boolean;
    actions: Function;
    validationAction: any;
    modify: boolean;
    languageData: any;
};

export const store = proxy<StoreType>({
    numberArchived: 0,
    numberActive: 0,
    isAssociateModalOpened: false,
    localShop: [],
    isAssociateModalClosed: false,
    numberActiveMultiShop: 0,
    numberArchiveMultiShop: 0,
    idsLocalShop: [],
    isAssociatedPosEditor: false,
    selectedRows: [],
    ids: 0,
    shopsPosEditorData: [],
    posEditorData: [],
    rowsShop: [],
    archivedPosEditorFranchise: [],
    associatedPosEditor: [],
    posEditorFranchise: [],
    activePosEditor: [],
    globalDataIsUpdated: false,
    dataArchive: [],
    dataFranchiseArchive: [],
    isArchived: false,
    message: "",
    posEditor: "",
    selectedRowsPosEditor: [],
    isAssignment: false,
    selectedPosEditor: [],
    isConsultModalOpened: false,
    isActionsClicked: false,
    isEdited: false,
    actions: () => {},
    validationAction: {},
    modify: false,
    languageData: [],
});
export function setLanguageData(languageData: any): void {
    store.languageData = languageData;
}
export function setModify(value: boolean): void {
    store.modify = value;
}

export function setNumberArchived(number: number): void {
    store.numberArchived = number;
}

export function setNumberActive(number: number): void {
    store.numberActive = number;
}
export function setSelectedPosEditor(selectedPosEditor: any[]): void {
    store.selectedPosEditor = selectedPosEditor;
}

export function setIsAssociateModalOpened(isModalOpened: boolean): void {
    store.isAssociateModalOpened = isModalOpened;
}
export function setIsConsultModalOpened(isConsultModalOpened: boolean): void {
    store.isConsultModalOpened = isConsultModalOpened;
}
export function setLocalShop(shops: any[]): void {
    store.localShop = shops;
}
export function setIsAssociateModalClosed(
    isAssociateModalClosed: boolean
): void {
    store.isAssociateModalClosed = isAssociateModalClosed;
}

export function setNumberActiveMultiShop(number: number): void {
    store.numberActiveMultiShop = number;
}
export function setNumberArchiveMultiShop(number: number): void {
    store.numberArchiveMultiShop = number;
}

export function setIdsLocalShop(ids: any[]): void {
    store.idsLocalShop = ids;
}

export function setIsAssociatedPosEditor(isAssociatedPosEditor: boolean): void {
    store.isAssociatedPosEditor = isAssociatedPosEditor;
}
export function setSelectedRow(selectedRows: any[]): void {
    store.selectedRows = selectedRows;
}

export function setIds(id: number): void {
    store.ids = id;
}

export function setShopsPosEditorData(shopsPosEditorData: any): void {
    store.shopsPosEditorData = shopsPosEditorData;
}

export function setPosEditorData(posEditorData: any): void {
    store.posEditorData = posEditorData;
}

export function setRowsShop(rowsShop: any[]): void {
    store.rowsShop = rowsShop;
}
export function setArchivedPosEditorFranchise(
    archivedPosEditorFranchise: any[]
): void {
    store.archivedPosEditorFranchise = archivedPosEditorFranchise;
}
export function setAssociatedPosEditor(associatedPosEditor: any[]): void {
    store.associatedPosEditor = associatedPosEditor;
}

export function setPosEditorFranchise(posEditorFranchise: any[]): void {
    store.posEditorFranchise = posEditorFranchise;
}

export function setActivePosEditor(activePosEditor: any): void {
    let index = store.activePosEditor.findIndex(
        (element: any) => element.Id === activePosEditor.Id
    );
    index !== -1
        ? (store.activePosEditor[index] = activePosEditor)
        : store.activePosEditor.push(activePosEditor);
}

export function setGlobalDataIsUpdated(
    globalDataIsUpdated: boolean | number
): void {
    store.globalDataIsUpdated = globalDataIsUpdated;
}

export function setDataArchive(dataArchive: any[]): void {
    store.dataArchive = dataArchive;
}
export function setDataFranchiseArchive(dataFranchiseArchive: any[]): void {
    store.dataFranchiseArchive = dataFranchiseArchive;
}

export function setIsArchived(isArchived: boolean): void {
    store.isArchived = isArchived;
}

export function setMessage(message: string): void {
    store.message = message;
}

export function setPosEditor(posEditor: any): void {
    store.posEditor = posEditor;
}

export function setSelectedRowsPosEditor(selectedRowsPosEditor: any[]): void {
    store.selectedRowsPosEditor = selectedRowsPosEditor;
}

export function setIsAssignment(isAssignment: boolean): void {
    store.isAssignment = isAssignment;
}

export function setIsEdited(isEdited: boolean): any {
    store.isEdited = isEdited;
}

export function setIsActionsClicked(isActionsClicked: boolean): any {
    store.isActionsClicked = isActionsClicked;
}

export function setValidationAction(validationAction: any): any {
    store.validationAction = validationAction;
}

export function setActions(actions: any): any {
    store.actions = actions;
}

export default store;
