import React from "react";

import { t } from "i18next";

export function AbandonArticle(props: any): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            viewBox="0 0 309 75"
        >
            <g
                id="Groupe_7"
                data-name="Groupe 7"
                transform="translate(2332 4006)"
            >
                <g
                    id="Groupe_2"
                    data-name="Groupe 2"
                    transform="translate(500 -3791)"
                >
                    <g
                        id="Rectangle_1"
                        data-name="Rectangle 1"
                        transform="translate(-2832 -215)"
                        fill="#fff"
                        stroke="#8b8b8b"
                        stroke-width="2"
                    >
                        <rect width="309" height="67" rx="9" stroke="none" />
                        <rect
                            x="1"
                            y="1"
                            width="307"
                            height="67"
                            rx="8"
                            fill="none"
                        />
                    </g>
                    <text
                        id="Abandon_article"
                        data-name="Abandon article"
                        transform="translate(-2677 -173)"
                        fill="#707070"
                        font-size="24"
                        font-family="HelveticaNeue-Medium, Helvetica Neue"
                        font-weight="500"
                    >
                        <tspan x="-112.044" y="0">
                            {t("Abandon article", {
                                lng: props.selectedLanguage,
                            })}
                        </tspan>
                    </text>
                </g>
            </g>
        </svg>
    );
}
