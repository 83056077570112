import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import { designStore, generalConfigStore } from "@store";

import { getImageContentById } from "@helpers/general";

import { ConfigOptionsWrapper } from "./ConfigOptionsWrapper";
import "./ConfigOptionsWrapper.css";

export function BasketWrapper(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: { template, files: images },
        isShopCartDetailsActive,
    } = useSnapshot(generalConfigStore);
    const { shopCardMessage, selectedLanguage } = useSnapshot(designStore);
    const basketButton = ((template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).design as ActionsButtonsDesignType).items.filter(
        (item) => item.name === "Empty basket"
    )[0];

    const basketButtonContent = getImageContentById(
        basketButton.id as string,
        images,
        selectedLanguage
    );
    const imageSrc =
        basketButtonContent?.includes("http") ||
        basketButtonContent?.includes("https")
            ? basketButtonContent
            : `./images/${basketButtonContent}`;

    const largeImageId = images.filter((image) => image.key === "enlarge")[0]
        .id;

    const largeImageContent = getImageContentById(
        largeImageId,
        images,
        selectedLanguage
    );
    const imageEnlargeSrc =
        largeImageContent?.includes("http") ||
        largeImageContent?.includes("https")
            ? largeImageContent
            : `./images/${largeImageContent}`;
    return (
        <div
            className="d-flex flex-column"
            style={{
                position: "relative",
                zIndex: 1,
                width: "425px",
                background: "#ffffff",
            }}
        >
            <div
                style={{
                    display: "grid",

                    gridTemplateColumns: "0.4fr 0.2fr 0.4fr",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "425px",
                    height: "40px",
                    boxShadow: " 0px -5px 6px #2c2c2c15",
                    borderBottom: "1px solid rgba(112, 112, 112, 0.23)",
                }}
            >
                <div className="ml-1 d-flex flex-row" style={{ gap: "3px" }}>
                    <img
                        alt="basketButton"
                        src={imageSrc}
                        style={{
                            height: "27px",
                            width: "27px",
                        }}
                    />

                    <span
                        className="d-flex align-items-center"
                        style={{
                            font: "normal normal  10px/11px Segoe UI",
                        }}
                    >
                        {0} Article(s)
                    </span>
                </div>
                <div>
                    <img
                        alt="imageEnlargeSrc"
                        src={imageEnlargeSrc}
                        style={{
                            height: "40px",
                            width: "100px",
                            marginBottom: "-20px",
                        }}
                    />
                </div>

                <ConfigOptionsWrapper />
            </div>
            {isShopCartDetailsActive === true ? (
                <div className="footer-contents">
                    {t(shopCardMessage[selectedLanguage].message)}
                </div>
            ) : null}
        </div>
    );
}
