import { proxy } from "valtio";

type StoreType = {
    isAddModeOfSaleClicked: boolean;
    isModeOfSale: boolean;
    numberArchived: number;
    numberActive: number;
    isAssociateModalOpened: boolean;
    localShop: any[];
    isAssociateModalClosed: boolean;
    numberActiveMultiShop: number;
    numberArchiveMultiShop: number;
    isModeOfSaleAssociatedModifiedClosed: boolean;
    idsLocalShop: string[];
    isAssociatedModeOfSale: boolean;
    selectedRows: any[];
    isAssociatedClosed: boolean;
    ids: number;
    isModeOfSaleAssociatedArchivedClosed: boolean;
    isModified: boolean;
    isActivation: boolean;
    isModeOfSaleAssociatedModified: boolean;
    shopsDeviceData: any;
    deviceData: any;
    rowsShop: any[];
    deviceDataArchived: any;
    selectedDevice: any[];
    listFranchiseArchived: any[];
    activeDevises: any[];
    shopsSelectedRow: any;
    associatedDevises: any[];
    shopsNumberActive: number;
    shopsNumberArchived: number;
    archivedData: any;
    isArchiveModalOpened: boolean;
    isValidateClicked: boolean;
    isResetAllDevise: boolean;
    devise: string;
    selectedRowsDevise: any;
    dataShops: any;
    deviceDataData: any;
    isActionsClicked: boolean;
    isEdited: boolean;
    actions: Function;
    validationAction: any;
    isPlusIconClicked: boolean;
    modify: boolean;
    shopDataIsUpdated: boolean;
    key: any;
    isLoading: boolean;
};

export const store = proxy<StoreType>({
    key: 0,
    isLoading: false,
    isAddModeOfSaleClicked: false,
    isModeOfSale: false,
    numberArchived: 0,
    numberActive: 0,
    isAssociateModalOpened: false,
    localShop: [],
    isAssociateModalClosed: false,
    numberActiveMultiShop: 0,
    numberArchiveMultiShop: 0,
    isModeOfSaleAssociatedModifiedClosed: false,
    idsLocalShop: [],
    isAssociatedModeOfSale: false,
    selectedRows: [],
    isAssociatedClosed: false,
    ids: 0,
    isModeOfSaleAssociatedArchivedClosed: false,
    isModified: false,
    isActivation: false,
    isModeOfSaleAssociatedModified: false,
    deviceDataArchived: [],
    shopsDeviceData: [],
    deviceData: {},
    rowsShop: [],
    selectedDevice: [],
    listFranchiseArchived: [],
    activeDevises: [],
    shopsSelectedRow: [],
    associatedDevises: [],
    shopsNumberArchived: 0,
    shopsNumberActive: 0,
    archivedData: {},
    isArchiveModalOpened: false,
    isValidateClicked: false,
    isResetAllDevise: false,
    devise: "",
    selectedRowsDevise: [],
    dataShops: [],
    deviceDataData: [],
    isActionsClicked: false,
    isEdited: false,
    actions: () => {},
    validationAction: {},
    isPlusIconClicked: false,
    modify: false,
    shopDataIsUpdated: false,
});
export function setShopDataIsUpdated(value: boolean): void {
    store.shopDataIsUpdated = value;
}
export function setIsLoading(isLoading: boolean): void {
    store.isLoading = isLoading;
}

export function setKey(key: any): void {
    store.key = key;
}
export function setModify(value: boolean): void {
    store.modify = value;
}
export function setArchivedData(archivedData: boolean): any {
    store.archivedData = archivedData;
}
export function setIsArchiveModalOpened(isArchiveModalOpened: boolean): any {
    store.isArchiveModalOpened = isArchiveModalOpened;
}
export function setAssociatedDevises(associatedDevises: any): void {
    store.associatedDevises = associatedDevises;
}
export function setIsAddModeOfSaleClicked(
    isAddModeOfSaleClicked: boolean
): void {
    store.isAddModeOfSaleClicked = isAddModeOfSaleClicked;
}
export function setIsResetAllDevise(isResetAllDevise: boolean): any {
    store.isResetAllDevise = isResetAllDevise;
}
export function setIsModeOfSale(isModeOfSale: boolean): void {
    store.isModeOfSale = isModeOfSale;
}

export function setSelectedDevise(selectedDevice: any[]): any {
    store.selectedDevice = selectedDevice;
}
export function setNumberArchived(number: number): void {
    store.numberArchived = number;
}
export function setIsValidateClicked(isValidateClicked: boolean): void {
    store.isValidateClicked = isValidateClicked;
}

export function setNumberActive(number: number): void {
    store.numberActive = number;
}

export function setIsAssociateModalOpened(isModalOpened: boolean): void {
    store.isAssociateModalOpened = isModalOpened;
}
export function setLocalShop(shops: any[]): void {
    store.localShop = shops;
}
export function setIsAssociateModalClosed(
    isAssociateModalClosed: boolean
): void {
    store.isAssociateModalClosed = isAssociateModalClosed;
}

export function setNumberActiveMultiShop(number: number): void {
    store.numberActiveMultiShop = number;
}
export function setNumberArchiveMultiShop(number: number): void {
    store.numberArchiveMultiShop = number;
}

export function setIsModeOfSaleAssociatedModifiedClosed(
    isModeOfSaleAssociatedModifiedClosed: boolean
): void {
    store.isModeOfSaleAssociatedModifiedClosed = isModeOfSaleAssociatedModifiedClosed;
}
export function setIdsLocalShop(ids: any[]): void {
    store.idsLocalShop = ids;
}

export function setIsAssociatedModeOfSale(
    isAssociatedModeOfSale: boolean
): void {
    store.isAssociatedModeOfSale = isAssociatedModeOfSale;
}
export function setSelectedRow(selectedRows: any[]): void {
    store.selectedRows = selectedRows;
}

export function setIsAssociatedClosed(isAssociatedClosed: boolean): void {
    store.isAssociatedClosed = isAssociatedClosed;
}

export function setIds(id: number): void {
    store.ids = id;
}

export function setIsModeOfSaleAssociatedArchivedClosed(
    isModeOfSaleAssociatedArchivedClosed: boolean
): void {
    store.isModeOfSaleAssociatedArchivedClosed = isModeOfSaleAssociatedArchivedClosed;
}
export function setIsModified(isModified: boolean): void {
    store.isModified = isModified;
}
export function setIsActivation(isActivation: boolean): void {
    store.isActivation = isActivation;
}
export function setIsModeOfSaleAssociatedModified(
    isModeOfSaleAssociatedModified: boolean
): void {
    store.isModeOfSaleAssociatedModified = isModeOfSaleAssociatedModified;
}

export function setShopsDevicesData(shopsDeviceData: any): void {
    store.shopsDeviceData = shopsDeviceData;
}

export function setDeviceData(deviceData: any): void {
    store.deviceData = deviceData;
}
export function setDeviceDataArchive(deviceDataArchived: any): void {
    store.deviceDataArchived = deviceDataArchived;
}

export function setRowsShop(rowsShop: any[]): void {
    store.rowsShop = rowsShop;
}
export function setDataData(deviceDataData: any[]): void {
    store.deviceDataData = deviceDataData;
}
export function setListFranchiseArchived(listFranchiseArchived: any[]): void {
    store.listFranchiseArchived = listFranchiseArchived;
}
export function setActiveDevises(activePaymentMethod: any): any {
    let localIndex: number = store.activeDevises.findIndex((e: any) => {
        return e.id === activePaymentMethod.id;
    });
    if (localIndex === -1 || localIndex === undefined) {
        store.activeDevises.push(activePaymentMethod);
    } else {
        store.activeDevises[localIndex] = activePaymentMethod;
    }
}
export function setShopsSelectedRow(shopSelectedRow: any): any {
    store.shopsSelectedRow = shopSelectedRow;
}
export function setShopsNumberArchived(number: number): void {
    store.shopsNumberArchived = number;
}

export function setShopsNumberActive(number: number): void {
    store.shopsNumberActive = number;
}
export function setDevise(devise: any): void {
    store.devise = devise;
}
export function setSelectedRowsDevise(selectedRowsDevise: any): void {
    store.selectedRowsDevise = selectedRowsDevise;
}

export function setDataShops(dataShops: any): void {
    store.dataShops = dataShops;
}

export function setIsEdited(isEdited: boolean): any {
    store.isEdited = isEdited;
}

export function setIsActionsClicked(isActionsClicked: boolean): any {
    store.isActionsClicked = isActionsClicked;
}

export function setValidationAction(validationAction: any): any {
    store.validationAction = validationAction;
}

export function setActions(actions: any): any {
    store.actions = actions;
}
export function setIsPlusIconClicked(isPlusIconClicked: boolean): void {
    store.isPlusIconClicked = isPlusIconClicked;
}

export default store;
