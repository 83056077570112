import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";

import { store } from "../../../../components/VerticalLayout/store";
import {
    store as storeLogo,
    setIsHistoryModalOpened,
    setLogoData,
    setLocalShop,
    setIdsLocalShop,
    setSelectedLogo,
    setDataDesignationLogo,
    setDataFranchiseLogo,
    setNumberActive,
    setNumberArchived,
    setDesignationLogo,
    setListFranchiseArchived,
    setSalesSupports,
    setIsActionsClicked,
    setActions,
    setValidationAction,
    setIsEdited,
    setArchivedLogoFranchise,
} from "../store";

import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import { useCallbackPrompt } from "@hooks/useCallbackPrompt";
import { ConfirmationUpdatingDataMessageModal } from "@components/Common/ConfirmationUpdatingDataMessageModal";
import { CustomSideFilter } from "./CustomSideFilter";
import { CustomIcons } from "./CustomIcons";
import { DisplayName } from "./DisplayName";
import LogoForm from "./LogoForm";
import Dimensions from "./Dimensions";
import { ConfirmationActiveLogo } from "./ConfirmationActiveLogo";
import HistoryModal from "../HistoryModal";
import { ConfirmationModal } from "./ConfirmationModal";

import "./index.css";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type logosFranchisesType = {
    isLogoArchived: boolean;
    setIsLogoArchived: Function;
};

export default function LogoFranchise({
    isLogoArchived,
    setIsLogoArchived,
}: logosFranchisesType) {
    const { t } = useTranslation();
    const { oneShop, userID, franchiseID, shopID } = useSnapshot(store);

    const {
        isHistoryModalOpened,
        isUpdateDataActive,
        isConfirmationModal,
        isActionsClicked,
        isEdited,
        actions,
        validationAction,
        key,
    } = useSnapshot(storeLogo);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isEdited
    );
    const didMountRef = React.useRef(false);
    const [, setIsShopSelected] = React.useState<boolean>(false);
    const [data, setData] = React.useState<any>({});
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [isConsult, setIsConsult] = React.useState<boolean>(false);
    const [isAddNewLogoButtonClicked, setIsAddNewLogoButtonClicked] = useState(
        false
    );
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [isAddLogoClicked, setIsAddLogoClicked] = React.useState<boolean>(
        false
    );

    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);

    const [
        isModalConfirmationOpened,
        setIsModalConfirmationOpened,
    ] = React.useState<boolean>(false);

    const [logo, setLogo] = useState<any>({});
    const [shopId, setShopId] = React.useState<string>(oneShop ? shopID : "0");
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [dataState, setDataState] = useState<any>({});

    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    const urlArchive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/logo?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=archived`;

    const urlActive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/logo?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=activated`;
    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };
    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: oneShop ? 9 : 10,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    isAddLogoClicked={isAddLogoClicked}
                    setIsAddLogoClicked={setIsAddLogoClicked}
                    selectedRowData={e}
                    setSelectedRows={setSelectedRows}
                    userData={{
                        userId: userID,
                        shopId: shopId,
                        franchiseId: franchiseID,
                    }}
                    setShopId={setShopId}
                    setLogo={setLogo}
                    setDataIsUpdated={setDataIsUpdated}
                    setIsModalConfirmationOpened={setIsModalConfirmationOpened}
                    isModalConfirmationOpened={isModalConfirmationOpened}
                    isLogoArchived={isLogoArchived}
                    setLocalFilterActive={setLocalFilterActive}
                    setIsConsult={setIsConsult}
                    setDataState={setDataState}
                />
            ),
            disableFilter: false,
        },
        {
            indexOFColumn: 1,
            columnName: t("Picture"),
            customJsx: (e: any) => <DisplayName e={e} />,
            filterName: t("Picture"),
            canFilter: true,
        },
        {
            indexOFColumn: 2,
            columnName: t("Dimensions"),
            customJsx: (e: any) => <Dimensions e={e} />,

            filterName: t("Dimensions"),
            canFilter: true,
        },
    ];
    const getSaleSupport = React.useCallback(async () => {
        await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&franchiseId=${franchiseID}&type=activated`,
            {
                method: "GET",
                headers: { "Accept-Language": i18nextLng },
            }
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    let shopSalesSupport: any[] = [];
                    let LocalSaleSupport: any[] = [];

                    result.data.forEach((el: any) => {
                        shopSalesSupport = el.subRows.map(
                            (element: any) => element[t("Sale support")]
                        );
                        LocalSaleSupport.push({
                            id: el.shopId,
                            salesSupport: shopSalesSupport,
                        });
                    });
                    setSalesSupports(LocalSaleSupport);
                },
                (error) => {
                    console.log(error);
                }
            );
    }, [franchiseID, userID, t, i18nextLng]);

    React.useEffect(() => {
        if (isAddLogoClicked) {
            getSaleSupport();
        }
    }, [getSaleSupport, isAddLogoClicked]);

    React.useEffect(() => {
        if (filterActive) setIsAddLogoClicked(false);
    }, [filterActive]);

    React.useEffect(() => {
        let localShop: string[] = [];
        let idsLocalShop: string[] = [];

        Object.values(selectedRows).forEach((el: any) => {
            if (el[t("Shop")] !== "" && el[t("Shop")] !== undefined)
                localShop.push(el.shopId);

            idsLocalShop.push(el.Id);
        });

        let array: any = [];

        Object.values(selectedRows).forEach((element: any) => {
            array.push({ [element.shopId]: element.Id });
        });

        setSelectedLogo(array);
        setIdsLocalShop(idsLocalShop);
        setLocalShop(localShop);
        setIsShopSelected(localShop.length !== 0);

        setNumberActive(
            data.othersData !== undefined ? data.othersData.activated : 0
        );
        setNumberArchived(
            data.othersData !== undefined ? data.othersData.archived : 0
        );
    }, [selectedRows, data?.othersData, t]);

    useEffect(() => {
        setLogoData(data.data !== undefined ? data.data : []);

        setDataDesignationLogo(
            data?.othersData !== undefined
                ? data?.othersData?.allData?.othersData.dataDesignation
                : []
        );

        setDataFranchiseLogo(
            data?.othersData?.allData !== undefined
                ? data?.othersData?.allData?.data
                : []
        );
        setDesignationLogo(
            data?.othersData !== undefined
                ? data?.othersData?.dataDesignation
                : []
        );
        setListFranchiseArchived(
            data?.othersData?.allData !== undefined
                ? data?.othersData?.allData.othersData.listArchived
                : []
        );
        setArchivedLogoFranchise(data);
    }, [data, data?.othersData]);
    useEffect(() => {
        if (isUpdateDataActive) {
            setDataIsUpdated(true);
        }
        getSaleSupport();
        // eslint-disable-next-line
    }, [isUpdateDataActive]);

    React.useEffect(() => {
        setActions(confirmNavigation);
        setIsActionsClicked(showPrompt as boolean);
        setValidationAction(cancelNavigation);
    }, [showPrompt, confirmNavigation, cancelNavigation]);

    React.useEffect(() => {
        setIsAddLogoClicked(false);
        setIsEdited(false);
        if (didMountRef.current) {
            setDataIsUpdated(true);
        } else {
            didMountRef.current = true;
        }
    }, [i18nextLng]);

    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: oneShop ? "650px" : "600px",
                    gridTemplateColumns: isAddLogoClicked ? "2fr 1fr " : "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    key={key}
                    url={isLogoArchived ? urlArchive : urlActive}
                    canExpand={false}
                    canSelect
                    setData={setData}
                    customSelect
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    actionColumn={ActionColumn}
                    setSelectedRows={setSelectedRows}
                    canMovedCheckboxLeftOnExpand
                    customJsxSideFilterButton={
                        <CustomSideFilter
                            setLogo={setLogo}
                            setShopId={setShopId}
                            selectedRows={selectedRows}
                            logo={logo}
                            isEdited={isEdited}
                            setDataIsUpdated={setDataIsUpdated}
                            setIsInInitializationModalOpened={
                                setIsInInitializationModalOpened
                            }
                            setIsModalConfirmationOpened={
                                setIsModalConfirmationOpened
                            }
                            setLocalFilterActive={setLocalFilterActive}
                            isModalConfirmationOpened={
                                isModalConfirmationOpened
                            }
                            isAddLogoClicked={isAddLogoClicked}
                            setIsAddLogoClicked={setIsAddLogoClicked}
                            setIsLogoArchived={setIsLogoArchived}
                            isLogoArchived={isLogoArchived}
                        />
                    }
                    showGlobalFilter
                    showFilter
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="Logo"
                />
                {isAddLogoClicked ? (
                    <LogoForm
                        isAddLogoClicked={isAddLogoClicked}
                        setIsAddLogoClicked={setIsAddLogoClicked!}
                        setDataIsUpdated={setDataIsUpdated}
                        rowData={logo}
                        setLogoData={setLogo}
                        setLocalFilterActive={setLocalFilterActive}
                        isAddNewLogoButtonClicked={isAddNewLogoButtonClicked}
                        setIsConsult={setIsConsult}
                        isConsult={isConsult}
                        dataState={dataState}
                    />
                ) : null}
            </div>
            <ConfirmationActiveLogo
                setIsModalConfirmationOpened={setIsModalConfirmationOpened}
                isModalConfirmationOpened={isModalConfirmationOpened}
                setDataIsUpdated={setDataIsUpdated}
                selectedRows={selectedRows}
            />
            {isHistoryModalOpened ? (
                <HistoryModal
                    ids={selectedRows}
                    setIsModalOpened={setIsHistoryModalOpened}
                    isModalOpened={isHistoryModalOpened}
                />
            ) : null}
            {isActionsClicked === true ? (
                <ConfirmationUpdatingDataMessageModal
                    isModalOpened={isActionsClicked}
                    setIsModalOpened={setIsActionsClicked}
                    validationAction={validationAction}
                    actions={actions}
                    setIsEdited={setIsEdited}
                />
            ) : null}
            {isConfirmationModal ? <ConfirmationModal /> : null}
            {isInInitializationModalOpened === true ? (
                <ConfirmationInitializationMessage
                    isModalOpened={isInInitializationModalOpened}
                    setIsModalOpened={setIsInInitializationModalOpened}
                    setIsClicked={setIsAddNewLogoButtonClicked}
                    isClicked={isAddNewLogoButtonClicked}
                    setIsEdited={setIsEdited}
                />
            ) : null}
        </React.Fragment>
    );
}
