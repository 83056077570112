import React from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classNames from "classnames";
import styled from "styled-components";
import { uid } from "react-uid";
import { useSnapshot } from "valtio";

import { setActions, setIsActionsClicked, setIsEdited, store } from "./store";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

export type TabsType = {
    title: any;
    content: any;
    disabled?: boolean;
};

export type PropsType = { tabs: TabsType[] };

export function StyledTabs({ tabs }: PropsType) {
    const { isEdited, isActionsClicked } = useSnapshot(store);

    const [activeTab, setActiveTab] = React.useState<number>(0);

    function toggleActiveTab(tab: number): void {
        if (isEdited === true) {
            setIsActionsClicked(!isActionsClicked);
            setActions(() => {
                setIsEdited(false);
                if (activeTab !== tab) setActiveTab(tab);
            });
        } else {
            if (activeTab !== tab) setActiveTab(tab);
        }
    }

    const StyleNavLink = styled(NavLink)`
        color: #323333;
        &:hover {
            color: #323333;
        }
    `;

    return (
        <React.Fragment>
            <Nav tabs>
                {tabs.map((element: TabsType, index: number) => {
                    return (
                        <NavItem
                            className={classNames("nav-item-hover__clz", {
                                "nav-item-active__clz": activeTab === index,
                            })}
                            key={uid(element, index)}
                        >
                            <StyleNavLink
                                className={classNames(
                                    "disable-nav-link-hover",
                                    {
                                        "active nav-link-new-segment-active__clz active-tab__clz":
                                            activeTab === index,
                                    }
                                )}
                                onClick={() => {
                                    toggleActiveTab(index);
                                }}
                                disabled={element.disabled}
                            >
                                {element.title}
                            </StyleNavLink>
                        </NavItem>
                    );
                })}
            </Nav>
            <TabContent activeTab={activeTab} className="m-3">
                {tabs.map((element: TabsType, index: number) => {
                    return (
                        <TabPane tabId={index} key={uid(element, index)}>
                            {element.content}
                        </TabPane>
                    );
                })}
            </TabContent>
        </React.Fragment>
    );
}
