import { CheckboxIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";
import classnames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";
import { uuid } from "uuidv4";

import ErrorToast from "../../../../components/Common/ErrorTost";
import { store as useStore } from "../../../../components/VerticalLayout/store";
import {
    setIsAssociateLogoOpened,
    setIsHistoryShopsModalOpened,
    setIsValidateClicked,
    store,
    setIsLoading,
    setKey,
} from "../store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

export function CustomSideFilter({
    isShopSelected,
    setLogo,
    selectedRows,
    setDataIsUpdated,
    setLocalFilterActive,
    setIsLogoArchived,
    isLogoArchived,
}: any) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID } = useSnapshot(useStore);
    const {
        numberActiveMultiShop,
        numberArchiveMultiShop,
        isHistoryShopsModalOpened,
        logoData,
        modify,
        isLoading,
    } = useSnapshot(store);

    const [error, setError] = React.useState<boolean>(false);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    const getArrayOfRows = () => {
        let arrayOfRows: any[] = [];
        selectedRows.forEach(async (el: any) => {
            if (el[t("Shop")] === undefined) {
                arrayOfRows.push(el);
            }
        });
        return arrayOfRows;
    };

    const getIsArrayOfRowsShop = () => {
        let isArrayShop: boolean = false;
        selectedRows.forEach(async (el: any) => {
            if (Object.values(el).includes(el.Id)) {
                isArrayShop = true;
            }
        });
        return isArrayShop;
    };
    let isArrayShop = getIsArrayOfRowsShop();

    let arrayOfRows = getArrayOfRows();
    function ArchivedLogo() {
        let idsAssociated: any[] = [];
        if (logoData !== undefined) {
            logoData.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    let dataArchive = ArchivedLogo();

    async function archiveLogo() {
        setIsValidateClicked(false);
        toast.dismiss();
        let localData: any = [];
        let arrayOfRows = getArrayOfRows();
        arrayOfRows.forEach(async (el: any) => {
            let dataLogos: any;
            dataLogos = {
                [el.Id]: {
                    designation: el[t("Designation")],
                    support_de_vente: el[t("Sales support")],
                    urlImage: el[t("Image")],
                    shopId: el.shopId,
                    tag: el.tag,
                    isActive: false,
                    isArchived: true,
                    img: el.img,
                },
            };
            let dataLogo = {
                userId: userID,
                shopId: [el.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                data: dataLogos,
            };
            const data = new FormData();
            data.append("dataLogo", JSON.stringify(dataLogo));
            data.append("image", "");
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/logo`;
            localData.push(
                fetch(apiUrlAdd, {
                    method: "POST",
                    body: data,
                }).then((response) => response.json())
            );
        });
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/logo`,
                await Promise.all(localData).then((result) => {})
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setError(true);
            setIsLoading(false);
        }

        if (error === false) {
            toast.success(`${t("The logo is successfully archived")!}`, {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
                icon: <CheckboxIcon height={25} width={25} fill="white" />,
            });
            setIsLoading(false);
            setKey(uuid());
            setIsValidateClicked(true);
        }
    }

    async function activeLogo() {
        setIsValidateClicked(false);
        toast.dismiss();
        let localData: any = [];
        let arrayOfRows = getArrayOfRows();
        arrayOfRows.forEach(async (el: any) => {
            let dataLogos: any;
            dataLogos = {
                [el.Id]: {
                    designation: el[t("Designation")],
                    support_de_vente: el[t("Sales support")],
                    urlImage: el[t("Image")],
                    shopId: el.shopId,
                    tag: el.tag,
                    isActive: false,
                    isArchived: false,
                    img: el.img,
                },
            };
            let dataLogo = {
                userId: userID,
                shopId: [el.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                data: dataLogos,
            };
            const data = new FormData();
            data.append("dataLogo", JSON.stringify(dataLogo));
            data.append("image", "");
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/logo`;
            localData.push(
                fetch(apiUrlAdd, {
                    method: "POST",
                    body: data,
                }).then((response) => response.json())
            );
        });
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/logo`,
                await Promise.all(localData).then((result) => {})
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setError(true);
            setIsLoading(false);
        }

        if (error === false) {
            toast.success(`${t("logo has been successfully activated")!}`, {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
                icon: <CheckboxIcon height={25} width={25} fill="white" />,
            });
            setIsLoading(false);
            setKey(uuid());
            setIsValidateClicked(true);
        }
    }
    async function isSaleSupportArchived() {
        toast.dismiss();
        let isSaleSupportArchived = false;
        const url = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&franchiseId=${franchiseID}&type=archived&uuidUser=${uuidUser}`;
        mutate(
            url,
            await fetch(url, {
                method: "GET",
                headers: { "Accept-Language": i18nextLng },
            })
                .then((response) => response.json())
                .then((data) => {
                    data.data.forEach((elt: any) => {
                        selectedRows.forEach((element: any) => {
                            element[t("Sales support")]
                                .split(",")
                                .forEach((support: any) => {
                                    if (
                                        elt.subRows.some(
                                            (sale: any) =>
                                                `${sale[t("Sale support")]} ${
                                                    sale.shopId
                                                }` ===
                                                `${support} ${element.shopId}`
                                        )
                                    ) {
                                        isSaleSupportArchived = true;
                                    }
                                });
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
        );
        return isSaleSupportArchived;
    }

    return (
        <div className="d-flex justify-content-start align-items-center">
            {modify === true ? (
                <>
                    <CustomMainColorButton
                        className={
                            isLogoArchived
                                ? "w-100 ml-2 not-allowed-icon__clz lgo_btn_BTassignLogo"
                                : "w-100 ml-2 lgo_btn_BTassignLogo"
                        }
                        rounded
                        disabled={isShopSelected ? false : true}
                        variant="primary"
                        onClick={() => {
                            setLogo([]);
                            setIsAssociateLogoOpened(true);
                            setLocalFilterActive(false);
                        }}
                    >
                        {t("Assign logo")}
                    </CustomMainColorButton>
                </>
            ) : null}
            <VerticalDotsIcon
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
                className="cmn_drp_BTmenu"
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item pl-1 cmn_btn_BTlistActivated"
                    )}
                    onClick={() => {
                        setIsLogoArchived(false);
                        setDataIsUpdated(true);
                    }}
                >
                    {t("Enabled logo(s)")} ({numberActiveMultiShop})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item pl-1 cmn_btn_BTlistArchived"
                    )}
                    onClick={() => {
                        setIsLogoArchived(true);
                        setDataIsUpdated(true);
                    }}
                >
                    {t("Archived logo(s)")} ({numberArchiveMultiShop})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={`${classnames(
                        "dropdown-item pl-1 cmn_btn_BThistory",
                        {
                            "not-allowed-icon__clz":
                                selectedRows.length === 0 ||
                                arrayOfRows.length === 0,
                        }
                    )}`}
                    onClick={() => {
                        if (arrayOfRows.length < 2) {
                            ErrorToast(
                                `${t(
                                    "Attention, it is a multiple action. Please select at least two items"
                                )!}`
                            );
                        } else {
                            setIsHistoryShopsModalOpened(
                                !isHistoryShopsModalOpened
                            );
                        }
                    }}
                >
                    {t("Historical")}
                </div>
                {modify === true ? (
                    <>
                        <div
                            style={{ cursor: "pointer" }}
                            className={`${classnames(
                                "dropdown-item pl-1 cmn_btn_BTunarchive cmn_btn_BTarchived",
                                {
                                    "not-allowed-icon__clz":
                                        isLoading ||
                                        selectedRows.length === 0 ||
                                        arrayOfRows.length === 0 ||
                                        (arrayOfRows.some((element: any) =>
                                            dataArchive.includes(element.Id)
                                        ) &&
                                            isLogoArchived) ||
                                        !isArrayShop ||
                                        arrayOfRows.length === 0,
                                }
                            )}`}
                            onClick={async () => {
                                setIsLoading(true);
                                let isArchived = await isSaleSupportArchived();
                                if (arrayOfRows.length < 2) {
                                    ErrorToast(
                                        `${t(
                                            "Attention, it is a multiple action. Please select at least two items"
                                        )!}`
                                    );
                                    setIsLoading(false);
                                } else if (
                                    isArchived === true &&
                                    isLogoArchived
                                ) {
                                    ErrorToast(
                                        `${t(
                                            "Please note that  the unarchiving process could not be successfully completed. Please check the settings of the store(s) data."
                                        )!}`
                                    );
                                    setIsLoading(false);
                                } else if (isLogoArchived) {
                                    activeLogo();
                                } else {
                                    archiveLogo();
                                }
                            }}
                        >
                            {isLogoArchived
                                ? `${t("Dearchive")}`
                                : `${t("Archive")}`}
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    );
}
