import React from "react";
import { AvForm } from "availity-reactstrap-validation";
import { CardBody, Card } from "reactstrap";

import PrintingOptions from "../PrintingOptions";
import Code from "../Code";
import PaperlessTicket from "../PaperlessTicket";

import "../../index.css";

function UserCard() {
    return (
        <Card
            style={{
                fontSize: "15px",
            }}
        >
            <AvForm>
                <CardBody>
                    <PrintingOptions />
                    <div className="border-footer__clz p-1" />
                    <Code />
                    <div className="border-footer__clz p-1" />
                    <PaperlessTicket />
                </CardBody>
            </AvForm>
        </Card>
    );
}

export default UserCard;
