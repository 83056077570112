import React from "react";
import { useTranslation } from "react-i18next";
import { StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { capitalize } from "@helpers/general";
import CardFooterModalPublish from "./CardFooterModalPublish";

type ModalPublishType = {
    rowData: any;
    setIsDataUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    setIsModalPublishOpened: Function;
    isModalPublishOpened: boolean;
    
};

export default function ModalPublish({
    setIsDataUpdated,
    rowData,
    setIsModalPublishOpened,
    isModalPublishOpened,
    
}: ModalPublishType) {
    const { t } = useTranslation();

   

    return (
        <React.Fragment>
            <Modal centered isOpen={isModalPublishOpened}>
                <ModalHeader
                    toggle={() => {
                        setIsModalPublishOpened!(!{ isModalPublishOpened });
                    }}
                >
                    <StyledH2>
                        {capitalize(t("Release configuration"))}
                    </StyledH2>
                </ModalHeader>
                <ModalBody>
                    <StyledLabel className="pl-3 m-0">
                        {t("Please confirm the publication of this template")} !
                    </StyledLabel>
                </ModalBody>
                <CardFooterModalPublish
                    rowData={rowData}
                    setIsDataUpdated={setIsDataUpdated}
                    isModalPublishOpened={isModalPublishOpened}
                    setIsModalPublishOpened={setIsModalPublishOpened}
                    
                />
            </Modal>
        </React.Fragment>
    );
}
