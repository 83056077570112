import React from "react";
import { Nav, NavItem, TabContent, TabPane, NavLink } from "reactstrap";
import classNames from "classnames";
import { t } from "i18next";

import { setActiveAdvertisingScreenTab, store } from "@store";

import { StandbyStateScreen } from "./StandbyStateScreen";
import { ActiveStateScreen } from "./ActiveStateScreen";
import { useSnapshot } from "valtio";

export function AdvertisingScreen(): JSX.Element {
    const tabs: TabsType[] = [
        {
            title: t("Active state"),
            content: <ActiveStateScreen />,
            customClassName: "epb_lnk_activeState",
        },
        {
            title: t("Standby state"),
            content: <StandbyStateScreen />,
            customClassName: "epb_lnk_standbyState",
        },
    ];
    const [activeTab, setActiveTab] = React.useState<number>(0);
    const { mainColor } = useSnapshot(store);
    function toggle(tab: number): void {
        if (activeTab !== tab) {
            setActiveTab(tab);
            setActiveAdvertisingScreenTab(tab);
        }
    }

    return (
        <div className="mb-2" style={{ height: "100%" }}>
            <Nav
                tabs
                style={{
                    backgroundColor: "white",
                    width: "25%",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gridTemplateRows: "1fr",
                    border: "unset",
                }}
            >
                {tabs.map((element, index) => {
                    return (
                        <NavItem
                            key={index}
                            className={classNames(
                                "d-flex align-items-center text-nowrap",
                                {
                                    "disable-nav-item__clz":
                                        activeTab !== index,
                                },
                                element.customClassName
                            )}
                            style={{
                                cursor: "pointer",
                                borderBottom:
                                    activeTab === index
                                        ? `${mainColor} 3px solid`
                                        : "",
                            }}
                        >
                            <NavLink
                                className={classNames("text-dark w-100", {
                                    "active customer-account-menu-nav-link-active__clz":
                                        activeTab === index,
                                    "customer-account-menu-nav-link-disable__clz":
                                        activeTab !== index,
                                })}
                                onClick={() => {
                                    toggle(index);
                                }}
                            >
                                <span
                                    className="px-2"
                                    style={{
                                        font:
                                            "normal normal 600 21px/28px Segoe UI",
                                    }}
                                >
                                    {element.title}
                                </span>
                            </NavLink>
                        </NavItem>
                    );
                })}
            </Nav>

            <TabContent
                activeTab={activeTab}
                style={{
                    width: "100%",
                    height: "93%",
                }}
                className="bg-white border-0 rounded"
            >
                {tabs.map((element, index) => {
                    return (
                        <TabPane
                            tabId={index}
                            style={{ height: "100%", width: "100%" }}
                            key={index}
                        >
                            {element.content}
                        </TabPane>
                    );
                })}
            </TabContent>
        </div>
    );
}
