import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    CrossIcon,
    StyledLabel,
    StyledTextInput,
    CheckboxIcon,
    StyledSelectInput,
} from "@aureskonnect/react-ui";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { mutate } from "swr";
import { toast } from "react-toastify";
import classnames from "classnames";
import { useSnapshot } from "valtio";
import { Select } from "antd";

import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";

import {
    store as device,
    setIsActionsClicked,
    setActions,
    setIsEdited,
    setIsPlusIconClicked,
} from "./store";

import {
    CustomCardBody,
    CustomCardFooter,
    CustomCardHeader,
} from "@components/Common/CustomCardStyled";

import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type ModeOfSaleFormType = {
    isAddCity: boolean;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<boolean | number>>;

    selectedRows: any;
    setLocalFilterActive: Function;
    setIsAddCity: Function;

    isAddNewDeviseButtonClicked: boolean;
    setSelectedRows: Function;
    selectedRowShops: any;
    list: any;
    dataLivraison: any;
};

export default function CityForm({
    setIsAddCity,
    isAddCity,
    setDataIsUpdated,
    selectedRows,
    setLocalFilterActive,

    isAddNewDeviseButtonClicked,
    setSelectedRows,
    selectedRowShops,
    list,
    dataLivraison,
}: ModeOfSaleFormType) {
    const { t } = useTranslation();
    const numberMatchWord = new RegExp(/^[0-9]\d*$/);
    const { isActionsClicked, isEdited, isPlusIconClicked } = useSnapshot(
        device
    );

    const [
        isClickedBouttonValider,
        setIsClickedBouttonValider,
    ] = React.useState<boolean>(true);

    const [selectCountry, setSelectCountry] = React.useState<boolean>(false);
    const [isValidationNumber, setIsValidationNumber] = React.useState<boolean>(
        false
    );
    const [
        isValidationtMinimumAmount,
        setIsValidationtMinimumAmount,
    ] = React.useState<boolean>(false);
    const [tva, setTva] = React.useState<string>("");
    const [shippingCosts, setShippingCosts] = React.useState<number>();

    const [minimumAmount, setMinimumAmount] = React.useState<number>();

    const [countryList, setCountryList] = React.useState<any>([]);
    const [countryData, setCountryData] = React.useState<string>("");
    const [change, setChange] = React.useState<string>("");
    const [city, setCity] = React.useState<string>("");
    const [isExistingCodePostal, setIsExistingCodePostal] = React.useState<
        boolean
    >(false);
    const [inputTva, setInputTva] = React.useState<boolean>(false);
    const [title, setTitle] = React.useState<string>("");

    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const children = [];
    const Option = Select.Option;
    for (let i = 10; i < 36; i++) {
        children.push(
            <Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>
        );
    }
    const [listTva, setListTva] = React.useState<any[]>([]);
    const [inputCode, setInputCode] = React.useState<boolean>(false);
    const [inputCity, setInputCity] = React.useState<boolean>(false);
    const [code, setCode] = React.useState<any[]>([]);

    const dataFetch = async () => {
        const data = await (
            await fetch(`${process.env.REACT_APP_API_V2_URL}/settings/pays`)
        ).json();

        setCountryList(
            data.data.map((el: any) => {
                return {
                    label: el.Designation,
                };
            })
        );

        setListTva(
            selectedRowShops[0]["list Of VatRate"].map((el: any) => {
                return {
                    label: el.value,
                    value: el.id,
                };
            })
        );
    };
    const CountryInformation = async () => {
        const data = await (
            await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/code/pays?pays=${countryData}`
            )
        ).json();

        setCode(
            data.data.map((el: any) => {
                return {
                    label: el.code_post,
                    value: el.nom_commune,
                    code: el.pays,
                    pays: el.nom_fr_fr,
                };
            })
        );
    };
    function listPostalCode() {
        let listPostalCode: any[] = [];
        let dataLivraison = JSON.parse(selectedRowShops[0]["minimum price"]);
        dataLivraison.STVilleLivraison.forEach((element: any) => {
            listPostalCode.push(element["CodePostale"]);
        });
        return listPostalCode;
    }
    let listCode = listPostalCode();

    let schema: any = localStorage.getItem("schemaFranchise");
   
    async function addVille() {
        let arrayCountry: any[] = [];
        dataLivraison.tabPaysLivrable.length > 0 &&
            dataLivraison.tabPaysLivrable.forEach((el: any) => {
                arrayCountry.push(el.Nom);
            });
        let idTva: any;
        let codePays: any;
        if (tva === "20") {
            idTva = "2";
        } else if (tva === "10") {
            idTva = "1";
        } else if (tva === "5.5") {
            idTva = "3";
        } else {
            listTva.forEach((el: any) => {
                if (
                    el.value !== "20" ||
                    el.value !== "10" ||
                    el.value !== "5.5"
                ) {
                    idTva = el.id;
                }
            });
        }
        code.forEach((el: any) => {
            if (countryData === el.pays) {
                codePays = el.code;
            }
        });
        let dataVille: any = {
            Department: "",
            CodePostale: change,
            NomVille: city,
            MinCommande: minimumAmount,
            FraisLivraison: shippingCosts,
            idTva: idTva,
            TauxTva: tva,
            detailsVille: `{\u001bPG+4260 ${city} \u001bPG-: Frais liv :  ${shippingCosts}£ \u20ac \u001bPG- - Min CMD : ${minimumAmount}£ \u20ac}`,
            InfoVille: `{ \u001bPG+ ${city} :\u001bPG- (Frais de liv: ${shippingCosts} \u20ac-Min commande: ${minimumAmount} \u20ac)}`,
            Pays: countryData,
            CodePays: codePays,
            SousVille: "",
        };

        dataLivraison.STVilleLivraison.push(dataVille);
        if (!arrayCountry.includes(countryData)) {
            dataLivraison.tabPaysLivrable.push({
                Nom: countryData,
                Refpays: codePays,
            });
        }
        dataLivraison.LivraisonVille = true;
        dataLivraison.Livraisonzone = false;
        setIsClickedBouttonValider(false);
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/list/add/city/c&c`;

        let data = {
            shopId: selectedRowShops[0].shopId,
            schemaFranchise: schema,

            data: dataLivraison,
        };

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };

        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            setDataIsUpdated!(true);
                            throw Error("error");
                        }
                        toast.success(`${t("City added successfully")!}`, {
                            position: toast.POSITION.TOP_CENTER,
                            theme: "colored",
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        });

                        setCountryData("");
                        setCity("");
                        setDataIsUpdated!(true);
                        setIsEdited(false);
                        setLocalFilterActive(false);
                        setChange("");
                        setTva("");
                        clearData();
                        clearMultiCustomSelectionData();
                        selectedRowShops[0][
                            t("minimum price")
                        ] = JSON.stringify(dataLivraison);
                        setIsClickedBouttonValider(true);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function editVille() {
        if (
            !isEdited 
        ) {
            setIsModalOpened(true);
        }else{
        let idTva: any;
        let codePays: any;
        if (tva === "20") {
            idTva = "2";
        } else if (tva === "10") {
            idTva = "1";
        } else if (tva === "5.5") {
            idTva = "3";
        } else {
            listTva.forEach((el: any) => {
                if (
                    el.value !== "20" ||
                    el.value !== "10" ||
                    el.value !== "5.5"
                ) {
                    idTva = el.id;
                }

                code.forEach((el: any) => {
                    if (countryData === el.pays) {
                        codePays = el.code;
                    }
                });
            });
        }

        dataLivraison.STVilleLivraison.forEach((element: any) => {
            if (selectedRows[0][t("Postal code")] === element["CodePostale"]) {
                element["Department"] = "";
                element["CodePostale"] = change;
                element["NomVille"] = city;
                element["MinCommande"] = minimumAmount;
                element["FraisLivraison"] = shippingCosts;
                element["idTva"] = idTva;
                element["TauxTva"] = tva;
                element[
                    "detailsVille"
                ] = `{\u001bPG+4260 ${city} \u001bPG-: Frais liv :  ${shippingCosts}£ \u20ac \u001bPG- - Min CMD : ${minimumAmount}£ \u20ac}`;
                element[
                    "InfoVille"
                ] = `{ \u001bPG+ ${city} :\u001bPG- (Frais de liv: ${shippingCosts} \u20ac-Min commande: ${minimumAmount} \u20ac)}`;
                element["Pays"] = countryData;
                element["CodePays"] = codePays;
                element["SousVille"] = "";
            }
        });

        setIsClickedBouttonValider(false);
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/list/add/city/c&c`;

        let data = {
            shopId: selectedRowShops[0].shopId,
            schemaFranchise: schema,

            data: dataLivraison,
        };

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };

        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            setDataIsUpdated!(true);
                            throw Error("error");
                        }
                        toast.success(
                            `${t("Modification effectuée avec succès")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );

                        setCountryData("");
                        selectedRowShops[0][
                            t("minimum price")
                        ] = JSON.stringify(dataLivraison);
                        setDataIsUpdated!(true);
                        setIsEdited(false);
                        setLocalFilterActive(false);
                        setShippingCosts(0);
                        setMinimumAmount(0);
                        setChange("");
                        clearData();
                        setIsAddCity(!isAddCity);
                        clearMultiCustomSelectionData();
                        setInputCity(false);
                        setInputTva(false);
                        setIsValidationNumber(false);
                        setIsValidationtMinimumAmount(false);
                        setSelectCountry(false);
                        setSelectCountry(false);
                        setIsPlusIconClicked(false);

                        setIsClickedBouttonValider(true);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }
    }

    useEffect(() => {
        if (selectedRows.length > 0) {
            setTitle(t("Modify a city"));

            setShippingCosts(selectedRows[0][t("Shipping costs")]);

            setCountryData(selectedRows[0][t("Country")]);

            setChange(selectedRows[0][t("Postal code")]);
            setCity(selectedRows[0][t("City")]);
            setTva(
                list.includes(selectedRows[0]["Tva"].toString())
                    ? selectedRows[0]["Tva"]
                    : ""
            );

            setMinimumAmount(
                selectedRows[0][t("Minimum amount to place an order")]
            );
           
        } else {
            setTitle(t("Add a city"));

            setCity("");
            setTva("");
            setShippingCosts(0);
            setChange("");
            setMinimumAmount(0);
            setCountryData("");
            setIsValidationNumber(false);
            setIsValidationtMinimumAmount(false);
            setIsEdited(false);
            setInputTva(false);
            setInputCode(false);
            setSelectCountry(false);
            setIsClickedBouttonValider(true);
        }

        // eslint-disable-next-line
    }, [isAddNewDeviseButtonClicked, selectedRows]);

    useEffect(() => {
        clearData();
        clearMultiCustomSelectionData();
    }, [isAddCity]);

    React.useEffect(() => {
        dataFetch();
        //eslint-disable-next-line
    }, []);
    React.useEffect(() => {
        CountryInformation();
        //eslint-disable-next-line
    }, [countryData]);

    React.useEffect(() => {
        if (isPlusIconClicked || selectedRows.length > 0) {
            setInputCity(false);
            setInputTva(false);
            setIsValidationNumber(false);
            setIsValidationtMinimumAmount(false);
            setSelectCountry(false);
            setInputCode(false);
            setIsPlusIconClicked(false);
        }
    }, [isPlusIconClicked, selectedRows]);

    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader
                    className="d-flex align-items-center justify-content-between"
                    style={{ maxHeight: "76px" }}
                >
                    <h5 className="pt-3 dev_txt_FRtitleFormCurrency">
                        {title}
                    </h5>
                    <CrossIcon
                        className="icon-close-notification__clz dev_icn_FRcloseForm"
                        height={13}
                        width={13}
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    // setDevises([]);
                                    setIsAddCity(!isAddCity);
                                    setIsEdited(false);
                                    clearData();
                                    clearMultiCustomSelectionData();
                                    setDataIsUpdated!(true);
                                });
                            } else {
                                setSelectedRows([]);
                                setIsAddCity(!isAddCity);
                                setIsEdited(false);
                                clearData();
                                clearMultiCustomSelectionData();
                                setDataIsUpdated!(true);
                            }
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className="pl-3 pr-5"
                    style={{
                        maxHeight: "450px",
                        overflowY: " scroll",
                    }}
                >
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Country")}
                        </StyledLabel>

                        <StyledSelectInput
                            isClearable
                            autoFocus
                            noOptionsMessage={() => t("No options")}
                            options={countryList}
                            autocomplete="off"
                            className={classnames("", {
                                input__clz: selectCountry,
                            })}
                            name="change"
                            onChange={(e: any) => {
                                if (e !== null) {
                                    setCountryData(e.label);
                                } else {
                                    setCountryData("");
                                }
                                setSelectCountry(false);
                                setIsEdited(true);

                                setIsClickedBouttonValider(true);
                            }}
                            value={
                                countryData === ""
                                    ? null
                                    : {
                                          label: countryData,
                                          value: countryData,
                                      }
                            }
                            placeholder={`${t("Select")}…`}
                            type="text"
                        />
                        {selectCountry ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Choose a selection")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Zip code")}
                        </StyledLabel>
                        <div>
                            <Select
                                style={{ width: "100%", fontSize: "14px" }}
                                options={code}
                                className={classnames("", {
                                    input__clz: inputCode,
                                })}
                                value={change}
                                showSearch={true}
                                onChange={(value: any, e: any) => {
                                    if (value !== null) {
                                        setChange(e.label);
                                        setCity(value);
                                        if (listCode.includes(e.label.trim())) {
                                            setIsExistingCodePostal(true);
                                        } else {
                                            setIsExistingCodePostal(false);
                                        }
                                    } else {
                                        setChange("");

                                        setCity("");
                                    }
                                    setIsEdited(true);
                                    setInputCode(false);

                                    setInputCity(false);
                                    setIsClickedBouttonValider(true);
                                }}
                            />
                        </div>

                        {inputCode ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Choose a selection")}
                            </div>
                        ) : null}
                        {isExistingCodePostal ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Postcode already exists")!}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("City")}
                        </StyledLabel>
                        <StyledTextInput
                            autocomplete="off"
                            name={t("City")}
                            value={city}
                            placeholder={t("City")}
                            className={classnames("not-allowed-input__clz", {
                                input__clz: inputCity,
                            })}
                            type="text"
                        />

                        {inputCity ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Choose a selection")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("TVA")}
                        </StyledLabel>
                        <StyledSelectInput
                            isClearable
                            noOptionsMessage={() => t("No options")}
                            options={listTva}
                            autocomplete="off"
                            className={classnames("", {
                                input__clz: inputTva,
                                // readOnly__clz: isConsult,
                            })}
                            name="tva"
                            onChange={(e: any) => {
                                if (e !== null) {
                                    setTva(e.label);
                                } else {
                                    setTva("");
                                }

                                setIsEdited(true);
                                setInputTva(false);

                                setIsClickedBouttonValider(true);
                            }}
                            value={
                                tva === ""
                                    ? null
                                    : {
                                          label: tva,
                                          value: tva,
                                      }
                            }
                            placeholder={`${t("Select")}…`}
                            type="text"
                            maxMenuHeight="80px"
                        />
                        {inputTva ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Choose a selection")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel className="mt-3" htmlFor="Shipping">
                            {t("Shipping costs")}
                        </StyledLabel>
                        <StyledTextInput
                            autocomplete="off"
                            id="remark"
                            name="remark"
                            value={shippingCosts}
                            type="text"
                            onChange={(e: any) => {
                                setIsClickedBouttonValider(true);
                                if (
                                    e.target.value.match(numberMatchWord) ||
                                    e.target.value === ""
                                ) {
                                    if (e.target.value === "") {
                                        setShippingCosts(0);
                                    }
                                    setShippingCosts(e.target.value);
                                    setIsValidationNumber(false);
                                } else {
                                    setIsValidationNumber(true);
                                }

                                setIsEdited!(true);
                            }}
                            // value={shippingCosts}
                            className={classnames("", {
                                input__clz: isValidationNumber,
                            })}
                        />

                        {isValidationNumber && (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please insert a number")!}
                            </div>
                        )}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel className="mt-3" htmlFor="remark">
                            {t("Minimum amount to place an order")}
                        </StyledLabel>
                        <StyledTextInput
                            autocomplete="off"
                            id="commande"
                            name="commande"
                            type="text"
                            onChange={(e: any) => {
                                setIsClickedBouttonValider(true);
                                if (
                                    e.target.value.match(numberMatchWord) ||
                                    e.target.value === ""
                                ) {
                                    if (e.target.value === "") {
                                        setMinimumAmount(0);
                                    }
                                    setMinimumAmount(e.target.value);
                                    setIsValidationtMinimumAmount(false);
                                } else {
                                    setIsValidationtMinimumAmount(true);
                                }

                                setIsEdited!(true);
                            }}
                            value={minimumAmount}
                            className={classnames("", {
                                input__clz: isValidationtMinimumAmount,
                            })}
                        />

                        {isValidationtMinimumAmount && (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please insert a number")!}
                            </div>
                        )}
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2 dev_btn_FRcancel"
                        variant="light"
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsEdited(false);
                                    setIsAddCity(!isAddCity);
                                    setDataIsUpdated!(true);
                                });
                            } else {
                                setIsAddCity(!isAddCity);
                                setDataIsUpdated!(true);
                            }
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        className="dev_btn_FRvalidate"
                        disabled={!isClickedBouttonValider}
                        onClick={() => {
                            if (change === "") setInputCode(true);

                            if (tva === "") setInputTva(true);
                            if (countryData === "") setSelectCountry(true);

                            if (city === "") setInputCity(true);
                            if (
                                change !== "" &&
                                countryData !== "" &&
                                tva !== "" &&
                                city !== ""
                            ) {
                               if (selectedRows.length === 0) {
                           
                                    addVille();
                                } else {

                                        editVille();
                                    
                                    
                                }
                            }
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>

            <ConfirmationEditMessage
                setDataIsUpdated={setDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddCity}
                setIsAddClicked={setIsAddCity}
                setEditedData={setSelectedRows}
            />
        </React.Fragment>
    );
}
