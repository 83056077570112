import React from "react";
import { useSnapshot } from "valtio";

import { designStore } from "@store";

import { ActionsButtonsWrapper } from "@components/Common/Design/ActionsButtonsWrapper";

export function ActionsButtons(): JSX.Element {
    const { actionsButtonsItems } = useSnapshot(designStore);

    const [, setFilteredItems] = React.useState<
        ActionsButtonsDesignItemType[]
    >(actionsButtonsItems);

    return (
        <ActionsButtonsWrapper
            localActionsButtonsItems={actionsButtonsItems}
            setLocalActionsButtonsItems={setFilteredItems}
        />
    );
}
