import React from "react";
import classnames from "classnames";
import {
    DisassociateIcon,
    EyeIcon,
    PencilIcon,
    SettingsIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next/";
import { useSnapshot } from "valtio";
import { Tooltip } from "@mui/material";

import { store as device } from "../../../../components/VerticalLayout/store";
import store, {
    setIsConfigurationPrinterClicked,
    setSelectedApplicationKds,
} from "../store";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import ErrorToast from "@components/Common/ErrorTost";
import LinkSvgIcon from "@components/Common/SvgIcons/LinkSvgIcon";

export function CustomIcons({
    e,
    setSelectedRows,
    isAddApplication,
    setIsAddApplication,
    isApplicationArchived,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    setIsConfirmationModalArchived,
    setIsConfirmationModalActived,
    setIsDeviceAssociateModalOpened,
    setIsDeviceDissociateModalOpened,
    setUrlHistoryPrinter,
    setIsAddPrinterOriginHistoryClicked,
}: any) {
    const { t } = useTranslation();
    const { userID, franchiseID } = useSnapshot(device);
    const { isConfigurationPrinterClicked, modify } = useSnapshot(store);
    return (
        <React.Fragment>
            {e.selectedRow?.subRows !== undefined &&
            e.selectedRow["Application"] !== "" ? (
                <div className="d-flex justify-content-between mt-2">
                    {modify === true && (
                        <>
                            <div>
                                <PencilIcon
                                    onClick={() => {
                                        setSelectedRows([e.selectedRow]);
                                        if (!isAddApplication) {
                                            setIsAddApplication(
                                                !isAddApplication
                                            );
                                        }
                                    }}
                                    height={25}
                                    width={25}
                                    style={{ cursor: "pointer" }}
                                    className={`${classnames(
                                        "pointer__clz cmn_icn_edit",
                                        {
                                            "not-allowed-icon__clz": isApplicationArchived,
                                        }
                                    )}`}
                                />
                            </div>
                            <div
                                style={{ position: "relative", bottom: "3px" }}
                            >
                                <Tooltip
                                    title={t(
                                        "Associate a device with an application"
                                    ).toString()}
                                >
                                    <div>
                                        <LinkSvgIcon
                                            className={`${classnames(
                                                "pointer__clz mr-1 cmn_icn_associate",
                                                {
                                                    "not-allowed-icon__clz": isApplicationArchived,
                                                }
                                            )}`}
                                            onClick={() => {
                                                setIsDeviceAssociateModalOpened(
                                                    true
                                                );
                                                setSelectedRows([
                                                    e.selectedRow,
                                                ]);
                                            }}
                                            height={30}
                                            width={25}
                                            style={{
                                                position: "relative",
                                                top: "5px",
                                            }}
                                        />
                                    </div>
                                </Tooltip>
                            </div>
                            <div>
                                {!isApplicationArchived ? (
                                    <TrashIcon
                                        onClick={() => {
                                            setIsAddApplication(false);
                                            if (
                                                e.selectedRow.subRows.length > 0
                                            ) {
                                                ErrorToast(
                                                    t(
                                                        "Please note that you cannot archive a application associated with an device."
                                                    )
                                                );
                                            } else {
                                                setIsConfirmationModalArchived(
                                                    true
                                                );
                                            }

                                            setSelectedRows([e.selectedRow]);
                                        }}
                                        height={25}
                                        width={25}
                                        fill="red"
                                        style={{ cursor: "pointer" }}
                                        className={`${classnames(
                                            "pointer__clz ml-1 cmn_icn_archived"
                                        )}`}
                                    />
                                ) : (
                                    <EyeIcon
                                        height={25}
                                        width={25}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setIsConfirmationModalActived(true);
                                            setSelectedRows([e.selectedRow]);
                                        }}
                                        className={`${classnames(
                                            "pointer__clz ml-1 cmn_icn_unarchive",
                                            {}
                                        )}`}
                                    />
                                )}
                            </div>
                        </>
                    )}
                    <div>
                        <TimePastSvgIcon
                            onClick={() => {
                                setSelectedApplicationKds([
                                    {
                                        [e.selectedRow.shopId]:
                                            e.selectedRow.appId,
                                    },
                                ]);
                                setIsHistoryModalOpened(!isHistoryModalOpened);

                                setSelectedRows([e.selectedRow]);
                            }}
                            className={`${classnames(
                                "pointer__clz cmn_icn_history",
                                "ml-2"
                            )}`}
                        />
                    </div>
                </div>
            ) : e.selectedRow[t("Peripheral")] !== "" ? (
                <div className="d-flex justify-content-between mt-3">
                    {modify === true && (
                        <>
                            <SettingsIcon
                                className="pointer__clz ml-4"
                                onClick={() => {
                                    if (
                                        e.selectedRow.peripheralType ===
                                        "printer"
                                    ) {
                                        setSelectedRows(e.selectedRow);
                                        setIsConfigurationPrinterClicked(
                                            !isConfigurationPrinterClicked
                                        );
                                    }
                                }}
                                height={22}
                                width={22}
                            />
                            <DisassociateIcon
                                height={40}
                                width={35}
                                fill="white"
                                style={{ position: "relative", bottom: "10px" }}
                                className={`${classnames("pointer__clz ml-2", {
                                    "not-allowed-icon__clz": isApplicationArchived,
                                })}`}
                                onClick={() => {
                                    setSelectedRows([e.selectedRow]);
                                    setIsDeviceDissociateModalOpened(true);
                                }}
                            />
                        </>
                    )}
                    <TimePastSvgIcon
                        onClick={() => {
                            setUrlHistoryPrinter(
                                `${process.env.REACT_APP_API_V2_URL}/settings/printers/printer/history?userId=${userID}&shopId=${e.selectedRow.shopId}&franchiseId=${franchiseID}&id=${e.selectedRow.peripheralId}`
                            );
                            setIsAddPrinterOriginHistoryClicked(true);
                        }}
                        className={`${classnames(
                            "pointer__clz cmn_icn_history",
                            "ml-3"
                        )}`}
                    />
                </div>
            ) : null}
        </React.Fragment>
    );
}
