import React from "react";
import { useTranslation } from "react-i18next/";
import { StyledButton } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";

import {
    setCheckModificationEffect,
    setIsPrinterModified,
    setSelectedPeripherals,
    setInAllPrinters,
    store,
} from "./store";
import "./index.css";

type PrinterActionColumnType = {
    element: any;
};

export function PrinterActionColumn({ element }: PrinterActionColumnType) {
    const { t } = useTranslation();
    const { printerSelected } = useSnapshot(store);
    const [isSelected, setIsSelected] = React.useState<boolean>(false);

    React.useEffect(() => {
        setIsSelected(
            printerSelected.filter((x: any) => x.Id === element["Id"]).length >
                0
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [printerSelected]);
    return (
        <StyledButton
            rounded
            outline
            variant={isSelected ? "danger" : "light"}
            className="button-style-select___clz afa_btn_selectPeripheral"
            onClick={() => {             
                setInAllPrinters(element);
                setSelectedPeripherals(element);
                setIsPrinterModified(true);
                setCheckModificationEffect(true);
            }}
        >
            <span>{isSelected ? t("Deselect") : t("Select")}</span>
        </StyledButton>
    );
}
