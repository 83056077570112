import React from "react";
import { StyledH2 } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { resetStore } from "./store";
import { setIsEdited } from "../store";

import ApplicationList from "./ApplicationList";
import { capitalize } from "@helpers/general";

import "./index.css";

type PrintersPathsModalType = {
    setIsDeviceAssociateModalOpened: Function;
    isDeviceAssociateModalOpened: boolean;
    rowData: any;
    dataIsUpdated: boolean | number;
    setDataIsUpdated: Function;
    selectedRows:any
};

export default function DeviceAssociateModal({
    setIsDeviceAssociateModalOpened,
    isDeviceAssociateModalOpened,
    rowData,
    dataIsUpdated,
    setDataIsUpdated,
    selectedRows,
}: PrintersPathsModalType) {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                className="modal-wrapper__clz  modal-assoc__clz"
                isOpen={isDeviceAssociateModalOpened}
            >
                <ModalHeader
                    toggle={() => {
                        setIsDeviceAssociateModalOpened!(
                            !isDeviceAssociateModalOpened
                        );
                        resetStore();
                        setIsEdited(false);
                    }}
                >
                    <StyledH2>
                        {capitalize(
                            t("Associate an application with a device")
                        )}
                    </StyledH2>
                </ModalHeader>
                <ModalBody className="p-0">
                    <ApplicationList
                        rowData={rowData}
                        setIsDeviceAssociateModalOpened={
                            setIsDeviceAssociateModalOpened
                        }
                        isDeviceAssociateModalOpened={
                            isDeviceAssociateModalOpened
                        }
                        setDataIsUpdated={setDataIsUpdated}
                        dataIsUpdated={dataIsUpdated}
                        selectedRows={selectedRows}
                    />
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}
