import React from "react";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { checkIpIfExist, getUniqueId } from "@helpers/general";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";
import { mutate } from "swr";

import { store } from "@components/VerticalLayout/store";
import {
    store as cashManagementDataStore,
    setCashManagementData,
    resetCashManagementData,
} from "./store";
import {
    store as generalStore,
    setIsActionsClicked,
    setActions,
} from "../../store";

import { resetAllPeripherals } from "../../DeviceAssociateModal/store";

import { resetPrinterData } from "../PrinterPeripheralConfigure/store";
import { resetMoneticData } from "../MoneticPeripheralConfigure/store";
import { verifyIfPeripheralAssociate } from "../../../../helpers/peripheralsHelpers";
import { ipMatchWord } from "@constants/index";
import ErrorToast from "@components/Common/ErrorTost";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type FooterType = {
    peripheralType: string;
    setDataIsUpdated: Function;
    editedData: any;
    selectedRows: any;
    isAddPeripheralClicked: boolean;
    isEdited: boolean;
    setIsAddPeripheralClicked: Function;
    setEditedData: Function;
    setIsEdited: Function;
    setIsConfirmationEditModalOpened: Function;
    shopIds: any;
    isConsult: boolean;
    setIsConsult: Function;
};

export function CardFooter({
    isEdited,
    setDataIsUpdated,
    editedData,
    selectedRows,
    isAddPeripheralClicked,
    setIsAddPeripheralClicked,
    setEditedData,
    setIsEdited,
    setIsConfirmationEditModalOpened,
    shopIds,
    isConsult,
    setIsConsult,
}: FooterType) {
    const { t } = useTranslation();

    const {
        franchiseID,
        userID,
        operatorID,
        oneShop,
        shopID,
        resourceIp,
    } = useSnapshot(store);
    if (oneShop === true) {
        shopIds = [shopID];
    }
    const { isActionsClicked } = useSnapshot(generalStore);
    const [canValidate, setCanValidate] = React.useState<boolean>(true);
    const [error, setError] = React.useState<boolean>(false);
    const notStartWithSpaceRegExp = new RegExp(/^\S/);
    const appId = "KIOSK";
    const uuidUser = localStorage.getItem("uuidUser");

    let ifPeripheralAssociateObject: {
        isPeripheralAssociate: boolean;
        appId_children: any[];
    };
    const {
        user,
        designation,
        note,
        mark,
        reference,
        ipAddress,
        portNumber,
        password,
        posId,
        logDirectory,
        isNameExist,
        isModalOpened,
        inputIp,
        ipExist,
        emptyIp,
    } = useSnapshot(cashManagementDataStore);
    const shopId = oneShop ? shopID : selectedRows?.shopId;

    async function RedisPublishPeripheral() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/peripheral/redis`;
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify({
                        shopId: oneShop ? shopID : shopId,
                    }),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                    })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
    }

    async function handleValidateButtonClickEventCashManagement() {
        let hasIpConflict: number = 0;
        toast.dismiss();
        if (canValidate) {
            setCanValidate(false);

            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/cash_management`;
            shopIds.forEach(async (element: any) => {
                let savedData = {
                    userId: userID,
                    shopId: element,
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    data: [
                        {
                            id: getUniqueId(10),
                            designation: designation.trim(),
                            mark: mark,
                            note: note,
                            reference: reference,
                            ip: ipAddress,
                            port_number: portNumber,
                            user: user,
                            password: password,
                            server_directory: logDirectory,
                            pos_id: posId,
                            shopId: shopId,
                            edit_date: new Date(),
                            add_date: new Date(),
                            isArchived: false,
                        },
                    ],
                };
                let filteredData = JSON.parse(JSON.stringify(resourceIp))
                    ?.filter(
                        (item: any) =>
                            item?.shopId === element && item.ip.trim() !== ""
                    )
                    .map((y: any) => {
                        return y.ip;
                    });
                filteredData = [...new Set(filteredData)];
                if (filteredData.includes(ipAddress)) {
                    hasIpConflict++;
                }
                if (!filteredData.includes(ipAddress)) {
                    try {
                        mutate(
                            apiUrl,
                            await fetch(apiUrl, {
                                headers: {
                                    "Content-Type": "application/json",
                                    authorization: `Bareer ${localStorage.getItem(
                                        "jwt"
                                    )}`,
                                },
                                body: JSON.stringify(savedData),
                                method: "POST",
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    if (data.error) {
                                        alert(data.error);
                                        throw Error(
                                            "Error while saving selection!"
                                        );
                                    }
                                })
                        );
                    } catch (e: any) {
                        setCanValidate(true);
                        setError(true);
                        toast.error(`${t("There's an error")}!`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    }
                }
            });
            if (hasIpConflict > 0) {
                ErrorToast(
                    t(
                        "Please note, the device could not be added to one or more stores. Please check the IP address settings."
                    )
                );
            }
            if (error === false) {
                toast.success(`${t("The device is successfully added")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                });
                setCanValidate(true);
                setDataIsUpdated(true);
                resetAllPeripherals();
                resetPrinterData();
                resetCashManagementData();
                resetMoneticData();
                setEditedData([]);
                setIsEdited(false);
                activateCheckbox();
            }
        }
    }

    async function handleEditButtonClickEventCashManagement() {
        toast.dismiss();
        if (canValidate) {
            setCanValidate(false);
            ifPeripheralAssociateObject = await verifyIfPeripheralAssociate(
                editedData.peripheralId,
                shopId,
                franchiseID,
                userID,
                appId
            );
            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/cash_management?isPeripheralAssociate=${ifPeripheralAssociateObject.isPeripheralAssociate}`;
            let savedData = {
                userId: userID,
                shopId: shopId,
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                appId: appId,
                appId_children: ifPeripheralAssociateObject.appId_children,
                peripheralId: editedData.peripheralId,
                data: [
                    {
                        id: editedData.peripheralId,
                        designation: designation.trim(),
                        mark: mark,
                        reference: reference,
                        note: note,
                        ip: ipAddress,
                        shopId: shopID,
                        port_number: portNumber,
                        user: user,
                        password: password,
                        server_directory: logDirectory,
                        pos_id: posId,
                        edit_date: new Date(),
                        add_date: "",
                        isArchived: false,
                    },
                ],
            };

            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify(savedData),
                        method: "PUT",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error!");
                            }

                            toast.success(
                                `${t("The device was successfully changed")!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            if (
                                ifPeripheralAssociateObject.isPeripheralAssociate
                            )
                                setTimeout(RedisPublishPeripheral, 3000);
                            setCanValidate(true);
                            resetAllPeripherals();
                            resetPrinterData();
                            resetCashManagementData();
                            resetMoneticData();
                            setEditedData([]);
                            setIsEdited(false);
                            activateCheckbox();
                            setDataIsUpdated(true);
                            setIsAddPeripheralClicked(!isAddPeripheralClicked);
                        })
                );
            } catch (e: any) {
                setCanValidate(true);
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    const handleValidate = () => {
        if (designation === "") {
            setCashManagementData(true, "inputDesignation");
        }
        if (mark === "") {
            setCashManagementData(true, "inputMarque");
        }
        if (mark !== "") {
            if (!ipAddress.match(ipMatchWord)) {
                setCashManagementData(true, "inputIp");
            }
            if (portNumber === "") {
                setCashManagementData(true, "inputPort");
            }
            if (user === "") {
                setCashManagementData(true, "inputUser");
            }
            if (password === "") {
                setCashManagementData(true, "inputPassword");
            }
            if (posId === "") {
                setCashManagementData(true, "inputPosId");
            }
        }
        if (ipAddress === "") {
            setCashManagementData(true, "emptyIp");
        }
        if (!ipAddress.match(ipMatchWord)) {
            setCashManagementData(true, "inputIp");
        }
        setCashManagementData(
            shopIds.length > 1 || shopIds.length === 0
                ? false
                : checkIpIfExist(
                      resourceIp,
                      ipAddress,
                      editedData?.peripheralId !== undefined
                          ? editedData?.peripheralId
                          : "",
                      editedData?.peripheralId !== undefined
                          ? editedData?.shopId
                          : shopId
                  ),
            "ipExist"
        );
        if (
            editedData.length === 0 &&
            mark.length !== 0 &&
            designation.length !== 0 &&
            ipAddress.length !== 0 &&
            ipAddress.match(ipMatchWord) !== null &&
            portNumber.length !== 0 &&
            ipExist === false &&
            emptyIp === false &&
            !isNameExist &&
            designation.trim() !== "" &&
            designation.match(notStartWithSpaceRegExp)
        ) {
            if (
                mark === "Cashdro" &&
                posId.length !== 0 &&
                user.length !== 0 &&
                password.length !== 0
            ) {
                if (shopId === undefined) {
                    ErrorToast(`${t("Attention ! you must select a store.")!}`);
                } else {
                    handleValidateButtonClickEventCashManagement();
                }
            } else if (mark === "Glory") {
                if (shopId === undefined) {
                    ErrorToast(`${t("Attention ! you must select a store.")!}`);
                } else {
                    handleValidateButtonClickEventCashManagement();
                }
            }
        } else if (
            editedData.length !== 0 &&
            mark.length !== 0 &&
            designation.length !== 0 &&
            ipAddress.length !== 0 &&
            inputIp === false &&
            ipExist === false &&
            emptyIp === false &&
            ipAddress.match(ipMatchWord) !== null &&
            portNumber.length !== 0 &&
            !isNameExist &&
            designation.trim() !== "" &&
            designation.match(notStartWithSpaceRegExp)
        ) {
            if (
                mark === "Cashdro" &&
                posId.length !== 0 &&
                user.length !== 0 &&
                password.length !== 0
            ) {
                if (isEdited === false) {
                    setIsConfirmationEditModalOpened(true);
                    setCashManagementData(!isModalOpened, "isModalOpened");
                } else handleEditButtonClickEventCashManagement();
            } else if (mark === "Glory") {
                if (isEdited === false) {
                    setIsConfirmationEditModalOpened(true);
                    setCashManagementData(!isModalOpened, "isModalOpened");
                } else handleEditButtonClickEventCashManagement();
            }
        }
    };

    const activateCheckbox = () => {
        let checks = document.getElementsByClassName("PrivateSwitchBase-input");
        for (let i = 0; i < checks.length; i++) {
            checks[i].classList.remove("not-allowed-icon-check__clz");
        }
    };

    return (
        <React.Fragment>
            <CustomSecondaryColorButton
                outline
                rounded
                variant="light"
                className="mr-2 afp_btn_FRcancel cmn_btn_cancel"
                onClick={() => {
                    if (isEdited === true) {
                        setIsActionsClicked(!isActionsClicked);
                        setActions(() => {
                            setIsAddPeripheralClicked(!isAddPeripheralClicked);
                            resetAllPeripherals();
                            resetPrinterData();
                            resetCashManagementData();
                            resetMoneticData();
                            setEditedData([]);
                            setIsEdited(false);
                            activateCheckbox();
                            setIsAddPeripheralClicked(false);
                        });
                    } else {
                        resetAllPeripherals();
                        resetPrinterData();
                        resetCashManagementData();
                        resetMoneticData();
                        setEditedData([]);
                        setIsEdited(false);
                        activateCheckbox();
                        setIsAddPeripheralClicked(false);
                    }
                    setIsConsult(false);
                }}
            >
                <span> {t("Cancel")} </span>
            </CustomSecondaryColorButton>
            <CustomMainColorButton
                rounded
                variant="primary"
                className={`afp_btn_FRvalidate cmn_btn_validate ${
                    isConsult ? "not-allowed-icon__clz" : ""
                }`}
                onClick={handleValidate}
            >
                {t("Validate")}
            </CustomMainColorButton>
        </React.Fragment>
    );
}
