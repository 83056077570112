import React from "react";
import { motion } from "framer-motion/dist/framer-motion";
import { useTranslation } from "react-i18next/";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import PageTitle from "@components/Common/PageTitle";
import { CARD_MENU_LIST } from "./CardMenuList";
import CardMenu from "@helpers/CardMenus";
import { getPathAuthorization } from "@components/VerticalLayout";
import { store as userStore } from "@components/VerticalLayout/store";

export function Kyra() {
    const { t } = useTranslation();
 const { oneShop } = useSnapshot(userStore);
    return (
        <React.Fragment>
            <PageTitle title={"Applications"} />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1 }}
                className="page-content__clz"
            >
                <StyledBreadcrumb
                    items={[
                        {
                            item: "Settings".toUpperCase(),
                            link: `${process.env.REACT_APP_PORTAIL_URL}`,
                        },
                        {
                            item:
                                localStorage
                                    .getItem("selectedAccount")
                                    ?.toUpperCase() || "",
                            link: oneShop
                                ? "/setting/customer-account"
                                : "/compte",
                        },
                        {
                            item: t("Application").toUpperCase(),
                            link: "applications",
                        },
                        {
                            item: t("KYRA").toUpperCase(),
                            link: "#",
                        },
                    ]}
                />
                <div className="row p-4">
                    {CARD_MENU_LIST.map((cardMenu: any, index: any) => {
                        if (
                            getPathAuthorization(cardMenu.path).Visible === true
                        ) {
                            return (
                                <CardMenu
                                    key={index}
                                    title={t(cardMenu.title).toUpperCase()}
                                    number={cardMenu.number}
                                    Icon={cardMenu.Icon}
                                    description={t(cardMenu.description)}
                                    path={cardMenu.path}
                                    numberKiosk="0"
                                />
                            );
                        } else {
                            return null;
                        }
                    })}
                </div>
            </motion.div>
        </React.Fragment>
    );
}
