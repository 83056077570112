import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledLabel,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";

import { store } from "@components/VerticalLayout/store";
import { setGlobalDataIsUpdated } from "./store";
import { store as appStore } from "./Kiosk/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationDissociateType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    selectedRows: any;
};

export function ConfirmationDissociate({
    isModalOpened,
    setIsModalOpened,
    selectedRows,
}: ConfirmationDissociateType) {
    const { t } = useTranslation();
    const { userID, franchiseID, shopID, operatorID, oneShop } = useSnapshot(
        store
    );
    const { displayMode } = useSnapshot(appStore);
    const uuidUser = localStorage.getItem("uuidUser");

    async function handleDissociatePeripheral() {
        let localData: any = [];
        let apiURL = "";
        let shopIds: any = [];
        for (let element of selectedRows) {
            if (element.peripheralType !== undefined) {
                if (element.peripheralType === "cash_management") {
                    apiURL = `${process.env.REACT_APP_API_V2_URL}/settings/application/cash_management`;
                } else if (element.peripheralType === "printer") {
                    apiURL = `${process.env.REACT_APP_API_V2_URL}/settings/application/printer`;
                } else if (element.peripheralType === "monetic") {
                    apiURL = `${process.env.REACT_APP_API_V2_URL}/settings/application/monetic`;
                }
                let data: any = {
                    userId: userID,
                    shopId: oneShop ? shopID : element.shopId,
                    franchiseId: franchiseID,
                    appId: "KIOSK",
                    appId_children: [element.appId],
                    peripheralId: element.peripheralId,
                    peripheralName: element[t("Peripheral")],
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    isArchived: true,
                    applicationName: [element.appName],
                };
                if (!shopIds.includes(element.shopId))
                    shopIds.push(element.shopId);
                localData.push(
                    fetch(apiURL, {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify(data),
                    }).then((response) => response.json())
                );
            }
        }
        try {
            mutate(
                apiURL,
                await Promise.all(localData).then((result) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                        toast.success(
                            `${t(
                                "The dissociation was successfully completed."
                            )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        RedisPublish(shopIds);
                        setGlobalDataIsUpdated(true);
                    }
                })
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }
    async function RedisPublish(shopIds: any) {

        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/peripheral/redis`;
        if (!oneShop) {
            for (let shopId of shopIds) {
                try {
                    mutate(
                        apiUrl,
                        await fetch(apiUrl, {
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bareer ${localStorage.getItem(
                                    "jwt"
                                )}`,
                            },
                            body: JSON.stringify({
                                shopId: shopId,
                            }),
                            method: "POST",
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                if (data.error) {
                                    throw Error(
                                        "Error while saving selection!"
                                    );
                                }
                            })
                    );
                } catch (e: any) {
                    console.log(`${t("There's an error")}!`);
                }
            }
        } else {
            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify({
                            shopId: shopID,
                        }),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            }
                        })
                );
            } catch (e: any) {
                console.log(`${t("There's an error")}!`);
            }
        }
    }

    async function handleDissociateApplication() {
        let localData: any = [];
        let apiURL = "";
        let shopIds: any = [];
        for (let element of selectedRows) {
            if (element.subRows === undefined) {
                if (element.peripheralType === "cash_management") {
                    apiURL = `${process.env.REACT_APP_API_V2_URL}/settings/application/cash_management`;
                } else if (element.peripheralType === "printer") {
                    apiURL = `${process.env.REACT_APP_API_V2_URL}/settings/application/printer`;
                } else if (element.peripheralType === "monetic") {
                    apiURL = `${process.env.REACT_APP_API_V2_URL}/settings/application/monetic`;
                }
                let data: any = {
                    userId: userID,
                    shopId: oneShop ? shopID : element.shopId,
                    franchiseId: franchiseID,
                    appId: "KIOSK",
                    appId_children: [element.appId],
                    peripheralId: element.peripheralId,
                    peripheralName: element.peripheral,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    isArchived: true,
                    applicationName: [element.appName],
                };
                if (!shopIds.includes(element.shopId))
                    shopIds.push(element.shopId);
                localData.push(
                    fetch(apiURL, {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify(data),
                    }).then((response) => response.json())
                );
            }
        }
        try {
            mutate(
                apiURL,
                await Promise.all(localData).then((result) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                        toast.success(
                            `${t(
                                "The dissociation was successfully completed."
                            )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        RedisPublish(shopIds);
                         setGlobalDataIsUpdated(true);
                    }
                })
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                }}
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {displayMode !== t("View by device")
                        ? t("Please confirm unpairing of the selected devices.")
                        : t("Please confirm unpairing of the application")}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    className="afa_btn_cancelAlert"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2 afa_btn_confirmAlert"
                    variant="primary"
                    onClick={async () => {
                        setIsModalOpened(!isModalOpened);
                        displayMode !== t("View by device")
                            ? await handleDissociatePeripheral()
                            : await handleDissociateApplication();
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
