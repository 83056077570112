import { StyledH1 } from "@aureskonnect/react-ui";
import CustomReactTable from "@components/Common/CustomReactTable";
import { ColumnDef } from "@tanstack/react-table";
import classnames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import { setCategoryAssignment } from "../../store";

import ArrowCircleLeftSvgIcon from "@components/Common/SvgIcons/ArrowCircleLeftSvgIcon";

import "./index.css";

export default function CategoryAssignment({ idStore, rowData }: any) {
    const { t } = useTranslation();
    const [data, setData] = React.useState<any>([]);

    const [newCategoryAssignment, setNewCategoryAssignment] = React.useState<
        any
    >(
        rowData?.template === undefined ||
            Object.keys(rowData.template).length === 0
            ? []
            : rowData.template.categoryAssignment.newCategoryAssignment
    );
    const schemaFranchise = localStorage.getItem("schemaFranchise");
    const [globalFilter, setGlobalFilter] = React.useState<string>("");
    const [
        newCategoryAssignmentGlobalFilter,
        setNewCategoryAssignmentGlobalFilter,
    ] = React.useState<string>("");
    const [rowSelection, setRowSelection] = React.useState<
        Record<string, boolean>
    >({});
    const [rowSelection1, setRowSelection1] = React.useState<
        Record<string, boolean>
    >({});

    const Columns: ColumnDef<any>[] = React.useMemo(() => {
        return [
            {
                accessorKey: "title",
                header: () => (
                    <span style={{ width: "327px" }}>
                        {t("Category designation")}
                    </span>
                ),
            },
        ];
    }, [t]);
    const dataFetch = React.useCallback(async () => {
        let listCategory: any = [];
        const data: any = await (
            await fetch(
                `https://dev-catalogue-api.aureskonnect.com/api_etk_article_bd/v1/cards/${schemaFranchise}/categories/${idStore}`
            )
        ).json();

        data.forEach((element: any) => {
            listCategory.push(element.category);
        });
        setData(listCategory);
    }, [idStore, schemaFranchise]);

    function handleOnClickEvent() {
        let newData: any = [...newCategoryAssignment];
        let table = data.filter((elt: any) =>
            Object.keys(rowSelection).includes(data.indexOf(elt).toString())
        );

        table.forEach((element: any) => {
            if (
                newCategoryAssignment.find(
                    (item: any) => item.title === element.title
                ) === undefined
            ) {
                newData.push(element);
            }
        });
        setNewCategoryAssignment(newData.flat());
        setRowSelection({});
    }
    React.useEffect(() => {
        dataFetch();
    }, [dataFetch]);

    React.useEffect(() => {
        setCategoryAssignment(
            JSON.parse(
                JSON.stringify({
                    newCategoryAssignment: newCategoryAssignment,
                })
            )
        );
        // eslint-disable-next-line
    }, [newCategoryAssignment]);

    return (
        <React.Fragment>
            <StyledH1 className="m-3">{t("Category assignment")}</StyledH1>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "3fr 1fr 3fr",
                }}
            >
                <div
                    style={{
                        background:
                            "rgb(255, 255, 255) none no-repeat scroll 0% 0% padding-box",
                        border: "1px solid rgb(230, 230, 230)",
                        color: "#212529 ",
                        marginLeft: "2%",
                        height: "600px",
                    }}
                >
                    <CustomReactTable
                        tableColumns={Columns}
                        data={data}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        customRowSelection={rowSelection}
                        setRowSelection={setRowSelection}
                        isRowSelectionEnabled
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginTop: "70%", gap: "2%", marginLeft: "25%" }}
                >
                    <ArrowCircleLeftSvgIcon
                        style={{
                            height: "45px",
                            width: "100px",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            handleOnClickEvent();
                        }}
                        className={`${classnames({
                            "not-allowed-icon__clz":
                                Object.values(rowSelection).length === 0,
                        })}`}
                    />

                    <ArrowCircleLeftSvgIcon
                        style={{
                            height: "45px",
                            width: "100px",
                            cursor: "pointer",
                            marginTop: "20%",
                        }}
                        onClick={() => {
                            let newData: any = structuredClone(
                                newCategoryAssignment
                            );

                            Object.keys(rowSelection1)
                                .reverse()
                                .forEach((elt: any) => {
                                    newData.splice(Number(elt), 1);
                                });
                            setNewCategoryAssignment(newData);
                            setRowSelection1({});
                        }}
                        className={`${classnames("rotate__clz", {
                            "not-allowed-icon__clz":
                                Object.values(rowSelection1).length === 0,
                        })}`}
                    />
                </div>
                <div
                    style={{
                        background:
                            "rgb(255, 255, 255) none no-repeat scroll 0% 0% padding-box",
                        border: "1px solid rgb(230, 230, 230)",
                        color: "#212529 ",
                        marginRight: "2%",
                        height: "600px",
                    }}
                >
                    <CustomReactTable
                        tableColumns={Columns}
                        data={newCategoryAssignment}
                        globalFilter={newCategoryAssignmentGlobalFilter}
                        setGlobalFilter={setNewCategoryAssignmentGlobalFilter}
                        customRowSelection={rowSelection1}
                        setRowSelection={setRowSelection1}
                        isRowSelectionEnabled
                    />
                </div>
            </div>
        </React.Fragment>
    );
}
