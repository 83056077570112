import React from "react";
import { useTranslation } from "react-i18next";
import { StyledH2 } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useSnapshot } from "valtio";

import { store, setIsCreatedCoping } from "../../../store/project";

import { capitalize } from "@helpers/general";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";
import CardFooterCreatedCoping from "./CardFooterCreatedCoping";

type ModalCreatedCopingType = {
    rowData: any;
    setIsDataUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    shopId: string;
    dataProject: any;
    setSelectedRows: Function;
};

export default function ModalCreatedCoping({
    rowData,
    setIsDataUpdated,
    shopId,
    dataProject,
    setSelectedRows,
}: ModalCreatedCopingType) {
    const { t } = useTranslation();
    const { isCreatedCoping } = useSnapshot(store);
    return (
        <React.Fragment>
            <Modal centered isOpen={isCreatedCoping}>
                <ModalHeader
                    toggle={() => {
                        setIsCreatedCoping!(!{ isCreatedCoping });
                        setSelectedRows([]);
                    }}
                >
                    <StyledH2>{capitalize(t("Project duplication"))}</StyledH2>
                </ModalHeader>
                <ModalBody
                    style={{ font: "normal normal 400 18px/30px Segoe UI" }}
                >
                    {t("Do you want to confirm the duplication of the project")}
                    <CustomColumnDisplay
                        text={`''${rowData[t("Designation")]}''?`}
                    />
                </ModalBody>
                <CardFooterCreatedCoping
                    rowData={rowData}
                    setIsDataUpdated={setIsDataUpdated}
                    shopId={shopId}
                    dataProject={dataProject}
                    setSelectedRows={setSelectedRows}
                />
            </Modal>
        </React.Fragment>
    );
}
