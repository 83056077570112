import React from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import { ModalBody } from "reactstrap";
import { useSnapshot } from "valtio";

import {
    setIsConfigurationCashManagementClicked,
    store,
} from "../../../../store";
import { setCollectionAlert } from "../../store";

import { Footer } from "../../Footer";

type CashManagementCollectionAlert = {
    rowData: any;
    oneShop: boolean;
    setDataIsUpdated: Function;
};
export function CollectionAlert({
    rowData,
    setDataIsUpdated,
}: CashManagementCollectionAlert) {
    const { t } = useTranslation();
    const { isConfigurationCashManagementClicked } = useSnapshot(store);

    const [
        isActivateTicketWithdrawalAlert,
        setIsActivateTicketWithdrawalAlert,
    ] = React.useState<boolean>(
        Object.values(rowData.collection_alert).length > 0
            ? rowData.collection_alert.withdrawal_tickets
                  .ticket_withdrawal_alert
            : false
    );
    const [
        isSendAlertWhenTicketOpened,
        setIsSendAlertWhenTicketOpened,
    ] = React.useState<boolean>(
        Object.values(rowData.collection_alert).length > 0
            ? rowData.collection_alert.withdrawal_tickets.ticket_opening_alert
                  .state
            : false
    );
    const [
        isSendAlertWhenTicketClosed,
        setIsSendAlertWhenTicketClosed,
    ] = React.useState<boolean>(
        Object.values(rowData.collection_alert).length > 0
            ? rowData.collection_alert.withdrawal_tickets.ticket_closing_alert
                  .state
            : false
    );
    const [
        isActivatePiecesWithdrawalAlert,
        setIsActivatePiecesWithdrawalAlert,
    ] = React.useState<boolean>(
        Object.values(rowData.collection_alert).length > 0
            ? rowData.collection_alert.withdrawal_coins.coin_withdrawal_alert
            : false
    );
    const [
        isSendAlertWhenPiecesOpened,
        setIsSendAlertWhenPiecesOpened,
    ] = React.useState<boolean>(
        Object.values(rowData.collection_alert).length > 0
            ? rowData.collection_alert.withdrawal_coins.coin_opening_alert.state
            : false
    );
    const [
        isSendAlertWhenPiecesClosed,
        setIsSendAlertWhenPiecesClosed,
    ] = React.useState<boolean>(
        Object.values(rowData.collection_alert).length > 0
            ? rowData.collection_alert.withdrawal_coins.coin_closing_alert.state
            : false
    );
    const [
        isActivateTicketSuspectAlert,
        setIsActivateTicketSuspectAlert,
    ] = React.useState<boolean>(
        Object.values(rowData.collection_alert).length > 0
            ? rowData.collection_alert.suspicious_withdrawal_tickets
                  .ticket_suspicious_l_alert
            : false
    );
    const [
        isActivatePiecesSuspectAlert,
        setIsActivatePiecesSuspectAlert,
    ] = React.useState<boolean>(
        Object.values(rowData.collection_alert).length > 0
            ? rowData.collection_alert.suspicious_withdrawal_coins
                  .coins_suspicious_l_alert
            : false
    );
    const [isSendAlertTicket, setIsSendAlertTicket] = React.useState<boolean>(
        Object.values(rowData.collection_alert).length > 0
            ? rowData.collection_alert.suspicious_withdrawal_tickets
                  .ticket_alert.state
            : false
    );
    const [isSendAlertPieces, setIsSendAlertPieces] = React.useState<boolean>(
        Object.values(rowData.collection_alert).length > 0
            ? rowData.collection_alert.suspicious_withdrawal_coins.coins_alert
                  .state
            : false
    );
    const [
        alertSentWhenTicketOpened,
        setAlertSentWhenTicketOpened,
    ] = React.useState<any>(
        Object.values(rowData.collection_alert).length > 0
            ? [
                  rowData.collection_alert.withdrawal_tickets
                      .ticket_opening_alert.sms && "SMS",
                  rowData.collection_alert.withdrawal_tickets
                      .ticket_opening_alert.email && "E-mail",
              ]
            : ["SMS"]
    );
    const [
        alertFormWhenTicketOpened,
        setAlertFormWhenTicketOpened,
    ] = React.useState<string>(
        Object.keys(rowData.collection_alert).length > 0
            ? rowData.collection_alert.withdrawal_tickets
                  .send_format_opening_alert.value
            : "XLS"
    );
    const [
        alertFormWhenTicketClosed,
        setAlertFormWhenTickeClosed,
    ] = React.useState<string>(
        Object.keys(rowData.collection_alert).length > 0
            ? rowData.collection_alert.withdrawal_tickets
                  .send_format_closing_alert.value
            : "XLS"
    );
    const [
        alertFormWhenPiecesOpened,
        setAlertFormWhenPiecesOpened,
    ] = React.useState<string>(
        Object.keys(rowData.collection_alert).length > 0
            ? rowData.collection_alert.withdrawal_coins
                  .send_format_opening_alert.value
            : "XLS"
    );
    const [
        alertFormWhenPiecesClosed,
        setAlertFormWhenPiecesClosed,
    ] = React.useState<string>(
        Object.keys(rowData.collection_alert).length > 0
            ? rowData.collection_alert.withdrawal_coins
                  .send_format_closing_alert.value
            : "XLS"
    );
    const [alertFormTicket, setAlertFormTicket] = React.useState<string>(
        Object.keys(rowData.collection_alert).length > 0
            ? rowData.collection_alert.suspicious_withdrawal_tickets.send_format
                  .value
            : "XLS"
    );
    const [alertFormPieces, setAlertFormPieces] = React.useState<string>(
        Object.keys(rowData.collection_alert).length > 0
            ? rowData.collection_alert.suspicious_withdrawal_coins.send_format
                  .value
            : "XLS"
    );
    const [
        alertSentWhenTicketClosed,
        setAlertSentWhenTicketClosed,
    ] = React.useState<any>(
        Object.values(rowData.collection_alert).length > 0
            ? [
                  rowData.collection_alert.withdrawal_tickets
                      .ticket_closing_alert.sms && "SMS",
                  rowData.collection_alert.withdrawal_tickets
                      .ticket_closing_alert.email && "E-mail",
              ]
            : ["SMS"]
    );
    const [
        alertSentWhenPiecesOpened,
        setAlertSentWhenPiecesOpened,
    ] = React.useState<any>(
        Object.values(rowData.collection_alert).length > 0
            ? [
                  rowData.collection_alert.withdrawal_coins.coin_opening_alert
                      .sms && "SMS",
                  rowData.collection_alert.withdrawal_coins.coin_opening_alert
                      .email && "E-mail",
              ]
            : ["SMS"]
    );
    const [
        alertSentWhenPiecesClosed,
        setAlertSentWhenPiecesClosed,
    ] = React.useState<any>(
        Object.values(rowData.collection_alert).length > 0
            ? [
                  rowData.collection_alert.withdrawal_coins.coin_closing_alert
                      .sms && "SMS",
                  rowData.collection_alert.withdrawal_coins.coin_closing_alert
                      .email && "E-mail",
              ]
            : ["SMS"]
    );
    const [alertSentAlertTicket, setAlertSentAlertTicket] = React.useState<any>(
        Object.values(rowData.collection_alert).length > 0
            ? [
                  rowData.collection_alert.suspicious_withdrawal_tickets
                      .ticket_alert.sms && "SMS",
                  rowData.collection_alert.suspicious_withdrawal_tickets
                      .ticket_alert.email && "E-mail",
              ]
            : ["SMS"]
    );
    const [alertSentAlertPieces, setAlertSentAlertPieces] = React.useState<any>(
        Object.values(rowData.collection_alert).length > 0
            ? [
                  rowData.collection_alert.suspicious_withdrawal_coins
                      .coins_alert.sms && "SMS",
                  rowData.collection_alert.suspicious_withdrawal_coins
                      .coins_alert.email && "E-mail",
              ]
            : ["SMS"]
    );
    const [
        isEmailIncludeWhenTicketOpened,
        setIsEmailIncludeWhenTicketOpened,
    ] = React.useState<boolean>(alertSentWhenTicketOpened.includes("E-mail"));
    const [
        isEmailIncludeWhenTicketClosed,
        setIsEmailIncludeWhenTicketClosed,
    ] = React.useState<boolean>(alertSentWhenTicketClosed.includes("E-mail"));
    const [
        isEmailIncludeWhenPiecesOpened,
        setIsEmailIncludeWhenPiecesOpened,
    ] = React.useState<boolean>(alertSentWhenPiecesOpened.includes("E-mail"));
    const [
        isEmailIncludeWhenPiecesClosed,
        setIsEmailIncludeWhenPiecesClosed,
    ] = React.useState<boolean>(alertSentWhenPiecesClosed.includes("E-mail"));
    const [isEmailIncludeTicket, setIsEmailIncludeTicket] = React.useState<
        boolean
    >(alertSentAlertTicket.includes("E-mail"));
    const [isEmailIncludePieces, setIsEmailIncludePieces] = React.useState<
        boolean
    >(alertSentAlertPieces.includes("E-mail"));

    React.useEffect(() => {
        setCollectionAlert({
            withdrawal_tickets: {
                ticket_withdrawal_alert: isActivateTicketWithdrawalAlert,
                ticket_opening_alert: {
                    state: isSendAlertWhenTicketOpened,
                    sms:
                        Object.values(rowData.collection_alert).length > 0
                            ? rowData.collection_alert.withdrawal_tickets
                                  .ticket_opening_alert.sms
                            : alertSentWhenTicketOpened.includes("SMS"),
                    email:
                        Object.values(rowData.collection_alert).length > 0
                            ? rowData.collection_alert.withdrawal_tickets
                                  .ticket_opening_alert.email
                            : alertSentWhenTicketOpened.includes("E-mail"),
                },
                send_format_opening_alert: {
                    value: alertFormWhenTicketOpened,
                },
                ticket_closing_alert: {
                    state: isSendAlertWhenTicketClosed,
                    sms:
                        Object.values(rowData.collection_alert).length > 0
                            ? rowData.collection_alert.withdrawal_tickets
                                  .ticket_closing_alert.sms
                            : alertSentWhenTicketClosed.includes("SMS"),
                    email:
                        Object.values(rowData.collection_alert).length > 0
                            ? rowData.collection_alert.withdrawal_tickets
                                  .ticket_closing_alert.email
                            : alertSentWhenTicketClosed.includes("E-mail"),
                },
                send_format_closing_alert: {
                    value: alertFormWhenTicketClosed,
                },
            },
            withdrawal_coins: {
                coin_withdrawal_alert: isActivatePiecesWithdrawalAlert,
                coin_opening_alert: {
                    state: isSendAlertWhenPiecesOpened,
                    sms:
                        Object.values(rowData.collection_alert).length > 0
                            ? rowData.collection_alert.withdrawal_coins
                                  .coin_opening_alert.sms
                            : alertSentWhenPiecesOpened.includes("SMS"),
                    email:
                        Object.values(rowData.collection_alert).length > 0
                            ? rowData.collection_alert.withdrawal_coins
                                  .coin_opening_alert.email
                            : alertSentWhenPiecesOpened.includes("E-mail"),
                },
                send_format_opening_alert: {
                    value: alertFormWhenPiecesOpened,
                },
                coin_closing_alert: {
                    state: isSendAlertWhenPiecesClosed,
                    sms:
                        Object.values(rowData.collection_alert).length > 0
                            ? rowData.collection_alert.withdrawal_coins
                                  .coin_closing_alert.sms
                            : alertSentWhenPiecesClosed.includes("SMS"),
                    email:
                        Object.values(rowData.collection_alert).length > 0
                            ? rowData.collection_alert.withdrawal_coins
                                  .coin_closing_alert.email
                            : alertSentWhenPiecesClosed.includes("E-mail"),
                },
                send_format_closing_alert: {
                    value: alertFormWhenPiecesClosed,
                },
            },
            suspicious_withdrawal_tickets: {
                ticket_suspicious_l_alert: isActivateTicketSuspectAlert,
                ticket_alert: {
                    state: isSendAlertTicket,
                    sms:
                        Object.values(rowData.collection_alert).length > 0
                            ? rowData.collection_alert
                                  .suspicious_withdrawal_tickets.ticket_alert
                                  .sms
                            : alertSentAlertTicket.includes("SMS"),
                    email:
                        Object.values(rowData.collection_alert).length > 0
                            ? rowData.collection_alert
                                  .suspicious_withdrawal_tickets.ticket_alert
                                  .email
                            : alertSentAlertTicket.includes("E-mail"),
                },
                send_format: {
                    value: alertFormTicket,
                },
            },
            suspicious_withdrawal_coins: {
                coins_suspicious_l_alert: isActivatePiecesSuspectAlert,
                coins_alert: {
                    state: isSendAlertPieces,
                    sms:
                        Object.values(rowData.collection_alert).length > 0
                            ? rowData.collection_alert
                                  .suspicious_withdrawal_coins.coins_alert.sms
                            : alertSentAlertPieces.includes("SMS"),
                    email:
                        Object.values(rowData.collection_alert).length > 0
                            ? rowData.collection_alert
                                  .suspicious_withdrawal_coins.coins_alert.email
                            : alertSentAlertPieces.includes("E-mail"),
                },
                send_format: {
                    value: alertFormPieces,
                },
            },
        });
    }, [
        alertFormPieces,
        alertFormTicket,
        alertFormWhenPiecesClosed,
        alertFormWhenPiecesOpened,
        alertFormWhenTicketClosed,
        alertFormWhenTicketOpened,
        alertSentAlertPieces,
        alertSentAlertTicket,
        alertSentWhenPiecesClosed,
        alertSentWhenPiecesOpened,
        alertSentWhenTicketClosed,
        alertSentWhenTicketOpened,
        isActivatePiecesSuspectAlert,
        isActivatePiecesWithdrawalAlert,
        isActivateTicketSuspectAlert,
        isActivateTicketWithdrawalAlert,
        isSendAlertPieces,
        isSendAlertTicket,
        isSendAlertWhenPiecesClosed,
        isSendAlertWhenPiecesOpened,
        isSendAlertWhenTicketClosed,
        isSendAlertWhenTicketOpened,
        rowData.collection_alert,
    ]);

    return (
        <React.Fragment>
            <ModalBody style={{ height: "84.1%", fontSize: "17px" }}>
                <div className="d-flex  align-items-center justify-content-between px-2 pb-2">
                    <b>{t("Withdrawal of ticket cassettes")}</b>
                </div>
                <div
                    className="d-flex  align-items-center justify-content-between p-2"
                    style={{ height: "25px" }}
                >
                    <div>{t("Enable Ticket Cassette Removal Alert")}</div>
                    <div className="float-right mr-4">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isActivateTicketWithdrawalAlert}
                            onChange={() => {
                                if (
                                    Object.values(rowData.collection_alert)
                                        .length > 0
                                ) {
                                    rowData.collection_alert.withdrawal_tickets.ticket_withdrawal_alert = !rowData
                                        .collection_alert.withdrawal_tickets
                                        .ticket_withdrawal_alert;
                                    setIsActivateTicketWithdrawalAlert(
                                        rowData.collection_alert
                                            .withdrawal_tickets
                                            .ticket_withdrawal_alert
                                    );
                                } else {
                                    setIsActivateTicketWithdrawalAlert(
                                        !isActivateTicketWithdrawalAlert
                                    );
                                }
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={55}
                            height={20}
                        />
                    </div>
                </div>{" "}
                <div className="row p-2" style={{ height: "26px" }}>
                    <div className="col-6">
                        {t("Send an alert when the ticket cassette opens by")}
                    </div>

                    <div className="col">
                        <span className="mt-2">
                            <input
                                type="checkbox"
                                id="example"
                                disabled={!isSendAlertWhenTicketOpened}
                                value={`${t("SMS")}`}
                                defaultChecked={
                                    Object.values(rowData.collection_alert)
                                        .length > 0
                                        ? rowData.collection_alert
                                              .withdrawal_tickets
                                              .ticket_opening_alert.sms
                                        : true
                                }
                                onChange={(e: any) => {
                                    if (
                                        Object.values(rowData.collection_alert)
                                            .length > 0
                                    ) {
                                        if (e.target.checked) {
                                            rowData.collection_alert.withdrawal_tickets.ticket_opening_alert.sms = !rowData
                                                .collection_alert
                                                .withdrawal_tickets
                                                .ticket_opening_alert.sms;
                                        } else {
                                            rowData.collection_alert.withdrawal_tickets.ticket_opening_alert.sms = !rowData
                                                .collection_alert
                                                .withdrawal_tickets
                                                .ticket_opening_alert.sms;
                                        }
                                    } else {
                                        if (e.target.checked) {
                                            setAlertSentWhenTicketOpened([
                                                ...alertSentWhenTicketOpened,
                                                "SMS",
                                            ]);
                                        } else {
                                            setAlertSentWhenTicketOpened([
                                                ...alertSentWhenTicketOpened.filter(
                                                    (element: any) =>
                                                        element !== "SMS"
                                                ),
                                            ]);
                                        }
                                    }
                                }}
                            />
                            <label className="ml-3">{t("SMS")}</label>
                        </span>
                        <span className="ml-3 mt-2">
                            <input
                                type="checkbox"
                                id="example"
                                disabled={!isSendAlertWhenTicketOpened}
                                value={`${t("E-mail")}`}
                                defaultChecked={
                                    Object.values(rowData.collection_alert)
                                        .length > 0
                                        ? rowData.collection_alert
                                              .withdrawal_tickets
                                              .ticket_opening_alert.email
                                        : false
                                }
                                onChange={(e: any) => {
                                    if (
                                        Object.values(rowData.collection_alert)
                                            .length > 0
                                    ) {
                                        if (e.target.checked) {
                                            rowData.collection_alert.withdrawal_tickets.ticket_opening_alert.email = !rowData
                                                .collection_alert
                                                .withdrawal_tickets
                                                .ticket_opening_alert.email;
                                            setIsEmailIncludeWhenTicketOpened(
                                                true
                                            );
                                        } else {
                                            rowData.collection_alert.withdrawal_tickets.ticket_opening_alert.email = !rowData
                                                .collection_alert
                                                .withdrawal_tickets
                                                .ticket_opening_alert.email;
                                            setIsEmailIncludeWhenTicketOpened(
                                                false
                                            );
                                        }
                                    } else {
                                        if (e.target.checked) {
                                            setAlertSentWhenTicketOpened([
                                                ...alertSentWhenTicketOpened,
                                                "E-mail",
                                            ]);
                                            setIsEmailIncludeWhenTicketOpened(
                                                true
                                            );
                                        } else {
                                            setAlertSentWhenTicketOpened([
                                                ...alertSentWhenTicketOpened.filter(
                                                    (element: any) =>
                                                        element !== "E-mail"
                                                ),
                                            ]);
                                            setIsEmailIncludeWhenTicketOpened(
                                                false
                                            );
                                        }
                                    }
                                }}
                            />
                            <label className="ml-3">{t("E-mail")}</label>
                        </span>
                    </div>

                    <div className="col-1 mr-4" style={{ textAlign: "end" }}>
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isSendAlertWhenTicketOpened}
                            onChange={() => {
                                if (
                                    Object.values(rowData.collection_alert)
                                        .length > 0
                                ) {
                                    rowData.collection_alert.withdrawal_tickets.ticket_opening_alert.state = !rowData
                                        .collection_alert.withdrawal_tickets
                                        .ticket_opening_alert.state;
                                    setIsSendAlertWhenTicketOpened(
                                        rowData.collection_alert
                                            .withdrawal_tickets
                                            .ticket_opening_alert.state
                                    );
                                } else {
                                    setIsSendAlertWhenTicketOpened(
                                        !isSendAlertWhenTicketOpened
                                    );
                                }
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={55}
                            height={20}
                        />
                    </div>
                </div>
                <div
                    className="row p-2"
                    style={{ marginTop: "2px", height: "30px" }}
                >
                    <div className="col-6">{t("Send alerts in format")}</div>

                    <div className="col">
                        <AvForm
                            model={{
                                form: alertFormWhenTicketOpened,
                                checkItOut: true,
                            }}
                        >
                            <AvRadioGroup
                                name="form"
                                required
                                inline
                                disabled={
                                    !isSendAlertWhenTicketOpened ||
                                    !isEmailIncludeWhenTicketOpened
                                }
                            >
                                <AvRadio
                                    id="xls"
                                    label={`${t("XLS")}`}
                                    value="XLS"
                                    onChange={() => {
                                        if (
                                            Object.values(
                                                rowData.collection_alert
                                            ).length > 0
                                        ) {
                                            rowData.collection_alert.withdrawal_tickets.send_format_opening_alert.value =
                                                "XLS";
                                            setAlertFormWhenTicketOpened(
                                                rowData.collection_alert
                                                    .withdrawal_tickets
                                                    .send_format_opening_alert
                                                    .value
                                            );
                                        } else {
                                            setAlertFormWhenTicketOpened("XLS");
                                        }
                                    }}
                                />

                                <AvRadio
                                    id="twoRadio"
                                    label={`${t("Word")}`}
                                    name="radio"
                                    value="Word"
                                    onChange={() => {
                                        if (
                                            Object.values(
                                                rowData.collection_alert
                                            ).length > 0
                                        ) {
                                            rowData.collection_alert.withdrawal_tickets.send_format_opening_alert.value =
                                                "Word";
                                            setAlertFormWhenTicketOpened(
                                                rowData.collection_alert
                                                    .withdrawal_tickets
                                                    .send_format_opening_alert
                                                    .value
                                            );
                                        } else {
                                            setAlertFormWhenTicketOpened(
                                                "Word"
                                            );
                                        }
                                    }}
                                />
                                <AvRadio
                                    id="threeRadio"
                                    label={`${t("XML")}`}
                                    name="radio"
                                    value="XML"
                                    onChange={() => {
                                        if (
                                            Object.values(
                                                rowData.collection_alert
                                            ).length > 0
                                        ) {
                                            rowData.collection_alert.withdrawal_tickets.send_format_opening_alert.value =
                                                "XML";
                                            setAlertFormWhenTicketOpened(
                                                rowData.collection_alert
                                                    .withdrawal_tickets
                                                    .send_format_opening_alert
                                                    .value
                                            );
                                        } else {
                                            setAlertFormWhenTicketOpened("XML");
                                        }
                                    }}
                                />
                                <AvRadio
                                    id="threeRadio"
                                    label={`${t("Txt")}`}
                                    name="radio"
                                    value="Txt"
                                    checked={true}
                                    onChange={() => {
                                        if (
                                            Object.values(
                                                rowData.collection_alert
                                            ).length > 0
                                        ) {
                                            rowData.collection_alert.withdrawal_tickets.send_format_opening_alert.value =
                                                "Txt";
                                            setAlertFormWhenTicketOpened(
                                                rowData.collection_alert
                                                    .withdrawal_tickets
                                                    .send_format_opening_alert
                                                    .value
                                            );
                                        } else {
                                            setAlertFormWhenTicketOpened("Txt");
                                        }
                                    }}
                                />
                            </AvRadioGroup>
                        </AvForm>
                    </div>
                </div>
                <div className="row p-2" style={{ height: "24px" }}>
                    <div className="col-6">
                        {t(
                            "Send an alert when the ticket cassette is closed by"
                        )}
                    </div>

                    <div className="col">
                        <span className="mt-2">
                            <input
                                type="checkbox"
                                id="example"
                                value={`${t("SMS")}`}
                                disabled={!isSendAlertWhenTicketClosed}
                                defaultChecked={
                                    Object.values(rowData.collection_alert)
                                        .length > 0
                                        ? rowData.collection_alert
                                              .withdrawal_tickets
                                              .ticket_closing_alert.sms
                                        : true
                                }
                                onChange={(e: any) => {
                                    if (
                                        Object.values(rowData.collection_alert)
                                            .length > 0
                                    ) {
                                        if (e.target.checked) {
                                            rowData.collection_alert.withdrawal_tickets.ticket_closing_alert.sms = !rowData
                                                .collection_alert
                                                .withdrawal_tickets
                                                .ticket_closing_alert.sms;
                                        } else {
                                            rowData.collection_alert.withdrawal_tickets.ticket_closing_alert.sms = !rowData
                                                .collection_alert
                                                .withdrawal_tickets
                                                .ticket_closing_alert.sms;
                                        }
                                    } else {
                                        if (e.target.checked) {
                                            setAlertSentWhenTicketClosed([
                                                ...alertSentWhenTicketClosed,
                                                "SMS",
                                            ]);
                                        } else {
                                            setAlertSentWhenTicketClosed([
                                                ...alertSentWhenTicketClosed.filter(
                                                    (element: any) =>
                                                        element !== "SMS"
                                                ),
                                            ]);
                                        }
                                    }
                                }}
                            />
                            <label className="ml-3">{t("SMS")}</label>
                        </span>
                        <span className="ml-3 mt-2">
                            <input
                                type="checkbox"
                                id="example"
                                disabled={!isSendAlertWhenTicketClosed}
                                value={`${t("E-mail")}`}
                                defaultChecked={
                                    Object.values(rowData.collection_alert)
                                        .length > 0
                                        ? rowData.collection_alert
                                              .withdrawal_tickets
                                              .ticket_closing_alert.email
                                        : false
                                }
                                onChange={(e: any) => {
                                    if (
                                        Object.values(rowData.collection_alert)
                                            .length > 0
                                    ) {
                                        if (e.target.checked) {
                                            rowData.collection_alert.withdrawal_tickets.ticket_closing_alert.email = !rowData
                                                .collection_alert
                                                .withdrawal_tickets
                                                .ticket_closing_alert.email;
                                            setIsEmailIncludeWhenTicketClosed(
                                                true
                                            );
                                        } else {
                                            rowData.collection_alert.withdrawal_tickets.ticket_closing_alert.email = !rowData
                                                .collection_alert
                                                .withdrawal_tickets
                                                .ticket_closing_alert.email;
                                            setIsEmailIncludeWhenTicketClosed(
                                                false
                                            );
                                        }
                                    } else {
                                        if (e.target.checked) {
                                            setAlertSentWhenTicketClosed([
                                                ...alertSentWhenTicketClosed,
                                                "E-mail",
                                            ]);
                                            setIsEmailIncludeWhenTicketClosed(
                                                true
                                            );
                                        } else {
                                            setAlertSentWhenTicketClosed([
                                                ...alertSentWhenTicketClosed.filter(
                                                    (element: any) =>
                                                        element !== "E-mail"
                                                ),
                                            ]);
                                            setIsEmailIncludeWhenTicketClosed(
                                                false
                                            );
                                        }
                                    }
                                }}
                            />
                            <label className="ml-3">{t("E-mail")}</label>
                        </span>
                    </div>

                    <div className="col-1 mr-4" style={{ textAlign: "end" }}>
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isSendAlertWhenTicketClosed}
                            onChange={() => {
                                if (
                                    Object.values(rowData.collection_alert)
                                        .length > 0
                                ) {
                                    rowData.collection_alert.withdrawal_tickets.ticket_closing_alert.state = !rowData
                                        .collection_alert.withdrawal_tickets
                                        .ticket_closing_alert.state;
                                    setIsSendAlertWhenTicketClosed(
                                        rowData.collection_alert
                                            .withdrawal_tickets
                                            .ticket_closing_alert.state
                                    );
                                } else {
                                    setIsSendAlertWhenTicketClosed(
                                        !isSendAlertWhenTicketClosed
                                    );
                                }
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={55}
                            height={20}
                        />
                    </div>
                </div>
                <div
                    className="row p-2"
                    style={{ marginTop: "2px", height: "38px" }}
                >
                    <div className="col-6">{t("Send alerts in format")}</div>
                    <div className="col">
                        <AvForm
                            model={{
                                form: alertFormWhenTicketClosed,
                                checkItOut: true,
                            }}
                        >
                            <AvRadioGroup
                                name="form"
                                required
                                inline
                                disabled={
                                    !isSendAlertWhenTicketClosed ||
                                    !isEmailIncludeWhenTicketClosed
                                }
                            >
                                <AvRadio
                                    id="xls"
                                    label={`${t("XLS")}`}
                                    value="XLS"
                                    onChange={() => {
                                        if (
                                            Object.keys(
                                                rowData.collection_alert
                                            ).length > 0
                                        ) {
                                            rowData.collection_alert.withdrawal_tickets.send_format_closing_alert.value =
                                                "XLS";
                                            setAlertFormWhenTickeClosed(
                                                rowData.collection_alert
                                                    .withdrawal_tickets
                                                    .send_format_closing_alert
                                                    .value
                                            );
                                        } else {
                                            setAlertFormWhenTickeClosed("XLS");
                                        }
                                    }}
                                />
                                <AvRadio
                                    id="twoRadio"
                                    label={`${t("Word")}`}
                                    name="radio"
                                    value="Word"
                                    onChange={() => {
                                        if (
                                            Object.keys(
                                                rowData.collection_alert
                                            ).length > 0
                                        ) {
                                            rowData.collection_alert.withdrawal_tickets.send_format_closing_alert.value =
                                                "Word";
                                            setAlertFormWhenTickeClosed(
                                                rowData.collection_alert
                                                    .withdrawal_tickets
                                                    .send_format_closing_alert
                                                    .value
                                            );
                                        } else {
                                            setAlertFormWhenTickeClosed("Word");
                                        }
                                    }}
                                />
                                <AvRadio
                                    id="threeRadio"
                                    label={`${t("XML")}`}
                                    name="radio"
                                    value="XML"
                                    onChange={() => {
                                        if (
                                            Object.keys(
                                                rowData.collection_alert
                                            ).length > 0
                                        ) {
                                            rowData.collection_alert.withdrawal_tickets.send_format_closing_alert.value =
                                                "XML";
                                            setAlertFormWhenTickeClosed(
                                                rowData.collection_alert
                                                    .withdrawal_tickets
                                                    .send_format_closing_alert
                                                    .value
                                            );
                                        } else {
                                            setAlertFormWhenTickeClosed("XML");
                                        }
                                    }}
                                />
                                <AvRadio
                                    id="threeRadio"
                                    label={`${t("Txt")}`}
                                    name="radio"
                                    value="Txt"
                                    checked={true}
                                    onChange={() => {
                                        if (
                                            Object.keys(
                                                rowData.collection_alert
                                            ).length > 0
                                        ) {
                                            rowData.collection_alert.withdrawal_tickets.send_format_closing_alert.value =
                                                "Txt";
                                            setAlertFormWhenTickeClosed(
                                                rowData.collection_alert
                                                    .withdrawal_tickets
                                                    .send_format_closing_alert
                                                    .value
                                            );
                                        } else {
                                            setAlertFormWhenTickeClosed("Txt");
                                        }
                                    }}
                                />
                            </AvRadioGroup>
                        </AvForm>
                    </div>
                </div>
                <div className="border-footer__clz" />
                <div className="d-flex align-items-center justify-content-between p-2">
                    <div>
                        <b>{t("Removing the coin cassette")}</b>
                    </div>
                </div>
                <div
                    className="d-flex align-items-center justify-content-between p-2"
                    style={{ height: "23px" }}
                >
                    <div>{t("Enable Coin Cassette Removal Alert")}</div>
                    <div className=" float-right  mr-4 ">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isActivatePiecesWithdrawalAlert}
                            onChange={() => {
                                if (
                                    Object.values(rowData.collection_alert)
                                        .length > 0
                                ) {
                                    rowData.collection_alert.withdrawal_coins.coin_withdrawal_alert = !rowData
                                        .collection_alert.withdrawal_coins
                                        .coin_withdrawal_alert;
                                    setIsActivatePiecesWithdrawalAlert(
                                        rowData.collection_alert
                                            .withdrawal_coins
                                            .coin_withdrawal_alert
                                    );
                                } else {
                                    setIsActivatePiecesWithdrawalAlert(
                                        !isActivatePiecesWithdrawalAlert
                                    );
                                }
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={55}
                            height={20}
                        />
                    </div>
                </div>
                <div className="row p-2" style={{ height: "28px" }}>
                    <div style={{ width: "440px" }} className="col-6">
                        {t("Send an alert when the coin cassette is opened by")}
                    </div>
                    <div className="col">
                        <span className="mt-2">
                            <input
                                type="checkbox"
                                id="example"
                                disabled={!isSendAlertWhenPiecesOpened}
                                value={`${t("SMS")}`}
                                defaultChecked={
                                    Object.values(rowData.collection_alert)
                                        .length > 0
                                        ? rowData.collection_alert
                                              .withdrawal_coins
                                              .coin_opening_alert.sms
                                        : true
                                }
                                onChange={(e: any) => {
                                    if (
                                        Object.values(rowData.collection_alert)
                                            .length > 0
                                    ) {
                                        if (e.target.checked) {
                                            rowData.collection_alert.withdrawal_coins.coin_opening_alert.sms = !rowData
                                                .collection_alert
                                                .withdrawal_coins
                                                .coin_opening_alert.sms;
                                        } else {
                                            rowData.collection_alert.withdrawal_coins.coin_opening_alert.sms = !rowData
                                                .collection_alert
                                                .withdrawal_coins
                                                .coin_opening_alert.sms;
                                        }
                                    } else {
                                        if (e.target.checked) {
                                            setAlertSentWhenPiecesOpened([
                                                ...alertSentWhenPiecesOpened,
                                                "SMS",
                                            ]);
                                        } else {
                                            setAlertSentWhenPiecesOpened([
                                                ...alertSentWhenPiecesOpened.filter(
                                                    (element: any) =>
                                                        element !== "SMS"
                                                ),
                                            ]);
                                        }
                                    }
                                }}
                            />
                            <label className="ml-3">{t("SMS")}</label>
                        </span>
                        <span className="ml-3 mt-2">
                            <input
                                type="checkbox"
                                id="example"
                                disabled={!isSendAlertWhenPiecesOpened}
                                value={`${t("E-mail")}`}
                                defaultChecked={
                                    Object.values(rowData.collection_alert)
                                        .length > 0
                                        ? rowData.collection_alert
                                              .withdrawal_coins
                                              .coin_opening_alert.email
                                        : false
                                }
                                onChange={(e: any) => {
                                    if (
                                        Object.values(rowData.collection_alert)
                                            .length > 0
                                    ) {
                                        if (e.target.checked) {
                                            rowData.collection_alert.withdrawal_coins.coin_opening_alert.email = !rowData
                                                .collection_alert
                                                .withdrawal_coins
                                                .coin_opening_alert.email;
                                            setIsEmailIncludeWhenPiecesOpened(
                                                true
                                            );
                                        } else {
                                            rowData.collection_alert.withdrawal_coins.coin_opening_alert.email = !rowData
                                                .collection_alert
                                                .withdrawal_coins
                                                .coin_opening_alert.email;
                                            setIsEmailIncludeWhenPiecesOpened(
                                                false
                                            );
                                        }
                                    } else {
                                        if (e.target.checked) {
                                            setAlertSentWhenPiecesOpened([
                                                ...alertSentWhenPiecesOpened,
                                                "E-mail",
                                            ]);
                                            setIsEmailIncludeWhenPiecesOpened(
                                                true
                                            );
                                        } else {
                                            setAlertSentWhenPiecesOpened([
                                                ...alertSentWhenPiecesOpened.filter(
                                                    (element: any) =>
                                                        element !== "E-mail"
                                                ),
                                            ]);
                                            setIsEmailIncludeWhenPiecesOpened(
                                                false
                                            );
                                        }
                                    }
                                }}
                            />
                            <label className="ml-3">{t("E-mail")}</label>
                        </span>
                    </div>
                    <div className="col-1 mr-4" style={{ textAlign: "end" }}>
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isSendAlertWhenPiecesOpened}
                            onChange={() => {
                                if (
                                    Object.values(rowData.collection_alert)
                                        .length > 0
                                ) {
                                    rowData.collection_alert.withdrawal_coins.coin_opening_alert.state = !rowData
                                        .collection_alert.withdrawal_coins
                                        .coin_opening_alert.state;
                                    setIsSendAlertWhenPiecesOpened(
                                        rowData.collection_alert
                                            .withdrawal_coins.coin_opening_alert
                                            .state
                                    );
                                } else {
                                    setIsSendAlertWhenPiecesOpened(
                                        !isSendAlertWhenPiecesOpened
                                    );
                                }
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={55}
                            height={20}
                        />
                    </div>
                </div>
                <div
                    className="row p-2"
                    style={{ marginTop: "2px", height: "26px" }}
                >
                    <div className="col-6">{t("Send alerts in format")}</div>
                    <div className="col">
                        <AvForm
                            model={{
                                form: alertFormWhenPiecesOpened,
                                checkItOut: true,
                            }}
                        >
                            <AvRadioGroup
                                name="form"
                                required
                                inline
                                disabled={
                                    !isSendAlertWhenPiecesOpened ||
                                    !isEmailIncludeWhenPiecesOpened
                                }
                            >
                                <AvRadio
                                    id="xls"
                                    label={`${t("XLS")}`}
                                    value="XLS"
                                    onChange={() => {
                                        if (
                                            Object.keys(
                                                rowData.collection_alert
                                            ).length > 0
                                        ) {
                                            rowData.collection_alert.withdrawal_coins.send_format_opening_alert.value =
                                                "XLS";
                                            setAlertFormWhenPiecesOpened(
                                                rowData.collection_alert
                                                    .withdrawal_coins
                                                    .send_format_opening_alert
                                                    .value
                                            );
                                        } else {
                                            setAlertFormWhenPiecesOpened("XLS");
                                        }
                                    }}
                                />
                                <AvRadio
                                    id="twoRadio"
                                    label={`${t("Word")}`}
                                    name="radio"
                                    value="Word"
                                    onChange={() => {
                                        if (
                                            Object.keys(
                                                rowData.collection_alert
                                            ).length > 0
                                        ) {
                                            rowData.collection_alert.withdrawal_coins.send_format_opening_alert.value =
                                                "Word";
                                            setAlertFormWhenPiecesOpened(
                                                rowData.collection_alert
                                                    .withdrawal_coins
                                                    .send_format_opening_alert
                                                    .value
                                            );
                                        } else {
                                            setAlertFormWhenPiecesOpened(
                                                "Word"
                                            );
                                        }
                                    }}
                                />
                                <AvRadio
                                    id="threeRadio"
                                    label={`${t("XML")}`}
                                    name="radio"
                                    value="XML"
                                    onChange={() => {
                                        if (
                                            Object.keys(
                                                rowData.collection_alert
                                            ).length > 0
                                        ) {
                                            rowData.collection_alert.withdrawal_coins.send_format_opening_alert.value =
                                                "XML";
                                            setAlertFormWhenPiecesOpened(
                                                rowData.collection_alert
                                                    .withdrawal_coins
                                                    .send_format_opening_alert
                                                    .value
                                            );
                                        } else {
                                            setAlertFormWhenPiecesOpened("XML");
                                        }
                                    }}
                                />
                                <AvRadio
                                    id="threeRadio"
                                    label={`${t("Txt")}`}
                                    name="radio"
                                    value="Txt"
                                    checked={true}
                                    onChange={() => {
                                        if (
                                            Object.keys(
                                                rowData.collection_alert
                                            ).length > 0
                                        ) {
                                            rowData.collection_alert.withdrawal_coins.send_format_opening_alert.value =
                                                "Txt";
                                            setAlertFormWhenPiecesOpened(
                                                rowData.collection_alert
                                                    .withdrawal_coins
                                                    .send_format_opening_alert
                                                    .value
                                            );
                                        } else {
                                            setAlertFormWhenPiecesOpened("Txt");
                                        }
                                    }}
                                />
                            </AvRadioGroup>
                        </AvForm>
                    </div>
                </div>
                <div
                    className="row p-2"
                    style={{ height: "28px", marginTop: "4px" }}
                >
                    <div style={{ width: "440px" }} className="col-6">
                        {t("Send an alert when the coin cassette is closed by")}
                    </div>
                    <div className="col">
                        <span className="mt-2">
                            <input
                                type="checkbox"
                                id="example"
                                disabled={!isSendAlertWhenPiecesClosed}
                                value={`${t("SMS")}`}
                                defaultChecked={
                                    Object.values(rowData.collection_alert)
                                        .length > 0
                                        ? rowData.collection_alert
                                              .withdrawal_coins
                                              .coin_closing_alert.sms
                                        : true
                                }
                                onChange={(e: any) => {
                                    if (
                                        Object.values(rowData.collection_alert)
                                            .length > 0
                                    ) {
                                        if (e.target.checked) {
                                            rowData.collection_alert.withdrawal_coins.coin_closing_alert.sms = !rowData
                                                .collection_alert
                                                .withdrawal_coins
                                                .coin_closing_alert.sms;
                                        } else {
                                            rowData.collection_alert.withdrawal_coins.coin_closing_alert.sms = !rowData
                                                .collection_alert
                                                .withdrawal_coins
                                                .coin_closing_alert.sms;
                                        }
                                    } else {
                                        if (e.target.checked) {
                                            setAlertSentWhenPiecesClosed([
                                                ...alertSentWhenPiecesClosed,
                                                "SMS",
                                            ]);
                                        } else {
                                            setAlertSentWhenPiecesClosed([
                                                ...alertSentWhenPiecesClosed.filter(
                                                    (element: any) =>
                                                        element !== "SMS"
                                                ),
                                            ]);
                                        }
                                    }
                                }}
                            />
                            <label className="ml-3">{t("SMS")}</label>
                        </span>
                        <span className="ml-3 mt-2">
                            <input
                                type="checkbox"
                                id="example"
                                disabled={!isSendAlertWhenPiecesClosed}
                                value={`${t("E-mail")}`}
                                defaultChecked={
                                    Object.values(rowData.collection_alert)
                                        .length > 0
                                        ? rowData.collection_alert
                                              .withdrawal_coins
                                              .coin_closing_alert.email
                                        : false
                                }
                                onChange={(e: any) => {
                                    if (
                                        Object.values(rowData.collection_alert)
                                            .length > 0
                                    ) {
                                        if (e.target.checked) {
                                            rowData.collection_alert.withdrawal_coins.coin_closing_alert.email = !rowData
                                                .collection_alert
                                                .withdrawal_coins
                                                .coin_closing_alert.email;
                                            setIsEmailIncludeWhenPiecesClosed(
                                                true
                                            );
                                        } else {
                                            rowData.collection_alert.withdrawal_coins.coin_closing_alert.email = !rowData
                                                .collection_alert
                                                .withdrawal_coins
                                                .coin_closing_alert.email;
                                            setIsEmailIncludeWhenPiecesClosed(
                                                false
                                            );
                                        }
                                    } else {
                                        if (e.target.checked) {
                                            setAlertSentWhenPiecesClosed([
                                                ...alertSentWhenPiecesClosed,
                                                "E-mail",
                                            ]);
                                            setIsEmailIncludeWhenPiecesClosed(
                                                true
                                            );
                                        } else {
                                            setAlertSentWhenPiecesClosed([
                                                ...alertSentWhenPiecesClosed.filter(
                                                    (element: any) =>
                                                        element !== "E-mail"
                                                ),
                                            ]);
                                            setIsEmailIncludeWhenPiecesClosed(
                                                false
                                            );
                                        }
                                    }
                                }}
                            />
                            <label className="ml-3">{t("E-mail")}</label>
                        </span>
                    </div>
                    <div className="col-1 mr-4" style={{ textAlign: "end" }}>
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isSendAlertWhenPiecesClosed}
                            onChange={() => {
                                if (
                                    Object.values(rowData.collection_alert)
                                        .length > 0
                                ) {
                                    rowData.collection_alert.withdrawal_coins.coin_closing_alert.state = !rowData
                                        .collection_alert.withdrawal_coins
                                        .coin_closing_alert.state;
                                    setIsSendAlertWhenPiecesClosed(
                                        rowData.collection_alert
                                            .withdrawal_coins.coin_closing_alert
                                            .state
                                    );
                                } else {
                                    setIsSendAlertWhenPiecesClosed(
                                        !isSendAlertWhenPiecesClosed
                                    );
                                }
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={55}
                            height={20}
                        />
                    </div>
                </div>
                <div className="row p-2" style={{ height: "38px" }}>
                    <div className="col-6">{t("Send alerts in format")}</div>

                    <div className="col">
                        <AvForm
                            model={{
                                form: alertFormWhenPiecesClosed,
                                checkItOut: true,
                            }}
                        >
                            <AvRadioGroup
                                name="form"
                                required
                                inline
                                disabled={
                                    !isSendAlertWhenPiecesClosed ||
                                    !isEmailIncludeWhenPiecesClosed
                                }
                            >
                                <AvRadio
                                    id="xls"
                                    label={`${t("XLS")}`}
                                    value="XLS"
                                    onChange={() => {
                                        if (
                                            Object.keys(
                                                rowData.collection_alert
                                            ).length > 0
                                        ) {
                                            rowData.collection_alert.withdrawal_coins.send_format_closing_alert.value =
                                                "XLS";
                                            setAlertFormWhenPiecesClosed(
                                                rowData.collection_alert
                                                    .withdrawal_coins
                                                    .send_format_closing_alert
                                                    .value
                                            );
                                        } else {
                                            setAlertFormWhenPiecesClosed("XLS");
                                        }
                                    }}
                                />
                                <AvRadio
                                    id="twoRadio"
                                    label={`${t("Word")}`}
                                    name="radio"
                                    value="Word"
                                    onChange={() => {
                                        if (
                                            Object.keys(
                                                rowData.collection_alert
                                            ).length > 0
                                        ) {
                                            rowData.collection_alert.withdrawal_coins.send_format_closing_alert.value =
                                                "Word";
                                            setAlertFormWhenPiecesClosed(
                                                rowData.collection_alert
                                                    .withdrawal_coins
                                                    .send_format_closing_alert
                                                    .value
                                            );
                                        } else {
                                            setAlertFormWhenPiecesClosed(
                                                "Word"
                                            );
                                        }
                                    }}
                                />
                                <AvRadio
                                    id="threeRadio"
                                    label={`${t("XML")}`}
                                    name="radio"
                                    value="XML"
                                    onChange={() => {
                                        if (
                                            Object.keys(
                                                rowData.collection_alert
                                            ).length > 0
                                        ) {
                                            rowData.collection_alert.withdrawal_coins.send_format_closing_alert =
                                                "XML";
                                            setAlertFormWhenPiecesClosed(
                                                rowData.collection_alert
                                                    .withdrawal_coins
                                                    .send_format_closing_alert
                                            );
                                        } else {
                                            setAlertFormWhenPiecesClosed("XML");
                                        }
                                    }}
                                />
                                <AvRadio
                                    id="threeRadio"
                                    label={`${t("Txt")}`}
                                    name="radio"
                                    value="Txt"
                                    checked={true}
                                    onChange={() => {
                                        if (
                                            Object.keys(
                                                rowData.collection_alert
                                            ).length > 0
                                        ) {
                                            rowData.collection_alert.withdrawal_coins.send_format_closing_alert =
                                                "Txt";
                                            setAlertFormWhenPiecesClosed(
                                                rowData.collection_alert
                                                    .withdrawal_coins
                                                    .send_format_closing_alert
                                                    .value
                                            );
                                        } else {
                                            setAlertFormWhenPiecesClosed("Txt");
                                        }
                                    }}
                                />
                            </AvRadioGroup>
                        </AvForm>
                    </div>
                </div>
                <div className="border-footer__clz " />
                <div className="d-flex align-items-center justify-content-between p-2">
                    <div>
                        <b>{t("Suspicious cash cassette withdrawal")}</b>
                    </div>
                </div>
                <div
                    className="d-flex align-items-center justify-content-between p-2"
                    style={{ height: "28px" }}
                >
                    <div>
                        {t(
                            "Activer l’alerte de suspect de la cassette des billets"
                        )}
                    </div>
                    <div className=" float-right  mr-4 ">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isActivateTicketSuspectAlert}
                            onChange={() => {
                                if (
                                    Object.values(rowData.collection_alert)
                                        .length > 0
                                ) {
                                    rowData.collection_alert.suspicious_withdrawal_tickets.ticket_suspicious_l_alert = !rowData
                                        .collection_alert
                                        .suspicious_withdrawal_tickets
                                        .ticket_suspicious_l_alert;
                                    setIsActivateTicketSuspectAlert(
                                        rowData.collection_alert
                                            .suspicious_withdrawal_tickets
                                            .ticket_suspicious_l_alert
                                    );
                                } else {
                                    setIsActivateTicketSuspectAlert(
                                        !isActivateTicketSuspectAlert
                                    );
                                }
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={55}
                            height={20}
                        />
                    </div>
                </div>
                <div className="row p-2" style={{ height: "28px" }}>
                    <div className="col-6">{t("Send alerts by")}</div>
                    <div className="col">
                        <span className="mt-2">
                            <input
                                type="checkbox"
                                id="example"
                                disabled={!isSendAlertTicket}
                                value={`${t("SMS")}`}
                                defaultChecked={
                                    Object.values(rowData.collection_alert)
                                        .length > 0
                                        ? rowData.collection_alert
                                              .suspicious_withdrawal_tickets
                                              .ticket_alert.sms
                                        : true
                                }
                                onChange={(e: any) => {
                                    if (
                                        Object.values(rowData.collection_alert)
                                            .length > 0
                                    ) {
                                        if (e.target.checked) {
                                            rowData.collection_alert.suspicious_withdrawal_tickets.ticket_alert.sms = !rowData
                                                .collection_alert
                                                .suspicious_withdrawal_tickets
                                                .ticket_alert.sms;
                                        } else {
                                            rowData.collection_alert.suspicious_withdrawal_tickets.ticket_alert.sms = !rowData
                                                .collection_alert
                                                .suspicious_withdrawal_tickets
                                                .ticket_alert.sms;
                                        }
                                    } else {
                                        if (e.target.checked) {
                                            setAlertSentAlertTicket([
                                                ...alertSentAlertTicket,
                                                "SMS",
                                            ]);
                                        } else {
                                            setAlertSentAlertTicket([
                                                ...alertSentAlertTicket.filter(
                                                    (element: any) =>
                                                        element !== "SMS"
                                                ),
                                            ]);
                                        }
                                    }
                                }}
                            />
                            <label className="ml-3">{t("SMS")}</label>
                        </span>
                        <span className="ml-3 mt-2">
                            <input
                                type="checkbox"
                                id="example"
                                disabled={!isSendAlertTicket}
                                value={`${t("E-mail")}`}
                                defaultChecked={
                                    Object.values(rowData.collection_alert)
                                        .length > 0
                                        ? rowData.collection_alert
                                              .suspicious_withdrawal_tickets
                                              .ticket_alert.email
                                        : false
                                }
                                onChange={(e: any) => {
                                    if (
                                        Object.values(rowData.collection_alert)
                                            .length > 0
                                    ) {
                                        if (e.target.checked) {
                                            rowData.collection_alert.suspicious_withdrawal_tickets.ticket_alert.email = !rowData
                                                .collection_alert
                                                .suspicious_withdrawal_tickets
                                                .ticket_alert.email;
                                            setIsEmailIncludeTicket(true);
                                        } else {
                                            rowData.collection_alert.suspicious_withdrawal_tickets.ticket_alert.email = !rowData
                                                .collection_alert
                                                .suspicious_withdrawal_tickets
                                                .ticket_alert.email;
                                            setIsEmailIncludeTicket(false);
                                        }
                                    } else {
                                        if (e.target.checked) {
                                            setAlertSentAlertTicket([
                                                ...alertSentAlertTicket,
                                                "E-mail",
                                            ]);
                                            setIsEmailIncludeTicket(true);
                                        } else {
                                            setAlertSentAlertTicket([
                                                ...alertSentAlertTicket.filter(
                                                    (element: any) =>
                                                        element !== "E-mail"
                                                ),
                                            ]);
                                            setIsEmailIncludeTicket(false);
                                        }
                                    }
                                }}
                            />
                            <label className="ml-3">{t("E-mail")}</label>
                        </span>
                    </div>

                    <div className="col-1 mr-4" style={{ textAlign: "end" }}>
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isSendAlertTicket}
                            onChange={() => {
                                if (
                                    Object.values(rowData.collection_alert)
                                        .length > 0
                                ) {
                                    rowData.collection_alert.suspicious_withdrawal_tickets.ticket_alert.state = !rowData
                                        .collection_alert
                                        .suspicious_withdrawal_tickets
                                        .ticket_alert.state;
                                    setIsSendAlertTicket(
                                        rowData.collection_alert
                                            .suspicious_withdrawal_tickets
                                            .ticket_alert.state
                                    );
                                } else {
                                    setIsSendAlertTicket(!isSendAlertTicket);
                                }
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={55}
                            height={20}
                        />
                    </div>
                </div>
                <div
                    className="row p-2"
                    style={{ marginTop: "2px", height: "38px" }}
                >
                    <div className="col-6">{t("Send alerts in format")}</div>

                    <div className="col">
                        <AvForm
                            model={{
                                form: alertFormTicket,
                                checkItOut: true,
                            }}
                        >
                            <AvRadioGroup
                                name="form"
                                required
                                inline
                                disabled={
                                    !isSendAlertTicket || !isEmailIncludeTicket
                                }
                            >
                                <AvRadio
                                    id="xls"
                                    label={`${t("XLS")}`}
                                    value="XLS"
                                    onChange={() => {
                                        if (
                                            Object.keys(
                                                rowData.collection_alert
                                            ).length > 0
                                        ) {
                                            rowData.collection_alert.suspicious_withdrawal_tickets.send_format.value =
                                                "XLS";
                                            setAlertFormTicket(
                                                rowData.collection_alert
                                                    .suspicious_withdrawal_tickets
                                                    .send_format.value
                                            );
                                        } else {
                                            setAlertFormTicket("XLS");
                                        }
                                    }}
                                />

                                <AvRadio
                                    id="twoRadio"
                                    label={`${t("Word")}`}
                                    name="radio"
                                    value="Word"
                                    onChange={() => {
                                        if (
                                            Object.keys(
                                                rowData.collection_alert
                                            ).length > 0
                                        ) {
                                            rowData.collection_alert.suspicious_withdrawal_tickets.send_format.value =
                                                "Word";
                                            setAlertFormTicket(
                                                rowData.collection_alert
                                                    .suspicious_withdrawal_tickets
                                                    .send_format.value
                                            );
                                        } else {
                                            setAlertFormTicket("Word");
                                        }
                                    }}
                                />

                                <AvRadio
                                    id="threeRadio"
                                    label={`${t("XML")}`}
                                    name="radio"
                                    value="XML"
                                    onChange={() => {
                                        if (
                                            Object.keys(
                                                rowData.collection_alert
                                            ).length > 0
                                        ) {
                                            rowData.collection_alert.suspicious_withdrawal_tickets.send_format.value =
                                                "XML";
                                            setAlertFormTicket(
                                                rowData.collection_alert
                                                    .suspicious_withdrawal_tickets
                                                    .send_format.value
                                            );
                                        } else {
                                            setAlertFormTicket("XML");
                                        }
                                    }}
                                />

                                <AvRadio
                                    id="threeRadio"
                                    label={`${t("Txt")}`}
                                    name="radio"
                                    value="Txt"
                                    checked={true}
                                    onChange={() => {
                                        if (
                                            Object.keys(
                                                rowData.collection_alert
                                            ).length > 0
                                        ) {
                                            rowData.collection_alert.suspicious_withdrawal_tickets.send_format.value =
                                                "Txt";
                                            setAlertFormTicket(
                                                rowData.collection_alert
                                                    .suspicious_withdrawal_tickets
                                                    .send_format.value
                                            );
                                        } else {
                                            setAlertFormTicket("Txt");
                                        }
                                    }}
                                />
                            </AvRadioGroup>
                        </AvForm>
                    </div>
                </div>
                <div className="border-footer__clz mt-2" />
                <div className="d-flex  align-items-center justify-content-between p-2">
                    <div>
                        <b>{t("Suspicious removal of coin cassette")}</b>
                    </div>
                </div>
                <div
                    className="d-flex align-items-center justify-content-between p-2"
                    style={{ height: "28px" }}
                >
                    <div>{t("Enable Coin Cassette Suspect Alert")}</div>
                    <div className="float-right mr-4 ">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isActivatePiecesSuspectAlert}
                            onChange={() => {
                                if (
                                    Object.values(rowData.collection_alert)
                                        .length > 0
                                ) {
                                    rowData.collection_alert.suspicious_withdrawal_coins.coins_suspicious_l_alert = !rowData
                                        .collection_alert
                                        .suspicious_withdrawal_coins
                                        .coins_suspicious_l_alert;
                                    setIsActivatePiecesSuspectAlert(
                                        rowData.collection_alert
                                            .suspicious_withdrawal_coins
                                            .coins_suspicious_l_alert
                                    );
                                } else {
                                    setIsActivatePiecesSuspectAlert(
                                        !isActivatePiecesSuspectAlert
                                    );
                                }
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={55}
                            height={20}
                        />
                    </div>
                </div>
                <div className="row p-2" style={{ height: "28px" }}>
                    <div className="col-6">{t("Send alerts by")} </div>
                    <div className="col">
                        <span className="mt-2">
                            <input
                                type="checkbox"
                                id="example"
                                disabled={!isSendAlertPieces}
                                value={`${t("SMS")}`}
                                defaultChecked={
                                    Object.values(rowData.collection_alert)
                                        .length > 0
                                        ? rowData.collection_alert
                                              .suspicious_withdrawal_coins
                                              .coins_alert.sms
                                        : true
                                }
                                onChange={(e: any) => {
                                    if (
                                        Object.values(rowData.collection_alert)
                                            .length > 0
                                    ) {
                                        if (e.target.checked) {
                                            rowData.collection_alert.suspicious_withdrawal_coins.coins_alert.sms = !rowData
                                                .collection_alert
                                                .suspicious_withdrawal_coins
                                                .coins_alert.sms;
                                        } else {
                                            rowData.collection_alert.suspicious_withdrawal_coins.coins_alert.sms = !rowData
                                                .collection_alert
                                                .suspicious_withdrawal_coins
                                                .coins_alert.sms;
                                        }
                                    } else {
                                        if (e.target.checked) {
                                            setAlertSentAlertPieces([
                                                ...alertSentAlertPieces,
                                                "SMS",
                                            ]);
                                        } else {
                                            setAlertSentAlertPieces([
                                                ...alertSentAlertPieces.filter(
                                                    (element: any) =>
                                                        element !== "SMS"
                                                ),
                                            ]);
                                        }
                                    }
                                }}
                            />
                            <label className="ml-3">{t("SMS")}</label>
                        </span>
                        <span className="ml-3 mt-2">
                            <input
                                type="checkbox"
                                id="example"
                                disabled={!isSendAlertPieces}
                                value={`${t("E-mail")}`}
                                defaultChecked={
                                    Object.values(rowData.collection_alert)
                                        .length > 0
                                        ? rowData.collection_alert
                                              .suspicious_withdrawal_coins
                                              .coins_alert.email
                                        : false
                                }
                                onChange={(e: any) => {
                                    if (
                                        Object.values(rowData.collection_alert)
                                            .length > 0
                                    ) {
                                        if (e.target.checked) {
                                            rowData.collection_alert.suspicious_withdrawal_coins.coins_alert.email = !rowData
                                                .collection_alert
                                                .suspicious_withdrawal_coins
                                                .coins_alert.email;
                                            setIsEmailIncludePieces(true);
                                        } else {
                                            rowData.collection_alert.suspicious_withdrawal_coins.coins_alert.email = !rowData
                                                .collection_alert
                                                .suspicious_withdrawal_coins
                                                .coins_alert.email;
                                            setIsEmailIncludePieces(false);
                                        }
                                    } else {
                                        if (e.target.checked) {
                                            setAlertSentAlertPieces([
                                                ...alertSentAlertPieces,
                                                "E-mail",
                                            ]);
                                            setIsEmailIncludePieces(true);
                                        } else {
                                            setAlertSentAlertPieces([
                                                ...alertSentAlertPieces.filter(
                                                    (element: any) =>
                                                        element !== "E-mail"
                                                ),
                                            ]);
                                            setIsEmailIncludePieces(false);
                                        }
                                    }
                                }}
                            />
                            <label className="ml-3">{t("E-mail")}</label>
                        </span>
                    </div>

                    <div className="col-1 mr-4" style={{ textAlign: "end" }}>
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isSendAlertPieces}
                            onChange={(e: any) => {
                                if (
                                    Object.values(rowData.collection_alert)
                                        .length > 0
                                ) {
                                    rowData.collection_alert.suspicious_withdrawal_coins.coins_alert.state = !rowData
                                        .collection_alert
                                        .suspicious_withdrawal_coins.coins_alert
                                        .state;
                                    setIsSendAlertPieces(
                                        rowData.collection_alert
                                            .suspicious_withdrawal_coins
                                            .coins_alert.state
                                    );
                                } else {
                                    setIsSendAlertPieces(!isSendAlertPieces);
                                }
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={55}
                            height={20}
                        />
                    </div>
                </div>
                <div
                    className="row p-2"
                    style={{ marginTop: "2px", height: "13px" }}
                >
                    <div className="col-6">{t("Send alerts in format")}</div>

                    <div className="col">
                        <AvForm
                            model={{
                                form: alertFormPieces,
                                checkItOut: true,
                            }}
                        >
                            <AvRadioGroup
                                name="form"
                                A
                                required
                                inline
                                disabled={
                                    !isSendAlertPieces || !isEmailIncludePieces
                                }
                            >
                                <AvRadio
                                    id="xls"
                                    label={`${t("XLS")}`}
                                    value="XLS"
                                    onChange={() => {
                                        if (
                                            Object.keys(
                                                rowData.collection_alert
                                            ).length > 0
                                        ) {
                                            rowData.collection_alert.suspicious_withdrawal_coins.send_format.value =
                                                "XLS";
                                            setAlertFormPieces(
                                                rowData.collection_alert
                                                    .suspicious_withdrawal_coins
                                                    .send_format.value
                                            );
                                        } else {
                                            setAlertFormPieces("XLS");
                                        }
                                    }}
                                />

                                <AvRadio
                                    id="twoRadio"
                                    label={`${t("Word")}`}
                                    name="radio"
                                    value="Word"
                                    onChange={() => {
                                        if (
                                            Object.keys(
                                                rowData.collection_alert
                                            ).length > 0
                                        ) {
                                            rowData.collection_alert.suspicious_withdrawal_coins.send_format.value =
                                                "Word";
                                            setAlertFormPieces(
                                                rowData.collection_alert
                                                    .suspicious_withdrawal_coins
                                                    .send_format.value
                                            );
                                        } else {
                                            setAlertFormPieces("Word");
                                        }
                                    }}
                                />

                                <AvRadio
                                    id="threeRadio"
                                    label={`${t("XML")}`}
                                    name="radio"
                                    value="XML"
                                    onChange={() => {
                                        if (
                                            Object.keys(
                                                rowData.collection_alert
                                            ).length > 0
                                        ) {
                                            rowData.collection_alert.suspicious_withdrawal_coins.send_format.value =
                                                "XML";
                                            setAlertFormPieces(
                                                rowData.collection_alert
                                                    .suspicious_withdrawal_coins
                                                    .send_format.value
                                            );
                                        } else {
                                            setAlertFormPieces("XML");
                                        }
                                    }}
                                />

                                <AvRadio
                                    id="threeRadio"
                                    label={`${t("Txt")}`}
                                    name="radio"
                                    value="Txt"
                                    checked={true}
                                    onChange={() => {
                                        if (
                                            Object.keys(
                                                rowData.collection_alert
                                            ).length > 0
                                        ) {
                                            rowData.collection_alert.suspicious_withdrawal_coins.send_format.value =
                                                "Txt";
                                            setAlertFormPieces(
                                                rowData.collection_alert
                                                    .suspicious_withdrawal_coins
                                                    .send_format.value
                                            );
                                        } else {
                                            setAlertFormPieces("Txt");
                                        }
                                    }}
                                />
                            </AvRadioGroup>
                        </AvForm>
                    </div>
                </div>
            </ModalBody>
            <Footer
                isConfigurationCashManagementClicked={
                    isConfigurationCashManagementClicked
                }
                setIsConfigurationCashManagementClicked={
                    setIsConfigurationCashManagementClicked
                }
                setDataIsUpdated={setDataIsUpdated}
                rowData={rowData}
            />
        </React.Fragment>
    );
}
