import React from "react";
import ReactSwitch from "react-switch";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import {
    designStore,
    generalConfigStore,
    setIsAllergenOrderTakingActive,
    setIsCustomerAccountOrderTakingActive,
    setIsHeaderOrderTakingActive,
    setIsPageAccountActive,
    setIsPageAllergenActive,
    setIsPageHeaderActive,
    setIsPageUserAccountActive,
    setIsUserAccountOrderTakingActive,
} from "@store";

export function MainContent(): JSX.Element {
    const { t } = useTranslation();

    const { selectedLanguage, isUserAccountOrderTakingActive } = useSnapshot(
        designStore
    );
    const {
        project: { template },
        isCustomerAccountOrderTakingActive,
        isAllergenOrderTakingActive,
        isCustomerAccountActive,
    } = useSnapshot(generalConfigStore);

    function handleIsUserAccountActiveSwitchOnClickEvent() {
        setIsUserAccountOrderTakingActive(!isUserAccountOrderTakingActive);
        setIsPageUserAccountActive(
            !isUserAccountOrderTakingActive,
            "orderTaking"
        );
        if (
            [
                !isUserAccountOrderTakingActive,
                isCustomerAccountOrderTakingActive,
                isAllergenOrderTakingActive,
            ].every((currentValue) => currentValue === false) === true
        ) {
            setIsHeaderOrderTakingActive(false);
            setIsPageHeaderActive(false, "orderTaking");
        } else {
            setIsHeaderOrderTakingActive(true);
            setIsPageHeaderActive(true, "orderTaking");
        }
    }
    function handleIsCustomerAccountActiveSwitchOnClickEvent() {
        setIsCustomerAccountOrderTakingActive(
            !isCustomerAccountOrderTakingActive
        );
        setIsPageAccountActive(!isCustomerAccountOrderTakingActive);
        if (
            [
                isUserAccountOrderTakingActive,
                !isCustomerAccountOrderTakingActive,
                isAllergenOrderTakingActive,
            ].every((currentValue) => currentValue === false) === true
        ) {
            setIsHeaderOrderTakingActive(false);
            setIsPageHeaderActive(false, "orderTaking");
        } else {
            setIsHeaderOrderTakingActive(true);
            setIsPageHeaderActive(true, "orderTaking");
        }
    }
    function handleIsAllergenActiveSwitchOnClickEvent() {
        setIsAllergenOrderTakingActive(!isAllergenOrderTakingActive);
        setIsPageAllergenActive(!isAllergenOrderTakingActive);
        if (
            [
                isUserAccountOrderTakingActive,
                isCustomerAccountOrderTakingActive,
                !isAllergenOrderTakingActive,
            ].every((currentValue) => currentValue === false) === true
        ) {
            setIsHeaderOrderTakingActive(false);
            setIsPageHeaderActive(false, "orderTaking");
        } else {
            setIsHeaderOrderTakingActive(true);
            setIsPageHeaderActive(true, "orderTaking");
        }
    }

    const selectedSaleMethodDeliveryExist = (template.content
        .salesMethods as ProjectContentItemType).items.filter(
        (sale: any) => sale.name === "Delivery"
    )[0];

    return (
        <div
            className="mx-2 m-2 rounded border"
            style={{ borderColor: "#CECECE" }}
        >
            <div
                className="text-left px-4 d-flex align-items-center cursor__clz"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                {t("Header")}
            </div>
            <div className="p-4">
                <div className="py-2 d-flex flex-rows justify-content-between align-items-center">
                    <div>
                        {"Nom du client identifié"}
                        <div style={{ color: "#B7B7B7", fontSize: "13px" }}>
                            {
                                ((template.pages.ways[
                                    "orderTaking"
                                ] as OrderTakingPageType).header
                                    .userAccount as UserAccountType).languages[
                                    selectedLanguage
                                ].content
                            }
                        </div>
                    </div>
                    <ReactSwitch
                        disabled={
                            isCustomerAccountActive === false ? true : false
                        }
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isUserAccountOrderTakingActive}
                        onChange={handleIsUserAccountActiveSwitchOnClickEvent}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
                <div className="py-2 d-flex flex-rows justify-content-between align-items-center">
                    <div>{"Compte client"}</div>
                    <ReactSwitch
                        disabled={
                            isCustomerAccountActive === false
                                ? true
                                : false ||
                                  (selectedSaleMethodDeliveryExist !==
                                      undefined &&
                                      selectedSaleMethodDeliveryExist.active ===
                                          true)
                                ? true
                                : false
                        }
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isCustomerAccountOrderTakingActive}
                        onChange={
                            handleIsCustomerAccountActiveSwitchOnClickEvent
                        }
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
                <div className="py-2 d-flex flex-rows justify-content-between align-items-center">
                    <div>{"Allergènes"}</div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isAllergenOrderTakingActive}
                        onChange={handleIsAllergenActiveSwitchOnClickEvent}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
            </div>
        </div>
    );
}
