import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledLabel,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";

import { store } from "@components/VerticalLayout/store";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";

import { capitalize } from "../../../../helpers/general";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationMessageType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    msg: string;
    setDesignation: Function;
    setCountryData: Function;
    setDecimalNumber: Function;
    setDecimalDesignation: Function;
    setChange: Function;
    setTagsData: Function;
    setSymbol: Function;
    setTag: Function;
    setInputSymbol: Function;
    setDataIsUpdated: Function;
    setIsEdited: Function;
    setIsSendingTag: Function;
    setLocalFilterActive: Function;
    designation: string;
    tagsData: any;
    change: string;
    allDataShops: any;
    countryData: any;
};

export function ConfirmationAssociateBoutique({
    setIsModalOpened,
    isModalOpened,
    msg,
    setDesignation,
    setCountryData,
    setDecimalNumber,
    setDecimalDesignation,
    setChange,
    change,
    setTagsData,
    setSymbol,
    setTag,
    setInputSymbol,
    setDataIsUpdated,
    setIsEdited,
    setIsSendingTag,
    setLocalFilterActive,
    designation,
    tagsData,
    allDataShops,
    countryData,
}: ConfirmationMessageType) {
    const { t } = useTranslation();
    const { userID, franchiseID, shopID, operatorID } = useSnapshot(store);
    const uuidUser = localStorage.getItem("uuidUser");

    function isPays() {
        let isPays: boolean = false;
        let objectData =
            allDataShops[
                allDataShops.findIndex(
                    (el: any) => el[t("Currency")] === change
                )
            ];

        countryData.forEach((elt: any) => {
            if (objectData[t("Country")].split(",").includes(elt)) {
                isPays = true;
            }
        });
        return isPays;
    }

    async function addDeviceFranchise() {
        let isPay: boolean = isPays();
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/devise`;
        let objectData =
            allDataShops[
                allDataShops.findIndex(
                    (el: any) => el[t("Currency")] === change
                )
            ];
        let dataConfig;
        if (isPay === true) {
            dataConfig = {
                userId: userID,
                shopId: [shopID],
                franchiseId: franchiseID,
                affectation: true,
                operatorId: operatorID,
                uuidUser: uuidUser,
                data: {
                    [objectData.id]: {
                        Nom_d_Affichage: designation,
                        tag: tagsData.join(","),
                        symbole: objectData[t("Symbol")],
                        Nbr_décimale: objectData[t("Nbr decimal")],
                        designation_décimale:
                            objectData[t("Decimal designation")],
                        devise: objectData[t("Currency")],
                        pays: objectData[t("Country")],
                        isArchived: false,
                        idShop: shopID,
                    },
                },
            };
        } else {
            dataConfig = {
                userId: userID,
                shopId: [0, shopID],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                data: {
                    [objectData.id]: {
                        Nom_d_Affichage: objectData[t("Display name")],
                        tag: objectData[t("Tag")],
                        symbole: objectData[t("Symbol")],
                        Nbr_décimale: objectData[t("Nbr decimal")],
                        designation_décimale:
                            objectData[t("Decimal designation")],
                        devise: objectData[t("Currency")],
                        pays: `${objectData[t("Country")]},${countryData.join(
                            ","
                        )}`,

                        isArchived: false,
                        idShop: shopID,
                    },
                },
            };
        }
        try {
            await fetch(apiUrlAdd, {
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bareer ${localStorage.getItem("jwt")}`,
                },
                body: JSON.stringify(dataConfig),
                method: "POST",
            }).then((x: any) => {
                toast.success(
                    `${t("The currency has been successfully added")!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
            });

            setTagsData([]);
            setDesignation("");
            setTag("");
            setIsSendingTag(true);
            setCountryData([]);
            setSymbol("");
            setDataIsUpdated!(true);
            setIsEdited(false);
            setLocalFilterActive(false);
            setDecimalNumber("");
            setDecimalDesignation("");
            setChange("");
            clearData();
            clearMultiCustomSelectionData();
            setInputSymbol(false);
            setIsModalOpened(!isModalOpened);
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                }}
            >
                <StyledH2>{capitalize(t("Alert"))}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">{msg}</StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        addDeviceFranchise();
                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
