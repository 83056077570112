import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel, CheckboxIcon } from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";

import { capitalize } from "@helpers/general";
import { store } from "@components/VerticalLayout/store";

import { store as fiscalYear } from "../store";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationAssociationModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setDataIsUpdated: Function;
    data: any;
    setDayStart: Function;
    setDayEnd: Function;
    setMonthStart: Function;
    setMonthEnd: Function;
    setIsDateExist: Function;
};

export function ConfirmationAssociationModal({
    isModalOpened,
    setIsModalOpened,
    setDataIsUpdated,
    data,
    setDayStart,
    setDayEnd,
    setMonthStart,
    setMonthEnd,
    setIsDateExist,
}: ConfirmationAssociationModalType) {
    const { t } = useTranslation();
    const { fiscalYearFranchise, isArchived, fiscalYearData } = useSnapshot(
        fiscalYear
    );
    const { franchiseID, userID, shopID, operatorID } = useSnapshot(store);

    async function AssociateFiscalYear() {
        toast.dismiss();
        let objectDataArchive: any =
            fiscalYearData.data[0] !== undefined
                ? {
                      [fiscalYearData.data[0].Id]: {
                          first_day: fiscalYearData.data[0].first_day,
                          first_month: fiscalYearData.data[0].first_month,
                          end_day: fiscalYearData.data[0].end_day,
                          end_month: fiscalYearData.data[0].end_month,
                          isArchived: true,
                          shopId: shopID,
                      },
                  }
                : {};
        let objectData =
            fiscalYearFranchise.othersData.fiscalYearFranchise[
                fiscalYearFranchise.othersData.fiscalYearFranchise.findIndex(
                    (el: any) =>
                        el.end_day === data.dayEnd &&
                        t(el.end_month) === data.monthEnd &&
                        el.first_day === data.dayStart &&
                        t(el.first_month) === data.monthStart
                )
            ];

        Object.assign(objectDataArchive, {
            [objectData.Id]: {
                first_day: objectData.first_day,
                first_month: objectData.first_month,
                end_day: objectData.end_day,
                end_month: objectData.end_month,
                isArchived: false,
                shopId: shopID,
            },
        });

        let fiscalYearSale = {
            userId: userID,
            shopId: [shopID],
            franchiseId: franchiseID,
            operatorId: operatorID,
            data:
                fiscalYearData.data.length > 0
                    ? objectDataArchive
                    : {
                          [objectData.Id]: {
                              first_day: objectData.first_day,
                              first_month: objectData.first_month,
                              end_day: objectData.end_day,
                              end_month: objectData.end_month,
                              isArchived: false,
                              shopId: shopID,
                          },
                      },
            isShop: true,
        };
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/fiscal_year`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(fiscalYearSale),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "Fiscal year assignment was successfully assigned"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );

                        setDayStart("01");
                        setMonthStart(t("January"));
                        setDayEnd("31");
                        setMonthEnd(t("December"));
                        clearMultiCustomSelectionData();
                        clearData();
                        setDataIsUpdated!(true);
                        setIsDateExist(false);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                }}
            >
                <span>{capitalize(t("Alert"))}</span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {!isArchived
                        ? t(
                              "The fiscal year already exists in the franchise. Would you like to associate it with this store?"
                          )
                        : t(
                              "Attention ! the fiscal year is already archived at the franchise level"
                          )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                {!isArchived && (
                    <CustomSecondaryColorButton
                        outline
                        variant="light"
                        onClick={() => {
                            setIsModalOpened(!isModalOpened);
                        }}
                        rounded
                    >
                        {t("Cancel")}
                    </CustomSecondaryColorButton>
                )}

                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        if (!isArchived) {
                            AssociateFiscalYear();
                        }

                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    {!isArchived ? t("Validate") : t("Ok")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
