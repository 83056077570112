import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    CrossIcon,
    StyledLabel,
    StyledTextInput,
    CheckboxIcon,
    StyledSelectInput,
} from "@aureskonnect/react-ui";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { mutate } from "swr";
import { toast } from "react-toastify";
import classnames from "classnames";
import { useSnapshot } from "valtio";
import { uuid } from "uuidv4";

import { store } from "@components/VerticalLayout/store";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";
import {
    store as device,
    setNumberActive,
    setIsValidateClicked,
    setIsActionsClicked,
    setActions,
    setIsEdited,
    setIsPlusIconClicked,
} from "../store";
import ErrorToast from "@components/Common/ErrorTost";
import { searchPosInArrayObject } from "@helpers/general";
import { ConfirmationModificationMessage } from "./ConfirmationModificationMessage";
import {
    CustomCardBody,
    CustomCardFooter,
    CustomCardHeader,
} from "@components/Common/CustomCardStyled";
import MultiCustomCreatableSelection from "@components/Common/MultiCustomCreatableSelection";
import MultiCustomSelect from "@components/Common/MultiCustomSelect";
import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import { ConfirmationAssociateBoutique } from "../ShopsDevise/ConfirmationAssociateBoutique";
import { ConfirmationModal } from "./ConfirmationModal";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

type ModeOfSaleFormType = {
    allDataShops: any;
    isAddDeviceClicked: boolean;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<boolean | number>>;
    devises: React.SetStateAction<any>;
    setDevises: Function;
    setLocalFilterActive: Function;
    setIsAddDeviceClicked: Function;
    allDataFanchiseArchived: any;
    isAddNewDeviseButtonClicked: boolean;
    isHistoryModalOpened: boolean;
    isConsult: boolean;
    setIsConsult: Function;
};

export default function DeviceForm({
    allDataShops,
    setIsAddDeviceClicked,
    isAddDeviceClicked,
    setDataIsUpdated,
    devises,
    setDevises,
    setLocalFilterActive,
    allDataFanchiseArchived,
    isAddNewDeviseButtonClicked,
    isHistoryModalOpened,
    setIsConsult,
    isConsult,
}: ModeOfSaleFormType) {
    const { t } = useTranslation();

    const {
        userID,
        franchiseID,
        shopID,
        oneShop,
        shopPays,
        operatorID,
    } = useSnapshot(store);

    const {
        numberActive,
        shopsDeviceData,
        deviceData,
        deviceDataArchived,
        isActionsClicked,
        isEdited,
        isPlusIconClicked,
        dataShops,
    } = useSnapshot(device);

    const [
        isArchivedFranchiseModalOpened,
        setIsArchivedFranchiseModalOpened,
    ] = React.useState<boolean>(false);
    const [selectCountry, setSelectCountry] = React.useState<boolean>(false);
    const [
        allAssociatedCountryShop,
        setAllAssociatedCountryShop,
    ] = React.useState<any>([]);

    const [designation, setDesignation] = React.useState<string>("");
    const [currencies, setCurrencies] = React.useState<any>([]);
    const [symbol, setSymbol] = React.useState<string>("");
    const [decimalNumber, setDecimalNumber] = React.useState<string>("");
    const [decimalDesignation, setDecimalDesignation] = React.useState<string>(
        ""
    );
    const [isMessageModalOpened, setIsMessageModalOpened] = React.useState<
        boolean
    >(false);
    const [countryList, setCountryList] = React.useState<any>([]);
    const [countryData, setCountryData] = React.useState<any>([]);
    const [tagsData, setTagsData] = React.useState<any>([]);
    const [isSendingTag, setIsSendingTag] = React.useState<boolean>(false);
    const [change, setChange] = React.useState<string>("");
    const [existingDevice, setExistingDevise] = React.useState<any>({});
    const [inputSymbol, setInputSymbol] = React.useState<boolean>(false);
    const [
        inputdesignationDecimal,
        setInputdesignationDecimal,
    ] = React.useState<boolean>(false);
    const [inputChange, setInputChange] = React.useState<boolean>(false);
    const [inputdecimalNumber, setInputDecimalNumber] = React.useState<boolean>(
        false
    );
    const [tag, setTag] = React.useState<string>("");
    const [title, setTitle] = React.useState<string>("");

    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [tags, setTags] = React.useState<any[]>([]);
    const [isNameExist, setIsNameExist] = React.useState<boolean>(false);
    const [inputDesignation, setInputDesignation] = React.useState<boolean>(
        false
    );
    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);
    const [associated, setAssociated] = React.useState<any[]>([]);
    const [dataEdited, setDataEdited] = React.useState<any>();
    const shopId = oneShop ? shopID : 0;

    let dataDevices: any[] = [];
    let localName: any[] = fetchDataNameFranchiseArchived();
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    async function refreshPays() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/pays`;

        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                        "Accept-Language": i18nextLng,
                    },

                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error!");
                        }
                        let listOfTag: any = [];

                        data.data.forEach((element: any) => {
                            listOfTag.push({
                                label: element["Designation"],
                                value: element.Id,
                                frDesignation: element.frenchDesignation,
                            });
                        });
                        if (oneShop) {
                            let shopPaysItems =
                                listOfTag[
                                    searchPosInArrayObject(
                                        listOfTag,
                                        "label",
                                        shopPays
                                    )
                                ];
                                                            setCountryList([shopPaysItems?.label]);
                        } else {
                            setCountryList(
                                data.data.map((el: any) => el.Designation)
                            );
                        }
                    })
            );
        } catch (e: any) {}
    }

    // const dataFetch = async () => {
    //     const data = await (
    //         await fetch(`${process.env.REACT_APP_API_V2_URL}/settings/pays`)
    //     ).json();
    //     let listOfTag: any = [];

    //     data.data.forEach((element: any) => {
    //         listOfTag.push({
    //             label: element.Designation,
    //             value: element["Id"],
    //         });
    //     });

    //     if (oneShop) {
    //         let shopPaysItems =
    //             listOfTag[searchPosInArrayObject(listOfTag, "label", shopPays)];
    //         setCountryList([shopPaysItems?.label]);
    //     } else {
    //         setCountryList(data.data.map((el: any) => el.Designation));
    //     }
    // };
    async function getCurrencies() {
        await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/currencies/symbol`
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    setCurrencies(
                        result.data.map((el: any) => {
                            return { label: el.key, value: el.Symbole };
                        })
                    );
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    async function handleGetServicesTagsButtonOnClickEvent() {
        const newList: any[] = [];
        try {
            await Promise.all([
                fetch(
                    `${
                        process.env.REACT_APP_API_V2_URL
                    }/settings/general/tag?userId=${userID}&shopId=${"0"}&franchiseId=${franchiseID}`
                ).then((value) => value.json()),
            ]).then((result) => {
                for (let element of result[0].data) {
                    newList.push(element.Designation);
                }

                setTags(newList);
            });
        } catch (error) {
            console.log(error);
        }
    }

    function checkIfDeviceExist() {
        let allData: any[] = [];
        let isDeviceExist = false;
        if (deviceData !== undefined && deviceDataArchived !== undefined) {
            devises.id === undefined
                ? (allData = [...deviceData, ...deviceDataArchived])
                : (allData = [...dataDevices, ...deviceDataArchived]);

            allData.forEach((el: any) => {
                if (el[t("Currency")] === change) isDeviceExist = true;
            });
        }

        return isDeviceExist;
    }

    const checkIfDeviceFranchiseExist = () => {
        let isDevicesExist: boolean = false;
        if (allDataShops !== undefined) {
            isDevicesExist = allDataShops.find(
                (el: any) => el[t("Currency")] === change
            );
        }

        return isDevicesExist;
    };
    function stateDevice() {
        let state: boolean = false;

        shopsDeviceData.forEach((element: any) => {
            element.subRows.forEach((el: any) => {
                if (el.id === devises.id) {
                    state = el.isActive;
                }
            });
        });
        return state;
    }
    function AssociateDevice() {
        let idsAssociated: any = [];
        if (shopsDeviceData !== undefined) {
            shopsDeviceData.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    if (el.id === devises.id) idsAssociated.push(el.idShop);
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function fetchDataNameFranchiseArchived() {
        let localNamesFranchise: string[] = [];
        allDataFanchiseArchived?.forEach((el: any) => {
            localNamesFranchise.push(`${el[t("Currency")]}`);
        });
        return localNamesFranchise;
    }

    async function addDevice() {
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/devise`;
        let id = uuid();
        let data = {
            userId: userID,
            shopId: oneShop ? [shopId, 0] : [shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            adding: true,
            data: {
                [id]: {
                    Nom_d_Affichage: designation,
                    tag: tagsData.join(","),
                    symbole: symbol,
                    Nbr_décimale: decimalNumber,
                    designation_décimale: decimalDesignation,
                    devise: change,
                    pays: countryData.join(","),
                    isArchived: false,
                    shopId: shopId,
                },
            },
        };

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        if (checkIfDeviceFranchiseExist() && oneShop) {
            setIsMessageModalOpened(!isMessageModalOpened);
        } else if (localName.includes(`${change}`)) {
            setIsArchivedFranchiseModalOpened(true);
        } else {
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                setDataIsUpdated!(true);
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    "The currency has been successfully added"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setIsSendingTag(true);
                            setTagsData([]);
                            setDesignation("");
                            setTag("");
                            setCountryData([]);
                            setSymbol("");
                            setDataIsUpdated!(true);
                            setIsEdited(false);
                            setLocalFilterActive(false);
                            setDecimalNumber("");
                            setDecimalDesignation("");
                            setChange("");
                            clearData();
                            clearMultiCustomSelectionData();
                            setInputSymbol(false);
                            setNumberActive(numberActive + 1);

                            handleGetServicesTagsButtonOnClickEvent();
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    async function editDevice() {
        setIsValidateClicked(false);
        let dataAssociate: any[] = [];
        toast.dismiss();
        const apiUrlUpdate = `${process.env.REACT_APP_API_V2_URL}/settings/general/devise`;

        if (
            devises[t("Display name")] === designation &&
            devises[t("Currency")] === change &&
            devises[t("Tag")] === tagsData.join(",") &&
            devises[t("Country")] === countryData.join(",") &&
            devises[t("Decimal designation")] === decimalDesignation &&
            devises[t("Nbr decimal")] === decimalNumber &&
            isEdited === false
        ) {
            setIsModalOpened(!isModalOpened);
        } else if (
            existingDevice !== undefined &&
            existingDevice[t("Country")]
                .split(",")
                .map((el: any) => countryData.includes(el))
                .find((el: any) => el === false) !== undefined
        ) {
            ErrorToast(
                `${t(
                    "Please note that you cannot remove the country from a currency used in a store."
                )!}`
            );
        } else {
            let isActive: boolean =
                shopId === 0 ? stateDevice() : devises.isActive;
            let dataConfig = {
                userId: userID,
                shopId: [shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                data: {
                    [devises.id]: {
                        Nom_d_Affichage: designation,
                        tag: tagsData.join(","),
                        symbole: symbol,
                        Nbr_décimale: decimalNumber,
                        designation_décimale: decimalDesignation,
                        devise: change,
                        pays: countryData.join(","),
                        isArchived: devises.isArchived,
                        isActive: isActive,
                        shopId: shopId,
                    },
                },
            };
            setDataEdited(dataConfig);
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(dataConfig),
            };

            try {
                mutate(
                    apiUrlUpdate,
                    await fetch(apiUrlUpdate, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            if (!oneShop) {
                                dataAssociate = AssociateDevice();
                            }
                            toast.success(
                                !(dataAssociate.length > 0)
                                    ? `${t(
                                          "The currency has been changed successfully"
                                      )!}`
                                    : `${t(
                                          "The franchise motto has been successfully changed"
                                      )!}`,

                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );

                            if (dataAssociate.length > 0) {
                                setIsModalOpen(true);
                            } else {
                                if (!isHistoryModalOpened)
                                    setDataIsUpdated!(true);
                                setTagsData([]);
                                setDesignation("");
                                setTag("");
                                setIsSendingTag(true);
                                setCountryData([]);
                                setSymbol("");
                                setIsEdited(false);
                                setLocalFilterActive(false);
                                setDecimalNumber("");
                                setDecimalDesignation("");
                                setChange("");
                                clearData();
                                clearMultiCustomSelectionData();
                                setInputSymbol(false);
                                setIsAddDeviceClicked(!isAddDeviceClicked);
                            }
                            setIsValidateClicked(true);
                            // if (!isHistoryModalOpened) setDataIsUpdated!(true);
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    const formatCreateLabel = (inputValue: any) => (
        <span>
            {inputValue} ({t("New tag")})
        </span>
    );

    useEffect(() => {
        if (devises.id !== undefined) {
            setTitle(t(isConsult ? "View a currency" : "Modify a currency"));
            setDesignation(devises[t("Display name")]);
            setTag(devises[t("Tag")]);
            setSymbol(devises[t("Symbol")]);
            setDecimalNumber(devises[t("Nbr decimal")]);
            setDecimalDesignation(devises[t("Decimal designation")]);
            setCountryData(devises[t("Country")]?.split(","));
            setChange(devises[t("Currency")]);

            setTagsData(devises[t("Tag")]?.split(","));
        } else {
            setDesignation("");
            setTitle(t("Add currency"));
            setTag("");
            setSymbol("");
            setChange("");
            setDecimalNumber("");
            setDecimalDesignation("");
            setCountryData([]);
            setTagsData([]);
            setIsEdited(false);
            setInputChange(false);
            setInputSymbol(false);
        }
        setAssociated(AssociateDevice);
        getCurrencies();

        // eslint-disable-next-line
    }, [isAddNewDeviseButtonClicked, devises.id, t]);

    useEffect(() => {
        clearData();
        clearMultiCustomSelectionData();
    }, [isAddDeviceClicked, devises.id]);

    useEffect(() => {
        handleGetServicesTagsButtonOnClickEvent();
        // eslint-disable-next-line
    }, [tag]);

    React.useEffect(() => {
        // dataFetch();
        refreshPays();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (Object.values(devises).length !== 0) {
            if (devises[t("Display name")] !== "") {
                setInputDesignation(false);
            }
            if (devises[t("Currency")] !== "") {
                setInputChange(false);
                setInputSymbol(false);
            }
            if (countryData?.length !== 0) {
                setSelectCountry(false);
            }
            if (decimalNumber !== "") {
                setInputDecimalNumber(false);
            }
            if (decimalDesignation !== "") {
                setInputdesignationDecimal(false);
            }
        }
        // eslint-disable-next-line
    }, [devises]);

    React.useEffect(() => {
        let allAssociatedCountryShop: any = [];
        shopsDeviceData.forEach((element: any) => {
            element.subRows.forEach((el: any) => {
                if (devises.id === el.id) {
                    allAssociatedCountryShop.push(element[t("Country")]);
                }
            });
        });

        setAllAssociatedCountryShop([...new Set(allAssociatedCountryShop)]);
        function checkIfDeviceExistPays() {
            let pays: string[] = [];

            dataShops.forEach((element: any) => {
                element?.archivedDevices.forEach((elt: any) => {
                    pays.push(elt);
                });
                element.subRows.forEach((el: any) => {
                    pays.push(el);
                });
            });
            return pays;
        }
        let paysDevise: any = checkIfDeviceExistPays();

        setExistingDevise(paysDevise.find((el: any) => el.id === devises.id));
    }, [dataShops, devises, t, shopsDeviceData]);

    useEffect(() => {
        let isDeviceExist = checkIfDeviceExist();

        if (
            isDeviceExist &&
            (devises.id === undefined ||
                (devises.id !== undefined && devises[t("Currency")] !== change))
        ) {
            setIsNameExist(true);
        } else {
            setIsNameExist(false);
        }
        // eslint-disable-next-line
    }, [change, t]);

    React.useEffect(() => {
        deviceData.forEach((el: any) => {
            if (devises.id !== undefined) {
                if (devises.id !== el.id) {
                    dataDevices.push(el);
                }
            }
        });
        //eslint-disable-next-line
    }, [deviceData, devises]);
    React.useEffect(() => {
        if (isPlusIconClicked) {
            setInputDesignation(false);
            setInputSymbol(false);
            setInputChange(false);
            setSelectCountry(false);
            setInputDecimalNumber(false);
            setInputdesignationDecimal(false);
            setIsPlusIconClicked(false);
        }
    }, [isPlusIconClicked]);
        return (
            <React.Fragment>
                <AvForm className="card">
                    <CustomCardHeader
                        className="d-flex align-items-center justify-content-between"
                        style={{ maxHeight: "76px" }}
                    >
                        <h5 className="pt-3 dev_txt_FRtitleFormCurrency">
                            {title}
                        </h5>
                        <CrossIcon
                            className="icon-close-notification__clz dev_icn_FRcloseForm"
                            height={13}
                            width={13}
                            onClick={() => {
                                setIsConsult(false);
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        // setDevises([]);
                                        setIsAddDeviceClicked(
                                            !isAddDeviceClicked
                                        );
                                        setIsEdited(false);
                                        clearData();
                                        clearMultiCustomSelectionData();
                                        // setDataIsUpdated!(true);
                                    });
                                } else {
                                    setIsAddDeviceClicked(!isAddDeviceClicked);
                                    setIsEdited(false);
                                    clearData();
                                    clearMultiCustomSelectionData();
                                    // setDataIsUpdated!(true);
                                }
                            }}
                        />
                    </CustomCardHeader>
                    <CustomCardBody
                        className={`pl-3 pr-5 `}
                        style={{
                            maxHeight: "480px",
                            overflowY: " scroll",
                        }}
                    >
                        <AvGroup
                            className={`${isConsult ? "pe-none__clz" : ""}`}
                        >
                            <StyledLabel
                                htmlFor="text"
                                className="required__clz mt-3"
                            >
                                {t("Currency")}
                            </StyledLabel>

                            <StyledSelectInput
                                isClearable
                                autoFocus={devises.id === undefined}
                                className={classnames("dev_inp_FRcurrency", {
                                    invalid__clz: inputChange || isNameExist,
                                    "not-allowed-input__clz": devises.id,
                                })}
                                noOptionsMessage={() => t("No options")}
                                options={currencies}
                                autocomplete="off"
                                name="change"
                                onChange={(e: any) => {
                                    if (e !== null) {
                                        setChange(e.label);

                                        setSymbol(e.value);
                                        setDesignation(e.label);
                                    } else {
                                        setChange("");
                                        setSymbol("");
                                    }
                                    setIsEdited(true);
                                    setInputChange(false);
                                    setInputSymbol(false);
                                    setInputDesignation(false);
                                }}
                                value={
                                    change === ""
                                        ? null
                                        : {
                                              label: change,
                                              value: change,
                                          }
                                }
                                placeholder={`${t("Select")}…`}
                                type="text"
                            />
                            {inputChange ? (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("Please select a change")}
                                </div>
                            ) : null}
                            {isNameExist ? (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("The Currency already exists")!}
                                </div>
                            ) : null}
                        </AvGroup>
                        <AvGroup
                            className={`${isConsult ? "pe-none__clz" : ""}`}
                        >
                            <StyledLabel
                                htmlFor="text"
                                className="required__clz mt-3"
                            >
                                {t("Display name")}
                            </StyledLabel>
                            <StyledTextInput
                                className={classnames("dev_inp_FRdisplayName", {
                                    input__clz:
                                        inputDesignation ||
                                        (designation.trim() === "" &&
                                            designation !== ""),
                                })}
                                autocomplete="off"
                                name="Display name"
                                onChange={(e: any) => {
                                    setDesignation(e.target.value.trim());
                                    setIsEdited(true);
                                    setInputDesignation(false);
                                }}
                                value={designation}
                                placeholder={t("Write")}
                                type="text"
                            />
                            {inputDesignation ||
                            (designation.trim() === "" &&
                                designation !== "") ? (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("Please enter a display name")}
                                </div>
                            ) : null}
                        </AvGroup>

                        <AvGroup
                            className={`${isConsult ? "pe-none__clz" : ""}`}
                        >
                            <StyledLabel
                                htmlFor="text"
                                className="required__clz mt-3"
                            >
                                {t("Symbole")}
                            </StyledLabel>
                            <StyledTextInput
                                className="not-allowed-input__clz dev_inp_FRsymbol"
                                autocomplete="off"
                                name="symbol"
                                value={symbol}
                                placeholder={t("Symbole")}
                                type="text"
                            />
                            {inputSymbol ? (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("Please select a change")}
                                </div>
                            ) : null}
                        </AvGroup>
                        <AvGroup
                            className={`${isConsult ? "pe-none__clz" : ""}`}
                        >
                            <StyledLabel
                                htmlFor="text"
                                className="required__clz mt-3"
                            >
                                {t("Decimal designation")}
                            </StyledLabel>
                            <StyledTextInput
                                className={classnames(
                                    "dev_inp_FRdecimalDesignation",
                                    {
                                        input__clz:
                                            inputdesignationDecimal ||
                                            (decimalDesignation.trim() === "" &&
                                                decimalDesignation !== ""),
                                        " not-allowed-input__clz":
                                            devises.id && oneShop,
                                    }
                                )}
                                autocomplete="off"
                                name="decimal designation"
                                onChange={(e: any) => {
                                    setDecimalDesignation(
                                        e.target.value.trim()
                                    );
                                    setIsEdited(true);

                                    setInputdesignationDecimal(false);
                                }}
                                value={decimalDesignation}
                                placeholder={t("Write")}
                                type="text"
                            />
                            {inputdesignationDecimal ||
                            (decimalDesignation.trim() === "" &&
                                decimalDesignation !== "") ? (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("Please enter a decimal designation")}
                                </div>
                            ) : null}
                        </AvGroup>
                        <AvGroup
                            className={`${isConsult ? "pe-none__clz" : ""}`}
                        >
                            <StyledLabel
                                htmlFor="text"
                                className="required__clz mt-3"
                            >
                                {t("Decimal number")}
                            </StyledLabel>

                            <StyledSelectInput
                                maxMenuHeight="80px"
                                placeholder={`${t("Select")}…`}
                                className={classnames(
                                    "dev_inp_FRdecimalNumber",
                                    {
                                        invalid__clz: inputdecimalNumber,
                                        " not-allowed-input__clz":
                                            devises.id && oneShop,
                                    }
                                )}
                                value={
                                    decimalNumber === ""
                                        ? null
                                        : {
                                              label: decimalNumber,
                                              value: decimalNumber,
                                          }
                                }
                                onChange={(e: any) => {
                                    setIsEdited(true);
                                    setInputDecimalNumber(false);
                                    e !== null
                                        ? setDecimalNumber(e.label)
                                        : setDecimalNumber("");
                                }}
                                isClearable
                                options={[
                                    {
                                        label: "1",
                                        value: "1",
                                    },
                                    {
                                        label: "2",
                                        value: "2",
                                    },
                                    {
                                        label: "3",
                                        value: "3",
                                    },
                                    {
                                        label: "4",
                                        value: "4",
                                    },
                                ]}
                                name="mark"
                                noOptionsMessage={() => t("No options")}
                            />
                            {inputdecimalNumber ? (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("Please select a decimal number")}
                                </div>
                            ) : null}
                        </AvGroup>

                        <AvGroup
                            className={`${isConsult ? "pe-none__clz" : ""}`}
                        >
                            <StyledLabel
                                htmlFor="text"
                                className="required__clz mt-3"
                            >
                                {t("Country")}
                            </StyledLabel>
                            <MultiCustomSelect
                                onDelete={(e: any) => {
                                    setIsEdited(true);
                                }}
                                result={countryData}
                                setResult={setCountryData}
                                data={countryList}
                                onChange={(e: any) => {
                                    setIsEdited(true);
                                    setSelectCountry(false);
                                }}
                                className={classnames("dev_inp_FRcountry", {
                                    invalid__clz: selectCountry,
                                })}
                                blocked={devises.id !== undefined && oneShop}
                                placeholder={`${t("Select")}…`}
                                blockedList={
                                    oneShop
                                        ? countryList && devises.id
                                        : allAssociatedCountryShop
                                }
                                value={t("Choose")}
                                name="country"
                            />
                            {selectCountry ? (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("Please select a country")}
                                </div>
                            ) : null}
                        </AvGroup>
                        <AvGroup
                            className={`${isConsult ? "pe-none__clz" : ""}`}
                        >
                            <StyledLabel htmlFor="text" className="mt-3">
                                {t("Tag")}
                            </StyledLabel>
                            <MultiCustomCreatableSelection
                                onDelete={() => {
                                    setIsEdited(true);
                                }}
                                isSendingTag={isSendingTag}
                                setResult={setTagsData}
                                result={tagsData}
                                className="dev_inp_FRtags"
                                data={tags}
                                onChange={(e: any) => {
                                    setIsEdited(true);
                                }}
                                onCreateOption={(e: any) => {
                                    setIsEdited(true);
                                }}
                                placeholder={`${t("Select")}…`}
                                value={t("Choose")}
                                name="tag"
                                noOptionsMessage={() => t("No options")}
                                formatCreateLabel={formatCreateLabel}
                            />
                            <div className="d-flex flex-wrap"></div>
                        </AvGroup>
                    </CustomCardBody>
                    <CustomCardFooter className="pt-1 pb-2">
                        <CustomSecondaryColorButton
                            outline
                            rounded
                            className="mr-2 dev_btn_FRcancel cmn_btn_cancel"
                            variant="light"
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);
                                        setIsAddDeviceClicked(
                                            !isAddDeviceClicked
                                        );
                                        setDataIsUpdated!(true);
                                    });
                                } else {
                                    setIsAddDeviceClicked(!isAddDeviceClicked);
                                    setDataIsUpdated!(true);
                                }
                                setIsConsult(false);
                            }}
                        >
                            <span> {t("Cancel")}</span>
                        </CustomSecondaryColorButton>
                        <CustomMainColorButton
                            rounded
                            variant="primary"
                            className={`dev_btn_FRvalidate cmn_btn_validate ${
                                isConsult ? "not-allowed-input__clz" : ""
                            }`}
                            disabled={isValidateButtonDisabled}
                            onClick={() => {
                                setIsValidateButtonDisabled(true);

                                if (change === "") {
                                    setInputChange(true);
                                    setInputSymbol(true);
                                }
                                if (decimalNumber === "")
                                    setInputDecimalNumber(true);
                                if (decimalDesignation === "")
                                    setInputdesignationDecimal(true);
                                if (countryData.length === 0) {
                                    setSelectCountry(true);
                                }
                                if (designation === "")
                                    setInputDesignation(true);
                                else if (
                                    devises.id !== undefined &&
                                    designation.trim() !== "" &&
                                    !isNameExist &&
                                    change !== "" &&
                                    decimalNumber !== "" &&
                                    decimalDesignation.trim() !== "" &&
                                    countryData.length !== 0
                                ) {
                                    editDevice();
                                } else if (
                                    designation.trim() !== "" &&
                                    !isNameExist &&
                                    change !== "" &&
                                    decimalNumber !== "" &&
                                    decimalDesignation.trim() !== "" &&
                                    countryData.length !== 0
                                ) {
                                    if (deviceData.length > 0 && oneShop) {
                                        ErrorToast(
                                            t(
                                                "Attention, a shop cannot have more than one currency"
                                            )
                                        );
                                    } else {
                                        addDevice();
                                    }
                                }
                                setTimeout(() => {
                                    setIsValidateButtonDisabled(false);
                                }, 500);
                            }}
                        >
                            {t("Validate")}
                        </CustomMainColorButton>
                    </CustomCardFooter>
                </AvForm>
                <ConfirmationEditMessage
                    setDataIsUpdated={setDataIsUpdated}
                    isModalOpened={isModalOpened}
                    setIsModalOpened={setIsModalOpened}
                    isAddClicked={isAddDeviceClicked}
                    setIsAddClicked={setIsAddDeviceClicked}
                    setEditedData={setDevises}
                />
                {isModalOpen && (
                    <ConfirmationModificationMessage
                        isModalOpened={isModalOpen}
                        setIsModalOpened={setIsModalOpen}
                        setIsEdited={setIsEdited}
                        setDataIsUpdated={setDataIsUpdated}
                        dataModeSale={dataEdited}
                        shopId={associated}
                        setIsAddDeviceClicked={setIsAddDeviceClicked}
                    />
                )}
                {isMessageModalOpened ? (
                    <ConfirmationAssociateBoutique
                        msg={t(
                            "The currency already exists in the franchise. Do you want to associate it with this shop"
                        )}
                        setIsModalOpened={setIsMessageModalOpened}
                        isModalOpened={isMessageModalOpened}
                        setTagsData={setTagsData}
                        setInputSymbol={setInputSymbol}
                        setDesignation={setDesignation}
                        setCountryData={setCountryData}
                        setDecimalNumber={setDecimalNumber}
                        setDecimalDesignation={setDecimalDesignation}
                        setChange={setChange}
                        setTag={setTag}
                        setSymbol={setSymbol}
                        setDataIsUpdated={setDataIsUpdated}
                        setIsEdited={setIsEdited}
                        setIsSendingTag={setIsSendingTag}
                        setLocalFilterActive={setLocalFilterActive}
                        designation={designation}
                        change={change}
                        tagsData={tagsData}
                        allDataShops={allDataShops}
                        countryData={countryData}
                    />
                ) : null}
                {isArchivedFranchiseModalOpened ? (
                    <ConfirmationModal
                        isConfirmationModal={isArchivedFranchiseModalOpened}
                        setIsConfirmationModal={
                            setIsArchivedFranchiseModalOpened
                        }
                    />
                ) : null}
            </React.Fragment>
        );
}
