import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { uuid } from "uuidv4";
import { CheckboxIcon, StyledH2, StyledModal } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { mutate } from "swr";
import { toast } from "react-toastify";

import ErrorToast from "@components/Common/ErrorTost";
import { store } from "@components/VerticalLayout/store";
import {
    setDevise,
    setIds,
    setSelectedRow,
    store as dataStore,
    setKey,
    setSelectedRowsDevise,
} from "../store";

import { ConfirmationMessage } from "./ConfirmationMessage";
import { CustomIconsAssociateModal } from "./CustomIconsAssociateModal";
import UniqueRowSelection from "./UniqueRowSelection";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type PaymentModalAssociateModalType = {
    isAssociateModalOpened: boolean;
    setIsAssociateModalOpened: Function;
    selectedShopsRows: any;
    setDataIsUpdatedShops: Function;
    setLimit: Function;
};

export function DeviseAssociateModal({
    setIsAssociateModalOpened,
    isAssociateModalOpened,
    selectedShopsRows,
    setDataIsUpdatedShops,
    setLimit,
}: PaymentModalAssociateModalType) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const {
        idsLocalShop,
        activeDevises,
        shopsSelectedRow,
        localShop,
        rowsShop,
        selectedRowsDevise,
        devise,
    } = useSnapshot(dataStore);

    const shopId = 0;

    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );

    const [isValidateClicked, setIsValidateClicked] = React.useState<
        boolean | number
    >(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isModalArchivedOpened, setIsModalArchivedOpened] = React.useState<
        boolean
    >(false);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [selectedDevise, setSelectedDevise] = React.useState<any[]>([]);
    const [isStateChanged, setIsStateChanged] = React.useState<boolean>(false);
    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    const url =
        localShop.length === 1
            ? `${process.env.REACT_APP_API_V2_URL}/settings/general/filtered_archived_devise?userId=${userID}&shopId=${localShop[0].id}&franchiseId=${franchiseID}&type=activated&paysQuery=${localShop[0].pays}`
            : `${
                  process.env.REACT_APP_API_V2_URL
              }/settings/general/filtered_archived_devise?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&paysQuery=${localShop
                  .map((el: any) => {
                      return el.pays;
                  })
                  .join(",")}`;

    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 0,
            columnName: " ",
            customJsx: UniqueRowSelection,
        },
        {
            indexOFColumn: 6,
            columnName: t("Action"),
            customJsx: (e: any) => (
                <CustomIconsAssociateModal
                    element={e}
                    isOneShopClicked={
                        selectedShopsRows.filter(
                            (el: any) => el.subRows !== undefined
                        ).length < 2
                    }
                    selectedDevise={selectedDevise}
                />
            ),
        },
    ];

    React.useEffect(() => {
        let local: any = [];
        selectedShopsRows.forEach((el: any) => {
            if (el?.subRows !== undefined)
                el?.subRows.forEach((element: any) => {
                    local.push(element);
                });
        });
        setSelectedDevise(local);
        let notChanged: any = [];
        local.forEach((el: any) => {
            if (selectedRowsDevise.id === el.id) {
                let test: any = activeDevises.find(
                    (element: any) => element.id === el.id
                );
                notChanged.push(test.state === el.isActive);
            }
        });
        setIsStateChanged(notChanged.includes(false));
    }, [selectedShopsRows, selectedRowsDevise, activeDevises]);
    async function handleAssociateDevisesButtonClick() {
        let localData: any = [];
        let isError: boolean = false;
        let isData: boolean = false;
        if (isValidateButtonDisabled === false) {
            setIsValidateButtonDisabled(true);
            const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/devise`;
            for (let element of selectedShopsRows) {
                if (devise !== "") {
                    if (
                        JSON.parse(JSON.stringify(selectedRowsDevise))
                            .length === 0
                    ) {
                        toast.success(
                            `${t("The assignment was successful")}`,

                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsAssociateModalOpened(!isAssociateModalOpened);
                        setIsValidateButtonDisabled(false);
                    } else if (
                        element.subRows !== undefined &&
                        element?.subRows.length === 1 &&
                        element?.subRows[0].id !== selectedRowsDevise.id
                    ) {
                        isError = true;
                    } else if (
                        (element.subRows !== undefined &&
                            element?.subRows.length === 0 &&
                            selectedRowsDevise[t("Country")]
                                .split(",")
                                .includes(element[t("Country")])) ||
                        (element.subRows !== undefined &&
                            element?.subRows.length === 1 &&
                            element?.subRows[0].id === selectedRowsDevise.id &&
                            selectedRowsDevise[t("Country")]
                                .split(",")
                                .includes(element[t("Country")]))
                    ) {
                        let objectData: any = {
                            affectation:
                                isStateChanged !== true
                                    ? "true"
                                    : "reassignment",
                            userId: userID,
                            shopId: [element.idShop],
                            franchiseId: franchiseID,
                            operatorId: operatorID,
                            uuidUser: uuidUser,
                            data: {
                                [selectedRowsDevise.id]: {
                                    Nom_d_Affichage:
                                        selectedRowsDevise[t("Display name")],
                                    tag: selectedRowsDevise[t("Tag")],
                                    symbole: selectedRowsDevise[t("Symbol")],
                                    Nbr_décimale:
                                        selectedRowsDevise[t("Nbr decimal")],
                                    designation_décimale:
                                        selectedRowsDevise[
                                            t("Decimal designation")
                                        ],
                                    devise: selectedRowsDevise[t("Currency")],
                                    pays: selectedRowsDevise[t("Country")],
                                    shopId: selectedRowsDevise.shopId,
                                    isActive: activeDevises.find(
                                        (element: any) =>
                                            element.id === selectedRowsDevise.id
                                    ).state,
                                    isArchived: selectedRowsDevise.isArchived,
                                },
                            },
                        };

                        localData.push(
                            fetch(apiUrl, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify(objectData),
                            }).then((response) => response.json())
                        );
                    }
                    if (localData.length === 0) {
                        isData = true;
                    }
                }
            }
            try {
                mutate(
                    apiUrl,
                    await Promise.all(localData).then((result: any) => {
                        let errors: boolean[] = result.map(
                            (el: any) => el.error
                        );
                        if (!errors.includes(true)) {
                            if (!isData) {
                                toast.success(
                                    !isStateChanged
                                        ? `${t(
                                              "The assignment was successful"
                                          )}`
                                        : `${t(
                                              "The reassignment was successful"
                                          )}`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 2000,
                                        theme: "colored",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        icon: (
                                            <CheckboxIcon
                                                height={25}
                                                width={25}
                                                fill="white"
                                            />
                                        ),
                                    }
                                );
                                setIsValidateClicked(false);
                                setIsAssociateModalOpened(
                                    !isAssociateModalOpened
                                );
                                setSelectedRows([]);
                                setLimit(2);
                                setIsValidateButtonDisabled(false);
                            }
                        } else {
                            throw Error(result.message);
                        }
                    })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
            if (isError === true) {
                ErrorToast(
                    `${t(
                        "Please note that the currency could not be assigned to one or more shops.\n A store cannot have more than one currency."
                    )}`
                );
                setIsAssociateModalOpened(!isAssociateModalOpened);
                setIsValidateButtonDisabled(false);
            }
            setSelectedRowsDevise([]);
            setIsValidateButtonDisabled(false);
            setKey(uuid());
        }
    }
    function CountIds() {
        let number = 0;
        idsLocalShop.forEach((el: any) => {
            selectedRows.forEach((v: any) => {
                if (v.Id === el) {
                    number = number + 1;
                }
            });
        });
        setIds(number);
    }

    function checkIfDeviseExistAndArchived() {
        let archived: any[] = [];
        if (shopsSelectedRow.length > 0) {
            shopsSelectedRow.forEach((element: any) => {
                if (element?.archivedDevices !== undefined) {
                    element?.archivedDevices.forEach((el: any) => {
                        archived.push(el.id);
                    });
                }
            });
        }

        return archived;
    }
    let archived = checkIfDeviseExistAndArchived();
    React.useEffect(() => {
        let localSelectedDevice: any = [];
        selectedShopsRows.forEach((el: any) => {
            if (el.subRows !== undefined)
                el.subRows.forEach((element: any) => {
                    localSelectedDevice.push(element);
                });
        });
        setSelectedDevise(localSelectedDevice);
    }, [selectedShopsRows, selectedRows, activeDevises]);
    React.useEffect(() => {
        if (rowsShop[0] !== undefined) {
            if (rowsShop[0]?.subRows !== undefined) {
                localShop.length > 1
                    ? setDevise("")
                    : setDevise(
                          rowsShop[0]?.subRows[0] !== undefined
                              ? rowsShop[0]?.subRows[0]?.id
                              : rowsShop[0].id
                      );
            } else if (rowsShop[1]?.subRows !== undefined) {
                localShop.length > 1
                    ? setDevise("")
                    : setDevise(
                          rowsShop[1]?.subRows[1] !== undefined
                              ? rowsShop[1]?.subRows[1]?.id
                              : rowsShop[0].id
                      );
            }
        }
    }, [rowsShop, localShop]);
    React.useEffect(() => {
        if (isValidateClicked === true) {
            handleAssociateDevisesButtonClick();
        }
        // eslint-disable-next-line
    }, [isValidateClicked]);

    return (
        <React.Fragment>
            <StyledModal
                toggle={true}
                isOpen={isAssociateModalOpened!}
                centered
                className="modal-wrapper__clz"
                backdrop="static"
                fade={false}
                scrollable
            >
                <ModalHeader
                    className="text-capitalize dev_icn_AFclose"
                    toggle={() => {
                        setIsAssociateModalOpened(!isAssociateModalOpened);
                        setDataIsUpdated(true);
                    }}
                >
                    <StyledH2 className="text-uppercase dev_txt_AFcurrencyList">
                        {t("List of currencys")}
                    </StyledH2>
                </ModalHeader>
                <ModalBody>
                    <DynamicTable
                        url={url}
                        customSelect
                        setSelectedRows={setSelectedRows}
                        canResize
                        canMovedCheckboxLeftOnExpand
                        actionColumn={ActionColumn}
                        requestHeader={{ "Accept-Language": i18nextLng }}
                        showGlobalFilter
                        setDataIsUpdated={setDataIsUpdated}
                        dataIsUpdated={dataIsUpdated}
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        elevationTable={1}
                        minHeight="500px"
                        maxHeight="500px"
                        name="associate"
                    />
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <CustomSecondaryColorButton
                        outline
                        variant="light"
                        className="dev_btn_AFcancel"
                        onClick={() => {
                            setIsAssociateModalOpened(!isAssociateModalOpened);
                            setDataIsUpdated(true);
                        }}
                        rounded
                    >
                        {t("Cancel")}
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        className="dev_btn_AFvalidate"
                        rounded
                        variant="primary"
                        onClick={() => {
                            CountIds();
                            if (devise === "" || devise === undefined) {
                                ErrorToast(
                                    `${t(
                                        "Attention ! You must select at least one currency"
                                    )!}`
                                );
                                setDataIsUpdated(true);
                            } else {
                                setSelectedRow(selectedRows);
                                if (isStateChanged) {
                                    setIsModalOpened(!isModalOpened);
                                } else if (
                                    archived.includes(selectedRowsDevise.id)
                                ) {
                                    setIsModalArchivedOpened(
                                        !isModalArchivedOpened
                                    );
                                } else {
                                    handleAssociateDevisesButtonClick();
                                }
                            }
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </ModalFooter>
            </StyledModal>
            {isModalOpened ? (
                <ConfirmationMessage
                    msg={t(
                        'Do you want to apply these changes for all stores? \n Attention ! If you click on "Validate", the specific settings of the shops will be modified.'
                    )}
                    setIsModalOpened={setIsModalOpened}
                    isModalOpened={isModalOpened}
                    setIsValidateClicked={setIsValidateClicked}
                />
            ) : null}
            {isModalArchivedOpened ? (
                <ConfirmationMessage
                    msg={t(
                        "Attention! One or more selected currency are already assigned and archived at the store level.Would you unarchive them?"
                    )}
                    setIsModalOpened={setIsModalArchivedOpened}
                    isModalOpened={isModalArchivedOpened}
                    setIsValidateClicked={setIsValidateClicked}
                />
            ) : null}
        </React.Fragment>
    );
}
