import { DeviceSettingsIcon } from "@components/Common/SvgIcons";

export type CardMenuType = {
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    description?: string;
    title: string;
    path: string;
};

export const CARD_MENU_LIST: CardMenuType[] = [
    {
        title: "C&C Setup",
        Icon: DeviceSettingsIcon,
        description:
            "To configure the C&C application and website, it is crucial to consider the sales methods and payment modes associated with the C&C site and application, as well as the VAT rates, in order to configure them appropriately.",
        path: "/Configuration",
    },
];
