import React from "react";

export default function ArrowCircleLeftSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34.875"
            height="34.875"
            viewBox="0 0 34.875 34.875"
            {...props}
        >
            <path
                d="M18 35.438A17.438 17.438 0 10.563 18 17.434 17.434 0 0018 35.438zM9.844 14.906H18V9.921a.844.844 0 011.441-.6l8.037 8.079a.836.836 0 010 1.188l-8.037 8.082a.844.844 0 01-1.441-.6v-4.976H9.844A.846.846 0 019 20.25v-4.5a.846.846 0 01.844-.844z"
                data-name="Icon awesome-arrow-alt-circle-left"
                transform="translate(-.563 -.563)"
            ></path>
        </svg>
    );
}
