import React from "react";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import {
    Dropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
} from "reactstrap";
import { toast } from "react-toastify";
import {
    VerticalDotsIcon,
    SettingsIcon,
    DuplicateIcon,
    TrashIcon,
    EyeIcon,
    ShieldExclamationIcon,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { CheckboxIcon } from "@aureskonnect/react-ui";

import {
    store,
    setIsModalAssociateStore,
    setIsModesModalPublishOpened,
    setIsCreatedCoping,
    setIsModalArchiveOpened,
    setIsModalDissociateOpened,
    setIsConfirmationPublishProject,
} from "../../store/project/index";
import { setProjectId } from "@components/VerticalLayout/store";
import {
    setChosenTemplate,
    setIsConfigActive,
    setIsProjectModesModalOpened,
    setProject,
    setSelectedSaleModeValue,
    setItems,
    setIsCustomerAccountActive,
    setSelectedLanguage,
    setDefaultLanguage,
    setIsModalOpened,
    setInitDesignStoreVariables,
    setInitStoreVariables,
    setDefaultWays,
    setInitItemsFromGeneralSetting,
    setPaymentSelectedRegulationModeValue,
    setPaymentMainContent,
    generalConfigStore,
    setConfigOptionsCustomerAccount,
    setIsStateChanged,
    setFiles,
    setActiveLanguages,
    setIsInformationModesEmpty,
    setIsModesModalOpened,
} from "@store";
import { setIsModalOpenedPlaning, setIsConsult } from "../../store/project";
import { setIsKioskSetting, setProjectRowData } from "../GeneralConfig/store";
import { store as userStore } from "../../components/VerticalLayout/store";

import ErrorToast from "@components/Common/ErrorTost";
import { FlexboxGrid } from "@components/Common/FlexboxGrid";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { moveArrayElementToTheBegin } from "@helpers/general";
import ConsultSvgIcon from "@components/Common/SvgIcons/ConsultSvgIcon";

import "./index.css";

type DropDownWrapperType = {
    element: any;
    setIsDataUpdated: Function;
    setSelectedRows: Function;
    isArchivedTemplateClicked: boolean;
    isDataUpdated: boolean | number;
    dataProject: any;
    isCreatedProject: boolean;
    setIsCreatedProject: Function;
};

export function DropDownWrapper({
    element,
    setSelectedRows,
    setIsDataUpdated,
    isArchivedTemplateClicked,
    isDataUpdated,
    dataProject,
    setIsCreatedProject,
}: DropDownWrapperType): JSX.Element {
    const { t } = useTranslation();
    const {
        isModalAssociateStore,
        isModesModalPublishOpened,
        isCreatedCoping,
        isModalArchiveOpened,
        isModalDissociateOpened,
        projectData,
        isConfirmationPublishProject,
        isCreateProjectClicked,
        modify,
        isLoading,
    } = useSnapshot(store);
    const { oneShop, franchiseID, shopID } = useSnapshot(userStore);
    const { project } = useSnapshot(generalConfigStore);

    const [isDropdownOpened, setIsDropdownOpened] = React.useState(false);

    const [isAlertModalOpened, setIsAlertModalOpened] = React.useState<boolean>(
        false
    );

    function alertToggle() {
        setIsAlertModalOpened(!isAlertModalOpened);
    }
    function toggleDropdown() {
        setIsDropdownOpened((prevState) => !prevState);
    }

    const getProject = () => {
        if (projectData) {
            let project1 = projectData.filter(
                (elt) =>
                    elt.projectId === element?.selectedRow?.original?.id_project
            );
            return project1[0];
        } else {
            return project;
        }
    };
    const isExistShops = React.useCallback(() => {
        let isExist: boolean = false;
        dataProject?.dataProjectFranchise?.data.forEach((el: any) => {
            if (el.projectId === element.selectedRow.original.projectId) {
                if (el.shopKey.length > 1) {
                    isExist = true;
                }
            }
        });
        return isExist;
    }, [element.selectedRow.original.projectId, dataProject]);
    let isExist = isExistShops();
    const isExistPublishProject: any = React.useCallback(() => {
        let isExist: boolean = false;
        if (projectData !== undefined) {
            projectData.forEach((el: any) => {
                if (el?.Status === "Publié") {
                    isExist = true;
                }
            });
            return isExist;
        }
        return isExist;
    }, [projectData]);
    let isExistProjectPublish = isExistPublishProject();

    const getProjectData = async () => {
        let dataProject: any;
        let apiUrl = `${
            process.env.REACT_APP_API_V2_URL
        }/settings/projectData?userId=106&shopId=${
            oneShop
                ? shopID
                : element?.selectedRow?.original?.shopId ??
                  element?.selectedRow?.original?.id_boutique
        }&franchiseId=${franchiseID}&projectId=${
            element?.selectedRow?.original?.id_project ??
            element?.selectedRow?.original?.projectId
        }`;
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        dataProject = data[0];
                    })
            );
            if (dataProject.template.id !== "") {
                const newLocalProjectWays: ItemsType = {};

                Object.values(dataProject.template.pages.ways).forEach(
                    (item: any, index) => {
                        newLocalProjectWays[item.name] = {
                            ...item,
                            index: index,
                        };
                    }
                );
                dataProject.template.pages.ways = newLocalProjectWays;
            }

            return dataProject;
        } catch (e: any) {}
    };

    const handleSettingIconOnClickEvent = async () => {
        let dataProject = await getProjectData();
        let data: any = dataProject.template;
        let files: any = dataProject.files;
        let hoursOfUse: any = dataProject.hoursOfUse;
        if (
            (data.content.salesMethods as ProjectContentItemType).items.filter(
                (item: any) =>
                    item.active === true &&
                    item.id !== "f25e7c96-b5d3-4f2e-acd0-1500258283c2"
            ).length === 0 ||
            (data.content.languages as LanguagesType).items.filter(
                (item: any) => item.active === true
            ).length === 0
        ) {
            toast.warning(
                `${t(
                    `Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings.`
                )!}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
        } else {
            setPaymentSelectedRegulationModeValue(
                (data?.content.payment as PaymentContentItemType).items[0]
                    .shortName
            );

            setPaymentMainContent(
                ((data?.content as ProjectContentType)[
                    "payment"
                ] as PaymentContentItemType).items
            );

            setIsModesModalOpened(true);
            setProjectId(element?.selectedRow?.original?.id_project);
            setProjectRowData({
                ...element?.selectedRow?.original,
                files: [...files],
                template: { ...data },
            });
            setSelectedRows({
                ...element?.selectedRow?.original,
                files: [...files],
                template: { ...data },
            });
            if (element?.selectedRow?.original.Status === "Publié") {
                setIsKioskSetting(true);
                setIsModalOpened(true);
            } else {
                setIsKioskSetting(false);
                setIsProjectModesModalOpened(true);
            }
            setIsConfigActive(true);

            setProject({
                ...element?.selectedRow?.original,
                files: [...files],
                template: { ...data },
            });
            setChosenTemplate(data, files);
            setFiles(files);

            setInitItemsFromGeneralSetting({
                informationModes: data.content.informationModes.items,
                saleMethods: data.content.salesMethods.items,
                reglements: data.content.meansOfPayment.items,
                languages: data.content.languages.items,
                logo: data.content.generalDesign.logo,
            });

            setDefaultLanguage(
                (data.content.languages as LanguagesType).items.filter(
                    (language) => language.isDefault === true
                )[0]
            );
            setSelectedLanguage(
                (data.content.languages as LanguagesType).items.filter(
                    (language) => language.isDefault === true
                )[0].name
            );
            setActiveLanguages(
                moveArrayElementToTheBegin(
                    (data.content.languages as LanguagesType).items
                        .filter((language) => language.active === true)
                        .filter(
                            (element: LanguagesItemType): boolean =>
                                element.isDefault === true
                        )[0],
                    (data.content.languages as LanguagesType).items.filter(
                        (language) => language.active === true
                    )
                )
            );
            const selectedSaleMethodDeliveryExist = (data.content
                .salesMethods as ProjectContentItemType).items.filter(
                (sale: any) => sale.name === "Delivery"
            )[0];
            if (
                selectedSaleMethodDeliveryExist !== undefined &&
                selectedSaleMethodDeliveryExist.active === true
            ) {
                setIsCustomerAccountActive(true);

                setIsStateChanged(true);
                setConfigOptionsCustomerAccount(true);
            } else {
                setIsCustomerAccountActive(
                    (data.content.options as OptionsType).items.filter(
                        (item) => item.title === "Customer account"
                    )[0].active
                );
            }

            if (
                (data.content.informationModes as ProjectContentItemType).items
                    .length === 0 ||
                (data.content
                    .informationModes as ProjectContentItemType).items.filter(
                    (item: ProjectMainContentItemType) => {
                        return (data.content
                            .salesMethods as ProjectContentItemType).items.filter(
                            (saleMethod: any) => {
                                return (
                                    Object.keys(item.active).includes(
                                        saleMethod.name
                                    ) && saleMethod.active === true
                                );
                            }
                        );
                    }
                ).length === 0
            ) {
                setIsInformationModesEmpty(true);
                setSelectedSaleModeValue("");
            } else {
                setIsInformationModesEmpty(false);

                const salesMethodInformationMode = (data.content
                    .salesMethods as ProjectContentItemType).items.filter(
                    (saleMethod) => {
                        return (
                            saleMethod.name !== "Retrait C&C" &&
                            (saleMethod.informationModes as InformationModesContentType)
                                .items.length !== 0 &&
                            saleMethod.active === true
                        );
                    }
                )[0];
                if (salesMethodInformationMode !== undefined) {
                    setSelectedSaleModeValue(salesMethodInformationMode.name);
                }
            }

            setItems(data.pages);
            setDefaultWays(data.pages);
            localStorage.setItem("hoursOfUse", JSON.stringify(hoursOfUse));
        }
    };

    return (
        <React.Fragment>
            <Modal
                isOpen={isAlertModalOpened}
                toggle={alertToggle}
                backdrop={false}
                fade={false}
                centered
            >
                <ModalHeader toggle={alertToggle}>
                    <span
                        style={{
                            textAlign: "left",
                            font: " normal normal 600 25px Segoe UI",
                            color: "#000000",
                        }}
                    >
                        {" "}
                        {t("Alert")}
                    </span>
                </ModalHeader>
                <ModalBody
                    className="d-flex flex-column"
                    style={{ gap: "10px" }}
                >
                    <div
                        style={{
                            textAlign: "center",
                            font: " normal normal 600 16px/10px Segoe UI",
                            color: "#2B2828",
                        }}
                    >
                        {t("Warning! Missing setting.")}
                    </div>
                    <div
                        style={{
                            font: " normal normal 600 16px/20px Segoe UI",
                            textAlign: "left",
                            color: "#2B2828",
                            padding: "14px",
                        }}
                    >
                        {t(
                            "To configure your KIOSK, it is essential to configure the elements in the general configuration section."
                        )}
                    </div>
                    <FlexboxGrid
                        className="pt-4 justify-content-end"
                        gap="10px"
                    >
                        <CustomMainColorButton
                            rounded
                            variant="primary"
                            onClick={() => setIsAlertModalOpened(false)}
                        >
                            {t("Close")}
                        </CustomMainColorButton>
                    </FlexboxGrid>
                </ModalBody>
            </Modal>
            <React.Fragment>
                {modify === true && (
                    <React.Fragment>
                        {element.selectedRow.original.projectId ? (
                            <Dropdown
                                isOpen={isDropdownOpened}
                                toggle={toggleDropdown}
                                className={`${classnames("", {
                                    "not-allowed-icon__clz":
                                        isLoading || isCreateProjectClicked,
                                })}`}
                            >
                                <DropdownToggle
                                    id="page-header-user-dropdown"
                                    tag="button"
                                    className={`${classnames(
                                        "pointer__clz btn btn header-item waves-effect",
                                        {
                                            "not-allowed-icon__clz":
                                                isCreateProjectClicked === true,
                                        }
                                    )}`}
                                >
                                    <VerticalDotsIcon
                                        height={25}
                                        width={25}
                                        className="Cgt_drp_menuProject"
                                    />
                                </DropdownToggle>
                                <DropdownMenu>
                                    {element.selectedRow.original.Status ===
                                        "Publié" && oneShop ? (
                                        <DropdownItem
                                            className={classnames(
                                                "Cgt_icn_configuration",
                                                {
                                                    "not-allowed-icon__clz":
                                                        isArchivedTemplateClicked ||
                                                        element.selectedRow
                                                            .original.Status ===
                                                            "Planifié",
                                                }
                                            )}
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            onClick={
                                                handleSettingIconOnClickEvent
                                            }
                                        >
                                            <SettingsIcon
                                                height={15}
                                                width={15}
                                            />
                                            <span className="ml-1">
                                                {t("Configure")}
                                            </span>
                                        </DropdownItem>
                                    ) : null}

                                    {element.selectedRow.original.Status ===
                                        "Publié" ||
                                    element.selectedRow.original.isArchived ? (
                                        <DropdownItem
                                            style={{
                                                cursor: "pointer",
                                                right: "5px",
                                                position: "relative",
                                            }}
                                            className="cgt_icn_consult"
                                            onClick={async () => {
                                                let template: any = (
                                                    await getProjectData()
                                                ).template;
                                                let files: any = (
                                                    await getProjectData()
                                                ).files;

                                                setIsModesModalOpened(true);
                                                setIsKioskSetting(false);
                                                setInitStoreVariables();
                                                setInitDesignStoreVariables();
                                                setIsConfigActive(true);
                                                setSelectedRows({
                                                    ...element.selectedRow
                                                        .original,
                                                    files: [...files],
                                                    template: { ...template },
                                                });
                                                setProject({
                                                    ...element.selectedRow
                                                        .original,
                                                    files: [...files],
                                                    template: { ...template },
                                                });
                                                toast.info(
                                                    element.selectedRow.original
                                                        .isArchived
                                                        ? `${t(
                                                              "Please note that you cannot modify an archived template"
                                                          )!}`
                                                        : `${t(
                                                              "Please note that you cannot modify a published template"
                                                          )!}`,
                                                    {
                                                        position:
                                                            toast.POSITION
                                                                .TOP_CENTER,
                                                        autoClose: 2000,
                                                        theme: "colored",
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                    }
                                                );
                                                if (template.id !== "") {
                                                    if (
                                                        (template.content
                                                            .salesMethods as ProjectContentItemType).items.filter(
                                                            (item: any) =>
                                                                item.active ===
                                                                    true &&
                                                                item.id !==
                                                                    "f25e7c96-b5d3-4f2e-acd0-1500258283c2"
                                                        ).length === 0 ||
                                                        (template.content
                                                            .languages as LanguagesType).items.filter(
                                                            (item: any) =>
                                                                item.active ===
                                                                true
                                                        ).length === 0
                                                    ) {
                                                        setIsModesModalOpened(
                                                            false
                                                        );
                                                        toast.warning(
                                                            `${t(
                                                                `Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings.`
                                                            )!}`,
                                                            {
                                                                position:
                                                                    toast
                                                                        .POSITION
                                                                        .TOP_CENTER,
                                                                autoClose: 2000,
                                                                theme:
                                                                    "colored",
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true,
                                                                icon: (
                                                                    <CheckboxIcon
                                                                        height={
                                                                            25
                                                                        }
                                                                        width={
                                                                            25
                                                                        }
                                                                        fill="white"
                                                                    />
                                                                ),
                                                            }
                                                        );
                                                    } else {
                                                        setChosenTemplate(
                                                            template,
                                                            files
                                                        );

                                                        setDefaultLanguage(
                                                            (template.content
                                                                .languages as LanguagesType).items.filter(
                                                                (language) =>
                                                                    language.isDefault ===
                                                                    true
                                                            )[0]
                                                        );
                                                        setSelectedLanguage(
                                                            (template.content
                                                                .languages as LanguagesType).items.filter(
                                                                (language) =>
                                                                    language.isDefault ===
                                                                    true
                                                            )[0].name
                                                        );
                                                        const selectedSaleMethodDeliveryExist = (template
                                                            .content
                                                            .salesMethods as ProjectContentItemType).items.filter(
                                                            (sale: any) =>
                                                                sale.name ===
                                                                "Delivery"
                                                        )[0];
                                                        if (
                                                            selectedSaleMethodDeliveryExist !==
                                                                undefined &&
                                                            selectedSaleMethodDeliveryExist.active ===
                                                                true
                                                        ) {
                                                            setIsCustomerAccountActive(
                                                                true
                                                            );

                                                            setIsStateChanged(
                                                                true
                                                            );
                                                            setConfigOptionsCustomerAccount(
                                                                true
                                                            );
                                                        } else {
                                                            setIsCustomerAccountActive(
                                                                (template
                                                                    .content
                                                                    .options as OptionsType).items.filter(
                                                                    (item) =>
                                                                        item.title ===
                                                                        "Customer account"
                                                                )[0].active
                                                            );
                                                        }

                                                        if (
                                                            (template.content
                                                                .informationModes as ProjectContentItemType)
                                                                .items
                                                                .length === 0 ||
                                                            (template.content
                                                                .informationModes as ProjectContentItemType).items.filter(
                                                                (
                                                                    item: ProjectMainContentItemType
                                                                ) => {
                                                                    return (template
                                                                        .content
                                                                        .salesMethods as ProjectContentItemType).items.filter(
                                                                        (
                                                                            saleMethod: any
                                                                        ) => {
                                                                            return (
                                                                                Object.keys(
                                                                                    item.active
                                                                                ).includes(
                                                                                    saleMethod.name
                                                                                ) &&
                                                                                saleMethod.active ===
                                                                                    true
                                                                            );
                                                                        }
                                                                    );
                                                                }
                                                            ).length === 0
                                                        ) {
                                                            setIsInformationModesEmpty(
                                                                true
                                                            );
                                                        } else {
                                                            setIsInformationModesEmpty(
                                                                false
                                                            );

                                                            const salesMethodInformationMode = (template
                                                                .content
                                                                .salesMethods as ProjectContentItemType).items.filter(
                                                                (
                                                                    saleMethod
                                                                ) => {
                                                                    return (
                                                                        saleMethod.name !==
                                                                            "Retrait C&C" &&
                                                                        (saleMethod.informationModes as InformationModesContentType)
                                                                            .items
                                                                            .length !==
                                                                            0 &&
                                                                        saleMethod.active ===
                                                                            true
                                                                    );
                                                                }
                                                            )[0];
                                                            if (
                                                                salesMethodInformationMode !==
                                                                undefined
                                                            ) {
                                                                setSelectedSaleModeValue(
                                                                    salesMethodInformationMode.name
                                                                );
                                                            }
                                                        }

                                                        setInitItemsFromGeneralSetting(
                                                            {
                                                                informationModes:
                                                                    template
                                                                        .content
                                                                        .informationModes
                                                                        .items,
                                                                saleMethods:
                                                                    template
                                                                        .content
                                                                        .salesMethods
                                                                        .items,
                                                                reglements:
                                                                    template
                                                                        .content
                                                                        .meansOfPayment
                                                                        .items,
                                                                languages:
                                                                    template
                                                                        .content
                                                                        .languages
                                                                        .items,
                                                                logo:
                                                                    template
                                                                        .content
                                                                        .generalDesign
                                                                        .logo,
                                                            }
                                                        );
                                                        setItems(
                                                            template.pages
                                                        );
                                                        setDefaultWays(
                                                            template.pages
                                                        );
                                                    }
                                                }
                                                setProjectId(
                                                    element.selectedRow.original
                                                        .projectId
                                                );
                                                setIsConsult(true);
                                                setIsModalOpened(true);
                                            }}
                                        >
                                            <ConsultSvgIcon
                                                height={25}
                                                width={25}
                                            />
                                            <span>{t("To consult")}</span>
                                        </DropdownItem>
                                    ) : (
                                        <DropdownItem
                                            className={classnames(
                                                "Cgt_icn_configuration",
                                                {
                                                    "not-allowed-icon__clz":
                                                        isArchivedTemplateClicked ||
                                                        element.selectedRow
                                                            .original.Status ===
                                                            "Planifié",
                                                }
                                            )}
                                            style={{ cursor: "pointer" }}
                                            onClick={async () => {
                                                let dataProject = await getProjectData();

                                                if (
                                                    dataProject !== undefined &&
                                                    dataProject?.template !==
                                                        undefined &&
                                                    dataProject?.template.id !==
                                                        ""
                                                ) {
                                                    let data: any =
                                                        dataProject?.template;
                                                    if (
                                                        (((data.content
                                                            .salesMethods as ProjectContentItemType).items.filter(
                                                            (item: any) =>
                                                                item.active ===
                                                                    true &&
                                                                item.id !==
                                                                    "f25e7c96-b5d3-4f2e-acd0-1500258283c2"
                                                        ).length === 0 ||
                                                            (data.content
                                                                .languages as LanguagesType).items.filter(
                                                                (item: any) =>
                                                                    item.active ===
                                                                    true
                                                            ).length === 0) &&
                                                            element.selectedRow
                                                                .original
                                                                .shopId !==
                                                                0) ||
                                                        (((data.content
                                                            .salesMethods as ProjectContentItemType).items.filter(
                                                            (item: any) =>
                                                                item.id !==
                                                                "f25e7c96-b5d3-4f2e-acd0-1500258283c2"
                                                        ).length === 0 ||
                                                            (data.content
                                                                .languages as LanguagesType)
                                                                .items
                                                                .length ===
                                                                0) &&
                                                            element.selectedRow
                                                                .original
                                                                .shopId === 0)
                                                    ) {
                                                        toast.warning(
                                                            `${t(
                                                                `Attention, access to the Template configuration is not possible. Please check your language and sales mode settings to resolve this issue.`
                                                            )!}`,
                                                            {
                                                                position:
                                                                    toast
                                                                        .POSITION
                                                                        .TOP_CENTER,
                                                                autoClose: 2000,
                                                                theme:
                                                                    "colored",
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true,
                                                                icon: (
                                                                    <CheckboxIcon
                                                                        height={
                                                                            25
                                                                        }
                                                                        width={
                                                                            25
                                                                        }
                                                                        fill="white"
                                                                    />
                                                                ),
                                                            }
                                                        );
                                                        setIsModesModalOpened(
                                                            false
                                                        );
                                                    } else {
                                                        let files: any =
                                                            dataProject.files;
                                                        setSelectedRows({
                                                            ...element
                                                                .selectedRow
                                                                .original,
                                                            template: {
                                                                ...data,
                                                            },
                                                        });
                                                        setProjectRowData({
                                                            ...element
                                                                .selectedRow
                                                                .original,
                                                            template: {
                                                                ...data,
                                                            },
                                                        });
                                                        setIsModesModalOpened(
                                                            true
                                                        );
                                                        setIsConsult(false);
                                                        setIsKioskSetting(
                                                            false
                                                        );
                                                        setInitStoreVariables();
                                                        setInitDesignStoreVariables();
                                                        setIsConfigActive(true);
                                                        setProject({
                                                            ...element
                                                                .selectedRow
                                                                .original,
                                                            template: {
                                                                ...data,
                                                            },
                                                        });
                                                        setChosenTemplate(
                                                            data,
                                                            files
                                                        );
                                                        setActiveLanguages(
                                                            moveArrayElementToTheBegin(
                                                                (data.content
                                                                    .languages as LanguagesType).items.filter(
                                                                    (
                                                                        element: LanguagesItemType
                                                                    ): boolean =>
                                                                        element.isDefault ===
                                                                        true
                                                                )[0],
                                                                (data.content
                                                                    .languages as LanguagesType).items.filter(
                                                                    (
                                                                        language
                                                                    ) =>
                                                                        language.active ===
                                                                        true
                                                                )
                                                            )
                                                        );
                                                        setDefaultLanguage(
                                                            (data.content
                                                                .languages as LanguagesType).items.filter(
                                                                (language) =>
                                                                    language.isDefault ===
                                                                    true
                                                            )[0]
                                                        );
                                                        setSelectedLanguage(
                                                            (data.content
                                                                .languages as LanguagesType).items.filter(
                                                                (language) =>
                                                                    language.isDefault ===
                                                                    true
                                                            )[0].name
                                                        );
                                                        setIsCustomerAccountActive(
                                                            (data.content
                                                                .options as OptionsType).items.filter(
                                                                (
                                                                    item: OptionsItemType
                                                                ) =>
                                                                    item.title ===
                                                                    "Customer account"
                                                            )[0].active
                                                        );

                                                        if (
                                                            (data.content
                                                                .informationModes as ProjectContentItemType)
                                                                .items
                                                                .length === 0 ||
                                                            (data.content
                                                                .informationModes as ProjectContentItemType).items.filter(
                                                                (
                                                                    item: ProjectMainContentItemType
                                                                ) => {
                                                                    return (data
                                                                        .content
                                                                        .salesMethods as ProjectContentItemType).items.filter(
                                                                        (
                                                                            saleMethod: any
                                                                        ) => {
                                                                            return (
                                                                                Object.keys(
                                                                                    item.active
                                                                                ).includes(
                                                                                    saleMethod.name
                                                                                ) &&
                                                                                saleMethod.active ===
                                                                                    true
                                                                            );
                                                                        }
                                                                    );
                                                                }
                                                            ).length === 0
                                                        ) {
                                                            setIsInformationModesEmpty(
                                                                true
                                                            );
                                                        } else {
                                                            setIsInformationModesEmpty(
                                                                false
                                                            );

                                                            const salesMethodInformationMode = (data
                                                                .content
                                                                .salesMethods as ProjectContentItemType).items.filter(
                                                                (
                                                                    saleMethod
                                                                ) => {
                                                                    return (
                                                                        saleMethod.name !==
                                                                            "Retrait C&C" &&
                                                                        (saleMethod.informationModes as InformationModesContentType)
                                                                            .items
                                                                            .length !==
                                                                            0 &&
                                                                        saleMethod.active ===
                                                                            true
                                                                    );
                                                                }
                                                            )[0];
                                                            if (
                                                                salesMethodInformationMode !==
                                                                undefined
                                                            ) {
                                                                setSelectedSaleModeValue(
                                                                    salesMethodInformationMode.name
                                                                );
                                                            }
                                                        }
                                                        setInitItemsFromGeneralSetting(
                                                            {
                                                                informationModes:
                                                                    data.content
                                                                        .informationModes
                                                                        .items,
                                                                saleMethods:
                                                                    data.content
                                                                        .salesMethods
                                                                        .items,
                                                                reglements:
                                                                    data.content
                                                                        .meansOfPayment
                                                                        .items,
                                                                languages:
                                                                    data.content
                                                                        .languages
                                                                        .items,
                                                                logo:
                                                                    data.content
                                                                        .generalDesign
                                                                        .logo,
                                                            }
                                                        );

                                                        setItems(data.pages);
                                                        setDefaultWays(
                                                            data.pages
                                                        );
                                                        setProjectId(
                                                            element.selectedRow
                                                                .original
                                                                .projectId
                                                        );

                                                        setIsProjectModesModalOpened(
                                                            true
                                                        );
                                                    }
                                                } else if (
                                                    dataProject !== undefined &&
                                                    dataProject?.template !==
                                                        undefined
                                                ) {
                                                    let data: any =
                                                        dataProject?.template;
                                                    setSelectedRows({
                                                        ...element.selectedRow
                                                            .original,
                                                        template: {
                                                            ...data,
                                                        },
                                                    });
                                                    setProjectRowData({
                                                        ...element.selectedRow
                                                            .original,
                                                        template: {
                                                            ...data,
                                                        },
                                                    });

                                                    setIsConsult(false);
                                                    setIsKioskSetting(false);
                                                    setInitStoreVariables();
                                                    setInitDesignStoreVariables();
                                                    setIsConfigActive(true);
                                                    setProject({
                                                        ...element.selectedRow
                                                            .original,
                                                        template: {
                                                            ...data,
                                                        },
                                                    });
                                                    setIsProjectModesModalOpened(
                                                        true
                                                    );
                                                }
                                            }}
                                        >
                                            <SettingsIcon
                                                height={15}
                                                width={15}
                                            />
                                            <span className="ml-1">
                                                {t("Configure")}
                                            </span>
                                        </DropdownItem>
                                    )}

                                    <DropdownItem
                                        style={{ cursor: "pointer" }}
                                        className={classnames(
                                            "Cgt_icn_toPlan",
                                            {
                                                "not-allowed-icon__clz":
                                                    element.selectedRow.original
                                                        .Status === "Publié" ||
                                                    isArchivedTemplateClicked ||
                                                    (isExist && oneShop) ||
                                                    oneShop ||
                                                    !oneShop,
                                            }
                                        )}
                                        onClick={async () => {
                                            if (
                                                element.selectedRow.original
                                                    .Status !== "Publié" ||
                                                !isArchivedTemplateClicked
                                            ) {
                                                let dataProject: any = await getProjectData();
                                                let data: any =
                                                    dataProject.template;
                                                let files: any =
                                                    dataProject.files;
                                                setSelectedRows({
                                                    ...element.selectedRow
                                                        .original,
                                                    files: [...files],
                                                    template: {
                                                        ...data,
                                                    },
                                                });
                                                setIsModalOpenedPlaning(true);
                                            }
                                        }}
                                    >
                                        <SettingsIcon
                                            height={15}
                                            width={15}
                                            style={{ cursor: "pointer" }}
                                        />
                                        <span className="ml-1">
                                            {element.selectedRow.original
                                                .Status === "Planifié"
                                                ? `${t("Edit Schedule")}`
                                                : `${t("Palnify")}`}
                                        </span>
                                    </DropdownItem>
                                    <DropdownItem
                                        style={{ cursor: "pointer" }}
                                        className={classnames(
                                            "Cgt_icn_publish",
                                            {
                                                "not-allowed-icon__clz":
                                                    element.selectedRow.original
                                                        .Status === "Publié" ||
                                                    isArchivedTemplateClicked ||
                                                    (isExist && oneShop),
                                            }
                                        )}
                                        onClick={async () => {
                                            let dataProject: any = await getProjectData();
                                            let template: any =
                                                dataProject.template;
                                            let files: any = dataProject.files;
                                            setSelectedRows({
                                                ...element.selectedRow.original,
                                                files: [...files],
                                                template: { ...template },
                                            });
                                            if (
                                                isExistProjectPublish &&
                                                oneShop
                                            ) {
                                                if (template.id === "") {
                                                    toast.error(
                                                        `${t(
                                                            "Unable to publish an unconfigured Template"
                                                        )!}`,
                                                        {
                                                            position:
                                                                toast.POSITION
                                                                    .TOP_CENTER,
                                                            autoClose: 2000,
                                                            theme: "colored",
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: true,
                                                            icon: (
                                                                <ShieldExclamationIcon
                                                                    width={25}
                                                                    height={25}
                                                                    fill="white"
                                                                />
                                                            ),
                                                        }
                                                    );
                                                    setSelectedRows([]);
                                                } else if (
                                                    template.content.salesMethods.items.filter(
                                                        (item: any) =>
                                                            item.id !==
                                                            "f25e7c96-b5d3-4f2e-acd0-1500258283c2"
                                                    ).length === 0 ||
                                                    template.content.languages
                                                        .items.length === 0
                                                ) {
                                                    toast.warning(
                                                        `${t(
                                                            "Attention, publishing the Template is not possible. Please check your language and sales mode settings to resolve this issue."
                                                        )!}`,
                                                        {
                                                            position:
                                                                toast.POSITION
                                                                    .TOP_CENTER,
                                                            autoClose: 2000,
                                                            theme: "colored",
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: true,
                                                            icon: (
                                                                <CheckboxIcon
                                                                    height={25}
                                                                    width={25}
                                                                    fill="white"
                                                                />
                                                            ),
                                                        }
                                                    );
                                                } else {
                                                    setIsConfirmationPublishProject(
                                                        !isConfirmationPublishProject
                                                    );
                                                }
                                            } else if (
                                                element.selectedRow.original
                                                    .subRows.length === 0 &&
                                                !oneShop
                                            ) {
                                                toast.error(
                                                    `${t(
                                                        "Unable to publish a template not associated with one or more store(s)"
                                                    )!}`,
                                                    {
                                                        position:
                                                            toast.POSITION
                                                                .TOP_CENTER,
                                                        autoClose: 2000,
                                                        theme: "colored",
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        icon: (
                                                            <ShieldExclamationIcon
                                                                width={25}
                                                                height={25}
                                                                fill="white"
                                                            />
                                                        ),
                                                    }
                                                );
                                                setSelectedRows([]);
                                            } else if (template.id === "") {
                                                toast.error(
                                                    `${t(
                                                        "Unable to publish an unconfigured Template"
                                                    )!}`,
                                                    {
                                                        position:
                                                            toast.POSITION
                                                                .TOP_CENTER,
                                                        autoClose: 2000,
                                                        theme: "colored",
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        icon: (
                                                            <ShieldExclamationIcon
                                                                width={25}
                                                                height={25}
                                                                fill="white"
                                                            />
                                                        ),
                                                    }
                                                );
                                                setSelectedRows([]);
                                            } else if (
                                                template.content.salesMethods.items.filter(
                                                    (item: any) =>
                                                        item.active === true &&
                                                        item.id !==
                                                            "f25e7c96-b5d3-4f2e-acd0-1500258283c2"
                                                ).length === 0 ||
                                                template.content.languages.items.filter(
                                                    (item: any) =>
                                                        item.active === true
                                                ).length === 0
                                            ) {
                                                toast.warning(
                                                    `${t(
                                                        "Attention, publishing the Template is not possible. Please check your language and sales mode settings to resolve this issue."
                                                    )!}`,
                                                    {
                                                        position:
                                                            toast.POSITION
                                                                .TOP_CENTER,
                                                        autoClose: 2000,
                                                        theme: "colored",
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        icon: (
                                                            <CheckboxIcon
                                                                height={25}
                                                                width={25}
                                                                fill="white"
                                                            />
                                                        ),
                                                    }
                                                );
                                            } else {
                                                setIsModesModalPublishOpened(
                                                    !isModesModalPublishOpened
                                                );
                                            }
                                        }}
                                    >
                                        <DuplicateIcon height={15} width={15} />
                                        <span className="ml-1">
                                            {t("Publish")}
                                        </span>
                                    </DropdownItem>
                                    <DropdownItem
                                        style={{ cursor: "pointer" }}
                                        className={classnames(
                                            "Cgt_icn_duplicate",
                                            {
                                                "not-allowed-icon__clz":
                                                    isArchivedTemplateClicked ||
                                                    (isExist && oneShop),
                                            }
                                        )}
                                        onClick={() => {
                                            setIsCreatedCoping(
                                                !isCreatedCoping
                                            );
                                            setSelectedRows(
                                                element.selectedRow.original
                                            );
                                            setProjectId(
                                                element.selectedRow.original
                                                    .projectId
                                            );
                                        }}
                                    >
                                        <DuplicateIcon height={15} width={15} />
                                        <span className="ml-1">
                                            {t("Duplicate")}
                                        </span>
                                    </DropdownItem>
                                    {!oneShop ? (
                                        <DropdownItem
                                            style={{ cursor: "pointer" }}
                                            className={classnames(
                                                "Cgt_icn_associateStore",
                                                {
                                                    "not-allowed-icon__clz":
                                                        isArchivedTemplateClicked ||
                                                        oneShop ||
                                                        element.selectedRow
                                                            .original.Status ===
                                                            "Publié",
                                                }
                                            )}
                                            onClick={async () => {
                                                let dataProject: any = await getProjectData();
                                                let template: any =
                                                    dataProject.template;
                                                let files: any =
                                                    dataProject.files;
                                                setSelectedRows({
                                                    ...element.selectedRow
                                                        .original,
                                                    files: [...files],
                                                    template: { ...template },
                                                });
                                                setProject({
                                                    ...element.selectedRow
                                                        .original,
                                                    files: [...files],
                                                    template: { ...template },
                                                });
                                                setIsModalAssociateStore(
                                                    !isModalAssociateStore
                                                );
                                            }}
                                        >
                                            <DuplicateIcon
                                                height={15}
                                                width={15}
                                            />
                                            <span className="ml-1">
                                                {t("Associate to store(s)")}
                                            </span>
                                        </DropdownItem>
                                    ) : null}
                                    <DropdownItem
                                        className={classnames(
                                            "Cgt_icn_archive cmn_btn_FRunarchive",
                                            {
                                                "not-allowed-icon__clz":
                                                    element.selectedRow.original
                                                        .Status === "Publié",
                                            }
                                        )}
                                        onClick={async () => {
                                            let dataProject: any = await getProjectData();
                                            let data: any =
                                                dataProject.template;
                                            let files: any = dataProject.files;
                                            if (oneShop) {
                                                setSelectedRows([
                                                    {
                                                        ...element.selectedRow
                                                            .original,
                                                    },
                                                ]);
                                                setIsModalDissociateOpened(
                                                    !isModalDissociateOpened
                                                );
                                            } else if (
                                                element.selectedRow.original
                                                    .subRows.length > 0 &&
                                                !oneShop
                                            ) {
                                                return ErrorToast(
                                                    `${t(
                                                        "Please note that you cannot archive a Template associated with the store(s)."
                                                    )!}`
                                                );
                                            } else {
                                                setSelectedRows([
                                                    {
                                                        ...element.selectedRow
                                                            .original,
                                                        files: [...files],
                                                        template: {
                                                            ...data,
                                                        },
                                                        associates: {},
                                                    },
                                                ]);
                                                setIsModalArchiveOpened(
                                                    !isModalArchiveOpened
                                                );
                                            }
                                        }}
                                    >
                                        {!isArchivedTemplateClicked ? (
                                            <TrashIcon height={15} width={15} />
                                        ) : (
                                            <EyeIcon height={15} width={15} />
                                        )}

                                        <span
                                            className={classnames("ml-1", {
                                                "not-allowed-icon__clz":
                                                    element.selectedRow.original
                                                        .Status === "Publié",
                                            })}
                                        >
                                            {!isArchivedTemplateClicked
                                                ? oneShop
                                                    ? `${t("Delete")}`
                                                    : `${t("Archive")}`
                                                : `${t("Enable")}`}
                                        </span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        ) : null}
                        {element.selectedRow.original.id_boutique ? (
                            <Dropdown
                                isOpen={isDropdownOpened}
                                toggle={toggleDropdown}
                                className={`${classnames("", {
                                    "not-allowed-icon__clz":
                                        isLoading || isCreateProjectClicked,
                                })}`}
                            >
                                <DropdownToggle
                                    id="page-header-user-dropdown"
                                    tag="button"
                                    className={`${classnames(
                                        "pointer__clz btn header-item waves-effect",
                                        {
                                            "not-allowed-icon__clz":
                                                getProject()?.template?.id ===
                                                    "" ||
                                                isArchivedTemplateClicked,
                                        }
                                    )}`}
                                >
                                    <VerticalDotsIcon height={25} width={25} />
                                </DropdownToggle>
                                <DropdownMenu
                                    dropdown-menu
                                    aria-labelledby="dropdownMenuButton"
                                >
                                    <DropdownItem
                                        onClick={handleSettingIconOnClickEvent}
                                        className={`${classnames(
                                            "pointer__clz ml-1",
                                            {
                                                "not-allowed-icon__clz":
                                                    element.selectedRow.original
                                                        .isProjectConfigured ===
                                                    false,
                                            }
                                        )}`}
                                    >
                                        <SettingsIcon height={15} width={15} />
                                        <span className="ml-1">
                                            {t("Configure")}
                                        </span>
                                    </DropdownItem>
                                    <DropdownItem
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        className="cgt_icn_consult"
                                        onClick={async () => {
                                            let dataProject: any = await getProjectData();
                                            let template: any =
                                                dataProject.template;
                                            let files: any = dataProject.files;
                                            setIsModesModalOpened(true);
                                            setIsKioskSetting(false);
                                            setInitStoreVariables();
                                            setInitDesignStoreVariables();
                                            setIsConfigActive(true);
                                            setSelectedRows({
                                                ...element.selectedRow.original,
                                                files: [...files],
                                                template: { ...template },
                                            });
                                            setProject({
                                                ...element.selectedRow.original,
                                                files: [...files],
                                                template: { ...template },
                                            });
                                            if (template.id !== "") {
                                                if (
                                                    (template.content
                                                        .salesMethods as ProjectContentItemType).items.filter(
                                                        (item: any) =>
                                                            item.active ===
                                                                true &&
                                                            item.id !==
                                                                "f25e7c96-b5d3-4f2e-acd0-1500258283c2"
                                                    ).length === 0 ||
                                                    (template.content
                                                        .languages as LanguagesType).items.filter(
                                                        (item: any) =>
                                                            item.active === true
                                                    ).length === 0
                                                ) {
                                                    setIsModesModalOpened(
                                                        false
                                                    );
                                                    toast.warning(
                                                        `${t(
                                                            `Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings.`
                                                        )!}`,
                                                        {
                                                            position:
                                                                toast.POSITION
                                                                    .TOP_CENTER,
                                                            autoClose: 2000,
                                                            theme: "colored",
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: true,
                                                            icon: (
                                                                <CheckboxIcon
                                                                    height={25}
                                                                    width={25}
                                                                    fill="white"
                                                                />
                                                            ),
                                                        }
                                                    );
                                                } else {
                                                    setChosenTemplate(
                                                        template,
                                                        files
                                                    );
                                                    setDefaultLanguage(
                                                        (template.content
                                                            .languages as LanguagesType).items.filter(
                                                            (language) =>
                                                                language.isDefault ===
                                                                true
                                                        )[0]
                                                    );
                                                    setSelectedLanguage(
                                                        (template.content
                                                            .languages as LanguagesType).items.filter(
                                                            (language) =>
                                                                language.isDefault ===
                                                                true
                                                        )[0].name
                                                    );
                                                    const selectedSaleMethodDeliveryExist = (template
                                                        .content
                                                        .salesMethods as ProjectContentItemType).items.filter(
                                                        (sale: any) =>
                                                            sale.name ===
                                                            "Delivery"
                                                    )[0];
                                                    if (
                                                        selectedSaleMethodDeliveryExist !==
                                                            undefined &&
                                                        selectedSaleMethodDeliveryExist.active ===
                                                            true
                                                    ) {
                                                        setIsCustomerAccountActive(
                                                            true
                                                        );

                                                        setIsStateChanged(true);
                                                        setConfigOptionsCustomerAccount(
                                                            true
                                                        );
                                                    } else {
                                                        setIsCustomerAccountActive(
                                                            (template.content
                                                                .options as OptionsType).items.filter(
                                                                (item) =>
                                                                    item.title ===
                                                                    "Customer account"
                                                            )[0].active
                                                        );
                                                    }

                                                    if (
                                                        (template.content
                                                            .informationModes as ProjectContentItemType)
                                                            .items.length ===
                                                            0 ||
                                                        (template.content
                                                            .informationModes as ProjectContentItemType).items.filter(
                                                            (
                                                                item: ProjectMainContentItemType
                                                            ) => {
                                                                return (template
                                                                    .content
                                                                    .salesMethods as ProjectContentItemType).items.filter(
                                                                    (
                                                                        saleMethod: any
                                                                    ) => {
                                                                        return (
                                                                            Object.keys(
                                                                                item.active
                                                                            ).includes(
                                                                                saleMethod.name
                                                                            ) &&
                                                                            saleMethod.active ===
                                                                                true
                                                                        );
                                                                    }
                                                                );
                                                            }
                                                        ).length === 0
                                                    ) {
                                                        setIsInformationModesEmpty(
                                                            true
                                                        );
                                                    } else {
                                                        setIsInformationModesEmpty(
                                                            false
                                                        );
                                                        const salesMethodInformationMode = (template
                                                            .content
                                                            .salesMethods as ProjectContentItemType).items.filter(
                                                            (saleMethod) => {
                                                                return (
                                                                    saleMethod.name !==
                                                                        "Retrait C&C" &&
                                                                    (saleMethod.informationModes as InformationModesContentType)
                                                                        .items
                                                                        .length !==
                                                                        0 &&
                                                                    saleMethod.active ===
                                                                        true
                                                                );
                                                            }
                                                        )[0];
                                                        if (
                                                            salesMethodInformationMode !==
                                                            undefined
                                                        ) {
                                                            setSelectedSaleModeValue(
                                                                salesMethodInformationMode.name
                                                            );
                                                        }
                                                    }

                                                    setInitItemsFromGeneralSetting(
                                                        {
                                                            informationModes:
                                                                template.content
                                                                    .informationModes
                                                                    .items,
                                                            saleMethods:
                                                                template.content
                                                                    .salesMethods
                                                                    .items,
                                                            reglements:
                                                                template.content
                                                                    .meansOfPayment
                                                                    .items,
                                                            languages:
                                                                template.content
                                                                    .languages
                                                                    .items,
                                                            logo:
                                                                template.content
                                                                    .generalDesign
                                                                    .logo,
                                                        }
                                                    );
                                                    setItems(template.pages);
                                                    setDefaultWays(
                                                        template.pages
                                                    );
                                                }
                                            }
                                            setProjectId(
                                                element.selectedRow.original
                                                    .projectId
                                            );
                                            setIsConsult(true);
                                            setIsModalOpened(true);
                                        }}
                                    >
                                        <ConsultSvgIcon
                                            height={25}
                                            width={25}
                                        />
                                        <span>{t("To consult")}</span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        ) : null}
                    </React.Fragment>
                )}
            </React.Fragment>
        </React.Fragment>
    );
}
