import React from "react";
import { StyledH2, StyledH1 } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";

import { setView } from "../../store";

export default function View({ rowData }: any) {
    const { t } = useTranslation();
    const [originCommandView, setOriginCommandView] = React.useState<any[]>(
        rowData?.template === undefined ||
            Object.keys(rowData.template).length === 0
            ? [
                  { name: "Borne", state: false, id: 1 },
                  { name: "Application C&C", state: false, id: 2 },
                  { name: "Site web C&C", state: false, id: 3 },
                  { name: "Pos", state: false, id: 4 },
              ]
            : rowData.template.view.originCommandView
    );
    const [saleModeView, setSaleModeView] = React.useState<any[]>(
        rowData?.template === undefined ||
            Object.keys(rowData.template).length === 0
            ? [
                  { name: "Emporter", state: false, id: 1 },
                  { name: "Sur place", state: false, id: 2 },
                  { name: "Restaurant", state: false, id: 3 },
                  { name: "Livraison", state: false, id: 4 },
              ]
            : rowData.template.view.saleModeView
    );
    const [otherOptionsView, setOtherOptionsView] = React.useState<any[]>(
        rowData?.template === undefined ||
            Object.keys(rowData.template).length === 0
            ? [
                  { name: "Show sale mode icon", state: false, id: 1 },
                  { name: "Show customer name", state: false, id: 2 },
              ]
            : rowData.template.view.otherOptionsView
    );

    React.useEffect(() => {
        setView(
            JSON.parse(
                JSON.stringify({
                    originCommandView: originCommandView,
                    saleModeView: saleModeView,
                    otherOptionsView: otherOptionsView,
                })
            )
        );
        // eslint-disable-next-line
    }, [originCommandView, saleModeView, otherOptionsView]);

    return (
        <React.Fragment>
            <StyledH1 className="m-3">{t("View")}</StyledH1>
            <div className="border-footer__clz" />
            <StyledH2 className="ml-3 mt-2">
                {t("Display according to the origin of the order")}
            </StyledH2>
            {originCommandView.map((element: any, key: number) => {
                return (
                    <div
                        key={key}
                        className="d-flex  align-items-center justify-content-between mt-3"
                    >
                        <div className="ml-5">{t(element.name)}</div>
                        <div className="float-right mr-5">
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={element.state}
                                onChange={() => {
                                    let newView = [...originCommandView];
                                    newView[key].state = !newView[key].state;
                                    setOriginCommandView(newView);
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={55}
                                height={20}
                            />
                        </div>
                    </div>
                );
            })}
            <div className="border-footer__clz" />
            <StyledH2 className="ml-3 mt-2">
                {t("Display according to the mode of sale")}
            </StyledH2>
            {saleModeView.map((element: any, key: number) => {
                return (
                    <div
                        key={key}
                        className="d-flex  align-items-center justify-content-between mt-3"
                    >
                        <div className="ml-5">{t(element.name)}</div>
                        <div className="float-right mr-5">
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={element.state}
                                onChange={() => {
                                    let newView = [...saleModeView];
                                    newView[key].state = !element.state;
                                    setSaleModeView(newView);
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={55}
                                height={20}
                            />
                        </div>
                    </div>
                );
            })}
            <div className="border-footer__clz" />
            <StyledH2 className="ml-3 mt-2">{t("Other Options")}</StyledH2>
            {otherOptionsView.map((element: any, key: number) => {
                return (
                    <div
                        key={key}
                        className="d-flex  align-items-center justify-content-between mt-3"
                    >
                        <div className="ml-5">{t(element.name)}</div>
                        <div className="float-right mr-5">
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={element.state}
                                onChange={() => {
                                    let newView = [...otherOptionsView];
                                    newView[key].state = !element.state;
                                    setOtherOptionsView(newView);
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={55}
                                height={20}
                            />
                        </div>
                    </div>
                );
            })}
        </React.Fragment>
    );
}
