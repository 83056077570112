import React from "react";
import { EyeIcon, PencilIcon, TrashIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

import store, {
    setIsLoading,
    setIsActionsClicked,
    setIsEdited,
    setActions,
} from "./store";
import { store as userStore } from "@components/VerticalLayout/store";

import { verifyIfPeripheralAssociate } from "../../../helpers/peripheralsHelpers";
import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import ConsultSvgIcon from "@components/Common/SvgIcons/ConsultSvgIcon";

export function CustomIcons({
    e,
    setShopId,
    setRowData,
    setMonetics,
    setIsModalOpened,
    isModalOpened,
    setMoneticId,
    isModalDeleteAssociatePeripheralOpened,
    setIsModalDeleteAssociatePeripheralOpened,
    setObjectApiUrlDissociate,
    setInputIp,
    setIsConfirmationEditRunning,
    typeDisplay,
    setSelectedRows,
    setIsHistoryModalOpened,
    setIsConsult,
    setIsAddMoneticsClicked,
    isAddMoneticsClicked,
}: any) {
    const {
        isUpdateOperation,
        isActionsClicked,
        isEdited,
        modify,
        isLoading,
    } = useSnapshot(store);
    const { userID, franchiseID } = useSnapshot(userStore);
    const { t } = useTranslation();
    const appId = "KIOSK";
    let ifPeripheralAssociateObject: {
        isPeripheralAssociate: boolean;
        appId_children: any[];
    };
    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined &&
                (modify === false || typeDisplay === "archived") && (
                    <div className="d-flex justify-content-around">
                        <Tooltip title={t("To consult").toString()}>
                            <div>
                                <Tooltip title={t("To consult").toString()}>
                                    <div>
                                        <ConsultSvgIcon
                                            className="cmn_icn_toConsult"
                                            height={35}
                                            width={35}
                                            onClick={() => {
                                                setIsConsult(true);
                                                setRowData(e.selectedRow);
                                                setShopId(e.selectedRow.shopId);
                                                setInputIp(false);
                                                setMonetics(e.selectedRow);
                                                if (!isAddMoneticsClicked) {
                                                    setIsAddMoneticsClicked(
                                                        !isAddMoneticsClicked
                                                    );
                                                }
                                            }}
                                            style={{
                                                cursor: "pointer",
                                                position: "relative",
                                                bottom: "0px",
                                            }}
                                        />
                                    </div>
                                </Tooltip>
                            </div>
                        </Tooltip>
                    </div>
                )}
            {e.selectedRow?.subRows === undefined && modify === true ? (
                <div className="d-flex justify-content-around">
                    {typeDisplay === "notArchived" ? (
                        <Tooltip title={t("Edit").toString()}>
                            <div>
                                <PencilIcon
                                    onClick={() => {
                                        if (isEdited === true) {
                                            setIsActionsClicked(
                                                !isActionsClicked
                                            );
                                            setActions(() => {
                                                setIsEdited(false);
                                                setRowData(e.selectedRow);
                                                setShopId(e.selectedRow.shopId);
                                                setInputIp(false);
                                                setMonetics(e.selectedRow);
                                                if (!isAddMoneticsClicked) {
                                                    setIsAddMoneticsClicked(
                                                        !isAddMoneticsClicked
                                                    );
                                                }
                                            });
                                        } else {
                                            setRowData(e.selectedRow);
                                            setShopId(e.selectedRow.shopId);
                                            setInputIp(false);
                                            setMonetics(e.selectedRow);
                                            if (!isAddMoneticsClicked) {
                                                setIsAddMoneticsClicked(
                                                    !isAddMoneticsClicked
                                                );
                                            }
                                        }
                                    }}
                                    className={` ${classnames("cmn_icn_edit", {
                                        "not-allowed-icon__clz":
                                            typeDisplay === "archived",
                                    })}`}
                                    height={25}
                                    width={25}
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                        </Tooltip>
                    ) : null}

                    {typeDisplay === "notArchived" ? (
                        <Tooltip title={t("Archive").toString()}>
                            <div>
                                <TrashIcon
                                    className={`${classnames(
                                        "cmn_icn_FRarchived ml-2",
                                        {
                                            "not-allowed-icon__clz": isLoading,
                                        }
                                    )}`}
                                    onClick={async () => {
                                        setIsLoading(true);
                                        if (isEdited === true) {
                                            setIsLoading(false);
                                            setIsActionsClicked(
                                                !isActionsClicked
                                            );
                                            setActions(async () => {
                                                setIsEdited(false);
                                                setIsAddMoneticsClicked(false);
                                                if (
                                                    isUpdateOperation ===
                                                        true &&
                                                    isAddMoneticsClicked ===
                                                        true
                                                ) {
                                                    setIsConfirmationEditRunning(
                                                        true
                                                    );
                                                } else {
                                                    setShopId(
                                                        e.selectedRow.shopId
                                                    );
                                                    ifPeripheralAssociateObject = await verifyIfPeripheralAssociate(
                                                        e.selectedRow.Id,
                                                        e.selectedRow.shopId,
                                                        franchiseID,
                                                        userID,
                                                        appId
                                                    );
                                                    setObjectApiUrlDissociate({
                                                        userId: userID,
                                                        shopId:
                                                            e.selectedRow
                                                                .shopId,
                                                        franchiseId: franchiseID,
                                                        appId: appId,
                                                        appId_children:
                                                            ifPeripheralAssociateObject.appId_children,
                                                        peripheralId:
                                                            e.selectedRow.Id,
                                                    });

                                                    if (
                                                        isUpdateOperation ===
                                                            true &&
                                                        isAddMoneticsClicked ===
                                                            true
                                                    ) {
                                                        setIsConfirmationEditRunning(
                                                            true
                                                        );
                                                    } else {
                                                        setShopId(
                                                            e.selectedRow.shopId
                                                        );
                                                        ifPeripheralAssociateObject = await verifyIfPeripheralAssociate(
                                                            e.selectedRow.Id,
                                                            e.selectedRow
                                                                .shopId,
                                                            franchiseID,
                                                            userID,
                                                            appId
                                                        );
                                                        setObjectApiUrlDissociate(
                                                            {
                                                                userId: userID,
                                                                shopId:
                                                                    e
                                                                        .selectedRow
                                                                        .shopId,
                                                                franchiseId: franchiseID,
                                                                appId: appId,
                                                                appId_children:
                                                                    ifPeripheralAssociateObject.appId_children,
                                                                peripheralId:
                                                                    e
                                                                        .selectedRow
                                                                        .Id,
                                                            }
                                                        );

                                                        if (
                                                            ifPeripheralAssociateObject.isPeripheralAssociate
                                                        ) {
                                                            setIsModalDeleteAssociatePeripheralOpened(
                                                                !isModalDeleteAssociatePeripheralOpened
                                                            );
                                                        } else {
                                                            setIsModalOpened(
                                                                !isModalOpened
                                                            );
                                                        }
                                                        setMoneticId(
                                                            e.selectedRow.Id
                                                        );
                                                    }
                                                }
                                            });
                                        } else {
                                            if (
                                                isUpdateOperation === true &&
                                                isAddMoneticsClicked === true
                                            ) {
                                                setIsConfirmationEditRunning(
                                                    true
                                                );
                                                setIsLoading(false);
                                            } else {
                                                setShopId(e.selectedRow.shopId);
                                                ifPeripheralAssociateObject = await verifyIfPeripheralAssociate(
                                                    e.selectedRow.Id,
                                                    e.selectedRow.shopId,
                                                    franchiseID,
                                                    userID,
                                                    appId
                                                );
                                                setObjectApiUrlDissociate({
                                                    userId: userID,
                                                    shopId:
                                                        e.selectedRow.shopId,
                                                    franchiseId: franchiseID,
                                                    appId: appId,
                                                    appId_children:
                                                        ifPeripheralAssociateObject.appId_children,
                                                    peripheralId:
                                                        e.selectedRow.Id,
                                                });

                                                if (
                                                    isUpdateOperation ===
                                                        true &&
                                                    isAddMoneticsClicked ===
                                                        true
                                                ) {
                                                    setIsConfirmationEditRunning(
                                                        true
                                                    );
                                                    setIsLoading(false);
                                                } else {
                                                    setShopId(
                                                        e.selectedRow.shopId
                                                    );
                                                    ifPeripheralAssociateObject = await verifyIfPeripheralAssociate(
                                                        e.selectedRow.Id,
                                                        e.selectedRow.shopId,
                                                        franchiseID,
                                                        userID,
                                                        appId
                                                    );
                                                    setObjectApiUrlDissociate({
                                                        userId: userID,
                                                        shopId:
                                                            e.selectedRow
                                                                .shopId,
                                                        franchiseId: franchiseID,
                                                        appId: appId,
                                                        appId_children:
                                                            ifPeripheralAssociateObject.appId_children,
                                                        peripheralId:
                                                            e.selectedRow.Id,
                                                    });

                                                    if (
                                                        ifPeripheralAssociateObject.isPeripheralAssociate
                                                    ) {
                                                        setIsModalDeleteAssociatePeripheralOpened(
                                                            !isModalDeleteAssociatePeripheralOpened
                                                        );
                                                    } else {
                                                        setIsLoading(false);
                                                        setIsModalOpened(
                                                            !isModalOpened
                                                        );
                                                    }
                                                    setMoneticId(
                                                        e.selectedRow.Id
                                                    );
                                                }
                                            }
                                        }
                                    }}
                                    style={{ cursor: "pointer" }}
                                    height={25}
                                    width={25}
                                    fill="red"
                                />
                            </div>
                        </Tooltip>
                    ) : (
                        <Tooltip title={t("Unarchive").toString()}>
                            <div>
                                <EyeIcon
                                    className={`${classnames(
                                        "cmn_icn_unarchive ml-2",
                                        {
                                            "not-allowed-icon__clz": isLoading,
                                        }
                                    )}`}
                                    onClick={async () => {
                                        setIsLoading(true);
                                        if (
                                            isUpdateOperation === true &&
                                            isAddMoneticsClicked === true
                                        ) {
                                            setIsLoading(false);
                                            setIsConfirmationEditRunning(true);
                                        } else {
                                            setShopId(e.selectedRow.shopId);
                                            ifPeripheralAssociateObject = await verifyIfPeripheralAssociate(
                                                e.selectedRow.Id,
                                                e.selectedRow.shopId,
                                                franchiseID,
                                                userID,
                                                appId
                                            );
                                            setObjectApiUrlDissociate({
                                                userId: userID,
                                                shopId: e.selectedRow.shopId,
                                                franchiseId: franchiseID,
                                                appId: appId,
                                                appId_children:
                                                    ifPeripheralAssociateObject.appId_children,
                                                peripheralId: e.selectedRow.Id,
                                            });

                                            if (
                                                isUpdateOperation === true &&
                                                isAddMoneticsClicked === true
                                            ) {
                                                setIsConfirmationEditRunning(
                                                    true
                                                );
                                                setIsLoading(false);
                                            } else {
                                                setShopId(e.selectedRow.shopId);
                                                ifPeripheralAssociateObject = await verifyIfPeripheralAssociate(
                                                    e.selectedRow.Id,
                                                    e.selectedRow.shopId,
                                                    franchiseID,
                                                    userID,
                                                    appId
                                                );
                                                setObjectApiUrlDissociate({
                                                    userId: userID,
                                                    shopId:
                                                        e.selectedRow.shopId,
                                                    franchiseId: franchiseID,
                                                    appId: appId,
                                                    appId_children:
                                                        ifPeripheralAssociateObject.appId_children,
                                                    peripheralId:
                                                        e.selectedRow.Id,
                                                });

                                                if (
                                                    ifPeripheralAssociateObject.isPeripheralAssociate
                                                ) {
                                                    setIsModalDeleteAssociatePeripheralOpened(
                                                        !isModalDeleteAssociatePeripheralOpened
                                                    );
                                                    setIsLoading(false);
                                                } else {
                                                    setIsModalOpened(
                                                        !isModalOpened
                                                    );
                                                    setIsLoading(false);
                                                }
                                                setMoneticId(e.selectedRow.Id);
                                            }
                                        }
                                    }}
                                    style={{ cursor: "pointer" }}
                                    height={25}
                                    width={25}
                                    fill="black"
                                />
                            </div>
                        </Tooltip>
                    )}
                    <Tooltip title={t("Consult history").toString()}>
                        <div>
                            <TimePastSvgIcon
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(async () => {
                                            setIsEdited(false);
                                            setIsAddMoneticsClicked(false);
                                            setIsHistoryModalOpened(true);
                                            setSelectedRows([e.selectedRow]);
                                        });
                                    } else {
                                        setIsHistoryModalOpened(true);
                                        setSelectedRows([e.selectedRow]);
                                    }
                                }}
                                className="ml-2 cmn_icn_history"
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : e.selectedRow?.subRows === undefined && modify === false ? (
                <div className="d-flex justify-content-around">
                    <Tooltip title={t("Consult history").toString()}>
                        <div>
                            <TimePastSvgIcon
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(async () => {
                                            setIsEdited(false);
                                            setIsAddMoneticsClicked(false);
                                            setIsHistoryModalOpened(true);
                                            setSelectedRows([e.selectedRow]);
                                        });
                                    } else {
                                        setIsHistoryModalOpened(true);
                                        setSelectedRows([e.selectedRow]);
                                    }
                                }}
                                className="ml-2 cmn_icn_history"
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </React.Fragment>
    );
}
