import React from "react";
import { t } from "i18next";

export function OtherPaymentMethods(props: any): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            viewBox="0 0 628 87"
        >
            <g
                id="Groupe_13"
                data-name="Groupe 13"
                transform="translate(-484 4082)"
            >
                <rect
                    id="Rectangle_9"
                    data-name="Rectangle 9"
                    width="628"
                    height="87"
                    rx="9"
                    transform="translate(484 -4082)"
                    fill="#323333"
                />
                <text
                    id="Other means of payment"
                    data-name="Other means of payment"
                    transform="translate(799 -4024)"
                    fill="#fff"
                    font-size="38"
                    font-family="HelveticaNeue-Medium, Helvetica Neue"
                    font-weight="500"
                >
                    <tspan x="-245.328" y="0">
                        {t("Other means of payment", {
                            lng: props.selectedLanguage,
                        })}
                    </tspan>
                </text>
            </g>
        </svg>
    );
}
