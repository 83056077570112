import React from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";

import {
    setPrinterInformationMode,
    setPrinterBold,
    StoreGeneral,
} from "./Hook/store";

import "../index.css";

type TicketHeaderType = {
    rowData: any;
};

export default function InformationMode({ rowData }: TicketHeaderType) {
    const { t } = useTranslation();
    const { printerBold, printerInformationMode } = useSnapshot(StoreGeneral);

    React.useEffect(() => {
        if (!printerInformationMode) setPrinterBold(false);
    }, [printerInformationMode]);

    return (
        <React.Fragment>
            <div className="d-flex  align-items-center justify-content-between px-1 pt-2 pb-1">
                <b>{t("Information mode")}</b>
            </div>
            <div className="d-flex  align-items-center justify-content-between p-1">
                <div>{t("Print receipt information mode")}</div>
                <div className=" float-right mr-4">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={printerInformationMode}
                        onChange={(e: any) => {
                            setPrinterInformationMode(!printerInformationMode);
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                    />
                </div>
            </div>
            <div className="d-flex  align-items-center justify-content-between p-1">
                <div>{t("Print information mode in bold")}</div>
                <div
                    className="float-right mr-4"
                    style={{ opacity: !printerInformationMode ? "0.8" : "1" }}
                >
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={printerInformationMode ? printerBold : false}
                        onChange={(e: any) => {
                            setPrinterBold(!printerBold);
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}
