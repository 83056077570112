import React from "react";

import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import { getPathAuthorization } from "@components/VerticalLayout";
import { useLocation } from "react-router-dom";

import { store } from "@components/VerticalLayout/store";
import { setModify } from "./store";

import PageTitle from "@components/Common/PageTitle";
import MessageInfo from "@components/Common/MessageInfo";
import { MainColorTabsWrapper } from "@components/Common/MainColorTabsWrapper";
import AggregatorFranchise from "./AggregatorFranchise";
import ShopsAggregator from "./ShopsAggregator";
import { StyledTabs } from "./StyledTabs";

import "./index.css";

function LocalAggregator() {
    const location = useLocation();
    setModify(getPathAuthorization(location.pathname).Modifie);
    const { t } = useTranslation();
    const [isAggregatorArchived, setIsAggregatorArchived] = React.useState<
        boolean
    >(false);
    const [limit, setLimit] = React.useState<number>(1);
    const { oneShop } = useSnapshot(store);

    const tabs1 = (
        <AggregatorFranchise
            isAggregatorArchived={isAggregatorArchived}
            setIsAggregatorArchived={setIsAggregatorArchived}
        />
    );

    const tabs2 = (
        <StyledTabs
            tabs={[
                {
                    content: (
                        <AggregatorFranchise
                            isAggregatorArchived={isAggregatorArchived}
                            setIsAggregatorArchived={setIsAggregatorArchived}
                        />
                    ),
                    disabled: false,
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark cmn_btn_franchise"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Aggregator")}
                        </div>
                    ),
                },
                {
                    content: (
                        <ShopsAggregator
                            isAggregatorArchived={isAggregatorArchived}
                            setIsAggregatorArchived={setIsAggregatorArchived}
                            setLimit={setLimit}
                        />
                    ),
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark border-0 cmn_btn_boutique"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Shop Aggregator")}
                        </div>
                    ),
                },
            ]}
        />
    );

    return (
        <React.Fragment>
            <ToastContainer limit={limit} />
            <PageTitle title={t("General settings")} />
            <div className="page-content__clz">
                <div className="fed_txt_loyalty">
                    <StyledBreadcrumb
                        items={[
                            {
                                item: "Settings".toUpperCase(),
                                link: `${process.env.REACT_APP_PORTAIL_URL}`,
                            },
                            {
                                item:
                                    localStorage
                                        .getItem("selectedAccount")
                                        ?.toUpperCase() || "",
                                link: oneShop
                                    ? "/setting/customer-account"
                                    : "/compte",
                            },
                            {
                                item: t("General settings").toUpperCase(),
                                link: "#",
                            },
                            {
                                item: !isAggregatorArchived
                                    ? t("Aggregator").toUpperCase()
                                    : t("Aggregator archived").toUpperCase(),
                                link: "#",
                            },
                        ]}
                    />
                </div>

                <MessageInfo
                    message={t(
                        "To enable the store to use the aggregator module, you need to assign it to the store and set it to the activated state."
                    )}
                />
                <MainColorTabsWrapper>
                    {oneShop ? tabs1 : tabs2}
                </MainColorTabsWrapper>
            </div>
        </React.Fragment>
    );
}

export const Aggregator = React.memo(LocalAggregator);
