import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import { setSelectedSaleModeValue, generalConfigStore } from "@store";

export function SalesMethodsOptionsWrapper(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: {
            template: {
                content: { salesMethods },
            },
        },
        selectedSaleModeValue,
        isInformationModesEmpty,
    } = useSnapshot(generalConfigStore);
  

    const [salesMethodsOptions, setSalesMethodsOptions] = React.useState<
        LocalOptionsType[]
    >([]);

    const [
        selectedSalesMethodsOption,
        setSelectedSalesMethodsOption,
    ] = React.useState<LocalOptionsType>(salesMethodsOptions[0]);

    function handleSelectOnChangeEvent(selectedValue: LocalOptionsType) {
        setSelectedSaleModeValue(selectedValue.value as string);
        setSelectedSalesMethodsOption(selectedValue);
    }

    React.useEffect(() => {
        if (isInformationModesEmpty === false) {
       
            const options = (salesMethods as ProjectContentItemType).items
                .filter((saleMethod) => {
                    return (
                        saleMethod.name !== "Retrait C&C" &&
                        (saleMethod.informationModes as InformationModesContentType)
                            .items.length !== 0 &&
                        saleMethod.active === true
                    );
                })
                .map((option) => ({
                    label: t(option.name) as string,
                    value: option.name,
                }));

            setSalesMethodsOptions(options);
          
          }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salesMethods, t, isInformationModesEmpty,]);

    
    React.useEffect(() => {
        const initSaleMethodOption = salesMethodsOptions.filter((option) => {
            return option.value === selectedSaleModeValue;
        })[0];

        setSelectedSalesMethodsOption(initSaleMethodOption);
    },[salesMethodsOptions, selectedSaleModeValue]);

    return (
        <div
            className="d-flex"
            style={{
                gap: "20px",
                font: "normal normal 600 16px/21px Segoe UI",
                borderBottom: "1px solid #CECECE",
            }}
        >
            {salesMethodsOptions.map(
                (item: LocalOptionsType, index: number) => {
                    return (
                        <div
                            key={index}
                            style={{
                                borderBottom:
                                    item === selectedSalesMethodsOption
                                        ? "2px solid #2B2828"
                                        : "unset",
                                cursor: "pointer",
                            }}
                            onClick={() => handleSelectOnChangeEvent(item)}
                        >
                            {item.label}
                        </div>
                    );
                }
            )}
        </div>
    );
}
