import styled from "styled-components";

type ContainerWrapperPropsType = {
    isTopBannerActive: boolean;
    isHeaderActive: boolean;
    isShopCartDetailsActive: boolean;
};

export const ContainerWrapper = styled.div<ContainerWrapperPropsType>`
    display: grid;
    background-color: transparent;
    position: relative;
    grid-template-rows: ${({
        isTopBannerActive,
        isHeaderActive,
        isShopCartDetailsActive,
    }: ContainerWrapperPropsType) =>
        isTopBannerActive && isHeaderActive && isShopCartDetailsActive
            ? "80px 40px 440px 110px 50px"
            : !isHeaderActive && !isTopBannerActive && isShopCartDetailsActive
            ? "593px 96px 30px"
            : isHeaderActive && !isTopBannerActive && isShopCartDetailsActive
            ? "50px 536px 105px 30px"
            : !isHeaderActive && isTopBannerActive && isShopCartDetailsActive
            ? "80px 503px 100px 30px"
            : isTopBannerActive && isHeaderActive && !isShopCartDetailsActive
            ? "71px 40px 522px 54px 40px"
            : !isHeaderActive && !isTopBannerActive && !isShopCartDetailsActive
            ? "643px 45px 30px"
            : isHeaderActive && !isTopBannerActive && !isShopCartDetailsActive
            ? "50px 582px 36px 60px"
            : !isHeaderActive && isTopBannerActive && !isShopCartDetailsActive
            ? "80px 555px 36px 60px"
            : ""};
    height: "725px";
`;
