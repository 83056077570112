import React from "react";
import { StyledH2 } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import classnames from "classnames";
import { useSnapshot } from "valtio";

import { capitalize } from "../../../helpers/general";
import { resetStore } from "../../../pages/Applications/DeviceAssociateModal/store";

import { store } from "../../../components/VerticalLayout/store";
import { store as storeData } from "./store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

export default function HistoryModal({ isModalOpened, setIsModalOpened }: any) {
    const { t } = useTranslation();

    const { selectedDevice } = useSnapshot(storeData);
    const { franchiseID, userID } = useSnapshot(store);

    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [, setArrayIds] = React.useState<any[]>([]);
    const [data, setData] = React.useState<any>({});
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };

    const url = `${
        process.env.REACT_APP_API_V2_URL
    }/settings/general/devise/history?userId=${userID}&franchiseId=${franchiseID}&devise=${selectedDevice
        .map((el: any) => JSON.stringify(el))
        .join()}`;

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 1,
            columnName: t("Actions"),
            customJsx: (e: any) => {
                let actionsArray: any = [];
                e.selectedRow.actions.forEach((el: any) => {
                    if (el.item !== "isActive") {
                        if (el.item === "Nom_d_Affichage") {
                            actionsArray.push(t("Display name"));
                        } else if (el.item === "designation_décimale") {
                            actionsArray.push(t("Decimal designation"));
                        } else if (el.item === "Nbr_décimale") {
                            actionsArray.push(t("Decimal number"));
                        } else if (el.item === "pays") {
                            actionsArray.push(t("Country"));
                        } else if (el.item === "tag") {
                            actionsArray.push(t("Tag"));
                        } else if (el.item === "devise") {
                            actionsArray.push(t("Currency"));
                        }
                    }
                });
                if (e.selectedRow.operation === "modify") {
                    if (e.selectedRow?.actions[0].item === "isActive") {
                        return e.selectedRow?.actions[0].newValue === true ? (
                            <span>{t("Activation")}</span>
                        ) : (
                            <span>{t("Deactivation")}</span>
                        );
                    } else if (
                        e.selectedRow?.actions[0].item === "isArchived"
                    ) {
                        return e.selectedRow?.actions[0].newValue === true ? (
                            <span>{t("Archiving")}</span>
                        ) : (
                            <span>{t("Unarchiving")}</span>
                        );
                    }
                    return (
                        <span>
                            {t("Modification")}:{actionsArray.join(",")}
                        </span>
                    );
                } else if (e.selectedRow.operation === "creation") {
                    return <span>{t("Creation")}</span>;
                } else if (e.selectedRow.operation.includes("reassignment")) {
                    return (
                        <span>
                            {t("Reassignment")}
                            {e.selectedRow.operation.substring(12)}
                        </span>
                    );
                } else {
                    return <span>{t("Affectation")}</span>;
                }
            },
        },
        {
            indexOFColumn: 2,
            columnName: t("Before modification"),
            customJsx: (e: any) => {
                if (e.selectedRow.operation === "modify") {
                    return (
                        <span>
                            {e.selectedRow?.actions[0]?.item ===
                            "isArchived" ? (
                                e.selectedRow?.actions[0]?.oldValue === true ? (
                                    <span>
                                        {t("Status")}: {t("Archived")}
                                    </span>
                                ) : (
                                    <span>
                                        {t("Status")}: {t("Unarchived")}
                                    </span>
                                )
                            ) : e.selectedRow.actions[0]?.item ===
                              "isActive" ? (
                                e.selectedRow.actions[0]?.oldValue === true ? (
                                    <span>
                                        {t("State")}: {t("Activated")}
                                    </span>
                                ) : (
                                    <span>
                                        {t("State")}: {t("Deactivated")}
                                    </span>
                                )
                            ) : (
                                <span>
                                    {e.selectedRow.actions
                                        .map((el: any) => el.oldValue)
                                        .join(",")
                                        .slice(-1) === ","
                                        ? e.selectedRow.actions
                                              .map((el: any) => el.oldValue)
                                              .join(",")
                                              .slice(0, -1)
                                        : e.selectedRow.actions
                                              .map((el: any) => el.oldValue)
                                              .join(",")}
                                </span>
                            )}
                        </span>
                    );
                } else if (e.selectedRow.operation === "creation") {
                    return null;
                } else if (e.selectedRow.operation === "Affectation") {
                    return <span>{""}</span>;
                } else {
                    return <span>{""}</span>;
                }
            },
        },
        {
            indexOFColumn: 3,
            columnName: t("After modification"),
            customJsx: (e: any) => {
                if (e.selectedRow.operation === "modify") {
                    return (
                        <span>
                            {e.selectedRow.actions[0].item === "isArchived" ? (
                                e.selectedRow.actions[0].newValue === true ? (
                                    <span>
                                        {t("Status")}: {t("Archived")}
                                    </span>
                                ) : (
                                    <span>
                                        {t("Status")}: {t("Unarchived")}
                                    </span>
                                )
                            ) : e.selectedRow.actions[0].item === "isActive" ? (
                                e.selectedRow.actions[0].newValue === true ? (
                                    <span>
                                        {t("State")}: {t("Activated")}
                                    </span>
                                ) : (
                                    <span>
                                        {t("State")}: {t("Deactivated")}
                                    </span>
                                )
                            ) : (
                                <span>
                                    {e.selectedRow.actions
                                        .map((el: any) => el.newValue)
                                        .slice(-1) === ","
                                        ? e.selectedRow.actions
                                              .map((el: any) => el.newValue)
                                              .join(",")
                                              .slice(0, -1)
                                        : e.selectedRow.actions
                                              .map((el: any) => el.newValue)
                                              .join(",")}
                                </span>
                            )}
                        </span>
                    );
                } else if (e.selectedRow.operation === "creation") {
                    return e.selectedRow[t("Currency")] !== undefined
                        ? e.selectedRow[t("Currency")]
                        : "--";
                } else if (e.selectedRow.operation === "Affectation") {
                    return <span>{""}</span>;
                } else {
                    return <span>{""}</span>;
                }
            },
        },
    ];
    React.useEffect(() => {
        setArrayIds(selectedDevice.map((el) => el.Id));
    }, [selectedDevice]);

    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                className="modal-wrapper__clz"
                isOpen={isModalOpened}
            >
                <ModalHeader
                    toggle={() => {
                        setIsModalOpened!(!isModalOpened);
                        setDataIsUpdated!(true);
                    }}
                    className="dev_icn_HSclose"
                >
                    <StyledH2 className="dev_txt_HStitleHistory">
                        {capitalize(t("Historical"))}
                    </StyledH2>
                </ModalHeader>

                <ModalBody>
                    <div
                        className={`${classnames("title__clz", {
                            "not-allowed-icon__clz": selectedDevice.length > 1,
                        })}`}
                    >
                        {t("Currency")} :
                        {selectedDevice.length > 1
                            ? null
                            : data?.data !== undefined
                            ? data?.data![0][t("Currency")]
                            : ""}
                    </div>

                    <DynamicTable
                        url={url}
                        canResize
                        actionColumn={ActionColumn}
                        showGlobalFilter
                        showFilter
                        setLocalFilterActive={setLocalFilterActive}
                        filterActive={filterActive}
                        setDataIsUpdated={setDataIsUpdated}
                        dataIsUpdated={dataIsUpdated}
                        elevationTable={1}
                        requestHeader={{ "Accept-Language": i18nextLng }}
                        minHeight="500px"
                        maxHeight="500px"
                        name="historyModeOfSale"
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        setData={setData}
                    />
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <CustomMainColorButton
                        variant="primary"
                        className="fed_btn_HSclose"
                        onClick={() => {
                            setIsModalOpened(!isModalOpened);
                            resetStore();
                            setDataIsUpdated!(true);
                        }}
                        rounded
                    >
                        {t("Close")}
                    </CustomMainColorButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
