import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";
import classnames from "classnames";

import { store } from "../../../../components/VerticalLayout/store";
import {
    store as PosEditor,
    setNumberArchived,
    setNumberActive,
    setLocalShop,
    setIdsLocalShop,
    setPosEditorData,
    setArchivedPosEditorFranchise,
    setPosEditorFranchise,
    setDataArchive,
    setDataFranchiseArchive,
    setSelectedPosEditor,
    setIsActionsClicked,
    setActions,
    setValidationAction,
    setIsEdited,
    setLanguageData,
} from "../store";

import { useCallbackPrompt } from "@hooks/useCallbackPrompt";
import { ConfirmationUpdatingDataMessageModal } from "@components/Common/ConfirmationUpdatingDataMessageModal";
import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import ErrorToast from "@components/Common/ErrorTost";

import { CustomIcons } from "./CustomIcons";
import { ActionColumn } from "./ActionColumn";
import { ConfirmationActivePosEditor } from "../ConfirmationActivePosEditor";
import PosEditorForm from "./PosEditorForm";
import HistoryModal from "../HistoryModal";

import "./index.css";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import RefreshPosIcon from "@components/Common/SvgIcons/RefreshPosIcon";
import { redisPosEditor } from "../ShopsPosEditor/CustomIcons";

export default function PosEditorFranchise({
    isPosEditorArchived,
    setIsPosEditorArchived,
}: any) {
    const { t } = useTranslation();
    const didMountRef = React.useRef(false);
    const { oneShop, userID, franchiseID, shopID } = useSnapshot(store);
    const {
        posEditorData,
        isActionsClicked,
        isEdited,
        actions,
        validationAction,
        modify,
        languageData,
    } = useSnapshot(PosEditor);

    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isEdited
    );
    const [
        isAddNewPosEditorButtonClicked,
        setIsAddNewPosEditorButtonClicked,
    ] = useState(false);

    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isHistoryModalOpened, setIsHistoryModalOpened] = React.useState<
        boolean
    >(false);
    const [posEditor, setPosEditor] = useState<any>([]);
    const [shopId, setShopId] = React.useState<string | number>(
        oneShop ? shopID : "0"
    );
    const [isConsult, setIsConsult] = React.useState<boolean>(false);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [dataTable, setDataTable] = React.useState<any>({});
    const [isAddPosEditorClicked, setIsAddPosEditorClicked] = React.useState<
        boolean
    >(false);

    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    const urlArchive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/posEditor?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`;

    const urlActive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/posEditor?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=activated`;

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 7,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setShopId={setShopId}
                    setPosEditor={setPosEditor}
                    setIsModalOpened={setIsModalOpened}
                    isModalOpened={isModalOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    setIsHistoryModalOpened={setIsHistoryModalOpened}
                    isHistoryModalOpened={isHistoryModalOpened}
                    setSelectedRows={setSelectedRows}
                    isOneShop={true}
                    isPosEditorArchived={isPosEditorArchived}
                    isAddPosEditorClicked={isAddPosEditorClicked}
                    setIsAddPosEditorClicked={setIsAddPosEditorClicked}
                    selectedRows={selectedRows}
                    setLocalFilterActive={setLocalFilterActive}
                    type={e.selectedRow[t("Pos Editor")]}
                    setIsConsult={setIsConsult}
                />
            ),
            disableFilter: false,
        },
    ];

    function NumberActiveAndArchive() {
        if (
            dataTable.othersData !== undefined &&
            dataTable.othersData.activated !== undefined
        ) {
            setNumberActive(dataTable.othersData.activated);
            setNumberArchived(dataTable.othersData.archived);
        } else {
            setNumberActive(0);
            setNumberArchived(0);
        }
    }
    async function getLanguages() {
        const newList: any[] = [];

        await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/language?userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    "Accept-Language": i18nextLng,
                },

                method: "GET",
            }
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    for (let element of result.data) {
                        newList.push({
                            label: element[t(`Language`)],
                            value: element[`Id`],
                        });
                    }
                    setLanguageData(newList);
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    React.useEffect(() => {
        if (filterActive) setIsAddPosEditorClicked(false);
    }, [filterActive]);

    React.useEffect(() => {
        setIsAddPosEditorClicked(false);
        if (didMountRef.current) {
            setDataIsUpdated(true);
            getLanguages();
        } else {
            didMountRef.current = true;
        }
        // eslint-disable-next-line
    }, [i18nextLng]);

    React.useEffect(() => {
        let localShop: string[] = [];
        let idsLocalShop: string[] = [];
        selectedRows.forEach((el: any) => {
            if (el[t("Shop")] !== "" && el[t("Shop")] !== undefined)
                localShop.push(el.shopId);

            idsLocalShop.push(el.Id);
        });
        setIdsLocalShop(idsLocalShop);
        setLocalShop(localShop);
        let array: any = [];

        Object.values(selectedRows).forEach((element: any) => {
            array.push({ [element.shopId]: element.Id });
        });

        setSelectedPosEditor(array);
        setPosEditorData(dataTable);
        setArchivedPosEditorFranchise(dataTable);
        setPosEditorFranchise(dataTable);
        setDataArchive(
            dataTable?.othersData?.allData !== undefined
                ? dataTable?.othersData?.allData.othersData.listArchived
                : []
        );
        setDataFranchiseArchive(
            dataTable?.othersData?.allData !== undefined
                ? dataTable?.othersData?.allData.othersData.listArchived
                : []
        );
        if (posEditor?.Id !== undefined && oneShop) {
            setPosEditor(dataTable.data[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows, dataTable, t]);

    React.useEffect(() => {
        NumberActiveAndArchive();
        // eslint-disable-next-line
    }, [dataTable]);

    React.useEffect(() => {
        setActions(confirmNavigation);
        setIsActionsClicked(showPrompt as boolean);
        setValidationAction(cancelNavigation);
    }, [showPrompt, confirmNavigation, cancelNavigation]);

    const ActionColumnList = (e: any) => {
        return (
            oneShop &&
            modify === true && (
                <div
                    style={{
                        cursor: "pointer",
                        top: "-7px",
                    }}
                    className={`${classnames("dropdown pt-2", {
                        "not-allowed-icon__clz":
                            isPosEditorArchived ||
                            e?.selectedRow?.original?.[t("Pos Editor")] !==
                                "Cashpad",
                    })}`}
                >
                    <VerticalDotsIcon
                        id="dropdownMenuButton1"
                        className="cmn_drp_BTmenu"
                        data-bs-toggle="dropdown"
                        style={{
                            cursor: "pointer",
                            position: "relative",
                            top: "-5px !important",
                        }}
                        height={20}
                        width={20}
                        fill="black"
                    />
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <div
                            className="dropdown-item pl-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                redisPosEditor(
                                    e?.selectedRow?.original?.shopId,
                                    t
                                );
                            }}
                        >
                            <span className=" cmn_btn_BTlistActivated">
                                <RefreshPosIcon
                                    height={50}
                                    width={50}
                                    style={{
                                        cursor: "pointer",
                                        position: "absolute",
                                    }}
                                    className={`${classnames(
                                        "pointer__clz fed_icn_BTconsult",
                                        {
                                            "not-allowed-icon__clz": isPosEditorArchived,
                                        }
                                    )}`}
                                />
                                <div className="ml-5">
                                    {" "}
                                    {t("Recover Cashpad data")}
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            )
        );
    };
    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: oneShop ? "650px" : "600px",
                    gridTemplateColumns: isAddPosEditorClicked
                        ? "2fr 1fr "
                        : "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    url={isPosEditorArchived ? urlArchive : urlActive}
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    canExpand={false}
                    canSelect
                    customSelect
                    setData={setDataTable}
                    setSelectedRows={setSelectedRows}
                    customJsxSideFilterButton={
                        <React.Fragment>
                            {modify === true ? (
                                <>
                                    {isAddPosEditorClicked !== true ? (
                                        <div className="d-flex justify-content-start align-items-center ml-2">
                                            <span
                                                onClick={() => {
                                                    setPosEditor([]);
                                                    if (
                                                        posEditorData.data
                                                            .length > 0 &&
                                                        oneShop
                                                    ) {
                                                        ErrorToast(
                                                            t(
                                                                "Attention, a shop cannot have more than one Pos Editor"
                                                            )
                                                        );
                                                    } else {
                                                        setIsAddPosEditorClicked(
                                                            !isAddPosEditorClicked
                                                        );
                                                    }

                                                    setLocalFilterActive(false);
                                                }}
                                                className={`${classnames(
                                                    "w-100 ml-3 cmn_btn_openForm ",
                                                    {
                                                        "not-allowed-icon__clz": isPosEditorArchived,
                                                    }
                                                )}`}
                                            >
                                                <CustomMainColorButtonWithIcon
                                                    icon="PlusIcon"
                                                    iconPosition="left"
                                                    rounded
                                                    variant="primary"
                                                >
                                                    {t("Add Pos Editor")}
                                                </CustomMainColorButtonWithIcon>
                                                <div></div>
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="d-flex justify-content-start">
                                            <CustomMainColorIconButton
                                                icon="PlusIcon"
                                                className=" fed_btn_FRreset "
                                                rounded
                                                disabled={
                                                    posEditor.length === 0
                                                        ? false
                                                        : true
                                                }
                                                onClick={() => {
                                                    if (isEdited === true)
                                                        setIsInInitializationModalOpened(
                                                            true
                                                        );
                                                }}
                                            >
                                                <PlusIcon
                                                    height={20}
                                                    width={20}
                                                    fill="white"
                                                />
                                            </CustomMainColorIconButton>
                                            <div className="mt-3"></div>
                                        </div>
                                    )}
                                </>
                            ) : null}
                            <ActionColumn
                                setIsHistoryModalOpened={
                                    setIsHistoryModalOpened
                                }
                                isHistoryModalOpened={isHistoryModalOpened}
                                selectedRows={selectedRows}
                                setDataIsUpdated={setDataIsUpdated}
                                setIsModalOpened={setIsModalOpened}
                                isModalOpened={isModalOpened}
                                isPosEditorArchived={isPosEditorArchived}
                                setIsPosEditorArchived={setIsPosEditorArchived}
                                setIsAddPosEditorClicked={
                                    setIsAddPosEditorClicked
                                }
                            />
                        </React.Fragment>
                    }
                    canResize
                    showGlobalFilter
                    showFilter
                    canMovedCheckboxLeftOnExpand
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    actionColumn={ActionColumnList}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="posEditorForm"
                />
                {isAddPosEditorClicked ? (
                    <PosEditorForm
                        isAddPosEditorClicked={isAddPosEditorClicked}
                        setIsAddPosEditorClicked={setIsAddPosEditorClicked!}
                        setDataIsUpdated={setDataIsUpdated}
                        dataPosEditor={posEditor}
                        isAddNewPosEditorButtonClicked={
                            isAddNewPosEditorButtonClicked
                        }
                        setDataPosEditor={setPosEditor}
                        setLocalFilterActive={setLocalFilterActive}
                        languageData={languageData}
                        setIsConsult={setIsConsult}
                        isConsult={isConsult}
                    />
                ) : null}
            </div>
            {isActionsClicked === true ? (
                <ConfirmationUpdatingDataMessageModal
                    isModalOpened={isActionsClicked}
                    setIsModalOpened={setIsActionsClicked}
                    validationAction={validationAction}
                    actions={actions}
                    setIsEdited={setIsEdited}
                />
            ) : null}
            <ConfirmationActivePosEditor
                setIsModalOpened={setIsModalOpened}
                isModalOpened={isModalOpened}
            />
            {isInInitializationModalOpened === true ? (
                <ConfirmationInitializationMessage
                    isModalOpened={isInInitializationModalOpened}
                    setIsModalOpened={setIsInInitializationModalOpened}
                    setIsClicked={setIsAddNewPosEditorButtonClicked}
                    isClicked={isAddNewPosEditorButtonClicked}
                    setIsEdited={setIsEdited}
                />
            ) : null}
            {isHistoryModalOpened ? (
                <HistoryModal
                    ids={selectedRows}
                    setIsModalOpened={setIsHistoryModalOpened}
                    isModalOpened={isHistoryModalOpened}
                    setDataUpdated={setDataIsUpdated}
                />
            ) : null}
        </React.Fragment>
    );
}
