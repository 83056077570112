import React from "react";
import classnames from "classnames";
import {
    SettingsIcon,
    TrashIcon,
    DisassociateIcon,
    EyeIcon,
    PencilIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next/";

import {
    store as appStore,
    setIsConfigurationPrinterClicked,
    setIsConfigurationCashManagementClicked,
} from "./Kiosk/store";
import { setSelectedPeripherals } from "./DeviceAssociateModal/store";
import { store as userStore } from "@components/VerticalLayout/store";
import { setIsModalOpened as setIsSettingModalOpened } from "@store";
import {
    store,
    setIsAddAppClicked,
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setIsAddPeripheralClicked,
    setIsLoading,
} from "./store";

import {
    verifyIfPeripheralAssociate,
} from "../../helpers/peripheralsHelpers";

import LinkSvgIcon from "@components/Common/SvgIcons/LinkSvgIcon";
import ErrorToast from "@components/Common/ErrorTost";
import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import ConsultSvgIcon from "@components/Common/SvgIcons/ConsultSvgIcon";
import { setSelectedMonnyeur } from "@pages/Peripherals/CashManagement/store";

export function CustomIcons({
    e,
    setShopId,
    setRowData,
    setPeripheralId,
    setIsModalOpened,
    isModalOpened,
    setIsMoneticsPathsModalOpened,
    isMoneticsPathsModalOpened,
    setIsAssociateModalOpened,
    isAssociateModalOpened,
    setIsDeletePeripheralModalOpened,
    setApplicationChildrenId,
    setApiUrl,
    isConfigurationCashManagementClicked,
    setIsHistoryModalCashMangmentOpened,
    setIsModalDeleteAssociatePeripheralOpened,
    setTextModal,
    typeDisplay,
    setSelectedRows,
    setIsHistoryModalOpened,
    setIsConsult,
    setEditedData,
    setIsComingFromModify,
    setIsHistoryMoneticsModalOpened,
    setUrlHistoryPrinter,
    setIsAddPrinterOriginHistoryClicked,
}: any) {
    const { t } = useTranslation();
    const { oneShop, shopID, userID, franchiseID } = useSnapshot(userStore);
    const { displayMode, isConfigurationPrinterClicked } = useSnapshot(
        appStore
    );
    const {
        isActionsClicked,
        isEdited,
        isAddPeripheralClicked,
        modify,
        isLoading,
        allSelectedRows
    } = useSnapshot(store);
    const appId = "KIOSK";
    let ifPeripheralAssociateObject: {
        isPeripheralAssociate: boolean;
        appId_children: any[];
    };
    async function fnDisPer() {
        setRowData(e.selectedRow);
        setIsDeletePeripheralModalOpened(true);
        setApplicationChildrenId(
            e.selectedRow?.subRows?.length > 0
                ? e.selectedRow.subRows.map((elt: any) => elt.appId)
                : e.selectedRow.appId
        );
        setShopId(oneShop ? shopID : e.selectedRow.shopId);
        setPeripheralId(e.selectedRow.peripheralId);
        ifPeripheralAssociateObject = await verifyIfPeripheralAssociate(
            e.selectedRow.peripheralId,
            shopID,
            franchiseID,
            userID,
            appId
        );

        if (
            ifPeripheralAssociateObject?.isPeripheralAssociate &&
            displayMode === t("View by device")
        ) {
            ErrorToast(
                t(
                    "Please note that you cannot archive a device associated with an application."
                )
            );
        } else {
            setIsModalOpened(!isModalOpened);
        }
        if (e.selectedRow.peripheralType === "cash_management") {
            setApiUrl(
                e.selectedRow?.subRows?.length > 0 ||
                    ifPeripheralAssociateObject?.isPeripheralAssociate
                    ? `${process.env.REACT_APP_API_V2_URL}/settings/application/cash_management`
                    : `${
                          process.env.REACT_APP_API_V2_URL
                      }/settings/cashManagement?userId=${userID}&shopId=${
                          oneShop ? shopID : e.selectedRow.shopId
                      }&franchiseId=${franchiseID}&id=${
                          e.selectedRow.peripheralId
                      }&isArchived=true`
            );
        } else if (e.selectedRow.peripheralType === "printer") {
            setApiUrl(
                e.selectedRow?.subRows?.length > 0 ||
                    ifPeripheralAssociateObject?.isPeripheralAssociate
                    ? `${process.env.REACT_APP_API_V2_URL}/settings/application/printer`
                    : `${
                          process.env.REACT_APP_API_V2_URL
                      }/settings/printer?userId=${userID}&shopId=${
                          oneShop ? shopID : e.selectedRow.shopId
                      }&franchiseId=${franchiseID}&id=${
                          e.selectedRow.peripheralId
                      }&isArchived=true`
            );
        } else if (e.selectedRow.peripheralType === "monetic") {
            setApiUrl(
                e.selectedRow?.subRows?.length > 0 ||
                    ifPeripheralAssociateObject?.isPeripheralAssociate
                    ? `${process.env.REACT_APP_API_V2_URL}/settings/application/monetic`
                    : `${
                          process.env.REACT_APP_API_V2_URL
                      }/settings/monetics?userId=${userID}&shopId=${
                          oneShop ? shopID : e.selectedRow.shopId
                      }&franchiseId=${franchiseID}&id=${
                          e.selectedRow.peripheralId
                      }&isArchived=true`
            );
        }
    }
    async function fnDisApp() {
        if (
            displayMode !== t("View by device") &&
            e?.selectedRow?.subRows?.length > 0 &&
            typeDisplay === "notArchived"
        ) {
            ErrorToast(
                t(
                    "Please note that you cannot archive a application associated with an device."
                )
            );
            setIsLoading(false);
        } else {
            setIsLoading(false);
            setIsModalOpened(!isModalOpened);
        }

        setRowData(e.selectedRow);
        setIsDeletePeripheralModalOpened(false);
        setApplicationChildrenId(e.selectedRow.appId);
        setShopId(oneShop ? shopID : e.selectedRow.shopId);
        setPeripheralId(e.selectedRow.peripheralId);
        setApiUrl(
            e.selectedRow.peripheralType === undefined
                ? `${process.env.REACT_APP_API_V2_URL}/settings/application`
                : e.selectedRow.peripheralType === "printer"
                ? `${process.env.REACT_APP_API_V2_URL}/settings/application/printer`
                : e.selectedRow.peripheralType === "monetic"
                ? `${process.env.REACT_APP_API_V2_URL}/settings/application/monetic`
                : e.selectedRow.peripheralType === "cash_management"
                ? `${process.env.REACT_APP_API_V2_URL}/settings/application/cash_management`
                : null
        );
    }
    return (
        <React.Fragment>
            <span className="d-flex justify-content-between ml-3">
                {e.selectedRow["Application"] !== "" &&
                e.selectedRow[t("Shop")] === "" ? (
                    <div
                        className={`${classnames(
                            "d-flex justify-content-around "
                        )}`}
                    >
                        {(modify === false || typeDisplay === "archived") &&
                            displayMode !== t("View by device") && (
                                <div className="">
                                    <Tooltip title={t("To consult")}>
                                        <div>
                                            <ConsultSvgIcon
                                                className="pointer__clz afa_icn_toConsult"
                                                height={35}
                                                width={35}
                                                style={{
                                                    cursor: "pointer",
                                                    position: "relative",
                                                    bottom: "5px",
                                                }}
                                                fill="black"
                                                onClick={async () => {
                                                    setSelectedRows([
                                                        e.selectedRow,
                                                    ]);
                                                    setEditedData(
                                                        e.selectedRow
                                                    );
                                                    setIsAddAppClicked(true);
                                                    setIsConsult(true);
                                                    setIsComingFromModify(true);
                                                }}
                                            />
                                        </div>
                                    </Tooltip>
                                </div>
                            )}
                        {modify === true && typeDisplay !== "archived" ? (
                            <>
                                {e.selectedRow.subRows !== undefined ? (
                                    <div className="mr-1">
                                        <Tooltip
                                            title={(displayMode ===
                                            t("View by device")
                                                ? t("Modify a device")
                                                : t("Modify an application")
                                            ).toString()}
                                        >
                                            <div
                                                className={`${classnames(
                                                    "pointer__clz cmn_icn_edit",
                                                    {
                                                        "not-allowed-icon__clz":
                                                            allSelectedRows.length >
                                                            1,
                                                    }
                                                )}`}
                                                onClick={() => {
                                                    setIsAddAppClicked(true);
                                                    setIsAddPeripheralClicked(
                                                        !isAddPeripheralClicked
                                                    );
                                                    setEditedData(
                                                        e.selectedRow
                                                    );
                                                    setIsComingFromModify(true);
                                                }}
                                            >
                                                <PencilIcon
                                                    height={25}
                                                    width={25}
                                                />
                                            </div>
                                        </Tooltip>
                                    </div>
                                ) : null}
                                {e.selectedRow.subRows !== undefined ? (
                                    <div>
                                        <Tooltip
                                            title={(displayMode ===
                                            t("View by device")
                                                ? t(
                                                      "Associate an application with a device"
                                                  )
                                                : t(
                                                      "Associate a device with an application"
                                                  )
                                            ).toString()}
                                        >
                                            <div>
                                                <LinkSvgIcon
                                                    className={`${classnames(
                                                        "pointer__clz cmn_icn_associate",
                                                        {
                                                            "not-allowed-icon__clz":
                                                                typeDisplay ===
                                                                    "archived" ||
                                                                allSelectedRows.length >
                                                                    1,
                                                        }
                                                    )}`}
                                                    onClick={() => {
                                                        if (isEdited === true) {
                                                            setIsActionsClicked(
                                                                !isActionsClicked
                                                            );
                                                            setActions(() => {
                                                                setIsEdited(
                                                                    false
                                                                );
                                                                setIsAddAppClicked(
                                                                    false
                                                                );
                                                                setIsAssociateModalOpened(
                                                                    true
                                                                );
                                                                setRowData(
                                                                    e.selectedRow
                                                                );
                                                                setSelectedRows([]);
                                                                setSelectedPeripherals(
                                                                    e
                                                                        .selectedRow
                                                                        .subRows
                                                                );
                                                            });
                                                        } else {
                                                            setIsAssociateModalOpened(
                                                                true
                                                            );
                                                            setSelectedRows([]);
                                                            setRowData(
                                                                e.selectedRow
                                                            );
                                                            setSelectedPeripherals(
                                                                e.selectedRow
                                                                    .subRows
                                                            );
                                                        }
                                                    }}
                                                    height={25}
                                                    width={25}
                                                />
                                            </div>
                                        </Tooltip>
                                    </div>
                                ) : (
                                    <Tooltip
                                        title={
                                            e.selectedRow?.mark ===
                                                "Valina swiss" ||
                                            e.selectedRow.peripheralType ===
                                                "cash_management"
                                                ? ""
                                                : t("Configure").toString()
                                        }
                                    >
                                        <div>
                                            <SettingsIcon
                                                className={`${classnames(
                                                    e.selectedRow
                                                        .peripheralType ===
                                                        "monetic" &&
                                                        e.selectedRow?.mark ===
                                                            "Valina"
                                                        ? "pointer__clz"
                                                        : (e.selectedRow
                                                              .peripheralType ===
                                                              "monetic" &&
                                                              e.selectedRow
                                                                  ?.mark ===
                                                                  "Nepting") ||
                                                          e.selectedRow
                                                              ?.mark ===
                                                              "Valina swiss" ||
                                                          typeDisplay ===
                                                              "archived" ||
                                                          e.selectedRow
                                                              .peripheralType ===
                                                              "cash_management"
                                                        ? " not-allowed-icon__clz pointer__clz"
                                                        : "pointer__clz",
                                                    {
                                                        "not-allowed-icon__clz":
                                                            allSelectedRows.length >
                                                            1,
                                                    }
                                                )}`}
                                                onClick={() => {
                                                    setIsSettingModalOpened(
                                                        true
                                                    );
                                                    if (
                                                        e.selectedRow
                                                            .peripheralType ===
                                                        "printer"
                                                    ) {
                                                        setRowData(
                                                            e.selectedRow
                                                        );
                                                        setIsConfigurationPrinterClicked(
                                                            !isConfigurationPrinterClicked
                                                        );
                                                    } else if (
                                                        e.selectedRow
                                                            .peripheralType ===
                                                            "monetic" &&
                                                        e.selectedRow?.mark ===
                                                            "Valina"
                                                    ) {
                                                        setIsMoneticsPathsModalOpened(
                                                            true
                                                        );
                                                        setRowData(
                                                            e.selectedRow
                                                        );
                                                    } else if (
                                                        e.selectedRow
                                                            .peripheralType ===
                                                        "cash_management"
                                                    ) {
                                                        setRowData(
                                                            e.selectedRow
                                                        );

                                                        setIsConfigurationCashManagementClicked(
                                                            !isConfigurationCashManagementClicked
                                                        );
                                                    }
                                                }}
                                                height={22}
                                                width={22}
                                                style={{
                                                    position: "relative",
                                                    top: "7px",
                                                }}
                                            />
                                        </div>
                                    </Tooltip>
                                )}
                            </>
                        ) : null}
                        {displayMode === t("View by device") ? (
                            <>
                                <>
                                    {modify === true ? (
                                        <>
                                            <Tooltip
                                                title={(displayMode ===
                                                t("View by device")
                                                    ? t(
                                                          "Unlink app from device"
                                                      )
                                                    : t(
                                                          "Unlink the device from the application"
                                                      )
                                                ).toString()}
                                            >
                                                <div>
                                                    <DisassociateIcon
                                                        height={40}
                                                        width={35}
                                                        style={{
                                                            position:
                                                                "relative",
                                                            top: "-4px",
                                                        }}
                                                        fill="white"
                                                        className={`${classnames(
                                                            "pointer__clz cgt_icn_unlinkShop",
                                                            {
                                                                "not-allowed-icon__clz":
                                                                    typeDisplay ===
                                                                        "archived" ||
                                                                    allSelectedRows.length >
                                                                        1,
                                                            }
                                                        )}`}
                                                        onClick={async () => {
                                                            fnDisApp();
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </>
                                    ) : null}
                                </>
                                <>
                                    <Tooltip
                                        title={t("Consult history").toString()}
                                    >
                                        <div>
                                            <TimePastSvgIcon
                                                style={{ cursor: "pointer" }}
                                                className="pointer__clz cmn_icn_history ml-2 mt-1"
                                                onClick={() => {
                                                    if (isEdited === true) {
                                                        setIsEdited(false);
                                                        setIsActionsClicked(
                                                            !isActionsClicked
                                                        );
                                                        setSelectedRows([
                                                            e.selectedRow,
                                                        ]);
                                                        setIsHistoryModalOpened(
                                                            true
                                                        );
                                                    } else {
                                                        setIsEdited(false);
                                                        setSelectedRows([
                                                            e.selectedRow,
                                                        ]);
                                                        setIsHistoryModalOpened(
                                                            true
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Tooltip>
                                </>
                            </>
                        ) : typeDisplay === "notArchived" ? (
                            <>
                                <div>
                                    {" "}
                                    {modify === true ? (
                                        <>
                                            <Tooltip
                                                title={t("Archive").toString()}
                                            >
                                                <div>
                                                    <TrashIcon
                                                        className={`${classnames(
                                                            "pointer__clz cmn_icn_archived",
                                                            {
                                                                "not-allowed-icon__clz":
                                                                    isLoading ||
                                                                    typeDisplay ===
                                                                        "archived" ||
                                                                    allSelectedRows.length >
                                                                        1,
                                                            }
                                                        )}`}
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        height={22}
                                                        width={22}
                                                        fill="red"
                                                        onClick={async () => {
                                                            setIsLoading(true);
                                                            if (
                                                                isEdited ===
                                                                true
                                                            ) {
                                                                setIsActionsClicked(
                                                                    !isActionsClicked
                                                                );
                                                                setActions(
                                                                    async () => {
                                                                        setIsEdited(
                                                                            false
                                                                        );
                                                                        fnDisApp();
                                                                    }
                                                                );
                                                            } else {
                                                                setIsEdited(
                                                                    false
                                                                );
                                                                fnDisApp();
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </>
                                    ) : null}
                                </div>
                                <Tooltip
                                    title={t("Consult history").toString()}
                                >
                                    <div>
                                        <TimePastSvgIcon
                                            style={{ cursor: "pointer" }}
                                            className="pointer__clz cmn_icn_history ml-1"
                                            onClick={() => {
                                                if (isEdited === true) {
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(async () => {
                                                        setIsEdited(false);
                                                        setIsAddAppClicked(
                                                            false
                                                        );
                                                        setSelectedRows([
                                                            e.selectedRow,
                                                        ]);
                                                        setIsHistoryModalOpened(
                                                            true
                                                        );
                                                    });
                                                } else {
                                                    setIsEdited(false);
                                                    setIsAddAppClicked(false);
                                                    setSelectedRows([
                                                        e.selectedRow,
                                                    ]);
                                                    setIsHistoryModalOpened(
                                                        true
                                                    );
                                                }
                                            }}
                                        />
                                    </div>
                                </Tooltip>
                            </>
                        ) : (
                            <>
                                {modify === true ? (
                                    <>
                                        <Tooltip
                                            title={t("Unarchive").toString()}
                                        >
                                            <div>
                                                <EyeIcon
                                                    className={`${classnames(
                                                        "cmn_icn_unarchive",
                                                        {
                                                            "not-allowed-icon__clz":
                                                                isLoading ||
                                                                allSelectedRows.length >
                                                                    1,
                                                        }
                                                    )}`}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    height={22}
                                                    width={22}
                                                    fill="black"
                                                    onClick={async () => {
                                                        setIsLoading(true);
                                                        fnDisApp();
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>
                                    </>
                                ) : null}
                                <Tooltip
                                    title={t("Consult history").toString()}
                                >
                                    <div>
                                        <TimePastSvgIcon
                                            style={{ cursor: "pointer" }}
                                            className="pointer__clz cmn_icn_history ml-1"
                                            onClick={() => {
                                                if (isEdited === true) {
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(async () => {
                                                        setIsAddAppClicked(
                                                            false
                                                        );
                                                        setIsEdited(false);
                                                        setSelectedRows([
                                                            e.selectedRow,
                                                        ]);
                                                        setIsHistoryModalOpened(
                                                            true
                                                        );
                                                    });
                                                } else {
                                                    setIsAddAppClicked(false);
                                                    setIsEdited(false);
                                                    setSelectedRows([
                                                        e.selectedRow,
                                                    ]);
                                                    setIsHistoryModalOpened(
                                                        true
                                                    );
                                                }
                                            }}
                                        />
                                    </div>
                                </Tooltip>
                            </>
                        )}
                    </div>
                ) : e.selectedRow[t("Peripheral")] !== "" &&
                  e.selectedRow[t("Shop")] === "" ? (
                    <div
                        className={`${classnames(
                            "d-flex justify-content-around"
                        )}`}
                    >
                        {(modify === false || typeDisplay === "archived") && (
                            <div className="">
                                {e.selectedRow.subRows !== undefined &&
                                    displayMode === t("View by device") &&
                                    e.selectedRow[t("Peripheral")] !== "" && (
                                        <Tooltip title={t("To consult")}>
                                            <div>
                                                <ConsultSvgIcon
                                                    className="pointer__clz afa_icn_toConsult"
                                                    height={35}
                                                    width={35}
                                                    style={{
                                                        cursor: "pointer",
                                                        position: "relative",
                                                        bottom: "5px",
                                                    }}
                                                    fill="black"
                                                    onClick={async () => {
                                                        setIsAddAppClicked(
                                                            true
                                                        );
                                                        setIsAddPeripheralClicked(
                                                            true
                                                        );
                                                        setEditedData(
                                                            e.selectedRow
                                                        );
                                                        setIsComingFromModify(
                                                            true
                                                        );
                                                        setIsConsult(true);
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>
                                    )}
                            </div>
                        )}
                        {e.selectedRow.subRows !== undefined &&
                        displayMode === t("View by device") ? (
                            <div
                                className="ml-3 pointer__clz d-flex justify-content-around"
                                style={{ position: "relative", top: "8px" }}
                            >
                                {modify === true ? (
                                    <>
                                        <Tooltip
                                            title={(displayMode ===
                                            t("View by device")
                                                ? t("Modify a device")
                                                : t("Modify an application")
                                            ).toString()}
                                        >
                                            <div
                                                className={`${classnames(
                                                    "afa_drp_FRedit",
                                                    {
                                                        "not-allowed-icon__clz":
                                                            typeDisplay ===
                                                                "archived" ||
                                                            allSelectedRows.length >
                                                                1,
                                                    }
                                                )}`}
                                                onClick={() => {
                                                    setIsAddAppClicked(true);
                                                    setIsAddPeripheralClicked(
                                                        true
                                                    );
                                                    setEditedData(
                                                        e.selectedRow
                                                    );
                                                    setIsComingFromModify(true);
                                                }}
                                            >
                                                <PencilIcon
                                                    height={25}
                                                    width={25}
                                                />
                                            </div>
                                        </Tooltip>
                                    </>
                                ) : null}
                            </div>
                        ) : null}

                        {e.selectedRow.subRows !== undefined ? (
                            <>
                                {modify === true ? (
                                    <>
                                        <Tooltip
                                            title={(displayMode ===
                                            t("View by device")
                                                ? t(
                                                      "Associate an application with a device"
                                                  )
                                                : t(
                                                      "Associate a device with an application"
                                                  )
                                            ).toString()}
                                        >
                                            <div>
                                                <LinkSvgIcon
                                                    className={`${classnames(
                                                        "pointer__clz cmn_icn_associate mt-1",
                                                        {
                                                            "not-allowed-icon__clz":
                                                                typeDisplay ===
                                                                    "archived" ||
                                                                allSelectedRows.length >
                                                                    1,
                                                        }
                                                    )}`}
                                                    onClick={() => {
                                                        if (isEdited === true) {
                                                            setIsActionsClicked(
                                                                !isActionsClicked
                                                            );
                                                            setActions(() => {
                                                                setIsEdited(
                                                                    false
                                                                );
                                                                setIsAddAppClicked(
                                                                    false
                                                                );
                                                                setIsAssociateModalOpened(
                                                                    true
                                                                );
                                                                setSelectedRows(
                                                                    []
                                                                );
                                                                setRowData(
                                                                    e.selectedRow
                                                                );
                                                            });
                                                        } else {
                                                            setIsEdited(false);
                                                            setIsAddAppClicked(
                                                                false
                                                            );
                                                            setIsAssociateModalOpened(
                                                                true
                                                            );
                                                            setSelectedRows([]);
                                                            setRowData(
                                                                e.selectedRow
                                                            );
                                                        }
                                                    }}
                                                    style={{
                                                        position: "relative",
                                                        top: "2px",
                                                    }}
                                                    height={25}
                                                    width={25}
                                                />
                                            </div>
                                        </Tooltip>
                                    </>
                                ) : null}
                                {e.selectedRow.peripheralType === "monetic" ? (
                                    <Tooltip
                                        title={t("Consult history").toString()}
                                    >
                                        <div>
                                            <TimePastSvgIcon
                                                style={{ cursor: "pointer" }}
                                                className="pointer__clz cmn_icn_history ml-1 mt-1"
                                                onClick={() => {
                                                    if (isEdited === true) {
                                                        setIsActionsClicked(
                                                            !isActionsClicked
                                                        );
                                                        setActions(async () => {
                                                            setIsEdited(false);
                                                            setIsAddAppClicked(
                                                                false
                                                            );
                                                            setSelectedRows([
                                                                e.selectedRow,
                                                            ]);
                                                            setIsHistoryMoneticsModalOpened(
                                                                true
                                                            );
                                                        });
                                                    } else {
                                                        setIsAddAppClicked(
                                                            false
                                                        );
                                                        setIsEdited(false);
                                                        setSelectedRows([
                                                            e.selectedRow,
                                                        ]);
                                                        setIsHistoryMoneticsModalOpened(
                                                            true
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Tooltip>
                                ) : e.selectedRow.peripheralType ===
                                  "cash_management" ? (
                                    <Tooltip
                                        title={t("Consult history").toString()}
                                    >
                                        <div>
                                            <TimePastSvgIcon
                                                style={{ cursor: "pointer" }}
                                                className="pointer__clz cmn_icn_history ml-1 mt-1"
                                                onClick={() => {
                                                    if (isEdited === true) {
                                                        setIsActionsClicked(
                                                            !isActionsClicked
                                                        );
                                                        setActions(async () => {
                                                            setIsAddAppClicked(
                                                                false
                                                            );
                                                            setIsEdited(false);
                                                            setSelectedMonnyeur(
                                                                [
                                                                    {
                                                                        [e
                                                                            .selectedRow
                                                                            .shopId]:
                                                                            e
                                                                                .selectedRow
                                                                                .peripheralId,
                                                                    },
                                                                ]
                                                            );

                                                            setSelectedRows([
                                                                e.selectedRow,
                                                            ]);
                                                            setIsHistoryModalCashMangmentOpened(
                                                                true
                                                            );
                                                        });
                                                    } else {
                                                        setIsAddAppClicked(
                                                            false
                                                        );
                                                        setIsEdited(false);
                                                        setSelectedMonnyeur([
                                                            {
                                                                [e.selectedRow
                                                                    .shopId]:
                                                                    e
                                                                        .selectedRow
                                                                        .peripheralId,
                                                            },
                                                        ]);

                                                        setSelectedRows([
                                                            e.selectedRow,
                                                        ]);
                                                        setIsHistoryModalCashMangmentOpened(
                                                            true
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Tooltip>
                                ) : e.selectedRow.peripheralType ===
                                  "printer" ? (
                                    <Tooltip
                                        title={t("Consult history").toString()}
                                    >
                                        <div>
                                            <TimePastSvgIcon
                                                style={{ cursor: "pointer" }}
                                                className="pointer__clz cmn_icn_history ml-1 mt-1"
                                                onClick={() => {
                                                    if (isEdited === true) {
                                                        setIsActionsClicked(
                                                            !isActionsClicked
                                                        );
                                                        setActions(async () => {
                                                            setIsAddAppClicked(
                                                                false
                                                            );
                                                            setIsEdited(false);
                                                            setUrlHistoryPrinter(
                                                                `${
                                                                    process.env
                                                                        .REACT_APP_API_V2_URL
                                                                }/settings/printers/printer/history?userId=${userID}&shopId=${
                                                                    oneShop
                                                                        ? shopID
                                                                        : e
                                                                              .selectedRow
                                                                              .shopId
                                                                }&franchiseId=${franchiseID}&id=${
                                                                    e
                                                                        .selectedRow
                                                                        .peripheralId
                                                                }}`
                                                            );
                                                            setIsAddPrinterOriginHistoryClicked(
                                                                true
                                                            );
                                                        });
                                                    } else {
                                                        setIsAddAppClicked(
                                                            false
                                                        );
                                                        setIsEdited(false);
                                                        setUrlHistoryPrinter(
                                                            `${
                                                                process.env
                                                                    .REACT_APP_API_V2_URL
                                                            }/settings/printers/printer/history?userId=${userID}&shopId=${
                                                                oneShop
                                                                    ? shopID
                                                                    : e
                                                                          .selectedRow
                                                                          .shopId
                                                            }&franchiseId=${franchiseID}&id=${
                                                                e.selectedRow
                                                                    .peripheralId
                                                            }`
                                                        );
                                                        setIsAddPrinterOriginHistoryClicked(
                                                            true
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Tooltip>
                                ) : null}
                            </>
                        ) : (
                            <>
                                {modify === true ? (
                                    <Tooltip
                                        title={
                                            e.selectedRow?.mark ===
                                                "Valina swiss" ||
                                            e.selectedRow.peripheralType ===
                                                "cash_management"
                                                ? ""
                                                : t("Configure").toString()
                                        }
                                    >
                                        <div   className={`${classnames(
                                                        "pointer__clz cgt_icn_unlinkShop mr-1 ",
                                                        {
                                                            "not-allowed-icon__clz":   allSelectedRows.length >
                                                                    1,
                                                        }
                                                    )}`}>
                                            <SettingsIcon
                                                className={
                                                    e.selectedRow
                                                        .peripheralType ===
                                                        "monetic" &&
                                                    e.selectedRow[t("Mark")] ===
                                                        "Valina"
                                                        ? "pointer__clz"
                                                        : (e.selectedRow
                                                              .peripheralType ===
                                                              "monetic" &&
                                                              e.selectedRow
                                                                  ?.mark ===
                                                                  "Nepting") ||
                                                          e.selectedRow
                                                              ?.mark ===
                                                              "Valina swiss" ||
                                                          typeDisplay ===
                                                              "archived" ||
                                                          e.selectedRow
                                                              .peripheralType ===
                                                              "cash_management"
                                                        ? " not-allowed-icon__clz pointer__clz"
                                                        : "pointer__clz"
                                                }
                                                style={{
                                                    position: "relative",
                                                    top: "8px",
                                                }}
                                                onClick={() => {
                                                    if (isEdited === true) {
                                                        setIsActionsClicked(
                                                            !isActionsClicked
                                                        );
                                                        setActions(() => {
                                                            setIsEdited(false);
                                                            setShopId(
                                                                e.selectedRow
                                                                    .shopId
                                                            );
                                                            setApplicationChildrenId(
                                                                e.selectedRow
                                                                    .appId
                                                            );
                                                            if (
                                                                e.selectedRow
                                                                    .peripheralType ===
                                                                "printer"
                                                            ) {
                                                                setRowData(
                                                                    e.selectedRow
                                                                );
                                                                setIsConfigurationPrinterClicked(
                                                                    !isConfigurationPrinterClicked
                                                                );
                                                            } else if (
                                                                e.selectedRow
                                                                    .peripheralType ===
                                                                    "monetic" &&
                                                                e.selectedRow[
                                                                    t("Mark")
                                                                ] === "Valina"
                                                            ) {
                                                                setIsMoneticsPathsModalOpened(
                                                                    !isMoneticsPathsModalOpened
                                                                );
                                                                setRowData(
                                                                    e.selectedRow
                                                                );
                                                            } else if (
                                                                e.selectedRow
                                                                    .peripheralType ===
                                                                "cash_management"
                                                            ) {
                                                                setRowData(
                                                                    e.selectedRow
                                                                );

                                                                setIsConfigurationCashManagementClicked(
                                                                    !isConfigurationCashManagementClicked
                                                                );
                                                            }
                                                        });
                                                    } else {
                                                        setIsEdited(false);
                                                        setShopId(
                                                            e.selectedRow.shopId
                                                        );
                                                        setApplicationChildrenId(
                                                            e.selectedRow.appId
                                                        );
                                                        if (
                                                            e.selectedRow
                                                                .peripheralType ===
                                                            "printer"
                                                        ) {
                                                            setRowData(
                                                                e.selectedRow
                                                            );
                                                            setIsConfigurationPrinterClicked(
                                                                !isConfigurationPrinterClicked
                                                            );
                                                        } else if (
                                                            e.selectedRow
                                                                .peripheralType ===
                                                                "monetic" &&
                                                            e.selectedRow[
                                                                t("Mark")
                                                            ] === "Valina"
                                                        ) {
                                                            setIsMoneticsPathsModalOpened(
                                                                true
                                                            );
                                                            setRowData(
                                                                e.selectedRow
                                                            );
                                                        } else if (
                                                            e.selectedRow
                                                                .peripheralType ===
                                                            "cash_management"
                                                        ) {
                                                            setRowData(
                                                                e.selectedRow
                                                            );

                                                            setIsConfigurationCashManagementClicked(
                                                                !isConfigurationCashManagementClicked
                                                            );
                                                        }
                                                    }
                                                }}
                                                height={22}
                                                width={22}
                                            />
                                        </div>
                                    </Tooltip>
                                ) : null}
                            </>
                        )}

                        {displayMode === t("View by device") ? (
                            <div></div>
                        ) : (
                            <span className="d-flex justify-content-around">
                                {modify === true ? (
                                    <>
                                        <Tooltip
                                            title={(displayMode ===
                                            t("View by device")
                                                ? t("Unlink app from device")
                                                : t(
                                                      "Unlink the device from the application"
                                                  )
                                            ).toString()}
                                        >
                                            <div>
                                                {" "}
                                                <DisassociateIcon
                                                    height={40}
                                                    width={35}
                                                    style={{
                                                        position: "relative",
                                                        top: "-4px",
                                                    }}
                                                    fill="white"
                                                    className={`${classnames(
                                                        "pointer__clz cgt_icn_unlinkShop mr-1 ",
                                                        {
                                                            "not-allowed-icon__clz":
                                                                typeDisplay ===
                                                                "archived"||allSelectedRows.length >
                                                                    1,
                                                        }
                                                    )}`}                     
                                                    onClick={async () => {
                                                        fnDisPer();
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>
                                    </>
                                ) : null}
                                {e.selectedRow.peripheralType === "monetic" ? (
                                    <Tooltip
                                        title={t("Consult history").toString()}
                                    >
                                        <div>
                                            <TimePastSvgIcon
                                                style={{ cursor: "pointer" }}
                                                className="pointer__clz cmn_icn_history ml-1 mt-2"
                                                onClick={() => {
                                                    if (isEdited === true) {
                                                        setIsActionsClicked(
                                                            !isActionsClicked
                                                        );
                                                        setActions(() => {
                                                            setIsEdited(false);
                                                            setIsAddAppClicked(
                                                                false
                                                            );
                                                            setSelectedRows([
                                                                e.selectedRow,
                                                            ]);
                                                            setIsHistoryMoneticsModalOpened(
                                                                true
                                                            );
                                                        });
                                                    } else {
                                                        setIsEdited(false);
                                                        setIsAddAppClicked(
                                                            false
                                                        );
                                                        setSelectedRows([
                                                            e.selectedRow,
                                                        ]);
                                                        setIsHistoryMoneticsModalOpened(
                                                            true
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Tooltip>
                                ) : e.selectedRow.peripheralType ===
                                  "cash_management" ? (
                                    <Tooltip
                                        title={t("Consult history").toString()}
                                    >
                                        <div>
                                            <TimePastSvgIcon
                                                style={{ cursor: "pointer" }}
                                                className="pointer__clz cmn_icn_history ml-1 mt-2"
                                                onClick={() => {
                                                    if (isEdited === true) {
                                                        setIsActionsClicked(
                                                            !isActionsClicked
                                                        );
                                                        setActions(() => {
                                                            setIsEdited(false);
                                                            setIsAddAppClicked(
                                                                false
                                                            );
                                                            setSelectedMonnyeur(
                                                                [
                                                                    {
                                                                        [e
                                                                            .selectedRow
                                                                            .shopId]:
                                                                            e
                                                                                .selectedRow
                                                                                .peripheralId,
                                                                    },
                                                                ]
                                                            );

                                                            setSelectedRows([
                                                                e.selectedRow,
                                                            ]);
                                                            setIsHistoryModalCashMangmentOpened(
                                                                true
                                                            );
                                                        });
                                                    } else {
                                                        setIsEdited(false);
                                                        setIsAddAppClicked(
                                                            false
                                                        );
                                                        setSelectedMonnyeur([
                                                            {
                                                                [e.selectedRow
                                                                    .shopId]:
                                                                    e
                                                                        .selectedRow
                                                                        .peripheralId,
                                                            },
                                                        ]);

                                                        setSelectedRows([
                                                            e.selectedRow,
                                                        ]);
                                                        setIsHistoryModalCashMangmentOpened(
                                                            true
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Tooltip>
                                ) : e.selectedRow.peripheralType ===
                                  "printer" ? (
                                    <Tooltip
                                        title={t("Consult history").toString()}
                                    >
                                        <div>
                                            <TimePastSvgIcon
                                                style={{ cursor: "pointer" }}
                                                className="pointer__clz cmn_icn_history ml-1 mt-2"
                                                onClick={() => {
                                                    if (isEdited === true) {
                                                        setIsActionsClicked(
                                                            !isActionsClicked
                                                        );
                                                        setActions(() => {
                                                            setIsEdited(false);
                                                            setIsAddAppClicked(
                                                                false
                                                            );
                                                            setUrlHistoryPrinter(
                                                                `${
                                                                    process.env
                                                                        .REACT_APP_API_V2_URL
                                                                }/settings/printers/printer/history?userId=${userID}&shopId=${
                                                                    oneShop
                                                                        ? shopID
                                                                        : e
                                                                              .selectedRow
                                                                              .shopId
                                                                }&franchiseId=${franchiseID}&id=${
                                                                    e
                                                                        .selectedRow
                                                                        .peripheralId
                                                                }`
                                                            );
                                                            setIsAddPrinterOriginHistoryClicked(
                                                                true
                                                            );
                                                        });
                                                    } else {
                                                        setIsEdited(false);
                                                        setUrlHistoryPrinter(
                                                            `${
                                                                process.env
                                                                    .REACT_APP_API_V2_URL
                                                            }/settings/printers/printer/history?userId=${userID}&shopId=${
                                                                oneShop
                                                                    ? shopID
                                                                    : e
                                                                          .selectedRow
                                                                          .shopId
                                                            }&franchiseId=${franchiseID}&id=${
                                                                e.selectedRow
                                                                    .peripheralId
                                                            }`
                                                        );
                                                        setIsAddPrinterOriginHistoryClicked(
                                                            true
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Tooltip>
                                ) : null}
                            </span>
                        )}
                    </div>
                ) : e.selectedRow[t("Peripheral")] !== "" &&
                  e.selectedRow[t("Shop")] === "" ? (
                    <div
                        className={`${classnames(
                            "d-flex justify-content-around w-50"
                        )}`}
                    >
                        {e.selectedRow.subRows !== undefined ? (
                            <>
                                {modify === true ? (
                                    <>
                                        <Tooltip
                                            title={(displayMode ===
                                            t("View by device")
                                                ? t(
                                                      "Associate an application with a device"
                                                  )
                                                : t(
                                                      "Associate a device with an application"
                                                  )
                                            ).toString()}
                                        >
                                            <div>
                                                <LinkSvgIcon
                                                    className={`${classnames(
                                                        "pointer__clz cmn_icn_associate",
                                                        {
                                                            "not-allowed-icon__clz":
                                                                typeDisplay ===
                                                                "archived",
                                                        }
                                                    )}`}
                                                    onClick={() => {
                                                        if (isEdited === true) {
                                                            setIsActionsClicked(
                                                                !isActionsClicked
                                                            );
                                                            setActions(() => {
                                                                setSelectedRows(
                                                                    []
                                                                );
                                                                setIsEdited(
                                                                    false
                                                                );
                                                                setIsAddAppClicked(
                                                                    false
                                                                );
                                                                setIsAssociateModalOpened(
                                                                    !isAssociateModalOpened
                                                                );
                                                                setRowData(
                                                                    e.selectedRow
                                                                );
                                                            });
                                                        } else {
                                                            setSelectedRows([]);
                                                            setIsEdited(false);
                                                            setIsAddAppClicked(
                                                                false
                                                            );
                                                            setIsAssociateModalOpened(
                                                                !isAssociateModalOpened
                                                            );
                                                            setRowData(
                                                                e.selectedRow
                                                            );
                                                        }
                                                    }}
                                                    height={23}
                                                    width={23}
                                                    style={{
                                                        position: "relative",
                                                        top: "5px",
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>
                                    </>
                                ) : null}
                            </>
                        ) : (
                            <>
                                {modify === true ? (
                                    <>
                                        <Tooltip
                                            title={t("Configure").toString()}
                                        >
                                            <div>
                                                <SettingsIcon
                                                    className={`${classnames(
                                                        "pointer__clz",
                                                        {
                                                            "not-allowed-icon__clz":
                                                                typeDisplay ===
                                                                "archived",
                                                        }
                                                    )}`}
                                                    onClick={() => {
                                                        if (isEdited === true) {
                                                            setIsActionsClicked(
                                                                !isActionsClicked
                                                            );
                                                            setActions(() => {
                                                                setIsEdited(
                                                                    false
                                                                );
                                                                setIsAddAppClicked(
                                                                    false
                                                                );
                                                                setRowData(
                                                                    e.selectedRow
                                                                );
                                                                setIsConfigurationPrinterClicked(
                                                                    !isConfigurationPrinterClicked
                                                                );
                                                            });
                                                        } else {
                                                            setIsEdited(false);
                                                            setIsAddAppClicked(
                                                                false
                                                            );
                                                            setRowData(
                                                                e.selectedRow
                                                            );
                                                            setIsConfigurationPrinterClicked(
                                                                !isConfigurationPrinterClicked
                                                            );
                                                        }
                                                    }}
                                                    height={22}
                                                    width={22}
                                                />
                                            </div>
                                        </Tooltip>
                                    </>
                                ) : null}
                            </>
                        )}
                        {modify === true ? (
                            <>
                                <Tooltip title={t("Archive").toString()}>
                                    <div>
                                        <TrashIcon
                                            className={`${classnames(
                                                "pointer__clz cmn_icn_archived",
                                                {
                                                    "not-allowed-icon__clz":
                                                        isLoading ||
                                                        typeDisplay ===
                                                            "archived",
                                                }
                                            )}`}
                                            style={{ cursor: "pointer" }}
                                            height={22}
                                            width={22}
                                            fill="red"
                                        />
                                    </div>
                                </Tooltip>
                            </>
                        ) : null}
                    </div>
                ) : null}
            </span>
        </React.Fragment>
    );
}
