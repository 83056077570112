import {
    ConfigurationTemplateIcon,
    DeviceSettingsIcon,
} from "@components/Common/SvgIcons";

export type CardMenuType = {
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    description?: string;
    title: string;
    number: number;
    path: string;
};

export const CARD_MENU_LIST: CardMenuType[] = [
    {
        title: "Template Setup",
        number: 0,
        Icon: ConfigurationTemplateIcon,
        description:
            "The configuration of the template used on the kiosk is done in this section. It is crucial to note that in order to configure a template, it is imperative for the store to have at least one sales mode associated with the kiosk.",
        path: "/project",
    },
    {
        title: "Peripheral setting",
        number: 0,
        Icon: DeviceSettingsIcon,
        description:
            "In this section, you can perform the declaration and configuration of the store's kiosks, as well as peripherals such as printers and electronic payment terminals (TPE).",
        path: "/kiosk-peripheral-setting",
    },
];
