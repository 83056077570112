import { t } from "i18next";

import React from "react";

export const Previous = (props: any): JSX.Element => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            viewBox="0 0 350 87"
        >
            <g
                id="Groupe_24"
                data-name="Groupe 24"
                transform="translate(-5359 4106)"
            >
                <g
                    id="Rectangle_17"
                    data-name="Rectangle 17"
                    transform="translate(5359 -4106)"
                    fill="#fff"
                    stroke="#000"
                    stroke-width="2"
                >
                    <rect width="350" height="87" rx="9" stroke="none" />
                    <rect
                        x="1"
                        y="1"
                        width="348"
                        height="85"
                        rx="8"
                        fill="none"
                    />
                </g>
                <text
                    id="Précédent"
                    transform="translate(5534 -4048)"
                    font-size="38"
                    font-family="HelveticaNeue-Medium, Helvetica Neue"
                    font-weight="500"
                >
                    <tspan x="-90.117" y="0">
                        {t("Previous", {
                            lng: props.selectedLanguage,
                        })}
                    </tspan>
                </text>
            </g>
        </svg>
    );
};
