import { SettingsIcon } from "@aureskonnect/react-ui";
import classnames from "classnames";
import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";


import { setIsModalOpened, store } from "./store";
import { store as useStore } from "@components/VerticalLayout/store";

export default function CustomIcons({ e, setSelectedRows }: any) {
    const { t } = useTranslation();
    const { isModalOpened, modify } = useSnapshot(store);
    const {oneShop} = useSnapshot(useStore);

    return (
        <React.Fragment>
            {modify === true && (
                <>
                    <Tooltip title={t("configure")}>
                        <IconButton>
                            <SettingsIcon
                                className={`${classnames("pointer__clz ml-3", {
                                    "not-allowed-icon__clz":!oneShop && e.selectedRow[t("Apk")],
                                })}`}
                                onClick={() => {
                                    setIsModalOpened(!isModalOpened);
                                  
                                    setSelectedRows([e.selectedRow]);
                                }}
                                height={22}
                                width={22}
                            />
                        </IconButton>
                    </Tooltip>
                </>
            )}
        </React.Fragment>
    );
}
