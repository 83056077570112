import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { ListManager } from "react-beautiful-dnd-grid";
import { uid } from "react-uid";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";

import {
    generalConfigStore,
    setConfigLanguages,
    setDefaultLanguage,
    setImageKey,
    setProjectFiles,
} from "@store";

import { ResetModalWrapper } from "@components/Common/ResetModalWrapper";
import { FlexboxGrid } from "@components/Common/FlexboxGrid";
import { ScrollContainer } from "@components/ScrollContainer";
import { CustomErrorBoundary } from "@components/CustomErrorBoundary";

import { LanguageCardWrapper } from "./LanguageCardWrapper";
import { moveArrayElementToTheBegin } from "@helpers/general";

export function Languages(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: { template, files: images },
        isResetModalOpened,
        defaultLanguage,
        initItemsFromGeneralSetting,
    } = useSnapshot(generalConfigStore);

    const [languagesItems, setLanguagesItems] = React.useState<
        LanguagesItemType[]
    >(
        moveArrayElementToTheBegin(
            (template.content.languages as LanguagesType).items.filter(
                (element: LanguagesItemType): boolean =>
                    element.isDefault === true
            )[0],
            (template.content.languages as LanguagesType).items
        )
    );

    const [
        localIsResetModalOpened,
        setLocalIsResetModalOpened,
    ] = React.useState<boolean>(isResetModalOpened);

    function handleOnDragEndEvent(
        sourceIndex: number,
        destinationIndex: number
    ) {
        if (destinationIndex === sourceIndex) {
            return;
        }

        if (destinationIndex === 0 || sourceIndex === 0) {
            return;
        }
        if (destinationIndex === 0 && sourceIndex !== 0) {
            return;
        }
        const newItems = [...languagesItems];
        const [reorderedItem] = newItems.splice(sourceIndex, 1);
        newItems.splice(destinationIndex, 0, reorderedItem);
        setLanguagesItems(newItems);
        setConfigLanguages(newItems);
    }

    async function handleResetOnClickEvent(): Promise<void> {
        const initLanguages = initItemsFromGeneralSetting.languages as LanguagesItemType[];
        setLanguagesItems(initLanguages);
        setDefaultLanguage(
            initLanguages.filter(
                (defaultLanguage) => defaultLanguage.isDefault === true
            )[0]
        );
        setConfigLanguages(initLanguages);

        const imagesArray = [...images];

        initLanguages
            .map((item: LanguagesItemType) => item.id)
            .forEach((id: string) => {
                const image = imagesArray.find((element) => element.id === id);

                if (image !== undefined) {
                    const imageIndex = imagesArray.indexOf(image);

                    const localContent = image.content as ImageItemLanguagesType;
                    if (localContent[defaultLanguage.name] !== undefined) {
                        imagesArray[imageIndex] = {
                            ...image,
                            content: {
                                ...localContent,
                                [defaultLanguage.name]: {
                                    ...localContent[defaultLanguage.name],
                                    path:
                                        localContent[defaultLanguage.name]
                                            .defaultImage,
                                },
                            } as ImageItemLanguagesType,
                        };
                    } else {
                        imagesArray[imageIndex] = {
                            ...image,
                            content: image.defaultImage as string,
                        };
                    }
                }
            });

        setProjectFiles(imagesArray);
        setImageKey(uid(imagesArray));
    }

    return (
        <CustomErrorBoundary>
            <div
                className="h-100 d-grid__clz cursor__clz"
                style={{
                    gridTemplateRows: "0.1fr auto",
                    gap: "50px",
                }}
            >
                <div>
                    <div
                        style={{
                            font: "normal normal 600 35px/61px Nunito Sans",
                        }}
                    >
                        {t("Languages choice")}
                    </div>

                    <FlexboxGrid
                        alignItemsCentered={true}
                        className="justify-content-between"
                    >
                        <span
                            style={{
                                font: "normal normal 300 20px/27px Nunito Sans",
                                color: "#A0A0A0",
                            }}
                        >
                            {t("Drag and drop elements to change the order")}
                        </span>
                        <StyledIconButton
                            title={t("Reset to default settings")}
                            outline={true}
                            variant="danger"
                            className="m-0 p-0"
                            icon="RefreshIcon"
                            style={{ height: "20%" }}
                            onClick={() => {
                                setLocalIsResetModalOpened(
                                    !localIsResetModalOpened
                                );
                            }}
                        >
                            <RefreshIcon
                                height={20}
                                width={20}
                                className="Cmn_btn_resetModifications"
                            />
                        </StyledIconButton>
                    </FlexboxGrid>
                </div>

                {languagesItems.length > 8 ? (
                    <ScrollContainer>
                        <div
                            className="drag-drop-wrapper__clz d-flex flex-column justify-content-center"
                            style={{
                                gap: "25px",
                            }}
                        >
                            <ListManager
                                items={languagesItems}
                                direction="horizontal"
                                maxItems={4}
                                render={(item) => {
                                    const index = languagesItems.findIndex(
                                        (object) => {
                                            return object.name === item.name;
                                        }
                                    );

                                    return (
                                        <LanguageCardWrapper
                                            item={item}
                                            index={index}
                                            key={index}
                                            setItems={setLanguagesItems}
                                        />
                                    );
                                }}
                                onDragEnd={handleOnDragEndEvent}
                            />
                        </div>
                    </ScrollContainer>
                ) : (
                    <div
                        className="drag-drop-wrapper__clz d-flex flex-column justify-content-center"
                        style={{ gap: "25px" }}
                    >
                        <ListManager
                            items={languagesItems}
                            direction="horizontal"
                            maxItems={
                                languagesItems.length > 4 &&
                                languagesItems.length <= 6
                                    ? 3
                                    : 4
                            }
                            render={(item) => {
                                const index = languagesItems.findIndex(
                                    (object) => {
                                        return object.name === item.name;
                                    }
                                );

                                return (
                                    <LanguageCardWrapper
                                        item={item}
                                        index={index}
                                        key={index}
                                        setItems={setLanguagesItems}
                                    />
                                );
                            }}
                            onDragEnd={handleOnDragEndEvent}
                        />
                    </div>
                )}
            </div>

            <ResetModalWrapper
                handleResetOnClickEvent={handleResetOnClickEvent}
                localIsResetModalOpened={localIsResetModalOpened}
                setLocalIsResetModalOpened={setLocalIsResetModalOpened}
            />
        </CustomErrorBoundary>
    );
}
