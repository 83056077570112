import React from "react";
import { Nav, TabContent, TabPane } from "reactstrap";
import { useTranslation } from "react-i18next";

import { setPaymentSelectedRegulationModeValue } from "@store";

import { MainContentCreditCard } from "./MainContentCreditCard";
import { MainContentChangeMachine } from "./MainContentChangeMachine";

export function ActionsButtons(): JSX.Element {
    const { t } = useTranslation();

    const [activeTab, setActiveTab] = React.useState<number>(0);

    const tabs: TabsType[] = [
        {
            title: t("Credit card"),
            content: <MainContentCreditCard />,
        },
        {
            title: t("Change machine"),
            content: <MainContentChangeMachine />,
        },
    ];

    function toggleNav(tab: number) {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    return (
        <React.Fragment>
            <div
                className="d-flex align-items-center"
                style={{ height: "60px" }}
            >
                <Nav
                    tabs
                    className="d-grid__clz"
                    style={{
                        backgroundColor: "white",
                        width: "50px",
                        gridTemplateColumns: `repeat(${tabs.length}, 1fr)`,
                        border: "unset",
                        gap: "15px",
                    }}
                >
                    {tabs.map((element, index) => {
                        return (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fill: "red",
                                    top: "301px",
                                    left: "557px",
                                    width: "155px",
                                    height: "34px",
                                    background:
                                        activeTab === index
                                            ? "#323333 0% 0% no-repeat padding-box"
                                            : "#E9E9E9 0% 0% no-repeat padding-box",
                                    borderRadius: "5px",
                                    opacity: 1,
                                    gap: "10px",
                                    textAlign: "center",
                                    font:
                                        "normal normal 600 15px/20px Segoe UI",
                                    letterSpacing: "0px",
                                    color:
                                        activeTab === index
                                            ? "#FFFFFF"
                                            : "#000000",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    toggleNav(index);
                                    setPaymentSelectedRegulationModeValue(
                                        index === 0
                                            ? "creditCard"
                                            : index === 1
                                            ? "changeMachine"
                                            : "restoflash"
                                    );
                                }}
                            >
                                {element.title}
                            </div>
                        );
                    })}
                </Nav>
            </div>
            <TabContent activeTab={activeTab}>
                {tabs.map((element, index) => {
                    return (
                        <TabPane
                            tabId={index}
                            key={index}
                            style={{ height: "100%", gap: "20px" }}
                        >
                            {element.content}
                        </TabPane>
                    );
                })}
            </TabContent>
        </React.Fragment>
    );
}
