import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledTextInput,
    DownloadIcon,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { AvGroup } from "availity-reactstrap-validation";
import classnames from "classnames";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";

import {
    setTicketHeaderSaleModeBold,
    setTicketHeaderSaleModeBlackBackround,
    setTicketHeaderName_phone_number_bold,
    setTicketHeaderLogoKitchen,
    setTicketHeaderLogoCustomer,
    StoreGeneral,
} from "./Hook/store";

import "../index.css";

type TicketHeaderType = {
    changeHandlerCustomer: React.ChangeEventHandler<HTMLInputElement>;
    changeHandler: React.ChangeEventHandler<HTMLInputElement>;
};

export default function TicketHeader({
    changeHandlerCustomer,
    changeHandler,
}: TicketHeaderType) {
    const { t } = useTranslation();
    const {
        ticketHeaderSaleModeBlackBackround,
        typeLogoCustomer,
        typeLogoKitchen,
        ticketHeaderSaleModeBold,
        ticketHeaderName_phone_number_bold,
        ticketHeaderPathCustomer,
        ticketHeaderPathKitchen,
        ticketHeaderLogoCustomer,
        ticketHeaderLogoKitchen,
    } = useSnapshot(StoreGeneral);

 const isJpgOrPng =
        typeLogoCustomer === "image/jpeg" ||
        typeLogoCustomer === "image/png" ||
        typeLogoCustomer === "image/ico" ||
        typeLogoCustomer === "image/jpg";
        const isJpgOrPngKitchen =
            typeLogoKitchen === "image/jpeg" ||
            typeLogoKitchen === "image/png" ||
            typeLogoKitchen === "image/ico" ||
            typeLogoKitchen === "image/jpg";
    return (
        <React.Fragment>
            <div className="d-flex  align-items-center justify-content-between px-1 pt-2 pb-1">
                <b>{t("Ticket header")}</b>
            </div>
            <div className="d-flex  align-items-center justify-content-between p-1">
                <div>{t("Print sale mode in bold")}</div>
                <div className=" float-right mr-4">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={ticketHeaderSaleModeBold}
                        onChange={(e: any) => {
                         

                            setTicketHeaderSaleModeBold(
                                !ticketHeaderSaleModeBold
                            );
                            
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                    />
                </div>
            </div>
            <div className="d-flex  align-items-center justify-content-between p-1">
                <div>{t("Print sale mode in  with black background")}</div>
                <div className=" float-right mr-4">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={ticketHeaderSaleModeBlackBackround}
                        onChange={(e: any) => {
                            setTicketHeaderSaleModeBlackBackround(
                                !ticketHeaderSaleModeBlackBackround
                            );
                           
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                    />
                </div>
            </div>
            <div className="d-flex  align-items-center justify-content-between p-1">
                <div>{t("Print company name and phone number in bold")}</div>
                <div className=" float-right mr-4">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={ticketHeaderName_phone_number_bold}
                        onChange={(e: any) => {
                            setTicketHeaderName_phone_number_bold(
                                !ticketHeaderName_phone_number_bold
                            );
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                    />
                </div>
            </div>
            <div className="d-flex  align-items-center justify-content-between p-1">
                <div>{t("Print logo on customer receipt")}</div>
                <div className=" float-right mr-4">
                    <b>
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={ticketHeaderLogoCustomer}
                            onChange={(e: any) => {
                                setTicketHeaderLogoCustomer(
                                    !ticketHeaderLogoCustomer
                                );
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={55}
                            height={20}
                        />
                    </b>
                </div>
            </div>
            <div className="d-flex  align-items-center justify-content-between p-1">
                <div className="d-flex justify-content-between align-items-center">
                    <AvGroup>
                        <StyledLabel
                            htmlFor="path"
                            style={{
                                opacity: !ticketHeaderLogoCustomer ? "0.3" : "",
                            }}
                        >
                            {t("Load logo")}
                        </StyledLabel>
                        <StyledTextInput
                            id="path"
                            type="text"
                            name="path"
                            disabled={!ticketHeaderLogoCustomer}
                            readonly
                            className="not-allowed-fidelity__clz"
                            style={{
                                width: "500px",
                                backgroundColor: !ticketHeaderLogoCustomer
                                    ? "#white"
                                    : "",
                                opacity: !ticketHeaderLogoCustomer && "0.5",
                            }}
                            value={ticketHeaderPathCustomer}
                        />
                    </AvGroup>
                    <div className="input-group">
                        <label htmlFor="file1">
                            <DownloadIcon
                                height={5}
                                width={5}
                                style={{ cursor: "pointer" }}
                                className={`${classnames(
                                    "btn_upload__clz ml-2",
                                    {
                                        "not-allowed-icon__clz": !ticketHeaderLogoCustomer,
                                    }
                                )}`}
                            />
                        </label>
                        <input
                            type="file"
                            onChange={changeHandlerCustomer}
                            id="file1"
                            style={{ display: "none" }}
                        />
                    </div>
                </div>
            </div>
            {!isJpgOrPng && typeLogoCustomer !== "" ? (
                <div
                    className="ml-2"
                    style={{
                        width: "100%",
                        marginTop: "0.25rem",
                        fontSize: "80%",
                        color: "#f46a6a",
                    }}
                >
                    {t("Please insert a png, jpg,jpeg or ico image.")}
                </div>
            ) : null}
            <div className="d-flex  align-items-center justify-content-between p-1">
                {t("Print logo on kitchen ticket")}
                <div className=" float-right mr-4">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={ticketHeaderLogoKitchen}
                        onChange={(e: any) => {
                            setTicketHeaderLogoKitchen(
                                !ticketHeaderLogoKitchen
                            );
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                    />
                </div>
            </div>
            <div className="d-flex  align-items-center justify-content-between p-1">
                <div className=" d-flex justify-content-between align-items-center">
                    <AvGroup>
                        <StyledLabel
                            style={{
                                opacity: !ticketHeaderLogoKitchen ? "0.3" : "",
                            }}
                            htmlFor="path_Kitchen"
                        >
                            {t("Load logo")}
                        </StyledLabel>
                        <StyledTextInput
                            id="path_Kitchen"
                            type="text"
                            name="path_Kitchen"
                            readonly
                            className="not-allowed-fidelity__clz"
                            style={{
                                width: "500px",
                                backgroundColor: !ticketHeaderLogoKitchen
                                    ? "#white"
                                    : "",
                                opacity: !ticketHeaderLogoKitchen ? "0.5" : "",
                            }}
                            value={ticketHeaderPathKitchen}
                        />
                    </AvGroup>
                    <div className="input-group">
                        <label htmlFor="file">
                            <DownloadIcon
                                height={5}
                                width={5}
                                style={{ cursor: "pointer" }}
                                className={`${classnames(
                                    "btn_upload__clz ml-2",
                                    {
                                        "not-allowed-icon__clz": !ticketHeaderLogoKitchen,
                                    }
                                )}`}
                            />
                        </label>
                        <input
                            type="file"
                            onChange={changeHandler}
                            id="file"
                            style={{ display: "none" }}
                        />
                    </div>
                </div>
            </div>
            {!isJpgOrPngKitchen && typeLogoKitchen !== "" ? (
                <div
                    className="ml-2"
                    style={{
                        width: "100%",
                        marginTop: "0.25rem",
                        fontSize: "80%",
                        color: "#f46a6a",
                    }}
                >
                    {t("Please insert a png, jpg,jpeg or ico image.")}
                </div>
            ) : null}
        </React.Fragment>
    );
}

 
