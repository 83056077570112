import { proxy } from "valtio";

type StoreType = {
    isAddModeOfInformationClicked: boolean;
    isModeOfInformation: boolean;
    isModeOfInformationArchived: boolean;
    numberArchived: number;
    numberActive: number;
    isAssociateModalOpened: boolean;
    localShop: any[];
    isAssociateModalClosed: boolean;
    numberActiveMultiShop: number;
    numberArchiveMultiShop: number;
    isModeOfInformationAssociatedModifiedClosed: boolean;
    idsLocalShop: string[];
    isAssociatedModeOfInformation: boolean;
    selectedRows: any[];
    isAssociatedClosed: boolean;
    ids: number;
    isModeOfInformationAssociatedArchivedClosed: boolean;
    isModified: boolean;
    isActivation: boolean;
    isModeOfInformationAssociatedModified: boolean;
    shopsModeInformationData: any;
    shopsModeInformationDataArchive: any;
    modeInformationData: any;
    rowsShop: any[];
    modeInformationFranchise: any;
    archivedModeInformationFranchise: any;
    globalDataIsUpdated: boolean | number;
    associatedModeInformation: any[];
    activeModeInformation: any[];
    modeOfInformationFranchiseArchive: any;
    dataArchive: any;
    archivedModeOfInformationFranchise: any;
    dataFranchiseArchive: any;
    isArchived: boolean;
    message: string;
    canValidate: boolean;
    localShopWithInformation: any;
    dataInformation: any;
    fnDelete: Function;
    isDeleteCheckClick: boolean;
    isActionsClicked: boolean;
    isEdited: boolean;
    actions: Function;
    validationAction: any;
    modify: boolean;
    key: any;
    isLoading: boolean;
};

export const store = proxy<StoreType>({
    key: 0,
    isLoading: false,
    isAddModeOfInformationClicked: false,
    isModeOfInformation: false,
    isModeOfInformationArchived: false,
    numberArchived: 0,
    numberActive: 0,
    isAssociateModalOpened: false,
    localShop: [],
    isAssociateModalClosed: false,
    numberActiveMultiShop: 0,
    numberArchiveMultiShop: 0,
    isModeOfInformationAssociatedModifiedClosed: false,
    idsLocalShop: [],
    isAssociatedModeOfInformation: false,
    selectedRows: [],
    isAssociatedClosed: false,
    ids: 0,
    isModeOfInformationAssociatedArchivedClosed: false,
    isModified: false,
    isActivation: false,
    isModeOfInformationAssociatedModified: false,
    shopsModeInformationData: [],
    shopsModeInformationDataArchive: [],
    modeInformationData: [],
    rowsShop: [],
    modeInformationFranchise: [],
    archivedModeInformationFranchise: [],
    globalDataIsUpdated: false,
    associatedModeInformation: [],
    activeModeInformation: [],
    modeOfInformationFranchiseArchive: [],
    dataArchive: [],
    archivedModeOfInformationFranchise: [],
    dataFranchiseArchive: [],
    isArchived: false,
    message: "",
    canValidate: false,
    localShopWithInformation: [],
    dataInformation: {},
    fnDelete: () => {},
    isDeleteCheckClick: false,
    isActionsClicked: false,
    isEdited: false,
    actions: () => {},
    validationAction: {},
    modify: false,
});

export function setIsLoading(isLoading: boolean): void {
    store.isLoading = isLoading;
}

export function setKey(key: any): void {
    store.key = key;
}

export function setModify(value: boolean): void {
    store.modify = value;
}

export function setIsDeleteCheckClick(x: boolean): void {
    store.isDeleteCheckClick = x;
}
export function setFnDelete(x: Function): void {
    store.fnDelete = x;
}
export function setDataInformation(x: any): void {
    store.dataInformation = x;
}
export function setLocalShopWithInformation(x: any): void {
    store.localShopWithInformation = x;
}
export function setCanValidate(x: boolean): void {
    store.canValidate = x;
}
export function setIsArchived(x: boolean): void {
    store.isArchived = x;
}
export function setDataFranchiseArchive(dataFranchiseArchive: any[]): void {
    store.dataFranchiseArchive = dataFranchiseArchive;
}
export function setArchivedModeOfInformationFranchise(
    archivedModeOfInformationFranchise: any[]
): void {
    store.archivedModeOfInformationFranchise = archivedModeOfInformationFranchise;
}

export function setShopsModeInformationDataArchive(x: any[]): void {
    store.shopsModeInformationDataArchive = x;
}
export function setDataArchive(x: any[]): void {
    store.dataArchive = x;
}
export function setModeOfSaleFranchiseArchive(x: any[]): void {
    store.modeOfInformationFranchiseArchive = x;
}
export function setIsAddModeOfInformationClicked(
    isAddModeOfInformationClicked: boolean
): void {
    store.isAddModeOfInformationClicked = isAddModeOfInformationClicked;
}

export function setIsModeOfInformation(isModeOfInformation: boolean): void {
    store.isModeOfInformation = isModeOfInformation;
}

export function setIsModeOfInformationArchived(
    isModeOfInformationArchived: boolean
): void {
    store.isModeOfInformationArchived = isModeOfInformationArchived;
}

export function setNumberArchived(number: number): void {
    store.numberArchived = number;
}

export function setNumberActive(number: number): void {
    store.numberActive = number;
}

export function setIsAssociateModalOpened(isModalOpened: boolean): void {
    store.isAssociateModalOpened = isModalOpened;
}
export function setLocalShop(shops: any[]): void {
    store.localShop = shops;
}
export function setIsAssociateModalClosed(
    isAssociateModalClosed: boolean
): void {
    store.isAssociateModalClosed = isAssociateModalClosed;
}

export function setNumberActiveMultiShop(number: number): void {
    store.numberActiveMultiShop = number;
}
export function setNumberArchiveMultiShop(number: number): void {
    store.numberArchiveMultiShop = number;
}

export function setIsModeOfInformationAssociatedModifiedClosed(
    isModeOfInformationAssociatedModifiedClosed: boolean
): void {
    store.isModeOfInformationAssociatedModifiedClosed = isModeOfInformationAssociatedModifiedClosed;
}
export function setIdsLocalShop(ids: any[]): void {
    store.idsLocalShop = ids;
}

export function setIsAssociatedModeOfInformation(
    isAssociatedModeOfInformation: boolean
): void {
    store.isAssociatedModeOfInformation = isAssociatedModeOfInformation;
}
export function setSelectedRow(selectedRows: any[]): void {
    store.selectedRows = selectedRows;
}

export function setIsAssociatedClosed(isAssociatedClosed: boolean): void {
    store.isAssociatedClosed = isAssociatedClosed;
}

export function setIds(id: number): void {
    store.ids = id;
}

export function setIsModeOfInformationAssociatedArchivedClosed(
    isModeOfInformationAssociatedArchivedClosed: boolean
): void {
    store.isModeOfInformationAssociatedArchivedClosed = isModeOfInformationAssociatedArchivedClosed;
}

export function setIsModified(isModified: boolean): void {
    store.isModified = isModified;
}
export function setIsActivation(isActivation: boolean): void {
    store.isActivation = isActivation;
}
export function setIsModeOfInformationAssociatedModified(
    isModeOfInformationAssociatedModified: boolean
): void {
    store.isModeOfInformationAssociatedModified = isModeOfInformationAssociatedModified;
}

export function setShopsModeInformationData(
    shopsModeInformationData: any
): void {
    store.shopsModeInformationData = shopsModeInformationData;
}

export function setModeInformationData(modeInformationData: any): void {
    store.modeInformationData = modeInformationData;
}

export function setRowsShop(rowsShop: any[]): void {
    store.rowsShop = rowsShop;
}
export function setArchivedModeInformationFranchise(
    archivedModeInformationFranchise: any[]
): void {
    store.archivedModeInformationFranchise = archivedModeInformationFranchise;
}

export function setModeInformationFranchise(
    modeInformationFranchise: any[]
): void {
    store.modeInformationFranchise = modeInformationFranchise;
}
export function setGlobalDataIsUpdated(
    globalDataIsUpdated: boolean | number
): void {
    store.globalDataIsUpdated = globalDataIsUpdated;
}
export function setAssociatedModeInformation(
    associatedModeInformation: any[]
): void {
    store.associatedModeInformation = associatedModeInformation;
}
export function setMessage(message: string): void {
    store.message = message;
}
export function setActiveModeInformation(activeModeInformation: any): void {
    let index = store.activeModeInformation.findIndex(
        (element: any) => element.Id === activeModeInformation.Id
    );
    index !== -1
        ? (store.activeModeInformation[index] = activeModeInformation)
        : store.activeModeInformation.push(activeModeInformation);
}

export function setIsEdited(isEdited: boolean): any {
    store.isEdited = isEdited;
}

export function setIsActionsClicked(isActionsClicked: boolean): any {
    store.isActionsClicked = isActionsClicked;
}

export function setValidationAction(validationAction: any): any {
    store.validationAction = validationAction;
}

export function setActions(actions: any): any {
    store.actions = actions;
}

export default store;
