import React from "react";
import { StyledH2 } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { capitalize } from "@helpers/general";
import { resetStore } from "@pages/Applications/DeviceAssociateModal/store";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import i18n from "i18next";
import detector from "i18next-browser-languagedetector";

import { store } from "@components/VerticalLayout/store";
import { store as storeData } from "./store";

import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { languages } from "@constants/index";

import "./index.css";

export default function HistoryModal({ isModalOpened, setIsModalOpened }: any) {
    const { t } = useTranslation();
    const {
        selectedPaymentMethods,
        selectedHistoryPaymentMethods,
    } = useSnapshot(storeData);
    const { franchiseID, userID } = useSnapshot(store);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);

    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };
    const url = `${
        process.env.REACT_APP_API_V2_URL
    }/settings/general/paymentMethod/history?userId=${userID}&language=${
        i18n.use(detector).language === "fr" ? "fr" : "en"
    }&franchiseId=${franchiseID}&payment_method=${selectedPaymentMethods
        .map((el: any) => JSON.stringify(el))
        .join()}`;
    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 0,
            columnName: t("Payment method"),
            customJsx: (e: any) => {
                return (
                    <CustomColumnDisplay
                        text={e.selectedRow["Type of payment method"]}
                    />
                );
            },
        },
        {
            indexOFColumn: 1,
            columnName: t("Actions"),
            customJsx: (e: any) => {
                let actionsArray: any = [];
                e.selectedRow.actions.forEach((el: any) => {
                    if (el.item !== "isActive") {
                        if (el.item === "saleSupport") {
                            actionsArray.push(t("Sale Support"));
                        } else if (el.item === "display") {
                            actionsArray.push(t("Display name"));
                        } else if (el.item === "advancedDisplayName") {
                            actionsArray.push(t("advanced display name"));
                        } else {
                            actionsArray.push(t(el.item));
                        }
                    }
                });

                if (e.selectedRow.operation === "modify") {
                    if (e.selectedRow.actions[0].item === "isActive") {
                        return e.selectedRow.actions[0].newValue === true ? (
                            <span>{t("Activation")}</span>
                        ) : (
                            <span>{t("Deactivation")}</span>
                        );
                    } else if (e.selectedRow.actions[0].item === "isArchived") {
                        return e.selectedRow.actions[0].newValue === true ? (
                            <span>{t("Archiving")}</span>
                        ) : (
                            <span>{t("Unarchiving")}</span>
                        );
                    }
                    return (
                        <span>
                            {t("Modification")} {actionsArray.join(",")}
                        </span>
                    );
                } else if (e.selectedRow.operation === "creation") {
                    return <span>{t("Creation")}</span>;
                } else if (e.selectedRow.operation.includes("reassignment")) {
                    return (
                        <span>
                            {t("Reassignment")}
                            {e.selectedRow.operation.substring(12)}
                        </span>
                    );
                } else {
                    return (
                        <span>
                            {t("Affectation")} {e.selectedRow.operation}
                        </span>
                    );
                }
            },
        },
        {
            indexOFColumn: 2,
            columnName: t("Before modification"),
            customJsx: (e: any) => {
                let oldValueArray: any = [];
                let beforeAdvancedDisplayname = "";
                let translatedAdvancedDisplayName: any = "";

                e.selectedRow.actions.forEach((el: any) => {
                    if (
                        el.oldValue !== true &&
                        el.oldValue !== false &&
                        typeof el.oldValue !== "object"
                    )
                        oldValueArray.push(el.oldValue);
                    else if (el.oldValue.languages !== undefined) {
                        for (let item in el.newValue.languages) {
                            translatedAdvancedDisplayName = languages.find(
                                (lg: any) => lg.value === item
                            )?.label;
                            beforeAdvancedDisplayname = `${beforeAdvancedDisplayname}  ${t(
                                translatedAdvancedDisplayName
                            )} : ${el.oldValue.languages[item]} |`;
                        }

                        oldValueArray.push(beforeAdvancedDisplayname);
                    }
                });

                if (e.selectedRow.operation === "modify") {
                    return (
                        <CustomColumnDisplay
                            text={
                                e.selectedRow.actions[0].item ===
                                "isArchived" ? (
                                    e.selectedRow.actions[0].oldValue ===
                                    true ? (
                                        <span>
                                            {t("Status")}: {t("Archived")}
                                        </span>
                                    ) : (
                                        <span>
                                            {t("Status")}: {t("Unarchived")}
                                        </span>
                                    )
                                ) : e.selectedRow.actions[0].item ===
                                  "isActive" ? (
                                    e.selectedRow.actions[0].oldValue ===
                                    true ? (
                                        <span>
                                            {t("State")}: {t("Activated")}
                                        </span>
                                    ) : (
                                        <span>
                                            {t("State")}: {t("Deactivated")}
                                        </span>
                                    )
                                ) : oldValueArray.join().slice(-1) === "|" ? (
                                    oldValueArray.join(",").slice(0, -1)
                                ) : (
                                    oldValueArray.join(",")
                                )
                            }
                        />
                    );
                } else if (e.selectedRow.operation === "creation") {
                    return "";
                } else if (e.selectedRow.operation === "reassignment") {
                    return "";
                } else {
                    return "";
                }
            },
        },
        {
            indexOFColumn: 3,
            columnName: t("After modification"),
            customJsx: (e: any) => {
                let NewValueArray: any = [];
                let afterAdvancedDisplayname: any = "";
                let translatedAdvancedDisplayName: any = "";

                e.selectedRow.actions.forEach((el: any) => {
                    if (
                        el.newValue !== true &&
                        el.newValue !== false &&
                        typeof el.newValue !== "object"
                    )
                        NewValueArray.push(el.newValue);
                    else if (el.newValue.languages !== undefined) {
                        for (let item in el.newValue.languages) {
                            translatedAdvancedDisplayName = languages.find(
                                (lg: any) => lg.value === item
                            )?.label;
                            afterAdvancedDisplayname = `${afterAdvancedDisplayname}  ${t(
                                translatedAdvancedDisplayName
                            )} : ${el.newValue.languages[item]} |`;
                        }

                        NewValueArray.push(afterAdvancedDisplayname);
                    }
                });

                if (e.selectedRow.operation === "modify") {
                    return (
                        <CustomColumnDisplay
                            text={
                                e.selectedRow.actions[0].item ===
                                "isArchived" ? (
                                    e.selectedRow.actions[0].newValue ===
                                    true ? (
                                        <span>
                                            {t("Status")}: {t("Archived")}
                                        </span>
                                    ) : (
                                        <span>
                                            {t("Status")}: {t("Unarchived")}
                                        </span>
                                    )
                                ) : e.selectedRow.actions[0].item ===
                                  "isActive" ? (
                                    e.selectedRow.actions[0].newValue ===
                                    true ? (
                                        <span>
                                            {t("State")}: {t("Activated")}
                                        </span>
                                    ) : (
                                        <span>
                                            {t("State")}: {t("Deactivated")}
                                        </span>
                                    )
                                ) : NewValueArray.join().slice(-1) === "|" ? (
                                    NewValueArray.join(",").slice(0, -1)
                                ) : (
                                    NewValueArray.join(",")
                                )
                            }
                        />
                    );
                } else if (e.selectedRow.operation === "creation") {
                    return t(
                        e.selectedRow.actions.find(
                            (elt: any) => elt.item === "display"
                        )?.newValue
                    );
                } else if (e.selectedRow.operation === "reassignment") {
                    return <span>{t("Reassigned")}</span>;
                } else {
                    return "";
                }
            },
        },
        {
            indexOFColumn: 5,
            columnName: t("Date and hour"),
            customJsx: (e: any) => {
                return (
                    <CustomColumnDisplay
                        text={e.selectedRow["Date and hour"]}
                    />
                );
            },
        },
    ];

    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                className="modal-wrapper__clz"
                isOpen={isModalOpened}
            >
                <ModalHeader
                    toggle={() => {
                        setIsModalOpened!(!isModalOpened);
                        resetStore();
                    }}
                >
                    <StyledH2 className="mdr_txt_HStitleHistory">
                        {capitalize(t("Historical"))}
                    </StyledH2>
                </ModalHeader>

                <ModalBody>
                    <div
                        className={`${classnames(
                            "title__clz d-flex justify-content-start",
                            {
                                "not-allowed-icon__clz":
                                    selectedPaymentMethods.length > 1,
                            }
                        )}`}
                    >
                        <div
                            style={{
                                marginRight: "200px",
                                marginLeft: "20px",
                            }}
                        >
                            {t("Payment method")} :{" "}
                            {selectedPaymentMethods.length > 1
                                ? ""
                                : selectedHistoryPaymentMethods[
                                      t("Type of payment method")
                                  ] !== undefined
                                ? selectedHistoryPaymentMethods[
                                      t("Type of payment method")
                                  ]
                                : selectedHistoryPaymentMethods[
                                      t("Payment method")
                                  ]}
                        </div>
                    </div>

                    <DynamicTable
                        url={url}
                        requestHeader={{ "Accept-Language": i18nextLng }}
                        canResize
                        actionColumn={ActionColumn}
                        showGlobalFilter
                        showFilter
                        setLocalFilterActive={setLocalFilterActive}
                        filterActive={filterActive}
                        setDataIsUpdated={setDataIsUpdated}
                        dataIsUpdated={dataIsUpdated}
                        elevationTable={1}
                        minHeight="500px"
                        maxHeight="500px"
                        name="historyModeOfSale"
                        arrayOfCustomColumns={arrayOfCustomColumns}
                    />
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <CustomMainColorButton
                        className="mdr_btn_HSclose"
                        variant="primary"
                        onClick={() => {
                            resetStore();
                            setIsModalOpened(!isModalOpened);
                        }}
                        rounded
                    >
                        {t("Close")}
                    </CustomMainColorButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
