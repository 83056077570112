import React, { useEffect, useState } from "react";
import {
    CheckboxIcon,
    CrossIcon,
    StyledLabel,
    StyledSelectInput,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import ReactSwitch from "react-switch";
import { useTranslation } from "react-i18next";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { v4 } from "uuid";

import {
    store as appStore,
    setIsEdited,
    setActions,
    setGlobalDataIsUpdated,
    setIsActionsClicked,
} from "../store";
import { store as userStore } from "@components/VerticalLayout/store";
import { store } from "../ApplicationAssociateModal/store";

import { checkIpIfExist } from "@helpers/general";
import { ipMatchWord } from "@constants/index";
import {
    CustomCardHeader,
    CustomCardBody,
    CustomCardFooter,
} from "@components/Common/CustomCardStyled";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import ErrorToast from "@components/Common/ErrorTost";

import "../index.css";

type applicationConfigurationType = {
    setIsAddAppClicked: Function;
    setDataIsUpdated: Function;
    isAddAppClicked: boolean;
    isAddNewAppButtonClicked: boolean;
    selectedRows: any;
    isComingFromModify?: boolean;
    inputDesignation: boolean;
    setInputDesignation: Function;
    setIsConfirmationEditModalOpened: Function;
    data: any;
    isConsult: boolean;
    setIsConsult: Function;
    shopIds?: any;
    setSelectedRows?: Function;
};

export function ApplicationConfigure({
    setIsAddAppClicked,
    isAddAppClicked,
    setDataIsUpdated,
    isAddNewAppButtonClicked,
    selectedRows,
    isComingFromModify,
    inputDesignation,
    setInputDesignation,
    setIsConfirmationEditModalOpened,
    data,
    isConsult,
    setIsConsult,
    shopIds,
    setSelectedRows,
}: applicationConfigurationType) {
    const { t } = useTranslation();
    const {
        oneShop,
        userID,
        franchiseID,
        shopID,
        operatorID,
        resourceIp,
    } = useSnapshot(userStore);
    const uuidUser = localStorage.getItem("uuidUser");

    const { applicationListData } = useSnapshot(store);
    const { isEdited, isActionsClicked } = useSnapshot(appStore);
    const typeOfLed: {
        label: string;
        value: string;
    }[] = [].map((element: string) => {
        return {
            label: element,
            value: element,
        };
    });
    for (let i = 1; i < 21; i++) {
        typeOfLed.push({
            label: "COM" + i + "",
            value: "COM" + i + "",
        });
    }
    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);

    const [isVisibiliteLedActive, setIsVisibiliteLedActive] = React.useState<
        boolean
    >(false);

    const [designation, setDesignation] = useState<string>("");
    const [application, setApplication] = useState<string>("KIOSK");
    const [remark, setRemark] = useState<string>("");
    const [ip, setIp] = useState<string>("");
    const [typeLed, setTypeLed] = useState<string>("");

    const [isApplicationListExist, setIsApplicationListExist] = React.useState<
        boolean
    >(false);

    const [exist, setExist] = useState<any>({
        isNameExist: false,
        isIpExist: false,
    });

    const [localNamesApplications, setLocalNamesApplications] = useState<
        string[]
    >([]);
    const [ips, setIps] = useState<string[]>([]);
    const matchWordDesignation = new RegExp(
        new RegExp(/^[\w]+([-_\s]{1}[A-Za-z0-9]+)*$/i)
    );

    const checkIfApplicationExist = React.useCallback(() => {
        let isApplicationListExist = false;
        let allData: any[] = JSON.parse(JSON.stringify(applicationListData));

        allData.forEach((el: any) => {
            if (
                el[t("Designation")]?.toUpperCase() ===
                designation?.toUpperCase()
            ) {
                isApplicationListExist = true;
                return;
            }
        });

        return isApplicationListExist;
    }, [applicationListData, designation, t]);
    const handleOnCLickEvent2 = () => {
        setIsVisibiliteLedActive(!isVisibiliteLedActive);
        if (typeLed === "") {
            setTypeLed("COM1");
        }
        if (isVisibiliteLedActive) {
            setTypeLed("");
        }
    };
    async function RedisPublishPeripheral() {
        let redisApiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/peripheral/redis`;
        try {
            mutate(
                redisApiUrl,
                await fetch(redisApiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify({
                        shopId: oneShop ? shopID : selectedRows.shopId,
                    }),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                    })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
    }
    async function handleValidateButtonClickEvent() {
        if (isValidateButtonDisabled === false) {
            setIsValidateButtonDisabled(true);
            toast.dismiss();
            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application`;
            if (
                (selectedRows === undefined || selectedRows.length === 0) &&
                !oneShop
            ) {
                ErrorToast(`${t("Attention ! you must select a store.")!}`);
                setIsValidateButtonDisabled(false);
            } else {
                let existData: any = {
                    designation: designation,
                    ip: ip,
                    note: remark,
                    typeLed: typeLed,
                    isVisibiliteLedActive: isVisibiliteLedActive,
                };
                let NewData: any = {
                    designation: selectedRows
                        ? selectedRows[t("Designation")] !== undefined
                            ? selectedRows[t("Designation")]
                            : selectedRows[t("Application")]
                        : designation,
                    ip: selectedRows ? selectedRows[t("IP adress")] : ip,
                    note: selectedRows ? selectedRows[t("Remark")] : remark,
                    typeLed: selectedRows
                        ? selectedRows[t("typeLed")]
                        : typeLed,
                    isVisibiliteLedActive: selectedRows
                        ? selectedRows[t("isVisibiliteLedActive")] === "true"
                        : isVisibiliteLedActive,
                };
                if (isComingFromModify !== true) {
                    let arrayOfIds = oneShop ? [shopID] : shopIds;
                    let results: any = [];
                    if (arrayOfIds === undefined) {
                        arrayOfIds = [selectedRows?.shopId];
                    }
                    let id = v4();
                    if (arrayOfIds?.length > 0) {
                        arrayOfIds.forEach(async (element: any) => {
                            let savedData = {
                                userId: userID,
                                shopId: element,
                                franchiseId: franchiseID,
                                operatorId: operatorID,
                                uuidUser: uuidUser,
                                data: {
                                    [application]: {
                                        id_application: application,

                                        mark: "",
                                        note: "",
                                        status: "",
                                        state: "",
                                        designation: "",
                                        template: {},
                                        data_app: {
                                            [id]: {
                                                id_application_children: id,
                                                designation: designation,
                                                printer: {},
                                                monetic: {},
                                                cash_management: {},
                                                template: {},
                                                ip: ip,
                                                note: remark,
                                                status: "Offline",
                                                state: "To configure",
                                                isArchived: false,
                                                isVisibiliteLedActive: isVisibiliteLedActive,
                                                typeLed: typeLed,
                                            },
                                        },
                                    },
                                },
                            };
                            try {
                                mutate(
                                    apiUrl,
                                    await fetch(apiUrl, {
                                        headers: {
                                            "Content-Type": "application/json",
                                            authorization: `Bareer ${localStorage.getItem(
                                                "jwt"
                                            )}`,
                                        },
                                        body: JSON.stringify(savedData),
                                        method: "POST",
                                    })
                                        .then((response) => response.json())
                                        .then((data) => {
                                            if (data.error) {
                                                results.push("false");
                                            } else {
                                                results.push("true");
                                            }
                                            setLocalNamesApplications([
                                                ...localNamesApplications,
                                                designation,
                                            ]);
                                            setIps([...ips, ip]);
                                            setDesignation("");
                                            setRemark("");
                                            setIp("");
                                            setTypeLed("");
                                            setIsVisibiliteLedActive(false);
                                            setInputDesignation(false);
                                            setIsEdited(false);
                                            setGlobalDataIsUpdated(true);
                                            setDataIsUpdated(true);
                                            setIsValidateButtonDisabled(false);
                                        })
                                );
                            } catch (e: any) {
                                toast.error(`${t("There's an error")}!`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                });
                            }
                        });
                    }
                    if (results.some((element: any) => element === "false")) {
                        toast.error("Error while saving selection!");
                    } else {
                        toast.success(
                            `${t("The application was successfully added")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                    }
                } else {
                    if (JSON.stringify(existData) === JSON.stringify(NewData)) {
                        setIsConfirmationEditModalOpened(true);
                    } else {
                        let userId: number;
                        let shopId: string;
                        let franchiseId: string;
                        if (oneShop) {
                            userId = userID;
                            shopId = shopID.toString();
                            franchiseId = franchiseID;
                        } else {
                            userId = userID;
                            shopId = selectedRows.shopId;
                            franchiseId = franchiseID;
                        }
                        let savedData = {
                            userId: userId,
                            shopId: shopId,
                            franchiseId: franchiseId,
                            operatorId: operatorID,
                            uuidUser: uuidUser,
                            data: {
                                KIOSK: {
                                    id_application:
                                        selectedRows["key_application"] ===
                                        undefined
                                            ? selectedRows[t("Application")]
                                            : selectedRows["key_application"],
                                    note: "",
                                    status: "",
                                    state: "",
                                    designation: "",
                                    data_app: {
                                        [selectedRows["appId"]]: {
                                            id_application_children:
                                                selectedRows["appId"],
                                            designation: designation,
                                            state: "To configure",
                                            status: "Offline",
                                            ip: ip,
                                            note: remark,
                                            printer: {},
                                            monetic: {},
                                            cash_management: {},
                                            template: selectedRows.templateApp,
                                            isVisibiliteLedActive: isVisibiliteLedActive,
                                            typeLed: typeLed,
                                        },
                                    },
                                },
                            },
                        };
                        try {
                            mutate(
                                apiUrl,
                                await fetch(apiUrl, {
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: `Bareer ${localStorage.getItem(
                                            "jwt"
                                        )}`,
                                    },
                                    body: JSON.stringify(savedData),
                                    method: "PUT",
                                })
                                    .then((response) => response.json())
                                    .then((data) => {
                                        if (data.error) {
                                            throw Error("Error!");
                                        }
                                        toast.success(
                                            `${t(
                                                "The application was successfully changed"
                                            )!}`,
                                            {
                                                position:
                                                    toast.POSITION.TOP_CENTER,
                                                autoClose: 2000,
                                                theme: "colored",
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                icon: (
                                                    <CheckboxIcon
                                                        height={25}
                                                        width={25}
                                                        fill="white"
                                                    />
                                                ),
                                            }
                                        );
                                        setTimeout(
                                            RedisPublishPeripheral,
                                            3000
                                        );
                                        setIsAddAppClicked!(!isAddAppClicked);
                                        setIsEdited(false);
                                        setDataIsUpdated(true);
                                    })
                            );
                        } catch (e: any) {
                            toast.error(`${t("There's an error")!}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                            });
                        }
                    }
                }
            }
        }
    }

    const activateCheckbox = () => {
        let checks = document.getElementsByClassName("PrivateSwitchBase-input");
        for (let i = 0; i < checks.length; i++) {
            checks[i].classList.remove("not-allowed-icon-check__clz");
        }
    };
    useEffect(() => {
        setDesignation("");
        setApplication("KIOSK");
        setRemark("");
        setIp("");
        setIsVisibiliteLedActive(false);
        setTypeLed("");
        setInputDesignation(false);
        setExist(false);
        // eslint-disable-next-line
    }, [isAddNewAppButtonClicked]);
    useEffect(() => {
        if (isComingFromModify === true && selectedRows !== undefined) {
            setDesignation(
                selectedRows[t("Designation")] !== undefined
                    ? selectedRows[t("Designation")]
                    : selectedRows[t("Application")]
            );
            setApplication("KIOSK");
            setRemark(selectedRows[t("Remark")]);
            setIp(
                selectedRows[t("IP Address")] ?? selectedRows[t("IP address")]
            );
            setTypeLed(selectedRows[t("typeLed")]);
            setIsVisibiliteLedActive(
                typeof selectedRows[t("isVisibiliteLedActive")] === "string"
                    ? selectedRows[t("isVisibiliteLedActive")] === "true"
                    : selectedRows[t("isVisibiliteLedActive")]
            );
        }

        checkIfApplicationExist();

        // eslint-disable-next-line
    }, [t, isComingFromModify, selectedRows]);

    useEffect(() => {
        if (isComingFromModify === false || selectedRows === undefined) {
            setDesignation("");
            setApplication("KIOSK");
            setRemark("");
            setIp("");
            setInputDesignation(false);
            setTypeLed("");
            setIsVisibiliteLedActive(false);
            setExist(false);
        }
        // eslint-disable-next-line
    }, [t, isComingFromModify]);

    useEffect(() => {
        let isApplicationListExist = checkIfApplicationExist();
        if (
            isApplicationListExist &&
            (isComingFromModify !== true ||
                (isComingFromModify === true && selectedRowEl !== designation))
        ) {
            setIsApplicationListExist(true);
        } else {
            setIsApplicationListExist(false);
        }

        const localNamesApplications: string[] = [];

        if (oneShop === false && selectedRows !== undefined) {
            if (selectedRows.subRows !== undefined) {
                if (selectedRows.subRows.length > 0) {
                    selectedRows.subRows.forEach((datum: any) => {
                        localNamesApplications.push(datum[t("Application")]);
                    });
                }
            }
        } else {
            if (data?.data !== undefined && data.data?.length > 0) {
                data.data.forEach((datum: any) => {
                    localNamesApplications.push(datum[t("Application")]);
                });
            }
        }
        let shopId: string = oneShop
            ? shopID
            : selectedRows?.shopId !== undefined
            ? selectedRows.shopId
            : "";
        setLocalNamesApplications(localNamesApplications);
        setIps(
            resourceIp
                // eslint-disable-next-line
                .filter((x: any) => {
                    if (x.shopId === shopId) {
                        return x.ip;
                    }
                })
                .map((x: any) => {
                    return x.ip;
                })
        );

        let check: any = checkIpIfExist(
            resourceIp,
            ip,
            selectedRows !== undefined &&
                selectedRows[t("Designation")] !== undefined
                ? selectedRows[t("Designation")]
                : "",
            shopId
        );

        setExist((prevState: any) => ({
            ...prevState,
            isIpExist: check && selectedRows[t("IP address")] !== ip,
        }));
        // eslint-disable-next-line
    }, [oneShop, selectedRows, data, isComingFromModify, designation]);

    React.useEffect(() => {
        let shopId: string = oneShop
            ? shopID
            : selectedRows?.shopId !== undefined
            ? selectedRows.shopId
            : "";

        let resCheckName: any = resourceIp.filter((x: any) => {
            return selectedRows?.appId !== undefined
                ? x.type === "application-KIOSK" &&
                      x.shopId === shopId &&
                      x.designation !== selectedRows?.appId
                : x.type === "application-KIOSK" && x.shopId === shopId;
        });

        setIsApplicationListExist(
            isComingFromModify
                ? resCheckName.filter((x: any) => {
                      return (
                          x.designation.toUpperCase() ===
                              designation.toUpperCase() &&
                          x.designation.toUpperCase() !==
                              selectedRows[t("Application")].toUpperCase()
                      );
                  }).length > 0
                : resCheckName.filter((x: any) => {
                      return (
                          x.designation.toUpperCase() ===
                          designation.toUpperCase()
                      );
                  }).length > 0
        );
    }, [
        designation,
        selectedRows,
        isComingFromModify,
        oneShop,
        resourceIp,
        shopID,
        t,
    ]);
    let selectedRowEl: string =
        selectedRows === undefined ? "" : selectedRows[t("Designation")];

    return (
        <AvForm className="card">
            <CustomCardHeader
                className="d-flex align-items-center justify-content-between"
                style={{ maxHeight: "76px" }}
            >
                <h5 className="pt-3">
                    {isConsult
                        ? t("View an application")
                        : isComingFromModify === false
                        ? t("Add an application")
                        : t("Modify an application")}
                </h5>
                <CrossIcon
                    style={{ cursor: "pointer" }}
                    height={13}
                    width={13}
                    onClick={() => {
                        if (isEdited === true) {
                            setIsActionsClicked(!isActionsClicked);
                            setActions(() => {
                                activateCheckbox();
                                setIsAddAppClicked!(!isAddAppClicked);
                                setIsEdited(false);
                                setIsConsult(false);
                            });
                        } else {
                            activateCheckbox();
                            setIsConsult(false);
                            setIsAddAppClicked!(!isAddAppClicked);
                        }
                        setSelectedRows !== undefined && setSelectedRows([]);
                    }}
                />
            </CustomCardHeader>
            <CustomCardBody className="pl-3 pr-5">
                <AvGroup>
                    <StyledLabel htmlFor="name" className="required__clz mt-3">
                        {t("Designation")} (KIOSK1,KIOSK2...)
                    </StyledLabel>
                    <StyledTextInput
                        autocomplete="off"
                        className={classnames("afa_inp_designation", {
                            input__clz:
                                inputDesignation ||
                                (isApplicationListExist &&
                                    (selectedRowEl === undefined ||
                                        selectedRowEl !== designation)) ||
                                (designation.trim() === "" &&
                                    designation !== ""),
                            readOnly__clz: isConsult,
                        })}
                        id="designation"
                        name="designation"
                        placeholder={t("Write")}
                        type="text"
                        onChange={(e: any) => {
                            setDesignation(e.target.value);
                            if (setIsEdited !== undefined) setIsEdited!(true);
                            setInputDesignation(false);
                        }}
                        value={designation}
                        validate={{
                            pattern: {
                                value: matchWordDesignation,
                                errorMessage: t("Prohibited characters"),
                            },
                        }}
                        autoFocus={isConsult === false}
                    />
                    {inputDesignation ||
                    (designation.trim() === "" && designation !== "") ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("Please enter a designation")}
                        </div>
                    ) : null}
                    {isApplicationListExist &&
                    (selectedRowEl === undefined ||
                        selectedRowEl !== designation) ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("The name is already exists")!}
                        </div>
                    ) : null}
                </AvGroup>
                <AvGroup>
                    <StyledLabel htmlFor="example-input" className="mt-3">
                        {t("IP address")}
                    </StyledLabel>
                    <StyledTextInput
                        id="ip"
                        name="ip"
                        autocomplete="off"
                        placeholder={t("Write")}
                        type="text"
                        onChange={(e: any) => {
                            setIp(e.target.value);
                            if (setIsEdited !== undefined) setIsEdited!(true);

                            let shopId: string = oneShop
                                ? shopID
                                : selectedRows?.shopId !== undefined
                                ? selectedRows.shopId
                                : "";

                            let check: any = checkIpIfExist(
                                resourceIp,
                                e.target.value,
                                selectedRows?.appId !== undefined
                                    ? selectedRows?.appId
                                    : "",
                                shopId
                            );

                            setExist((prevState: any) => ({
                                ...prevState,
                                isIpExist:
                                    isComingFromModify === true &&
                                    selectedRows !== undefined
                                        ? check &&
                                          selectedRows[t("IP address")] !==
                                              e.target.value
                                        : check,
                            }));
                        }}
                        className={classnames("afa_inp_ipAddress", {
                            input__clz:
                                (!ip.match(ipMatchWord) && ip !== "") ||
                                (exist.isIpExist && ip !== ""),
                            readOnly__clz: isConsult,
                        })}
                        value={ip}
                    />

                    {exist.isIpExist && ip !== "" ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("The ip address is already exists")!}
                        </div>
                    ) : !ip.match(ipMatchWord) && ip !== "" ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("IP address is invalid")!}
                        </div>
                    ) : null}
                </AvGroup>
                <AvGroup>
                    <div className="d-flex justify-content-between">
                        {" "}
                        <StyledLabel
                            className="mt-3 required__clz"
                            htmlFor="text"
                        >
                            {t("Alert LED")}
                        </StyledLabel>
                        <div style={{ marginTop: "24px" }}>
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={isVisibiliteLedActive}
                                onChange={() => {
                                    handleOnCLickEvent2();
                                    if (setIsEdited !== undefined)
                                        setIsEdited!(true);
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={52}
                                height={20}
                                className={classnames("afa_icn_alertLed", {
                                    readOnly__clz: isConsult,
                                })}
                            />
                        </div>
                    </div>
                </AvGroup>

                <AvGroup>
                    <StyledSelectInput
                        className={classnames("afa_drp_alertLed", {
                            "not-allowed-input__clz": !isVisibiliteLedActive,
                            readOnly__clz: isConsult,
                        })}
                        value={{
                            label: typeLed,
                            value: typeLed,
                        }}
                        id="typeLed"
                        name="typeLed"
                        options={typeOfLed}
                        onChange={(e: any) => {
                            setTypeLed(e.value);

                            if (setIsEdited !== undefined) setIsEdited!(true);
                        }}
                        placeholder={t("Select")}
                        maxMenuHeight="80px"
                    />
                </AvGroup>
                <AvGroup>
                    <StyledLabel className="mt-3" htmlFor="remark">
                        {t("Remark")} (
                        {`${t("Left Kiosk")},${t("Right kiosk")}`})
                    </StyledLabel>
                    <StyledTextInput
                        autocomplete="off"
                        id="remark"
                        name="remark"
                        placeholder={t("Write")}
                        type="text"
                        onChange={(e: any) => {
                            setRemark(e.target.value);
                            if (setIsEdited !== undefined) setIsEdited!(true);
                        }}
                        value={remark}
                        className={classnames("afa_inp_remark", {
                            readOnly__clz: isConsult,
                        })}
                    />
                </AvGroup>
            </CustomCardBody>
            <CustomCardFooter className="pt-1 pb-2">
                <CustomSecondaryColorButton
                    outline
                    rounded
                    variant="light"
                    className="mr-2 afa_btn_FRcancel  cmn_btn_cancel"
                    onClick={() => {
                        if (isEdited === true) {
                            setIsActionsClicked(!isActionsClicked);
                            setActions(() => {
                                activateCheckbox();
                                setIsConsult(false);
                                setIsAddAppClicked!(!isAddAppClicked);
                                setIsEdited(false);
                            });
                        } else {
                            setIsConsult(false);
                            activateCheckbox();
                            setIsAddAppClicked!(!isAddAppClicked);
                            setIsEdited(false);
                        }
                        setSelectedRows !== undefined && setSelectedRows([]);
                    }}
                >
                    <span> {t("Cancel")}</span>
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    variant="primary"
                    onClick={() => {
                        if (designation === "") {
                            setInputDesignation(true);
                        }
                        if (
                            (ip.trim() !== "" ? ip.match(ipMatchWord) : true) &&
                            designation.length !== 0 &&
                            (!isApplicationListExist ||
                                selectedRowEl === designation) &&
                            designation.trim() !== "" &&
                            designation.match(matchWordDesignation) &&
                            (ip.trim() !== "" ? !exist.isIpExist : true)
                        )
                            handleValidateButtonClickEvent();
                        activateCheckbox();
                    }}
                    className={classnames(
                        "afa_btn_FRvalidate cmn_btn_validate",
                        {
                            readOnly__clz: isConsult,
                            "not-allowed-icon__clz": isConsult,
                        }
                    )}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </CustomCardFooter>
        </AvForm>
    );
}
