import React from "react";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { StyledH2, StyledLabel, StyledModal } from "@aureskonnect/react-ui";

import { setIsResetOneImageButtonClicked } from "@store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "./index.css";

type PropsType = {
    handleResetOnClickEvent(): void;
    localIsResetModalOpened: boolean;
    setLocalIsResetModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

export function ResetModalWrapper({
    handleResetOnClickEvent,
    localIsResetModalOpened,
    setLocalIsResetModalOpened,
}: PropsType): JSX.Element {
    const { t } = useTranslation();

    return (
        <StyledModal
            toggle={setLocalIsResetModalOpened}
            isOpen={localIsResetModalOpened}
            centered
            className="modal-dialog__clz"
        >
            <ModalHeader
                toggle={() => {
                    setLocalIsResetModalOpened(!localIsResetModalOpened);
                }}
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="d-flex flex-column m-0">
                    <span>
                        {t(
                            "All information (images, texts, commands) will be reset."
                        )}
                    </span>
                    <span>{t("Do you want to continue ?")}</span>
                </StyledLabel>
            </ModalBody>
            <ModalFooter>
                <CustomSecondaryColorButton
                    outline
                    rounded
                    className="mr-2"
                    variant="light"
                    onClick={() => {
                        setLocalIsResetModalOpened(!localIsResetModalOpened);
                    }}
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    variant="primary"
                    onClick={() => {
                        handleResetOnClickEvent();
                        setLocalIsResetModalOpened(!localIsResetModalOpened);
                        setIsResetOneImageButtonClicked(false);
                    }}
                    rounded={true}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
