import React from "react";
import { useTranslation } from "react-i18next";
import { StyledSelectInput } from "@aureskonnect/react-ui";
import classnames from "classnames";
import { useSnapshot } from "valtio";

import {
    setCodeBarcodeTicket,
    setCodeQrCode,
    setCodeCheckedBarcodeTicket,
    setCodeCheckedQrCode,
    storeDivers,
} from "./Hook/store";

import "../index.css";

export default function Code() {
    const { t } = useTranslation();
    const { codeBarcodeTicket } = useSnapshot(storeDivers);
    const { codeQrCode } = useSnapshot(storeDivers);
    const { codeCheckedBarcodeTicket } = useSnapshot(storeDivers);

    const { codeCheckedQrCode } = useSnapshot(storeDivers);
    const selectStyles = {
        valueContainer: () => ({
            display: "flex",
            alignItems: "center",
        }),
        menu: () => ({
            zIndex: 50,
            position: "fixed",
            backgroundColor: "#fff",
            width: "280px",
        }),
    };

    return (
        <div>
            <div className="d-flex  align-items-center flex-wrap p-2">
                <div className="d-flex  align-items-center justify-content-between">
                    <div>
                        <input
                            type="checkbox"
                            checked={codeCheckedBarcodeTicket}
                            onChange={(e: any) => {
                                setCodeCheckedBarcodeTicket(
                                    !codeCheckedBarcodeTicket
                                );
                                if(codeBarcodeTicket===""){
                                    setCodeBarcodeTicket('2')
                                }
                            }}
                        />
                        <label className=" mx-3 form-check-label">
                            {t("Barcode on ticket")}
                        </label>
                    </div>

                    <StyledSelectInput
                        styles={selectStyles}
                        value={{
                            label:
                                codeBarcodeTicket !== ""
                                    ? codeBarcodeTicket
                                    : "En bas de ticket",
                            value:
                                codeBarcodeTicket !== ""
                                    ? codeBarcodeTicket
                                    : "En haut de ticket",
                        }}
                        onChange={(e: any) => {
                            setCodeBarcodeTicket(e.value);
                        }}
                        options={[
                            {
                                label: "En bas de ticket",
                                value: "En bas de ticket",
                            },
                            {
                                label: "En haut de ticket",
                                value: "En haut de ticket",
                            },
                        ]}
                        className={`${classnames("input-styled__clz", {
                            "not-allowed-icon__clz": !codeCheckedBarcodeTicket,
                        })}`}
                    />
                </div>
            </div>
            <div className="d-flex  align-items-center flex-wrap p-2  ">
                <div className="d-flex  align-items-center justify-content-between">
                    <div>
                        <input
                            type="checkbox"
                            checked={codeCheckedQrCode}
                            onChange={(e: any) => {
                                setCodeCheckedQrCode(!codeCheckedQrCode);
                            }}
                        />
                        <label className=" mx-3 form-check-label">
                            {t("QR code")}
                        </label>
                    </div>
                    <StyledSelectInput
                        styles={selectStyles}
                        value={{
                            label: codeQrCode ? codeQrCode : "En bas de ticket",
                            value: codeQrCode ? codeQrCode : "En bas de ticket",
                        }}
                        onChange={(e: any) => {
                            setCodeQrCode(e.value);
                        }}
                        options={[
                            {
                                label: "En bas de ticket",
                                value: "En bas de ticket",
                            },
                            {
                                label: "En haut de ticket",
                                value: "En haut de ticket",
                            },
                        ]}
                        className={`${classnames("input-qr-styled__clz ml-2", {
                            "not-allowed-icon__clz": !codeCheckedQrCode,
                        })}`}
                    />
                </div>
            </div>
        </div>
    );
}


