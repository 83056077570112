import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel, CheckboxIcon } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useSnapshot } from "valtio";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { uuid } from "uuidv4";

import {
    store as generalSettingStore,
    setIsValidateArchiveOpened,
    setIsValidateShopArchiveOpened,
    setIsLoading,
    setKey,
} from "./store";
import { store } from "@components/VerticalLayout/store";

import { capitalize } from "@helpers/general";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { languages } from "@constants/index";
import ErrorToast from "@components/Common/ErrorTost";

import { generalConfigStore, setItemsDisable } from "@store";
type ConfirmationArchiveLanguageType = {
    selectedRows: any;
    setLimit:Function
};

export function ConfirmationArchiveLanguage({
    selectedRows,
    setLimit,
}: ConfirmationArchiveLanguageType) {
    const { t } = useTranslation();
    const {
        isValidateArchiveOpened,
        isValidateShopArchiveOpened,
    } = useSnapshot(generalSettingStore);
    const { userID, franchiseID, shopID, operatorID } = useSnapshot(store);
    const { itemsDisable } = useSnapshot(generalConfigStore);
    const [error, setError] = React.useState<boolean>(false);
    const uuidUser = localStorage.getItem("uuidUser");

    async function archiveFranchiseLanguage() {
        setIsLoading(true);
        toast.dismiss();
        setLimit(2);
        let localData: any = {};
        let cantArchive = false;
        selectedRows.forEach(async (el: any) => {
            if (el.isFavorite === false) {
                const id = el.Id;
                Object.assign(localData, {
                    [id]: {
                        id: id,
                        language: languages.find(
                            (lg: any) => lg.value === el.abbreviation
                        )?.label,
                        display_name: el.display_name,
                        country: el.country,
                        urlImage: el.url_image,
                        abbreviation: el.abbreviation,
                        isArchived: true,
                        shopId: el.shopId,
                        isFavorite: el.isFavorite,
                        isActive: false,
                        advancedDisplayName: el.advancedDisplayName,
                    },
                });
            } else {
                cantArchive = true;
            }
        });
        if (localData.length !== 0) {
            let dataLanguage = {
                userId: userID,
                shopId: [shopID],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                isShop: false,
                data: localData,
            };
            const data = new FormData();
            data.append("dataLanguage", JSON.stringify(dataLanguage));
            data.append("image", "");
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/language`;
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, {
                        method: "POST",
                        body: data,
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    "The language was successfully archived"
                                )}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setIsLoading(false);
                            setKey(uuid());
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
        if (cantArchive) {
            ErrorToast(
                t("Please note, you cannot archive a favorite language")
            );
            setIsLoading(false);
        }
    }

    const getArrayOfRows = () => {
        let arrayOfRows: any[] = [];
        let ids: any[] = [];
        selectedRows.forEach(async (el: any) => {
            if (el[t("Shop")] !== undefined) {
                el.subRows.forEach((elt: any) => {
                    if (ids.indexOf(`${elt.shopId}${elt.Id}`) === -1) {
                        arrayOfRows.push(elt);
                        ids.push(`${elt.shopId}${elt.Id}`);
                    }
                });
            } else {
                if (ids.indexOf(`${el.shopId}${el.Id}`) === -1) {
                    arrayOfRows.push(el);
                    ids.push(`${el.shopId}${el.Id}`);
                }
            }
        });
        return arrayOfRows;
    };

    async function archiveShopLanguage() {
        setIsLoading(true);
        toast.dismiss();
        let arrayOfRows = getArrayOfRows();
        let cantArchive = false;
        setLimit(2);
        for (let el of arrayOfRows) {
            if (el.isFavorite === false) {
                const id = el.Id;
                let dataLanguage = {
                    userId: userID,
                    shopId: [el.shopId],
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    isShop: true,
                    data: {
                        [id]: {
                            id: id,
                            language: languages.find(
                                (lg: any) => lg.value === el.abbreviation
                            )?.label,
                            display_name: el.display_name,
                            country: el.country,
                            urlImage: el.url_image,
                            abbreviation: el.abbreviation,
                            isArchived: true,
                            shopId: el.shopId,
                            isActive: false,
                            isFavorite: el.isFavorite,
                            advancedDisplayName: el.advancedDisplayName,
                        },
                    },
                };

                if (el.isActive === false) {
                    const item = itemsDisable.filter(
                        (item: any) => item.id !== el.Id
                    );
                    setItemsDisable(item);
                    localStorage.setItem("itemsDisable", JSON.stringify(item));
                }
                const data = new FormData();
                data.append("dataLanguage", JSON.stringify(dataLanguage));
                data.append("image", "");
                const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/language`;
                try {
                    mutate(
                        `${process.env.REACT_APP_API_V2_URL}/settings/general/language`,
                        await fetch(apiUrlAdd, {
                            method: "POST",
                            body: data,
                        }).then((response) => response.json())
                    );
                } catch (e) {
                    toast.error(`${t("There's an error")}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setIsLoading(false);
                    setError(true);
                }
            } else {
                cantArchive = true;
            }
        }
        if (error === false) {
            toast.success(`${t("The language was successfully archived")}`, {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
                icon: <CheckboxIcon height={25} width={25} fill="white" />,
            });
            setKey(uuid());
            setIsLoading(false);
        }
        if (cantArchive) {
            ErrorToast(
                t("Please note, you cannot archive a favorite language")
            );
            setIsLoading(false);
        }
    }

    return (
        <StyledModal
            toggle={true}
            isOpen={isValidateArchiveOpened || isValidateShopArchiveOpened}
            centered
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsValidateArchiveOpened(false);
                    setIsValidateShopArchiveOpened(false);
                }}
            >
                <span>{capitalize(t("Alert"))}</span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        "Attention, these language(s) can be used by other modules.\n Do you want to confirm the archiving of the selected language(s)"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <React.Fragment>
                    <CustomSecondaryColorButton
                        outline
                        variant="light"
                        onClick={() => {
                            setIsValidateArchiveOpened(false);
                            setIsValidateShopArchiveOpened(false);
                        }}
                        rounded
                    >
                        {t("Cancel")}
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        className="mr-2"
                        variant="primary"
                        onClick={() => {
                            isValidateArchiveOpened
                                ? archiveFranchiseLanguage()
                                : archiveShopLanguage();
                            setIsValidateArchiveOpened(false);
                            setIsValidateShopArchiveOpened(false);
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </React.Fragment>
            </ModalFooter>
        </StyledModal>
    );
}
