import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { cache } from "swr";
import { useTranslation } from "react-i18next/";
import {
    HeaderWrapper,
    MenuBurgerIcon,
    SettingsIcon,
    StyledH1,
    StyledIconButton,
} from "@aureskonnect/react-ui";
import classNames from "classnames";
import jwt_decode from "jwt-decode";
import { Tooltip } from "@mui/material";

import {
    setIsMobile,
    setCollapsed,
    setIsMobileBurgerButtonClicked,
    store,
} from "./store";
import { store as colorStore } from "@store";
import {
    store as appStore,
    setIsActionsClicked,
    setActions,
    setValidationAction,
} from "@store";

import { useCallbackPrompt } from "@hooks/useCallbackPrompt";
import LanguageDropdown from "@components/CommonForBoth/TopbarDropdown/LanguageDropdown";
import { HomeSvgIcon, ShopIconHeader } from "../Common/SvgIcons";
import ProfileMenu from "./ProfileMenu";

import "./layout.css";

export default function Header(): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { mainColor } = useSnapshot(colorStore);

    const {
        collapsed,
        isMobileBurgerButtonClicked,
        oneShop,
        isShopUser,
        isMobile,
    } = useSnapshot(store);
    const { isEdited } = useSnapshot(appStore);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isEdited
    );
    const [menu, setMenu] = React.useState<boolean>(false);
    const [name, setName] = React.useState<string>("");
    const [userFirstName, setUserFirstName] = React.useState<any>("");
    const [email, setEmail] = React.useState<any>("");
    const [userEmail, setUserEmail] = React.useState<any>("");
    const [userLastName, setUserLastName] = React.useState<any>("");

    const tokenUserTmp: any = localStorage.getItem("access_token_id");
    const location = useLocation();

    let matchCompteRoute = "/compte" === location.pathname;
    let matchTestAutoRoute = "/test-auto" === location.pathname;

    let testing = false;

    if (matchCompteRoute === true || matchTestAutoRoute === true) {
        testing = true;
    }

    function logout() {
        cache.clear();
        localStorage.clear();
        navigate("/login");
    }

    const cryptDecryptDataUser = React.useCallback(
        async (wordToDecrypt: string, stateSetter: typeof Function) => {
            return await fetch(
                `${process.env.REACT_APP_ACCESS_API_URL}/decryptWord`,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: `wordToDecrypted=${wordToDecrypt}`,
                    method: "POST",
                }
            )
                .then((result) => result.text())
                .then((response: any) => {
                    stateSetter(response);
                    return response;
                })
                .catch((error) => {
                    stateSetter("");
                    return "";
                });
        },
        []
    );

    React.useEffect(() => {
        if (window.innerWidth < 767) {
            setIsMobile(true);
        }

        const handleResize = () => {
            if (window.innerWidth < 767) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    React.useEffect(() => {
        if (isMobile === true) {
            setCollapsed(true);
        }
    }, [isMobile, collapsed, isMobileBurgerButtonClicked]);

    React.useEffect(() => {}, [oneShop]);

    React.useEffect(() => {
        if (tokenUserTmp != null) {
            let dataUserConnected: any = jwt_decode(tokenUserTmp);
            setUserFirstName(async () => {
                const firstName = await cryptDecryptDataUser(
                    dataUserConnected.sub.firstname,
                    setUserFirstName as typeof Function
                );
                return firstName;
            });
            setUserEmail(async () => {
                const email = await cryptDecryptDataUser(
                    dataUserConnected.sub.email,
                    setUserEmail as typeof Function
                );
                return email;
            });
            setUserLastName(async () => {
                const lastName = await cryptDecryptDataUser(
                    dataUserConnected.sub.name,
                    setUserLastName as typeof Function
                );
                return lastName;
            });
        } else {
            setUserFirstName(async () => {
                const firstNameLocalStorage: any = localStorage.getItem(
                    "firstName"
                );
                const firstName = await cryptDecryptDataUser(
                    firstNameLocalStorage,
                    setUserFirstName as typeof Function
                );

                return firstName;
            });
            setUserLastName(async () => {
                const lastNameLocalStorage: any =
                    localStorage.getItem("lastName") ?? "test";
                const lastName = await cryptDecryptDataUser(
                    lastNameLocalStorage,
                    setUserLastName as typeof Function
                );

                return lastName;
            });

            setUserEmail(async () => {
                const emailLocalStorage: any = localStorage.getItem("email");
                const email = await cryptDecryptDataUser(
                    emailLocalStorage,
                    setUserEmail as typeof Function
                );

                return email;
            });
        }
    }, [tokenUserTmp, cryptDecryptDataUser]);

    React.useEffect(() => {
        if (userFirstName !== "" && userLastName !== "") {
            const fullName = `${userFirstName} ${userLastName}`;
            setName(fullName);
        } else if (localStorage.getItem("authUser")) {
            if (
                process.env.REACT_APP_DEFAULTAUTH === "fake" ||
                process.env.REACT_APP_DEFAULTAUTH === "jwt"
            ) {
                const obj = JSON.parse(localStorage.getItem("authUser")!);
                setName(
                    `${
                        obj.firstname
                            ? `${obj.firstname} ${obj.lastname}`
                            : obj.username
                    }`
                );
            }
        }
        if (userEmail !== "" && typeof userEmail !== "object") {
            setEmail(userEmail);
        }
    }, [userEmail, userFirstName, userLastName]);

    React.useEffect(() => {
        setActions(confirmNavigation);
        setIsActionsClicked(true);
        setValidationAction(cancelNavigation);
    }, [showPrompt, confirmNavigation, cancelNavigation]);

    return (
        <HeaderWrapper className="header_wrapper__clz">
            <div className="navbar-header">
                <div className="d-flex align-items-center">
                    {collapsed ? (
                        <StyledH1
                            style={{ backgroundColor: mainColor }}
                            className="navbar-brand-box text-white d-flex justify-content-center brand__clz mb-0  styled_h1__clz"
                        >
                            <SettingsIcon
                                className=""
                                height={25}
                                width={25}
                                fill="white"
                            />
                        </StyledH1>
                    ) : (
                        <StyledH1
                            style={{ backgroundColor: mainColor }}
                            className="navbar-brand-box navbar-brand-box__clz text-white d-flex justify-content-center brand__clz mb-0 p-2 sid_txt_nameOfTheProject"
                        >
                            SETTINGS
                        </StyledH1>
                    )}

                    <div
                        className={classNames({
                            "d-none": testing,
                        })}
                    >
                        <div
                            className={classNames(
                                "d-flex justify-content-center align-items-center",
                                {
                                    "ml-2": collapsed === true,
                                    "ml-3": collapsed === false,
                                    "d-none": testing,
                                }
                            )}
                            style={{ columnGap: 10, cursor: "pointer" }}
                        >
                            <MenuBurgerIcon
                                fill={mainColor}
                                height={25}
                                width={25}
                                onClick={() => {
                                    if (isMobile === true) {
                                        setCollapsed(true);
                                        setIsMobileBurgerButtonClicked(
                                            !isMobileBurgerButtonClicked
                                        );
                                    } else setCollapsed(!collapsed);
                                }}
                                className="sid_btn_openClose"
                            />
                        </div>
                    </div>
                </div>

                <div className="d-flex align-items-center">
                    <LanguageDropdown />

                    <Tooltip title="">
                        <div>
                            <StyledIconButton
                                className="m-2 border-none__clz"
                                icon="ShopIcon"
                                onClick={(e: any) => {
                                    window.location.replace(
                                        `${process.env.REACT_APP_PORTAIL_URL}`
                                    );
                                }}
                            >
                                <HomeSvgIcon
                                    onClick={(e: any) => {
                                        window.location.replace(
                                            `${process.env.REACT_APP_PORTAIL_URL}`
                                        );
                                    }}
                                    height={25}
                                    width={25}
                                />
                            </StyledIconButton>
                        </div>
                    </Tooltip>
                    <Tooltip
                        className={classNames({ "d-none": isShopUser })}
                        title={(oneShop
                            ? t("Single store display")
                            : t("Multi-store display")
                        ).toString()}
                    >
                        <div>
                            <StyledIconButton
                                className="m-2 border-none__clz"
                                icon="ShopIcon"
                                onClick={(e: any) => {
                                    navigate("/compte");
                                }}
                            >
                                {oneShop ? (
                                    <ShopIconHeader height={25} width={25} />
                                ) : (
                                    <ShopIconHeader height={25} width={25} />
                                )}
                            </StyledIconButton>
                        </div>
                    </Tooltip>

                    <ProfileMenu
                        logout={logout}
                        menu={menu}
                        setMenu={setMenu}
                        name={name}
                        email={email}
                    />
                </div>
            </div>
        </HeaderWrapper>
    );
}
