import { proxy } from "valtio";

type StoreType = {
    isDataUpdated: boolean;
    key: any;
    isArchivedTemplateClicked: boolean;
    isActionsClicked: boolean;
    isEdited: boolean;
    actions: Function;
    validationAction: any;
};

const defaultStateStore = {
    isDataUpdated: false,
    key: 0,
    isArchivedTemplateClicked: false,
     isActionsClicked: false,
    isEdited: false,
    actions: () => { },
    validationAction: {},
};

const initialStateStore = defaultStateStore;
export const store = proxy<StoreType>(initialStateStore);

export function setDataIsUpdated(isDataUpdated: boolean): void {
    store.isDataUpdated = isDataUpdated;
}

export function setKey(key: number): void {
    store.key = key;
}
export function setIsArchivedTemplateClicked(isArchivedTemplateClicked: boolean): void {
    store.isArchivedTemplateClicked = isArchivedTemplateClicked;
}

export function setIsEdited(isEdited: boolean): any {
    store.isEdited = isEdited;
}

export function setIsActionsClicked(isActionsClicked: boolean): any {
    store.isActionsClicked = isActionsClicked;
}

export function setValidationAction(validationAction: any): any {
    store.validationAction = validationAction;
}

export function setActions(actions: any): any {
    store.actions = actions;
}
