import { proxy } from "valtio";

type StoreType = {
    path: string;
    selected: string;
    isUpdateOperation: boolean;
    isMultiDeletePeripheralModalOpened: boolean;
    isActionsClicked: boolean;
    isSettingModalOpened: boolean;
    isEdited: boolean;
    actions: Function;
    validationAction: any;
    modify: boolean;
    isSettingFromPrinter: boolean;
    isPrinterConfigOpened: boolean;
    dataIsUpdated: boolean;
    key: any;
    isLoading: boolean;
};

export const store = proxy<StoreType>({
    key: 0,
    isLoading: false,
    path: "",
    selected: "",
    isUpdateOperation: false,
    isMultiDeletePeripheralModalOpened: false,
    isActionsClicked: false,
    isSettingModalOpened: false,
    isEdited: false,
    actions: () => {},
    validationAction: {},
    modify: false,
    isSettingFromPrinter: false,
    isPrinterConfigOpened: false,
    dataIsUpdated: false,
});

export function setIsLoading(isLoading: boolean): void {
    store.isLoading = isLoading;
}

export function setKey(key: any): void {
    store.key = key;
}

export function setDataIsUpdated(value: boolean): void {
    store.dataIsUpdated = value;
}
export function setIsSettingFromPrinter(value: boolean): void {
    store.isSettingFromPrinter = value;
}
export function setIsPrinterConfigOpened(value: boolean): void {
    store.isPrinterConfigOpened = value;
}
export function setModify(value: boolean): void {
    store.modify = value;
}
export function setPath(value: string): void {
    store.path = value;
}

export function setSelected(value: string): void {
    store.selected = value;
}
export function setIsMultiDeletePeripheralModalOpened(
    isMultiDeletePeripheralModalOpened: boolean
): void {
    store.isMultiDeletePeripheralModalOpened = isMultiDeletePeripheralModalOpened;
}
export function setIsUpdateOperation(x: boolean): void {
    store.isUpdateOperation = x;
}

export function setIsSettingModalOpened(x: boolean): void {
    store.isSettingModalOpened = x;
}

export function setIsEdited(isEdited: boolean): any {
    store.isEdited = isEdited;
}

export function setIsActionsClicked(isActionsClicked: boolean): any {
    store.isActionsClicked = isActionsClicked;
}

export function setValidationAction(validationAction: any): any {
    store.validationAction = validationAction;
}

export function setActions(actions: any): any {
    store.actions = actions;
}

export default store;
