import React from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { StyledLabel, StyledSelectInput } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

type ValinaSwissConfigType = {
    setIdIntegrator: Function;
    setInputIdIntegrator: Function;
    setIsEdited: Function;
    idIntegrator: string;
    inputIdIntegrator: boolean;
    isConsult: boolean;
    setAutoValidate: Function;
    autoValidate: any;
    inputCurrency: boolean;
    setCurrency: Function;
    setInputCurrency: Function;
    currency: any;
    terminalType: string;
    inputTerminalType: boolean;
    setTerminalType: Function;
    setInputTerminalType: Function;
};

export function ValinaSwissConfig({
    setIdIntegrator,
    setInputIdIntegrator,
    setIsEdited,
    idIntegrator,
    inputIdIntegrator,
    isConsult,
    setAutoValidate,
    autoValidate,
    inputCurrency,
    setCurrency,
    setInputCurrency,
    currency,
    terminalType,
    inputTerminalType,
    setTerminalType,
    setInputTerminalType,
}: ValinaSwissConfigType) {
    const { t } = useTranslation();

    const [currencies, setCurrencies] = React.useState<any>([]);

    async function getCurrencies() {
        await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/currencies/symbol`
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    setCurrencies(
                        result.data.map((el: any) => {
                            return { label: el.key, value: el.Symbole };
                        })
                    );
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    React.useEffect(() => {
        getCurrencies();
    }, []);

    return (
        <React.Fragment>
            <AvForm>
                <AvGroup>
                    <StyledLabel
                        htmlFor="example-input"
                        className="required__clz mt-3"
                    >
                        {t("Terminal type")}
                    </StyledLabel>
                    <AvField
                        className={classnames({
                            input__clz: inputTerminalType,
                            readOnly__clz: isConsult,
                        })}
                        autocomplete="off"
                        id="terminalType"
                        name="terminalType"
                        placeholder={t("Write")}
                        type="text"
                        onChange={(e: any) => {
                            setTerminalType(e.target.value);
                            setInputTerminalType(false);
                            setIsEdited(true);
                        }}
                        value={terminalType}
                    />
                    {inputTerminalType && (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("Please enter an integrator id")}
                        </div>
                    )}
                </AvGroup>
                <AvGroup>
                    <StyledLabel
                        htmlFor="example-input"
                        className="required__clz mt-3"
                    >
                        {t("ID of integrator")}
                    </StyledLabel>
                    <AvField
                        className={classnames({
                            input__clz: inputIdIntegrator,
                            readOnly__clz: isConsult,
                        })}
                        autocomplete="off"
                        id="idIntegrator"
                        name="idIntegrator"
                        placeholder={t("Write")}
                        type="text"
                        onChange={(e: any) => {
                            setIdIntegrator(e.target.value);
                            setInputIdIntegrator(false);
                            setIsEdited(true);
                        }}
                        value={idIntegrator}
                    />
                    {inputIdIntegrator && (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("Please enter an integrator id")}
                        </div>
                    )}
                </AvGroup>
                <AvGroup>
                    <StyledLabel
                        htmlFor="example-input"
                        className="required__clz mt-3"
                    >
                        {t("Automatic validation")} (auto-commit)
                    </StyledLabel>
                    <StyledSelectInput
                        className={classnames({
                            readOnly__clz: isConsult,
                        })}
                        autocomplete="off"
                        id="AutoValidate"
                        name="AutoValidate"
                        type="text"
                        onChange={(e: any) => {
                            setAutoValidate(e);
                            setIsEdited(true);
                            setInputCurrency(false);
                        }}
                        options={[
                            {
                                label: t("True"),
                                value: t("True"),
                            },
                            {
                                label: t("False"),
                                value: t("False"),
                            },
                        ]}
                        value={autoValidate}
                        placeholder={`${t("Select")}…`}
                    />
                </AvGroup>
                <AvGroup>
                    <StyledLabel htmlFor="text" className="required__clz mt-3">
                        {t("Currency")}
                    </StyledLabel>
                    <StyledSelectInput
                        isClearable
                        className={classnames({
                            invalid__clz: inputCurrency,
                            readOnly__clz: isConsult,
                        })}
                        noOptionsMessage={() => t("No options")}
                        options={currencies}
                        autocomplete="off"
                        name="change"
                        onChange={(e: any) => {
                            if (e !== null) {
                                setCurrency(e.label);
                            } else {
                                setCurrency("");
                            }
                            setIsEdited(true);
                            setInputCurrency(false);
                        }}
                        value={
                            currency === ""
                                ? null
                                : {
                                      label: currency,
                                      value: currency,
                                  }
                        }
                        placeholder={`${t("Select")}…`}
                        type="text"
                    />
                    {inputCurrency ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("Please select a change")}
                        </div>
                    ) : null}
                </AvGroup>
            </AvForm>
        </React.Fragment>
    );
}
