import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledLabel,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { useSnapshot } from "valtio";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";

import { capitalize } from "@helpers/general";

import {
    setIsDataUpdated as setIsDataUpdatedShops,
    setIsEdited,
    setIsValidateClicked,
} from "../store";
import { store as logo } from "../store";
import { store } from "@components/VerticalLayout/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationModificationMessageType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setIsAddLogoClicked: Function;
    isAddLogoClicked: boolean;
    shopId: string[];
    setDataIsUpdated: Function;
    uploadedImage: string | Blob;
    salesSupport: any;
    rowData: any;
    associated: any;
};

export function ConfirmationModificationModal({
    isModalOpened,
    setIsModalOpened,
    setIsAddLogoClicked,
    isAddLogoClicked,
    shopId,
    setDataIsUpdated,
    uploadedImage,
    salesSupport,
    rowData,
    associated,
}: ConfirmationModificationMessageType) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const { salesSupports, shopsLogoData, editedDataLogo } = useSnapshot(logo);
    const [isSalesSupportEmpty, setIsSalesSupportEmpty] = useState<boolean[]>(
        []
    );
    const [error, setError] = React.useState<boolean>(false);
    const [isDataChanged, setIsDataChanged] = useState<boolean>(false);
     const uuidUser = localStorage.getItem("uuidUser");

    React.useEffect(() => {
        let allLogoShop: any = [];
        let localSalesSupport: boolean[] = [];
        if (shopsLogoData !== undefined) {
            shopsLogoData.forEach((el: any) => {
                if (el.subRows !== undefined)
                    el.subRows.forEach((element: any) => {
                        allLogoShop.push(element.shopId);
                    });
            });
        }
        salesSupports.forEach((el: any) => {
            if (allLogoShop.includes(el.id)) {
                let filteredSalesSupport: any = el.salesSupport.filter(
                    (value: any) => salesSupport.includes(value)
                );
                localSalesSupport.push(
                    filteredSalesSupport.length > 0 &&
                        salesSupport.split(",").length ===
                            filteredSalesSupport.length
                );
            }
            let localData: any = Object.values(editedDataLogo.data)[0];
           
            setIsDataChanged(
                rowData[t("Designation")] !== localData.designation ||
                    rowData.Image !== localData.urlImage
            );
        });

        setIsSalesSupportEmpty(localSalesSupport);
    }, [
        shopsLogoData,
        salesSupports,
        salesSupport,
        shopId,
        editedDataLogo.data,
        t,
        rowData,
    ]);

    async function editLogo() {
        toast.dismiss();

        let objectData = {};

        associated.forEach(async (el: any) => {
            Object.assign(objectData, {
                [el.Id]: {
                    designation: editedDataLogo.data[el.Id].designation,
                    tag: editedDataLogo.data[el.Id].tag,
                    support_de_vente: salesSupport,
                    isArchived: el.isArchived,
                    shopId: el.shopId,
                    isActive: el.isActive,
                    urlImage: editedDataLogo.data[el.Id].urlImage,
                    img: editedDataLogo.data[el.Id].img,
                },
            });

            let dataLogo = {
                userId: userID,
                shopId: [el.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                data: objectData,
            };

            const data = new FormData();
            data.append("dataLogo", JSON.stringify(dataLogo));
            data.append("image", uploadedImage);
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/logo`;
            const requestOptions = {
                method: "POST",
                body: data,
            };
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }

                            setDataIsUpdated(true);
                            setIsAddLogoClicked(!isAddLogoClicked);
                            setIsDataUpdatedShops(true);
                            setIsValidateClicked(true);
                            setIsEdited(false);
                            // clearData();
                            // clearMultiCustomSelectionData();
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setError(true);
            }
        });
        if (error === false) {
            if (!isSalesSupportEmpty.includes(false)) {
                toast.success(
                    `${t(
                        "The logo of the store(s) has been changed successfully"
                    )!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
            } else if (
                (isSalesSupportEmpty.includes(true) &&
                    isSalesSupportEmpty.includes(false)) ||
                (!isSalesSupportEmpty.includes(true) && isDataChanged)
            ) {
                toast.warn(
                    `${t(
                        "Please note that the modification could not be made at one or more stores.Please check the data settings of the store(s)"
                    )}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
                toast.success(
                    `${t(
                        "The logo of the store(s) has been changed successfully"
                    )!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
            } else if (!isSalesSupportEmpty.includes(true) && !isDataChanged) {
                toast.warn(
                    `${t(
                        "Please note that the modification could not be made at one or more stores.Please check the data settings of the store(s)"
                    )}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
            }
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => setIsModalOpened(!isModalOpened)}
            >
                <StyledH2>{capitalize(t("Alert"))}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        'Do you want to apply these changes for all stores? \n Attention ! If you click on "Validate", the specific settings of the shops will be modified.'
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                        setIsAddLogoClicked(!isAddLogoClicked);
                        setIsEdited(false);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        editLogo();
                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
