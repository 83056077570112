import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import {
    StyledLabel,
    StyledModal,
    StyledH2,
    PlusIcon,
    StyledIconButton,
    TrashIcon,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { IoAlarmOutline } from "react-icons/io5";

import { mutate } from "swr";
import { toast } from "react-toastify";
import classnames from "classnames";
import { useSnapshot } from "valtio";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    CustomCardBody,
    CustomCardFooter,
} from "@components/Common/CustomCardStyled";
import moment from "moment";
import { Input } from "antd";

import {
    setAssociatedDays,
    setFranchiseDataIsUpdated,
    setShopDataIsUpdated,
} from "../../store";
import { store } from "@components/VerticalLayout/store";
import { store as hoursStore } from "../../store";
import { ConfirmationModificationModal } from "./ConfirmationModificationModal";
import { ConfirmationModificationAssociatedHoursModal } from "./ConfirmationModificationAssociatedHoursModal";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";
import "./index.css";

type hoursSettingModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    selectedRows: any;
    day: any;
    setDay: Function;
    daysDesignations: string;
    setDaysDesignations: Function;
    setIsAllHoursEqual: Function;
    isConsult: boolean;
    setIsConsult: Function;
};

export default function HoursSettingModal({
    setIsModalOpened,
    isModalOpened,
    selectedRows,
    day,
    setDay,
    daysDesignations,
    setDaysDesignations,
    setIsAllHoursEqual,
    setIsConsult,
    isConsult,
}: hoursSettingModalType) {
    const { t } = useTranslation();
    const { userID, franchiseID, shopID, oneShop, operatorID } = useSnapshot(
        store
    );
    const { shopsHoursSettingData, associatedDays } = useSnapshot(hoursStore);
    const shopId = oneShop ? shopID : "0";
    const [
        isModificationModalOpened,
        setIsModificationModalOpened,
    ] = React.useState<boolean>(false);
    const [isValidateClicked, setIsValidateClicked] = React.useState<boolean>(
        false
    );
    const [isHourModified, setIsHourModified] = React.useState<boolean>(false);

    const [
        isHoursAssociatedModified,
        setIsHoursAssociatedModified,
    ] = React.useState<boolean>(false);

    const checkIfHoursOverLapped = (key: number, changedItem: string) => {
        let newDay = { ...day };
        if (changedItem === "openingHour")
            newDay.settingDay[key].overLappedStart = false;
        if (changedItem === "closingHour")
            newDay.settingDay[key].overLappedEnd = false;
        let isOverLapped = false;
        newDay.settingDay.map((element: any, key1: number) => {
            if (changedItem === "openingHour") {
                if (
                    element.openingHour !== "" &&
                    element.closingHour !== "" &&
                    (element.openingHour < element.closingHour
                        ? newDay.settingDay[key].openingHour >
                              element.openingHour &&
                          newDay.settingDay[key].openingHour <
                              element.closingHour
                        : (newDay.settingDay[key].openingHour >
                              element.openingHour &&
                              newDay.settingDay[key].openingHour >
                                  element.closingHour) ||
                          (newDay.settingDay[key].openingHour <
                              element.openingHour &&
                              newDay.settingDay[key].openingHour <
                                  element.closingHour))
                ) {
                    isOverLapped = true;
                }
            } else if (changedItem === "closingHour") {
                if (
                    element.openingHour !== "" &&
                    element.closingHour !== "" &&
                    (element.openingHour < element.closingHour
                        ? newDay.settingDay[key].closingHour >
                              element.openingHour &&
                          newDay.settingDay[key].closingHour <
                              element.closingHour
                        : (newDay.settingDay[key].closingHour >
                              element.openingHour &&
                              newDay.settingDay[key].closingHour >
                                  element.closingHour) ||
                          (newDay.settingDay[key].closingHour <
                              element.openingHour &&
                              newDay.settingDay[key].closingHour <
                                  element.closingHour))
                ) {
                    isOverLapped = true;
                }
            }
            if (isOverLapped === true) {
                if (changedItem === "closingHour")
                    newDay.settingDay[key].overLappedEnd = true;
                else if (changedItem === "openingHour")
                    newDay.settingDay[key].overLappedStart = true;
            }
            return true;
        });
        setDay(newDay);
    };
    const addHourToDay = () => {
        let newDay: any = { ...day };
        newDay.settingDay.push({
            openingHour: "",
            closingHour: "",
            stateHour: true,
            isArchived: false,
            overLappedStart: false,
            overLappedEnd: false,
            inputStartTime: false,
            inputEndTime: false,
            hoursNumber: 0,
        });
        setDay(newDay);
    };
    const deleteHourToDay = (key: any) => {
        let newDay: any = { ...day };
        newDay.settingDay.splice(key, 1);
        setDay(newDay);
    };
    const handleStateDayChange = () => {
        let newDay: any = { ...day };
        newDay.stateDay = !day.stateDay;
        setDay(newDay);
    };
    const handleStateHourChange = (key: number) => {
        let newDay: any = { ...day };
        newDay.settingDay[key].stateHour = !day.settingDay[key].stateHour;

        setDay(newDay);
    };

    const handleHoursChange = (changedItem: string, key: number, item: any) => {
        let newDay = { ...day };
        if (changedItem === "openingHour") {
            newDay.settingDay[key].openingHour = item;
            newDay.settingDay[key].inputStartTime = false;
        } else if (changedItem === "closingHour") {
            newDay.settingDay[key].closingHour = item;
            newDay.settingDay[key].inputEndTime = false;
        }

        setDay(newDay);
    };
    async function handleConfigureDayButtonClick() {
        let newDay = { ...day };
        let rows: any = [];
        selectedRows.forEach((element: any) => {
            if (element[t("Day of week")] !== "") {
                rows.push(element);
            }
        });
        if (
            newDay.settingDay.some((elt: any) => {
                return elt.openingHour === "" || elt.closingHour === "";
            })
        ) {
            newDay.settingDay.map((elt: any) => {
                if (elt.openingHour === "") {
                    elt.inputStartTime = true;
                }
                if (elt.closingHour === "") {
                    elt.inputEndTime = true;
                }
                setDay(newDay);
                return true;
            });
        } else if (
            newDay.settingDay.every((elt: any) => elt.stateHour === false)
        ) {
            toast.error(
                `${t(
                    "Attention, Impossible to validate an open day without activated schedule(s)."
                )!}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
        } else if (
            rows.length > 1 &&
            isModificationModalOpened === false &&
            isHourModified === false &&
            newDay.settingDay.every((elt: any) => {
                return (
                    elt.overLappedStart === false && elt.overLappedEnd === false
                );
            })
        ) {
            setIsModificationModalOpened(true);
        } else if (
            newDay.settingDay.every((elt: any) => {
                return (
                    elt.overLappedStart === false && elt.overLappedEnd === false
                );
            })
        ) {
            let hoursNumberDay = 0;
            let totalHours = 0;
            let totalMinutes = 0;
            newDay.settingDay.forEach((hour: any) => {
                let openingHour = moment(hour.openingHour, "hh:mm");
                let closingHour = moment(hour.closingHour, "hh:mm");
                hoursNumberDay = closingHour.diff(openingHour, "minutes");
                let hours = Math.floor(hoursNumberDay / 60);
                let minutes = hoursNumberDay % 60;
                hours = hours < 0 ? hours + 24 : hours;
                minutes = minutes < 0 ? minutes + 60 : minutes;
                totalHours = totalHours + hours;
                totalMinutes = totalMinutes + minutes;

                hour.hoursNumber = hours + "h" + minutes + "m";
                newDay.hoursNumber = totalHours + "h" + totalMinutes + "m";
            });
            newDay.settingDay = Object.assign({}, newDay.settingDay);
            let daysData = [];
            let idsAssociated: any = [];
            if (selectedRows.length > 1) {
                for (const elt of selectedRows) {
                    if (elt[t("Day of week")] !== "") {
                        shopsHoursSettingData.forEach((el: any) => {
                            el.subRows.forEach((element: any) => {
                                if (
                                    element.Id === elt["Id"] &&
                                    element.subRows.length > 0
                                ) {
                                    idsAssociated.push(element);
                                }
                            });
                        });
                        let dataDay = [
                            {
                                userId: userID,
                                shopId: oneShop ? [shopId, "0"] : [shopId],
                                franchiseId: franchiseID,
                                operatorId: operatorID,
                                day: elt["Id"],
                                data: {
                                    day: elt["Id"],
                                    designation: elt[t("Day of week")],
                                    stateDay: newDay.stateDay,
                                    hoursNumber: newDay.hoursNumber,
                                    settingDay: newDay.settingDay,
                                },
                            },
                        ];
                        daysData.push(dataDay);
                    }
                }
            } else {
                shopsHoursSettingData.forEach((el: any) => {
                    el.subRows.forEach((element: any) => {
                        if (
                            element.Id === day.day &&
                            element.subRows.length > 0
                        ) {
                            idsAssociated.push(element);
                        }
                    });
                });
                let dataDay = [
                    {
                        userId: userID,
                        shopId: oneShop ? [shopId, "0"] : [shopId],
                        franchiseId: franchiseID,
                        operatorId: operatorID,
                        day: day.day,
                        data: newDay,
                    },
                ];
                daysData.push(dataDay);
            }

            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/hour`;
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(daysData),
            };

            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                !oneShop && idsAssociated.length > 0
                                    ? `${t(
                                          "Franchise opening hours have been successfully configured"
                                      )!}`
                                    : `${t(
                                          "Opening hours have been configured successfully"
                                      )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                        })
                );

                if (!oneShop && idsAssociated.length > 0) {
                    setAssociatedDays(idsAssociated);
                    setIsHoursAssociatedModified(true);
                } else {
                    setFranchiseDataIsUpdated(true);
                    setShopDataIsUpdated(true);
                    setIsModalOpened(false);
                    setDay({});
                    setDaysDesignations("");
                    setIsAllHoursEqual(false);
                }
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }
    async function handleValidateClicked() {
        let newDay = { ...day };
        let hoursNumberDay = 0;
        let totalHours = 0;
        let totalMinutes = 0;
        newDay.settingDay.forEach((hour: any) => {
            let openingHour = moment(hour.openingHour, "hh:mm");
            let closingHour = moment(hour.closingHour, "hh:mm");
            hoursNumberDay = closingHour.diff(openingHour, "minutes");
            let hours = Math.floor(hoursNumberDay / 60);
            let minutes = hoursNumberDay % 60;
            hours = hours < 0 ? hours + 24 : hours;
            minutes = minutes < 0 ? minutes + 60 : minutes;
            totalHours = totalHours + hours;
            totalMinutes = totalMinutes + minutes;

            hour.hoursNumber = hours + "h" + minutes + "m";
            newDay.hoursNumber = totalHours + "h" + totalMinutes + "m";
        });
        newDay.settingDay = Object.assign({}, newDay.settingDay);
        let daysData = [];
        if (associatedDays.length > 0) {
            for (const elt of associatedDays) {
                if (elt[t("Day of week")] !== "") {
                    let dataDay = [
                        {
                            userId: userID,
                            shopId: [elt.shopId],
                            franchiseId: franchiseID,
                            operatorId: operatorID,
                            day: elt["Id"],
                            data: {
                                day: elt["Id"],
                                designation: elt[t("Day of week")],
                                stateDay: newDay.stateDay,
                                hoursNumber: newDay.hoursNumber,
                                settingDay: newDay.settingDay,
                            },
                        },
                    ];
                    daysData.push(dataDay);
                }
            }
        }
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/hour`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(daysData),
        };

        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "Shop opening hours have been configured successfully"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                    })
            );

            setFranchiseDataIsUpdated(true);
            setShopDataIsUpdated(true);
            setIsModalOpened(false);
            setDay({});
            setDaysDesignations("");
            setIsAllHoursEqual(false);
            setIsHoursAssociatedModified(false);
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }
    useEffect(() => {
        if (isHourModified) handleConfigureDayButtonClick();
        // eslint-disable-next-line
    }, [isHourModified]);
    useEffect(() => {
        if (isValidateClicked) handleValidateClicked();
        // eslint-disable-next-line
    }, [isValidateClicked]);

    return (
        <React.Fragment>
            <StyledModal
                toggle={true}
                isOpen={isModalOpened}
                centered
                className="modal-wrapper-hours__clz "
                backdrop="static"
                fade={false}
                scrollable
            >
                <ModalHeader
                    className="text-capitalize hov_icn_FRcloseForm"
                    toggle={() => {
                        setIsModalOpened(!isModalOpened);
                        setFranchiseDataIsUpdated(true);
                        setDay({});
                        setDaysDesignations("");
                        setIsAllHoursEqual(false);
                    }}
                >
                    <StyledH2 className="text-uppercase hov_txt_FRtimeSetting">
                        {t("schedule setting")}
                    </StyledH2>
                </ModalHeader>
                <ModalBody>
                    <fieldset className="border p-2 rounded">
                        <span className="d-flex justify-content-between">
                            <div>
                                {" "}
                                <StyledLabel
                                    className={`${classnames(
                                        "ml-2 hov_txt_FRdayOfTheWeek",
                                        {
                                            "not-allowed-icon__clz": !day.stateDay,
                                        }
                                    )}`}
                                >
                                    {daysDesignations !== ""
                                        ? `  ${t(
                                              "Days of week"
                                          )}:   ${daysDesignations}`
                                        : `  ${t("Day of week")}:  ${t(
                                              day.designation
                                          )}`}
                                </StyledLabel>
                            </div>
                            <div className="d-flex align-self-center">
                                <ReactSwitch
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    handleDiameter={20}
                                    offColor="#f7b4b8"
                                    offHandleColor="#E30613"
                                    checked={day.stateDay}
                                    onChange={handleStateDayChange}
                                    onColor="#c2eddd"
                                    onHandleColor="#34C38F"
                                    width={40}
                                    height={15}
                                    className={`${classnames(
                                        "pointer__clz ml-3 mt-1 hov_icn_FRdayState"
                                    )}`}
                                />
                                <StyledLabel className="ml-3">
                                    {day.stateDay ? t("Opened") : t("Closed")}
                                </StyledLabel>
                            </div>
                        </span>
                    </fieldset>

                    <fieldset
                        className="border p-2"
                        style={{ height: "500px", overflow: "scroll" }}
                    >
                        <CustomCardBody
                            style={{
                                overflowX: "hidden",
                                overflowY: "auto",
                                height: "85%",
                                border: "none",
                                width: "100%",
                            }}
                        >
                            <div>
                                {day.settingDay.map((elt: any, key: number) => (
                                    <div className="d-flex justify-content-between ">
                                        <div className="mr-3">
                                            <StyledLabel
                                                htmlFor="example-input"
                                                className={`${classnames(
                                                    "required__clz mt-3",
                                                    {
                                                        "not-allowed-icon__clz": !day.stateDay,
                                                    }
                                                )}`}
                                            >
                                                {t("Opening hour")}
                                            </StyledLabel>
                                            <Input
                                                id="example-input"
                                                name="example-input"
                                                placeholder="Insert text"
                                                type="time"
                                                onChange={(e: any) => {
                                                    handleHoursChange(
                                                        "openingHour",
                                                        key,
                                                        e.target.value
                                                    );

                                                    day.settingDay.map(
                                                        (
                                                            el: any,
                                                            key1: number
                                                        ) => {
                                                            if (
                                                                el.openingHour !==
                                                                ""
                                                            )
                                                                checkIfHoursOverLapped(
                                                                    key1,
                                                                    "openingHour"
                                                                );
                                                            if (
                                                                el.closingHour !==
                                                                ""
                                                            )
                                                                checkIfHoursOverLapped(
                                                                    key1,
                                                                    "closingHour"
                                                                );

                                                            return true;
                                                        }
                                                    );
                                                }}
                                                value={elt.openingHour}
                                                addonBefore={<IoAlarmOutline />}
                                                className={classnames(
                                                    "hov_drp_FRhourlyOpening",
                                                    {
                                                        invalid__clz:
                                                            elt.overLappedStart,
                                                    }
                                                )}
                                                disabled={!day.stateDay}
                                            />
                                            {elt.inputStartTime ? (
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        marginTop: "0.25rem",
                                                        fontSize: "80%",
                                                        color: "#f46a6a",
                                                    }}
                                                >
                                                    {t("Please choose a time")}
                                                </div>
                                            ) : null}
                                            {elt.overLappedStart ? (
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        marginTop: "0.25rem",
                                                        fontSize: "80%",
                                                        color: "#f46a6a",
                                                    }}
                                                >
                                                    {t("Chevauchement detecté")}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div>
                                            <StyledLabel
                                                htmlFor="example-input"
                                                className={`${classnames(
                                                    "required__clz mt-3",
                                                    {
                                                        "not-allowed-icon__clz": !day.stateDay,
                                                    }
                                                )}`}
                                            >
                                                {t("Closing hour")}
                                            </StyledLabel>
                                            <Input
                                                id="example-input"
                                                name="example-input"
                                                placeholder="Insert text"
                                                type="time"
                                                onChange={(e: any) => {
                                                    handleHoursChange(
                                                        "closingHour",
                                                        key,
                                                        e.target.value
                                                    );

                                                    day.settingDay.map(
                                                        (
                                                            el: any,
                                                            key1: number
                                                        ) => {
                                                            if (
                                                                el.openingHour !==
                                                                ""
                                                            )
                                                                checkIfHoursOverLapped(
                                                                    key1,
                                                                    "openingHour"
                                                                );
                                                            if (
                                                                el.closingHour !==
                                                                ""
                                                            )
                                                                checkIfHoursOverLapped(
                                                                    key1,
                                                                    "closingHour"
                                                                );

                                                            return true;
                                                        }
                                                    );
                                                }}
                                                value={elt.closingHour}
                                                addonBefore={<IoAlarmOutline />}
                                                className={classnames(
                                                    "hov_drp_FRclosingTime",
                                                    {
                                                        invalid__clz:
                                                            elt.overLappedEnd,
                                                    }
                                                )}
                                                disabled={!day.stateDay}
                                            />
                                            {elt.inputEndTime ? (
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        marginTop: "0.25rem",
                                                        fontSize: "80%",
                                                        color: "#f46a6a",
                                                    }}
                                                >
                                                    {t("Please choose a time")}
                                                </div>
                                            ) : null}
                                            {elt.overLappedEnd ? (
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        marginTop: "0.25rem",
                                                        fontSize: "80%",
                                                        color: "#f46a6a",
                                                    }}
                                                >
                                                    {t("Chevauchement detecté")}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div
                                            style={{
                                                marginTop: "38px",
                                                marginLeft: "10px",
                                            }}
                                        >
                                            <ReactSwitch
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                handleDiameter={20}
                                                offColor="#f7b4b8"
                                                offHandleColor="#E30613"
                                                checked={
                                                    day.settingDay[key]
                                                        .stateHour
                                                }
                                                onChange={(e: any) => {
                                                    handleStateHourChange(key);
                                                }}
                                                onColor="#c2eddd"
                                                onHandleColor="#34C38F"
                                                width={40}
                                                height={15}
                                                className={`${classnames(
                                                    "pointer__clz mt-3 hov_icn_FRtimeState"
                                                )}`}
                                                disabled={!day.stateDay}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                marginTop: "32px",
                                            }}
                                        >
                                            <StyledIconButton
                                                rounded
                                                disabled={
                                                    !day.stateDay || key === 0
                                                }
                                                onClick={() =>
                                                    deleteHourToDay(key)
                                                }
                                                height={25}
                                                width={25}
                                                className="hov_icn_FRarchived"
                                            >
                                                <TrashIcon
                                                    height={20}
                                                    width={20}
                                                    fill="red"
                                                />
                                            </StyledIconButton>
                                        </div>
                                    </div>
                                ))}{" "}
                            </div>
                        </CustomCardBody>
                        <CustomCardFooter
                            style={{
                                height: "15%",
                                width: "100%",
                                border: "none",
                            }}
                        >
                            <CustomMainColorIconButton
                                rounded
                                variant="primary"
                                onClick={addHourToDay}
                                style={{
                                    width: "100%",
                                }}
                                disabled={!day.stateDay}
                                className="cmn_btn_openForm"
                            >
                                <div>
                                    <PlusIcon
                                        height={15}
                                        width={15}
                                        fill="white"
                                        className="mr-3"
                                    />
                                </div>
                                {t("Add hour")}
                            </CustomMainColorIconButton>
                        </CustomCardFooter>
                    </fieldset>
                </ModalBody>
                <div className="border-footer__clz" />
                <ModalFooter>
                    <CustomSecondaryColorButton
                        outline
                        variant="light"
                        onClick={() => {
                            setIsModalOpened(!isModalOpened);
                            setFranchiseDataIsUpdated(true);
                            setDay({});
                            setDaysDesignations("");
                            setIsAllHoursEqual(false);
                            setIsConsult(false);
                        }}
                        rounded
                        className="hov_btn_FRcancel cmn_btn_cancel"
                    >
                        {t("Cancel")}
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        onClick={() => {
                            handleConfigureDayButtonClick();
                        }}
                        className={`hov_btn_FRvalidate cmn_btn_validate ${classnames(
                            { "not-allowed-input-vr__clz": isConsult }
                        )}`}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </ModalFooter>
            </StyledModal>

            {isModificationModalOpened ? (
                <ConfirmationModificationModal
                    isModalOpened={isModificationModalOpened}
                    setIsModalOpened={setIsModificationModalOpened}
                    setIsHourModified={setIsHourModified}
                />
            ) : null}
            {isHoursAssociatedModified && (
                <ConfirmationModificationAssociatedHoursModal
                    isModalOpened={isHoursAssociatedModified}
                    setIsModalOpened={setIsHoursAssociatedModified}
                    setIsValidateClicked={setIsValidateClicked}
                />
            )}
        </React.Fragment>
    );
}
