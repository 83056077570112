import { StyledH2 } from "@aureskonnect/react-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { Card, Input, Label } from "reactstrap";

import City from "./City";
import Zone from "./Zone";

export default function Delivery({ selectedRows }: any) {
    const { t } = useTranslation();
    let dataLivraison = selectedRows[0].length>0 && JSON.parse(selectedRows[0][t("minimum price")]);

    const [compositeProducts, setCompositeProducts] = React.useState<string>(
        dataLivraison.LivraisonVille === true &&
            dataLivraison.Livraisonzone === false
            ? `${t("Delivery management by city")}`
            : (dataLivraison.LivraisonVille === false &&
                  dataLivraison.Livraisonzone === true) ||
              (dataLivraison.LivraisonVille === true &&
                  dataLivraison.Livraisonzone === true)
            ? `${t("Delivery management by zone (in kilometers)")}`
            : `${t("Delivery management by city")}`
    );

    return (
        <React.Fragment>
            <StyledH2
                style={{
                    font: "normal normal 600 30px/41px Nunito Sans",
                    color: "#000000",
                    opacity: "1",
                }}
                className="ml-3 mt-2"
            >
                {t("delivery management")}
            </StyledH2>
            <Card
                className="ml-1"
                style={{ height: "75px", borderRightWidth: "unset" }}
            >
                <div className="d-flex flex-column p-1 ml-1">
                    <div className="d-flex justify-content-between p-1 mt-2">
                        <span
                            className="ml-1"
                            style={{
                                fontSize: "13px",
                            }}
                        >
                            {t("Delivery management by city")}
                        </span>
                        <Label>
                            <Input
                                type="radio"
                                name="radio1"
                                value={compositeProducts}
                                defaultChecked={
                                    compositeProducts ===
                                    `${t("Delivery management by city")}`
                                        ? true
                                        : false
                                }
                                onClick={(e: any) => {
                                    setCompositeProducts(
                                        `${t("Delivery management by city")}`
                                    );
                                }}
                                onCheckColor={"#6F763F"}
                            />
                        </Label>
                    </div>
                    <div className="d-flex justify-content-between p-1 mt-1">
                        <span
                            className="ml-1"
                            style={{
                                fontSize: "13px",
                            }}
                        >
                            {t("Delivery management by zone (in kilometers)")}
                        </span>
                        <Label>
                            <Input
                                type="radio"
                                name="radio1"
                                defaultChecked={
                                    compositeProducts ===
                                    `${t(
                                        "Delivery management by zone (in kilometers)"
                                    )}`
                                        ? true
                                        : false
                                }
                                value={compositeProducts}
                                className="Cgt_chk_definiteTime"
                                onClick={(e: any) => {
                                    setCompositeProducts(
                                        `${t(
                                            "Delivery management by zone (in kilometers)"
                                        )}`
                                    );
                                }}
                                onCheckColor={"#6F763F"}
                            />
                        </Label>
                    </div>
                </div>
            </Card>
            {compositeProducts === `${t("Delivery management by city")}` ? (
                <Card className="ml-1" style={{ borderRightWidth: "unset" }}>
                    <City selectedRowShops={selectedRows} />
                </Card>
            ) : null}
            {compositeProducts ===
            `${t("Delivery management by zone (in kilometers)")}` ? (
                <Card className="ml-1" style={{ borderRightWidth: "unset" }}>
                    <Zone selectedRowShops={selectedRows} />
                </Card>
            ) : null}
        </React.Fragment>
    );
}
