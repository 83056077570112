import React from "react";
import { StyledH2 } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";

import { capitalize } from "../../../helpers/general";
import { resetStore } from "../../../pages/Applications/DeviceAssociateModal/store";

import { store } from "../../../components/VerticalLayout/store";
import { store as storeData } from "./store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import classnames from "classnames";

export default function HistoryModal({ isModalOpened, setIsModalOpened }: any) {
    const { t } = useTranslation();

    const { selectedLogo } = useSnapshot(storeData);
    const { franchiseID, userID } = useSnapshot(store);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [, setArrayIds] = React.useState<any[]>([]);
    const [data, setData] = React.useState<any>({});
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);

    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };

    const url = `${
        process.env.REACT_APP_API_V2_URL
    }/settings/general/logo/history?userId=${userID}&franchiseId=${franchiseID}&logo=${selectedLogo
        .map((el: any) => JSON.stringify(el))
        .join()}`;

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: t("Actions"),
            customJsx: (e: any) => {
                let actionsArray: any = [];
                e.selectedRow.actions.forEach((el: any) => {
                    if (el.item !== "isActive") {
                        if (el.item === "designation") {
                            actionsArray.push(t("Designation"));
                        } else if (el.item === "support_de_vente") {
                            actionsArray.push(t("Sale support"));
                        } else if (el.item === "Image") {
                            actionsArray.push(t("Image"));
                        }
                    }
                });
                if (e.selectedRow.operation === "modify") {
                    if (e.selectedRow?.actions[0].item === "isActive") {
                        return e.selectedRow?.actions[0].newValue === true ? (
                            <span>{t("Activation")}</span>
                        ) : (
                            <span>{t("Deactivation")}</span>
                        );
                    } else if (
                        e.selectedRow?.actions[0].item === "isArchived"
                    ) {
                        return e.selectedRow?.actions[0].newValue === true ? (
                            <span>{t("Archiving")}</span>
                        ) : (
                            <span>{t("Unarchiving")}</span>
                        );
                    }
                    return (
                        <span>
                            {t("Modification")}: {actionsArray.join(",")}
                        </span>
                    );
                } else if (e.selectedRow.operation === "creation") {
                    return <span>{t("Creation")}</span>;
                } else if (e.selectedRow.operation === "Affectation") {
                    return <span>{`${t("Assignment")}`}</span>;
                } else {
                    return <span>{t("Reassignment")}</span>;
                }
            },
        },

        {
            indexOFColumn: 3,
            columnName: t("Before modification"),
            customJsx: (e: any) => {
                if (e.selectedRow.operation === "modify") {
                    return (
                        <span>
                            {e.selectedRow?.actions[0]?.item ===
                            "isArchived" ? (
                                e.selectedRow?.actions[0]?.oldValue === true ? (
                                    <span>
                                        {t("Status")}: {t("Archived")}
                                    </span>
                                ) : (
                                    <span>
                                        {t("Status")}: {t("Unarchived")}
                                    </span>
                                )
                            ) : e.selectedRow.actions[0]?.item ===
                              "isActive" ? (
                                e.selectedRow.actions[0]?.oldValue === true ? (
                                    <span>
                                        {t("State")}:{t("Activated")}
                                    </span>
                                ) : (
                                    <span>
                                        {t("State")}:{t("Deactivated")}
                                    </span>
                                )
                            ) : (
                                <span>
                                    {e.selectedRow &&
                                        e.selectedRow.actions.map(
                                            (el: any, key: number) => {
                                                if (
                                                    el.item !== "Image" &&
                                                    el.item !== "img"
                                                ) {
                                                    return (
                                                        <span>
                                                            {el.oldValue}
                                                            {key !==
                                                                e.selectedRow
                                                                    .actions
                                                                    .length -
                                                                    1 && ","}
                                                        </span>
                                                    );
                                                }
                                                return (
                                                    <span>
                                                        <img
                                                            src={el.oldValue}
                                                            alt="no data"
                                                            height="25px"
                                                            width="35px"
                                                        />
                                                        {key !==
                                                            e.selectedRow
                                                                .actions
                                                                .length -
                                                                1 && ","}
                                                    </span>
                                                );
                                            }
                                        )}
                                </span>
                            )}
                        </span>
                    );
                } else if (e.selectedRow.operation === "creation") {
                    return null;
                } else if (e.selectedRow.operation === "Affectation") {
                    return <span>{""}</span>;
                } else {
                    return <span>{""}</span>;
                }
            },
        },
        {
            indexOFColumn: 4,
            columnName: t("After modification"),
            customJsx: (e: any) => {
                if (e.selectedRow.operation === "modify") {
                    return (
                        <span>
                            {e.selectedRow.actions[0].item === "isArchived" ? (
                                e.selectedRow.actions[0].newValue === true ? (
                                    <span>
                                        {t("Status")}: {t("Archived")}
                                    </span>
                                ) : (
                                    <span>
                                        {t("Status")}: {t("Unarchived")}
                                    </span>
                                )
                            ) : e.selectedRow.actions[0].item === "isActive" ? (
                                e.selectedRow.actions[0].newValue === true ? (
                                    <span>
                                        {t("State")}:{t("Activated")}
                                    </span>
                                ) : (
                                    <span>
                                        {t("State")}:{t("Deactivated")}
                                    </span>
                                )
                            ) : (
                                <span>
                                    {e.selectedRow &&
                                        e.selectedRow.actions.map(
                                            (el: any, key: number) => {
                                                if (el.item !== "Image") {
                                                    return (
                                                        <span>
                                                            {el.newValue}
                                                            {key !==
                                                                e.selectedRow
                                                                    .actions
                                                                    .length -
                                                                    1 && ","}
                                                        </span>
                                                    );
                                                }
                                                return (
                                                    <span>
                                                        <img
                                                            src={el.newValue}
                                                            alt="no data"
                                                            height="25px"
                                                            width="35px"
                                                        />
                                                        {key !==
                                                            e.selectedRow
                                                                .actions
                                                                .length -
                                                                1 && ","}
                                                    </span>
                                                );
                                            }
                                        )}
                                </span>
                            )}
                        </span>
                    );
                } else if (e.selectedRow.operation === "creation") {
                    return e.selectedRow[t("Designation")] !== undefined
                        ? e.selectedRow[t("Designation")]
                        : "--";
                } else if (e.selectedRow.operation === "Affectation") {
                    return <span>{""}</span>;
                } else {
                    return <span>{""}</span>;
                }
            },
        },
    ];

    React.useEffect(() => {
        setArrayIds(selectedLogo.map((el) => el.Id));
    }, [selectedLogo]);

    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                className="modal-wrapper__clz"
                isOpen={isModalOpened}
            >
                <ModalHeader
                    className="lgo_icn_HSclose"
                    toggle={() => {
                        setIsModalOpened!(!isModalOpened);
                        resetStore();
                    }}
                >
                    <StyledH2 className="lgo_txt_HStitleHistory">
                        {capitalize(t("Historical"))}
                    </StyledH2>
                </ModalHeader>

                <ModalBody>
                    <div
                        className={`${classnames("title__clz", {
                            "not-allowed-icon__clz": selectedLogo.length > 1,
                        })}`}
                    >
                        {t("Logo")} :{" "}
                        {selectedLogo.length > 1
                            ? null
                            : data?.data !== undefined
                            ? data?.data![0][t("Designation")]
                            : ""}
                    </div>
                    <DynamicTable
                        url={url}
                        canResize
                        actionColumn={ActionColumn}
                        showGlobalFilter
                        showFilter
                        requestHeader={{ "Accept-Language": i18nextLng }}
                        setLocalFilterActive={setLocalFilterActive}
                        filterActive={filterActive}
                        setDataIsUpdated={setDataIsUpdated}
                        dataIsUpdated={dataIsUpdated}
                        elevationTable={1}
                        minHeight="500px"
                        maxHeight="500px"
                        name="historyLogo"
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        setData={setData}
                    />
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        className="lgo_btn_HSclose"
                        onClick={() => setIsModalOpened(!isModalOpened)}
                    >
                        {t("Close")}
                    </CustomMainColorButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
