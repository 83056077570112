import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { Card } from "reactstrap";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";

import {
    generalConfigStore,
    setGeneralDesignColor,
    setGeneralDesignFonts,
    setProjectFiles,
    setPRM,
    setLogo,
    designStore,
} from "@store";

import { ColorPickerWrapper } from "@components/ColorPickerWrapper";
import { ResetModalWrapper } from "@components/Common/ResetModalWrapper";
import { CustomErrorBoundary } from "@components/CustomErrorBoundary";

import { LogoWrapper } from "./LogoWrapper";

import { PrmWrapper } from "./PrmWrapper";

export function GeneralDesignTabs(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: { template, files: images },
        isResetModalOpened,
        initItemsFromGeneralSetting,
    } = useSnapshot(generalConfigStore);

    const { selectedLanguage } = useSnapshot(designStore);

    const [colors, setColors] = React.useState<ColorsContentType[]>(
        (template.content.generalDesign as GeneralDesignType).colors
    );
    const [
        localIsResetModalOpened,
        setLocalIsResetModalOpened,
    ] = React.useState<boolean>(isResetModalOpened);
    const [localPrm, setLocalPrm] = React.useState<GeneralDesignPrmType>(
        (template.content.generalDesign as GeneralDesignType).prm
    );

    async function handleResetOnClickEvent() {
        const initLogo = initItemsFromGeneralSetting.logo;

        setLogo(initLogo);

        const imagesArray1 = [...images];

        const imageLogo = imagesArray1.find(
            (element) => element.key === "Logo" && element.id === initLogo.id
        );

        if (imageLogo !== undefined) {
            const imageIndex = imagesArray1.indexOf(imageLogo);

            imagesArray1[imageIndex] = {
                ...imageLogo,

                content: imagesArray1[imageIndex].defaultImage as string,
            };
        }

        const initialColors = colors.map((item: ColorsContentType) => {
            return {
                ...item,

                content: item.defaultContent,
            };
        });

        setGeneralDesignColor(initialColors);

        setColors(initialColors);

        const initialFonts = ((template.content
            .generalDesign as GeneralDesignType)
            .fonts as GeneralDesignFontType[]).map(
            (item: GeneralDesignFontType) => {
                return {
                    ...item,

                    font: item.defaultFont,

                    size: item.defaultSize,
                };
            }
        );

        setGeneralDesignFonts(initialFonts);

        const imagesArray = [...imagesArray1];

        const image = imagesArray.find((element) => element.key === "Logo");

        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);

            imagesArray[imageIndex] = {
                ...image,

                content: imagesArray[imageIndex].defaultImage as string,
            };
        }

        const templateFilesArray = [...imagesArray];

        const file = templateFilesArray.find(
            (element) => element.id === localPrm.id
        );

        if (file !== undefined) {
            const fileIndex = templateFilesArray.indexOf(file);

            templateFilesArray[fileIndex] = {
                ...file,
                content: templateFilesArray[fileIndex].defaultImage as string,
            };

            setLocalPrm(
                (prevState: GeneralDesignPrmType): GeneralDesignPrmType => {
                    const newState = { ...prevState };

                    if (newState.id === localPrm.id) {
                        return {
                            ...newState,

                            content: templateFilesArray[fileIndex]
                                .content as string,

                            type: templateFilesArray[fileIndex].type as string,
                        };
                    }

                    setPRM(newState);

                    return newState;
                }
            );
        }

        const usualOptionItem = (template.content
            .options as OptionsType).items.filter(
            (item: OptionsItemType) => item.title === "Usual"
        )[0];

        const templateFilesArray1 = [...templateFilesArray];
        const usualImage = templateFilesArray1.find(
            (element) => element.id === usualOptionItem.id
        );

        if (usualImage !== undefined) {
            const usualImageIndex = templateFilesArray1.indexOf(usualImage);
            const localContent = usualImage.content as ImageItemLanguagesType;

            templateFilesArray1[usualImageIndex] = {
                ...usualImage,

                content: {
                    ...localContent,
                    [selectedLanguage as string]: {
                        ...localContent[selectedLanguage as string],
                        path: localContent[selectedLanguage as string]
                            .defaultImage as string,
                    },
                } as ImageItemLanguagesType,
            };
        }
        setProjectFiles(templateFilesArray1);
    }

    return (
        <CustomErrorBoundary>
            <div
                className="d-flex flex-column cursor__clz"
                style={{ gap: "40px" }}
            >
                <div className="d-flex align-items-center justify-content-between">
                    <span
                        style={{
                            font: "normal normal 600 35px/61px Nunito Sans",
                        }}
                    >
                        {t("General design")}
                    </span>
                    <StyledIconButton
                        title={t("Reset to default image")}
                        outline={true}
                        variant="danger"
                        className="m-0 p-0"
                        icon="RefreshIcon"
                        style={{ height: "20%", width: "20px" }}
                        onClick={() => {
                            setLocalIsResetModalOpened(
                                !localIsResetModalOpened
                            );
                        }}
                    >
                        <RefreshIcon height={20} width={20} />
                    </StyledIconButton>
                </div>
                <div
                    className="d-flex flex-row justify-content-center"
                    style={{ gap: "80px" }}
                >
                    <LogoWrapper />
                    {colors.map((color: ColorsContentType, index: number) => (
                        <div
                            key={index}
                            className="d-flex flex-column"
                            style={{ gap: "10px" }}
                        >
                            <Card
                                style={{
                                    width: "270px",
                                    height: "230px",
                                    borderRadius: "20px",
                                }}
                                className="d-flex justify-content-center"
                            >
                                <div className="d-flex flex-column justify-content-center mx-4">
                                    <ColorPickerWrapper
                                        item={color}
                                        setColor={setColors}
                                    />
                                </div>
                            </Card>
                            <div className="d-flex justify-content-center align-items-center">
                                <span
                                    style={{
                                        font:
                                            "normal normal normal 15px/20px Nunito Sans",
                                        color: "black",
                                    }}
                                >
                                    {t(color.title)}
                                </span>
                            </div>
                        </div>
                    ))}
                  
                </div>
                  <PrmWrapper />
           
            </div>
            <ResetModalWrapper
                handleResetOnClickEvent={handleResetOnClickEvent}
                localIsResetModalOpened={localIsResetModalOpened}
                setLocalIsResetModalOpened={setLocalIsResetModalOpened}
            />
        </CustomErrorBoundary>
    );
}
