import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel, CheckboxIcon } from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";

import { capitalize } from "@helpers/general";
import { store } from "@components/VerticalLayout/store";

import { store as saleStore, setCanValidate } from "../store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationAssociationModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setDataIsUpdated: Function;
    InformationMode: string;
    supportOfSale?: any;
    modeOfSale?: any;
    displayName?: string;
    tags?: any;
    setIsSending?: Function;
    advancedDisplayName: any;
};

export function ConfirmationAssociationModal({
    isModalOpened,
    setIsModalOpened,
    setDataIsUpdated,
    InformationMode,
    supportOfSale,
    modeOfSale,
    displayName,
    tags,
    setIsSending,
    advancedDisplayName,
}: ConfirmationAssociationModalType) {
    const { t } = useTranslation();
    const { modeInformationFranchise, isArchived } = useSnapshot(saleStore);
    const { franchiseID, userID, shopID, oneShop, operatorID } = useSnapshot(
        store
    );
    const uuidUser = localStorage.getItem("uuidUser");

    async function AssociateModeOfSale() {
        toast.dismiss();

        let objectData =
            modeInformationFranchise[
                modeInformationFranchise.findIndex(
                    (el: any) =>
                        el[t("Mode of information")] === InformationMode &&
                        el[t("Sales support")].value === supportOfSale.value
                )
            ];

        if (objectData?.Id !== undefined) {
            let dataModeOfInformation = [
                {
                    userId: userID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    shopId: [shopID],
                    franchiseId: franchiseID,
                    data: {
                        [objectData.Id]: {
                            designation: objectData[t("Mode of information")],
                            idMI: objectData?.idMI,
                            supportOfSale: objectData[t("Sales support")],
                            salesSupportData: [objectData[t("Sales support")]],
                            displayName: displayName,
                            modeOfSale: modeOfSale,
                            tag: tags,
                            isArchived: false,
                            state: oneShop ? false : objectData.state,
                            shopId: shopID,
                            stateShop: false,
                            advancedDisplayName: advancedDisplayName,
                        },
                    },
                    isShop: true,
                },
            ];

            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`;
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(dataModeOfInformation),
            };
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            if (setIsSending !== undefined) {
                                setIsSending(true);
                            }
                            toast.success(
                                `${t("Sale mode is successfully added")!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setDataIsUpdated(true);
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setCanValidate(true);
                    setIsModalOpened(!isModalOpened);
                }}
            >
                <span>{capitalize(t("Alert"))}</span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {!isArchived
                        ? t(
                              "The mode of information already exists in the franchise.\n\nDo you want to associate it with this shop?"
                          )
                        : t(
                              "Attention! the mode of information is already archived at the level of the franchise"
                          )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                {!isArchived && (
                    <CustomSecondaryColorButton
                        outline
                        variant="light"
                        onClick={() => {
                            setCanValidate(true);
                            setIsModalOpened(!isModalOpened);
                        }}
                        rounded
                    >
                        {t("Cancel")}
                    </CustomSecondaryColorButton>
                )}

                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        setCanValidate(true);
                        !isArchived && AssociateModeOfSale();
                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    {!isArchived ? t("Validate") : t("Ok")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
