import React from "react";
import { StyledLabel, StyledSelectInput } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import classnames from "classnames";
import { components } from "react-select";
import { useSnapshot } from "valtio";

import { setMoneticData, store } from "./store";

import { ipMatchWord } from "@constants/index";
import { httpRegex } from "../../../../constants";
import { ArrowDownIcon } from "@components/Common/SvgIcons/ArrowDownIcon";
import { SupportCodeModal } from "@pages/Applications/DeviceAssociateModal/MoneticsApplicationForm/SupportCodeModal";

import "react-toastify/dist/ReactToastify.css";

type AdyenConfigType = {
    setIsEdited: Function;
};

export function AdyenConfiguration({ setIsEdited }: AdyenConfigType) {
    const { t } = useTranslation();
    const {
        port,
        ipAddress,
        inputIp,
        ipExist,
        adyenIpAddress,
        inputAdyenIpAddress,
        mode,
        inputMode,
        adyenIpExist,
        isConsult,
        webhook,
        inputWebHook,
    } = useSnapshot(store);
    const portNumArray: {
        label: string;
        value: string;
    }[] = [...Array(32).keys()].map((e: any, index: number) => {
        return {
            label: `COM ${index + 1}`,
            value: `COM ${index + 1}`,
        };
    });
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [codeSupport, setCodeSupport] = React.useState<string>("");

    const DropdownIndicator = (props: any) => {
        return (
            <components.DropdownIndicator {...props}>
                <span
                    onClick={() => {
                        if (codeSupport === "") {
                            setIsModalOpened(true);
                        }
                    }}
                >
                    <ArrowDownIcon fill="currentColor" />
                </span>
            </components.DropdownIndicator>
        );
    };

    return (
        <React.Fragment>
            <AvForm>
                <AvGroup>
                    <StyledLabel
                        htmlFor="example-input"
                        className="mt-3 required__clz"
                    >
                        {t("IP address")}
                    </StyledLabel>
                    <AvField
                        className={classnames({
                            input__clz: inputIp || ipExist,
                            readOnly__clz: isConsult,
                        })}
                        autocomplete="off"
                        id="ipAddress"
                        name="ipAddress"
                        placeholder={t("Write")}
                        type="text"
                        onChange={(e: any) => {
                            setMoneticData(e.target.value, "ipAddress");
                            if (
                                !e.target.value.match(ipMatchWord) &&
                                e.target.value !== ""
                            ) {
                                setMoneticData(true, "inputIp");
                            } else {
                                setMoneticData(false, "inputIp");
                            }
                            setIsEdited(true);
                        }}
                        value={ipAddress}
                    />
                    {inputIp || ipExist ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t(
                                ipAddress === ""
                                    ? "Please enter a IP address"
                                    : !ipAddress.match(ipMatchWord)
                                    ? "IP address is invalid"
                                    : ipExist
                                    ? "The ip address is already exists"
                                    : ""
                            )}
                        </div>
                    ) : null}
                </AvGroup>
                <AvGroup>
                    <StyledLabel
                        htmlFor="example-input"
                        className="required__clz mt-3"
                    >
                        {t("Adyen IP")}
                    </StyledLabel>
                    <AvField
                        className={classnames({
                            input__clz: inputAdyenIpAddress || adyenIpExist,
                            readOnly__clz: isConsult,
                        })}
                        autocomplete="off"
                        id="ipAddressAdyen"
                        name="ipAddressAdyen"
                        placeholder={t("Write")}
                        type="text"
                        onChange={(e: any) => {
                            setMoneticData(e.target.value, "adyenIpAddress");
                            if (
                                !e.target.value.match(ipMatchWord) &&
                                e.target.value !== ""
                            ) {
                                setMoneticData(true, "inputAdyenIpAddress");
                            } else {
                                setMoneticData(false, "inputAdyenIpAddress");
                            }
                            setIsEdited(true);
                        }}
                        value={adyenIpAddress}
                    />
                    {inputAdyenIpAddress || adyenIpExist ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t(
                                adyenIpAddress === ""
                                    ? "Please enter a IP address"
                                    : !adyenIpAddress.match(ipMatchWord)
                                    ? "IP address is invalid"
                                    : adyenIpExist
                                    ? "The ip address is already exists"
                                    : ""
                            )}
                        </div>
                    ) : null}
                </AvGroup>
                <AvGroup>
                    <StyledLabel
                        htmlFor="example-input"
                        className="required__clz mt-3"
                    >
                        {t("Port number")}
                    </StyledLabel>
                    <StyledSelectInput
                        name="port_num"
                        id="port_num"
                        options={portNumArray}
                        onChange={(e: any) => {
                            setIsEdited(true);
                            setMoneticData(e.label, "port");
                        }}
                        placeholder={t("Write")}
                        value={{
                            value: port,
                            label: port,
                        }}
                        noOptionsMessage={() => t("No options")}
                        maxMenuHeight="90px"
                        className={classnames("imp_inp_portNumber", {
                            readOnly__clz: isConsult,
                        })}
                    />
                </AvGroup>
                <AvGroup>
                    <StyledLabel
                        htmlFor="example-input"
                        className="required__clz mt-3"
                    >
                        {t("Webhook")}
                    </StyledLabel>
                    <AvField
                        className={classnames({
                            input__clz: inputWebHook,
                            readOnly__clz: isConsult,
                        })}
                        autocomplete="off"
                        id="webhook"
                        name="webhook"
                        placeholder={t("Write")}
                        type="text"
                        onChange={(e: any) => {
                            if (!e.target.value.match(httpRegex)) {
                                setMoneticData(true, "inputWebHook");
                            } else {
                                setMoneticData(e.target.value, "webhook");
                                setMoneticData(false, "inputWebHook");
                                setIsEdited(true);
                            }
                        }}
                        value={webhook}
                    />
                    {inputWebHook && (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("Please enter a webhook")}
                        </div>
                    )}
                </AvGroup>
                <AvGroup>
                    <StyledLabel
                        htmlFor="payment type"
                        className="required__clz mt-3"
                    >
                        {t("Mode")}
                    </StyledLabel>
                    <StyledSelectInput
                        disabled
                        components={{ DropdownIndicator }}
                        className={classnames({
                            invalid__clz: inputMode,
                            readOnly__clz: isConsult,
                        })}
                        name="mode"
                        required
                        options={
                            codeSupport === ""
                                ? []
                                : [
                                      {
                                          label: "Test",
                                          value: "Test",
                                      },
                                      {
                                          label: "Production",
                                          value: "Production",
                                      },
                                  ]
                        }
                        placeholder={t("Select")}
                        onChange={(e: any) => {
                            setMoneticData(e.label, "mode");
                            setMoneticData(false, "inputMode");
                            setIsEdited(true);
                        }}
                        value={{ label: mode, value: mode }}
                        onFocus={() => {
                            if (codeSupport === "") {
                                setIsModalOpened(true);
                            }
                        }}
                    />
                    {inputMode && (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("Please select a mode")}
                        </div>
                    )}
                </AvGroup>
            </AvForm>
            {isModalOpened ? (
                <SupportCodeModal
                    isModalOpened={isModalOpened}
                    setIsModalOpened={setIsModalOpened}
                    codeSupport={codeSupport}
                    setCodeSupport={setCodeSupport}
                />
            ) : null}
        </React.Fragment>
    );
}
