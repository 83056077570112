import { proxy } from "valtio";

type StoreType = {
    selectedData: any;
};

export const store = proxy<StoreType>({
    selectedData: {},
});

export function setSelectedData(selectedData: any, name: string): any {
    if (store.selectedData[name] !== undefined) {
        store.selectedData[name].push(selectedData);
    } else {
        store.selectedData[name] = [selectedData];
    }
}

export function setRemoveSelectedData(selectedData: any, name: string): any {
    store.selectedData[name] = store.selectedData[name].filter(
        (el: any) => el.value !== selectedData.value
    );
}

export function clearMultiCustomSelectionData(): any {
    store.selectedData = {};
}

export default store;
